const countOptionItemSelection = (option, flatProduct) => {
  let quantity = 0;
  if (option.sub_options) {
    option.sub_options.forEach(subOptionId => {
      const subOption = flatProduct.subOptionsById[subOptionId];
      quantity += countOptionItemSelection(subOption, flatProduct);
    });
  }

  if (option.items) {
    quantity += option.items.reduce((count, itemId) => {
      const item = flatProduct.itemsById[itemId];
      if (item.sub_items && item.sub_items.length) {
        return (
          count +
          item.sub_items.reduce((count, subItemId) => {
            const subItem = flatProduct.subItemsById[subItemId];
            return count + subItem.quantity * subItem.count_as;
          }, 0)
        );
      }
      return count + item.quantity * item.count_as;
    }, 0);
  }

  return quantity;
};

export default countOptionItemSelection;
