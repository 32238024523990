import {
  DROPOFF_LOCATION_FORM_IS_VISIBLE,
  GET_FIRST_DROPOFF_SELECTION_REQUEST,
  GET_FIRST_DROPOFF_SELECTION_SUCCESS,
  SAVE_DROPOFF_SELECTION_SUCCESS,
  SET_IS_EDIT_DROPOFF_LOCATION,
  SAVE_DROPOFF_LOCATION,
  DELETE_DROPOFF_LOCATION,
  RESTORE_PICKED_LOCATION,
  RESTORE_SELECTED_LOCATION,
} from './dropOffLocationTypes';
import api from '../../api';

export function setDropOffLocationFormIsVisible(isVisible) {
  return {
    type: DROPOFF_LOCATION_FORM_IS_VISIBLE,
    isVisible,
  };
}

export function getFirstDropOffSelection(businessId) {
  return function(dispatch) {
    dispatch({type: GET_FIRST_DROPOFF_SELECTION_REQUEST});
    api.get(`/drop-off-locations?business_id=${businessId}`).then(response => {
      dispatch({
        type: GET_FIRST_DROPOFF_SELECTION_SUCCESS,
        dropOffLocations: response.data.data.length ? [response.data.data] : [],
      });
    });
  };
}

export function deleteDropOffLocation() {
  return {
    type: DELETE_DROPOFF_LOCATION,
    pickedDropOffLocation: [],
  };
}

export function saveDropOffLocation(location) {
  return {
    type: SAVE_DROPOFF_LOCATION,
    selectedDropOffLocation: location,
  };
}

export function setIsEditDropOffLocation(isEdit) {
  return {
    type: SET_IS_EDIT_DROPOFF_LOCATION,
    isEdit,
  };
}

export function restorePickedLocation() {
  return {
    type: RESTORE_PICKED_LOCATION,
  };
}

export function restoreSelectedLocation() {
  return function(dispatch, getState) {
    const finalDropOffLocation = getState().orderSettingReducer.dropOffDetails
      .finalDropOffLocation;
    dispatch({
      type: RESTORE_SELECTED_LOCATION,
      finalDropOffLocation: finalDropOffLocation,
    });
  };
}

export function saveDropOffSelection(location, selectionIndex) {
  let saveDropOffLocationButtonIsEnabled = false;

  return function(dispatch, getState) {
    api.get(`/drop-off-locations/${location.id}/options`).then(response => {
      const pickedDropOffLocation = [
        ...getState().dropOffLocationReducer.pickedDropOffLocation,
      ].slice(0, selectionIndex);
      const dropOffLocations = [
        ...getState().dropOffLocationReducer.dropOffLocations,
      ].slice(0, selectionIndex + 1);
      pickedDropOffLocation[selectionIndex] = location;

      if (response.data.data.length)
        dropOffLocations[selectionIndex + 1] = response.data.data;

      if (dropOffLocations.length === pickedDropOffLocation.length) {
        saveDropOffLocationButtonIsEnabled = true;
      }

      dispatch({
        type: SAVE_DROPOFF_SELECTION_SUCCESS,
        dropOffLocations,
        pickedDropOffLocation,
        saveDropOffLocationButtonIsEnabled,
      });
    });
  };
}
