import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const KeyboardDatePickerWrapper = styled(KeyboardDatePicker)`
    button {
    display: none;
  }
`;

const StyledTextField = styled(TextField)`
  input[type='date']:focus::before,
  input[type='date']:valid::before {
    // display: none;
    color: ${(props) => (props.hasValue ? 'inherit' : 'transparent')};
  }

  input[type='date']:focus {
    color: black;
  }

  input[type='date'] {
    color: ${(props) => (props.hasValue ? 'inherit' : 'transparent')};
  }

  ::-webkit-datetime-edit-year-field {
    color: transparent;
  }
`;

const SingleDatePicker = ({
  field,
  form: { setFieldValue },
  getValueOnChange,
  classes,
  setError,
  error,
  value,
  onChange,
  ...rest
}) => {
  const [selectedDate, handleDateChange] = useState(null);

  useEffect(() => {
    setFieldValue(field.name, selectedDate);
  }, [selectedDate]);

  const dateChangeHandler = (e) => {
    handleDateChange(e.target.value);
    const date = moment(e.target.value, 'YYYY-MM-DD');
    setFieldValue(field.name, new Date(date));
  };

  const [innerWidth, setInnerWidth] = useState(null);

  useEffect(() => {
    const windowSizeHandler = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', windowSizeHandler);
    return () => window.removeEventListener('resize', windowSizeHandler);
  }, []);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [typeof window !== 'undefined' && window.innerWidth]);

  if (innerWidth && innerWidth > 768) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePickerWrapper
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          InputProps={{
            placeholder: rest.format.toLowerCase(),
            className: classes.root,
          }}
          {...rest}
          keyboardIcon={<Icon style={{ display: 'none' }} />}
          minDate={null}
          // maxDateMessage="Date should not be in the future!"
          error={false}
          helperText={false}
          autoFocus={false}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (innerWidth) {
    return (
      <StyledTextField
        id="birthdate"
        label="Birthday - Optional"
        type="date"
        name="birthdate"
        value={selectedDate}
        onChange={dateChangeHandler}
        defaultValue={''}
        InputLabelProps={{
          className: classes.input,
          // shrink: true
        }}
        data-placeholder="Birthday - Optional"
        // aria-invalid="true"
        className="datepicker"
        autoFocus={true}
        fullWidth={true}
        hasValue={value}
      />
    );
  } else return null;
};

export default withStyles(styles)(SingleDatePicker);
