import styled from 'styled-components';

const Anchor = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: ${props => props.fontWeight || 'normal'};
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || props.theme.bodyColor};
  text-transform: ${props => props.textTransform || 'unset'};
  margin: 0;
  letter-spacing: 0px;
  font-family: ${props => props.theme.secondaryFontFontFamily};
  color: ${props => props.theme.clickableTextColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

export default Anchor;
