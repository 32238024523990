import React, { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import "react-widgets/dist/css/react-widgets.css";
import { withStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { PICKUP, ASAP } from "../../orderSettingTypes";
import Select from "../../../../components/Select";
import hexToRgbA from "../../../../utils/hexToRgbA";
import ControlledRadio from "../../../../components/Form/Radio";
import UnavailableMessage from "./UnavailableMessage";
import formatTimesOptions from "../utils/formatTimesOptions";
import appConfig from "../../../../../app-config";
import UnavailableMessageWithButton from "./UnavailableMessageWithButton";

const HrLine = styled.hr`
  background: #ebebeb;
  margin-bottom: 0;
`;

const TimeSelectionDiv = styled(RadioGroup)`
  margin-top: 10px;
  display: grid;
  grid-template-rows: auto auto;
`;

const DateTimeSelectDiv = styled.div`
  margin-top: 10px;
  display: ${(props) => (props.isGrid ? "grid" : "block")};
  grid-template-rows: 20px 20px;
  grid-gap: 30px;

  @media (max-width: 768px) {
    grid-template-rows: 40px 45px;
  }
`;

const MainDiv = styled.div`
  display: grid;
`;

const styles = (theme) => ({
  label: {
    fontFamily: theme.typography.fontFamily.main,
  },
  radio: {
    color: "#d8d8d8",
    "&$checked": {
      color: theme.palette.primary.main,

      "&:hover": {
        backgroundColor: hexToRgbA(theme.palette.primary.main, 0.1),
      },
    },
    "&:hover": {
      backgroundColor: hexToRgbA(theme.palette.primary.main, 0.1),
    },
  },
  checked: {},
});

const TogglesAndDateTimeSelect = ({
  interSelectedDateTime,
  selectedDateTime,
  maxFutureOrderDate,
  onDateChange,
  timesOptions,
  onTimeChange,
  datesOptions,
  noTimesAvailable,
  isAsapRadioDisabled,
  isScheduleRadioDisabled,
  handleRadioChange,
  isTimeNoLongerValid,
  orderPacingData,
  deepLinkParameters,
  is_future_order_enabled,
  is_asap_order_enabled,
  noDatesAvailableForSchedule,
  noHoursAvailableForChosenDay,
  onSeeMenu
}) => {
  let today = moment();

  const isDateShowing =
    moment(maxFutureOrderDate).format("ddd MMM DD") ==
    moment().format("ddd MMM DD")
      ? false
      : true;

  const isHoursShowing =
    isDateShowing && timesOptions && timesOptions.length > 0;

  useEffect(() => {
    if (deepLinkParameters?.time === "asap") {
      const ev = { target: { value: "asap" } };
      handleRadioChange(ev);
    } else if (deepLinkParameters?.time === "schedule") {
      const ev = { target: { value: "schedule" } };
      handleRadioChange(ev);
    }
  }, [deepLinkParameters]);

  useEffect(() => {
    if (
      noDatesAvailableForSchedule &&
      interSelectedDateTime.radioValue.value === "schedule"
    ) {
      onDateChange(new Date().toString());
    }
  }, [noDatesAvailableForSchedule, interSelectedDateTime.radioValue.value]);

  return (
    <MainDiv>
      <FormControl component="fieldset">
        <TimeSelectionDiv
          onChange={handleRadioChange}
          value={interSelectedDateTime.radioValue.value}
        >
          {is_asap_order_enabled && (
            <div>
              <ControlledRadio
                value={ASAP}
                disabled={isAsapRadioDisabled}
                label={`As soon as possible ${
                  isAsapRadioDisabled ? "- Currently unavailable" : ""
                }`}
              />
              <HrLine />
            </div>
          )}
          {is_future_order_enabled && (
            <ControlledRadio
              value="schedule"
              label={`Schedule a time ${
                isScheduleRadioDisabled ? "- Currently unavailable" : ""
              }`}
              disabled={isScheduleRadioDisabled}
            />
          )}
          {(!is_asap_order_enabled && !is_future_order_enabled) ||
          (noDatesAvailableForSchedule &&
            noHoursAvailableForChosenDay &&
            isAsapRadioDisabled) ? (
            <UnavailableMessageWithButton
              onClick={() => onSeeMenu()}
              marginTop="6px"
              title="Selected location is unable to accept orders at the moment"
            />
          ) : null}
        </TimeSelectionDiv>
      </FormControl>

      {interSelectedDateTime.radioValue.value === "schedule" &&
        !isScheduleRadioDisabled && (
          <DateTimeSelectDiv
            isGrid={!moment(maxFutureOrderDate).isSame(today, "day")}
          >
            {noDatesAvailableForSchedule ? null : (
              <Select
                value={interSelectedDateTime.dateTime.date}
                onChange={onDateChange}
                options={datesOptions}
                getOptionLabel={(date) => date.name}
                getOptionValue={(date) => date.value}
                placeholder="Select Date"
                isSearchable={false}
                menuPlacement="top"
                maxMenuHeight={150}
                isDate
              />
            )}
            {noDatesAvailableForSchedule &&
            noHoursAvailableForChosenDay ? null : (
              <Select
                value={
                  !!interSelectedDateTime.dateTime.time.name
                    ? interSelectedDateTime.dateTime.time
                    : null
                }
                onChange={onTimeChange}
                options={formatTimesOptions(
                  timesOptions,
                  orderPacingData.future_unavailable_slots
                )}
                getOptionLabel={(time) => time.name}
                getOptionValue={(time) => time.value}
                placeholder="Select Time"
                isSearchable={false}
                noOptionsMessage={() =>
                  appConfig.appSource === "madradish_catering"
                    ? "Please note that we require 24 hours notice for all delivery orders. This date is unavailable because we are closed or require more notice."
                    : "There are no order ahead hours for the selected date or the restaurant is closed"
                }
                materialUiNoOptionsMessage={
                  noTimesAvailable ? null : "No order ahead hours"
                }
                menuPlacement="top"
                maxMenuHeight={150}
                isTimeNoLongerValid={isTimeNoLongerValid}
                isFromDateTimeSelect={true}
              />
            )}
          </DateTimeSelectDiv>
        )}

      {isTimeNoLongerValid && selectedDateTime.radioValue && (
        <UnavailableMessage
          marginTop="15px"
          title="Selected time is no longer valid. Please select a different time"
        />
      )}
    </MainDiv>
  );
};

export default withStyles(styles)(TogglesAndDateTimeSelect);
