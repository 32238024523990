import React, {Fragment} from 'react';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import Title1 from '../../../components/Typography/Title1';
import Headline from '../../../components/Typography/Headline';
import Body from '../../../components/Typography/Body';
import {PrimaryButton} from '../../../components/Buttons';
import Dialog from '@material-ui/core/Dialog';
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

const styles = theme => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? '300px'
        : '100%',
  },
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const CreditWrapper = styled.div`
  width: 440px;
  height: 336px;
  padding: 20px;
  background: white;
  margin: auto;
  display: grid;
  grid-gap: 20px;

  @media (max-width: 786px) {
  
  width: 320px;
  height: 619px;
  padding: 23px 12px 290px;
  border-radius: 12px;
  
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const MenuButton = styled(PrimaryButton)`
min-width: 240px;
height: 56px;
border-radius: 10px;
margin-left: 60px;
@media (max-width: 786px) {
  height: 56px;
  margin-top:-20px;
  min-width: 120px;
  margin-left:30px;
}
`;
const Paragraph = styled(Body)`
@media (max-width: 786px) {
  width: 296px;
  height: 88px;
  margin: 16px 0 32px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
`
const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;
const MenuWrapper = styled.div`
display:flex;
justify-contetent: center;
align-item:center;
margin-top:0px;
height: 56px;
width : 240px;

`;

const CreditModal = ({
  setCreditModalIsVisible,
  creditModalIsVisible,
  classes,
  loyalty,
}) => {
  const location = useLocation();
  return (
    <Dialog
      id="CreditsModal-Dialog"
      aria-labelledby="credit"
      aria-describedby="credit"
      open={creditModalIsVisible}
      // onBackdropClick={() => setCreditModalIsVisible(false)}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{scrollPaper: classes.scrollPaper}}
      PaperProps={{classes: {root: classes.paper}}}
      scroll="body"
    >
      <CreditWrapper>
        <TitleWrapper>
          <Title1 id="CreditsModal-Title" style={{fontWeight: '600'}}>
            Credits
          </Title1>
          <Cancel
            id="CreditsModal-Cancel"
            onClick={() => setCreditModalIsVisible(false)}
          >
            <i className="fal fa-times" />
          </Cancel>
        </TitleWrapper>
        {loyalty && (
          <Fragment>
            <Headline id="CreditsModal-Headline">
            Credit balance:<br/>
            ${loyalty.credits_available.toFixed(2)}
            </Headline>
            {loyalty.credits_available > 0 && (
              <Paragraph id="CreditsModal-Message">
                This credit will be applied automatically on your next order.
                Any remaining balance will be applied on consecutive orders.
              </Paragraph>
            )}
          </Fragment>
        )}
       <MenuWrapper>
        <MenuButton
          id="CreditsModal-CloseButton"
          onClick={() => {
            if(location.pathname.includes("/menu")){
              setCreditModalIsVisible(false)
            }else{
              navigate('/');
              setCreditModalIsVisible(false)
            }
            
          }
          }
          
        >
          {' '}
          Go to Menu{' '}
        </MenuButton>
        </MenuWrapper>
        
      </CreditWrapper>
    </Dialog>
  );
};

export default withStyles(styles)(CreditModal);
