import React from 'react'
import styled from 'styled-components'
import numeral from 'numeral'

const Price = styled.span`
  position: absolute;
  right: 10px;
  margin-right: 10px;
`

const AddtionalPrice = ({
  prices,
  selectedVariantId,
  singlePrice,
  startingPrice
}) => {
  if (!selectedVariantId) return null

  let extraPrice = singlePrice - startingPrice

  const selectedVariant = prices.find(
    ({ variant_id }) => variant_id === selectedVariantId
  );

  if (selectedVariant.extra_price) {
    extraPrice += selectedVariant.extra_price
  }

  if (extraPrice === 0) return null

  return (
    <Price id="OptionSummary-AdditionalPrice">
      +{numeral(extraPrice).format('$0.00')}
    </Price>
  )
}

export default AddtionalPrice
