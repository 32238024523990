import React, { Fragment, useEffect, useState } from 'react';
import { Body, Title1 } from '../../../../components/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import * as actions from '../../orderSettingActions';
import styled from 'styled-components';
import ConfirmButton from '../../../OrderSetting/OrderSettingModal/components/ConfirmButton';
import UnavailableProducts from '../../OrderSettingModal/components/UnavailableProducts';

const styles = () => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '100%',
    // maxHeight: '95%',
  },
});

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  padding: 32px;
  max-width: 450px;
  // height: 1000px;
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const StartNewOrderFormContainer = ({
  orderSettingReducer,
  setStartNewOrderFormIsVisible,
  confirmMarketplaceDeliverySelection,
  checkUnavailableProductForMarketplace,
}) => {
  const {
    interDeliveryDetails,
    startNewOrderFormIsVisible,
    marketplaceDeliverySelection,
    cartErrorMessage,
    unavailableProducts,
    deliveryDetails,
    interSelectedDateTime,
    interOrderType,
  } = orderSettingReducer;

  return (
    <Dialog open={startNewOrderFormIsVisible} scroll="body">
      <Wrapper>
        <TitleWrapper>
          <Title1 style={{ fontWeight: '600' }}>Product Unavailable</Title1>

          <CloseButton
            onClick={() => {
              setStartNewOrderFormIsVisible(false);
            }}
          >
            <i
              className="fas fa-times"
              style={{ fontSize: '24px', cursor: 'pointer' }}
            ></i>
          </CloseButton>
        </TitleWrapper>

        <Body style={{ marginTop: '35px', marginBottom: '20px' }}>
          You have an item in your cart from another menu.
        </Body>

        {/* <Body style={{marginTop: '35px', marginBottom: '35px'}}>
          You have an item in your cart from another menu. Would you like to
          remove it from your cart and start a new one?
        </Body> */}
        {unavailableProducts.length ? (
          <UnavailableProducts
            cartErrorMessage={cartErrorMessage}
            marginBottom="25px"
          />
        ) : null}

        <ConfirmButton
          text={'Confirm'}
          onClick={() => {
            confirmMarketplaceDeliverySelection(
              marketplaceDeliverySelection,
              true
            );
            setStartNewOrderFormIsVisible(false);
            window.scrollTo(0, 0);
          }}
        />
      </Wrapper>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  orderSettingReducer: state.orderSettingReducer,
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(StartNewOrderFormContainer)
);
