import React from 'react';
import styled from 'styled-components';
import * as actions from '../../productCustomizerActions';
import { connect } from 'react-redux'
import OptionSummary from './OptionSummary'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  margin-top: 16px;
`;

const OptionSummaryContainer = ({
  flatProduct,
  removeItem,
  removeSubItem,
  handleOpenDrawerModal
}) => {
  return (
    <Wrapper>
      {flatProduct.options &&
        flatProduct.options.map(optionId => {
          const option = flatProduct.optionsById[optionId];
          return (
            <OptionSummary
              key={optionId}
              flatProduct={flatProduct}
              option={option}
              removeItem={removeItem}
              removeSubItem={removeSubItem}
              handleOpenDrawerModal={handleOpenDrawerModal}
            />
          );
        })}
    </Wrapper>
  );
};

export default connect(null, actions)(OptionSummaryContainer);

