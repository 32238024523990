import {
  GET_CUSTOMER_PROFILE_REQUEST,
  GET_CUSTOMER_PROFILE_SUCCESS,
  GET_CUSTOMER_PROFILE_FAILURE,
  EDIT_PROFILE_SETTINGS_REQUEST,
  EDIT_PROFILE_SETTINGS_SUCCESS,
  EDIT_PROFILE_SETTINGS_FAILURE,
  SET_USER_COORDS,
  RESET_EDIT_PROFILE_SETTINGS,
  SAVE_BIRTHDAY_REQUEST,
  SAVE_BIRTHDAY_SUCCESS,
  OPEN_BIRTHDAY_MODAL,
  CLOSE_BIRTHDAY_MODAL,
} from './profileSettingsTypes';

export default function(
  state = {
    requestingEditProfileSettings: false,
    coords: null,
    customerProfile: null,
    requestingSaveBirthday: false,
    birthdayModalIsVisible: false,
  },
  action
) {
  switch (action.type) {
    case GET_CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        requestingGetCustomerProfile: true,
      };
    case GET_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        requestingGetCustomerProfile: false,
        customerProfile: action.response,
      };
    case GET_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        requestingGetCustomerProfile: false,
      };
    case EDIT_PROFILE_SETTINGS_REQUEST:
      return {
        ...state,
        requestingEditProfileSettings: true,
      };
    case EDIT_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        requestingEditProfileSettings: false,
        editProfileSettingsIsSuccessful: true,
        customerProfile: {
          ...state.customerProfile,
          first_name: action.response.first_name,
          last_name: action.response.last_name,
          company_name: action.response.company_name,
        },
      };
    case RESET_EDIT_PROFILE_SETTINGS:
      return {
        ...state,
        editProfileSettingsIsSuccessful: false,
      };
    case EDIT_PROFILE_SETTINGS_FAILURE:
      return {
        ...state,
        requestingEditProfileSettings: false,
      };
    case SET_USER_COORDS:
      return {
        ...state,
        coords: action.coords,
      };
    case OPEN_BIRTHDAY_MODAL:
      return {
        ...state,
        birthdayModalIsVisible: true,
      };
    case CLOSE_BIRTHDAY_MODAL:
      return {
        ...state,
        birthdayModalIsVisible: false,
      };
    case SAVE_BIRTHDAY_REQUEST:
      return {
        ...state,
        requestingSaveBirthday: true,
      };
    case SAVE_BIRTHDAY_SUCCESS:
      return {
        ...state,
        requestingSaveBirthday: false,
        customerProfile: {
          ...state.customerProfile,
          birthdate: action.birthdate,
        },
        birthdayModalIsVisible: false,
      };
    default:
      return state;
  }
}
