import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../loyaltyActions';
import GivexLoyalty from './components/GivexLoyalty';
import LinkLoyaltyCard from './components/LinkLoyaltyCard';
import CreateLoyaltySuccessMessage from './components/CreateLoyaltySuccessMessage';

const GivexLoyaltyContainer = ({
  createGivexLoyalty,
  givexSuccessfullyCreated,
  requestingCreateGivexLoyalty,
  hideAuthenticationModal,
  setLoginToken,
  shouldDisplayTitle,
  getLoyalty,
}) => {
  const [customerHasLoyalty, setCustomerHasLoyalty] = useState(false);
  const [customerWantsLoyalty, setCustomerWantsLoyalty] = useState(false);

  useEffect(
    () => {
      if (customerWantsLoyalty) {
        createGivexLoyalty();
      }
    },
    [customerWantsLoyalty]
  );

  const handleCloseModal = () => {
    hideAuthenticationModal();
    setLoginToken();
    getLoyalty();
  };

  return (
    <Fragment>
      {((!customerWantsLoyalty && !givexSuccessfullyCreated) ||
        (customerWantsLoyalty && !givexSuccessfullyCreated)) &&
      !customerHasLoyalty ? (
        <GivexLoyalty
          setCustomerHasLoyalty={setCustomerHasLoyalty}
          setCustomerWantsLoyalty={setCustomerWantsLoyalty}
          hideAuthenticationModal={hideAuthenticationModal}
          setLoginToken={setLoginToken}
          shouldDisplayTitle={shouldDisplayTitle}
          isRequesting={requestingCreateGivexLoyalty}
        />
      ) : customerWantsLoyalty && givexSuccessfullyCreated ? (
        <CreateLoyaltySuccessMessage handleCloseModal={handleCloseModal} />
      ) : (
        customerHasLoyalty &&
        !givexSuccessfullyCreated && (
          <LinkLoyaltyCard createGivexLoyalty={createGivexLoyalty} />
        )
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  givexSuccessfullyCreated: state.loyaltyReducer.givexSuccessfullyCreated,
  givexLoyaltyProcessIsDone: state.loyaltyReducer.givexLoyaltyProcessIsDone,
  requestingCreateGivexLoyalty:
    state.loyaltyReducer.requestingCreateGivexLoyalty,
});

export default connect(
  mapStateToProps,
  actions
)(GivexLoyaltyContainer);
