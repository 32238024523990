import {
  INSERT_DEEP_LINK_PARAMETERS,
  RESET_DEEP_LINK_PARAMETERS,
} from './DeepLinkTypes';

export function insertParameters(parameters) {
  return function(dispatch) {
    dispatch({type: INSERT_DEEP_LINK_PARAMETERS, params: parameters});
  };
}

export function resetParameters() {
  return function(dispatch) {
    dispatch({type: RESET_DEEP_LINK_PARAMETERS});
  };
}
