import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../comboActions';
import ComboDetails from './ComboDetails';
import {SCREEN_COMBO_GROUP, SCREEN_PRODUCT} from '../../comboTypes';

const ComboDetailsContainer = ({
  editSelectedProductInGroup,
  setComboGroup,
  removeSelectedProductInGroup,
  changePage,
  addToCart,
  getComboItemProduct,
  updatePrice,
  isEditing,
  setscrollToDiv,
  addComboOrderInstruction,
  ...props
}) => {
  const {combo} = props;

  const addToCartString = isEditing ? 'Update Combo' : 'Add to Cart';

  useEffect(() => {
    updatePrice();
  }, [combo.combo_item_groups]);

  const onEditProductInGroup = (product, comboGroupIndex, scrollDivId) => {
    editSelectedProductInGroup(product, comboGroupIndex);
    setscrollToDiv(scrollDivId);
    changePage(SCREEN_PRODUCT);
  };

  const onRemoveProductInGroup = (comboGroupIndex, scrollDivId) => {
    removeSelectedProductInGroup(comboGroupIndex);
    setscrollToDiv(scrollDivId);
  };

  const onGroupClick = (comboGroupIndex, scrollDivId) => {
    if (1 === combo.combo_item_groups[comboGroupIndex].productItems.length) {
      getComboItemProduct(
        combo.combo_item_groups[comboGroupIndex].productItems[0]
      );
      setComboGroup(comboGroupIndex);
      setscrollToDiv(scrollDivId);
      changePage(SCREEN_PRODUCT);
    } else {
      setComboGroup(comboGroupIndex);
      setscrollToDiv(scrollDivId);
      changePage(SCREEN_COMBO_GROUP);
    }
  };

  const handleAddToCart = () => {
    addToCart();
  };

  return (
    <ComboDetails
      onRemoveProductInGroup={onRemoveProductInGroup}
      onEditProductInGroup={onEditProductInGroup}
      onGroupClick={onGroupClick}
      handleAddToCart={handleAddToCart}
      addToCartString={addToCartString}
      addComboOrderInstruction={addComboOrderInstruction}
      setscrollToDiv={setscrollToDiv}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  isEditing: state.comboReducer.isEditing,
});

export default connect(mapStateToProps, actions)(ComboDetailsContainer);
