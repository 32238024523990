import {
  LOAD_ORDER_STATUS,
  REMOVE_ALL_ORDER_STATUS,
  REMOVE_ORDER_STATUS,
  OPEN_FEEDBACK_MODAL,
  CLOSE_FEEDBACK_MODAL,
  SEND_REVIEW_SUCCESS,
  SEND_REVIEW_REQUEST,
  SEND_REVIEW_FAILURE,
} from './orderStatusTypes';

export const initialState = {
  orderStatusList: [],
  feedbackModalIsVisible: false,
  reviewIsRequesting: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_ORDER_STATUS:
      return {
        ...state,
        orderStatusList: [
          ...state.orderStatusList.filter(
            orderStatus => orderStatus.orderId !== action.orderStatus.orderId
          ),
          action.orderStatus,
        ].sort((a, b) => {
          if (a.estimatedTime && b.estimatedTime) {
            return a.estimatedTime.isBefore(b.estimatedTime)
              ? -1
              : a.estimatedTime.isAfter(b.estimatedTime)
              ? 1
              : 0;
          }
        }),
      };
    case REMOVE_ORDER_STATUS:
      return {
        ...state,
        orderStatusList: [
          ...state.orderStatusList.filter(
            orderStatus => orderStatus.orderId !== action.orderStatus.orderId
          ),
        ],
      };
    case REMOVE_ALL_ORDER_STATUS:
      return {
        ...state,
        orderStatusList: initialState.orderStatusList,
      };
    case OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModalIsVisible: true,
      };
    case CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModalIsVisible: false,
      };
    case SEND_REVIEW_REQUEST:
      return {
        ...state,
        reviewIsRequesting: true,
      };
    case SEND_REVIEW_SUCCESS:
      return {
        ...state,
        reviewIsRequesting: false,
        feedbackModalIsVisible: false,
      };
    case SEND_REVIEW_FAILURE:
      return {
        ...state,
        reviewIsRequesting: false,
      };
    default:
      return state;
  }
}
