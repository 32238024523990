import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import DropOffLocationForm from './components/DropOffLocationForm';
import * as actions from '../dropOffLocationActions';
import {setAuthenticationModalIsVisible} from '../../Auth/authActions';

const styles = () => ({
  scrollPaper: {
    // alignItems:
    //   typeof window !== 'undefined' && window.innerWidth < 786
    //     ? 'flex-end'
    //     : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '600px',
  },
});

const DropOffLocationFormContainer = ({
  classes,
  dropOffLocationReducer,
  setDropOffLocationFormIsVisible,
  saveDropOffSelection,
  deleteDropOffLocation,
  setIsEditDropOffLocation,
  saveDropOffLocation,
  restorePickedLocation,
  interDropOffDetails,
  isAuthenticated,
  setAuthenticationModalIsVisible,
}) => {
  const {
    dropOffLocationFormIsVisible,
    dropOffLocations,
    pickedDropOffLocation,
    saveDropOffLocationButtonIsEnabled,
    isEditDropOffLocation,
    selectedDropOffLocation,
  } = dropOffLocationReducer;

  useEffect(() => {
    if (dropOffLocationFormIsVisible && !isAuthenticated) {
      setAuthenticationModalIsVisible(true);
    }
  }, [dropOffLocationFormIsVisible]);

  return (
    <Dialog
      open={dropOffLocationFormIsVisible}
      onClose={() => setDropOffLocationFormIsVisible(false)}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{scrollPaper: classes.scrollPaper}}
      PaperProps={{classes: {root: classes.paper}}}
      aria-labelledby="delivery-addresses-form"
      scroll="body"
    >
      <DropOffLocationForm
        setDropOffLocationFormIsVisible={setDropOffLocationFormIsVisible}
        dropOffLocations={dropOffLocations}
        saveDropOffSelection={saveDropOffSelection}
        pickedDropOffLocation={pickedDropOffLocation}
        saveDropOffLocationButtonIsEnabled={saveDropOffLocationButtonIsEnabled}
        deleteDropOffLocation={deleteDropOffLocation}
        isEditDropOffLocation={isEditDropOffLocation}
        setIsEditDropOffLocation={setIsEditDropOffLocation}
        saveDropOffLocation={saveDropOffLocation}
        selectedDropOffLocation={selectedDropOffLocation}
        restorePickedLocation={restorePickedLocation}
        interDropOffDetails={interDropOffDetails}
      />
    </Dialog>
  );
};

const mapStateToProps = state => ({
  dropOffLocationReducer: state.dropOffLocationReducer,
  interDropOffDetails: state.orderSettingReducer.interDropOffDetails,
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default withStyles(styles)(
  connect(mapStateToProps, {...actions, setAuthenticationModalIsVisible})(
    DropOffLocationFormContainer
  )
);
