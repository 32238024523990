import {
  GET_MY_TRANSACTIONS_REQUEST,
  GET_MY_TRANSACTIONS_SUCCESS,
  GET_MY_TRANSACTION_DETAILS_SUCCESS,
  RESET_TRANSACTION_DETAILS_SUCCESS,
} from './MyTransactionTypes';
import api from '../../api';

export function getMyTransactionsList() {
  return function(dispatch) {
    dispatch({type: GET_MY_TRANSACTIONS_REQUEST});
    api
      .get('/transactions')
      .then(response => {
        dispatch({
          type: GET_MY_TRANSACTIONS_SUCCESS,
          myTransactions: response.data.data.slice(0, 10),
        });
      })
      .catch(error => console.error(error));
  };
}

export function getMyTransactionDetails(orderId) {
  return dispatch => {
    api.get(`/orders/${orderId}`).then(response => {
      dispatch({
        type: GET_MY_TRANSACTION_DETAILS_SUCCESS,
        myTransactionDetails: response.data.data,
      });
    });
  };
}

export function resetTransactionDetails() {
  return dispatch => {
    dispatch({
      type: RESET_TRANSACTION_DETAILS_SUCCESS,
    });
  };
}
