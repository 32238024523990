import axios from "axios";
import { store } from "../wrap-with-provider";
import { createAlert } from "./modules/Alert/alertActions";
import appConfig from "../app-config";

const environment = process.env.GATSBY_ENV;

let baseURL = { api: "", oldYork: "" };

if (environment === "development") {
  baseURL = {
    api: "https://api.staging.smoothpay.com/app_dev.php/web/v1",
    oldYork: "https://api.staging.smoothpay.com/app_dev.php",
  };
} else if (environment === "staging") {
  baseURL = {
    api: "https://api.staging.smoothpay.com/app_dev.php/web/v1",
    oldYork: "https://api.staging.smoothpay.com/app_dev.php",
  };
} else if (environment === "sandbox") {
  baseURL = {
    api: "https://api.sandbox.smoothpay.com/app_dev.php/web/v1",
    oldYork: "https://api.sandbox.smoothpay.com/app_dev.php",
  };
} else if (environment === "demo-sandbox") {
  baseURL = {
    api: "https://api.sandbox.demo.smoothpay.com/app_dev.php/web/v1",
    oldYork: "https://api.sandbox.demo.smoothpay.com/app_dev.php",
  };
} else if (environment === "production") {
  baseURL = {
    api: "https://api.smoothpay.com/web/v1",
    oldYork: "https://api.smoothpay.com",
  };
}

const api = axios.create({
  baseURL: baseURL.api,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const oldYork = axios.create({
  baseURL: baseURL.oldYork,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use((request) => {
  if (
    !request.url.includes("/session") &&
    !request.url.includes("login") &&
    !request.url.includes("availability") &&
    request.url !== "/registration"
  ) {
    request.headers["X-SP-API-Token"] = localStorage.getItem(
      `${appConfig.appSource}_token`
    );
  }
  return request;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Do something with response error
    // return Promise.reject(error);
    if (true !== error.config.ignore_interception) {
      if (error.response.status === 401) {
        store.dispatch({ type: "LOGOUT_SUCCESS" });
        localStorage.removeItem(`${appConfig.appSource}_token`);
        return Promise.reject(error);
      } else if (error.response.data.errors.message) {
        if (error.response.data.errors.message === "Access Denied") {
          store.dispatch({ type: "LOGOUT_SUCCESS" });
          localStorage.removeItem(`${appConfig.appSource}_token`);
        } else {
          store.dispatch(
            createAlert({
              type: "error",
              message: error.response.data.errors.message,
            })
          );
        }
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
