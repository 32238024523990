import React, { Fragment, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import {
  logout,
  setAuthenticationModalIsVisible,
} from '../modules/Auth/authActions';
import {
  updateInterDeliveryDetails,
  updateOrderSettingModalIsVisible,
} from '../modules/OrderSetting/orderSettingActions';
import { updateCartScreen } from '../modules/Cart/cartActions';
import Helmet from 'react-helmet';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import Alert from '../modules/Alert/Alert';
import BreadCrumb from './components/BreadCrumb';
import './layout.css';
import OnAppLoadContainer from '../modules/OnAppLoad/OnAppLoadContainer';
import ProductCustomizerContainer from '../modules/ProductCustomizer/ProductCustomizerContainer';
import appConfig from '../../app-config';
import favicon from '../assets/favicon';
import { Location } from '@reach/router';
import AuthContainer from '../modules/Auth/AuthContainer';
import ComboModalContainer from '../modules/Combo/ComboModalContainer';
import OrderSettingModalContainer from '../modules/OrderSetting/OrderSettingModal/OrderSettingModalContainer';
import MarketplaceOrderSettingModalContainer from '../modules/OrderSetting/MarketplaceOrderSettingModal/MarketplaceOrderSettingModalContainer';
import analytics from '../utils/analytics';
import Firebase, { FirebaseContext } from '../modules/Firebase';
import DeliveryAddressesFormContainer from '../modules/DeliveryAddresses/DeliveryAddressesForm/DeliveryAddressesFormContainer';
import { PICKUP } from '../modules/OrderSetting/orderSettingTypes';
import DropOffLocationFormContainer from '../modules/DropOff/DropOffLocationForm/DropOffLocationFormContainer';
import DeliveryTimeFormContainer from '../modules/OrderSetting/MarketplaceOrderSettingModal/DeliveryTimeFormContainer';
import PickUpTimeFormContainer from '../modules/OrderSetting/MarketplaceOrderSettingModal/PickUpTimeFormContainer';
import '../styles/fonts.css';
import ParentChildOrderMethodModal from '../modules/ParentChildRefactor/ParentChildOrderMethodModal';
import GlobalSearchWrapper from './components/GlobalSearchWrapper';
import useLinkParameters from '../modules/DeepLink/useLinkParameters';
import StartNewOrderFormContainer from '../modules/OrderSetting/MarketplaceOrderSettingModal/components/StartNewOrderFormContainer';
import api from '../api';
import useDeepLink from '../customHooks/useDeepLink';

const { appTitle, styleConfig } = appConfig;

export const AppConfigContext = React.createContext(null);

const firebase = new Firebase();

moment.locale('en');
momentLocalizer();

const GlobalStyle = createGlobalStyle`
    html {
    font-family: ${(props) => props.theme.primaryFontFontFamily};
  }
  body {
    background: white;
  }
  .rc-time-picker-panel {
    z-index: 1300 !important;
  }
  .pac-container {
    z-index: 1300 !important;
  }
`;

const Main = styled.div`
  margin: ${(props) => (props.removeMargin ? '80px 0 0 0' : '60px 0')};
  padding: ${(props) => (props.removePadding ? '0' : '20px 0px')};
  min-height: calc(100vh - 371px);
  @media (max-width: 786px) {
    margin: 50px 0 20px 0;
    margin-bottom: ${(props) => (props.removeMargin ? '0' : '20px')};
    min-height: calc(100vh - 72px);
    position: 'relative';
    // padding: 16px;
  }
`;

const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: flex-start;
  margin: 0 auto;
  // max-width: ${(props) => (props.removeMaxWidth ? 'inherit' : '1500px')};
   margin-left: ${(props) =>
     props.isCartPage ? '0' : props.removeMargin ? 'inherit' : '5%'};
  margin-right: ${(props) =>
    props.isCartPage ? '0' : props.removeMargin ? 'inherit' : '5%'};
  margin-bottom: ${(props) => (props.removeMargin ? '0' : '60px')};
  @media (max-width: 786px) {
    // width: 100%;
    display: block;
  }
`;

const Layout = ({
  children,
  authReducer,
  pages,
  productCustomizerIsVisible,
  logout,
  authenticationModalIsVisible,
  setAuthenticationModalIsVisible,
  comboCustomizerIsVisible,
  amountEditOrderConfig,
  updateOrderSettingModalIsVisible,
  locations,
  currentLocation,
  location,
  orderSettingReducer,
  updateCartScreen,
  deliveryAddressesList,
  updateInterDeliveryDetails,
  deliveryAddressesReducer,
}) => {
  const { interOrderType } = orderSettingReducer;
  const { requestingLogin, isAuthenticated } = authReducer;
  const isCartPage = location.pathname.includes('/cart');
  useLinkParameters(location);

  useDeepLink();

  const nearestLocation =
    locations &&
    locations.find(
      (location) =>
        location.accepting_orders &&
        location.is_order_ahead_enabled &&
        location.accepted_order_types.includes(PICKUP)
    );

  const muiTheme = createMuiTheme({
    palette: {
      primary: { main: styleConfig.primaryColor },
      toggleColor: { main: styleConfig.toggleColor },
    },
    typography: {
      fontFamily: {
        main: styleConfig.primaryFontFontFamily,
        secondary: styleConfig.secondaryFontFontFamily,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 450,
        md: 640,
        lg: 900,
        xl: 1200,
      },
    },
    zIndex: {
      tooltip: 1,
    },
    overrides: {
      MuiInput: {
        underline: {
          '&$focused:after': {
            borderBottomColor: styleConfig.inputFieldsBorderColorHover,
          },
        },
      },
      MuiInputLabel: {
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule

          '&$focused': {
            // increase the specificity for the pseudo class
            color: styleConfig.black,
          },
          '&$error': {
            color: 'red',
          },
        },
      },
    },
  });
  useEffect(() => {
    if (!requestingLogin && isAuthenticated) {
      setAuthenticationModalIsVisible(false);
    }
  }, [requestingLogin, isAuthenticated]);

  // useEffect(() => {
  //   if (authReducer.session_id) {
  //     analytics.initialize(authReducer.session_id);
  //   }
  // }, [authReducer.session_id]);

  // useEffect(() => {
  //   if (amountEditOrderConfig === 0) updateOrderSettingModalIsVisible(true);
  // }, [children.props.path]);

  // useEffect(() => {
  //   if (
  //     location.pathname.includes("/locations") &&
  //     orderSettingReducer.orderSettingModalIsVisible
  //   ) {
  //     updateOrderSettingModalIsVisible(false);
  //   }
  // }, [orderSettingReducer.orderSettingModalIsVisible, location.pathname]);

  useEffect(() => {
    if (!location.pathname.includes('/cart')) {
      updateCartScreen('menu');
    }
  }, [location.pathname]);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Helmet
        title={appTitle}
        link={[
          // {
          //   rel: 'shortcut icon',
          //   type: 'image/png',
          //   href: `${favicon[appConfig.appSource]}`,
          // },
          { rel: 'stylesheet', href: `${styleConfig.primaryFontWebLink}` },
        ]}
        // meta={[
        //   {name: 'description', content: 'Sample'},
        //   {name: 'keywords', content: 'sample, something'},
        // ]}
      />
      <AppConfigContext.Provider value={appConfig}>
        <FirebaseContext.Provider value={firebase}>
          <ThemeProvider theme={styleConfig}>
            <GlobalSearchWrapper>
              <div>
                <GlobalStyle />
                <div style={{ minHeight: '100vh' }}>
                  <Location>
                    {({ location }) => {
                      const removeSpacing = true;
                      const removeMaxWidth = true;

                      return (
                        <Fragment>
                          <Alert />
                          <Header
                            logout={logout}
                            location={location}
                            isAuthenticated={isAuthenticated}
                            showAuthenticationModal={() =>
                              setAuthenticationModalIsVisible(true)
                            }
                          />
                          {authenticationModalIsVisible && (
                            <AuthContainer
                              authenticationModalIsVisible={
                                authenticationModalIsVisible
                              }
                              hideAuthenticationModal={() =>
                                setAuthenticationModalIsVisible(false)
                              }
                            />
                          )}
                          <OnAppLoadContainer />
                          <Main
                            removePadding={removeSpacing}
                            removeMargin={removeSpacing}
                            location={location}
                          >
                            {pages && <BreadCrumb pages={pages} />}
                            <PageWrapper
                              removeMargin={removeSpacing}
                              removeMaxWidth={removeMaxWidth}
                              isCartPage={isCartPage}
                            >
                              <div>{children}</div>
                            </PageWrapper>
                            {productCustomizerIsVisible && (
                              <ProductCustomizerContainer />
                            )}

                            {appConfig.isComboEnabled &&
                              comboCustomizerIsVisible &&
                              !productCustomizerIsVisible && (
                                <ComboModalContainer />
                              )}

                            {(children.props.path.includes('cart') &&
                              appConfig.isMarketplaceDelivery) ||
                            appConfig.isParentChildClient ? (
                              <MarketplaceOrderSettingModalContainer
                                nearestLocation={
                                  currentLocation && locations
                                    ? nearestLocation
                                    : null
                                }
                              />
                            ) : children.props.path.includes('cart') &&
                              !appConfig.isParentChildClient ? (
                              <OrderSettingModalContainer
                                nearestLocation={
                                  currentLocation && locations
                                    ? nearestLocation
                                    : null
                                }
                              />
                            ) : null}
                            <DeliveryAddressesFormContainer />
                            <DropOffLocationFormContainer />

                            {/* <PickUpTimeFormContainer /> */}
                            {appConfig.isMarketplaceDelivery && (
                              <StartNewOrderFormContainer />
                            )}
                          </Main>
                        </Fragment>
                      );
                    }}
                  </Location>
                </div>
                <Footer isAuthenticated={isAuthenticated} />
              </div>
            </GlobalSearchWrapper>
          </ThemeProvider>
        </FirebaseContext.Provider>
      </AppConfigContext.Provider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  productCustomizerIsVisible:
    state.productCustomizerReducer.productCustomizerIsVisible,
  authenticationModalIsVisible: state.authReducer.authenticationModalIsVisible,
  comboCustomizerIsVisible: state.comboReducer.comboCustomizerIsVisible,
  locations: state.locationsReducer.locations,
  currentLocation: state.locationsReducer.currentLocation,
  amountEditOrderConfig: state.orderSettingReducer.amountEditOrderConfig,
  orderSettingReducer: state.orderSettingReducer,
  deliveryAddressesList: state.deliveryAddressesReducer.deliveryAddressesList,
  deliveryAddressesReducer: state.deliveryAddressesReducer,
});

export default connect(mapStateToProps, {
  logout,
  setAuthenticationModalIsVisible,
  updateOrderSettingModalIsVisible,
  updateInterDeliveryDetails,
  updateCartScreen,
})(Layout);
