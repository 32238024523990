import appConfigFile from "../../../../app-config";
import moment from "moment";

export const getOpeningHour = (location) => {
  if (location && location.hours) {
    const today = moment();
    const dayOfWeek = moment()
      .format("dddd")
      .toLowerCase();
    let hours = null;
    if (location.hours[dayOfWeek]) {
      hours = location.hours[dayOfWeek];
    }
    const lastOpeningHour =
      hours && hours.length > 0 ? hours[0].substring(11, 20) : null;
    if (
      !lastOpeningHour ||
      moment(lastOpeningHour, "h:mm A").isBefore(today, "minutes")
    )
      return false;
    return lastOpeningHour;
  }

  return false;
};

export const initialDynamicOrderMethodType = (appConfig) => {
  const isDeliveryEnabled =
  appConfig.accepted_order_types.delivery || appConfigFile.isFutureOrderDelivery;
  const isPickUpEnabled =
  appConfig.accepted_order_types.pickup || appConfigFile.isFutureOrderPickup;

  if (appConfigFile.isParentChildClient && appConfigFile.appSource === "feastify")
    return "delivery";

  if (isPickUpEnabled && isDeliveryEnabled) return "pickup";
  else if (isPickUpEnabled && !isDeliveryEnabled) return "pickup";
  else if (isDeliveryEnabled && !isPickUpEnabled) return "delivery";
  else return null;
};

export const formatLocationLabelAsString = (
  location,
  nearestLocation,
  coords
) => {
  let parts = [];
  let isNearestLocation = nearestLocation && location.id === nearestLocation.id;
  if (!location.accepting_orders || !location.is_order_ahead_enabled) {
    parts.push("💤 Closed");
  }

  parts.push(location.name);
  parts.push(location.distance);

  if (isNearestLocation) {
    parts.push("(Nearest Location)");
  }

  return {
    ...location,
    label: coords ? parts.join(" - ") : parts[0],
    isNearestLocation,
  };
};
