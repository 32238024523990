import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import appConfig from "../app-config";
import orderSettingReducer, {
  initialState as orderSettingReducerInitialState,
  initialStateLocalStorageOverrides as orderSettingReducerLocalStorageOverrides,
} from "./modules/OrderSetting/orderSettingReducer";

import homeFeedReducer from "./modules/HomeFeed/homeFeedReducer";
import offersReducer from "./modules/Offers/offersReducer";
import authReducer, {
  initialState as authReducerInitialState,
} from "./modules/Auth/authReducer";
import registrationReducer from "./modules/Registration/registrationReducer";
import menuReducer from "./modules/OnAppLoad/Menu/menuReducer";
import comboReducer from "./modules/Combo/comboReducer";
import productCustomizerReducer from "./modules/ProductCustomizer/productCustomizerReducer";
import cartReducer, {
  initialState as cartReducerInitialState,
} from "./modules/Cart/cartReducer";
import locationsReducer from "./modules/OnAppLoad/Locations/locationsReducer";
import myTransactionsReducer from "./modules/MyTransactions/MyTransactionsReducer";
import paymentMethodsReducer from "./modules/PaymentMethods/paymentMethodsReducer";
import profileSettingsReducer from "./modules/ProfileSettings/profileSettingsReducer";
import orderHistoryReducer from "./modules/OrderHistory/orderHistoryReducer";
import loyaltyReducer from "./modules/Loyalty/loyaltyReducer";
import favouritesReducer from "./modules/Favourites/favouritesReducer";
import alert from "./modules/Alert/alertReducer";
import deliveryAddressesReducer from "./modules/DeliveryAddresses/deliveryAddressesReducer";
import changePasswordReducer from "./modules/ChangePassword/changePasswordReducer";
import DeleteAccountReducer from "./modules/DeleteAccount/DeleteAccountReducer";
import upsellReducer from "./modules/Upsell/UpsellReducer";
import couponsReducer from "./modules/Coupons/couponsReducer";
import orderStatusReducer from "./modules/OrderStatus/orderStatusReducer";
import giftCardReducer from "./modules/GiftCard/giftCardReducer";
import dropOffLocationReducer from "./modules/DropOff/dropOffLocationReducer";
import deepLinkReducer from "./modules/DeepLink/DeepLinkReducer";

const { appSource } = appConfig;

const appReducer = combineReducers({
  alert,
  authReducer,
  registrationReducer,
  orderSettingReducer,
  locationsReducer,
  homeFeedReducer,
  offersReducer,
  menuReducer,
  comboReducer,
  productCustomizerReducer,
  cartReducer,
  paymentMethodsReducer,
  profileSettingsReducer,
  orderHistoryReducer,
  myTransactionsReducer,
  loyaltyReducer,
  favouritesReducer,
  deliveryAddressesReducer,
  dropOffLocationReducer,
  changePasswordReducer,
  DeleteAccountReducer,
  upsellReducer,
  couponsReducer,
  orderStatusReducer,
  giftCardReducer,
  deepLinkReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = {
      authReducer: {
        ...authReducerInitialState,
        appConfig: state.authReducer.appConfig,
      },
      deepLinkReducer: {
        ...state.deepLinkReducer,
      },
    };
  }

  return appReducer(state, action);
};

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(
    reduxThunk,
    save({
      states: [
        "authReducer",
        "cartReducer",
        // 'orderSettingReducer',
      ],
      namespace: appSource,
    })
  )
)(reduxCreateStore);

const loadState = () => {
  if (typeof localStorage !== "undefined") {
    const storedVersion = localStorage.getItem("version");
    if (!storedVersion || parseFloat(storedVersion) !== 1.132) {
      localStorage.clear();
      localStorage.setItem("version", 1.132);
    }
  }

  try {
    const loadedState = load({
      states: [
        "authReducer",
        "cartReducer",
        // 'orderSettingReducer',
      ],
      preloadedState: {
        authReducer: authReducerInitialState,
        cartReducer: cartReducerInitialState,
        // orderSettingReducer: orderSettingReducerInitialState,
      },
      namespace: appSource,
    });

    return {
      ...loadedState,
      cartReducer: {
        ...loadedState.cartReducer,
        validatedCart: null,
        placedOrder: null,
        orderPlaced: false,
        requestingPlaceOrder: false,
      },
      authReducer: {
        ...loadedState.authReducer,
        isGuestSessionInitiated: false,
        session_id: loadedState.authReducer.isAuthenticated
          ? loadedState.authReducer.session_id
          : null,
      },
      // orderSettingReducer: {
      //   ...loadedState.orderSettingReducer,
      //   ...orderSettingReducerLocalStorageOverrides,
      //   // orderingClosedModalIsVisible: false,
      //   orderSettingModalIsVisible: true,
      //   amountEditOrderConfig: 0,
      //   interSelectedDateTime: {
      //     ...orderSettingReducerInitialState.interSelectedDateTime,
      //     radioValue: 'asap',
      //   },
      //   dateTimeToShow: orderSettingReducerInitialState.dateTimeToShow,
      //   availableTimes: orderSettingReducerInitialState.availableTimes,
      // },
    };
  } catch (err) {
    return undefined;
  }
};

const createStore = () => createStoreWithMiddleware(rootReducer, loadState());

export default createStore;
