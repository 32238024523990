import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, ButtonRow } from '../../../../components/Buttons';

const Button = styled(PrimaryButton)`
  min-width: 150px;
  position: relative;
  font-size: 19px;
  font-weight: 600;
  height: 38px;
  border: ${(props) =>
    `2px solid ${props.inverted ? props.theme.primaryColor : '#fff'}`};
  color: ${(props) => (props.inverted ? props.theme.primaryColor : '#fff')};
  background-color: ${(props) =>
    props.inverted ? '#fff' : props.theme.primaryColor};
  width: 100%;
  & > * {
    font-weight: 600;
  }
  &:disabled {
    background-color: ${(props) =>
      props.theme.clickableTextColorInactiveState || '#637ea6'};
    border: none;
    color: white;
  }
`;

const ConfirmButton = ({ onClick, text, disabled, style, inverted }) => {
  return (
    <ButtonRow justifyContent="center">
      <Button
        onClick={() => (onClick ? onClick() : null)}
        disabled={disabled}
        style={style}
        inverted={inverted}
      >
        <a>{text}</a>
      </Button>
    </ButtonRow>
  );
};

export default ConfirmButton;
