import {
  SET_COUPON_MODAL_IS_VISIBLE,
  COUPON_POST_SUCCESS,
  SET_IS_REQUESTING,
  } from './couponsTypes';
  
  const initialState = {
    couponsModalIsVisible: false,
    couponType: '',
    couponValue: '',
    isRequesting: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_COUPON_MODAL_IS_VISIBLE:
        return {
          ...state,
          couponsModalIsVisible: action.isCouponModalVisible
        };
      case SET_IS_REQUESTING:
        return {
          ...state,
          isRequesting: action.boolean
        };
      case COUPON_POST_SUCCESS:
        return {
          ...state,
          couponType: action.couponType,
          couponValue: action.couponValue,
          snackBarIsVisible: true,
          couponsModalIsVisible: false,
          isRequesting: false
        };
      default:
        return state;
    }
  }
  