import moment from 'moment';

const getOrderDateTimeParam = selectedDateTime => {
  let orderDateTimeParam = '';
  if (
    selectedDateTime &&
    selectedDateTime.radioValue.value === 'schedule' &&
    selectedDateTime.dateTime &&
    selectedDateTime.dateTime.date &&
    selectedDateTime.dateTime.date.name &&
    selectedDateTime.dateTime.time &&
    selectedDateTime.dateTime.time.name
  ) {
    let orderDate = moment(
      selectedDateTime.dateTime.date.name,
      'ddd MMM DD'
    ).set({
      year: moment().format('YYYY'),
    });

    let orderTime = moment(selectedDateTime.dateTime.time.name, 'hh:mm A');
    if (orderDate.isValid() && orderTime.isValid()) {
      orderDate = orderDate.format('YYYY-MM-DD');
      orderTime = orderTime.format('HH:mm:ss');
      orderDateTimeParam = `&order_datetime=${orderDate + ' ' + orderTime}`;
    }
  }
  return orderDateTimeParam;
};

export default getOrderDateTimeParam;
