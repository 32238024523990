import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import {Headline} from '../../../../../components/Typography';

const ComboItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 16px;
  margin-bottom: 32px;
  cursor: pointer;
  padding: 10px;
  cursor: ${props => (props.isAvailable ? 'pointer' : 'default')};
  pointer-events: ${props => (props.isAvailable ? 'pointer' : 'none')};

  &:hover {
    background: ${props => props.theme.comboInMenuHoverStateBackgroundColor};
    transition: all 0.25s ease;
  }
`;

const ProductImage = styled.img`
  max-width: 148px;
  border-radius: 10%;
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
  border: solid 1px rgba(0, 0, 0, 0.08);
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 25px;
`;

const ProductTitle = styled(Headline)`
  color: ${props =>
    props.isAvailable ? 'inherit' : props.theme.unavailableProductColor};
`;

const ProductDesc = styled.p`
  color: ${props =>
    props.isAvailable
      ? props.theme.darkGray
      : props.theme.unavailableProductColor};
`;

const CalPrice = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: ${props => props.theme.darkGray};
  justify-self: right;
`;

const ComboItem = ({product, onItemClick}) => {
  return (
    <ComboItemWrapper
      onClick={() => onItemClick(product)}
      isAvailable={product.is_product_available}
    >
      <ProductImage
        src={product.image_url}
        isAvailable={product.is_product_available}
      />
      <InfoWrapper>
        <div>
          <ProductTitle
            id="ComboItems-Title"
            isAvailable={product.is_product_available}
          >
            {product.name}
          </ProductTitle>
          <ProductDesc
            id="ComboItems-ProductDesc"
            isAvailable={product.is_product_available}
          >
            {product.user_description}
          </ProductDesc>
        </div>
        {product.prices.length === 1 && product.prices[0].extra_price && (
          <CalPrice id="ComboItems-CalPrice">
            +{numeral(product.prices[0].extra_price).format('$0.00')}
          </CalPrice>
        )}
      </InfoWrapper>
    </ComboItemWrapper>
  );
};

export default ComboItem;
