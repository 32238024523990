import React from 'react';
import styled from 'styled-components';

const UncheckIcon = styled.div`
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  width: 20px;
  height: 20px;
`;

const CheckIcon = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 50%;
  border: 7px solid ${props => props.theme.primaryButtonBackgroundColor};
  width: 20px;
  height: 20px;
`;

const InnerCircle = styled.div`
  position: absolute;
  background: ${props => props.theme.primaryButtonFontColor};
  border-radius: 50%;
  height: 6px;
  width: 6px;
`;

const CircleCheck = ({checked = false}) =>
  checked ? (
    <CheckIcon>
      <InnerCircle />
    </CheckIcon>
  ) : (
    <UncheckIcon />
  );

export default CircleCheck;
