import {
  ADD_TO_CART,
  ADD_COMBO_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  REMOVE_COMBO_FROM_CART,
  INCREMENT_PRODUCT,
  DECREMENT_PRODUCT,
  VALIDATE_CART_REQUEST,
  VALIDATE_CART_SUCCESS,
  VALIDATE_CART_FAILURE,
  CHANGE_TIP_PERCENTAGE,
  CHANGE_SPECIAL_INSTRUCTIONS,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  INVALIDATE_CART,
  TOGGLE_PRODUCT_IS_FAVOURITED,
  UNFAVOURITE_BY_FAVOURITE_ID,
  SET_IS_FOR_PREVIEW,
  INVALID_TIP_AMOUNT,
  RESET_INVALID_TIP_FLAG,
  INITIALIZE_VALIDATE_CART_WITH_NULL,
  EMPTY_CART,
  UPDATE_CART_PRODUCTS,
  UPDATE_CART_SCREEN,
  UPDATE_CART_REQUEST, //GLOBAL CART
  SET_CHECKOUT_AS_GUEST_BUTTON_IS_VISIBLE,
  SET_SHOW_GUEST_PERSONAL_DETAILS_PAGE,
  SET_ACCOUNT_FOUND_MODAL_IS_VISIBLE,
  SET_GUEST_PERSONAL_DETAILS,
  VALIDATE_GUEST_CART_REQUEST,
  VALIDATE_GUEST_CART_SUCCESS,
  VALIDATE_GUEST_CART_FAILURE,
  RESET_GUEST_PERSONAL_DETAILS,
  PLACE_GUEST_ORDER_REQUEST,
  PLACE_GUEST_ORDER_SUCCESS,
  PLACE_GUEST_ORDER_FAILURE,
  RESET_TIP_PERCENTAGE,
  UPDATE_UNAVAILABLE_PRODUCTS,
} from './cartTypes';
import appConfig from '../../../app-config';

export const initialState = {
  products: [],
  combos: [],
  orderPlaced: false,
  tipPercentage: 0,
  specialInstructions: '',
  requestedCart: null,
  isForPreview: false,
  requestingValidateCart: false,
  requestingPlaceOrder: false,
  userInputInvalidTip: false,
  validatedCart: null,
  placedOrder: null,
  cartScreen: 'review',
  cart: {}, // GLOBAL CART
  errors: {},
  checkoutAsGuestButtonIsVisible: false,
  showGuestPersonalDetailsPage: false,
  accountFoundModalIsVisible: false,
  guestPersonalDetails: {
    source: appConfig.appSource,
    platform_id: 3,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  },
  unavailableProducts: null,
  guestOrder: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_CART_ERROR_MESSAGE':
      return {
        ...state,
        errors: action.errors,
      };
    case 'UPDATE_CART_ERROR_MESSAGE':
      return {
        ...state,
        errors: action.errors,
      };
    case ADD_TO_CART:
      if (action.product.isEditing) {
        return {
          ...state,
          products: state.products.map((product, index) => {
            if (index === action.product.productIndexInCart) {
              return action.product;
            }
            return product;
          }),
        };
      } else {
        return {
          ...state,
          products: [
            ...state.products,
            { ...action.product, productIndexInCart: state.products.length },
          ],
        };
      }
    case REMOVE_PRODUCT_FROM_CART:
      return {
        ...state,
        products: state.products
          .filter((product, index) => index !== action.productIndexInCart)
          .map((product, productIndex) => ({
            ...product,
            productIndexInCart: productIndex,
          })),
      };
    case ADD_COMBO_TO_CART:
      if (action.comboData.isEditing) {
        return {
          ...state,
          combos: state.combos.map((combo, index) => {
            if (index === action.comboData.comboIndexInCart) {
              return action.comboData.combo;
            }
            return combo;
          }),
        };
      } else {
        return {
          ...state,
          combos: [...state.combos, action.comboData.combo],
        };
      }
    case REMOVE_COMBO_FROM_CART:
      return {
        ...state,
        combos: state.combos.filter(
          (combo, index) => index != action.comboIndexInCart
        ),
      };
    case INCREMENT_PRODUCT:
      return {
        ...state,
        products: state.products.map((product, index) => {
          if (index === action.productIndexInCart) {
            return {
              ...product,
              flatProduct: {
                ...product.flatProduct,
                quantity: product.flatProduct.quantity + 1,
              },
              totalPrice:
                product.singlePrice * (product.flatProduct.quantity + 1),
            };
          } else {
            return product;
          }
        }),
      };
    case DECREMENT_PRODUCT:
      return {
        ...state,
        products: state.products.map((product, index) => {
          if (index === action.productIndexInCart) {
            return {
              ...product,
              flatProduct: {
                ...product.flatProduct,
                quantity: product.flatProduct.quantity - 1,
              },
              totalPrice:
                product.singlePrice * (product.flatProduct.quantity - 1),
            };
          } else {
            return product;
          }
        }),
      };
    case VALIDATE_CART_REQUEST:
      return {
        ...state,
        requestingValidateCart: true,
      };
    case VALIDATE_CART_SUCCESS:
      return {
        ...state,
        validatedCart: action.response,
        requestingValidateCart: false,
        requestedCart: action.requestedCart,
      };
    case VALIDATE_CART_FAILURE:
      return {
        ...state,
        requestingValidateCart: false,
      };

    case CHANGE_TIP_PERCENTAGE:
      return {
        ...state,

        tipPercentage: action.tipPercentage,

        // validatedCart: action.response,
        // requestedCart: {...state.requestedCart, tip: action.tipPercentage},
        cart: { ...state.cart, tip: action.tipPercentage },
      };
    case RESET_TIP_PERCENTAGE:
      return {
        ...state,
        tipPercentage: 0,
      };
    case CHANGE_SPECIAL_INSTRUCTIONS:
      return {
        ...state,
        specialInstructions: action.specialInstructions,
      };
    case PLACE_ORDER_REQUEST:
      return {
        ...state,
        requestingPlaceOrder: true,
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...initialState,
        products: [],
        orderPlaced: true,
        placedOrder: action.response,
        validatedCart: null,
      };
    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        requestingPlaceOrder: false,
      };
    case INVALIDATE_CART:
      return {
        ...state,
        validatedCart: null,
        orderPlaced: false,
      };
    case TOGGLE_PRODUCT_IS_FAVOURITED:
      return {
        ...state,
        products: state.products.map((product, index) =>
          index === action.productIndexInCart
            ? { ...product, favouriteId: action.favouriteId }
            : product
        ),
      };
    case UNFAVOURITE_BY_FAVOURITE_ID:
      return {
        ...state,
        products: state.products.map((product) =>
          product.favouriteId === action.favouriteId
            ? { ...product, favouriteId: null }
            : product
        ),
      };
    case SET_IS_FOR_PREVIEW:
      return {
        ...state,
        isForPreview: action.isForPreview,
      };
    case INVALID_TIP_AMOUNT:
      return {
        ...state,
        userInputInvalidTip: true,
      };
    case RESET_INVALID_TIP_FLAG:
      return {
        ...state,
        userInputInvalidTip: initialState.userInputInvalidTip,
      };
    case UPDATE_CART_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case INITIALIZE_VALIDATE_CART_WITH_NULL:
      return {
        ...state,
        validatedCart: null,
      };
    case EMPTY_CART:
      return {
        ...initialState,
        isForPreview: true,
      };
    case 'UPDATE_CART_NEW_PRICES':
      return {
        ...state,
        //products: action.newCart,
      };
    case 'UPDATE_CART_COMBOS':
      return {
        ...state,
        combos: action.combosModified,
      };
    case UPDATE_CART_SCREEN:
      return {
        ...state,
        cartScreen: action.cartScreen,
      };
    case UPDATE_CART_REQUEST: // GLOBAL CART
      return {
        ...state,
        cart: action.updatedCart,
      };
    case SET_CHECKOUT_AS_GUEST_BUTTON_IS_VISIBLE:
      return {
        ...state,
        checkoutAsGuestButtonIsVisible: action.bool,
      };
    case SET_SHOW_GUEST_PERSONAL_DETAILS_PAGE:
      return {
        ...state,
        showGuestPersonalDetailsPage: action.bool,
      };
    case SET_ACCOUNT_FOUND_MODAL_IS_VISIBLE:
      return {
        ...state,
        accountFoundModalIsVisible: action.bool,
      };
    case SET_GUEST_PERSONAL_DETAILS:
      return {
        ...state,
        guestPersonalDetails: { ...action.personalDetails },
      };
    case VALIDATE_GUEST_CART_REQUEST:
      return {
        ...state,
        requestingValidateCart: true,
      };
    case VALIDATE_GUEST_CART_SUCCESS:
      return {
        ...state,
        validatedCart: action.response,
        requestingValidateCart: false,
        requestedCart: action.requestedCart,
        showGuestPersonalDetailsPage: true,
      };
    case VALIDATE_GUEST_CART_FAILURE:
      return {
        ...state,
        requestingValidateCart: false,
        showGuestPersonalDetailsPage: false,
      };
    case RESET_GUEST_PERSONAL_DETAILS:
      return {
        ...state,
        guestPersonalDetails: {
          source: appConfig.appSource,
          platform_id: 3,
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
        },
      };
    case PLACE_GUEST_ORDER_REQUEST:
      return {
        ...state,
        requestingPlaceOrder: true,
      };
    case PLACE_GUEST_ORDER_SUCCESS:
      return {
        ...initialState,
        products: [],
        orderPlaced: true,
        placedOrder: action.response,
        guestPersonalDetails: state.guestPersonalDetails,
        guestOrder: action.response,
        requestingPlaceOrder: false,
      };
    case PLACE_GUEST_ORDER_FAILURE:
      return {
        ...state,
        requestingPlaceOrder: false,
      };
    case UPDATE_UNAVAILABLE_PRODUCTS:
      return {
        ...state,
        unavailableProducts: action.unavailableProducts,
      };
    default:
      return state;
  }
}
