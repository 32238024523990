import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { getCountries } from '../../../../utils/parseProvinceAbbr';
import appConfig from '../../../../../app-config';
import { usePlacesWidget } from 'react-google-autocomplete';
import InputField from '../../../../components/Inputs/InputField';

import Paper from '@material-ui/core/Paper';
const Wrapper = styled.div`
  padding: 8px;

  max-width: 100%;

  @media (min-width: 786px) {
    width: 100%;
  }
`;

const AddressLabel = styled.label`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`;

const EnterAddressInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid ${(props) => props.theme.darkGray};
  margin-bottom: 23px;

  &:focus {
    outline: none;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 23px;

  @media (max-width: 786px) {
    grid-template-columns: 1fr;
  }
`;

const DeliveryOptionsWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const StyledPaper = styled(Paper)`
  padding: 0px 5px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-items: center;
  width: 100%;
  height: 40px;

  &:hover {
    outline: 1px solid #000;
    outline-offset: -1px;
  }

  &:focus-within {
    outline: 2px solid ${appConfig.styleConfig.primaryButtonBackgroundColor};
    outline-offset: -1px;
  }
`;

const DeliveryNoteWrapper = styled('div')`
  display: grid;
  width: 100%;
  margin-top: -10px;

  @media (min-width: 786px) {
    grid-column: 1 / span 2;
  }
`;

const styles = (theme) => {
  return {
    input: {
      fontFamily: theme.typography.fontFamily.main,
    },
  };
};

const STREET_NUMBER = 'street_number';
const ROUTE = 'route';
const LOCALITY = 'locality';
const SUB_LOCALITY = 'sublocality';
const ADMINISTRATIVE_ADREA_LEVEL_1 = 'administrative_area_level_1';
const COUNTRY = 'country';
const POSTAL_CODE = 'postal_code';
const POSTAL_TOWN = 'postal_town';

const COUNTRIES = getCountries();

const DeliveryAddressesForm = ({
  getMarketplaceDeliveryLocations,
  isMarketplaceDelivery,
  getChildLocations,
  parentId,
  setSelectedDeliveryAddress,
  deliveryToAddress,
  deliveryAddressToBeDisplayed,
  formatAddressName,
}) => {
  const handleLine2Update = (line2) => {
    if (deliveryToAddress) {
      const newDeliveryToAddress = { ...deliveryToAddress };
      newDeliveryToAddress.line2 = line2;
      setSelectedDeliveryAddress(
        newDeliveryToAddress,
        deliveryAddressToBeDisplayed
      );
    }
  };
  const handleDetailesUpdate = (details) => {
    if (deliveryToAddress) {
      const newDeliveryToAddress = { ...deliveryToAddress };
      newDeliveryToAddress.details = details;
      setSelectedDeliveryAddress(
        newDeliveryToAddress,
        deliveryAddressToBeDisplayed
      );
    }
  };
  const handleSelectAddress = (place) => {
    const addressComponents = place.address_components;
    let streetNumber;
    let route;
    let locality;
    let administrativeAreaLevel1;
    let country;
    let postalCode;
    streetNumber = addressComponents.find(
      (component) =>
        component.types.includes(STREET_NUMBER) ||
        component.types.includes(ROUTE)
    )?.long_name;

    route = addressComponents.find((component) =>
      component.types.includes(ROUTE)
    )?.long_name;

    locality = addressComponents.find(
      (component) =>
        component.types.includes(LOCALITY) ||
        component.types.includes(SUB_LOCALITY) ||
        component.types.includes(POSTAL_TOWN)
    )?.long_name;

    administrativeAreaLevel1 = addressComponents.find((component) =>
      component.types.includes(ADMINISTRATIVE_ADREA_LEVEL_1)
    )?.long_name;

    postalCode = addressComponents.find((component) =>
      component.types.includes(POSTAL_CODE)
    )?.long_name;

    country = COUNTRIES.find(
      (c) =>
        c.name ===
        addressComponents.find((component) => component.types.includes(COUNTRY))
          .long_name
    ).abbreviation;
    const delivery_address = {
      city: locality || null,
      country: country,
      details: '',
      is_preferred: false,
      line1: `${streetNumber} ${route}`,
      line2: '',
      state: administrativeAreaLevel1,
      type: 'home',
      zip: postalCode,
    };
    const diplaying_delivery_address = {
      city: locality || null,
      country: country,
      details: '',
      is_preferred: false,
      line1: `${streetNumber} ${route}`,
      line2: '',
      state: administrativeAreaLevel1,
      type: 'home',
      zip: postalCode,
    };

    setSelectedDeliveryAddress(delivery_address, materialRef?.current?.value);

    if (isMarketplaceDelivery) {
      getMarketplaceDeliveryLocations(delivery_address);
    }
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyALktQynT1ijyIoKCLD1IrNZa1kR7xQtxI',
    onPlaceSelected: (place) => handleSelectAddress(place),
    options: {
      componentRestrictions: {
        country: appConfig.countriesSupported || 'ca',
      },
      types: ['geocode', 'establishment'],
    },
  });
  useEffect(() => {
    if (deliveryAddressToBeDisplayed) {
      materialRef.current.value = deliveryAddressToBeDisplayed;
    }
  }, [deliveryAddressToBeDisplayed]);
  return (
    <Wrapper>
      <Formik
        initialValues={
          deliveryToAddress
            ? {
                line2: deliveryToAddress.line2,
                details: deliveryToAddress.details,
                is_preferred: false,
                type: 'home',
              }
            : {
                line2: '',
                details: '',
                is_preferred: false,
                type: 'home',
              }
        }
      >
        {({ values, setFieldValue, handleChange, handleBlur }) => (
          <FormWrapper>
            <DeliveryOptionsWrapper>
              <InputField
                focused
                autoFocus
                leftIcon={
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ fontSize: '16px' }}
                  />
                }
                rightIcon={
                  <i className="fas fa-location" style={{ fontSize: '16px' }} />
                }
                style={{
                  marginLeft: '5px',
                  flex: 1,
                }}
                highlight={!deliveryToAddress}
                placeholder="Enter Your Address"
                inputProps={{ 'aria-label': 'Enter Your Address' }}
                inputRef={materialRef}
                label="Address"
                value={
                  deliveryToAddress && formatAddressName(deliveryToAddress)
                }
                deleteIcon
                onDelete={() => {
                  setSelectedDeliveryAddress(null);
                  materialRef.current.value = '';
                  getChildLocations(parentId);
                  getMarketplaceDeliveryLocations(null);
                }}
              />
            </DeliveryOptionsWrapper>
            {deliveryToAddress ? (
              <>
                <DeliveryOptionsWrapper>
                  <InputField
                    id="DeliveryAddresses-AddressLine2"
                    value={deliveryToAddress?.line2}
                    leftIcon={
                      <i className="fas fa-home" style={{ fontSize: '16px' }} />
                    }
                    style={{ marginLeft: '5px', flex: 1 }}
                    placeholder="Unit/Suite/Floor (Optional)"
                    inputProps={{ 'aria-label': 'Unit/Suite/Floor (Optional)' }}
                    onChange={(ev) => handleLine2Update(ev.target.value)}
                    label="Unit/Suite/Floor (Optional)"
                    highlight={false}
                  />
                </DeliveryOptionsWrapper>
                <DeliveryNoteWrapper>
                  <InputField
                    id="DeliveryAddresses-DeliveryNote"
                    value={deliveryToAddress?.details}
                    leftIcon={
                      <i className="fas fa-pen" style={{ fontSize: '16px' }} />
                    }
                    style={{ marginLeft: '5px', flex: 1 }}
                    placeholder="Delivery Instructions (Optional)"
                    inputProps={{
                      'aria-label': 'Delivery Instructions (Optional)',
                    }}
                    label="Delivery Instructions (Optional)"
                    onChange={(ev) => handleDetailesUpdate(ev.target.value)}
                    highlight={false}
                  />
                </DeliveryNoteWrapper>
              </>
            ) : null}
          </FormWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};

export default withStyles(styles)(DeliveryAddressesForm);
