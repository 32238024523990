import {
  INSERT_DEEP_LINK_PARAMETERS,
  RESET_DEEP_LINK_PARAMETERS,
} from './DeepLinkTypes';

export default function(state = {parameters: null}, action) {
  switch (action.type) {
    case INSERT_DEEP_LINK_PARAMETERS:
      return {
        ...state,
        parameters: action.params,
      };
    case RESET_DEEP_LINK_PARAMETERS:
      return {};
    default:
      return state;
  }
}
