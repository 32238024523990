import React, {memo, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  updateOrderSettingModalIsVisible,
  getTimeIntervalsDate,
} from '../OrderSetting/orderSettingActions';
import DynamicCartIcon from './DynamicCartIcon';
import {reviewOrder} from '../CartMenuSidebar/utils/reviewOrderValidation';
import {updateCartScreen} from '../Cart/cartActions';

const DynamicCartIconContainer = ({
  productsInCart,
  combos,
  amountEditOrderConfig,
  updateOrderSettingModalIsVisible,
  orderSettingReducer,
  updateCartScreen,
  cartScreen,
}) => {
  const {
    availableTimes,
    interPickUpDetails,
    deliveryDetails,
    selectedDateTime,
    orderType,
    deliveryBusiness,
    dateTimeToShow,
    specialHours,
    interDeliveryDetails,
    interDropOffDetails,
    dropOffDetails,
  } = orderSettingReducer;

  const numberOfProductsInCart = () => {
    let total = 0;
    productsInCart.length &&
      productsInCart.forEach(product => {
        total += product.flatProduct.quantity;
      });
    total += combos.length;
    return total;
  };

  return (
    <Fragment>
      <DynamicCartIcon
        id="DynamicCartIcon"
        numberOfProductsInCart={numberOfProductsInCart()}
        navigateToCart={() => {
          cartScreen === 'checkout' && updateCartScreen('review');
          reviewOrder({
            amountEditOrderConfig,
            orderType,
            interPickUpDetails,
            deliveryDetails,
            deliveryBusiness,
            selectedDateTime,
            availableTimes,
            dateTimeToShow,
            updateOrderSettingModalIsVisible,
            specialHours,
            interPickUpDetails,
            interDeliveryDetails,
            interDropOffDetails,
            dropOffDetails,
          });
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  productsInCart: state.cartReducer.products,
  cartScreen: state.cartReducer.cartScreen,
  combos: state.cartReducer.combos,
  amountEditOrderConfig: state.orderSettingReducer.amountEditOrderConfig,
  locations: state.locationsReducer.locations,
  orderingClosedInAllLocations:
    state.locationsReducer.orderingClosedInAllLocations,
  orderSettingReducer: state.orderSettingReducer,
});

export default memo(
  connect(mapStateToProps, {
    updateCartScreen,
    updateOrderSettingModalIsVisible,
    getTimeIntervalsDate,
  })(DynamicCartIconContainer)
);
