import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,
  SET_ORDER_TYPE,
  RESET_MENU_REDUCER,
  SET_VIEW_ONLY_MENU,
} from './menuTypes';
import api from '../../../api';
import moment from 'moment';
import getOrderDateTimeParam from '../../../utils/getOrderDateTimeParam';
import flattenProduct from '../../../utils/flattenProduct';
import calculatePrice from '../../../utils/calculatePrice';

const formatProducts = (products) => {
  if (!products) {
    return [];
  }
  return products.map((product) => {
    if (product.prices.some((price) => price.is_default)) {
      return product;
    } else {
      return {
        ...product,
        prices: [
          { ...product.prices[0], is_default: true },
          ...product.prices.slice(1),
        ],
      };
    }
  });
};

export function getMenu(businessId, orderType) {
  return function(dispatch, getState) {
    dispatch({ type: GET_MENU_REQUEST });
    const {
      selectedDateTime,
      interSelectedDateTime,
    } = getState().orderSettingReducer;
    console.log('');
    const orderDateTimeParam = getOrderDateTimeParam(interSelectedDateTime);
    if (businessId)
      return api
        .get(
          `/menu?business_id=${businessId}&order_type=${orderType}${orderDateTimeParam}`
        )
        .then(
          (response) => {
            const cart = getState().cartReducer.products;
            const newProducts = formatProducts(response.data.data.products);
            let newCart = cart
              .map((product) => {
                let selectedProduct = newProducts.find(
                  (newProduct) =>
                    newProduct.product_id === product.flatProduct.product_id
                );
                if (selectedProduct) {
                  let selectedVariant = selectedProduct.prices.find(
                    (price) => price.variant_id === product.selectedVariantId
                  );
                  if (selectedVariant) {
                    let newFlatProduct = {
                      ...product.flatProduct,
                      price: selectedVariant.price,
                      prices: selectedProduct.prices,
                      variant_name: selectedVariant.name,
                      is_product_available:
                        selectedProduct.is_product_available,
                    };

                    let newPriceFlatProduct = calculatePrice(newFlatProduct);

                    return {
                      ...product,
                      ...newPriceFlatProduct,
                      flatProduct: newFlatProduct,
                    };
                  }
                }
              })
              .filter(
                (product) =>
                  product &&
                  product.flatProduct &&
                  product.flatProduct.is_product_available
              );
            //dispatch({ type: "UPDATE_CART_NEW_PRICES", newCart });
            //dispatch({ type: "UPDATE_CART_PRODUCTS", products: newCart });
            dispatch({
              type: GET_MENU_SUCCESS,
              products: newProducts,
              categories: response.data.data.categories,
            });
          },
          (error) => {
            dispatch({ type: GET_MENU_FAILURE, error });
          }
        );
  };
}

export function updateOrderType(orderType) {
  return function(dispatch) {
    dispatch({ type: SET_ORDER_TYPE, orderType });
  };
}

export function resetMenuReducer() {
  return function(dispatch) {
    dispatch({ type: RESET_MENU_REDUCER });
  };
}
