import {
  GET_LOYALTY_REQUEST,
  GET_LOYALTY_SUCCESS,
  GET_LOYALTY_FAILURE,
  CREATE_GIVEX_LOYALTY_REQUEST,
  CREATE_GIVEX_LOYALTY_SUCCESS,
  CREATE_GIVEX_LOYALTY_FAILURE,
} from './loyaltyTypes';
import api from '../../api';
import {getPaymentMethods} from '../PaymentMethods/paymentMethodsActions';
import appConfig from '../../../app-config';

export function getLoyalty() {
  return function(dispatch) {
    // dispatch({type: GET_LOYALTY_REQUEST});
    return api.get(`/customer/loyalty`).then(
      response => {
        dispatch({type: GET_LOYALTY_SUCCESS, response: response.data});
      },
      error => {
        dispatch({type: GET_LOYALTY_FAILURE, error});
      }
    );
  };
}

export function createGivexLoyalty(loyalty_number = null) {
  const formBody = loyalty_number
    ? {loyalty_number, create: false}
    : {create: true, loyalty_number: ''};
  return function(dispatch) {
    dispatch({type: CREATE_GIVEX_LOYALTY_REQUEST});
    return api.post(`/customer/loyalty`, formBody).then(
      response => {
        dispatch({
          type: CREATE_GIVEX_LOYALTY_SUCCESS,
          isCreated: loyalty_number ? false : true,
        });
        dispatch(getPaymentMethods());
      },
      error => {
        dispatch({type: CREATE_GIVEX_LOYALTY_FAILURE});
      }
    );
  };
}

export function setLoginToken() {
  return function(dispatch) {
    dispatch({
      type: 'LOGIN_SUCCESS',
      token: localStorage.getItem(`${appConfig.appSource}_token`),
    });
  };
}
