import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import * as actions from './couponsActions';
import CouponsModal from './components/CouponsModal';

const CouponsContainer = ({
    couponsReducer,
    setCouponsModalIsVisible,
    applyCouponCode
}) => {

  const handleCouponSubmit = (couponCode) => {
    applyCouponCode(couponCode);
  }

  const {couponsModalIsVisible, couponType, couponValue, isRequesting} = couponsReducer

 return (
   <Fragment>
        <CouponsModal 
            id="Coupons-CouponsModal"
            couponsModalIsVisible={couponsModalIsVisible} 
            setCouponsModalIsVisible={setCouponsModalIsVisible}
            handleCouponSubmit={handleCouponSubmit}
            couponType={couponType}
            couponValue={couponValue}
            isRequesting={isRequesting}
            /> 
   </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    couponsReducer: state.couponsReducer
  };
};

export default connect(mapStateToProps,actions)(CouponsContainer);
