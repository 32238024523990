import React from 'react';
import styled from 'styled-components';
import {Formik} from 'formik';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Body} from '../../../../components/Typography';
import {PrimaryButton} from '../../../../components/Buttons';
import FormGrid from '../../../../components/Form/FormGrid';
import loyaltyCardBack from '../../../../assets/loyaltyCardBack.jpg';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 32px;
`;

const Description = styled(Body)`
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const FormWrapper = styled.div`
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const styles = theme => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const LinkLoyaltyCard = ({createGivexLoyalty, classes}) => {
  return (
    <Wrapper>
      <Description id="GiveLoyalty-Description">
        Input the code in the back of your card, under the barcode.
      </Description>
      <ImageWrapper>
        <img src={loyaltyCardBack} width="150" />
      </ImageWrapper>
      <FormWrapper>
        <Formik
          validate={values => {
            let errors = {};
            if (!values.loyalty_number) {
              errors.password = 'Required';
            }
            return errors;
          }}
          onSubmit={values => createGivexLoyalty(values.loyalty_number)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormGrid>
                <TextField
                  id="GiveLoyalty-VerificationCode"
                  label="Verification Code"
                  name="loyalty_number"
                  type="text"
                  value={values.loyalty_number}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={
                    touched.loyalty_number && errors.loyalty_number
                      ? true
                      : false
                  }
                  helperText={touched.loyalty_number && errors.loyalty_number}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                />
                <PrimaryButton id="GiveLoyalty-SubmitButton" type="submit">Continue</PrimaryButton>
              </FormGrid>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};

export default withStyles(styles)(LinkLoyaltyCard);
