import React from 'react';
import styled from 'styled-components';
import { Title3, Title1 } from '../../../components/Typography';
import OrderMethodSelection from '../OrderSettingModal/components/OrderMethodSelection';
import PickUp from '../OrderSettingModal/components/PickUp';
import MarketplacePickup from './components/MarketplacePickup';
import MarketplaceDelivery from './components/MarketplaceDelivery';
//import Delivery from "../OrderSettingModal/components/Delivery";
import Delivery from './components/Delivery';
import { PICKUP, DROPOFF, DELIVERY } from '../orderSettingTypes';
import appConfigFile from '../../../../app-config';
import DropOff from '../OrderSettingModal/components/DropOff';
import MarketplaceDropOff from './components/MarketplaceDropOff';

const Wrapper = styled.div`
  ${(props) =>
    props.isParentChildClient || props.isMarketplaceDelivery
      ? `display: grid;
      grid-gap: 16px;
      background: white;
      border-radius: 6px;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.24);`
      : null}

  padding: ${(props) =>
    props.isMarketplaceDelivery && !props.isParentChildClient ? '24px' : '0px'};
  background: white;
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.24);
  width:100%;
  /* width: ${(props) =>
    ((props.isParentChildClient || props.isMarketplaceDelivery) &&
      !props.interOrderType) ||
    (props.interOrderType === PICKUP && props.isParentChildClient) ||
    (props.interOrderType === DELIVERY &&
      (props.isParentChildClient || props.isMarketplaceDelivery)) ||
    (props.interOrderType === DROPOFF && props.isParentChildClient)
      ? '100%'
      : '380px'};
        @media (max-width: 786px) {
    max-width: 100%;
  }; */
`;

const CancelWrapper = styled.div``;

const Cancel = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 18px;
  z-index: 99;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
  }
`;

const Title = styled(Title1)`
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  grid-column-start: 1;
  grid-row-start: 1;
  text-align: center;
  width: 100%;
  font-size: 20px;
`;

const formatLocationsPickup = (locations) => {
  return locations.filter((location) =>
    location.accepted_order_types.includes(PICKUP)
  );
};

const formatLocationsDropOff = (locations) => {
  return locations.filter((location) =>
    location.accepted_order_types.includes(DROPOFF)
  );
};

const MarketplaceOrderSettingModal = ({
  cartItems,
  interOrderType,
  updateInterOrderType,
  locations,
  updateInterPickUpDetails,
  interPickUpDetails,
  deliveryAddressesList,
  updateInterDeliveryDetails,
  interDeliveryDetails,
  availableTimes,
  interSelectedDateTime,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  updateOrderSettingModalIsVisible,
  confirmPickUpDetails,
  unavailableProducts,
  resetInterSelectedDateTime,
  resetDateTimes,
  deliveryBusiness,
  isRequesting,
  requestingGetLocations,
  confirmMarketplaceDeliverySelection,
  setDeliveryAddressesFormIsVisible,
  resetIsTimeNoLongerValid,
  isTimeNoLongerValid,
  currentLocation,
  orderHistory,
  isAuthenticated,
  selectedDateTime,
  hideToolTip,
  toolTipIsHidden,
  nearestLocation,
  handleConfirmPickupButton,
  handleConfirmDeliveryButton,
  specialHours,
  orderPacingData,
  setDropOffLocationFormIsVisible,
  interDropOffDetails,
  updateInterDropOffDetails,
  confirmDropOffDetails,
  getFirstDropOffSelection,
  pickedDropOffLocation,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  handleConfirmDropOffButton,
  dropOffLocations,
  selectedDropOffLocation,
  getDropOffLocationsIsRequesting,
  refine,
  selectedLocation,
  orderSettingModalIsVisible,
  updateSelectedLocation,
  cartErrorMessage,
  deliveryToAddress,
  deepLinkParameters,
  getMarketplaceDeliveryLocations,
  marketplaceDeliveryLocations,
  isMarketplaceDeliveryLocationsRequest,
  setDeliveryTimeFormIsVisible,
  deliveryTimeFormIsVisible,
  deliveryDetails,
  isUnavailableProductInCartRequest,
  checkUnavailableProductForMarketplace,
  resetMarketplaceDeliveryLocations,
  confirmDeliveryDetails,
  setMarketplaceDeliverySelection,
  checkUnavailableProductForPickUp,
  parentLocation,
  setParentLocation,
  getChildLocations,
  childLocations,
  appConfig,
  profileSettingsReducer,
  setPickUpTimeFormIsVisible,
  cartReducer,
  validateCartForLocation,
  isDeepLink,
  isParentDeepLink,
  setIsParentStoreDeepLink,
  getMenu,
  setAuthenticationModalIsVisible,
  setIsMenuDisabled,
  selectMarketplacePickUpLocation,
  isMenuDisabled,
  setSelectedDeliveryAddress,
  deliveryAddressToBeDisplayed,
  emptyCart,
  clearSelectedLocation,
  getCombos,
  singleOrderType,
}) => {
  // only works for 3 truth assignments
  const onlyOne = (a, b, c) => {
    let truthyCount = !!a + !!b + !!c;
    // Workaround for the prohibited test truthyCount == 1
    return 0 < truthyCount && truthyCount < 2;
  };
  let PickUpTruth =
    (appConfig && appConfig.accepted_order_types.pickup) ||
    appConfigFile.isFutureOrderPickup;
  let deliveryTruth =
    (appConfig && appConfig.accepted_order_types.delivery) ||
    appConfigFile.isFutureOrderDelivery;
  let dropOffTruth =
    (appConfig && appConfig.accepted_order_types.pickup) ||
    appConfigFile.isFutureOrderDropOff;

  const isOneMethodOnly = onlyOne(PickUpTruth, deliveryTruth, dropOffTruth);

  return (
    <Wrapper
      interOrderType={interOrderType}
      isMarketplaceDelivery={appConfigFile.isMarketplaceDelivery}
      isParentChildClient={appConfigFile.isParentChildClient}
    >
      {((isAuthenticated && !orderHistory.length) ||
        (!isAuthenticated && nearestLocation) ||
        (isAuthenticated && !orderHistory.length && nearestLocation)) &&
        (interPickUpDetails || interDeliveryDetails || interDropOffDetails) && (
          <CancelWrapper>
            <Cancel
              onClick={() => {
                updateOrderSettingModalIsVisible(false);
              }}
            >
              <i
                className="fas fa-times"
                style={{ fontSize: '24px', cursor: 'pointer' }}
              ></i>
            </Cancel>
          </CancelWrapper>
        )}
      {!appConfigFile.isParentChildClient ? (
        <Title>HOW WOULD YOU LIKE TO ORDER TODAY?</Title>
      ) : null}
      {!isOneMethodOnly ||
      appConfigFile.isMarketplaceDelivery ||
      appConfigFile.isParentChildClient ||
      interOrderType === null ? null : (
        <OrderMethodSelection
          interOrderType={interOrderType}
          updateInterOrderType={updateInterOrderType}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          resetDateTimes={resetDateTimes}
          deepLinkParameters={deepLinkParameters}
          appConfig={appConfig}
        />
      )}
      {interOrderType === PICKUP &&
      ((appConfig && appConfig.accepted_order_types.pickup) ||
        appConfigFile.isFutureOrderPickup) &&
      !appConfigFile.isParentChildClient ? (
        <PickUp
          locations={formatLocationsPickup(locations)}
          currentLocation={currentLocation}
          updateInterPickUpDetails={updateInterPickUpDetails}
          interPickUpDetails={interPickUpDetails}
          availableTimes={availableTimes}
          interSelectedDateTime={interSelectedDateTime}
          interOrderType={interOrderType}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          confirmPickUpDetails={confirmPickUpDetails}
          unavailableProducts={unavailableProducts}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          isTimeNoLongerValid={isTimeNoLongerValid}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          handleConfirmPickupButton={handleConfirmPickupButton}
          selectedDateTime={selectedDateTime}
          nearestLocation={nearestLocation}
          hideToolTip={hideToolTip}
          toolTipIsHidden={toolTipIsHidden}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          refine={refine}
          selectedLocation={selectedLocation}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          updateSelectedLocation={updateSelectedLocation}
          cartErrorMessage={cartErrorMessage}
          deepLinkParameters={deepLinkParameters}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
          getMenu={getMenu}
        />
      ) : (interOrderType === PICKUP || interOrderType === null) &&
        ((appConfig && appConfig.accepted_order_types.pickup) ||
          appConfigFile.isFutureOrderPickup) &&
        appConfigFile.isParentChildClient ? (
        <MarketplacePickup
          locations={formatLocationsPickup(locations)}
          appConfig={appConfig}
          currentLocation={currentLocation}
          updateInterPickUpDetails={updateInterPickUpDetails}
          interPickUpDetails={interPickUpDetails}
          availableTimes={availableTimes}
          interSelectedDateTime={interSelectedDateTime}
          interOrderType={interOrderType}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          confirmPickUpDetails={confirmPickUpDetails}
          unavailableProducts={unavailableProducts}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          isTimeNoLongerValid={isTimeNoLongerValid}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          handleConfirmPickupButton={handleConfirmPickupButton}
          selectedDateTime={selectedDateTime}
          nearestLocation={nearestLocation}
          hideToolTip={hideToolTip}
          toolTipIsHidden={toolTipIsHidden}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          refine={refine}
          selectedLocation={selectedLocation}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          updateSelectedLocation={updateSelectedLocation}
          cartErrorMessage={cartErrorMessage}
          deepLinkParameters={deepLinkParameters}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          updateInterOrderType={updateInterOrderType}
          parentLocation={parentLocation}
          setParentLocation={setParentLocation}
          getChildLocations={getChildLocations}
          childLocations={childLocations}
          setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          isAuthenticated={isAuthenticated}
          profileSettingsReducer={profileSettingsReducer}
          setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
          cartReducer={cartReducer}
          isDeepLink={isDeepLink}
          isParentDeepLink={isParentDeepLink}
          setIsParentStoreDeepLink={setIsParentStoreDeepLink}
          resetDateTimes={resetDateTimes}
          setAuthenticationModalIsVisible={setAuthenticationModalIsVisible}
          isMenuDisabled={isMenuDisabled}
          setIsMenuDisabled={setIsMenuDisabled}
          getMenu={getMenu}
          emptyCart={emptyCart}
          clearSelectedLocation={clearSelectedLocation}
          getCombos={getCombos}
          singleOrderType={singleOrderType}
        />
      ) : interOrderType === DROPOFF && !appConfigFile.isParentChildClient ? (
        <DropOff
          locations={formatLocationsDropOff(locations)}
          availableTimes={availableTimes}
          specialHours={specialHours}
          interOrderType={interOrderType}
          setDropOffLocationFormIsVisible={setDropOffLocationFormIsVisible}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          currentLocation={currentLocation}
          interDropOffDetails={interDropOffDetails}
          updateInterDropOffDetails={updateInterDropOffDetails}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          confirmDropOffDetails={confirmDropOffDetails}
          isTimeNoLongerValid={isTimeNoLongerValid}
          selectedDateTime={selectedDateTime}
          orderPacingData={orderPacingData}
          unavailableProducts={unavailableProducts}
          getFirstDropOffSelection={getFirstDropOffSelection}
          pickedDropOffLocation={pickedDropOffLocation}
          setIsEditDropOffLocation={setIsEditDropOffLocation}
          deleteDropOffLocation={deleteDropOffLocation}
          handleConfirmDropOffButton={handleConfirmDropOffButton}
          dropOffLocations={dropOffLocations}
          selectedDropOffLocation={selectedDropOffLocation}
          getDropOffLocationsIsRequesting={getDropOffLocationsIsRequesting}
          cartErrorMessage={cartErrorMessage}
          deepLinkParameters={deepLinkParameters}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          isAuthenticated={isAuthenticated}
          profileSettingsReducer={profileSettingsReducer}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
        />
      ) : interOrderType === DROPOFF && appConfigFile.isParentChildClient ? (
        <MarketplaceDropOff
          locations={formatLocationsDropOff(locations)}
          availableTimes={availableTimes}
          specialHours={specialHours}
          interOrderType={interOrderType}
          setDropOffLocationFormIsVisible={setDropOffLocationFormIsVisible}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          currentLocation={currentLocation}
          interDropOffDetails={interDropOffDetails}
          updateInterOrderType={updateInterOrderType}
          updateInterDropOffDetails={updateInterDropOffDetails}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          confirmDropOffDetails={confirmDropOffDetails}
          isTimeNoLongerValid={isTimeNoLongerValid}
          selectedDateTime={selectedDateTime}
          orderPacingData={orderPacingData}
          unavailableProducts={unavailableProducts}
          getFirstDropOffSelection={getFirstDropOffSelection}
          pickedDropOffLocation={pickedDropOffLocation}
          setIsEditDropOffLocation={setIsEditDropOffLocation}
          deleteDropOffLocation={deleteDropOffLocation}
          handleConfirmDropOffButton={handleConfirmDropOffButton}
          dropOffLocations={dropOffLocations}
          selectedDropOffLocation={selectedDropOffLocation}
          getDropOffLocationsIsRequesting={getDropOffLocationsIsRequesting}
          cartErrorMessage={cartErrorMessage}
          deepLinkParameters={deepLinkParameters}
          parentLocation={parentLocation}
          setParentLocation={setParentLocation}
          getChildLocations={getChildLocations}
          childLocations={childLocations}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          isAuthenticated={isAuthenticated}
          profileSettingsReducer={profileSettingsReducer}
          setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
          isDeepLink={isDeepLink}
          isParentDeepLink={isParentDeepLink}
        />
      ) : interOrderType === DELIVERY &&
        ((appConfig && appConfig.accepted_order_types.delivery) ||
          appConfigFile.isFutureOrderDelivery) &&
        !appConfigFile.isParentChildClient ? (
        <Delivery
          cartItems={cartItems}
          deliveryAddressesList={deliveryAddressesList}
          updateInterDeliveryDetails={updateInterDeliveryDetails}
          interDeliveryDetails={interDeliveryDetails}
          interOrderType={interOrderType}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          unavailableProducts={unavailableProducts}
          availableTimes={availableTimes}
          deliveryBusiness={deliveryBusiness}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          isTimeNoLongerValid={isTimeNoLongerValid}
          isRequesting={isRequesting}
          confirmMarketplaceDeliverySelection={
            confirmMarketplaceDeliverySelection
          }
          selectedDateTime={selectedDateTime}
          setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
          isAuthenticated={isAuthenticated}
          handleConfirmDeliveryButton={handleConfirmDeliveryButton}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          refine={refine}
          cartErrorMessage={cartErrorMessage}
          deliveryToAddress={deliveryToAddress}
          hasLengthAddressesList={
            deliveryAddressesList ? deliveryAddressesList.length : null
          }
          deliveryToAddressId={
            deliveryToAddress ? deliveryToAddress.line1 : null
          }
          deepLinkParameters={deepLinkParameters}
          updateInterOrderType={updateInterOrderType}
          resetDateTimes={resetDateTimes}
          isOneMethodOnly={isOneMethodOnly}
          getMarketplaceDeliveryLocations={getMarketplaceDeliveryLocations}
          marketplaceDeliveryLocations={marketplaceDeliveryLocations}
          locations={locations}
          isMarketplaceDeliveryLocationsRequest={
            isMarketplaceDeliveryLocationsRequest
          }
          setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
          deliveryTimeFormIsVisible={deliveryTimeFormIsVisible}
          deliveryDetails={deliveryDetails}
          isUnavailableProductInCartRequest={isUnavailableProductInCartRequest}
          checkUnavailableProductForMarketplace={
            checkUnavailableProductForMarketplace
          }
          resetMarketplaceDeliveryLocations={resetMarketplaceDeliveryLocations}
          confirmDeliveryDetails={confirmDeliveryDetails}
          setMarketplaceDeliverySelection={setMarketplaceDeliverySelection}
          appConfig={appConfig}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          profileSettingsReducer={profileSettingsReducer}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
        />
      ) : (interOrderType === DELIVERY &&
          appConfig &&
          appConfig.accepted_order_types.delivery) ||
        (appConfigFile.isFutureOrderDelivery &&
          appConfigFile.isParentChildClient) ? (
        <MarketplaceDelivery
          setIsMenuDisabled={setIsMenuDisabled}
          updateSelectedLocation={updateSelectedLocation}
          cartItems={cartItems}
          deliveryAddressesList={deliveryAddressesList}
          updateInterDeliveryDetails={updateInterDeliveryDetails}
          interDeliveryDetails={interDeliveryDetails}
          interOrderType={interOrderType}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          unavailableProducts={unavailableProducts}
          availableTimes={availableTimes}
          deliveryBusiness={deliveryBusiness}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          isTimeNoLongerValid={isTimeNoLongerValid}
          isRequesting={isRequesting}
          confirmMarketplaceDeliverySelection={
            confirmMarketplaceDeliverySelection
          }
          resetDateTimes={resetDateTimes}
          selectedDateTime={selectedDateTime}
          setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
          isAuthenticated={isAuthenticated}
          handleConfirmDeliveryButton={handleConfirmDeliveryButton}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          refine={refine}
          cartErrorMessage={cartErrorMessage}
          deliveryToAddress={deliveryToAddress}
          hasLengthAddressesList={
            deliveryAddressesList ? deliveryAddressesList.length : null
          }
          deliveryToAddressId={
            deliveryToAddress ? deliveryToAddress.line1 : null
          }
          deepLinkParameters={deepLinkParameters}
          updateInterOrderType={updateInterOrderType}
          isOneMethodOnly={isOneMethodOnly}
          getMarketplaceDeliveryLocations={getMarketplaceDeliveryLocations}
          marketplaceDeliveryLocations={marketplaceDeliveryLocations}
          locations={locations}
          isMarketplaceDeliveryLocationsRequest={
            isMarketplaceDeliveryLocationsRequest
          }
          setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
          deliveryTimeFormIsVisible={deliveryTimeFormIsVisible}
          deliveryDetails={deliveryDetails}
          isUnavailableProductInCartRequest={isUnavailableProductInCartRequest}
          checkUnavailableProductForMarketplace={
            checkUnavailableProductForMarketplace
          }
          resetMarketplaceDeliveryLocations={resetMarketplaceDeliveryLocations}
          confirmDeliveryDetails={confirmDeliveryDetails}
          setMarketplaceDeliverySelection={setMarketplaceDeliverySelection}
          parentLocation={parentLocation}
          setParentLocation={setParentLocation}
          getChildLocations={getChildLocations}
          childLocations={childLocations}
          appConfig={appConfig}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          profileSettingsReducer={profileSettingsReducer}
          validateCartForLocation={validateCartForLocation}
          isDeepLink={isDeepLink}
          isParentDeepLink={isParentDeepLink}
          setIsParentStoreDeepLink={setIsParentStoreDeepLink}
          setAuthenticationModalIsVisible={setAuthenticationModalIsVisible}
          selectMarketplacePickUpLocation={selectMarketplacePickUpLocation}
          getMenu={getMenu}
          isMenuDisabled={isMenuDisabled}
          selectedLocation={selectedLocation}
          setSelectedDeliveryAddress={setSelectedDeliveryAddress}
          deliveryAddressToBeDisplayed={deliveryAddressToBeDisplayed}
          emptyCart={emptyCart}
          clearSelectedLocation={clearSelectedLocation}
          getCombos={getCombos}
          singleOrderType={singleOrderType}
        />
      ) : null}
    </Wrapper>
  );
};

export default MarketplaceOrderSettingModal;
