import React, { useState } from 'react';
import { AsYouType } from 'libphonenumber-js';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import styled from 'styled-components';
import { zIndex } from 'material-ui/styles';

const PhoneNumberText = styled.div`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='tel'] {
    -moz-appearance: textfield;
    padding-left: ${(props) =>
      props.id === 'ProfileSettings-MobileNumberField' ? '20px' : '90px'};
    width: 100%;
  }
  label {
    padding-left: 90px;
  }
  label span {
    display: none;
  }
  label[data-shrink='true'] {
    padding-left: 0px;
  }
`;

const AutoWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  fieldset {
    border: 0px;
  }
  fieldset:after {
    content: '';
    background: #ccc;
    width: 1px;
    height: 26px;
    position: absolute;
    right: 4px;
    top: 9px;
  }
  button[aria-label='Clear'] {
    display: none !important;
  }
`;

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    position: 'relative',
    '& > i': {
      display: 'none',
    },

    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
    '&[data-focus="true"]': {
      backgroundColor: '#f9f9f9',
      borderColor: '#ffffff',
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: '#f9f9f9',
      borderColor: '#ffffff',
    },
    '&[aria-selected="true"] > i': {
      display: 'inline-block',
      position: 'absolute',
      right: '10px',
    },
  },
});

const COUNTRIES_OPTIONS = [
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },
  { code: 'US', label: 'United States', phone: '1', suggested: true },
];

const CountryPopper = function(props) {
  return (
    <Popper {...props} style={{ width: '400px' }} placement="bottom-start" />
  );
};

const PhoneNumber = ({
  name,
  label,
  id,
  handlePhone,
  required,
  handleBlur,
  defaultPhoneValue,
  error,
  helperText,
  disabled,
  notVisible,
  value,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(
    defaultPhoneValue
      ? new AsYouType(COUNTRIES_OPTIONS[0].code).input(defaultPhoneValue)
      : ''
  );
  const [selectedCountryOption, setSelectedCountryOption] = useState(
    COUNTRIES_OPTIONS[0]
  );

  const classes = useStyles();

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  const onChange = (e) => {
    let { value } = e.target;
    while (value.charAt(0) === '+') {
      value = value.substring(1);
    }
    while (value.charAt(0) === '1') {
      value = value.substring(1);
    }
    if (value.includes('(') && !value.includes(')')) {
      value = value.slice(0, value.length - 1);
    }
    const formattedPhone = new AsYouType(selectedCountryOption.code).input(
      value
    );
    const phoneValue = formattedPhone
      .replace(/[^\w\s\d ]/gi, '')
      .replace(/ /g, '');

    if (phoneValue.length === 0) {
      setPhoneNumber(formattedPhone);
      handlePhone('');
    } else if (phoneValue.length <= 10) {
      setPhoneNumber(formattedPhone);
      //API doen't support country code for now, hence sending plain number.
      //setFieldValue(name, `+${selectedCountryOption.phone}${phoneValue}`);
      handlePhone(phoneValue);
    }
  };

  return (
    <PhoneNumberText id={id}>
      <AutoWraper>
        <Autocomplete
          disabled={disabled}
          id="country-select"
          autoHighlight
          options={COUNTRIES_OPTIONS}
          defaultValue={COUNTRIES_OPTIONS[0]}
          PopperComponent={CountryPopper}
          style={{
            width: 83,
            position: 'absolute',
            left: '0px',
            top: '3px',
            zIndex: 1,
            display: notVisible ? 'none' : 'inline-block',
          }}
          classes={{
            option: classes.option,
          }}
          onChange={(e, value) => {
            setSelectedCountryOption(value);
          }}
          getOptionLabel={(option) => '+' + option.phone}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code}) +{option.phone}
              <i className="fas fa-check"></i>
            </React.Fragment>
          )}
          renderInput={(params, data) => (
            <div style={{ position: 'relative' }}>
              {params.inputProps.value && (
                <span
                  style={{
                    position: 'absolute',
                    transform: 'translateY(50%)',
                    marginLeft: '0px',
                    top: '2px',
                    fontSize: '19px',
                    opacity: disabled ? 0.5 : 1,
                  }}
                >
                  {countryToFlag(selectedCountryOption.code)}
                </span>
              )}
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  style: {
                    paddingLeft: '20px',
                    width: '30px',
                  },
                }}
              />
            </div>
          )}
        />
      </AutoWraper>
      <TextField
        disabled={disabled}
        id="phone-field"
        label={label}
        name={name}
        type="tel"
        value={
          phoneNumber === '' && value
            ? new AsYouType(selectedCountryOption.code).input(value)
            : phoneNumber
        }
        onChange={onChange}
        style={{
          width: '100%',
        }}
        onBlur={handleBlur}
        error={error}
        helperText={helperText}
        required={required}
      />
    </PhoneNumberText>
  );
};

export default PhoneNumber;
