import React, { useMemo } from "react";
import appConfig from "../../../app-config";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";

function GlobalSearchWrapper({ children }) {
  const searchClient = algoliasearch(
    "EQU97UI0M4",
    "bcae10a8e54f677ecf0c4e8966c727f4"
  );

  const indexName =
    appConfig.appSource === "smooth_cafe"
      ? "smooth_cafe-staging"
      : process.env.GATSBY_ENV !== "development"
      ? appConfig.pathPrefix + "-" + process.env.GATSBY_ENV
      : appConfig.pathPrefix + "-" + "staging";

  return appConfig.isGlobalSearchEnabled ? (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      {children}
    </InstantSearch>
  ) : (
    children
  );
}

export default GlobalSearchWrapper;
