import React from 'react';
import styled from 'styled-components';
import { Headline } from '../../../../../components/Typography';

const Wrapper = styled.div`
  height: 60px;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  padding: 0 10%;
  background: ${(props) =>
    props.id !== 'Auth-Tab'
      ? props.theme.categoriesBarBackgroundColor
      : '#ffffff'};
  text-align: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none;
  }
  position: ${(props) => props.position || 'sticky'};
  top: 80px;
  z-index: 99;
  @media (max-width: 786px) {
    top: 56px;
  }
`;

const TabSelector = styled(Headline)`
  color: ${(props) =>
    props.active
      ? props.theme.categoriesBarSelectedFontColor
      : props.theme.categoriesBarFontColor};
  border-bottom: ${(props) =>
    props.active
      ? `3px solid ${props.theme.categoriesBarSelectedBorderColor}`
      : 'none'};
  cursor: pointer;
  margin-top: ${(props) => (props.active ? `3px` : '0')};
  text-transform: ${(props) => (props.customStyle ? 'none' : 'uppercase')};
  white-space: nowrap;
  padding: ${(props) => (props.customStyle ? '0 0 15px 0' : 0)};
  @media (max-width: 786px) {
    padding-right: ${(props) => (props.isLastCategory ? '20px' : '0')};
  }
`;

const CategoriesBar = ({
  id,
  categories,
  selectedCategoryId,
  changeCategory,
  customStyle,
  position,
}) => {
  return (
    <Wrapper id={id} style={customStyle ? customStyle : {}} position={position}>
      {categories.map((category, index) => {
        const active = selectedCategoryId === category.category_id;
        const isLastCategory = index + 1 === categories.length;
        return (
          <TabSelector
            id="MenuA-TabSelector"
            key={category.category_id}
            active={active}
            isLastCategory={isLastCategory}
            onClick={() => changeCategory(category.category_id)}
            customStyle={customStyle}
          >
            {category.name}
          </TabSelector>
        );
      })}
    </Wrapper>
  );
};

export default CategoriesBar;
