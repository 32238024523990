import {useState, useEffect} from 'react';
import moment from 'moment';

function useOpeningHours(location) {
  const [openingHour, setOpeningHours] = useState(null);

  useEffect(() => {
    const today = moment();
    const dayOfWeek = moment()
      .format('dddd')
      .toLowerCase();
    const hours = location.hours[dayOfWeek];
    const lastOpeningHour =
      hours.length > 0 ? hours[0].substring(11, 20) : null;
    if (
      !lastOpeningHour ||
      moment(lastOpeningHour, 'h:mm A').isBefore(today, 'minutes')
    ) {
      setOpeningHours(null);
    }
    setOpeningHours(lastOpeningHour);
  }, [location]);

  return {openingHour};
}

export default useOpeningHours;
