import {
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  NAVIGATED_TO_OFFERS_TAB,
  NAVIGATED_TO_HOW_IT_WORKS_TAB,
  ACTIVATE_OFFER_SUCCESS,
  DEACTIVATE_OFFER_SUCCESS,
  GET_OFFERS_FOR_REVIEW_SCREEN_REQUEST,
  GET_OFFERS_FOR_REVIEW_SCREEN_SUCCESS,
  GET_OFFERS_FOR_REVIEW_SCREEN_FAILURE,
} from './offersTypes';

export default function(
  state = {
    isRequestingGetOffers: true,
    offers: null,
    tabIndex: 0,
    offersForReviewScreenIsRequesting: true,
    reviewScreenOffers: [],
  },
  action
) {
  switch (action.type) {
    case GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload,
        isRequestingGetOffers: false,
      };
    case GET_OFFERS_FAILURE:
      return {
        ...state,
        requestingGetOffers: false,
      };
    case NAVIGATED_TO_OFFERS_TAB:
      return {
        ...state,
        tabIndex: action.tabIndex,
      };
    case NAVIGATED_TO_HOW_IT_WORKS_TAB:
      return {
        ...state,
        tabIndex: action.tabIndex,
      };
    case ACTIVATE_OFFER_SUCCESS:
      const updatedActivatedOffers = state.offers
        ? state.offers.map(offer => {
            if (offer.offer_id === action.payload.offer_id) {
              return action.payload;
            }
            return offer;
          })
        : null;
      return {
        ...state,
        offers: updatedActivatedOffers,
      };
    case DEACTIVATE_OFFER_SUCCESS:
      const updatedDeactivatedOffers = state.offers
        ? state.offers.map(offer => {
            if (offer.offer_id === action.payload.offer_id) {
              return action.payload;
            }
            return offer;
          })
        : null;
      return {
        ...state,
        offers: updatedDeactivatedOffers,
      };
    case GET_OFFERS_FOR_REVIEW_SCREEN_REQUEST:
      return {
        ...state,
        offersForReviewScreenIsRequesting: true,
      };
    case GET_OFFERS_FOR_REVIEW_SCREEN_SUCCESS:
      return {
        ...state,
        offersForReviewScreenIsRequesting: false,
        reviewScreenOffers: action.reviewScreenOffers,
      };
    case GET_OFFERS_FOR_REVIEW_SCREEN_FAILURE:
      return {
        ...state,
        offersForReviewScreenIsRequesting: false,
        reviewScreenOffers: [],
      };
    default:
      return state;
  }
}
