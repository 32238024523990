import React, { useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import FormGrid from '../../../components/Form/FormGrid';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { PrimaryButton, ButtonRow } from '../../../components/Buttons';
import { Caption1, Title1 } from '../../../components/Typography';
import ResendModal from './ResendModal';

const TextButton = styled(Caption1)`
  color: ${(props) => props.theme.clickableTextColor};
  cursor: pointer;
  margin-right: auto;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
  margin-top: 24px;
  font-family: Inter;
  font-size: 19px;
  font-weight: 600;
`;

const FormLayout = styled(FormGrid)`
  grid-gap: 20px;
`;

const BackButton = styled(Caption1)`
  color: ${(props) => props.theme.clickableTextColor};
  cursor: pointer;
  margin-right: auto;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;
const TitleWrapper = styled(Title1)`
  letter-spacing: 0.04px;
  font-weight: 600;
  width: fit-content;
  margin: 9px 0 24px 0;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
  }
`;
const BackIcon = styled.i`
  margin-right: 8px;
`;
const DescritionWrapper = styled.p`
  padding-bottom: 15px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
`;
const ResendOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ResendButton = styled.div``;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const Verification = ({
  classes,
  handleChangeScreen,
  emailOrPhoneField,
  setEmailOrPhoneField,
  resetForgotPassword,
  emailOrPhoneObject,
  verificationCodeConfirmation,
  postForgotPassword,
  setEmailOrPhoneObject,
  authErrors,
}) => {
  const [isResendModalVisible, setIsResendModalVisible] = useState(false);
  return (
    <div>
      <BackButton
        id="Verification-Back-Button"
        onClick={() => {
          resetForgotPassword();
          handleChangeScreen(2);
        }}
      >
        <BackIcon className="fas fa-chevron-left" />
        Back
      </BackButton>
      <TitleWrapper>Verification Code</TitleWrapper>
      <DescritionWrapper>
        Please enter the verification code below
      </DescritionWrapper>
      <Formik
        initialValues={{ code: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.code) {
            errors.code = 'Required';
          }
          return errors;
        }}
        onSubmit={(values) =>
          verificationCodeConfirmation(emailOrPhoneObject, values.code)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                id="Verification-Code"
                label="Enter code"
                name="code"
                type="text"
                value={values.email}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                error={touched.email && errors.email ? true : false}
                helperText={touched.email && errors.email}
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
              />
              <ButtonRow style={{ marginTop: '12px' }} justifyContent="center">
                <PrimaryButton
                  id="Verification-Code-Submit"
                  type="submit"
                  disabled={!values.code}
                  buttonWidth="240px"
                  buttonHeight="56px"
                  buttonBorderRadius="10px"
                  fontWeight="600"
                >
                  Continue
                </PrimaryButton>
              </ButtonRow>
            </FormLayout>
          </form>
        )}
      </Formik>
      <ResendOptionWrapper>
        <ResendButton>
          <TextButton
            id="LoginForm-ForgotEmail"
            onClick={() => {
              resetForgotPassword();
              setIsResendModalVisible(true);
            }}
          >
            Resend Code
          </TextButton>
        </ResendButton>
        <ResendButton>
          <TextButton
            id="LoginForm-ForgotEmail"
            onClick={() => {
              emailOrPhoneField === 'email'
                ? setEmailOrPhoneField('phone')
                : setEmailOrPhoneField('email');
              resetForgotPassword();
              handleChangeScreen(2);
            }}
          >
            {`Send Via ${
              emailOrPhoneField === 'email' ? 'SMS' : 'Email'
            } Instead`}
          </TextButton>
        </ResendButton>
      </ResendOptionWrapper>
      <ResendModal
        emailOrPhoneField={emailOrPhoneField}
        isResendModalVisible={isResendModalVisible}
        setIsResendModalVisible={setIsResendModalVisible}
        postForgotPassword={postForgotPassword}
        emailOrPhoneObject={emailOrPhoneObject}
        setEmailOrPhoneObject={setEmailOrPhoneObject}
        classes={classes}
      />
    </div>
  );
};

export default withStyles(styles)(Verification);
