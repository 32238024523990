import React from 'react';
import styled from 'styled-components';
import {PrimaryButton} from '../../../../components/Buttons';
import {Body} from '../../../../components/Typography';
import appConfig from '../../../../../app-config';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 100px;
`;

const Description = styled(Body)`
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin: auto;
`;

const CreateLoyaltySuccessMessage = ({handleCloseModal}) => {
  return (
    <Wrapper>
      <Description id="GiveLoyalty-SuccessMessage">
        Welcome to the {appConfig.appTitle} Loyalty Program. You will
        automatically collect points when you order ahead. You can keep track of
        your points and learn more about our loyalty program on the homefeed.
      </Description>
      <ButtonWrapper>
        <PrimaryButton id="GiveLoyalty-CloseModalButton" onClick={() => handleCloseModal()}>
          AWESOME, THANKS
        </PrimaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default CreateLoyaltySuccessMessage;
