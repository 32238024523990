import styled from 'styled-components';

const SecondaryButton = styled.button`
  height: ${props => (props.small ? '38px' : '48px')};
  height: ${props => props.buttonHeight};
  min-width: ${props => (props.small ? '114px' : '280px')};
  min-width: ${props => props.buttonWidth};
  color: ${props =>
    props.disabled
      ? props.theme.primaryButtonDisabledFontColor
      : props.theme.primaryColor};
  background: transparent;
  text-transform: ${props => props.theme.primaryButtonTextTransform};
  border-radius: ${props => props.theme.primaryButtonBorderRadius};
  // box-shadow: ${props => props.theme.primaryButtonBoxShadow};
  font-size: ${props => (props.small ? '14px' : '20px')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => props.theme.primaryColor}};
  border-radius: ${props =>
    props.buttonBorderRadius ? props.buttonBorderRadius : '4px'};
  font-family: ${props => props.theme.primaryFontFontFamily};
  font-weight: ${props => props.fontWeight};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default SecondaryButton;