import React from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItems from './MenuItems';

const ComponentWrapper = styled.div`
  position: absolute;
  top: 79px;
  right: 248px;
`;

const Wrapper = styled.div`
  // border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 2px;
  background: ${props => props.theme.white};
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  min-width: 192px;
  // padding: 0 8px;
`;

const DesktopMenu = ({
  open,
  toggleDesktopMenu,
  logout,
  closeMenu,
  loyalty,
  setCouponsModalIsVisible,
  showReferralCode,
  appconfig
}) => (
  <ComponentWrapper>
    <Popper
      open={open}
      transition
      disablePortal
      style={{top: 'unset', left: 'unset'}}
    >
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <ClickAwayListener onClickAway={closeMenu}>
            <Wrapper>
              <MenuItems
                toggleMenu={toggleDesktopMenu}
                logout={logout}
                loyalty={loyalty}
                setCouponsModalIsVisible={setCouponsModalIsVisible}
                showReferralCode={showReferralCode}
                appconfig={appconfig}
              />
            </Wrapper>
          </ClickAwayListener>
        </Grow>
      )}
    </Popper>
  </ComponentWrapper>
);

export default DesktopMenu;
