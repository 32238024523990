import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import PickUpTimeForm from './components/PickUpTimeForm';
import * as actions from '../orderSettingActions';
import { validateCartForLocation } from '../../Cart/cartActions';
import { setAuthenticationModalIsVisible } from '../../Auth/authActions';
import { getMenu } from '../../OnAppLoad/Menu/menuActions';
import { getCombos } from '../../Combo/comboActions';
import appConfig from '../../../../app-config';
import {
  emptyCart,
  updateCartWithUnavailbleProducts,
  resetCartErrorMessages,
  updateUnavailableProducts,
} from '../../Cart/cartActions';

const styles = () => ({
  scrollPaper: {
    // alignItems:
    //   typeof window !== 'undefined' && window.innerWidth < 786
    //     ? 'flex-end'
    //     : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '500px',
  },
});

const PickUpTimeFormContainer = ({
  classes,
  orderSettingReducer,
  isAuthenticated,
  updateInterSelectedDateTime,
  confirmPickUpDetails,
  resetIsTimeNoLongerValid,
  getTimeIntervalsDate,
  deepLinkParameters,
  refine,
  emptyCart,
  resetUnavailableProducts,
  setPickUpTimeFormIsVisible,
  updateInterPickUpDetails,
  updateSelectedLocation,
  validateCartForLocation,
  cartReducer,
  resetCartErrorMessages,
  updateInterDropOffDetails,
  confirmDropOffDetails,
  confirmDeliveryDetails,
  updateOrderSettingModalIsVisible,
  checkUnavailableProductForMarketplace,
  selectMarketplacePickUpLocation,
  updateCartWithUnavailbleProducts,
  updateUnavailableProducts,
  setIsMenuDisabled,
  getMenu,
  getCombos,
}) => {
  const {
    interPickUpDetails,
    pickUpTimeFormIsVisible,
    interOrderType,
    interSelectedDateTime,
    availableTimes,
    selectedDateTime,
    isTimeNoLongerValid,
    specialHours,
    orderPacingData,
    isUnavailableProductInCartRequest,
    cartErrorMessage,
    selectedLocation,
  } = orderSettingReducer;

  const { unavailableProducts } = cartReducer;

  const handleConfirmPickUpButton = () => {
    if (!isAuthenticated) {
      emptyCart();
    } else if (unavailableProducts && unavailableProducts.length > 0) {
      updateCartWithUnavailbleProducts(unavailableProducts);
    }
    selectMarketplacePickUpLocation();
    confirmPickUpDetails(true);
    setPickUpTimeFormIsVisible(false);
    updateOrderSettingModalIsVisible(false);
  };

  const onSeeMenu = () => {
    emptyCart();
    selectMarketplacePickUpLocation();
    confirmPickUpDetails(true);
    setPickUpTimeFormIsVisible(false);
    updateOrderSettingModalIsVisible(false);
  };

  const emptySearchBox = () => {
    if (appConfig.isGlobalSearchEnabled) {
      refine('');
    }
  };

  return (
    <Dialog
      open={pickUpTimeFormIsVisible}
      onClose={() => {
        setPickUpTimeFormIsVisible(false, null, true);
        resetCartErrorMessages();
      }}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{ scrollPaper: classes.scrollPaper }}
      PaperProps={{ classes: { root: classes.paper } }}
      aria-labelledby="pickup-time-form"
      scroll="body"
    >
      <PickUpTimeForm
        setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
        location={selectedLocation}
        interOrderType={interOrderType}
        interSelectedDateTime={interSelectedDateTime}
        updateInterSelectedDateTime={updateInterSelectedDateTime}
        availableTimes={availableTimes}
        selectedDateTime={selectedDateTime}
        isTimeNoLongerValid={isTimeNoLongerValid}
        resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
        getTimeIntervalsDate={getTimeIntervalsDate}
        specialHours={specialHours}
        orderPacingData={orderPacingData}
        orderMessage={appConfig.orderMessageDelivery}
        isOnline={interPickUpDetails && interPickUpDetails?.settings?.online}
        emptySearchBox={() => emptySearchBox()}
        deepLinkParameters={deepLinkParameters}
        handleConfirmPickUpButton={handleConfirmPickUpButton}
        onSeeMenu={onSeeMenu}
        isUnavailableProductInCartRequest={isUnavailableProductInCartRequest}
        unavailableProductsunavailableProducts={unavailableProducts}
        updateCartWithUnavailbleProducts={updateCartWithUnavailbleProducts}
        cartErrorMessage={cartErrorMessage}
        resetUnavailableProducts={resetUnavailableProducts}
        updateInterPickUpDetails={updateInterPickUpDetails}
        confirmPickUpDetails={confirmPickUpDetails}
        updateSelectedLocation={updateSelectedLocation}
        cartReducer={cartReducer}
        resetCartErrorMessages={resetCartErrorMessages}
        updateInterDropOffDetails={updateInterDropOffDetails}
        confirmDropOffDetails={confirmDropOffDetails}
        confirmDeliveryDetails={confirmDeliveryDetails}
        checkUnavailableProductForMarketplace={
          checkUnavailableProductForMarketplace
        }
        selectMarketplacePickUpLocation={selectMarketplacePickUpLocation}
        isAuthenticated={isAuthenticated}
        emptyCart={emptyCart}
        updateUnavailableProducts={updateUnavailableProducts}
        unavailableProducts={unavailableProducts}
        setIsMenuDisabled={setIsMenuDisabled}
        getMenu={getMenu}
        getCombos={getCombos}
        validateCartForLocation={validateCartForLocation}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  orderSettingReducer: state.orderSettingReducer,
  cartReducer: state.cartReducer,
  isAuthenticated: state.authReducer.isAuthenticated,
  deepLinkParameters: state.deepLinkReducer.parameters,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    ...actions,
    getMenu,
    getCombos,
    emptyCart,
    updateUnavailableProducts,
    updateCartWithUnavailbleProducts,
    validateCartForLocation,
    resetCartErrorMessages,
  })(PickUpTimeFormContainer)
);
