import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from './comboActions';
import {withStyles} from '@material-ui/core';
import ComboModal from './ComboModal';
import {SCREEN_COMBO} from './comboTypes';

let pageStack = [SCREEN_COMBO];

const ComboModalContainer = props => {
  const [page, setPage] = useState(SCREEN_COMBO);
  const [scrollToDiv, setscrollToDiv] = useState(0);

  useEffect(() => {
    pageStack = [SCREEN_COMBO];
  }, [props.combo]);

  useEffect(() => {
    if (page == SCREEN_COMBO) {
      document.getElementById(scrollToDiv) &&
        document.getElementById(scrollToDiv).scrollIntoView();
    }
  }, page);

  const changePage = nextPage => {
    setPage(nextPage);
    pageStack.push(nextPage);
  };

  const goBack = (toTop = false) => {
    let prevPage;

    if (toTop) {
      prevPage = SCREEN_COMBO;
      pageStack = [SCREEN_COMBO];
    } else {
      pageStack.pop();
      prevPage = pageStack[pageStack.length - 1];
    }

    setPage(prevPage);
  };

  const onModalClose = () => {};

  return (
    <ComboModal
      {...props}
      id="Combo-ComboModal"
      page={page}
      changePage={changePage}
      onModalClose={onModalClose}
      goBack={goBack}
      setscrollToDiv={setscrollToDiv}
    />
  );
};

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  paper: {
    margin: '32px 32px 0px 32px',
    minHeight: '100vh',
  },
});

const mapStateToProps = state => ({
  combo: state.comboReducer.combo,
  comboCustomizerIsVisible: state.comboReducer.comboCustomizerIsVisible,
  selectedPickUpLocation: state.orderSettingReducer.interPickUpDetails,
  productCustomizerReducer: state.productCustomizerReducer,
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(ComboModalContainer)
);
