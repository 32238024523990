import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import OptionDrawer from './OptionDrawer';
import * as actions from '../../productCustomizerActions';
import {Title2} from '../../../../components/Typography';
import countOptionItemSelection from '../../utils/countOptionItemSelection';
import {connect} from 'react-redux';
import isOptionItemSelectionMaxed from '../../utils/isOptionItemSelectionMaxed';
import countSubItemSelection from '../../utils/countSubItemSelection';
import getProperOption from '../../utils/getProperOption';
import OptionBar from './components/OptionBar';
import {Element} from 'react-scroll';
import SubOptionBar from './components/SubOptionBar';
import {PrimaryButton} from '../../../../components/Buttons';
import appConfig from '../../../../../app-config';

const Wrapper = styled.div`
  padding: ${props => (props.withHeaders ? '0 16px' : '0')};
  overflow-y: ${props => (props.withHeaders ? 'scroll' : 'inherit')};
  font-family: ${appConfig.styleConfig.appFontFamily};
  > div {
    padding-bottom: 10px;
  }
  > div:last-child {
    padding-bottom: 10px;
  }
`;

const OptionWrapper = styled.div``;

const OptionTitleContainer = styled.div`
  width: 100%;
  padding-top: ${props => (props.index === 0 ? '25px' : '0')};
`;

const OptionTitle = styled(Title2)`
  width: fit-content;
  font-weight: normal;
  margin-bottom: 15px;
  display: inline-block;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const MaximumPick = styled.div`
  margin-bottom: 12px;
  color: ${props => props.theme.darkGray};
`;

const MinimumPick = styled.span`
  float: right;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 14px;
  color: ${props =>
    props.theme.requiredPickColor ||
    props.theme.categoriesBarSelectedBorderColor};
  background-color: #f3f3f3;
`;

const SubOptionWrapper = styled.div``;

const StyledButtonRow = styled.div`
  padding: 8px 12px;
  box-shadow: 0 -1px 6px 0 rgba(66, 59, 112, 0.32);
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${props => props.theme.white};
`;

const DoneButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.addToCartButtonBackgroundColor};
  height: 60;
  width: 100%;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
`;
const OptionDrawersContainer = ({
  flatProduct,
  selectedVariantId,
  incrementItem,
  decrementItem,
  incrementSubItem,
  decrementSubItem,
  withHeaders = false,
  targetOptionId,
  handleCloseDrawerModal,
}) => {
  const handleToggleSubItemQuantity = (subItem, item, option) => {
    if (subItem.is_available) {
      if (subItem.quantity) {
        decrementSubItem(subItem);
      } else {
        if (1 === item.maximum_pick) {
          handleSelectSubItem(subItem, item, option); // toggle items as radio buttons
        } else {
          // sub item level
          let isMaxed =
            subItem.quantity * subItem.count_as >= subItem.maximum_pick;

          // item level (if any)
          if (!isMaxed && item.maximum_pick) {
            const remainingPick =
              item.maximum_pick - countSubItemSelection(item, flatProduct);
            isMaxed = remainingPick <= 0 || remainingPick < subItem.count_as;
          }

          // option level
          if (!isMaxed) {
            const remainingPick =
              option.maximum_pick -
              countOptionItemSelection(option, flatProduct);
            isMaxed = remainingPick <= 0 || remainingPick < subItem.count_as;
          }

          if (!isMaxed) {
            incrementSubItem(subItem);
          }
        }
      }
    }
  };

  const handleSelectSubItem = (subItem, item, option) => {
    const isMaxed = countSubItemSelection(item, flatProduct) >= 1;
    const optionIsMaxed = isOptionItemSelectionMaxed(option, flatProduct);

    let isDecreased = false;
    item.sub_items.forEach(subItemId => {
      const subItem = flatProduct.subItemsById[subItemId];
      if (subItem.quantity) {
        decrementSubItem(subItem);
        isDecreased = true;
      }
    });

    if (!subItem.quantity && ((!isMaxed && !optionIsMaxed) || isDecreased)) {
      incrementSubItem(subItem);
    }
  };

  const handleSelectItem = (itemToSelect, option, parentOption) => {
    const isMaxed = isOptionItemSelectionMaxed(parentOption, flatProduct);

    let isDecreased = false;
    option.items.forEach(itemId => {
      const item = flatProduct.itemsById[itemId];
      if (item.quantity) {
        decrementItem(item);
        isDecreased = true;
      }
    });

    if (!itemToSelect.quantity && (!isMaxed || isDecreased)) {
      incrementItem(itemToSelect);
    }
  };

  const handleIncreaseItemQuantity = (item, option) => {
    let isMaxed = item.quantity * item.count_as === item.maximum_pick;

    if (!isMaxed) {
      const remainingPick =
        option.maximum_pick - countOptionItemSelection(option, flatProduct);
      isMaxed = remainingPick <= 0 || remainingPick < item.count_as;
    }

    if (!isMaxed) {
      const parentOption = flatProduct.optionsById[option.parent_id];

      if (parentOption) {
        const remainingPick =
          parentOption.maximum_pick -
          countOptionItemSelection(parentOption, flatProduct);
        isMaxed = remainingPick <= 0 || remainingPick < item.count_as;
      }
    }

    if (!isMaxed) {
      incrementItem(item);
    }
  };

  const handleDecreaseItemQuantity = item => {
    if (item.quantity) {
      decrementItem(item);
    }
  };
  return (
    <Fragment>
      {withHeaders && (
        <OptionBar
          options={flatProduct.options.map(id => flatProduct.optionsById[id])}
          targetOptionId={targetOptionId}
        />
      )}
      <Wrapper id="productOptionDrawers" withHeaders={withHeaders}>
        {flatProduct.options &&
          flatProduct.options.map((optionId, index) => {
            const option = flatProduct.optionsById[optionId];
            const itemSelected = countOptionItemSelection(option, flatProduct);
            if (!option.sub_options.length) {
              return (
                <Element
                  name={`option_${option.option_id}`}
                  id={`option_${option.option_id}`}
                  key={option.option_id}
                >
                  <OptionWrapper>
                    <OptionTitleContainer index={index}>
                      <OptionTitle>{option.name}</OptionTitle>
                      {option.minimum_pick > 0 && (
                        <MinimumPick>
                          {option.minimum_pick} required
                        </MinimumPick>
                      )}
                    </OptionTitleContainer>
                    {option.maximum_pick > 1 && itemSelected === 0 ? (
                      <MaximumPick>
                        Select up to {option.maximum_pick}
                      </MaximumPick>
                    ) : (
                      <MaximumPick>
                        {itemSelected} out of {option.maximum_pick} selected
                      </MaximumPick>
                    )}
                    <OptionDrawer
                      flatProduct={flatProduct}
                      option={option}
                      key={option.option_id}
                      selectedVariantId={selectedVariantId}
                      handleToggleSubItemQuantity={handleToggleSubItemQuantity}
                      handleSelectItem={handleSelectItem}
                      handleIncreaseItemQuantity={handleIncreaseItemQuantity}
                      handleDecreaseItemQuantity={handleDecreaseItemQuantity}
                    />
                  </OptionWrapper>
                </Element>
              );
            } else {
              return (
                <Element
                  name={`option_${option.option_id}`}
                  key={`option_${option.option_id}`}
                  id={`option_${option.option_id}`}
                >
                  <OptionWrapper>
                    <OptionTitleContainer index={index}>
                      <OptionTitle>{option.name}</OptionTitle>
                      {option.minimum_pick > 0 && (
                        <MinimumPick>
                          {option.minimum_pick} required
                        </MinimumPick>
                      )}
                    </OptionTitleContainer>
                    {withHeaders && (
                      <SubOptionBar
                        subOptions={option.sub_options.map(
                          id => flatProduct.subOptionsById[id]
                        )}
                      />
                    )}
                    {option.maximum_pick > 1 && itemSelected === 0 ? (
                      <MaximumPick>
                        Select up to {option.maximum_pick}
                      </MaximumPick>
                    ) : !option.maximum_pick ? null : (
                      <MaximumPick>
                        {itemSelected} out of {option.maximum_pick} selected
                      </MaximumPick>
                    )}
                    <SubOptionWrapper>
                      {option.sub_options.map(subOptionId => {
                        const subOption =
                          flatProduct.subOptionsById[subOptionId];
                        return (
                          <Element
                            name={`option_${subOption.option_id}`}
                            key={`option_${subOption.option_id}`}
                            id={`option_${subOption.option_id}`}
                          >
                            <OptionDrawer
                              flatProduct={flatProduct}
                              parentOption={getProperOption(option, subOption)}
                              option={subOption}
                              selectedVariantId={selectedVariantId}
                              handleToggleSubItemQuantity={
                                handleToggleSubItemQuantity
                              }
                              handleSelectItem={handleSelectItem}
                              handleIncreaseItemQuantity={
                                handleIncreaseItemQuantity
                              }
                              handleDecreaseItemQuantity={
                                handleDecreaseItemQuantity
                              }
                            />
                          </Element>
                        );
                      })}
                    </SubOptionWrapper>
                  </OptionWrapper>
                </Element>
              );
            }
          })}
      </Wrapper>
      {withHeaders && (
        <StyledButtonRow>
          <DoneButton onClick={handleCloseDrawerModal}>Done</DoneButton>
        </StyledButtonRow>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  selectedVariantId: state.productCustomizerReducer.selectedVariantId,
});

export default connect(mapStateToProps, actions)(OptionDrawersContainer);
