import {
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  SET_SIGNUP_FAIL_ERROR,
  CONFIRM_SMS_REQUEST,
  CONFIRM_SMS_SUCCESS,
  CONFIRM_SMS_FAILURE,
  TO_CONFIRM_SMS,
  RESET_REGISTRATION_STATES,
} from "./registrationTypes";

const initialState = {
  requestingCreateAccount: false,
  step: 1,
  confirmSMSIsSuccessful: false,
  requestingConfirmSMS: false,
  signUpFailureError: null,
  registrationError: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_REGISTERATION_ERROR_MESSAGE":
      return {
        ...state,
        registrationError: action.errors,
      };
    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        requestingCreateAccount: true,
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        requestingCreateAccount: false,
        createAccountIsSuccessful: true,
      };
    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        requestingCreateAccount: false,
        signUpFailureError: action.error,
      };
    case SET_SIGNUP_FAIL_ERROR:
      return {
        ...state,
        signUpFailureError: action.error,
      };
    case CONFIRM_SMS_REQUEST:
      return {
        ...state,
        requestingConfirmSMS: true,
      };
    case CONFIRM_SMS_SUCCESS:
      return {
        ...state,
        requestingConfirmSMS: false,
        confirmSMSIsSuccessful: true,
      };
    case CONFIRM_SMS_FAILURE:
      return {
        ...state,
        requestingConfirmSMS: false,
      };
    case TO_CONFIRM_SMS:
      return {
        ...state,
        step: 2,
      };
    case RESET_REGISTRATION_STATES:
      return initialState;
    default:
      return state;
  }
}
