import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import SizeDrawer from './SizeDrawer';
import OptionDrawersContainer from './OptionDrawers/OptionDrawersContainer';
import OptionSummaryContainer from './OptionSummary/OptionSummaryContainer';
import { Dialog, Slide } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../productCustomizerActions';
import appConfig from '../../../../app-config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const InstructionWrapper = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;
const InstructionInput = styled.input`
  width: 100%;
  overflow: hidden;
  height: auto;
  resize: none;
  margin: 0 8px 5px 8px;
  padding: 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
    color: var(--colors-basic-dark);
  }
  &::placeholder {
    color: #737a87;
  }
`;
const Line = styled.hr`
  width: 100%;
  color: #c1c1c1;
`;
const DrawersContainer = ({
  flatProduct,
  isDrawerModalOpen,
  setDrawerModalOpen,
  isCustomizingProductForCombo,
  addOrderInstruction,
  combo,
}) => {
  const posClientList = [
    'mary_browns',
    'opa',
    'madradish',
    'iQ',
    'balzacs',
    'grounds',
    'loadedpierogi',
    'basilbox',
    'madradish_catering',
  ];
  const [targetOptionId, setTargetOptionId] = useState();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleOpenDrawerModal = (optionId) => {
    setDrawerModalOpen(true);
    setTargetOptionId(optionId);
  };

  const handleCloseDrawerModal = () => {
    setDrawerModalOpen(false);
  };

  const debounceWindowWidth = () => {
    let timer;
    return (event) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => setWindowWidth(window.innerWidth), 100, event);
    };
  };

  useEffect(() => {
    window.addEventListener('resize', debounceWindowWidth());

    return () => {
      window.removeEventListener('resize', debounceWindowWidth());
    };
  }, []);
  const [instruction, setInstruction] = useState('');
  useEffect(() => {
    if (flatProduct.instruction && instruction === '') {
      setInstruction(flatProduct.instruction);
    }
  }, [instruction]);
  const [rows, setRows] = useState(1);
  const [prevRows, setPrevRows] = useState(1);
  const handleChange = (e) => {
    if (e.target.value.length < 180) {
      setInstruction(e.target.value);
      addOrderInstruction(e.target.value);
    }
    const textareaLineHeight = 20;
    const previousRows = e.target.rows;
    e.target.rows = 1;
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows > prevRows) {
      setPrevRows((prevRows) => prevRows + 1);
      e.target.rows = rows;
      setRows((rows) => rows + 1);
    } else if (currentRows < prevRows) {
      setPrevRows((prevRows) => prevRows - 1);
      setRows((rows) => rows - 1);
      e.target.rows = rows;
    }
  };
  return (
    <div>
      {flatProduct.prices.length >= 1 && <SizeDrawer />}
      {flatProduct.options && (
        <Fragment>
          {windowWidth <= 480 ? (
            <div>
              <OptionSummaryContainer
                flatProduct={flatProduct}
                handleOpenDrawerModal={handleOpenDrawerModal}
              />
            </div>
          ) : (
            <OptionDrawersContainer
              flatProduct={flatProduct}
              isCustomizingProductForCombo={isCustomizingProductForCombo}
              combo={combo}
            />
          )}
          {!isCustomizingProductForCombo &&
            !posClientList.includes(appConfig.appSource) && (
              <InstructionWrapper
                marginTop={window.innerWidth <= 480 ? '25px' : '10px'}
                marginBottom={window.innerWidth <= 480 ? '160px' : '60px'}
              >
                <InstructionInput
                  placeholder="Additional Notes (Optional)"
                  as="textarea"
                  onChange={handleChange}
                  value={instruction}
                  rows={rows}
                />
                <Line size="2" />
              </InstructionWrapper>
            )}
          {windowWidth <= 480 && (
            <Dialog
              aria-labelledby="edit-product-options-modal"
              aria-describedby="Edit Product Options"
              fullScreen={true}
              TransitionComponent={Transition}
              open={isDrawerModalOpen || false}
              maxWidth={'md'}
              fullWidth={true}
            >
              {flatProduct.options && (
                <OptionDrawersContainer
                  flatProduct={flatProduct}
                  withHeaders
                  handleCloseDrawerModal={handleCloseDrawerModal}
                  targetOptionId={targetOptionId}
                  isCustomizingProductForCombo={isCustomizingProductForCombo}
                />
              )}
            </Dialog>
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDrawerModalOpen: state.productCustomizerReducer.isDrawerModalOpen,
});

export default connect(mapStateToProps, actions)(DrawersContainer);
