import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import SubItemRow from './SubItemRow';
import {PlusIcon, MinusIcon} from '../../../../../components/Icons';
import {Subhead, Body} from '../../../../../components/Typography';
import CircleCheck from './CircleCheck';
import CalorieString from '../../../../../components/CalorieString';
import Collapse from '@material-ui/core/Collapse';
import getSelectedSubItemQuantity from '../../../utils/getSelectedSubItemQuantity';
import appConfig from '../../../../../../app-config';
import QuantityCheck from './QuantityCheck';
import getItemPrice from '../../../utils/getItemPrice';

const ItemLabel = styled(Body)`
  color: ${props => (props.disabled ? '#a6a9aa' : props.theme.bodyColor)};
`;

const ItemRowWrapper = styled.div`
  background: white;
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  &:hover {
    background: ${props =>
      props.disabled ? null : props.theme.itemRowBackgroundColor};
    transition: all 0.25s ease;
  }
`;

const ItemRow = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: ${props =>
    props.image && !props.isNoImage ? '40px 1fr 64px' : '40px 1fr'};
  pointer-events: ${props => (props.disabled ? 'none' : null)};
`;

const ImageWrapper = styled.div`
  overflow: hidden;
`;

const ItemImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: ${props => props.theme.lightGray};
  object-fit: cover;
`;

const ItemInfo = styled.div`
  align-self: center;
`;

const ItemPrice = styled(Subhead)`
  color: ${props => props.theme.secondaryColor};
  font-weight: normal;
`;

const QuantitySectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 8px 4px 8px;
`;

const QuantitySection = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 20px;
`;

const ItemQuantity = styled.div`
  align-self: center;
`;

const SubItemWrapper = styled.div`
  margin: 10px 0 10px 40px;
`;

const Dot = styled.span`
  margin: 0 5px;
  color: ${props => props.theme.gray};
  ::after {
    content: '•';
  }
`;

const InfoDescription = styled.p`
  color: ${props => props.theme.secondaryColor};
  font-weight: normal;
`;

const PriceAndCalories = styled.div``;

const ItemRowComponent = ({
  flatProduct,
  parentOption,
  option,
  item,
  handleToggleSubItemQuantity,
  handleIncreaseItemQuantity,
  handleSelectItem,
  handleDecreaseItemQuantity,
  selectedVariantId,
  setOpenItem,
  openItem,
}) => {
  const {subItemsById} = flatProduct;

  const toggleSelf = () => {
    setOpenItem(item.item_id === openItem ? false : item.item_id);
  };

  const optionMaximumPick = parentOption
    ? parentOption.maximum_pick
    : option.maximum_pick;

  const handleItemClick = () => {
    if (!item.sub_items) {
      if (optionMaximumPick === 1) {
        handleSelectItem(item, option, parentOption || option);
      } else if (!item.quantity) {
        handleIncreaseItemQuantity(item, parentOption || option);
        if (item.maximum_pick !== 1) {
          toggleSelf();
        }
      } else if (item.quantity && item.maximum_pick === 1) {
        handleDecreaseItemQuantity(item);
      } else {
        toggleSelf();
      }
    } else {
      toggleSelf();
    }
  };

  const handleItemIncreaseClick = () => {
    handleIncreaseItemQuantity(item, parentOption || option);
  };

  const handleItemDescreaseClick = () => {
    if (item.quantity) {
      if (item.quantity === 1) {
        toggleSelf();
      }
      handleDecreaseItemQuantity(item);
    }
  };

  const itemQuantity =
    item.sub_items &&
    item.sub_items.some(subItemId => {
      const subItem = subItemsById[subItemId];
      return subItem.quantity;
    })
      ? getSelectedSubItemQuantity(subItemsById, item)
      : item.quantity;

  const hasSubItems = item.sub_items && item.sub_items.length > 0;

  const itemPrice = getItemPrice(option, item, flatProduct);

  const calories =
    item.calories &&
    item.calories.find(calorie => calorie.variant_id === selectedVariantId);

  return (
    <ItemRowWrapper disabled={!item.is_available}>
      <ItemRow
        onClick={handleItemClick}
        image={item.image_url}
        disabled={!item.is_available}
        isNoImage={appConfig.isNoImage}
      >
        <ItemQuantity id="ProductCustomizer-ItemQuantity">
          {!hasSubItems && optionMaximumPick === 1 && (
            <CircleCheck checked={item.quantity === 1} />
          )}
          {optionMaximumPick > 1 && (
            <QuantityCheck
              maximumPick={hasSubItems ? null : item.maximum_pick}
              quantity={itemQuantity}
            />
          )}
        </ItemQuantity>
        <ItemInfo>
          <ItemLabel
            id="ProductCustomizer-ItemName"
            disabled={!item.is_available}
          >
            {item.name}
          </ItemLabel>
          { item.user_description && (
          <InfoDescription>
            {item.user_description}
          </InfoDescription>
          )}
          <PriceAndCalories>
            {itemPrice !== 0 && (
              <ItemPrice disabled={!item.is_available}>
                {numeral(itemPrice).format('$0.00')}
              </ItemPrice>
            )}
            {itemPrice !== 0 && calories && <Dot />}
            {calories && (
              <CalorieString
                id="ProductCustomizer-ItemCalories"
                caloriesFontColor="gray"
                caloriesFontSize="15px"
                calories={calories}
              />
            )}
          </PriceAndCalories>
        </ItemInfo>
        {item.image_url && !appConfig.isNoImage && (
          <ImageWrapper>
            <ItemImage src={item.image_url} />
          </ImageWrapper>
        )}
      </ItemRow>
      <Collapse in={openItem === item.item_id}>
        {item.sub_items && item.sub_items.length ? (
          <SubItemWrapper>
            {item.sub_items.map(subItemId => {
              const subItem = subItemsById[subItemId];
              return (
                <SubItemRow
                  key={subItem.item_id}
                  option={option}
                  parentOption={parentOption}
                  subItem={subItem}
                  item={item}
                  handleToggleSubItemQuantity={handleToggleSubItemQuantity}
                />
              );
            })}
          </SubItemWrapper>
        ) : (
          item.maximum_pick > 1 && (
            <QuantitySectionWrapper>
              <QuantitySection>
                <div
                  id="ProductCustomizer-ItemDecrease"
                  onClick={handleItemDescreaseClick}
                >
                  <MinusIcon />
                </div>
                {item.quantity}
                <div
                  id="ProductCustomizer-ItemIncrease"
                  onClick={handleItemIncreaseClick}
                >
                  <PlusIcon />
                </div>
              </QuantitySection>
            </QuantitySectionWrapper>
          )
        )}
      </Collapse>
    </ItemRowWrapper>
  );
};

export default ItemRowComponent;
