import styled from 'styled-components';

const Callout = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.theme.clickableTextColor};
  text-transform: ${props => props.textTransform || 'unset'};
  margin: 0;
  font-family: ${props => props.theme.primaryFontFontFamily};
`;

export default Callout;
