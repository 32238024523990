import productForOrder from "./productForOrder"

export default combos => {
  return combos.map(combo => {
    const configProducts = combo.products.reduce((acc, product) => {
      return [...acc, ...productForOrder(product).config_products]
    }, [])

    return {
      combo_id: combo.id,
      config_products: configProducts
    }
  }) 
}
