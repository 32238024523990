import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Body, Caption1 } from '../../../../components/Typography';
import Select from '../../../../components/Select';
import formatAddressName from '../../../../utils/formatAddressName';
import OrderFutureSchedule from './OrderFutureSchedule';
import UnavailableMessage from './UnavailableMessage';
import ConfirmButton from './ConfirmButton';
import UnavailableProducts from './UnavailableProducts';
import {
  renderConfirmButtonText,
  isConfirmButtonDisabled,
} from '../utils/helper';
import appConfig from '../../../../../app-config';
import SkeletonLoading from './SkeletonLoading';
import UnavailableMessageWithButton from './UnavailableMessageWithButton';
import { isScheduleRadioDisabledCalculated } from '../utils/helper';
import DeliveryOrderMessage from './DeliveryOrderMessage';
import GuestDeliveryAddressForm from './GuestDeliveryAddressForm';
import { updateInterPickUpDetails } from '../../orderSettingActions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const SelectTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const GuestDeliveryForm = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const AddNewButton = styled(Caption1)`
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const Delivery = ({
  cartItems,
  deliveryAddressesList,
  updateInterDeliveryDetails,
  interDeliveryDetails,
  interSelectedDateTime,
  updateInterSelectedDateTime,
  interOrderType,
  unavailableProducts,
  availableTimes,
  getTimeIntervalsDate,
  resetInterSelectedDateTime,
  selectedDateTime,
  isRequesting,
  confirmDeliveryDetails,
  setDeliveryAddressesFormIsVisible,
  isTimeNoLongerValid,
  resetIsTimeNoLongerValid,
  isAuthenticated,
  handleConfirmDeliveryButton,
  specialHours,
  orderPacingData,
  orderSettingModalIsVisible,
  refine,
  cartErrorMessage,
  deliveryToAddress,
  deepLinkParameters,
  guestDeliveryFormInitialAddress,
  setGuestDeliveryAddressFormInitialValues,
  appConfig,
  cartReducer,
  validateCartForLocation,
  checkUnavailableProductForPickUp,
  getMenu,
  updateInterPickUpDetails,
  updateOrderSettingModalIsVisible,
  locations,
  emptyCart,
}) => {
  const deliveryBusiness = interDeliveryDetails
    ? interDeliveryDetails.deliveryBusiness
    : null;

  const maxFutureOrderDate =
    interDeliveryDetails &&
    interDeliveryDetails.settings &&
    interDeliveryDetails.settings.max_future_order_date;

  const orderEndTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);

  const isAsapRadioDisabled = !(
    interDeliveryDetails &&
    specialHours &&
    deliveryBusiness &&
    deliveryBusiness.accepting_orders &&
    !interDeliveryDetails.settings.online
  );

  const isScheduleRadioDisabled = !(
    availableTimes &&
    maxFutureOrderDate &&
    orderEndTime &&
    specialHours &&
    !isScheduleRadioDisabledCalculated(
      availableTimes,
      maxFutureOrderDate,
      orderEndTime,
      specialHours,
      interDeliveryDetails.settings.online
    )
  );
  const [deliveryLocation, setDeliveryLocation] = useState(null);

  useEffect(() => {
    if (!orderSettingModalIsVisible) {
      setDeliveryLocation(null);
    }
  }, [orderSettingModalIsVisible]);

  const emptySearchBox = () => {
    if (appConfig.isGlobalSearchEnabled) {
      refine('');
    }
  };

  useEffect(() => {
    if (
      interSelectedDateTime.radioValue?.value === 'asap' ||
      (interSelectedDateTime.dateTime.date &&
        interSelectedDateTime.dateTime.time.value)
    )
      if (interDeliveryDetails && interDeliveryDetails.deliveryBusiness)
        getMenu(interDeliveryDetails.deliveryBusiness.id, interOrderType);
  }, [
    interSelectedDateTime.dateTime.date,
    interSelectedDateTime.dateTime.time.value,
  ]);

  useEffect(() => {
    if (deliveryToAddress) {
      const address = deliveryAddressesList.filter(
        (addr) => addr.line1 === deliveryToAddress.line1
      );
      if (address.length > 0) {
        setDeliveryLocation(address[0]);
        updateInterDeliveryDetails(address[0]);
      }
    }
  }, [deliveryToAddress, deliveryAddressesList]);

  useEffect(() => {
    if (deepLinkParameters?.id) {
      const address = deliveryAddressesList.filter(
        (addr) => addr.id === parseInt(deepLinkParameters.id)
      );
      if (address.length > 0) {
        setDeliveryLocation(address[0]);
      }
    }
  }, [deepLinkParameters, deliveryAddressesList]);

  useEffect(() => {
    if (
      (interSelectedDateTime.radioValue.value === 'asap' ||
        interSelectedDateTime.radioValue.value === 'schedule') &&
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness
    ) {
      checkUnavailableProductForPickUp(interDeliveryDetails.deliveryBusiness);
    }
  }, [interSelectedDateTime.radioValue]);

  const isValidChoice =
    interDeliveryDetails &&
    interDeliveryDetails.settings &&
    interSelectedDateTime
      ? (interSelectedDateTime.radioValue.value === 'asap' &&
          interDeliveryDetails.settings.is_asap_order_enabled) ||
        (interSelectedDateTime.radioValue.value === 'schedule' &&
          interDeliveryDetails.settings.is_future_order_enabled)
      : false;

  return (
    <Wrapper>
      {isAuthenticated ? (
        <>
          <SelectTitleWrapper>
            <Body style={{ fontWeight: 'bold' }}>Delivery to:</Body>
            <div style={{ marginRight: 'auto' }}>
              <AddNewButton
                onClick={() => {
                  setDeliveryAddressesFormIsVisible(true);
                }}
              >
                + Add New Address
              </AddNewButton>
            </div>
          </SelectTitleWrapper>
          <div>
            <Select
              value={interDeliveryDetails}
              options={deliveryAddressesList}
              getOptionLabel={(deliveryAddress) =>
                formatAddressName(deliveryAddress)
              }
              getOptionValue={(deliveryAddress) => deliveryAddress}
              onChange={(deliveryAddress) => {
                updateInterDeliveryDetails(deliveryAddress);
                resetInterSelectedDateTime();
                resetIsTimeNoLongerValid();
              }}
              isDisabled={isRequesting}
            />
          </div>
        </>
      ) : appConfig && appConfig.guest_checkout_enabled ? (
        <GuestDeliveryForm>
          <Body style={{ fontWeight: 'bold' }}>Delivery to:</Body>
          <GuestDeliveryAddressForm
            guestDeliveryFormInitialAddress={guestDeliveryFormInitialAddress}
            updateInterDeliveryDetails={updateInterDeliveryDetails}
            setGuestDeliveryAddressFormInitialValues={
              setGuestDeliveryAddressFormInitialValues
            }
          />
        </GuestDeliveryForm>
      ) : (
        <SelectTitleWrapper>
          <Body style={{ fontWeight: 'bold' }}>Delivery to:</Body>
          <div style={{ marginRight: 'auto' }}>
            <AddNewButton
              onClick={() => {
                setDeliveryAddressesFormIsVisible(true);
              }}
            >
              + Add New Address
            </AddNewButton>
          </div>
        </SelectTitleWrapper>
      )}

      {isRequesting ? (
        <SkeletonLoading />
      ) : (
        <Fragment>
          {interDeliveryDetails && !interDeliveryDetails.isDeliveryValid ? (
            <UnavailableMessage
              onClick={() => confirmDeliveryDetails(true)}
              title={'We are unable to deliver to this address'}
            />
          ) : interDeliveryDetails &&
            availableTimes &&
            deliveryBusiness &&
            deliveryBusiness.id &&
            (!isScheduleRadioDisabled || !isAsapRadioDisabled) ? (
            !deliveryBusiness.is_order_ahead_enabled ? (
              <UnavailableMessageWithButton
                onClick={() => {
                  confirmDeliveryDetails(true);
                  emptySearchBox();
                }}
                title="We are unable to deliver to this address at this time"
              />
            ) : (
              <>
                {interDeliveryDetails &&
                  interDeliveryDetails.settings &&
                  !interDeliveryDetails.settings.online &&
                  deliveryBusiness.accepting_orders &&
                  (!isScheduleRadioDisabled || !isAsapRadioDisabled) && (
                    <div>
                      <UnavailableMessageWithButton
                        onClick={() => {
                          confirmDeliveryDetails(true);
                          emptySearchBox();
                        }}
                        title="Your nearest location is unable to accept orders at the moment"
                      />
                    </div>
                  )}
              </>
            )
          ) : null}
          {interDeliveryDetails && interDeliveryDetails.deliveryBusiness ? (
            <OrderFutureSchedule
              is_future_order_enabled={
                interDeliveryDetails && interDeliveryDetails.deliveryBusiness
                  ? interDeliveryDetails.deliveryBusiness
                      .is_future_order_enabled
                  : null
              }
              is_asap_order_enabled={
                interDeliveryDetails && interDeliveryDetails.deliveryBusiness
                  ? interDeliveryDetails.deliveryBusiness.is_asap_order_enabled
                  : null
              }
              interDeliveryDetails={interDeliveryDetails}
              updateInterDeliveryDetails={updateInterDeliveryDetails}
              interOrderType={interOrderType}
              interSelectedDateTime={interSelectedDateTime}
              updateInterSelectedDateTime={updateInterSelectedDateTime}
              confirmDeliveryDetails={confirmDeliveryDetails}
              availableTimes={availableTimes}
              selectedDateTime={selectedDateTime}
              maxFutureOrderDate={
                interDeliveryDetails && interDeliveryDetails.settings
                  ? interDeliveryDetails.settings.max_future_order_date
                  : null
              }
              selectedLocation={interDeliveryDetails}
              deliveryBusiness={deliveryBusiness}
              isTimeNoLongerValid={isTimeNoLongerValid}
              resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
              isLocationOrDelivBusinesssAcceptingOrders={
                deliveryBusiness ? deliveryBusiness.accepting_orders : null
              }
              getTimeIntervalsDate={getTimeIntervalsDate}
              specialHours={specialHours}
              orderPacingData={orderPacingData}
              orderMessage={appConfig.orderMessageDelivery}
              isOnline={
                interDeliveryDetails && interDeliveryDetails.settings
                  ? interDeliveryDetails.settings.online
                  : null
              }
              emptySearchBox={() => emptySearchBox()}
              deepLinkParameters={deepLinkParameters}
              onSeeMenu={() => {
                if (locations && locations.length > 0) {
                  updateInterPickUpDetails(locations[0]);
                  updateOrderSettingModalIsVisible(false);
                }
                emptySearchBox();
              }}
              cartReducer={cartReducer}
              emptyCart={emptyCart}
            />
          ) : null}
          {deliveryBusiness ? (
            <DeliveryOrderMessage
              deliveryBusiness={deliveryBusiness}
              isOrderSetting
            />
          ) : null}

          {cartErrorMessage && cartErrorMessage.length ? (
            <UnavailableProducts
              unavailableProducts={unavailableProducts}
              cartErrorMessage={cartErrorMessage}
            />
          ) : null}

          <ConfirmButton
            text="Confirm"
            onClick={() => {
              handleConfirmDeliveryButton();
              window.scrollTo(0, 0);
            }}
            disabled={
              !isValidChoice ||
              !interDeliveryDetails ||
              !deliveryBusiness ||
              isConfirmButtonDisabled(
                interSelectedDateTime,
                appConfig.isFutureOrderDelivery,
                deliveryBusiness
              ) ||
              !interDeliveryDetails.isDeliveryValid ||
              !interDeliveryDetails?.settings?.online ||
              (!interDeliveryDetails?.settings?.is_asap_order_enabled &&
                !interDeliveryDetails?.settings?.is_future_order_enabled)
            }
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default Delivery;
