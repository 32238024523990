export const calculateMinimumSelectionRequired = flatProduct => {
  const totalMinimumPick = flatProduct.options.reduce((count, optionId) => {
    const option = flatProduct.optionsById[optionId];
    if (option.sub_options && option.sub_options.length) {
      return (
        count +
        option.sub_options.reduce((count, subOptionId) => {
          const subOption = flatProduct.subOptionsById[subOptionId];

          return count + subOption.minimum_pick;
        }, 0)
      );
    } else {
      return count + option.minimum_pick;
    }
  }, 0);

  return totalMinimumPick;
};

export default calculateMinimumSelectionRequired;
