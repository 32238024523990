import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { PrimaryButton, ButtonRow } from '../../../../components/Buttons';
import { PICKUP, DELIVERY, DROPOFF } from '../../orderSettingTypes';
import appConfigFile from '../../../../../app-config';

const ButtonWrapper = styled.div`
  border-radius: 24px;
  height: 38px;
  background-color: ${(props) => props.theme.lightGray};
  white-space: nowrap;
  background: ${(props) => props.theme.lightGray};
  display: flex;
  width: ${(props) =>
    appConfigFile.isParentChildClient
      ? '250px'
      : props.interOrderType === 'delivery' &&
        appConfigFile.isMarketplaceDelivery &&
        '380px'};
  justify-content: center;
  align-items: center;
  @media (max-width: 785px) {
    width: auto;
  }
`;

const StyledButton = styled(PrimaryButton)`
  flex: 1 1 0px;
  border-radius: 24px;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) => !props.isSelected && 'transparent'};
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? props.theme.primaryButtonFontColor : '#000'};
  text-align: center;
  font-weight: 600;
  display: inline-block;
  min-width: auto;

  & div {
    color: ${(props) =>
      props.isSelected
        ? props.theme.primaryButtonFontColor
        : props.disabled
        ? '#69768c'
        : '#000'};
    font-weight: bold;
    opacity: ${(props) => props.disabled && '0.5'};
  }

  &:hover {
    background-color: ${(props) =>
      props.isSelected && !props.disabled
        ? props.theme.primaryButtonHoverColor
        : 'inherit'};
    color: ${(props) => !props.isSelected && '#69768c'};
  }

  &:hover {
    background-color: ${(props) =>
      props.isSelected ? props.theme.primaryButtonHoverColor : 'inherit'};
    color: ${(props) =>
      props.isSelected ? props.theme.primaryButtonTextHoverColor : '#000000'};
  }
  &:focus {
    outline: 0;
  }
`;

const OrderMethodSelection = ({
  interOrderType,
  updateInterOrderType,
  resetDateTimes,
  resetInterSelectedDateTime,
  deepLinkParameters,
  appConfig,
}) => {
  useEffect(() => {
    if (
      deepLinkParameters?.type === DELIVERY &&
      (interOrderType === DELIVERY || !interOrderType)
    ) {
      updateInterOrderType(DELIVERY);
      resetDateTimes();
      resetInterSelectedDateTime();
    } else if (
      deepLinkParameters?.type === DROPOFF &&
      appConfigFile.isDropOffEnabled
    ) {
      updateInterOrderType(DROPOFF);
      resetDateTimes();
      resetInterSelectedDateTime();
    } else if (
      deepLinkParameters?.type === PICKUP &&
      (interOrderType === PICKUP || !interOrderType)
    ) {
      updateInterOrderType(PICKUP);
      resetDateTimes();
      resetInterSelectedDateTime();
    }
  }, []);

  return (
    <ButtonWrapper interOrderType={interOrderType}>
      {appConfig && appConfig.accepted_order_types.delivery && (
        <StyledButton
          small
          isSelected={interOrderType === DELIVERY}
          onClick={() => {
            updateInterOrderType(DELIVERY);
            resetDateTimes();
            resetInterSelectedDateTime();
          }}
        >
          Delivery
        </StyledButton>
      )}
      {appConfig && appConfig.accepted_order_types.pickup && (
        <StyledButton
          small
          isSelected={interOrderType === PICKUP}
          onClick={() => {
            updateInterOrderType(PICKUP);
            resetDateTimes();
            resetInterSelectedDateTime();
          }}
        >
          Pick Up
        </StyledButton>
      )}
      {appConfig && appConfig.accepted_order_types.drop_off && (
        <StyledButton
          small
          isSelected={interOrderType === DROPOFF}
          onClick={() => {
            updateInterOrderType(DROPOFF);
            resetDateTimes();
            resetInterSelectedDateTime();
          }}
        >
          Dine-in
        </StyledButton>
      )}
    </ButtonWrapper>
  );
};

export default OrderMethodSelection;
