import React, { useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import {
  updateOrderSettingModalIsVisible,
  getChildLocations,
  setIsStoreDeepLink,
  setIsParentStoreDeepLink,
  setParentLocation,
  updateInterOrderType,
  updateInterPickUpDetails,
  updateInterDeliveryDetails,
  updateInterDropOffDetails,
  updateOrderType,
  updateSelectedLocation,
  setIsMenuDisabled,
} from '../modules/OrderSetting/orderSettingActions';
import {
  PICKUP,
  DELIVERY,
  DROPOFF,
} from '../modules/OrderSetting/orderSettingTypes';
import appConfig from '../../app-config';
import { getMenu } from '../modules/OnAppLoad/Menu/menuActions';
import { getCombos } from '../modules/Combo/comboActions';

function useDeepLink() {
  const dispatch = useDispatch();
  const urlQuery = useLocation();
  useEffect(() => {
    if (urlQuery.search.startsWith('?'))
      localStorage.setItem('deep-link-url', urlQuery.search);
  }, [urlQuery?.search]);
  const { locations } = useSelector((state) => state.locationsReducer);
  const {
    childLocations,
    parentLocation,
    interOrderType,
    isParentDeepLink,
    isDeepLink,
  } = useSelector((state) => state.orderSettingReducer);

  const findAndSetParentLocation = (parentId) => {
    if (locations) {
      const parentStore = locations.find(
        (location) => location.id === parseInt(parentId)
      );
      if (parentStore) {
        dispatch(setParentLocation(parentStore));
        dispatch(setIsParentStoreDeepLink(true));
        dispatch(setIsMenuDisabled(true));
        dispatch(updateSelectedLocation(null));

        if (!childLocations) dispatch(getChildLocations(parentId));
      }
    }
  };

  const findAndSetChildLocation = (storeId, orderType) => {
    if (childLocations) {
      const store = childLocations.find(
        (location) => location.id === parseInt(storeId)
      );
      if (store) {
        dispatch(updateSelectedLocation(store));
        dispatch(updateInterOrderType(orderType));
        dispatch(getMenu(storeId, orderType));
        dispatch(getCombos(storeId));
      }
      if (store && orderType === PICKUP) {
        dispatch(updateInterPickUpDetails(store, new Date().toString()));
        dispatch(setIsMenuDisabled(true));
        dispatch(setIsStoreDeepLink(true));
        dispatch(updateOrderSettingModalIsVisible(false));
      } else if (store && orderType === DELIVERY) {
        // dispatch(updateInterDeliveryDetails(store, new Date().toString()));
        dispatch(setIsMenuDisabled(true));
        dispatch(setIsStoreDeepLink(true));
        dispatch(updateOrderSettingModalIsVisible(false));
      } else if (store && orderType === DROPOFF) {
        dispatch(updateInterDropOffDetails(store, new Date().toString()));
        dispatch(setIsStoreDeepLink(true));
        dispatch(updateOrderSettingModalIsVisible(false));
      }
    }
  };

  const verifyURL = useCallback(() => {
    if (urlQuery && urlQuery.search && appConfig.isDeepLinkClient) {
      const query = queryString.parse(urlQuery.search);

      if (query.parentId && !isParentDeepLink) {
        findAndSetParentLocation(query.parentId);
        if (!query.storeId) dispatch(updateOrderSettingModalIsVisible(true));
      }

      if (query.storeId && !isDeepLink) {
        if (!query.type) dispatch(updateInterOrderType(query.type));
        findAndSetChildLocation(query.storeId, query.type);
      }

      if (query.type && !isParentDeepLink) {
        dispatch(updateInterOrderType(query.type));
        dispatch(updateOrderType(query.type));
      }
    } else {
      const selectedDateTime = {
        radioValue: 'asap',
        dateTime: {
          date: { value: new Date().toString() },
        },
      };

      dispatch({
        type: 'UPDATE_INTER_SELECTED_DATE_TIME',
        selectedDateTime,
      });
    }
  }, [locations, childLocations]);

  useEffect(() => {
    verifyURL();
  }, [verifyURL]);
}

export default useDeepLink;
