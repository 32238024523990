import React, { Fragment } from 'react';
import styled from 'styled-components';
import Title2 from '../../../components/Typography/Title2';
import Body from '../../../components/Typography/Body';
import StepIndicator from './StepIndicator';
import RegistrationFormContainer from '../RegistrationForm/RegistrationFormContainer';
import ConfirmSMSFormContainer from '../ConfirmSMSForm/ConfirmSMSFormContainer';
import GivexLoyaltyContainer from '../../Loyalty/GivexLoyaltyModal/GivexLoyaltyContainer';

const Wrapper = styled.div`
  width: 100%;
  max-width: 660px;
  background: white;
  margin: 0;
  @media (max-width: 786px) {
    padding: 24px 0px;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);

  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const Registration = ({
  activeStep,
  handleBackButtonClick,
  resetCheckedEmail,
  hideAuthenticationModal,
  updatePhoneNumber,
  setDeliveryAddressesFormIsVisible,
}) => {
  return (
    <Wrapper>
      {activeStep === 3 && (
        <TitleWrapper>
          (<Title2 id="Loyalty-Title">LOYALTY PROGRAM</Title2>)
        </TitleWrapper>
      )}
      <StepIndicator activeStep={activeStep} />
      {activeStep === 1 && (
        <RegistrationFormContainer resetCheckedEmail={resetCheckedEmail} />
      )}
      {activeStep === 2 && (
        <Fragment>
          <Body id="Registration-Message">
            We texted a verification code to the phone number you provided in
            order to ensure a secure sign-up
          </Body>
          <ConfirmSMSFormContainer
            handleBackButtonClick={handleBackButtonClick}
            updatePhoneNumber={updatePhoneNumber}
          />
        </Fragment>
      )}
      {activeStep === 3 && (
        <GivexLoyaltyContainer
          hideAuthenticationModal={hideAuthenticationModal}
        />
      )}
    </Wrapper>
  );
};

export default Registration;
