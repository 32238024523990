import styled from 'styled-components';

const Caption2 = styled.p`
  font-size: 12px;
  font-weight: ${props => props.fontWeight || 'normal'};
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || props.theme.bodyColor};
  text-transform: ${props => props.textTransform || 'unset'};
  margin: 0;
  letter-spacing: 0px;
  font-family: ${props => props.theme.secondaryFontFontFamily};
`;

export default Caption2;
