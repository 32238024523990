import React from 'react';
import styled from 'styled-components';

const SubItem = styled.span`
  margin-right: 12px;
  border-radius: 17.5px;
  background-color: ${props =>
    props.disabled
      ? props.theme.lightGray
      : props.selected
      ? props.theme.primaryButtonBackgroundColor
      : '#ecf0f1'};
  color: ${props =>
    props.selected
      ? props.theme.primaryButtonFontColor
      : props.theme.categoriesBarFontColor};
  padding: 8px 16px;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
`;

const SubItemRowComponent = ({
  parentOption,
  option,
  item,
  subItem,
  handleToggleSubItemQuantity,
}) => {
  return (
    <SubItem
      id="ProductCustomizer-SubItem"
      onClick={() =>
        handleToggleSubItemQuantity(subItem, item, parentOption || option)
      }
      disabled={!subItem.is_available}
      selected={subItem.quantity === 1}
    >
      {subItem.name}
    </SubItem>
  );
};

export default SubItemRowComponent;
