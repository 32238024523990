  
  const initialState = {
    changeAccountIsVisible: false,
    changePasswordErrors:{},   
  };
  
  export default function(state = initialState, action) {
    
    switch (action.type) {
        case "SET_CHANGE_ACCOUNT_IS_VISIBLE":
        return {
          ...state,
          changeAccountIsVisible: action.payload,
        };
        case 'SET_DELETE_ACCOUNT_ERROR_MESSAGE':
      return {
        ...state,
        changePasswordErrors: action.errors,
      };
      default:
        return state;
    }
  }
  