import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Title1, Title2, Headline } from '../../../../components/Typography';
import { TextField } from '@material-ui/core';
import { PrimaryButton } from '../../../../components/Buttons';
import { getCountries } from '../../../../utils/parseProvinceAbbr';
import Checkbox from '../../../../components/Form/Checkbox';
import appConfig from '../../../../../app-config';

const Wrapper = styled.div`
  padding: 32px;
  max-width: 600px;
  @media (min-width: 786px) {
    width: 600px;
  }
`;
const AddressLabel = styled.label`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`;
const EnterAddressInput = styled.input`
  width: 100%;
  border: none;
  height: 30px;
  padding: 8px;
  font-size: 16px;
  border-bottom: 1px solid ${(props) => props.theme.darkGray};
  &:focus {
    outline: none;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const FormWrapper = styled.div`
  position: relative;
`;

const DeliveryOptionsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 2em;
  margin-top: 20px;
`;

const Delete = styled(Headline)`
  color: ${(props) =>
    props.disabled
      ? props.theme.primaryButtonDisabledColor
      : props.theme.black};
  align-self: center;
  cursor: pointer;
`;

const SaveButton = styled(PrimaryButton)`
  width: 50%;
  margin: auto;
  height: 56px;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const STREET_NUMBER = 'street_number';
const ROUTE = 'route';
const LOCALITY = 'locality';
const SUB_LOCALITY = 'sublocality';
const ADMINISTRATIVE_ADREA_LEVEL_1 = 'administrative_area_level_1';
const COUNTRY = 'country';
const POSTAL_CODE = 'postal_code';
const POSTAL_TOWN = 'postal_town';

const COUNTRIES = getCountries();

const DeliveryAddressesForm = ({
  setDeliveryAddressesFormIsVisible,
  saveDeliveryAddress,
  isRequesting,
  deliveryAddressesFormInitialValues,
  updateDeliveryAddress,
  deleteDeliveryAddress,
  classes,
  deliverToThisAddress,
  clearDeliveryAddressErrorMessage,
  setSelectedDeliveryAdress,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isDeliveryToAddressEnabled, setIsDeliveryToAddressEnabled] = useState(
    true
  );

  function isNumeric(str) {
    if (typeof str != 'string') return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  useEffect(() => {
    let input = document.getElementById('pac-input');

    if (deliveryAddressesFormInitialValues) {
      input.value = `${deliveryAddressesFormInitialValues.line1}, ${deliveryAddressesFormInitialValues.city}, ${deliveryAddressesFormInitialValues.state}, ${deliveryAddressesFormInitialValues.zip}, ${deliveryAddressesFormInitialValues.country}`;

      setSelectedAddress({
        line1: deliveryAddressesFormInitialValues.line1,
        city: deliveryAddressesFormInitialValues.city,
        state: deliveryAddressesFormInitialValues.state,
        zip: deliveryAddressesFormInitialValues.zip,
        country: deliveryAddressesFormInitialValues.country,
      });
    }

    let searchBox = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: {
        country: appConfig.countriesSupported
          ? appConfig.countriesSupported
          : 'ca',
      },
    });
    let searchBoxListener = searchBox.addListener('place_changed', () => {
      let place = searchBox.getPlace();

      if (!place) {
        setSelectedAddress(null);
        return;
      }

      const addressComponents = place.address_components;
      let streetNumber;
      let route;
      let locality;
      let administrativeAreaLevel1;
      let country;
      let postalCode;

      try {
        streetNumber = addressComponents.find(
          (component) =>
            component.types.includes(STREET_NUMBER) ||
            component.types.includes(ROUTE)
        );

        route = addressComponents.find((component) =>
          component.types.includes(ROUTE)
        );

        locality = addressComponents.find(
          (component) =>
            component.types.includes(LOCALITY) ||
            component.types.includes(SUB_LOCALITY) ||
            component.types.includes(POSTAL_TOWN)
        );

        administrativeAreaLevel1 = addressComponents.find((component) =>
          component.types.includes(ADMINISTRATIVE_ADREA_LEVEL_1)
        );

        postalCode = addressComponents.find((component) =>
          component.types.includes(POSTAL_CODE)
        );

        country = COUNTRIES.find(
          (c) =>
            c.name ===
            addressComponents.find((component) =>
              component.types.includes(COUNTRY)
            ).long_name
        ).abbreviation;
      } catch (e) {
        document.getElementById('pac-input').value = '';
      }
      const fullAddress = document.getElementById('pac-input').value;

      streetNumber = streetNumber
        ? streetNumber.long_name
        : isNumeric(fullAddress.split(' ')[0])
        ? fullAddress.split(' ')[0]
        : null;

      route = route ? route.long_name : null;
      locality = locality ? locality.long_name : null;
      postalCode = postalCode ? postalCode.long_name : null;
      administrativeAreaLevel1 = administrativeAreaLevel1
        ? administrativeAreaLevel1.long_name
        : null;

      const delivery_address = {
        line1:
          route !== streetNumber
            ? `${streetNumber} ${route}`
            : `${fullAddress.split(' ')[0]} ${route}`,
        city: locality || null,
        state: administrativeAreaLevel1,
        zip: postalCode,
        country: country,
      };

      setSelectedAddress(delivery_address);
    });
    clearDeliveryAddressErrorMessage();
    return () => {
      window.google.maps.event.removeListener(searchBoxListener);
    };
  }, []);

  useEffect(() => {
    if (isDeliveryToAddressEnabled) {
      deliverToThisAddress(selectedAddress, false);
    } else {
      deliverToThisAddress(null, true);
    }
  }, [selectedAddress, isDeliveryToAddressEnabled]);
  const handleSaveDeliveryAddress = (values, selectedAddress) => {
    deliveryAddressesFormInitialValues
      ? updateDeliveryAddress(
          {
            ...values,
            ...selectedAddress,
          },
          deliveryAddressesFormInitialValues.id
        )
      : saveDeliveryAddress({ ...values, ...selectedAddress });
  };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title1 style={{ fontWeight: '600' }}>
          {(deliveryAddressesFormInitialValues && 'Saved Address') ||
            'Add a Delivery Address'}
        </Title1>
        <CloseButton onClick={() => setDeliveryAddressesFormIsVisible(false)}>
          <i
            class="fas fa-times"
            style={{ fontSize: '24px', cursor: 'pointer' }}
          ></i>
        </CloseButton>
      </TitleWrapper>
      <Formik
        initialValues={
          deliveryAddressesFormInitialValues
            ? {
                type: deliveryAddressesFormInitialValues.type,
                line2: deliveryAddressesFormInitialValues.line2,
                details: deliveryAddressesFormInitialValues.details,
                is_preferred: deliveryAddressesFormInitialValues.is_preferred,
              }
            : {
                type: '',
                line2: '',
                details: '',
                is_preferred: true,
              }
        }
      >
        {({ values, setFieldValue, handleChange, handleBlur }) => (
          <FormWrapper>
            <div style={{ marginTop: '40px', marginBottom: '56px' }}>
              {selectedAddress && <AddressLabel> Address</AddressLabel>}
              <EnterAddressInput
                id="pac-input"
                class="controls"
                type="text"
                placeholder="Enter Address"
                onChange={() => {
                  setSelectedAddress(null);
                }}
              />
            </div>
            <DeliveryOptionsWrapper>
              <Title2>Delivery Options</Title2>
              <TextField
                id="DeliveryAddresses-AddressNickname"
                label="Address Nickname (E.g. Home)"
                name="type"
                type="text"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="DeliveryAddresses-AddressLine2"
                label="Apt/ Suite/ Floor"
                name="line2"
                type="text"
                value={values.line2}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="DeliveryAddresses-DeliveryNote"
                label="Delivery note"
                name="details"
                type="text"
                value={values.details}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  className: classes.input,
                }}
              />
              <Checkbox
                id="DeliveryAddresses-DeliverToThisAddress"
                label="Deliver to this address"
                name="is_preferred"
                checked={values.is_preferred}
                onChange={() => {
                  setIsDeliveryToAddressEnabled(!values.is_preferred);
                  setFieldValue('is_preferred', !values.is_preferred);
                }}
                onBlur={handleBlur}
              />
            </DeliveryOptionsWrapper>
            <ButtonWrapper>
              {deliveryAddressesFormInitialValues ? (
                <Delete
                  id="DeliveryAddresses-DeleteButton"
                  onClick={() =>
                    isRequesting
                      ? null
                      : deleteDeliveryAddress(
                          deliveryAddressesFormInitialValues.id
                        )
                  }
                  disabled={isRequesting}
                >
                  <i class="fas fa-trash-alt" /> Remove Address
                </Delete>
              ) : (
                <div />
              )}
              <SaveButton
                id="DeliveryAddresses-SaveButton"
                disabled={!selectedAddress}
                onClick={() =>
                  handleSaveDeliveryAddress(values, selectedAddress)
                }
              >
                Save
              </SaveButton>
            </ButtonWrapper>
          </FormWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};

export default withStyles(styles)(DeliveryAddressesForm);
