import React, {useState} from 'react';
import styled from 'styled-components';
import {Title2} from '../../../../components/Typography';
import ItemRow from './components/ItemRow';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: ${props => (props.hasSubOptions ? 'none' : 'inherit')};
    grid-column: span 2;
    align-self: flex-start;
  }
`;

const OptionLabel = styled(Title2)`
  color: ${props => props.theme.darkGray};
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 15px;
`;

const ItemDropdown = styled.div`
  display: grid;
  padding-bottom: 25px;
  /* grid-gap: 12px; */
  /* margin-left: 20px;
  @media (min-width: 768px) {
    margin-left: 100px;
  } */
`;

const OptionDrawer = ({
  flatProduct,
  parentOption,
  option,
  id,
  handleSelectItem,
  handleToggleSubItemQuantity,
  handleIncreaseItemQuantity,
  handleDecreaseItemQuantity,
  selectedVariantId,
}) => {
  const [openItem, setOpenItem] = useState(false);

  return (
    <Wrapper id={id}>
      {parentOption && <OptionLabel>{option.name}</OptionLabel>}
      <ItemDropdown>
        {option.items.map(itemId => {
          const item = flatProduct.itemsById[itemId];
          return (
            <ItemRow
              key={item.item_id}
              flatProduct={flatProduct}
              parentOption={parentOption}
              option={option}
              item={item}
              handleToggleSubItemQuantity={handleToggleSubItemQuantity}
              handleSelectItem={handleSelectItem}
              handleIncreaseItemQuantity={handleIncreaseItemQuantity}
              handleDecreaseItemQuantity={handleDecreaseItemQuantity}
              selectedVariantId={selectedVariantId}
              openItem={openItem}
              setOpenItem={setOpenItem}
            />
          );
        })}
      </ItemDropdown>
    </Wrapper>
  );
};

export default OptionDrawer;
