import React from 'react';
import styled from 'styled-components';
import ImageFadeIn from 'react-image-fade-in';
import {Headline} from '../../../../../../components/Typography';

const Wrapper = styled.div`
  margin-bottom: 32px;
  border-radius: 8px;
  border-width: 1.2px;
  border-style: dashed;
  padding: 16px;
  border-color: ${props => props.theme.comboGroupOutlineBorderColor};

  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto 20px;
  grid-gap: 16px;
  cursor: pointer;
  pointer-events: ${props => (props.isAvailable ? 'default' : 'none')};

  &:hover {
    background: ${props => props.theme.comboGroupStateBackgroundColor};
    transition: all 0.25s ease;
  }
`;

const ProductTitleWrapper = styled(Headline)`
  margin-bottom: 8px;
  position: relative;
`;

const ProductTitle = styled.p`
  color: ${props =>
    props.isAvailable ? 'inherit' : props.theme.unavailableProductColor};
`;

const ProductImage = styled(ImageFadeIn)`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: ${props =>
    props.theme.productPreviewImageShape === 'circle' ? '50%' : '8px'};
  border: ${props => props.theme.productPreviewBorder};
  order: ${props => (props.theme.productPreviewAlignImage === 'left' ? 0 : 2)};
  grid-row: 1 / span 2;
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
`;

const ActionRow = styled.div`
  color: ${props => props.theme.darkGray};
`;

const CustomizeButton = styled.span`
  margin-right: 12px;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
`;

const ProductInfo = styled.div``;

const Required = styled.div`
  background-color: ${props => props.theme.primaryColor};
  border-radius: 4px;
  color: ${props => props.theme.white};
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  font-weight: 300;
  padding: 4px 8px;
  @media (max-width: 480px) {
    position: relative;
    display: inline-block;
  }
`;

const RequiredComboGroupOneItemPreview = ({
  comboGroup,
  comboGroupIndex,
  onGroupClick,
  id,
}) => {
  const product = comboGroup.productItems[0];
  return (
    <Wrapper
      id={id}
      onClick={() => onGroupClick(comboGroupIndex, id)}
      isAvailable={product.is_product_available}
    >
      <ProductImage
        src={product.image_url}
        isAvailable={product.is_product_available}
      />
      <ProductInfo>
        <ProductTitleWrapper>
          <ProductTitle
            id="RequiredComboGroupOneItem-Title"
            isAvailable={product.is_product_available}
          >
            {product.name}
          </ProductTitle>
          <Required id="RequiredComboGroupOneItem-SelectionRequired">
            1 Selection Required
          </Required>
        </ProductTitleWrapper>
      </ProductInfo>
      <ActionRow>
        <CustomizeButton>
          <i className="fas fa-pen"></i>
          <span>Customize</span>
        </CustomizeButton>
      </ActionRow>
    </Wrapper>
  );
};

export default RequiredComboGroupOneItemPreview;
