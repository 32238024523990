import {
  GET_MY_TRANSACTIONS_REQUEST,
  GET_MY_TRANSACTIONS_SUCCESS,
  GET_MY_TRANSACTION_DETAILS_SUCCESS,
  RESET_TRANSACTION_DETAILS_SUCCESS
} from './MyTransactionTypes';

export default function(
  state = {
    requestingMyTransactions: false,
    myTransactions: [],
  },
  action
) {
  switch (action.type) {
    case GET_MY_TRANSACTIONS_REQUEST:
      return {
        ...state,
        requestingMyTransactions: true,
      };
    case GET_MY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        requestingMyTransactions: false,
        myTransactions: action.myTransactions,
      };
    case GET_MY_TRANSACTION_DETAILS_SUCCESS:
    return {
      ...state,
      myTransactionDetails: action.myTransactionDetails,
    };
    case RESET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        myTransactionDetails: undefined,
      };

    default:
      return state;
  }
}