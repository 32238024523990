import React from 'react';
import styled from 'styled-components';
import spinner from '../../assets/spinner.svg';

const Spinner = styled.img`
  width: 32px;
  position: absolute;
  left: -24px;
  top: -4px;
`;

const Button = styled.button`
  color: ${props => props.theme.bodyColor};
  font-weight: ${props => (props.secondary ? '400' : '600')};
  cursor: pointer;
  font-size: 15px;
  background: none;
  border: none;
  font-family: ${props => props.theme.primaryFontFontFamily};
`;

const FlatButton = ({showLoading, children, ...otherProps}) => {
  if (showLoading) {
    return (
      <div style={{position: 'relative'}}>
        <Spinner src={spinner} alt="loading" />
        <Button {...otherProps}>{children}</Button>
      </div>
    );
  } else {
    return <Button {...otherProps}>{children}</Button>;
  }
};

export default FlatButton;
