import React from 'react';
import styled from 'styled-components';
import appConfig from '../../../../../app-config';

const DeliveryMessage = styled.div`
  margin-top: ${props => (props.marginTop ? props.marginTop : '5px')};
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 24px auto;
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 12px;
  max-width: 350px;
  > div:first-child {
    padding-top: ${props => (props.isOrderSetting ? '0px' : '12px')};
  }
`;

const Message = styled.p`
  text-align: center;
  font-family: ${appConfig.primaryFontFamily};
`;

const DeliveryOrderMessage = ({deliveryBusiness, isOrderSetting}) => {
  return (
    <DeliveryMessage isOrderSetting={isOrderSetting}>
      <div>
        <i className="fal fa-exclamation-circle" />
      </div>
      <div>
        <Message>{`Your order will be Fulfilled by ${appConfig.appTitle} - ${deliveryBusiness.name}`}</Message>
      </div>
    </DeliveryMessage>
  );
};

export default DeliveryOrderMessage;
