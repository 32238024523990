import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Title1, Headline } from '../../../../components/Typography';
import { PrimaryButton } from '../../../../components/Buttons';
import Select from '../../../../components/Select';
import OrderFutureSchedule from '../../OrderSettingModal/components/OrderFutureSchedule';
import appConfig from '../../../../../app-config';
import SkeletonLoading from '../../OrderSettingModal/components/SkeletonLoading';
import {
  renderConfirmButtonText,
  isConfirmButtonDisabled,
} from '../../OrderSettingModal/utils/helper';
import ConfirmButton from '../../OrderSettingModal/components/ConfirmButton';
import { isScheduleRadioDisabledCalculated } from '../../OrderSettingModal/utils/helper';
import UnavailableMessage from '../../OrderSettingModal/components/UnavailableMessage';
import UnavailableProducts from '../../OrderSettingModal/components/UnavailableProducts';
import { PICKUP, DROPOFF } from '../../orderSettingTypes';

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  padding: 32px;
  max-width: 500px;

  // height: 1000px;
  @media (min-width: 786px) {
    width: 500px;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const FormWrapper = styled.div`
  position: relative;
  margin-top: 14px;
  display: grid;
  gap: 21px;
`;

const SelectLabel = styled.p`
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  // grid-template-rows: 1fr;
  grid-gap: 14px;
  margin-top: 40px;
`;

const Delete = styled(Headline)`
  color: ${(props) =>
    props.disabled
      ? props.theme.primaryButtonDisabledColor
      : props.theme.black};
  align-self: center;
  cursor: pointer;
`;

const SaveButton = styled(PrimaryButton)`
  width: 70%;
  margin: auto;
  height: 56px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const DeleteButton = styled(PrimaryButton)`
  width: 70%;
  margin: auto;
  height: 56px;
  font-size: 19px;
  font-weight: 600;

  background-color: ${(props) =>
    props.theme.deleteLocationButtonBackgroundColor};
  color: ${(props) => props.theme.deleteLocationButtonTextColor};

  &:hover {
    background-color: ${(props) =>
      props.theme.deleteLocationButtonBackgroundColor};
    color: ${(props) => props.theme.deleteLocationButtonTextColor};
    transition: all 0.25s ease;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const PickUpTimeForm = ({
  setPickUpTimeFormIsVisible,
  location,
  interOrderType,
  interSelectedDateTime,
  updateInterSelectedDateTime,
  confirmDeliveryDetails,
  availableTimes,
  selectedDateTime,
  isTimeNoLongerValid,
  resetIsTimeNoLongerValid,
  getTimeIntervalsDate,
  specialHours,
  orderPacingData,
  isOnline,
  emptySearchBox,
  deepLinkParameters,
  isConfirmMarketPlaceDeliveryDetailsRequest,
  isUnavailableProductInCartRequest,
  unavailableProducts,
  cartErrorMessage,
  checkUnavailableProductForMarketplace,
  resetUnavailableProducts,
  updateInterPickUpDetails,
  confirmPickUpDetails,
  updateSelectedLocation,
  cartReducer,
  resetCartErrorMessages,
  updateInterDropOffDetails,
  confirmDropOffDetails,
  onSeeMenu,
  selectMarketplacePickUpLocation,
  updateCartWithUnavailbleProducts,
  isAuthenticated,
  emptyCart,
  updateUnavailableProducts,
  setIsMenuDisabled,
  getMenu,
  getCombos,
  validateCartForLocation,
}) => {
  const maxFutureOrderDate =
    location && location.settings && location.settings.max_future_order_date;

  const orderEndTime =
    location &&
    location.availableTimes &&
    location.availableTimes?.length &&
    (location.availableTimes?.length > 1
      ? location.availableTimes[1].order_end_time
      : location.availableTimes[0].order_end_time);

  const isAsapRadioDisabled =
    !(location && location?.accepting_orders) ||
    !isOnline ||
    (location.orderPacingData &&
      !location.orderPacingData.is_asap_slot_available);

  let isScheduleRadioDisabled = true;

  if (location) {
    isScheduleRadioDisabled = isScheduleRadioDisabledCalculated(
      location.availableTimes,
      maxFutureOrderDate,
      orderEndTime,
      location.specialHours,
      isOnline
    );
  }
  const checkProductAvialibility = useCallback(() => {
    if (
      interSelectedDateTime?.radioValue?.value === 'asap' ||
      (interSelectedDateTime?.radioValue?.value === 'schedule' &&
        interSelectedDateTime?.dateTime?.time?.value?.length)
    ) {
      validateCartForLocation(location);
    }
  }, [interSelectedDateTime]);
  useEffect(() => {
    checkProductAvialibility();
  }, [checkProductAvialibility]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title1 style={{ fontWeight: '600', marginBottom: '20px' }}>
          {interOrderType
            ? interOrderType.charAt(0).toUpperCase() +
              interOrderType.slice(1).replace('_', '-')
            : 'Order'}{' '}
          Time
        </Title1>

        <CloseButton
          onClick={() => {
            setPickUpTimeFormIsVisible(false, null, true);
          }}
        >
          <i
            className="fas fa-times"
            style={{ fontSize: '24px', cursor: 'pointer' }}
          ></i>
        </CloseButton>
      </TitleWrapper>
      <div style={{ marginBottom: '32px' }}>
        {isConfirmMarketPlaceDeliveryDetailsRequest ? (
          <SkeletonLoading />
        ) : (
          <OrderFutureSchedule
            selectedLocation={location}
            interOrderType={interOrderType}
            interSelectedDateTime={interSelectedDateTime}
            updateInterSelectedDateTime={updateInterSelectedDateTime}
            confirmDeliveryDetails={confirmDeliveryDetails}
            confirmDropOffDetails={confirmDropOffDetails}
            confirmPickUpDetails={confirmPickUpDetails}
            availableTimes={availableTimes}
            selectedDateTime={selectedDateTime}
            maxFutureOrderDate={
              location && location.settings.max_future_order_date
            }
            isTimeNoLongerValid={isTimeNoLongerValid}
            resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
            isLocationOrDelivBusinesssAcceptingOrders={
              location && location.accepting_orders
            }
            getTimeIntervalsDate={getTimeIntervalsDate}
            specialHours={specialHours}
            orderPacingData={orderPacingData}
            orderMessage={appConfig.orderMessageDelivery}
            isOnline={location && location.settings.online}
            onSeeMenu={onSeeMenu}
            emptySearchBox={() => emptySearchBox()}
            deepLinkParameters={deepLinkParameters}
            isMarketplaceDelivery={true}
            setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
            is_asap_order_enabled={
              !(!location?.is_asap_order_enabled || !location.accepting_orders)
            }
            is_future_order_enabled={location?.is_future_order_enabled}
            cartReducer={cartReducer}
            selectMarketplacePickUpLocation={selectMarketplacePickUpLocation}
            emptyCart={emptyCart}
          />
        )}
        {unavailableProducts && unavailableProducts.length ? (
          <UnavailableProducts
            cartErrorMessage={cartErrorMessage}
            marginBottom="25px"
          />
        ) : null}
      </div>

      {!isConfirmMarketPlaceDeliveryDetailsRequest && (
        <ConfirmButton
          text={'Confirm'}
          onClick={() => {
            if (location) {
              getCombos(location.id);
              getMenu(location.id, 'pickup');
              setIsMenuDisabled(false);
              selectMarketplacePickUpLocation();
              if (!isAuthenticated) {
                emptyCart();
              } else if (
                unavailableProducts &&
                unavailableProducts.length > 0
              ) {
                updateCartWithUnavailbleProducts(unavailableProducts);
              }

              if (interOrderType === PICKUP) {
                confirmPickUpDetails(true);
              } else if (interOrderType === DROPOFF) {
                confirmDropOffDetails(true);
              }
              resetCartErrorMessages();
            }
            resetUnavailableProducts();
            setPickUpTimeFormIsVisible(false);
            updateSelectedLocation(null);
            window.scrollTo(0, 0);
          }}
          disabled={
            !interSelectedDateTime?.radioValue?.value ||
            (interSelectedDateTime?.radioValue?.value === 'schedule' &&
              interSelectedDateTime?.dateTime?.time?.value === '')
          }
        />
      )}
    </Wrapper>
  );
};

export default withStyles(styles)(PickUpTimeForm);
