export default flatProduct => {
  const {
    options,
    optionsById,
    subOptionsById,
    itemsById,
    subItemsById,
  } = flatProduct;
  const mapOption = option => {
    const optionToReturn = {
      option_id: option.option_id,
      items: option.items
        .map(itemId => {
          const item = itemsById[itemId];
          if (item.sub_items) {
            const itemToReturn = {
              item_id: item.item_id,
              sub_items: item.sub_items
                .map(subItemId => {
                  const subItem = subItemsById[subItemId];
                  if (subItem.quantity) {
                    return {
                      item_id: subItem.item_id,
                      quantity: subItem.quantity,
                    };
                  }
                })
                .filter(subItem => subItem),
            };
            if (itemToReturn.sub_items.length) {
              return itemToReturn;
            }
          } else if (item.quantity) {
            return {
              item_id: item.item_id,
              quantity: item.quantity,
            };
          }
        })
        .filter(item => item),
    };
    if (optionToReturn.items.length) {
      return optionToReturn;
    }
  };

  let skinnyProduct = {
    product_id: flatProduct.product_id,
    quantity: flatProduct.quantity,
    special_instruction: flatProduct.instruction,
    configuration: {
      variant_id: flatProduct.variant_id,
    },
  };

  const skinnyOptions = options
    ? options
        .map(optionId => {
          const option = optionsById[optionId];
          if (option.sub_options.length) {
            const optionToReturn = {
              option_id: option.option_id,
              items: [],
              sub_options: option.sub_options
                .map(subOptionId => mapOption(subOptionsById[subOptionId]))
                .filter(subOption => subOption),
            };
            if (optionToReturn.sub_options.length) {
              return optionToReturn;
            }
          } else {
            return mapOption(option);
          }
        })
        .filter(option => option)
    : [];

  if (skinnyOptions) {
    skinnyProduct = {
      ...skinnyProduct,
      configuration: {
        ...skinnyProduct.configuration,
        options: skinnyOptions,
      },
    };
  }

  return {config_products: [skinnyProduct]};
};
