import React, { Fragment, useState, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import numeral from "numeral";
import Dialog from "@material-ui/core/Dialog";
import DrawersContainer from "./components/DrawersContainer";
import CalorieString from "../../components/CalorieString";
import { PrimaryButton } from "../../components/Buttons";
import { Title1, Body } from "../../components/Typography";
import ImageFadeIn from "react-image-fade-in";
import AddtionalPrice from "./components/AddtionalPrice";
import appConfig from "../../../app-config";
import DefaultUnavailableItems from "./components/DefaultUnavailableItems";
import updateProductErrors from "./utils/updateProductErrors";
import { createAlert } from "../Alert/alertActions";
import { constant } from "lodash";

const Wrapper = styled.div`
  position: relative;
  max-width: 640px;
  height: 100%;
  margin: auto;
  @media (max-width: 786px) {
    -webkit-overflow-scrolling: touch;
  }
`;

const InfoWrapper = styled.div`
  padding: 24px;
  @media (max-width: 786px) {
    padding: 20px;
    padding-bottom: 80px;
  }
`;

const ProductImageWrapper = styled.div`
  position: relative;
  &:after {
    display: block;
    content: "";
    /* 16:9 aspect ratio */
    padding-bottom: 56.25%;
  }
`;

const ProductImage = styled(ImageFadeIn)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductTitle1 = styled(Title1)`
  color: ${(props) => props.theme.productCustomizerTitleFontColor};
  margin-bottom: ${(props) => (props.hasVariant ? "8px" : "24px")};
`;

const Category = styled.div`
  font-size: 14px;
  font-weight: 900;
  color: ${(props) => props.theme.primaryColor};
`;

const Price = styled(Body)``;

const CalPriceRow = styled.div`
  display: flex;
  grid-gap: 5px;
  align-items: center;
  margin-top: ${(props) => (props.hasVariant ? "8px" : "24px")};
`;
const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const CartButtonWrapper = styled.div`
  background-color: #fff;
  width: 608px;
  position: fixed;
  bottom: 0;
  margin: 20px 16px 12px 16px;
`;

const CartButton = styled(PrimaryButton)`
  width: 608px;

  @media (max-width: 640px) {
    background-color: ${(props) => props.theme.addToCartButtonBackgroundColor};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
`;

const Dot = () => (
  <i
    style={{
      fontSize: "3px",
      verticalAlign: "middle",
      marginRight: "5px",
      lineHeight: "0",
    }}
    className="fas fa-circle"
  />
);

const DefualtUnavailableTooltip = styled.div`
  margin-top: 12px;
  padding: 8px 14px 10px;
  border-radius: 12px;
  background-color: #f7f7f7;
`;

const DefualtUnavailablePopup = styled.div`
  margin: 25px;
`;

const CancelWrapper = styled.div``;

const BottomPrice = styled.span`
  position: absolute;
  top: 13px;
  right: 13px;
  margin-right: 10px;
  color: ${(props) => props.theme.primaryButtonFontColor};
  font-size: 16px;
`;

const BackButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 75px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.54);
  color: ${(props) => props.theme.white};
  padding: 6px 12px;
  top: 8px;
  left: 8px;
  z-index: 1;
  i {
    margin-right: 8px;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.85);
  }
`;

const ProductCustomizer = ({
  productCustomizerReducer,
  handleAddToCart,
  addToCartString,
  onCancel,
  onBackClick,
  isCustomizingProductForCombo,
  hidePrice,
  combo,
}) => {
  const {
    selectedProduct,
    flatProduct,
    singlePrice,
    selectedVariantId,
  } = productCustomizerReducer;
  const dispatch = useDispatch();
  const [isUnavailableModalOpen, setIsUnavailableModalOpen] = useState(0);
  const validatedProduct = updateProductErrors(flatProduct);

  const getDefaultUnvailableItems = () => {
    let localDefaultUnavailableItems = [];
    if (
      selectedProduct &&
      Object.keys(selectedProduct).length > 0 &&
      selectedProduct.options
    ) {
      selectedProduct.options.forEach((option) => {
        if (option.items && option.items.length > 0) {
          option.items.forEach((item) => {
            if (item.is_default && item.quantity > 0 && !item.is_available) {
              localDefaultUnavailableItems.push(item.name);
            }
          });
        }
      });
    }
    return localDefaultUnavailableItems;
  };

  const defaultUnavailableItems = getDefaultUnvailableItems();

  const handleAddToCartClick = () => {
    if (
      defaultUnavailableItems.length > 0 &&
      isUnavailableModalOpen === 0 &&
      !validatedProduct.hasErrors
    ) {
      setIsUnavailableModalOpen(1);
    } else {
      if (validatedProduct.hasErrors) {
        dispatch(
          createAlert({
            type: "error",
            message: "Product configuration incomplete.",
          })
        );
        return;
      } else {
        handleAddToCart(validatedProduct);
      }
    }
  };
  return (
    <Wrapper id="productCustomizerContainer">
      {isCustomizingProductForCombo && (
        <BackButton
          id="ProductCustomizer-BackButton"
          onClick={() => onBackClick()}
        >
          <i className="fas fa-chevron-left"></i>
          Back
        </BackButton>
      )}
      <CancelWrapper>
        <Cancel id="ProductCustomizer-Cancel" onClick={onCancel}>
          <i className="fal fa-times" />
        </Cancel>
      </CancelWrapper>
      {!appConfig.isNoImage && (
        <ProductImageWrapper>
          <ProductImage src={flatProduct.image_url} />
        </ProductImageWrapper>
      )}
      <InfoWrapper>
        <Category>{flatProduct.category}</Category>
        <ProductTitle1 hasVariant={flatProduct.prices.length > 1}>
          {flatProduct.name}
        </ProductTitle1>
        <Body color={"#737a87"}>{flatProduct.user_description}</Body>
        {!isCustomizingProductForCombo && !hidePrice && (
          <CalPriceRow cals hasVariant={flatProduct.prices.length > 1}>
            <Price id="ProductCustomizer-Price">
              {numeral(singlePrice).format("$0.00")}
            </Price>
            <div>
              {flatProduct.calories ? (
                <Fragment>
                  {/* <Dot /> */}
                  <CalorieString
                    calories={flatProduct.calories.find(
                      (calorie) => calorie.variant_id === flatProduct.variant_id
                    )}
                  />
                </Fragment>
              ) : (
                <div />
              )}
            </div>
          </CalPriceRow>
        )}
        {defaultUnavailableItems.length > 0 && (
          <DefualtUnavailableTooltip>
            <DefaultUnavailableItems
              items={defaultUnavailableItems}
              productName={flatProduct.name}
            />
          </DefualtUnavailableTooltip>
        )}
        <DrawersContainer
          flatProduct={flatProduct}
          isCustomizingProductForCombo={isCustomizingProductForCombo}
          combo={combo}
        />
      </InfoWrapper>
      <CartButtonWrapper>
        <CartButton
          id="ProductCustomizer-CartButton"
          onClick={handleAddToCartClick}
        >
          {addToCartString}
          {isCustomizingProductForCombo && !hidePrice && (
            <AddtionalPrice
              prices={flatProduct.prices}
              selectedVariantId={selectedVariantId}
              singlePrice={singlePrice}
              startingPrice={flatProduct.price}
            />
          )}
          {!isCustomizingProductForCombo && (
            <BottomPrice id="ProductCustomizer-BottomPrice">
              {numeral(singlePrice).format("$0.00")}
            </BottomPrice>
          )}
        </CartButton>
      </CartButtonWrapper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={isUnavailableModalOpen === 1}
        onClose={() => setIsUnavailableModalOpen(2)}
        scroll="body"
        maxWidth={"md"}
      >
        <DefualtUnavailablePopup>
          <DefaultUnavailableItems
            items={defaultUnavailableItems}
            productName={flatProduct.name}
            modalView={true}
            onCancel={() => setIsUnavailableModalOpen(2)}
            onProceed={() => handleAddToCart(validatedProduct)}
          />
        </DefualtUnavailablePopup>
      </Dialog>
    </Wrapper>
  );
};

export default ProductCustomizer;
