import React from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  border: ${props => props.theme.EditIconBorder} 1px ${props => props.theme.addEditBorderColor ? 
                                                      props.theme.addEditBorderColor : props.theme.clickableTextColor};
  width: fit-content;
  border-radius: 18px;
  padding: 8px 12px;
  height: 36px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: ${props => props.theme.addEditTextColor ? 
                  props.theme.addEditTextColor : props.theme.clickableTextColor};
  display: inline-block;
  cursor: pointer;
`

const Label = styled.span`
  margin-left: 12px;
`

const EditOptionButton = ({
  handleEditOption,
}) => {
  
  return (
    <ItemWrapper onClick={handleEditOption}>
      <i className="fas fa-pen"/>
      <Label id="OptionSummary-EditOptionButton">Edit</Label>
    </ItemWrapper>
  );
};

export default EditOptionButton;

