import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import { Callout, Body } from "../../../../components/Typography";
import Select from "../../../../components/Select";
import { Link } from "gatsby";
import LocationCustomFormatLabel from "./LocationCustomLabel";
import OrderFutureSchedule from "./OrderFutureSchedule";
import UnavailableMessage from "./UnavailableMessage";
import ConfirmButton from "./ConfirmButton";
import UnavailableProducts from "./UnavailableProducts";
import {
  renderConfirmButtonText,
  isConfirmButtonDisabled,
  isLocationAcceptingOrders,
  isScheduleRadioDisabledCalculated,
} from "../utils/helper";
import appConfig from "../../../../../app-config";
import SkeletonLoading from "./SkeletonLoading";
import UnavailableMessageWithButton from "./UnavailableMessageWithButton";

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const SelectTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddNewButton = styled(Link)`
  margin: 0;
  letter-spacing: 0px;
  text-decoration: none;
`;

const CalloutWrapper = styled(Callout)`
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const PickUp = ({
  interPickUpDetails,
  locations,
  updateInterPickUpDetails,
  availableTimes,
  interSelectedDateTime,
  interOrderType,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  confirmPickUpDetails,
  unavailableProducts,
  resetInterSelectedDateTime,
  isRequesting,
  requestingGetLocations,
  isTimeNoLongerValid,
  resetIsTimeNoLongerValid,
  selectedDateTime,
  currentLocation,
  nearestLocation,
  hideToolTip,
  toolTipIsHidden,
  handleConfirmPickupButton,
  specialHours,
  orderPacingData,
  refine,
  selectedLocation,
  orderSettingModalIsVisible,
  updateSelectedLocation,
  cartErrorMessage,
  deepLinkParameters,
  checkUnavailableProductForPickUp,
  updateOrderSettingModalIsVisible,
  cartReducer,
  getMenu,
  emptyCart
}) => {
  const isAsapRadioDisabled =
    interPickUpDetails &&
    interPickUpDetails.settings &&
    !interPickUpDetails.settings.online;

  const maxFutureOrderDate =
    interPickUpDetails &&
    interPickUpDetails.settings &&
    interPickUpDetails.settings.max_future_order_date;

  const orderEndTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);

  const isScheduleRadioDisabled = !(
    availableTimes &&
    maxFutureOrderDate &&
    orderEndTime &&
    specialHours &&
    !isScheduleRadioDisabledCalculated(
      availableTimes,
      maxFutureOrderDate,
      orderEndTime,
      specialHours,
      interPickUpDetails.settings.online
    )
  );

  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (
      interSelectedDateTime.radioValue?.value === "asap" ||
      (interSelectedDateTime.dateTime.date &&
        interSelectedDateTime.dateTime.time.value)
    )
      if (interPickUpDetails) getMenu(interPickUpDetails.id, interOrderType);
  }, [
    interSelectedDateTime.dateTime.date,
    interSelectedDateTime.dateTime.time.value,
  ]);

  useEffect(() => {
    if (selectedLocation && !deepLinkParameters?.id) {
      const pickedLocation = locations.filter(
        (location) => location.name === selectedLocation
      );

      if (pickedLocation.length > 0) {
        setLocation(pickedLocation[0]);
      } else {
        setLocation(null);
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!orderSettingModalIsVisible) {
      setLocation(null);
      updateSelectedLocation(null);
    }
  }, [orderSettingModalIsVisible]);

  useEffect(() => {
    if (deepLinkParameters?.id) {
      const address = locations.filter(
        (addr) => addr.id === parseInt(deepLinkParameters.id)
      );
      if (address.length > 0) {
        setLocation(address[0]);
      }
    }
  }, [deepLinkParameters, locations]);

  useEffect(() => {
    if (deepLinkParameters?.id && location) {
      let selectedDateTimeModified = {
        ...interSelectedDateTime,
        radioValue: { name: "As soon as possible", value: "asap" },
      };

      updateInterPickUpDetails(location, new Date().toString());
      updateInterSelectedDateTime(selectedDateTimeModified);
      if (interPickUpDetails) {
        handleConfirmPickupButton();
        emptySearchBox();
      }
    }
  }, [deepLinkParameters, location, interPickUpDetails]);

  const emptySearchBox = () => {
    if (appConfig.isGlobalSearchEnabled) {
      refine("");
    }
  };

  useEffect(() => {
    if (
      (interSelectedDateTime.radioValue.value === "asap" ||
        interSelectedDateTime.radioValue.value === "schedule") &&
      interOrderType === "pickup"
    ) {
      checkUnavailableProductForPickUp(interPickUpDetails);
    }
  }, [interSelectedDateTime.radioValue]);

  return (
    <Wrapper>
      <SelectTitleWrapper>
        <Body style={{ fontWeight: "bold" }}>Picking up from:</Body>
        <AddNewButton to="/locations" onClick={() => {}}>
          <CalloutWrapper>View Map</CalloutWrapper>
        </AddNewButton>
      </SelectTitleWrapper>
      <div>
        <Select
          value={location ? location : interPickUpDetails}
          onChange={(location) => {
            updateInterPickUpDetails(location, new Date().toString());
            resetInterSelectedDateTime();
            resetIsTimeNoLongerValid();
          }}
          options={locations}
          getOptionLabel={(location) => location.name}
          getOptionValue={(location) => location}
          formatOptionLabel={(location) => (
            <LocationCustomFormatLabel
              location={location}
              coords={currentLocation}
              interPickUpDetails={interPickUpDetails}
              specialHours={specialHours}
            />
          )}
          placeholder="Select a location..."
          maxMenuHeight={200}
          isLocation={true}
          isDisabled={isRequesting || requestingGetLocations}
          coords={currentLocation}
          nearestLocation={interPickUpDetails}
          specialHours={specialHours}
          interOrderType={interOrderType}
          triggerOnChange
        />
      </div>
      {isRequesting || requestingGetLocations ? (
        <SkeletonLoading />
      ) : (
        <Fragment>
          {interPickUpDetails && availableTimes ? (
            !interPickUpDetails.is_order_ahead_enabled ? (
              <div>
                <UnavailableMessageWithButton
                  onClick={() => {
                    handleConfirmPickupButton();
                    emptySearchBox();
                  }}
                  title="Selected location does not accept order ahead"
                />
              </div>
            ) : (
              <>
                {!interPickUpDetails.settings.online &&
                  isLocationAcceptingOrders(
                    interPickUpDetails.accepting_orders,
                    specialHours
                  ) &&
                  (!isScheduleRadioDisabled || !isAsapRadioDisabled) && (
                    <div>
                      <UnavailableMessageWithButton
                        onClick={() => {
                          handleConfirmPickupButton();
                          emptySearchBox();
                        }}
                        title="Selected location is unable to accept orders at the moment"
                      />
                    </div>
                  )}
                {!(
                  interPickUpDetails.settings.is_asap_order_enabled &&
                  !interPickUpDetails.settings.is_future_order_enabled
                ) && (
                  <OrderFutureSchedule
                    availableTimes={availableTimes}
                    selectedLocation={interPickUpDetails}
                    updateInterPickUpDetails={updateInterPickUpDetails}
                    interOrderType={interOrderType}
                    interSelectedDateTime={interSelectedDateTime}
                    updateInterSelectedDateTime={updateInterSelectedDateTime}
                    getTimeIntervalsDate={getTimeIntervalsDate}
                    confirmPickUpDetails={confirmPickUpDetails}
                    isTimeNoLongerValid={isTimeNoLongerValid}
                    resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
                    selectedDateTime={selectedDateTime}
                    is_future_order_enabled={
                      interPickUpDetails.settings.is_future_order_enabled
                    }
                    is_asap_order_enabled={
                      interPickUpDetails.settings.is_asap_order_enabled
                    }
                    maxFutureOrderDate={
                      interPickUpDetails
                        ? interPickUpDetails.settings.max_future_order_date
                        : null
                    }
                    isLocationOrDelivBusinesssAcceptingOrders={
                      isLocationAcceptingOrders(
                        interPickUpDetails.accepting_orders,
                        specialHours
                      )
                      // interPickUpDetails.accepting_orders
                    }
                    specialHours={specialHours}
                    orderPacingData={orderPacingData}
                    orderMessage={appConfig.orderMessagePickup}
                    isOnline={interPickUpDetails.settings.online}
                    emptySearchBox={() => emptySearchBox()}
                    deepLinkParameters={deepLinkParameters}
                    onSeeMenu={handleConfirmPickupButton}
                    cartReducer={cartReducer}
                    emptyCart={emptyCart}
                  />
                )}
              </>
            )
          ) : null}
          {cartErrorMessage && cartErrorMessage.length ? (
            <UnavailableProducts
              unavailableProducts={unavailableProducts}
              cartErrorMessage={cartErrorMessage}
            />
          ) : null}

          <ConfirmButton
            text={renderConfirmButtonText(
              interPickUpDetails,
              appConfig.isFutureOrderPickup
            )}
            onClick={() => {
              handleConfirmPickupButton();
              if (
                !interPickUpDetails.settings.online &&
                isLocationAcceptingOrders(
                  interPickUpDetails.accepting_orders,
                  specialHours
                ) &&
                (!isScheduleRadioDisabled || !isAsapRadioDisabled)
              )
                emptySearchBox();
            }}
            disabled={
              !interPickUpDetails ||
              isConfirmButtonDisabled(
                interSelectedDateTime,
                appConfig.isFutureOrderPickup,
                interPickUpDetails
              ) ||
              (!interPickUpDetails.settings.is_future_order_enabled &&
                !interPickUpDetails.settings.is_asap_order_enabled)
            }
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default PickUp;
