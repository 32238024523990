import React from 'react';
import styled from 'styled-components';
import {Subhead} from '../../../../components/Typography';
import {PrimaryButton} from '../../../../components/Buttons';
import {Link} from 'gatsby';

const LocationUnavailableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #f7f7f7;
  align-items: center;
  padding: 5px;
  margin-top: ${props => (props.marginTop ? props.marginTop : '-16px')};
  margin-bottom: 10px;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0px')};
`;

const LocationUnavailable = styled.div`
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 5px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto;
  align-items: center;
  margin-right: 5px;
`;

const SeeMenuButton = styled(PrimaryButton)`
  min-width: 150px;
  position: relative;
  width: 100px;
  min-width: 100px;
  border-radius: 17.5px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 786px) {
    min-width: 80px;
  }
`;

const ButtonLink = styled(Link)`
  margin: 0;
  letter-spacing: 0px;
  text-decoration: none;
`;

const UnavailableMessageWithButton = ({title, onClick, marginTop, paddingTop}) => {
  return (
    <LocationUnavailableWrapper marginTop={marginTop} paddingTop={paddingTop}>
      <LocationUnavailable>
        <i className="fal fa-exclamation-circle" />
        <Subhead style={{fontWeight: '400'}}>{title}</Subhead>
      </LocationUnavailable>
      <ButtonLink to="/">
        <SeeMenuButton onClick={onClick}>See Menu</SeeMenuButton>
      </ButtonLink>
    </LocationUnavailableWrapper>
  );
};

export default UnavailableMessageWithButton;
