import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Body, Caption1, Title1 } from '../../../../components/Typography';
import Select from '../../../../components/Select';
import formatAddressName from '../../../../utils/formatAddressName';
import UnavailableMessage from '../../OrderSettingModal/components/UnavailableMessage';
import SkeletonLoading from '../../OrderSettingModal/components/SkeletonLoading';
import OrderMethodSelection from '../../OrderSettingModal/components/OrderMethodSelection';
import LocationCard from '../components/LocationCard';

const Wrapper = styled.div`
  ${(props) =>
    props.isOneMethodOnly
      ? `
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
    `
      : `
      display: grid;
      grid-template-columns: auto;
      `}
`;

const SelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isOpen ? '0.5fr' : '1fr')};
  justify-content: center;
`;

const LocationsWrapper = styled.div`
  display: grid;
  overflow-y: scroll;
  max-height: 400px;
`;

const StoresWrapper = styled.div`
  display: grid;
`;

const SelectTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const AddNewButton = styled(Caption1)`
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const OrderMethodDetails = styled.div`
  ${(props) =>
    props.isOneMethodOnly
      ? `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      `
      : `
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      grid-template-rows: 23% 23% 23%;
      gap: 16px;

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      };

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      };
  `}
`;

const Hr = styled.hr`
  background-color: white;
  border: none;
  border-top: solid 3px #ebebeb;
  margin: 0;
  margin-bottom: 22px;
  @media (max-width: 786px) {
    margin: 0 -20px;
  }
`;

const Stores = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 46px;
  padding: 0 5px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const Delivery = ({
  deliveryAddressesList,
  interDeliveryDetails,
  interOrderType,
  resetInterSelectedDateTime,
  isRequesting,
  confirmMarketplaceDeliverySelection,
  setDeliveryAddressesFormIsVisible,
  resetIsTimeNoLongerValid,
  isAuthenticated,
  orderSettingModalIsVisible,
  deepLinkParameters,
  updateInterOrderType,
  resetDateTimes,
  isOneMethodOnly,
  getMarketplaceDeliveryLocations,
  marketplaceDeliveryLocations,
  locations,
  isMarketplaceDeliveryLocationsRequest,
  setDeliveryTimeFormIsVisible,
  deliveryDetails,
  isUnavailableProductInCartRequest,
  checkUnavailableProductForMarketplace,
  resetMarketplaceDeliveryLocations,
  confirmDeliveryDetails,
  getTimeIntervalsDate,
  deliveryToAddress,
  updateInterDeliveryDetails,
  setMarketplaceDeliverySelection,
  validateCartForLocation,
  deliveryToAddressId,
  hasLengthAddressesList,
}) => {
  const [deliveryLocation, setDeliveryLocation] = useState(null);

  let filteredMarketplaceDeliveryLocations = [];

  if (deliveryDetails) {
    filteredMarketplaceDeliveryLocations = marketplaceDeliveryLocations.filter(
      (location) => location.business_id !== deliveryDetails.business_id
    );
  } else {
    filteredMarketplaceDeliveryLocations = marketplaceDeliveryLocations;
  }

  useEffect(() => {
    if (!orderSettingModalIsVisible) {
      setDeliveryLocation(null);
    }
  }, [orderSettingModalIsVisible]);

  useEffect(() => {
    if (deepLinkParameters?.id) {
      const address = deliveryAddressesList.filter(
        (addr) => addr.id === parseInt(deepLinkParameters.id)
      );
      if (address.length > 0) {
        setDeliveryLocation(address[0]);
      }
    }
  }, [deepLinkParameters]);

  useEffect(() => {
    if (deliveryToAddress) {
      const address = deliveryAddressesList.filter(
        (addr) => addr.line1 === deliveryToAddress.line1
      );
      if (address.length > 0) {
        setDeliveryLocation(address[0]);
        updateInterDeliveryDetails(address[0]);
        getMarketplaceDeliveryLocations(address[0]);
      }
    }
  }, [deliveryToAddressId, hasLengthAddressesList]);

  return (
    <>
      <OrderMethodDetails isOneMethodOnly={isOneMethodOnly}>
        {isOneMethodOnly ? null : (
          <OrderMethodSelection
            interOrderType={interOrderType}
            updateInterOrderType={updateInterOrderType}
            resetInterSelectedDateTime={resetInterSelectedDateTime}
            resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
            resetDateTimes={resetDateTimes}
            deepLinkParameters={deepLinkParameters}
          />
        )}
      </OrderMethodDetails>
      <Wrapper isOneMethodOnly={isOneMethodOnly}>
        <SelectionWrapper isOpen={marketplaceDeliveryLocations.length !== 0}>
          {isAuthenticated && deliveryAddressesList.length !== 0 && (
            <Select
              value={deliveryLocation ? deliveryLocation : interDeliveryDetails}
              options={deliveryAddressesList}
              placeholder="Select the delivery address ..."
              getOptionLabel={(deliveryAddress) =>
                formatAddressName(deliveryAddress)
              }
              getOptionValue={(deliveryAddress) => deliveryAddress}
              onChange={(deliveryAddress) => {
                resetInterSelectedDateTime();
                resetIsTimeNoLongerValid();
                resetMarketplaceDeliveryLocations();
                setDeliveryLocation(deliveryAddress);
                getMarketplaceDeliveryLocations(deliveryAddress);
              }}
              isDisabled={isRequesting}
              interOrderType={interOrderType}
              triggerOnChange
            />
          )}
          {isMarketplaceDeliveryLocationsRequest ? (
            <SkeletonLoading />
          ) : (
            <Fragment>
              {!isMarketplaceDeliveryLocationsRequest &&
              marketplaceDeliveryLocations.length === 0 &&
              deliveryLocation ? (
                <UnavailableMessage
                  marginTop="10px"
                  title={
                    'We are unable to deliver to this address at this time. Select Pickup above to pickup instead.'
                  }
                />
              ) : null}
            </Fragment>
          )}
          <SelectTitleWrapper>
            <div style={{ marginRight: 'auto', marginTop: '10px' }}>
              <AddNewButton
                onClick={() => {
                  setDeliveryAddressesFormIsVisible(true);
                }}
              >
                + Add New Address
              </AddNewButton>
            </div>
          </SelectTitleWrapper>
        </SelectionWrapper>
      </Wrapper>
      <LocationsWrapper>
        {deliveryDetails && deliveryDetails.deliveryBusiness && (
          <>
            <Hr />
            <StoresWrapper>
              <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                {'Current Store'}
              </Title1>
              <Stores>
                <LocationCard
                  confirmMarketplaceDeliverySelection={
                    confirmMarketplaceDeliverySelection
                  }
                  marketplaceDeliveryLocation={deliveryDetails}
                  locations={locations}
                  deliveryLocation={deliveryLocation}
                  setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
                  isCurrentStore={true}
                  confirmDeliveryDetails={confirmDeliveryDetails}
                  getTimeIntervalsDate={getTimeIntervalsDate}
                  resetInterSelectedDateTime={resetInterSelectedDateTime}
                  interDeliveryDetails={interDeliveryDetails}
                  deliveryDetails={deliveryDetails}
                  updateInterDeliveryDetails={updateInterDeliveryDetails}
                  isRequesting={isRequesting}
                  setMarketplaceDeliverySelection={
                    setMarketplaceDeliverySelection
                  }
                  checkUnavailableProductForMarketplace={
                    checkUnavailableProductForMarketplace
                  }
                  validateCartForLocation={validateCartForLocation}
                />
              </Stores>
            </StoresWrapper>
          </>
        )}
        {marketplaceDeliveryLocations.length === 0 && !deliveryLocation && (
          <>
            <Hr />
            <StoresWrapper>
              <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                {deliveryDetails ? 'Other Stores' : 'Select Store'}
              </Title1>
              <Body style={{ marginBottom: '24px' }}>
                Add a delivery address to browse stores{' '}
              </Body>
            </StoresWrapper>
          </>
        )}
        {!isMarketplaceDeliveryLocationsRequest &&
          filteredMarketplaceDeliveryLocations.length !== 0 && (
            <>
              {!deliveryDetails && <Hr />}
              <StoresWrapper>
                <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                  {deliveryDetails ? 'Other Stores' : 'Select Store'}
                </Title1>
                {!isMarketplaceDeliveryLocationsRequest && (
                  <Stores>
                    {filteredMarketplaceDeliveryLocations &&
                      filteredMarketplaceDeliveryLocations.map(
                        (marketplaceDeliveryLocation) => (
                          <>
                            {marketplaceDeliveryLocation.deliveryBusiness && (
                              <LocationCard
                                confirmMarketplaceDeliverySelection={
                                  confirmMarketplaceDeliverySelection
                                }
                                marketplaceDeliveryLocation={
                                  marketplaceDeliveryLocation
                                }
                                locations={locations}
                                deliveryLocation={deliveryLocation}
                                setDeliveryTimeFormIsVisible={
                                  setDeliveryTimeFormIsVisible
                                }
                                setDeliveryAddressesFormIsVisible={
                                  setDeliveryAddressesFormIsVisible
                                }
                                isUnavailableProductInCartRequest={
                                  isUnavailableProductInCartRequest
                                }
                                checkUnavailableProductForMarketplace={
                                  checkUnavailableProductForMarketplace
                                }
                                resetInterSelectedDateTime={
                                  resetInterSelectedDateTime
                                }
                                interOrderType={interOrderType}
                                confirmDeliveryDetails={confirmDeliveryDetails}
                                getTimeIntervalsDate={getTimeIntervalsDate}
                                interDeliveryDetails={interDeliveryDetails}
                                deliveryDetails={deliveryDetails}
                                updateInterDeliveryDetails={
                                  updateInterDeliveryDetails
                                }
                                isRequesting={isRequesting}
                                setMarketplaceDeliverySelection={
                                  setMarketplaceDeliverySelection
                                }
                                validateCartForLocation={
                                  validateCartForLocation
                                }
                              />
                            )}
                          </>
                        )
                      )}
                  </Stores>
                )}
                {interOrderType && !isOneMethodOnly && (
                  <Body style={{ color: '#737a87', marginTop: '55px' }}>
                    Couldn’t find what you’re looking for? Try Pickup instead.
                  </Body>
                )}
              </StoresWrapper>
            </>
          )}
      </LocationsWrapper>
    </>
  );
};

export default Delivery;
