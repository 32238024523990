import {
  ADD_TO_FAVOURITES_SUCCESS,
  CLEAR_SELECTED_FAVOURITE_ID,
  DELETE_FAVOURITE_SUCCESS,
  GET_FAVOURITES_SUCCESS,
} from './favouritesTypes';
import {updateCartWithFavouritedProducts} from '../Cart/cartActions';
import api from '../../api';
import productForCartValidation from '../Cart/utils/productForCartValidation';
import flattenProduct from '../../utils/flattenProduct';
import {PICKUP, DELIVERY, DROPOFF} from '../OrderSetting/orderSettingTypes';

export function addToFavourites(flatProduct, favouriteName) {
  return function(dispatch, getState) {
    const currentOrderType = getState().orderSettingReducer.interOrderType;

    const business_id =
      currentOrderType === PICKUP
        ? getState().orderSettingReducer.interPickUpDetails.id
        : currentOrderType === DELIVERY
        ? getState().orderSettingReducer.interDeliveryDetails.deliveryBusiness.id
        : currentOrderType === DROPOFF
        ? getState().orderSettingReducer.interDropOffDetails.id
        : null;
    
    return api
      .post(`/favourite-products`, {
        business_id,
        name: favouriteName,
        config_products: productForCartValidation(flatProduct)
          .config_products[0],
      })
      .then(response => {
        dispatch({
          type: ADD_TO_FAVOURITES_SUCCESS,
          selectedFavouriteId: response.data.data.favourite_id,
          flatProduct,
        });
      });
  };
}

export function deleteFavourite(favouriteId) {
  return function(dispatch) {
    return api.delete(`/favourite-products/${favouriteId}`).then(response => {
      dispatch({type: DELETE_FAVOURITE_SUCCESS, favouriteId});
      dispatch({type: 'UNFAVOURITE_BY_FAVOURITE_ID', favouriteId});
    });
  };
}

export function getFavourites() {
  return function(dispatch) {
    return api.get(`/favourite-products`).then(response => {
      let favouritesList = response.data.data.map(favourite => {
        let flatProduct = flattenProduct({
          ...favourite.config_products,
          options: favourite.config_products.options || [],
        });
        flatProduct.name = favourite.name;
        return {flatProduct, favouriteId: favourite.favourite_id};
      });

      dispatch({type: GET_FAVOURITES_SUCCESS, favouritesList});
    });
  };
}

export function clearSelectedFavouriteId() {
  return {
    type: CLEAR_SELECTED_FAVOURITE_ID,
  };
}
export function updateCartFavouritedProducts() {
  return function(dispatch, getState) {
    const {favouritesList} = getState().favouritesReducer;
    let favouriteIds = favouritesList.map(favourite => favourite.favouriteId);
    let {products} = getState().cartReducer;

    products = products.map(product => ({
      ...product,
      favouriteId:
        favouriteIds.find(favouriteId => product.favouriteId === favouriteId) ||
        null,
    }));

    dispatch(updateCartWithFavouritedProducts(products));
  };
}
