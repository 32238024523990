import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Body } from '../../components/Typography';
import { navigate } from 'gatsby';
import ConfirmCloseModal from '../../modules/Auth/ConfirmCloseModal/ConfirmCloseModal';
import appConfig from '../../../app-config';
import Credits from '../../modules/Credits/CreditsContainer';
import Coupons from '../../modules/Coupons/CouponsContainer';

const CreditsWrapper = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
`;

let menuItems = [];

if (appConfig.accountIcons?.icons) {
  menuItems = [
    {
      name: 'Credits',
      url: '/',
      icon: appConfig.accountIcons.icons.my_credits,
    },
    {
      name: 'Account Settings',
      url: '/profile-settings',
      icon: appConfig.accountIcons.icons.profile_settings,
    },
    {
      name: 'Payment Methods',
      url: '/payment-methods',
      icon: appConfig.accountIcons.icons.payment_methods,
    },
    {
      name: 'Order History',
      url: '/my-transactions',
      icon: appConfig.accountIcons.icons.order_history,
    },
    {
      name: 'Delivery Addresses',
      url: '/addresses',
      icon: appConfig.accountIcons.icons.my_address,
    },
    {
      name: 'Coupons',
      url: '/',
      icon: appConfig.accountIcons.icons.coupons,
    },
    {
      name: 'Refer a Friend',
      url: '/refer-friend',
      icon: 'fas fa-user-friends',
    },
    {
      name: 'Logout',
      url: '/',
      icon: appConfig.accountIcons.icons.logout,
    },
  ];
} else {
  menuItems = [
    {
      name: 'Credits',
      url: '/',
      icon: 'fal fa-usd-circle',
    },
    {
      name: 'Account Settings',
      url: '/profile-settings',
      icon: 'fal fa-user',
    },
    {
      name: 'Payment Methods',
      url: '/payment-methods',
      icon: 'fal fa-money-bill',
    },
    {
      name: 'Order History',
      url: '/my-transactions',
      icon: 'fal fa-receipt',
    },
    {
      name: 'Delivery Addresses',
      url: '/addresses',
      icon: 'fal fa-home',
    },
    {
      name: 'Coupons',
      url: '/',
      icon: 'fas fa-tags',
    },
    {
      name: 'Refer a Friend',
      url: '/refer-friend',
      icon: 'fas fa-user-friends',
    },
    {
      name: 'Logout',
      url: '/',
      icon: 'fal fa-sign-out fa-rotate-180',
    },
  ];
}

if (!appConfig.isDeliveryEnabled && !appConfig.isFutureOrderDelivery)
  menuItems = menuItems.filter((item) => item.url !== '/addresses');

if (!appConfig.isCouponsEnabled)
  menuItems = menuItems.filter((item) => item.name != 'Coupons');

// var environment = process.env.GATSBY_ENV;

// if (environment !== 'development') {
//   menuItems = menuItems.map(item => ({
//     ...item,
//     url: `/${appConfig.pathPrefix}${item.url}`,
//   }));
// }

const MenuItem = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 20px;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.menuItemBackgroundColor};
    transition: all 0.25s ease;
  }
`;

const Icon = styled.i`
  color: ${(props) => props.theme.menuIconColors};
  justify-self: center;
  font-weight: bold;
`;

const Image = styled.img`
  width: 20px;
  justify-self: center;
`;

const MenuItems = ({
  toggleMenu,
  logout,
  loyalty,
  setCouponsModalIsVisible,
  showReferralCode,
}) => {
  const [confirmCloseModalIsVisible, setConfirmCloseModalIsVisible] = useState(
    false
  );

  const [creditModalIsVisible, setCreditModalIsVisible] = useState(false);

  if (!showReferralCode)
    menuItems = menuItems.filter((item) => item.url !== '/refer-friend');

  const handleConfirmModalConfirmClick = () => {
    setConfirmCloseModalIsVisible(false);
    logout();
    toggleMenu();
  };

  const handleConfirmModalCancelClick = () => {
    setConfirmCloseModalIsVisible(false);
    toggleMenu();
  };

  const handleMenuItemClick = (urlPath, menuName) => {
    switch (menuName) {
      case 'Logout':
        setConfirmCloseModalIsVisible(true);
        break;
      case 'Credits':
        setCreditModalIsVisible(true);
        break;
      case 'Coupons':
        setCouponsModalIsVisible(true);
        break;
      default:
        navigate(urlPath);
        toggleMenu();
    }
  };

  return (
    <div>
      <div>
        {menuItems
          .reduce((acc, menu) => {
            return menu.name == 'Credits'
              ? loyalty && loyalty.credits_available > 0
                ? acc.concat(menu)
                : acc
              : acc.concat(menu);
          }, [])
          .map((menuItem) => (
            <MenuItem
              id={menuItem.name}
              onClick={() => handleMenuItemClick(menuItem.url, menuItem.name)}
            >
              {menuItem.name != 'Credits' ? (
                <Fragment>
                  {appConfig.accountIcons?.iconType === 'image' &&
                  menuItem.url !== '/refer-friend' ? (
                    <Image src={menuItem.icon} />
                  ) : (
                    <Icon className={menuItem.icon} />
                  )}

                  <Body style={{ fontWeight: '400' }}>{menuItem.name}</Body>
                </Fragment>
              ) : (
                <Fragment>
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <Image src={menuItem.icon} />
                  ) : (
                    <Icon className={menuItem.icon} />
                  )}
                  {loyalty.credits_available > 0 ? (
                    <CreditsWrapper>
                      {menuItem.name}{' '}
                      {`: $${loyalty.credits_available.toFixed(2)}`}
                    </CreditsWrapper>
                  ) : (
                    <CreditsWrapper>{menuItem.name}</CreditsWrapper>
                  )}
                </Fragment>
              )}
            </MenuItem>
          ))}
      </div>
      {confirmCloseModalIsVisible && (
        <ConfirmCloseModal
          handleConfirmClick={handleConfirmModalConfirmClick}
          j
          handleCancelClick={handleConfirmModalCancelClick}
          confirmCloseModalIsVisible={confirmCloseModalIsVisible}
          body="Are you sure you want to logout?"
        />
      )}
      <Credits
        setCreditModalIsVisible={setCreditModalIsVisible}
        creditModalIsVisible={creditModalIsVisible}
      />
    </div>
  );
};

export default MenuItems;
