export default (product, option) => {
  const {itemsById, subItemsById} = product;
  let itemsString = '';

  option.items.forEach(itemId => {
    const item = itemId.item_id || itemId.id ? itemId : itemsById[itemId];
    const addCommaAndSpace = itemsString ? ', ' : '';
    if (item.sub_items) {
      item.sub_items.forEach(subItemId => {
        const subItem =
          subItemId.item_id || subItemId.id
            ? subItemId
            : subItemsById[subItemId];
        const showQuantity = subItem.maximum_pick !== 1;
        const quantityString = showQuantity ? ` x${subItem.quantity}` : '';
        if (subItem.quantity) {
          itemsString += `${addCommaAndSpace}${item.name} (${subItem.name}${quantityString})`;
        }
      });
    } else {
      const showQuantity = item.maximum_pick !== 1;
      const quantityString = showQuantity ? ` x${item.quantity}` : '';
      if (item.quantity) {
        itemsString += `${addCommaAndSpace}${item.name}${quantityString}`;
      }
    }
  });
  return itemsString;
};
