import {
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  SET_SIGNUP_FAIL_ERROR,
  CONFIRM_SMS_REQUEST,
  CONFIRM_SMS_SUCCESS,
  CONFIRM_SMS_FAILURE,
  RESET_REGISTRATION_STATES,
} from './registrationTypes';
import moment from 'moment';
import api from '../../api';
import appConfig from '../../../app-config';
import { createAlert } from '../../modules/Alert/alertActions';
import { setInterDeliveryDetailsId } from '../OrderSetting/orderSettingActions';
import formatedBEErrorMessage from '../../utils/formatedBEErrorMessage';
import { updateOrderSettingModalIsVisible } from '../OrderSetting/orderSettingActions';
import {
  getDeliveryAddresses,
  setSelectedDeliveryAddress,
} from '../DeliveryAddresses/deliveryAddressesActions';
export function clearRegisterationErrorMessage() {
  return function(dispatch) {
    dispatch({
      type: 'UPDATE_REGISTERATION_ERROR_MESSAGE',
      errors: {},
    });
  };
}
export function createAccount(values) {
  const formBody = {
    source: appConfig.appSource,
    ...values,
    phone_number: values.phone_number.toString(),
  };
  delete formBody.confirm_password;
  return function(dispatch, getState) {
    dispatch({
      type: 'UPDATE_REGISTERATION_ERROR_MESSAGE',
      errors: {},
    });
    if (values.birthdate.length) {
      if (!moment(values.birthdate).isValid()) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Invalid Birthday',
          })
        );
        return;
      }

      if (moment(values.birthdate).isAfter(moment())) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Date should not be in the future!',
          })
        );
        return;
      }

      const age = moment().diff(moment(values.birthdate), 'years');
      if (age < 13) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'The minimal required age to register is 13!',
          })
        );
        return;
      }
    }
    dispatch({ type: CREATE_ACCOUNT_REQUEST });
    return api
      .post(`/registration`, formBody, { ignore_interception: true })
      .then((response) => {
        localStorage.setItem(
          `${appConfig.appSource}_token`,
          response.data.data.token
        );
        dispatch({
          type: CREATE_ACCOUNT_SUCCESS,
          token: response.data.data.token,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_ACCOUNT_FAILURE,
        });
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_REGISTERATION_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}

export function setSignupFailError(error) {
  return function(dispatch) {
    dispatch({
      type: SET_SIGNUP_FAIL_ERROR,
      error,
    });
  };
}

export function confirmSMS(values) {
  return function(dispatch, getState) {
    dispatch({ type: CONFIRM_SMS_REQUEST });
    dispatch({
      type: 'UPDATE_REGISTERATION_ERROR_MESSAGE',
      errors: {},
    });
    return api
      .post(
        `/registration/phone/confirmation`,
        {
          sms_code: values.sms_code,
        },
        { ignore_interception: true }
      )
      .then((response) => {
        dispatch({
          type: CONFIRM_SMS_SUCCESS,
          confirmSMSIsSuccessful: true,
        });
        dispatch(updateOrderSettingModalIsVisible(true));

        const { interDeliveryDetails } = getState().orderSettingReducer;
        const config = getState().authReducer.appConfig;
        const { deliveryToAddress } = getState().deliveryAddressesReducer;

        if (deliveryToAddress && config && config.guest_checkout_enabled) {
          api.post('/customer/addresses', deliveryToAddress).then((res) => {
            if (res.data.data.customer_address_id) {
              dispatch(
                setSelectedDeliveryAddress({
                  ...deliveryToAddress,
                  id: res.data.data.customer_address_id,
                })
              );
              dispatch(getDeliveryAddresses());
              dispatch(
                setInterDeliveryDetailsId(res.data.data.customer_address_id)
              );
            }
          });
        }
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_REGISTERATION_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}

export function resetRegistrationStates() {
  return {
    type: RESET_REGISTRATION_STATES,
  };
}

export function setLoginToken() {
  return function(dispatch) {
    dispatch({
      type: 'LOGIN_SUCCESS',
      token: localStorage.getItem(`${appConfig.appSource}_token`),
    });
  };
}
