import React from 'react';
import styled from 'styled-components';

const WarningBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
  border-radius: 8px;
  background-color: #fbebe5;
  margin-bottom: 10px;
`;

const WarningText = styled.div`
  font-size: 16px;
  letter-spacing: 0.02px;
  line-height: initial;
`;

const WarningIcon = styled.i`
  font-size: 16px;
  margin: 0 12px;
`;

const WarningBox = ({message}) => {
  return (
    <WarningBoxWrapper>
      <WarningIcon className="fal fa-exclamation-triangle" />
      <WarningText>{message}</WarningText>
    </WarningBoxWrapper>
  );
};

export default WarningBox;
