import React from 'react';
import styled from 'styled-components';

const CalorieSpan = styled.span`
  font-size: ${props => props.caloriesFontSize || '16px'};
  font-weight: initial;
  letter-spacing: -0.4px;
  color: ${props => props.theme[props.caloriesFontColor || 'bodyColor']};
  font-family: ${props => props.theme.primaryFontFontFamily};
  align-self: flex-end;
`;

const CalorieString = ({calories, caloriesFontSize, caloriesFontColor}) => {
  return null;
  const {min_calories, max_calories} = calories;
  if (min_calories === max_calories) {
    return (
      <CalorieSpan
        caloriesFontSize={caloriesFontSize}
        caloriesFontColor={caloriesFontColor}
      >
        {min_calories} Cals
      </CalorieSpan>
    );
  } else {
    return (
      <CalorieSpan
        caloriesFontSize={caloriesFontSize}
        caloriesFontColor={caloriesFontColor}
      >
        {min_calories}-{max_calories} Cals
      </CalorieSpan>
    );
  }
};

export default CalorieString;
