import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from './productCustomizerActions';
import { addToCart } from '../Cart/cartActions';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import ProductCustomizer from './ProductCustomizer';
import spinner from '../../assets/spinner.svg';
import {
  changePickUpLocationFromLocationsPage,
  updateOrderSettingModalIsVisible,
} from '../OrderSetting/orderSettingActions';

const ProductCustomizerContainer = ({
  productId,
  getProductToConfigure,
  selectedPickUpLocation,
  addToCart,
  updatePrice,
  validateProduct,
  clearProductToConfigure,
  cancelEditProductConfiguration,
  productCustomizerReducer,
  navigateToMenu,
  classes,
  isEditingFromMenu,
  orderSettingReducer,
  cartReducer,
  changePickUpLocationFromLocationsPage,
  updateOrderSettingModalIsVisible,
}) => {
  const {
    flatProduct,
    isEditing,
    isFromPreviousOrder,
    isFromFavourites,
    isEditingFromOffers,
    productCustomizerIsVisible,
  } = productCustomizerReducer;

  // useEffect(() => {
  //   if (
  //     selectedPickUpLocation
  //     // &&
  //     // !isEditing &&
  //     // !isFromPreviousOrder &&
  //     // !isFromFavourites
  //   ) {
  //     getProductToConfigure(productId, selectedPickUpLocation.id);
  //   }
  // }, [selectedPickUpLocation]);

  const [numberOfAddToCartClick, setNumberofAddToCartClick] = useState(0);
  const scrollToFirstOptionWithError = () => {
    const firstOptionIdWithError =
      flatProduct.options &&
      flatProduct.options.find((optionId) => {
        const option = flatProduct.optionsById[optionId];
        return option.error;
      });

    document
      .getElementById(`option_${firstOptionIdWithError}`)
      .scrollIntoView({ behavior: 'smooth' });
  };
  const addProduct = () => {
    addToCart(productCustomizerReducer, true);
    clearProductToConfigure();
  };
  const handleAddToCart = (validatedProduct) => {
    validateProduct(validatedProduct);
    setNumberofAddToCartClick(numberOfAddToCartClick + 1);
  };
  useEffect(() => {
    if (flatProduct) {
      updatePrice(flatProduct);
    }
  }, [flatProduct]);

  useEffect(() => {
    if (flatProduct && flatProduct.hasErrors) {
      scrollToFirstOptionWithError();
    }
    if (flatProduct && numberOfAddToCartClick !== 0 && !flatProduct.hasErrors) {
      addProduct();
    }
  }, [numberOfAddToCartClick]);

  const handleProductCustomizerCancel = () => {
    clearProductToConfigure();

    // if (isFromPreviousOrder || isFromFavourites) {
    //   clearProductToConfigure();
    // }
    // if (isEditing) {
    //   cancelEditProductConfiguration();
    // }
    // // else {
    // //   navigateToMenu();
    // // }
  };
  return (
    <Dialog
      aria-labelledby="edit-product-modal"
      aria-describedby="Edit Product"
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      //open={productId !== null || isEditing === true}
      open={productCustomizerIsVisible}
      onClose={handleProductCustomizerCancel}
      className={classes.root}
      PaperProps={{
        classes: { root: classes.paper },
      }}
      scroll="body"
      maxWidth={'md'}
      fullWidth={true}
    >
      {flatProduct ? (
        <ProductCustomizer
          productCustomizerReducer={productCustomizerReducer}
          onCancel={handleProductCustomizerCancel}
          handleAddToCart={handleAddToCart}
          addToCartString={!isEditing ? 'Add to Cart' : 'Update Order'}
          isCustomizingProductForCombo={false}
          orderSettingReducer={orderSettingReducer}
          cartReducer={cartReducer}
        />
      ) : (
        <div style={{ textAlign: 'center', margin: '160px' }}>
          <img src={spinner} style={{ width: '100px' }} />
        </div>
      )}
    </Dialog>
  );
};

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  paper: {
    margin: '32px 32px 0px 32px',
    minHeight: '100vh',
  },
});

const mapStateToProps = (state) => ({
  selectedPickUpLocation: state.orderSettingReducer.interPickUpDetails,
  productCustomizerReducer: state.productCustomizerReducer,
  orderSettingReducer: state.orderSettingReducer,
  cartReducer: state.cartReducer,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    ...actions,
    addToCart,
    changePickUpLocationFromLocationsPage,
    updateOrderSettingModalIsVisible,
  })(ProductCustomizerContainer)
);
