import {
  GET_PRODUCT_TO_CONFIGURE_REQUEST,
  GET_PRODUCT_TO_CONFIGURE_SUCCESS,
  // GET_PRODUCT_TO_CONFIGURE_FAILURE,
  CHANGE_VARIANT_REQUEST,
  CHANGE_VARIANT_SUCCESS,
  // CHANGE_VARIANT_FAILURE,
  INCREMENT_ITEM,
  DECREMENT_ITEM,
  INCREMENT_SUB_ITEM,
  DECREMENT_SUB_ITEM,
  REMOVE_ITEM,
  REMOVE_SUB_ITEM,
  SET_DRAWER_MODAL_OPEN,
  UPDATE_PRICE,
  EDIT_PRODUCT_CONFIGURATION,
  CANCEL_EDIT_PRODUCT_CONFIGURATION,
  CLEAR_PRODUCT_TO_CONFIGURE,
  VALIDATE_PRODUCT,
  VALIDATE_OPTION,
  EDIT_PREVIOUS_PRODUCT_CONFIGURATION_SUCCESS,
  EDIT_PREVIOUS_PRODUCT_CONFIGURATION_REQUEST,
  ADD_ORDER_INSTRUCTION,
} from './productCustomizerTypes';
import updateVariant from './utils/updateVariant';

const initialState = {
  selectedProduct: {},
  singlePrice: 0,
  totalPrice: 0,
  isEditing: false,
  isEditingFromOffers: false,
  productCustomizerIsVisible: false,
  isDrawerModalOpen: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_TO_CONFIGURE_REQUEST:
      return {
        requestGetProductToConfigure: true,
        singlePrice: 0,
        totalPrice: 0,
        flatProduct: false,
        isEditing: false,
      };
    case GET_PRODUCT_TO_CONFIGURE_SUCCESS:
      return {
        ...state,
        productCustomizerIsVisible: action.hasOwnProperty(
          'productCustomizerIsVisible'
        )
          ? action.productCustomizerIsVisible
          : true,
        requestGetProductToConfigure: false,
        singlePrice: action.response.data.price,
        totalPrice: action.response.data.price,
        flatProduct: { ...action.flatProduct, quantity: 1 },
        selectedProduct: action.selectedProduct,
        selectedVariantId: action.flatProduct.prices.find(
          (price) => price.is_default
        ).variant_id,
      };
    case CHANGE_VARIANT_REQUEST:
      return {
        ...state,
        requestChangeVariant: true,
        selectedVariantId: action.selectedVariantId,
      };
    case CHANGE_VARIANT_SUCCESS:
      return {
        ...state,
        requestChangeVariant: false,
        singlePrice: action.flatProduct.price,
        totalPrice: action.flatProduct.price * state.flatProduct.quantity,
        flatProduct: action.flatProduct,
        // flatProduct: action.flatProduct,
      };
    case INCREMENT_ITEM:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          itemsById: {
            ...state.flatProduct.itemsById,
            [action.item.item_id]: {
              ...action.item,
              quantity: action.item.quantity + 1,
            },
          },
        },
      };
    case REMOVE_ITEM:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          itemsById: {
            ...state.flatProduct.itemsById,
            [action.item.item_id]: {
              ...action.item,
              quantity: 0,
            },
          },
        },
      };
    case REMOVE_SUB_ITEM:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          subItemsById: {
            ...state.flatProduct.subItemsById,
            [action.subItem.item_id]: {
              ...action.subItem,
              quantity: 0,
            },
          },
        },
      };
    case DECREMENT_ITEM:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          itemsById: {
            ...state.flatProduct.itemsById,
            [action.item.item_id]: {
              ...action.item,
              quantity: action.item.quantity - 1,
            },
          },
        },
      };
    case INCREMENT_SUB_ITEM:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          subItemsById: {
            ...state.flatProduct.subItemsById,
            [action.subItem.item_id]: {
              ...action.subItem,
              quantity: action.subItem.quantity + 1,
            },
          },
        },
      };
    ///
    case ADD_ORDER_INSTRUCTION:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          instruction: action.instruction,
        },
      };
    case DECREMENT_SUB_ITEM:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          subItemsById: {
            ...state.flatProduct.subItemsById,
            [action.subItem.item_id]: {
              ...action.subItem,
              quantity:
                action.quantity || action.quantity === 0
                  ? action.quantity
                  : action.subItem.quantity - 1,
            },
          },
        },
      };
    case UPDATE_PRICE:
      return {
        ...state,
        singlePrice: action.singlePrice,
        totalPrice: action.totalPrice,
      };
    case EDIT_PRODUCT_CONFIGURATION:
      return {
        ...action.productToEdit,
        isEditing: action.isEditing ? action.isEditing : true,
        isEditingFromOffers: true,
        productCustomizerIsVisible: action.hasOwnProperty(
          'productCustomizerIsVisible'
        )
          ? action.productCustomizerIsVisible
          : true,
      };
    case CANCEL_EDIT_PRODUCT_CONFIGURATION:
      return initialState;
    case VALIDATE_PRODUCT:
      return { ...state, flatProduct: action.validatedProduct };
    case VALIDATE_OPTION:
      return {
        ...state,
        flatProduct: {
          ...state.flatProduct,
          optionsById: {
            ...state.flatProduct.optionsById,
            [action.validatedOption.option_id]: action.validatedOption,
          },
        },
      };
    case EDIT_PREVIOUS_PRODUCT_CONFIGURATION_REQUEST:
      return {
        requestGetProductToConfigure: true,
        singlePrice: 0,
        totalPrice: 0,
        flatProduct: false,
        isEditing: false,
      };
    case EDIT_PREVIOUS_PRODUCT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...action.productToEdit,
        isEditing: false,
        requestGetProductToConfigure: false,
        productCustomizerIsVisible: true,
      };
    case SET_DRAWER_MODAL_OPEN:
      return {
        ...state,
        isDrawerModalOpen: action.open,
      };
    case CLEAR_PRODUCT_TO_CONFIGURE:
      return initialState;
    default:
      return state;
  }
}
