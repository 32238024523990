import React, { useState } from 'react';
import styled from 'styled-components';
import { Headline, Body } from '../../../../components/Typography';
import ConfirmButton from '../../OrderSettingModal/components/ConfirmButton';
import UnavailableMessage from '../../OrderSettingModal/components/UnavailableMessage';
import {
  renderMessageText,
  orderingIsAvailable,
} from '../../OrderSettingModal/utils/helper';
import { getOpeningHour } from '../../utils/helper';
import appConfig from '../../../../../app-config';
import moment from 'moment';
import DeliveryTimeFormContainer from '../DeliveryTimeFormContainer';
const LocationCard = styled('div')`
  display: grid;
  grid-template-rows: 1fr 1fr;
  box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  border-radius: 9.6px;
  max-width: 497px;
  height: 180px;
  margin-bottom: 1em;
  padding: ${(props) => (props.message ? `0 1em` : `14px 1em 0 1em`)};
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    width: 385px;
    padding: ${(props) => (!props.noImage ? '0 1em 1em 1em' : '0 1em 1em 1em')};
    height: ${(props) => (props.message ? ` 260px` : `180px`)};
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    width: 100%;
    padding: ${(props) => (!props.noImage ? '0 1em 1em 1em' : '0 1em 1em 1em')};
    height: ${(props) => (props.message ? ` 260px` : `180px`)};
  }
`;

const LocationContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr 0.75fr;
`;

const LocationMessageWrapper = styled('div')`
  display: grid;
  grid-column: 1 / span 3;
  grid-row: 4;
  padding: 2em 0;
`;

const ImageWrapper = styled.div`
  display: block;
  align-self: center;
  justify-content: center;
`;

const LocationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-left: ${(props) => (props.noImage ? '0' : '1.25em')};
  width: 245px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  align-items: end;
  justify-content: end;
  grid-column: 3;

  @media (max-width: 768px) {
    grid-column: ${(props) => (props.noImage ? '1' : '2')};
    grid-row: 2;
    margin-top: 0.5em;
    padding-left: ${(props) => (props.noImage ? '0' : '1.25em')};
    align-items: start;
    justify-content: start;
  }
`;

const LocationAddress = styled(Body)`
  display: flex;
  flex-direction: column;
  color: #737a87;
  font-size: 15px;
  margin-top: 9px;
`;

const OrderTypesWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  &:first-child {
    margin-right: 1em;
  }
`;

const TypeDivider = styled('div')`
  width: 4px;
  height: 4px;
  margin: 10px 7px 0 5px;
  background-color: #737a87;
`;

const ThresholdFeeText = styled(Body)`
  color: ${(props) => props.theme.primaryButtonFontColor};
`;

const ViewOnlyCard = ({
  location,
  isCurrentStore,
  interOrderType,
  setPickUpTimeFormIsVisible,
  resetInterSelectedDateTime,
  isParentDeepLink,
  deliveryLocation, //pls, fix this, its horrible
  setIsMenuDisabled,
  selectMarketplacePickUpLocation,
  updateSelectedLocation,
  updateInterSelectedDateTime,
  updateOrderSettingModalIsVisible,
  getMenu,
  setDeliveryTimeFormIsVisible,
  interDeliveryDetails,
}) => {
  const [openDeliveryTimeModal, setOpenDeliveryTimeModal] = useState(false);
  const openingHour =
    location && location.hours ? getOpeningHour(location) : null;

  const orderingIsAvailableResult =
    location && location.ordering_hours
      ? orderingIsAvailable(location, location.ordering_hours)
      : null;
  const confirmButtonText = isCurrentStore
    ? 'Edit Time'
    : location.accepting_orders &&
      location.is_order_ahead_enabled &&
      interDeliveryDetails
    ? 'Order Here'
    : 'See Menu';

  return location ? (
    <LocationCard
      noImage={location.image_url === ''}
      isCurrentStore={isCurrentStore}
      data-cy={`marketplace-pickup-location-card-${location?.name}`}
      onClick={() => {
        if (confirmButtonText === 'See Menu') {
          updateSelectedLocation(location);
          updateInterSelectedDateTime({
            radioValue: {
              name: 'As soon as possible',
              value: 'asap',
            },
            dateTime: {
              date: {
                name: moment().format('ddd MMM DD'),
                value: moment().toString(),
              },
              time: {
                name: '',
                value: '',
              },
            },
          });
          getMenu(location.id, 'pickup');
          updateOrderSettingModalIsVisible(false);
        } else {
          resetInterSelectedDateTime();
          setDeliveryTimeFormIsVisible(true, location);
        }
      }}
      message={
        !location ||
        !orderingIsAvailableResult ||
        !location.accepting_orders ||
        !location.is_order_ahead_enabled
      }
    >
      <LocationContentWrapper>
        {location.image_url !== '' ? (
          <ImageWrapper>
            <img width="100" height="100" src={location.image_url} />
          </ImageWrapper>
        ) : null}

        <LocationInfoWrapper noImage={location.image_url === ''}>
          <Headline style={{ paddingTop: '1em' }}>{location.name}</Headline>
          <LocationAddress>
            {location.line1}, {location.city}
            <OrderTypesWrapper>
              {location.accepted_order_types?.map((orderType, i) =>
                location.accepted_order_types.length > 1 && i === 0 ? (
                  <>
                    <a>{orderType[0].toUpperCase() + orderType.slice(1)}</a>
                    <TypeDivider />
                  </>
                ) : (
                  <a>{orderType[0].toUpperCase() + orderType.slice(1)}</a>
                )
              )}
            </OrderTypesWrapper>
            {!location.is_order_ahead_enabled || !location.accepting_orders ? (
              <Body>💤 Closed</Body>
            ) : (
              <a>Order until {openingHour} </a>
            )}
          </LocationAddress>
        </LocationInfoWrapper>
        <ButtonWrapper noImage={location.image_url === ''}>
          <ConfirmButton
            text={confirmButtonText}
            style={{
              borderRadius: '18px',
              minWidth: '110px',
              fontSize: '14px',
            }}
            inverted={true}
            onClick={() => {
              if (confirmButtonText === 'See Menu') {
                updateSelectedLocation(location);
                updateInterSelectedDateTime({
                  radioValue: {
                    name: 'As soon as possible',
                    value: 'asap',
                  },
                  dateTime: {
                    date: {
                      name: moment().format('ddd MMM DD'),
                      value: moment().toString(),
                    },
                    time: {
                      name: '',
                      value: '',
                    },
                  },
                });
                getMenu(location.id, 'pickup');
                updateOrderSettingModalIsVisible(false);
              } else {
                console.log('Click=>');
                updateSelectedLocation(location);
                resetInterSelectedDateTime();
                setDeliveryTimeFormIsVisible(true, location);
                setOpenDeliveryTimeModal(true);
                window.scrollTo(0, 0);
              }
            }}
          />
        </ButtonWrapper>
      </LocationContentWrapper>
      <LocationMessageWrapper>
        {!location.is_order_ahead_enabled ? (
          <UnavailableMessage title="Selected location is unable to accept orders at the moment" />
        ) : !orderingIsAvailableResult || !location.accepting_orders ? (
          <UnavailableMessage
            title={renderMessageText(
              interOrderType,
              location.ordering_hours,
              []
            )}
          />
        ) : !location ? (
          <UnavailableMessage title={location.errorMessage} />
        ) : null}
      </LocationMessageWrapper>
      {openDeliveryTimeModal && (
        <DeliveryTimeFormContainer
          setOpenDeliveryTimeModal={setOpenDeliveryTimeModal}
        />
      )}
    </LocationCard>
  ) : null;
};

export default ViewOnlyCard;
