import React, {Fragment} from 'react';
import styled from 'styled-components';

const DistanceSpan = styled.span`
  color: ${props => props.theme.secondaryColorPlainText};
`;

const LocationCustomFormatLabel = ({location, coords, interPickUpDetails}) => {
  const {accepting_orders, name, distance, is_order_ahead_enabled} = location;

  if (interPickUpDetails && location.id === interPickUpDetails.id) {
    location.isNearestLocation = interPickUpDetails.isNearestLocation;
  }

  return (
    <Fragment>
      {(!accepting_orders || !is_order_ahead_enabled) && (
        <span>💤 Closed - </span>
      )}
      <span>
        {name} {coords && '- '}
      </span>
      {coords && <DistanceSpan>{distance}</DistanceSpan>}
      {location.isNearestLocation && <span> - (Nearest Location)</span>}
    </Fragment>
  );
};

export default LocationCustomFormatLabel;
