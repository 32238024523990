import React from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from '@material-ui/core';
import hexToRgbA from '../../utils/hexToRgbA';
import appConfig from '../../../app-config';

let StyledCheckbox = styled(Checkbox)`
  &:hover {
    background-color: ${props =>
      hexToRgbA(props.theme.checkmarkColor, 0.1)} !important;
    transition: all 0.25s ease;
  }
  svg {
    color: ${props => props.theme.checkmarkColor};
  }
`;

let ControlledCheckbox = ({label, checked, onChange, onBlur, name}) => (
  <FormGroup row>
    <FormControlLabel
      control={
        <StyledCheckbox
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
        />
      }
      label={
        <span style={{fontFamily: appConfig.styleConfig.primaryFontFontFamily}}>
          {label}
        </span>
      }
    />
  </FormGroup>
);

export default ControlledCheckbox;
