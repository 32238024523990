import {CREATE_ALERT, CLEAR_ALERT} from './alertTypes';

export default function(state = false, action) {
  switch (action.type) {
    case CREATE_ALERT:
      return {id: Math.random().toString(10), ...action.alert};
    case CLEAR_ALERT:
      return false;
    default:
      return state;
  }
}
