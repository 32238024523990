const optionIsValid = option => {
  if (option.sub_options && option.sub_options.length) {
    return option.sub_options.every(subOption => subOption.items);
  } else {
    return option.items && option.items.length ? true : false;
  }
};

const modifyPrices = prices => {
  let pricesModified;
  if (prices.length) {
    pricesModified = prices.filter(variant => variant.is_default);

    if (!pricesModified.length) {
      prices[0].is_default = true;
    }
    return prices;
  } else return prices;
};

export default product => {
  let flatProduct = {
    ...product,
    options: product.options
      ? product.options
          .filter(option => optionIsValid(option))
          .map(option => option.option_id || option.id)
      : [],
    optionsById: {},
    subOptionsById: {},
    itemsById: {},
    subItemsById: {},
    prices: modifyPrices(product.prices),
  };

  const flattenItems = items => {
    items.forEach(item => {
      if (item.sub_items) {
        flatProduct = {
          ...flatProduct,
          itemsById: {
            ...flatProduct.itemsById,
            [item.item_id || item.id]: {
              ...item,
              sub_items: item.sub_items.map(
                subItem => subItem.item_id || subItem.id
              ),
            },
          },
        };
        item.sub_items.forEach(subItem => {
          flatProduct = {
            ...flatProduct,
            subItemsById: {
              ...flatProduct.subItemsById,
              [subItem.item_id || subItem.id]: subItem,
            },
          };
        });
      } else {
        flatProduct = {
          ...flatProduct,
          itemsById: {
            ...flatProduct.itemsById,
            [item.item_id || item.id]: item,
          },
        };
      }
    });
  };

  product.options &&
    product.options.forEach(option => {
      if (option.sub_options && option.sub_options.length) {
        flatProduct = {
          ...flatProduct,
          optionsById: {
            ...flatProduct.optionsById,
            [option.option_id || option.id]: {
              ...option,
              sub_options: option.sub_options.map(
                subOption => subOption.option_id || subOption.id
              ),
              items: null,
            },
          },
        };
        option.sub_options.forEach(subOption => {
          flatProduct = {
            ...flatProduct,
            subOptionsById: {
              ...flatProduct.subOptionsById,
              [subOption.option_id || subOption.id]: {
                ...subOption,
                minimum_pick:
                  subOption.minimum_pick !== null
                    ? subOption.minimum_pick
                    : option.minimum_pick,
                maximum_pick:
                  subOption.maximum_pick !== null
                    ? subOption.maximum_pick
                    : option.maximum_pick,
                included_pick:
                  subOption.included_pick !== null
                    ? subOption.included_pick
                    : option.included_pick,
                items: subOption.items
                  ? subOption.items.map(item => item.item_id || item.id)
                  : [],
              },
            },
          };
          flattenItems(subOption.items || [], flatProduct);
        });
      } else {
        flatProduct = {
          ...flatProduct,
          optionsById: {
            ...flatProduct.optionsById,
            [option.option_id || option.id]: {
              ...option,
              items: option.items
                ? option.items.map(item => item.item_id || item.id)
                : [],
            },
          },
        };
        flattenItems(option.items || [], flatProduct);
      }
    });
  return flatProduct;
};
