import {
  GET_PRODUCT_TO_CONFIGURE_REQUEST,
  GET_PRODUCT_TO_CONFIGURE_SUCCESS,
  GET_PRODUCT_TO_CONFIGURE_FAILURE,
  CHANGE_VARIANT_REQUEST,
  CHANGE_VARIANT_SUCCESS,
  CHANGE_VARIANT_FAILURE,
  UPDATE_PRICE,
  INCREMENT_ITEM,
  DECREMENT_ITEM,
  INCREMENT_SUB_ITEM,
  DECREMENT_SUB_ITEM,
  EDIT_PRODUCT_CONFIGURATION,
  CANCEL_EDIT_PRODUCT_CONFIGURATION,
  CLEAR_PRODUCT_TO_CONFIGURE,
  VALIDATE_PRODUCT,
  VALIDATE_OPTION,
  EDIT_PREVIOUS_PRODUCT_CONFIGURATION_SUCCESS,
  EDIT_PREVIOUS_PRODUCT_CONFIGURATION_REQUEST,
  REMOVE_ITEM,
  REMOVE_SUB_ITEM,
  SET_DRAWER_MODAL_OPEN,
  ADD_ORDER_INSTRUCTION,
} from './productCustomizerTypes';
import api from '../../api';
import flattenProduct from '../../utils/flattenProduct';
import calculatePrice from '../../utils/calculatePrice';
import updateProductErrors from './utils/updateProductErrors';
import updateOptionError from './utils/updateOptionError';
import useDefaultItems from './utils/useDefaultItems';
import { transformComboItemForProductCustomizer } from '../Combo/comboTransformers';
import perserveOnVariantChange from './utils/perserveOnVariantChange';
import { PICKUP, DELIVERY, DROPOFF } from '../OrderSetting/orderSettingTypes';
import removeDefaultItems from './utils/removeDefaultItems';

export function getProduct(productId, businessId, variantId, product) {
  let defaultCustomization = '';
  if (product) {
    defaultCustomization = `&default_customization=${product.has_valid_configuration}`;
  }
  const storedBusinessId = localStorage.getItem('business_id');
  return api.get(
    `/products/${productId}?business_id=${businessId ||
      storedBusinessId}&include=options${
      variantId
        ? `&variant_id=${variantId}`
        : `&variant_id=${product?.prices[0]?.variant_id}`
    }${defaultCustomization}`
  );
}

export function getProductToConfigure(
  product_id,
  business_id,
  offer,
  variant_id
) {
  return function(dispatch, getState) {
    let productsModified;
    dispatch({
      type: GET_PRODUCT_TO_CONFIGURE_REQUEST,
      hasErrors: true,
    });
    let businessId = business_id;

    if (!businessId) {
      const orderSettingReducer = getState().orderSettingReducer;
      if (orderSettingReducer.interOrderType === DELIVERY) {
        businessId = orderSettingReducer.interDeliveryDetails.id;
      } else if (orderSettingReducer.interOrderType === DROPOFF) {
        businessId = orderSettingReducer.interDropOffDetails.id;
      } else {
        businessId = orderSettingReducer.interPickUpDetails.id;
      }
    }
    const comboCustomizerIsVisible = getState().comboReducer
      .comboCustomizerIsVisible;
    const selectedProduct = getState().menuReducer.products.find(
      (product) => product.product_id === product_id
    );

    return getProduct(product_id, businessId, variant_id, selectedProduct).then(
      (response) => {
        let responseData = JSON.parse(JSON.stringify(response.data.data));
        if (comboCustomizerIsVisible) {
          responseData = transformComboItemForProductCustomizer(
            getState(),
            response.data.data
          );
        }

        if (offer && offer.web_conditions) {
          const pricesModified = responseData.prices.filter((variant) => {
            if (offer.web_conditions.eligible_variants)
              return offer.web_conditions.eligible_variants.includes(
                variant.variant_id
              );
          });

          if (!pricesModified.length) productsModified = responseData;
          else productsModified = { ...responseData, prices: pricesModified };
        } else {
          productsModified = responseData;
        }
        dispatch({
          type: GET_PRODUCT_TO_CONFIGURE_SUCCESS,
          response: { data: responseData },
          flatProduct: {
            ...useDefaultItems(flattenProduct(productsModified)),
            productLocationId: businessId,
          },
          selectedProduct: response.data.data,
          productCustomizerIsVisible: !comboCustomizerIsVisible,
        });
      },
      (error) => {
        dispatch({ type: GET_PRODUCT_TO_CONFIGURE_FAILURE, error });
      }
    );
  };
}

export function editPreviousProductConfiguration(
  productToEdit,
  isFlattened,
  isFavourite,
  favouriteId
) {
  return function(dispatch, getState) {
    const {
      interOrderType,
      interDeliveryDetails,
      interPickUpDetails,
      interDropOffDetails,
    } = getState().orderSettingReducer;

    dispatch({ type: EDIT_PREVIOUS_PRODUCT_CONFIGURATION_REQUEST });
    let flatProduct = productToEdit;
    if (!isFlattened) {
      flatProduct = flattenProduct({
        ...productToEdit,
        options: productToEdit.options || [],
      });
    }
    flatProduct = removeDefaultItems(flatProduct);
    productToEdit = {
      singlePrice: flatProduct.price,
      totalPrice: flatProduct.price * flatProduct.quantity,
      isEditing: false,
      isFromPreviousOrder: !isFavourite || false,
      isFromFavourites: isFavourite || false,
      requestGetProductToConfigure: false,
      selectedVariantId: flatProduct.variant_id,
      favouriteId: null,
      flatProduct: flatProduct,
    };

    const location =
      interOrderType === PICKUP && interPickUpDetails
        ? interPickUpDetails
        : interOrderType === DELIVERY &&
          interDeliveryDetails &&
          interDeliveryDetails.deliveryBusiness
        ? interDeliveryDetails.deliveryBusiness
        : interOrderType === DROPOFF && interDropOffDetails
        ? interDropOffDetails
        : null;

    const businessId = location ? location.id : null;

    if (businessId)
      combineProductOptionsAndItems(productToEdit, businessId).then(
        (response) => {
          dispatch({
            type: EDIT_PREVIOUS_PRODUCT_CONFIGURATION_SUCCESS,
            productToEdit: response,
          });
        }
      );
  };
}

export function changeVariant({
  selectedProduct,
  variantId,
  businessId,
  offer,
}) {
  return function(dispatch, getState) {
    const productId = selectedProduct.product_id;
    const storedBusinessId = localStorage.getItem('business_id');

    dispatch({
      type: CHANGE_VARIANT_REQUEST,
      selectedVariantId: variantId,
    });
    const { has_valid_configuration } = getState().menuReducer.products.find(
      (product) => product.product_id === productId
    );
    const selectedBusinessId = getState().orderSettingReducer.selectedLocation
      ?.id;
    api
      .get(
        `/products/${productId}?business_id=${selectedBusinessId}&include=options&variant_id=${variantId}&default_customization=${has_valid_configuration}`
      )
      .then(
        (response) => {
          const selectedProductModified = {
            ...response.data.data,
            prices: selectedProduct.prices,
          };
          dispatch({
            type: CHANGE_VARIANT_SUCCESS,
            response: selectedProductModified,
            flatProduct: perserveOnVariantChange(
              selectedProduct,
              flattenProduct(selectedProductModified)
            ),
          });
        },
        (error) => {
          dispatch({ type: CHANGE_VARIANT_FAILURE, error });
        }
      );
  };
}

export function combineProductOptionsAndItems(productToEdit, businessId) {
  // will need to debug how this function is being used and add has_default_customization
  return getProduct(
    productToEdit.flatProduct.product_id,
    businessId,
    productToEdit.flatProduct.variant_id
  ).then((response) => {
    const flatProduct = flattenProduct(response.data.data);
    let itemsById = {};
    let subItemsById = {};
    Object.keys(flatProduct.itemsById).forEach((itemId) => {
      itemsById[itemId] = {
        ...flatProduct.itemsById[itemId],
        quantity: productToEdit.flatProduct.itemsById[itemId]
          ? productToEdit.flatProduct.itemsById[itemId].quantity
          : 0,
      };
    });
    Object.keys(flatProduct.subItemsById).forEach((itemId) => {
      subItemsById[itemId] = {
        ...flatProduct.subItemsById[itemId],
        quantity: productToEdit.flatProduct.subItemsById[itemId]
          ? productToEdit.flatProduct.subItemsById[itemId].quantity
          : 0,
      };
    });

    return {
      ...productToEdit,
      flatProduct: {
        ...productToEdit.flatProduct,
        options: flatProduct.options,
        optionsById: flatProduct.optionsById,
        subOptionsById: flatProduct.subOptionsById,
        itemsById,
        subItemsById,
      },
    };
  });
}

export function incrementItem(item) {
  return {
    type: INCREMENT_ITEM,
    item,
  };
}

export function decrementItem(item) {
  return {
    type: DECREMENT_ITEM,
    item,
  };
}

export function removeItem(item) {
  return {
    type: REMOVE_ITEM,
    item,
  };
}

export function removeSubItem(subItem) {
  return {
    type: REMOVE_SUB_ITEM,
    subItem,
  };
}

export function incrementSubItem(subItem) {
  return {
    type: INCREMENT_SUB_ITEM,
    subItem,
  };
}

export function decrementSubItem(subItem, quantity) {
  return {
    type: DECREMENT_SUB_ITEM,
    subItem,
    quantity,
  };
}
export function addOrderInstruction(instruction) {
  return {
    type: ADD_ORDER_INSTRUCTION,
    instruction,
  };
}
export function setDrawerModalOpen(open) {
  return {
    type: SET_DRAWER_MODAL_OPEN,
    open,
  };
}

export function updatePrice(flatProduct) {
  const price = calculatePrice(flatProduct);
  return {
    type: UPDATE_PRICE,
    singlePrice: price.singlePrice,
    totalPrice: price.totalPrice,
  };
}

export function editProductConfiguration(productToEdit) {
  return function(dispatch, getState) {
    const comboCustomizerIsVisible = getState().comboReducer
      .comboCustomizerIsVisible;
    if (productToEdit.isFromPreviousOrder) {
      const businessId = getState().orderSettingReducer.interPickUpDetails.id;

      if (businessId)
        combineProductOptionsAndItems(productToEdit, businessId).then(
          (response) => {
            dispatch({
              type: EDIT_PRODUCT_CONFIGURATION,
              productToEdit: response,
              isEditingFromOffers: true,
              productCustomizerIsVisible: !comboCustomizerIsVisible,
            });
          }
        );
    } else {
      dispatch({
        type: EDIT_PRODUCT_CONFIGURATION,
        productToEdit,
        isEditing: productToEdit.isEditing,
        productCustomizerIsVisible: !comboCustomizerIsVisible,
      });
    }
  };
}

export function cancelEditProductConfiguration() {
  return {
    type: CANCEL_EDIT_PRODUCT_CONFIGURATION,
  };
}

export function clearProductToConfigure() {
  return {
    type: CLEAR_PRODUCT_TO_CONFIGURE,
  };
}

export function validateProduct(validatedProduct) {
  return function(dispatch) {
    dispatch({
      type: VALIDATE_PRODUCT,
      validatedProduct,
    });
  };
}

export function validateOption(option, flatProduct) {
  return {
    type: VALIDATE_OPTION,
    validatedOption: updateOptionError(option, flatProduct),
  };
}
