import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Title1, Headline } from '../../../../components/Typography';
import { PrimaryButton } from '../../../../components/Buttons';
import Select from '../../../../components/Select';
import OrderFutureSchedule from '../../OrderSettingModal/components/OrderFutureSchedule';
import appConfig from '../../../../../app-config';
import SkeletonLoading from '../../OrderSettingModal/components/SkeletonLoading';
import {
  renderConfirmButtonText,
  isConfirmButtonDisabled,
} from '../../../OrderSetting/OrderSettingModal/utils/helper';
import ConfirmButton from '../../../OrderSetting/OrderSettingModal/components/ConfirmButton';
import { isScheduleRadioDisabledCalculated } from '../../OrderSettingModal/utils/helper';
import UnavailableMessage from '../../../OrderSetting/OrderSettingModal/components/UnavailableMessage';
import UnavailableProducts from '../../OrderSettingModal/components/UnavailableProducts';
import { updateInterDeliveryDetails } from '../../orderSettingActions';

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  padding: 32px;
  max-width: 500px;

  // height: 1000px;
  @media (min-width: 786px) {
    width: 500px;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const FormWrapper = styled.div`
  position: relative;
  margin-top: 14px;
  display: grid;
  gap: 21px;
`;

const SelectLabel = styled.p`
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  // grid-template-rows: 1fr;
  grid-gap: 14px;
  margin-top: 40px;
`;

const Delete = styled(Headline)`
  color: ${(props) =>
    props.disabled
      ? props.theme.primaryButtonDisabledColor
      : props.theme.black};
  align-self: center;
  cursor: pointer;
`;

const SaveButton = styled(PrimaryButton)`
  width: 70%;
  margin: auto;
  height: 56px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const DeleteButton = styled(PrimaryButton)`
  width: 70%;
  margin: auto;
  height: 56px;
  font-size: 19px;
  font-weight: 600;

  background-color: ${(props) =>
    props.theme.deleteLocationButtonBackgroundColor};
  color: ${(props) => props.theme.deleteLocationButtonTextColor};

  &:hover {
    background-color: ${(props) =>
      props.theme.deleteLocationButtonBackgroundColor};
    color: ${(props) => props.theme.deleteLocationButtonTextColor};
    transition: all 0.25s ease;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const DeliveryTimeForm = ({
  setDeliveryTimeFormIsVisible,
  interDeliveryDetails,
  interOrderType,
  interSelectedDateTime,
  updateInterSelectedDateTime,
  confirmDeliveryDetails,
  availableTimes,
  selectedDateTime,
  isTimeNoLongerValid,
  resetIsTimeNoLongerValid,
  getTimeIntervalsDate,
  specialHours,
  orderPacingData,
  orderMessage,
  isOnline,
  emptySearchBox,
  deepLinkParameters,
  isConfirmMarketPlaceDeliveryDetailsRequest,
  handleConfirmDeliveryButton,
  isUnavailableProductInCartRequest,
  unavailableProducts,
  cartErrorMessage,
  checkUnavailableProductForMarketplace,
  resetUnavailableProducts,
  cartReducer,
  resetCartErrorMessages,
  updateInterDeliveryDetails,
  confirmMarketplaceDeliverySelection,
  updateOrderSettingModalIsVisible,
  selectedLocation,
  onSeeMenu,
  selectMarketplaceDeliveryLocation,
  updateCartWithUnavailbleProducts,
  isAuthenticated,
  emptyCart,
  updateUnavailableProducts,
  setIsMenuDisabled,
  getMenu,
  getCombos,
  validateCartForLocation,
}) => {
  const [maxFutureOrderDate, setMaxFutureOrderDate] = useState();
  const [isAsapRadioDisabled, setIsAsapRadioDisabled] = useState();
  const [isScheduleRadioDisabled, setIsScheduleRadioDisabled] = useState();

  const updateTimeParams = useCallback(() => {
    const newMaxFutureOrderDate = interDeliveryDetails?.max_future_order_date;
    let newOrderEndTime = null;
    if (availableTimes) {
      newOrderEndTime =
        availableTimes?.length > 0
          ? availableTimes[1]?.order_end_time
          : availableTimes[0]?.order_end_time;
    }
    const isAsapRadioDisabled =
      !interDeliveryDetails?.is_asap_order_enabled ||
      !interDeliveryDetails.accepting_orders;
    const isScheduleRadioDisabled = isScheduleRadioDisabledCalculated(
      availableTimes,
      newMaxFutureOrderDate,
      newOrderEndTime,
      specialHours,
      isOnline
    );
    setMaxFutureOrderDate(setMaxFutureOrderDate);
    setIsAsapRadioDisabled(isAsapRadioDisabled);
    setIsScheduleRadioDisabled(isScheduleRadioDisabled);
  }, [interDeliveryDetails]);

  useEffect(() => {
    updateTimeParams();
  }, [updateTimeParams]);
  const checkProductAvialibility = useCallback(() => {
    if (
      interSelectedDateTime?.radioValue?.value === 'asap' ||
      (interSelectedDateTime?.radioValue?.value === 'schedule' &&
        interSelectedDateTime?.dateTime?.time?.value?.length)
    ) {
      validateCartForLocation(interDeliveryDetails);
    }
  }, [interSelectedDateTime]);
  useEffect(() => {
    checkProductAvialibility();
  }, [checkProductAvialibility]);
  return (
    <Wrapper>
      <TitleWrapper>
        <Title1 style={{ fontWeight: '600', marginBottom: '20px' }}>
          Delivery Time
        </Title1>

        <CloseButton
          onClick={() => {
            setDeliveryTimeFormIsVisible(false, null, true);
          }}
        >
          <i
            className="fas fa-times"
            style={{ fontSize: '24px', cursor: 'pointer' }}
          ></i>
        </CloseButton>
      </TitleWrapper>
      <div style={{ marginBottom: '32px' }}>
        {isConfirmMarketPlaceDeliveryDetailsRequest ? (
          <SkeletonLoading />
        ) : (
          <OrderFutureSchedule
            interDeliveryDetails={interDeliveryDetails}
            interOrderType={interOrderType}
            interSelectedDateTime={interSelectedDateTime}
            selectedLocation={selectedLocation}
            updateInterSelectedDateTime={updateInterSelectedDateTime}
            confirmDeliveryDetails={confirmDeliveryDetails}
            availableTimes={availableTimes}
            selectedDateTime={selectedDateTime}
            maxFutureOrderDate={maxFutureOrderDate}
            deliveryBusiness={interDeliveryDetails?.deliveryBusiness}
            isTimeNoLongerValid={isTimeNoLongerValid}
            resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
            isLocationOrDelivBusinesssAcceptingOrders={
              interDeliveryDetails?.accepting_orders
            }
            getTimeIntervalsDate={getTimeIntervalsDate}
            specialHours={specialHours}
            orderPacingData={orderPacingData}
            orderMessage={appConfig.orderMessageDelivery}
            isOnline={interDeliveryDetails?.deliveryBusiness?.settings.online}
            emptySearchBox={() => emptySearchBox()}
            deepLinkParameters={deepLinkParameters}
            isMarketplaceDelivery={true}
            setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
            is_asap_order_enabled={!isAsapRadioDisabled}
            is_future_order_enabled={
              interDeliveryDetails?.is_future_order_enabled
            }
            cartReducer={cartReducer}
            onSeeMenu={onSeeMenu}
            selectMarketplaceDeliveryLocation={
              selectMarketplaceDeliveryLocation
            }
            emptyCart={emptyCart}
          />
        )}
      </div>
      {!isConfirmMarketPlaceDeliveryDetailsRequest && (
        <ConfirmButton
          text={'Confirm'}
          onClick={() => {
            getCombos(selectedLocation.id);
            getMenu(selectedLocation.id, 'delivery');
            selectMarketplaceDeliveryLocation();
            if (!isAuthenticated) {
              emptyCart();
            } else if (unavailableProducts && unavailableProducts.length > 0) {
              updateCartWithUnavailbleProducts(unavailableProducts);
            }
            setIsMenuDisabled(false);
            confirmDeliveryDetails(true);
            setDeliveryTimeFormIsVisible(false, selectedLocation);
            updateOrderSettingModalIsVisible(false);
            resetCartErrorMessages();
            window.scrollTo(0, 0);
          }}
          disabled={
            !interSelectedDateTime?.radioValue?.value ||
            (interSelectedDateTime?.radioValue?.value === 'schedule' &&
              interSelectedDateTime?.dateTime?.time?.value === '')
          }
        />
      )}
    </Wrapper>
  );
};

export default withStyles(styles)(DeliveryTimeForm);
