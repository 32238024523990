import styled from 'styled-components';
import appConfig from '../../../app-config';

const Title2 = styled.h2`
  font-size: ${props => props.theme.title2DefaultFontSize};
  color: ${props => props.theme.title2DefaultFontColor};
  font-weight: ${props => props.theme.title2DefaultFontWeight};
  text-transform: ${props => appConfig.appSource === "raahi" ? 'uppercase' : props.textTransform || 'unset'};
  text-align: ${props => props.textAlign || 'inherit'};
  margin: 0;
  font-family: ${props => props.theme.primaryFontFontFamily};
`;

export default Title2;
