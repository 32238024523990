import React from 'react';
import { connect } from 'react-redux';
import { Title3, Caption1, Callout } from '../Typography';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { showPaymentMethodForm } from '../../modules/PaymentMethods/paymentMethodsActions';
import { isNullableType } from 'graphql';

const Wrapper = styled.div`
  padding-left: ${(props) => (props.isForCheckoutPage ? '80px' : '0')};
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  @media (max-width: 786px) {
    padding-left: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  align-items: center;
  justify-self: center;
  cursor: pointer;
  border: solid 2px #dbdbdb;
  padding: 19px 16px 19px 24px;
  border-radius: 6px;
  width: 100%;

  :hover {
    border-color: ${(props) => props.theme.addCreditCardBorderColorHover};
    color: ${(props) => props.theme.addCreditCardBorderColorHover};
    transition: all 0.25s ease;
  }
`;

const ChangeButton = styled(Caption1)`
  cursor: pointer;
  justify-self: flex-end;
  margin-top: 12px;
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

const CalloutWrapper = styled(Callout)`
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

const AddNewPaymentMethodButton = ({
  showPaymentMethodForm,
  isForCheckoutPage,
  noDialogBackground,
  showChangePaymentMethodButton,
  showSelectPaymentMethodForm,
}) => {
  return (
    <Wrapper isForCheckoutPage={isForCheckoutPage}>
      <ButtonWrapper
        id="AddPaymentMethodButton"
        onClick={() =>
          isForCheckoutPage
            ? navigate('/payment-methods')
            : showPaymentMethodForm(noDialogBackground)
        }
      >
        <div style={{ fontWeight: 600 }}>
          {isForCheckoutPage ? '+ Add a Payment Method' : '+ Add a Credit Card'}
        </div>
        <div style={{ textAlign: 'right' }}>
          <i
            className="fas fa-credit-card-blank"
            style={{ fontSize: '29px' }}
          />
        </div>
      </ButtonWrapper>
      {showChangePaymentMethodButton ? (
        <ChangeButton
          id="CartPaymentMethod-ChangeButton"
          onClick={() => showSelectPaymentMethodForm(true)}
          style={{ alignContent: 'flex-end' }}
        >
          <CalloutWrapper>Change Payment Method</CalloutWrapper>
        </ChangeButton>
      ) : null}
    </Wrapper>
  );
};

export default connect(null, { showPaymentMethodForm })(
  AddNewPaymentMethodButton
);
