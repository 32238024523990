import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../registrationActions';
import RegistrationForm from './RegistrationForm';

const RegistrationContainer = ({
  registrationReducer,
  authReducer,
  createAccount,
  setSignupFailError,
  minPasswordLength,
  registrationError,
  clearRegisterationErrorMessage,
}) => {
  const { requestingCreateAccount, signUpFailureError } = registrationReducer;

  const isBirthdayEnabled = authReducer.appConfig.get_birthday;

  const showReferralCode = authReducer.appConfig.show_referral_code;

  return (
    <RegistrationForm
      setSignupFailError={setSignupFailError}
      createAccount={createAccount}
      isRequesting={requestingCreateAccount}
      isBirthdayEnabled={isBirthdayEnabled}
      showReferralCode={showReferralCode}
      signUpFailureError={signUpFailureError}
      minPasswordLength={minPasswordLength}
      registrationError={registrationError}
      clearRegisterationErrorMessage={clearRegisterationErrorMessage}
    />
  );
};

const mapStateToProps = (state) => ({
  registrationReducer: state.registrationReducer,
  authReducer: state.authReducer,
  minPasswordLength: state.authReducer.appConfig.password_complexity_min_length,
  registrationError: state.registrationReducer.registrationError,
});

export default connect(mapStateToProps, actions)(RegistrationContainer);
