import {
  ADD_TO_FAVOURITES_SUCCESS,
  CLEAR_SELECTED_FAVOURITE_ID,
  DELETE_FAVOURITE_SUCCESS,
  GET_FAVOURITES_SUCCESS,
} from './favouritesTypes';

const initialState = {
  selectedFavouriteId: null,
  favouritesList: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_FAVOURITES_SUCCESS:
      return {
        ...state,
        selectedFavouriteId: action.selectedFavouriteId,
        favouritesList: [
          ...state.favouritesList,
          {
            favouriteId: action.selectedFavouriteId,
            flatProduct: action.flatProduct,
          },
        ],
      };
    case DELETE_FAVOURITE_SUCCESS:
      return {
        ...state,
        selectedFavouriteId: null,
        favouritesList: state.favouritesList
          .filter(favourite => favourite.favouriteId !== action.favouriteId)
          .slice(0),
      };
    case CLEAR_SELECTED_FAVOURITE_ID:
      return {...state, selectedFavouriteId: null};
    case GET_FAVOURITES_SUCCESS:
      return {
        ...state,
        favouritesList: action.favouritesList,
      };
    default:
      return state;
  }
}
