import {
  ADD_TO_CART,
  ADD_COMBO_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  INCREMENT_PRODUCT,
  DECREMENT_PRODUCT,
  VALIDATE_CART_REQUEST,
  VALIDATE_CART_SUCCESS,
  VALIDATE_CART_FAILURE,
  CHANGE_TIP_PERCENTAGE,
  CHANGE_SPECIAL_INSTRUCTIONS,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  INVALIDATE_CART,
  TOGGLE_PRODUCT_IS_FAVOURITED,
  UNFAVOURITE_BY_FAVOURITE_ID,
  SET_IS_FOR_PREVIEW,
  RESET_INVALID_TIP_FLAG,
  INITIALIZE_VALIDATE_CART_WITH_NULL,
  EMPTY_CART,
  REMOVE_COMBO_FROM_CART,
  UPDATE_CART_PRODUCTS,
  UPDATE_CART_SCREEN,
  UPDATE_CART_REQUEST, //GLOBAL CART
  SET_CHECKOUT_AS_GUEST_BUTTON_IS_VISIBLE,
  SET_SHOW_GUEST_PERSONAL_DETAILS_PAGE,
  SET_ACCOUNT_FOUND_MODAL_IS_VISIBLE,
  SET_GUEST_PERSONAL_DETAILS,
  VALIDATE_GUEST_CART_REQUEST,
  VALIDATE_GUEST_CART_SUCCESS,
  VALIDATE_GUEST_CART_FAILURE,
  RESET_GUEST_PERSONAL_DETAILS,
  PLACE_GUEST_ORDER_REQUEST,
  PLACE_GUEST_ORDER_SUCCESS,
  PLACE_GUEST_ORDER_FAILURE,
  RESET_TIP_PERCENTAGE,
} from './cartTypes';
import {
  DELIVERY,
  DROPOFF,
  PICKUP,
  RESET_ORDER_SETTING_TIME_SETTINGS,
} from '../OrderSetting/orderSettingTypes';
import api from '../../api';
import isValidCreditCard from '../../utils/isValidCreditCard';
import { createAlert } from '../Alert/alertActions';
import flattenProduct from '../../utils/flattenProduct';
import calculatePrice from '../../utils/calculatePrice';
import { getPaymentMethods } from '../PaymentMethods/paymentMethodsActions';
import identifyCartChanges from '../OrderSetting/utils/identifyCartChanges';
import { setIsGuestCheckoutInitialized } from '../Auth/authActions';
import { useDispatch } from 'react-redux';
// import identifyCartChanges from '../OrderSetting/utils/identifyCartChanges';
import {
  confirmDropOffDetails,
  formatSelectedDateTime,
  setInterDeliveryDetailsId,
} from '../OrderSetting/orderSettingActions';
import productForCartValidation from './utils/productForCartValidation';
import comboForCartValidation from './utils/comboForCartValidation';
import moment from 'moment';
import appConfigFile from '../../../app-config';

const PATTERN_PAYLOAD = '$$PAYLOAD$$';

export function addToCart(product, notFavourite) {
  return {
    type: ADD_TO_CART,
    product: notFavourite
      ? { ...product, isFromFavourite: false, favouriteId: null }
      : product,
  };
}

export const addComboToCart = (comboData) => {
  return {
    type: ADD_COMBO_TO_CART,
    comboData,
  };
};

export function initializeValidateCartWithNull() {
  return {
    type: INITIALIZE_VALIDATE_CART_WITH_NULL,
  };
}

export function removeProductFromCart(productIndexInCart) {
  return {
    type: REMOVE_PRODUCT_FROM_CART,
    productIndexInCart,
  };
}

export const removeComboFromCart = (comboIndexInCart) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_COMBO_FROM_CART,
      comboIndexInCart,
    });
  };
};

export function incrementProduct(productIndexInCart) {
  return {
    type: INCREMENT_PRODUCT,
    productIndexInCart,
  };
}

export function decrementProduct(productIndexInCart) {
  return {
    type: DECREMENT_PRODUCT,
    productIndexInCart,
  };
}

export function emptyCart() {
  return function(dispatch) {
    dispatch({ type: EMPTY_CART });
  };
}
// GLOBAL
export function clearErrorMessage() {
  return function(dispatch) {
    dispatch({ type: 'CLEAR_CART_ERROR_MESSAGE', errors: null });
  };
}
export function updateGlobalCart(updatedCart) {
  return function(dispatch) {
    dispatch({ type: UPDATE_CART_REQUEST, updatedCart: updatedCart });
  };
}
// GLOBAL CART
export function validateGlobalCart(fromLocationsScreen = false) {
  return function(dispatch, getState) {
    const {
      cart,
      products,
      combos,
      cartScreen,
      guestPersonalDetails,
      specialInstructions,
    } = getState().cartReducer;
    const isPayOnArrivalSelected = getState().paymentMethodsReducer
      .isPayOnArrivalSelected;
    const tipPercentage = isPayOnArrivalSelected
      ? parseFloat(0)
      : parseFloat(cart.tip);
    const { isAuthenticated, appConfig } = getState().authReducer;
    const selectedDropOffLocation = getState().dropOffLocationReducer
      .selectedDropOffLocation;

    const deliveryToAddress = getState().deliveryAddressesReducer
      .deliveryToAddress;

    const {
      interPickUpDetails,
      interDeliveryDetails,
      interDropOffDetails,
      interOrderType,
      interSelectedDateTime,
    } = getState().orderSettingReducer;

    const location =
      interOrderType === PICKUP
        ? interPickUpDetails
        : interOrderType === DELIVERY
        ? interDeliveryDetails
        : interOrderType === DROPOFF
        ? interDropOffDetails
        : null;

    let order_date;
    if (interSelectedDateTime.radioValue.value === 'schedule') {
      order_date = interSelectedDateTime.dateTime.date
        ? moment(
            interSelectedDateTime.dateTime.date.name +
              ' ' +
              interSelectedDateTime.dateTime.time.name,
            'ddd MMM DD h:mm A'
          ).format('YYYY-MM-DD HH:mm:ss')
        : null;
    }

    const isValidChoice =
      location && interOrderType !== DELIVERY && location.settings
        ? (interSelectedDateTime.radioValue.value === 'asap' &&
            location.settings.is_asap_order_enabled) ||
          (interSelectedDateTime.radioValue.value === 'schedule' &&
            location.settings.is_future_order_enabled)
        : location && interOrderType === DELIVERY && location.deliveryBusiness
        ? (interSelectedDateTime.radioValue.value === 'asap' &&
            location.deliveryBusiness.settings.is_asap_order_enabled) ||
          (interSelectedDateTime.radioValue.value === 'schedule' &&
            location.deliveryBusiness.settings.is_future_order_enabled)
        : false;

    if (location && isValidChoice) {
      dispatch({ type: VALIDATE_CART_REQUEST });

      const endpoint =
        !isAuthenticated && appConfig.guest_checkout_enabled
          ? '/guest/cart/validate'
          : '/cart/validate';

      let payload = {
        business_id: location.id,

        tip: tipPercentage || 0,
        cart: [
          ...products.map((product) =>
            productForCartValidation(product.flatProduct)
          ),
          ...comboForCartValidation(combos || []),
        ],
        order_type: interOrderType,
      };

      if (order_date)
        payload = {
          ...payload,
          order_date,
        };

      if (
        interOrderType === DELIVERY &&
        !isAuthenticated &&
        location &&
        appConfig.guest_checkout_enabled
      ) {
        const {
          city,
          line1,
          line2,
          state,
          country,
          zip,
          type,
          is_preferred,
          details,
        } = deliveryToAddress;
        payload = {
          ...payload,
          delivery_address: {
            line1,
            line2,
            city,
            state,
            zip,
            country,
            type,
            is_preferred,
            details,
          },
        };
      } else if (
        interOrderType === DELIVERY &&
        isAuthenticated &&
        deliveryToAddress
      ) {
        payload = {
          ...payload,
          customer_address_id: deliveryToAddress.id,
        };
      } else if (interOrderType === DROPOFF && isAuthenticated && location) {
        let dropOffLocation = [...selectedDropOffLocation];
        const dropOffLocationObject = dropOffLocation
          .reverse()
          .reduce((obj, selection) => {
            return {
              id: selection.id,
              option: { ...obj },
            };
          }, {});

        payload = {
          ...payload,
          drop_off_location: dropOffLocationObject,
        };
      }

      // if (
      //   fromLocationsScreen ||
      //   (isAuthenticated &&
      //     (cartScreen === 'review' || cartScreen === 'checkout')) ||
      //   (!isAuthenticated &&
      //     // appConfig?.guest_checkout_enabled &&
      //     // guestPersonalDetails.email !== '' &&
      //     cartScreen === 'review') ||
      //   cartScreen === 'checkout' ||
      //   cartScreen === 'guest-order'
      // )
      return api
        .post(endpoint, payload, { ignore_interception: true })
        .then((response) => {
          let validatedFlatProducts = [];
          response.data.data.products.map((product) => {
            let flatProduct = flattenProduct({
              ...product,
              options: product.options || [],
            });
            flatProduct.name = product.name;
            const price = calculatePrice(flatProduct);

            validatedFlatProducts.push({ ...flatProduct, ...price });
          });
          dispatch({
            type: VALIDATE_CART_SUCCESS,
            tipPercentage: tipPercentage,
            response: {
              ...response.data.data,
              validatedFlatProducts: validatedFlatProducts,
            },
            requestedCart: cart,
          });
          dispatch({
            type: 'UPDATE_CART_ERROR_MESSAGE',
            cartErrorMessage: [],
          });

          if (cartScreen === 'review' && isAuthenticated)
            dispatch({
              type: UPDATE_CART_SCREEN,
              cartScreen: 'checkout',
            });
        })
        .catch((error) => {
          dispatch({
            type: 'UPDATE_CART_ERROR_MESSAGE',
            errors: error.response.data.errors,
          });
          if (
            error.response.status === 400 &&
            error.response.data.errors.message === 'Invalid cart'
          ) {
            const newCart =
              error.response.data?.errors?.details?.new_cart?.cart || [];

            const products = getState().cartReducer.products;
            const combos = getState().cartReducer.combos;
            const unavailableProducts = identifyCartChanges(
              newCart,
              products,
              combos
            );

            dispatch({
              type: 'UPDATE_UNAVAILABLE_PRODUCTS',
              unavailableProducts,
            });

            if (
              !appConfigFile.isMarketplaceDelivery &&
              !appConfigFile.parentChildClient
            )
              dispatch(updateCartWithUnavailbleProducts(unavailableProducts));

            if (cartScreen === 'checkout')
              dispatch({
                type: UPDATE_CART_SCREEN,
                cartScreen: 'review',
              });
          } else {
            const products = getState().cartReducer.products;
            const combos = getState().cartReducer.combos;
            const unavailableProducts = identifyCartChanges(
              null,
              products,
              combos
            );
            dispatch({
              type: 'UPDATE_UNAVAILABLE_PRODUCTS',
              unavailableProducts,
            });
          }
        });
    }
  };
}

export function updateUnavailableProducts(products) {
  return function(dispatch) {
    dispatch({
      type: 'UPDATE_UNAVAILABLE_PRODUCTS',
      unavailableProducts: products,
    });
  };
}

export function validateCartForLocation(location) {
  return function(dispatch, getState) {
    const {
      cart,
      products,
      combos,
      cartScreen,
      specialInstructions,
    } = getState().cartReducer;

    const deliveryToAddress = getState().deliveryAddressesReducer
      .deliveryToAddress;

    const {
      interOrderType,
      interSelectedDateTime,
    } = getState().orderSettingReducer;

    const tipPercentage = parseFloat(cart.tip);
    const { isAuthenticated, appConfig } = getState().authReducer;
    const selectedDropOffLocation = getState().dropOffLocationReducer
      .selectedDropOffLocation;

    let order_date;
    if (interSelectedDateTime.radioValue.value === 'schedule') {
      order_date = interSelectedDateTime.dateTime.date
        ? moment(
            interSelectedDateTime.dateTime.date.name +
              ' ' +
              interSelectedDateTime.dateTime.time.name,
            'ddd MMM DD h:mm A'
          ).format('YYYY-MM-DD HH:mm:ss')
        : null;
    }
    if (location) {
      dispatch({ type: VALIDATE_CART_REQUEST });

      const endpoint = '/cart/validate';

      let payload = {
        business_id: location?.id,
        tip: tipPercentage || 0,
        cart: [
          ...products.map((product) =>
            productForCartValidation(product.flatProduct)
          ),
          ...comboForCartValidation(combos || []),
        ],
        order_type: interOrderType,
      };
      if (order_date) {
        payload = { ...payload, order_date: order_date };
      }
      if (interOrderType === DELIVERY && isAuthenticated && deliveryToAddress) {
        if (deliveryToAddress.id) {
          payload = {
            ...payload,
            customer_address_id: deliveryToAddress.id,
          };
        } else return;
      } else if (interOrderType === DROPOFF && isAuthenticated && location) {
        let dropOffLocation = [...selectedDropOffLocation];
        const dropOffLocationObject = dropOffLocation
          .reverse()
          .reduce((obj, selection) => {
            return {
              id: selection.id,
              option: { ...obj },
            };
          }, {});

        payload = {
          ...payload,
          drop_off_location: dropOffLocationObject,
        };
      }

      if (isAuthenticated && payload.cart.length > 0)
        //FILL CART ERROR MESSAGE AND PROCEED
        return api
          .post(endpoint, payload, { ignore_interception: true })
          .then((response) => {
            let validatedFlatProducts = [];
            response.data.data.products.map((product) => {
              let flatProduct = flattenProduct({
                ...product,
                options: product.options || [],
              });
              flatProduct.name = product.name;
              const price = calculatePrice(flatProduct);

              validatedFlatProducts.push({ ...flatProduct, ...price });
            });
            dispatch({
              type: VALIDATE_CART_SUCCESS,
              tipPercentage: tipPercentage,
              response: {
                ...response.data.data,
                validatedFlatProducts: validatedFlatProducts,
              },
              requestedCart: cart,
            });
            dispatch({
              type: 'UPDATE_CART_ERROR_MESSAGE',
              cartErrorMessage: [],
            });
          })
          .catch((error) => {
            if (error.response.data.errors.details)
              dispatch({
                type: 'UPDATE_CART_ERROR_MESSAGE',
                errors: error.response.data.errors.details.cart_changes,
              });

            if (
              error.response.status === 400 &&
              error.response.data.errors.message === 'Invalid cart'
            ) {
              const newCart =
                error.response.data?.errors?.details?.new_cart?.cart || [];

              const products = getState().cartReducer.products;
              const combos = getState().cartReducer.combos;
              const unavailableProducts = identifyCartChanges(
                newCart,
                products,
                combos
              );

              dispatch({
                type: 'UPDATE_UNAVAILABLE_PRODUCTS',
                unavailableProducts,
              });

              if (
                !appConfigFile.isMarketplaceDelivery &&
                !appConfigFile.parentChildClient
              )
                dispatch(updateCartWithUnavailbleProducts(unavailableProducts));
            } else {
              const products = getState().cartReducer.products;
              const combos = getState().cartReducer.combos;
              const unavailableProducts = identifyCartChanges(
                null,
                products,
                combos
              );
              dispatch({
                type: 'UPDATE_UNAVAILABLE_PRODUCTS',
                unavailableProducts,
              });
            }
          });
    }
  };
}

export function validateGuestCart(cart) {
  return function(dispatch, getState) {
    const { tipPercentage } = getState().cartReducer;
    dispatch({
      type: 'UPDATE_CART_ERROR_MESSAGE',
      cartErrorMessage: [],
    });
    console.log('cart', cart);
    dispatch({ type: VALIDATE_GUEST_CART_REQUEST });
    return api
      .post('/guest/cart/validate', { ...cart })
      .then((response) => {
        let validatedFlatProducts = [];
        response.data.data.products.map((product) => {
          let flatProduct = flattenProduct({
            ...product,
            options: product.options || [],
          });

          flatProduct.name = product.name;
          const price = calculatePrice(flatProduct);

          validatedFlatProducts.push({ ...flatProduct, ...price });
        });
        dispatch({
          type: 'UPDATE_CART_ERROR_MESSAGE',
          cartErrorMessage: [],
        });
        dispatch({
          type: VALIDATE_GUEST_CART_SUCCESS,
          tipPercentage: tipPercentage,
          response: {
            ...response.data.data,
            validatedFlatProducts: validatedFlatProducts,
          },
          requestedCart: cart,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_CART_ERROR_MESSAGE',
          errors: error.response.data.errors,
        });
      });
  };
}

export const changeTipPercentage = (tip) => (dispatch) => {
  return function(dispatch) {
    dispatch({
      type: CHANGE_TIP_PERCENTAGE,
      tipPercentage: tip,
    });
  };
};

export function resetTipPercentage() {
  return {
    type: RESET_TIP_PERCENTAGE,
  };
}

export function changeSpecialInstructions(specialInstructions) {
  return {
    type: CHANGE_SPECIAL_INSTRUCTIONS,
    specialInstructions,
  };
}

export function placeOrder(orderConfig) {
  return function(dispatch, getState) {
    const isPayOnArrivalSelected = getState().paymentMethodsReducer
      .isPayOnArrivalSelected;
    orderConfig = {
      ...orderConfig,
      tip: isPayOnArrivalSelected ? 0 : getState().cartReducer.tipPercentage,
    };
    const coords = getState().locationsReducer.currentLocation;
    const selectedPaymentMethod = getState().paymentMethodsReducer
      .selectedPaymentMethod;
    const selectedLocationId = getState().orderSettingReducer.selectedLocation
      .id;
    if (coords) {
      orderConfig = {
        ...orderConfig,
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    } else {
      orderConfig = {
        ...orderConfig,
        latitude: 0,
        longitude: 0,
      };
    }
    const creditCard = getState().paymentMethodsReducer.paymentMethods.find(
      (card) => card.is_preferred
    );

    if (
      selectedPaymentMethod !== 'poa' &&
      creditCard &&
      creditCard.type === 'credit_card' &&
      creditCard.details.expiry_date.slice(5, 7) > 12
    ) {
      dispatch({
        type: 'UPDATE_CART_ERROR_MESSAGE',
        errors: {
          details: null,
          message: 'Invalid Expiry Date',
        },
      });
      // return dispatch(
      //   createAlert({ type: 'error', message: 'Invalid Expiry Date' })
      // );
    }
    if (
      selectedPaymentMethod !== 'poa' &&
      creditCard &&
      creditCard.type === 'credit_card' &&
      !isValidCreditCard(
        creditCard.details.expiry_date.slice(5, 7),
        creditCard.details.expiry_date.slice(0, 4)
      )
    ) {
      dispatch({
        type: 'UPDATE_CART_ERROR_MESSAGE',
        errors: {
          details: null,
          message: 'Expired Credit Card',
        },
      });
      // return dispatch(
      //   createAlert({ type: 'error', message: 'Expired Credit Card' })
      // );
    }
    dispatch({ type: PLACE_ORDER_REQUEST });
    return api.post('/payment-code').then((response) => {
      // get address
      const customer_address_id =
        getState().orderSettingReducer.orderType === DELIVERY
          ? getState().deliveryAddressesReducer.deliveryToAddress?.id
          : null;

      // get dropoff location
      const dropOffLocation =
        getState().orderSettingReducer.orderType === DROPOFF
          ? getState().orderSettingReducer.interDropOffDetails.id
          : null;
      // make base body
      let qr_code = response.data.data.pattern;
      qr_code = qr_code.replace(
        PATTERN_PAYLOAD,
        response.data.data.payment_code
      );

      let body = null;
      if (selectedPaymentMethod === 'poa') {
        body = {
          ...orderConfig,
          is_loyalty: true,
        };
      } else {
        body = {
          ...orderConfig,
          qr_code,
        };
      }

      // if delivery address, add it

      if (selectedLocationId) {
        body = {
          ...body,
          business_id: selectedLocationId,
        };
      }
      if (customer_address_id) {
        body = {
          ...body,
          customer_address_id,
        };
      }
      api
        .post('/orders', body, { ignore_interception: true })
        .then((response) => {
          let placedOrder = {
            ...response.data.data,
          };
          if (customer_address_id) {
            let deliveryAddress = getState().deliveryAddressesReducer.deliveryAddressesList.find(
              (deliveryAddress) => deliveryAddress.id === customer_address_id
            );
            placedOrder = {
              ...placedOrder,
              orderType: DELIVERY,
              address: deliveryAddress,
            };
          } else if (dropOffLocation) {
            let dropOffLocation = getState().dropOffLocationReducer
              .selectedDropOffLocation;
            placedOrder = {
              ...placedOrder,
              orderType: DROPOFF,
              address: [...dropOffLocation],
            };
          } else {
            let businessAddress = getState().locationsReducer.locations.find(
              (location) => location.id === orderConfig.business_id
            );
            placedOrder = {
              ...placedOrder,
              orderType: PICKUP,
              address: businessAddress,
            };
          }
          dispatch({
            type: PLACE_ORDER_SUCCESS,
            response: placedOrder,
          });
          dispatch({
            type: 'UPDATE_CART_ERROR_MESSAGE',
            errors: null,
          });
          dispatch(getPaymentMethods());
        })
        .catch((error) => {
          dispatch({
            type: PLACE_GUEST_ORDER_FAILURE,
          });
          dispatch({
            type: 'UPDATE_CART_ERROR_MESSAGE',
            errors: error.response.data.errors,
          });
        });
    });
  };
}

export function placeGuestOrder(
  orderConfig,
  paymentDetails,
  giftCard,
  payment_method_nonce
) {
  return function(dispatch, getState) {
    const selectedLocationId = getState().orderSettingReducer.selectedLocation
      .id;
    const isPayOnArrivalSelected = getState().paymentMethodsReducer
      .isPayOnArrivalSelected;
    orderConfig = {
      ...orderConfig,
      tip: isPayOnArrivalSelected ? 0 : getState().cartReducer.tipPercentage,
    };
    const coords = getState().locationsReducer.currentLocation;

    if (coords) {
      orderConfig = {
        ...orderConfig,
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    } else {
      orderConfig = {
        ...orderConfig,
        latitude: 0,
        longitude: 0,
      };
    }

    const guestPaymentMethod = getState().paymentMethodsReducer
      .guestPaymentMethod;
    const payPalNonce = getState().paymentMethodsReducer.payPalNonce;

    if (paymentDetails) {
      if (guestPaymentMethod === 'creditCard') {
        orderConfig = {
          ...orderConfig,
          is_loyalty: false,
          payment: {
            payment_method: 'credit_card',
            credit_card: {
              cardholder_name: paymentDetails.cardholder_name,
              card_number: paymentDetails.card_number,
              cvv: paymentDetails.cvv,
              expiry_date: `${paymentDetails.expiry_year}-${paymentDetails.expiry_month}-01`,
              postal_code: paymentDetails.postal_code,
            },
          },
        };
      } else if (guestPaymentMethod === 'giftCard') {
        const cardNumber = giftCard.gift_card_number
          .split(' ')
          .join('')
          .toString();
        orderConfig = {
          ...orderConfig,
          is_loyalty: false,
          payment: {
            payment_method: 'gift_card',
            gift_card: {
              card_number: cardNumber,
              pin: giftCard.pin,
            },
          },
        };
      } else if (guestPaymentMethod === 'paypal') {
        orderConfig = {
          ...orderConfig,
          is_loyalty: false,
          payment: {
            payment_method: 'paypal',
            paypal: {
              payment_method_nonce: payment_method_nonce,
            },
          },
        };
      } else {
        orderConfig = {
          ...orderConfig,
          is_loyalty: true,
        };
      }
    }

    if (
      guestPaymentMethod !== 'giftCard' &&
      guestPaymentMethod !== 'poa' &&
      paymentDetails.expiry_month > 12
    ) {
      dispatch({
        type: 'UPDATE_CART_ERROR_MESSAGE',
        errors: {
          details: null,
          message: 'Invalid Expiry Date',
        },
      });
      // return dispatch(
      //   createAlert({ type: 'error', message: 'Invalid Expiry Date' })
      // );
    }
    if (
      guestPaymentMethod !== 'giftCard' &&
      guestPaymentMethod !== 'poa' &&
      guestPaymentMethod !== 'paypal' &&
      !isValidCreditCard(
        paymentDetails.expiry_month,
        paymentDetails.expiry_year
      )
    ) {
      dispatch({
        type: 'UPDATE_CART_ERROR_MESSAGE',
        errors: {
          details: null,
          message: 'Expired Credit Card',
        },
      });
    }
    dispatch({ type: PLACE_GUEST_ORDER_REQUEST });
    if (selectedLocationId) {
      orderConfig = {
        ...orderConfig,
        business_id: selectedLocationId,
      };
    }
    api
      .post('/guest/orders', orderConfig, { ignore_interception: true })
      .then((response) => {
        let placedOrder = {
          ...response.data.data,
        };
        placedOrder = {
          ...placedOrder,
          orderType: getState().orderSettingReducer.orderType,
        };

        dispatch({
          type: PLACE_GUEST_ORDER_SUCCESS,
          response: placedOrder,
        });
        // dispatch({
        //   type: RESET_ORDER_SETTING_TIME_SETTINGS,
        //   dateTimeToShow: formatSelectedDateTime(),
        // });
        dispatch({
          type: 'UPDATE_CART_ERROR_MESSAGE',
          cartErrorMessage: [],
        });
        // dispatch({
        //   type: 'UPDATE_PAYPAL_NONCE',
        //   payPalNonce: null,
        // });
      })
      .catch((error) => {
        dispatch({
          type: PLACE_GUEST_ORDER_FAILURE,
        });
        dispatch({
          type: 'UPDATE_CART_ERROR_MESSAGE',
          errors: error.response.data.errors,
        });
        dispatch({
          type: 'UPDATE_PAYPAL_NONCE',
          payPalNonce: null,
        });
      });
  };
}

export function toggleProductIsFavourited(productIndexInCart, favouriteId) {
  return {
    type: TOGGLE_PRODUCT_IS_FAVOURITED,
    productIndexInCart,
    favouriteId,
  };
}

export function unfavouriteByFavouriteId(favouriteId) {
  return {
    type: UNFAVOURITE_BY_FAVOURITE_ID,
    favouriteId,
  };
}

export function invalidateCart() {
  return {
    type: INVALIDATE_CART,
  };
}

export function setIsForPreview(isForPreview) {
  return {
    type: SET_IS_FOR_PREVIEW,
    isForPreview,
  };
}

export function resetInvalidTip() {
  return function(dispatch) {
    dispatch({ type: RESET_INVALID_TIP_FLAG });
  };
}
export function updateCartWithFavouritedProducts(products) {
  return function(dispatch) {
    dispatch({ type: UPDATE_CART_PRODUCTS, products });
  };
}

export function updateCartWithUnavailbleProducts(unavaliableProductIds) {
  return function(dispatch, getState) {
    let products = getState().cartReducer.products;
    let combos = getState().cartReducer.combos;

    products = products.filter((product) => {
      return !unavaliableProductIds.includes(product.flatProduct.product_id);
    });
    combos = combos.filter((combo) => {
      return !unavaliableProductIds.includes(combo.id);
    });

    dispatch({ type: UPDATE_CART_PRODUCTS, products, combos });
    dispatch({ type: 'UPDATE_CART_COMBOS', combosModified: combos });

    dispatch({
      type: 'UPDATE_UNAVAILABLE_PRODUCTS',
      unavailableProducts: null,
    });
  };
}

export function updateCartNewPrices(newCart) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_CART_NEW_PRICES', newCart });
  };
}

export function updateCartScreen(cartScreen) {
  return {
    type: UPDATE_CART_SCREEN,
    cartScreen,
  };
}

export function setCheckoutAsGuestButtonIsVisible(bool) {
  return function(dispatch) {
    dispatch({ type: SET_CHECKOUT_AS_GUEST_BUTTON_IS_VISIBLE, bool });
  };
}

export function setShowGuestPersonalDetailsPage(bool) {
  return function(dispatch) {
    dispatch({ type: SET_SHOW_GUEST_PERSONAL_DETAILS_PAGE, bool });
  };
}

export function setAccountFoundModalIsVisible(bool) {
  return function(dispatch) {
    dispatch({ type: SET_ACCOUNT_FOUND_MODAL_IS_VISIBLE, bool });
  };
}

export function setGuestPersonalDetails(personalDetails) {
  return function(dispatch) {
    dispatch({ type: SET_GUEST_PERSONAL_DETAILS, personalDetails });
  };
}

export function resetGuestPersonalDetails() {
  return function(dispatch) {
    dispatch({ type: RESET_GUEST_PERSONAL_DETAILS });
  };
}

export function resetCartErrorMessages() {
  return function(dispatch) {
    dispatch({
      type: 'UPDATE_CART_ERROR_MESSAGE',
      errors: null,
    });
  };
}

export function resetPlaceOrderRequest() {
  return function(dispatch) {
    dispatch({
      type: PLACE_GUEST_ORDER_FAILURE,
    });
  };
}
