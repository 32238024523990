export const LOAD_ORDER_STATUS = 'LOAD_ORDER_STATUS';

export const REMOVE_ORDER_STATUS = 'REMOVE_ORDER_STATUS';

export const REMOVE_ALL_ORDER_STATUS = 'REMOVE_ALL_ORDER_STATUS';

export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';

export const SEND_REVIEW_REQUEST = 'SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_FAILURE = 'SEND_REVIEW_FAILURE';

export const ORDER_REFUNDED = -1;
export const ORDER_SUBMITTED = 0;
export const ORDER_ACCEPTED = 1;
export const ORDER_COMPLETE = 2;
export const ORDER_REJECTED = 3; // not currently supporting
export const ORDER_ON_DELIVERY = 4;
export const ORDER_DELIVERED = 5;
