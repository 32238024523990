import {
  GET_LOYALTY_REQUEST,
  GET_LOYALTY_SUCCESS,
  GET_LOYALTY_FAILURE,
  CREATE_GIVEX_LOYALTY_REQUEST,
  CREATE_GIVEX_LOYALTY_SUCCESS,
} from './loyaltyTypes';

export default function(
  state = {
    requestingGetLoyalty: true,
    loyalty: null,
    requestingCreateGivexLoyalty: false,
    givexSuccessfullyCreated: false,
    givexLoyaltyProcessIsDone: false,
  },
  action
) {
  switch (action.type) {
    case GET_LOYALTY_REQUEST:
      return {
        ...state,
        requestingGetLoyalty: true,
      };
    case GET_LOYALTY_SUCCESS:
      return {
        ...state,
        loyalty: action.response.data,
        requestingGetLoyalty: false,
      };
    case GET_LOYALTY_FAILURE:
      return {
        ...state,
        requestingGetLoyalty: false,
      };
    case CREATE_GIVEX_LOYALTY_REQUEST:
      return {
        ...state,
        requestingCreateGivexLoyalty: true,
        givexSuccessfullyCreated: false,
        givexLoyaltyProcessIsDone: false,
      };
    case CREATE_GIVEX_LOYALTY_SUCCESS:
      return {
        ...state,
        requestingCreateGivexLoyalty: true,
        givexSuccessfullyCreated: action.isCreated,
        givexLoyaltyProcessIsDone: action.isCreated,
      };
    default:
      return state;
  }
}
