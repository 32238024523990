import React, { useEffect, useState, useCallback, Suspense } from 'react';
import styled from 'styled-components';
import {
  Body,
  Caption1,
  Title1,
  Callout,
  Caption2,
} from '../../../../components/Typography';
// import Select from '../../../../components/Select';
import formatAddressName from '../../../../utils/formatAddressName';
import LocationCard from './LocationCard';
import { PICKUP, DELIVERY, DROPOFF } from '../../orderSettingTypes';
import GuestDeliveryAddressForm from '../../OrderSettingModal/components/GuestDeliveryAddressForm';
import appConfigFile from '../../../../../app-config';
import OrderMethodSelection from '../../OrderSettingModal/components/OrderMethodSelection';
import LocationDeliveryCard from './LocationDeliveryCard';
import SelectField from '../../../../components/Inputs/SelectField';
import DeliveryTimeFormContainer from '../DeliveryTimeFormContainer';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutOfDeliveryAreaModal from './OutOfDeliveryAreaModal';
import spinner from '../../../../assets/spinner.svg';
import { Popper } from '@material-ui/core';
import appClientConfig from '../../../../../app-config';

const MainDiv = styled.div`
  background: white;
  padding: 19px 36px 32px 36px;
  margin: 0;
  border-bottom: solid 3px #ebebeb;
  margin-top: 5px;
  border-start-start-radius: 5px;

  @media (max-width: 786px) {
    padding: 16px;
    align-items: center;
  }
`;
const LocationsWrapper = styled.div`
  display: grid;
  overflow-y: auto;
  max-height: 450px;
  padding: 19px 36px 0 36px;
  margin-bottom: 5px;
  border-end-end-radius: 5px;

  @media (max-width: 786px) {
    padding: 16px 16px 32px 16px;
    align-items: center;
    width: 100%;
  }
`;
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  width: 100%;
  @media (max-width: 786px) {
    display: block;
  }
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.7em;

  @media (max-width: 786px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 500px) {
    justify-content: center;
    text-align: center;
  }
`;

const Title = styled(Title1)`
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const SelectSectionWrapper = styled.div`
  margin: 0 16px 0 0;
  @media (max-width: 786px) {
    display: block;
    margin: 9px 0;
  }
`;
const StoresWrapper = styled.div`
  display: grid;
`;
const AddNewAddressButton = styled(Caption1)`
  margin: 10px 0 0 0;
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const SelectTitleWrapper = styled.div`
  display: block;
  align-items: center;
  align-content: center;

  @media (min-width: 786px) {
    padding-top: 10px;
  }
`;

const Stores = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 46px;
  padding: 0 5px;
  animation: fadeIn 0.5s ease-in-out;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 3em;
    width: 100%;
  }

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    width: 100%;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CalloutWrapper = styled(Callout)`
  display: flex;
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const SignInWrapper = styled(Callout)`
  display: block;
  padding-top: 10px;
  padding-left: 10px;
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const GuestDeliveryForm = styled.div`
  margin-top: 20px;
`;

const AddressSelectorDiv = styled('div')`
  width: 100%;
  margin-top: 3em;
  padding: 0 0.75em;

  @media (min-width: 780px) {
    grid-column: 1 / span 3;
  }
`;

const NotFoundWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const DeliveryErrorMessage = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: #fbebe5;
  margin-right: 0.5em;
  height: 40px;
  border-radius: 8px;
  margin: 0.5em 0 1em 0;
  padding: 0.5em 1em 1em 1em;

  @media (max-width: 1025px) {
    height: 55px;
  }

  @media (max-width: 555px) {
    height: 75px;
  }

  @media (max-width: 410px) {
    height: 90px;
  }
`;
const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    root: {
      backgroundColor: 'red',
      // '& .MuiAutocomplete-listbox': {
      //   backgroundColor: 'red',
      // },
    },
  })
);
const CustomPopper = (props) => {
  const classes = useStyles();
  return <Popper {...props} />;
};
const MarketplaceDelivery = ({
  deliveryAddressesList,
  interDeliveryDetails,
  interOrderType,
  resetInterSelectedDateTime,
  isRequesting,
  confirmMarketplaceDeliverySelection,
  setDeliveryAddressesFormIsVisible,
  resetIsTimeNoLongerValid,
  isAuthenticated,
  orderSettingModalIsVisible,
  deepLinkParameters,
  updateInterOrderType,
  getMarketplaceDeliveryLocations,
  marketplaceDeliveryLocations,
  locations,
  isMarketplaceDeliveryLocationsRequest,
  setDeliveryTimeFormIsVisible,
  deliveryDetails,
  isUnavailableProductInCartRequest,
  checkUnavailableProductForMarketplace,
  resetMarketplaceDeliveryLocations,
  confirmDeliveryDetails,
  getTimeIntervalsDate,
  deliveryToAddress,
  updateInterDeliveryDetails,
  setMarketplaceDeliverySelection,
  parentLocation,
  setParentLocation,
  getChildLocations,
  appConfig,
  setGuestDeliveryAddressFormInitialValues,
  updateOrderSettingModalIsVisible,
  profileSettingsReducer,
  validateCartForLocation,
  isDeepLink,
  isParentDeepLink,
  setIsParentStoreDeepLink,
  childLocations,
  resetDateTimes,
  setAuthenticationModalIsVisible,
  setIsMenuDisabled,
  selectMarketplacePickUpLocation,
  updateSelectedLocation,
  updateInterSelectedDateTime,
  getMenu,
  selectedLocation,
  isMenuDisabled,
  setSelectedDeliveryAddress,
  deliveryAddressToBeDisplayed,
  emptyCart,
  clearSelectedLocation,
  getCombos,
  singleOrderType,
}) => {
  const classes = useStyles();
  const [
    modifiedDeliveryAddressList,
    setModifiedDeliveryAddressList,
  ] = useState();
  const updateAddressList = useCallback(() => {
    const modifiedDeliveryAddressList = [...deliveryAddressesList];
    modifiedDeliveryAddressList.unshift('+ Add New Address');
    setModifiedDeliveryAddressList(modifiedDeliveryAddressList);
  }, [deliveryAddressesList]);
  useEffect(() => {
    updateAddressList();
  }, [updateAddressList]);
  const [hasError, setHasError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deliveryLocation, setDeliveryLocation] = useState(null);
  const { appSource } = appConfigFile;
  const { email } = profileSettingsReducer;

  const [displayingLocations, setDisplayingLocations] = useState();
  useEffect(() => {
    if (deliveryToAddress) {
      getMarketplaceDeliveryLocations(deliveryToAddress);
    }
  }, [deliveryToAddress?.city]);
  const updateDeliveryLocations = useCallback(() => {
    // Remove only pickup locations
    const filteredLocation = childLocations?.filter((location) =>
      location.accepted_order_types.includes('delivery')
    );
    //
    const filteredDeliveryCandidateLocations = marketplaceDeliveryLocations?.filter(
      (location) => location.accepted_order_types.includes('delivery')
    );

    let newDisplayingLocations;
    // console.log('filteredLocation', filteredLocation);
    // console.log('marketplaceDeliveryLocations', marketplaceDeliveryLocations);
    if (filteredDeliveryCandidateLocations?.length > 0) {
      if (!selectedLocation) {
        // console.log('1');
        newDisplayingLocations = filteredDeliveryCandidateLocations;
        setHasError(false);
      } else {
        // console.log('2', filteredDeliveryCandidateLocations);
        newDisplayingLocations = filteredDeliveryCandidateLocations.filter(
          (location) => location.id !== selectedLocation.id
        );
        const isIncluded = filteredDeliveryCandidateLocations?.filter(
          (location) => location.id === selectedLocation.id
        );
        if (isIncluded?.length === 0) {
          setIsOpen(true);
          setHasError(true);
        } else {
          setIsOpen(false);
          setHasError(false);
        }
      }
    } else {
      if (deliveryToAddress) {
        // console.log('3', marketplaceDeliveryLocations);
        newDisplayingLocations = [];
        if (selectedLocation) {
          setIsOpen(true);
          setHasError(true);
        }
      } else {
        setHasError(false);
        // console.log('4', marketplaceDeliveryLocations);
        newDisplayingLocations = filteredLocation;
      }
    }
    setDisplayingLocations(newDisplayingLocations);
  }, [marketplaceDeliveryLocations, childLocations, selectedLocation]);

  useEffect(() => {
    updateDeliveryLocations();
  }, [updateDeliveryLocations]);

  useEffect(() => {
    if (deliveryToAddress && !deliveryLocation) {
      setDeliveryLocation(deliveryToAddress);
    }
  }, [deliveryToAddress]);

  const setChildLocations = useCallback(() => {
    if (parentLocation && interOrderType) {
      if (!deliveryToAddress) {
        getChildLocations(parentLocation.id);
      }
      const strParentLocation = JSON.stringify(parentLocation);

      if (isAuthenticated && !appConfigFile.isDeepLinkClient) {
        localStorage.setItem(`${appSource}-${email}`, strParentLocation);
      } else {
        localStorage.setItem(`${appSource}-guest`, strParentLocation);
      }
    }
  }, [parentLocation]);

  useEffect(() => {
    setChildLocations();
  }, [setChildLocations]);

  useEffect(() => {
    const cachedParentLocation = isAuthenticated
      ? localStorage.getItem(`${appSource}-${email}`)
      : localStorage.getItem(`${appSource}-guest`);

    if (cachedParentLocation && !appConfigFile.isDeepLinkClient) {
      const parsedLocation = JSON.parse(cachedParentLocation);
      setParentLocation(parsedLocation);
    }
    // return () => {
    //   if (
    //     selectedLocation?.accepted_order_types?.length === 1 &&
    //     selectedLocation?.accepted_order_types[0] === 'delivery'
    //   ) {
    //     clearSelectedLocation();
    //   }
    // };
  }, []);

  const onOrderTypeChange = (interOrderType) => {
    if (interOrderType === PICKUP) {
      updateInterOrderType(interOrderType);
    } else if (interOrderType === DELIVERY) {
      updateInterOrderType(interOrderType);
    } else if (interOrderType === DROPOFF) {
      updateInterOrderType(interOrderType);
    }
  };

  const isCurrentStore = (store) => {
    if (
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness &&
      store &&
      store.deliveryBusiness
    ) {
      if (
        interDeliveryDetails.deliveryBusiness.id === store.deliveryBusiness.id
      ) {
        return true;
      }
    }

    return false;
  };
  const handleAddressChange = (address) => {
    // const deliveryAddress = event.target.value;
    resetInterSelectedDateTime();
    resetIsTimeNoLongerValid();
    resetMarketplaceDeliveryLocations();
    setDeliveryLocation(address);
    getMarketplaceDeliveryLocations(address);
    setSelectedDeliveryAddress(address);
  };
  const availableOrderMethods = parentLocation
    ? parentLocation.accepted_order_types
    : [];

  return (
    <>
      <MainDiv>
        <TitleWrapper>
          <Title>
            {parentLocation
              ? `Ordering from ${parentLocation.city[0] +
                  parentLocation.city.slice(1).toLowerCase()}, ${
                  parentLocation.state
                }`
              : 'Ordering from'}
          </Title>
          {!singleOrderType && (
            <OrderMethodSelection
              interOrderType={interOrderType}
              updateInterOrderType={updateInterOrderType}
              resetInterSelectedDateTime={resetInterSelectedDateTime}
              resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
              resetDateTimes={resetDateTimes}
              deepLinkParameters={deepLinkParameters}
              appConfig={appConfig}
            />
          )}
        </TitleWrapper>
        <SectionWrapper>
          {isAuthenticated && (
            <AddressSelectorDiv>
              {/* <Box sx={{ width: '100%' }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>
                    {!deliveryToAddress && (
                      <i
                        className="fas fa-home"
                        style={{ color: '#737a87', marginRight: '8px' }}
                      ></i>
                    )}
                    Select Your Address
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={
                      deliveryAddressesList.find(
                        (address) => address?.id === deliveryToAddress?.id
                      ) || deliveryToAddress
                    }
                    onChange={handleAddressChange}
                    // labelWidth={300}
                    label="Select Your Address"
                    startAdornment={
                      deliveryToAddress && (
                        <InputAdornment position="start">
                          <i
                            className="fas fa-home"
                            style={{ color: '#737a87' }}
                          ></i>
                        </InputAdornment>
                      )
                    }
                    sx={{ maxWidth: 200, fontSize: '12px' }}
                  >
                    <MenuItem
                      value=""
                      onClick={() => setDeliveryAddressesFormIsVisible(true)}
                      style={{
                        fontFamily: 'Nunito',
                        fontSize: '15px',
                        fontWeight: '800',
                      }}
                    >
                      + Add New Address
                    </MenuItem>
                    {deliveryAddressesList.map((address) => (
                      <MenuItem
                        value={address}
                        style={{
                          maxWidth: 200,
                          overflowWrap: 'break-word',
                          wordBreak: 'break-all',
                        }}
                      >
                        {formatAddressName(address).substring(0, 25)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}

              <Autocomplete
                id="combo-box-demo"
                options={modifiedDeliveryAddressList || []}
                getOptionLabel={(address) =>
                  address === '+ Add New Address'
                    ? address
                    : formatAddressName(address)
                }
                value={
                  deliveryToAddress
                    ? modifiedDeliveryAddressList?.find(
                        (address) => address?.id === deliveryToAddress?.id
                      ) || deliveryToAddress
                    : null
                }
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                  if (newValue === '+ Add New Address') {
                    setDeliveryAddressesFormIsVisible(true);
                  } else {
                    handleAddressChange(newValue);
                  }
                }}
                renderOption={(address, { selected }) => (
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '10px',
                        width: '100%',
                        fontWeight:
                          address === '+ Add New Address' ? 700 : 'normal',
                      }}
                    >
                      {address === '+ Add New Address'
                        ? address
                        : formatAddressName(address)}
                    </div>
                    <hr
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                )}
                // ListboxProps={{ sx: classes.root }}
                // PopperComponent={CustomPopper}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label="Select Your Address"
                      placeholder="Select Your Address"
                      style={{
                        border: !deliveryToAddress
                          ? `3px solid ${appClientConfig?.styleConfig.deliveryAddressHighlightColor}`
                          : 'none',
                        borderRadius: '10px',
                      }}
                      fullWidth
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <i
                                className="fas fa-home"
                                style={{ color: '#737a87' }}
                              ></i>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
              />
            </AddressSelectorDiv>
          )}
        </SectionWrapper>
        {appConfig && appConfig.guest_checkout_enabled && !isAuthenticated && (
          <>
            <GuestDeliveryForm>
              <GuestDeliveryAddressForm
                getMarketplaceDeliveryLocations={
                  getMarketplaceDeliveryLocations
                }
                isMarketplaceDelivery
                getChildLocations={getChildLocations}
                parentId={parentLocation?.id}
                setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                deliveryToAddress={deliveryToAddress}
                deliveryAddressToBeDisplayed={deliveryAddressToBeDisplayed}
                formatAddressName={formatAddressName}
              />
            </GuestDeliveryForm>
            <SignInWrapper>
              <Callout
                style={{ cursor: 'pointer', fontSize: '15px' }}
                onClick={() => setAuthenticationModalIsVisible(true)}
              >
                Sign In For Saved Addresses
              </Callout>
            </SignInWrapper>
          </>
        )}
      </MainDiv>
      <LocationsWrapper>
        <StoresWrapper>
          {!deliveryLocation &&
          !isMenuDisabled &&
          !isMarketplaceDeliveryLocationsRequest &&
          selectedLocation ? (
            <>
              <Title1 style={{ fontWeight: '600', marginBottom: '15px' }}>
                {'Current Restaurant'}
              </Title1>
              <Body
                style={{
                  marginBottom: '15px',
                }}
              >
                Please enter a delivery address to confirm that we can deliver
                to you
              </Body>
            </>
          ) : selectedLocation ? (
            <Title1 style={{ fontWeight: '600', marginBottom: '15px' }}>
              {'Current Restaurant'}
            </Title1>
          ) : null}
          {hasError && (
            <DeliveryErrorMessage>
              <i
                className="fas fa-exclamation-triangle"
                style={{
                  fontSize: '16px',
                  color: '#c90e0e',
                  marginRight: '1em',
                }}
              />
              <a style={{ fontSize: '15px', color: '#000' }}>
                Sorry, this restaurant cannot deliver to your address. Try
                ordering for pickup instead, or browse our other restaurants.
              </a>
            </DeliveryErrorMessage>
          )}
          <Stores>
            {selectedLocation?.accepted_order_types.includes('delivery') && (
              <LocationDeliveryCard
                confirmMarketplaceDeliverySelection={
                  confirmMarketplaceDeliverySelection
                }
                location={selectedLocation}
                selectedLocation={selectedLocation}
                locations={locations}
                deliveryLocation={deliveryLocation}
                setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
                setDeliveryAddressesFormIsVisible={
                  setDeliveryAddressesFormIsVisible
                }
                isUnavailableProductInCartRequest={
                  isUnavailableProductInCartRequest
                }
                checkUnavailableProductForMarketplace={
                  checkUnavailableProductForMarketplace
                }
                resetInterSelectedDateTime={resetInterSelectedDateTime}
                interOrderType={interOrderType}
                confirmDeliveryDetails={confirmDeliveryDetails}
                getTimeIntervalsDate={getTimeIntervalsDate}
                interDeliveryDetails={interDeliveryDetails}
                deliveryDetails={deliveryDetails}
                updateInterDeliveryDetails={updateInterDeliveryDetails}
                isRequesting={isRequesting}
                setMarketplaceDeliverySelection={
                  setMarketplaceDeliverySelection
                }
                validateCartForLocation={validateCartForLocation}
                isParentDeepLink={isParentDeepLink}
                setIsMenuDisabled={setIsMenuDisabled}
                selectMarketplacePickUpLocation={
                  selectMarketplacePickUpLocation
                }
                updateSelectedLocation={updateSelectedLocation}
                updateInterSelectedDateTime={updateInterSelectedDateTime}
                updateOrderSettingModalIsVisible={
                  updateOrderSettingModalIsVisible
                }
                getMenu={getMenu}
                deliveryToAddress={deliveryToAddress}
                isSelectedLocationCard={true}
                hasError={hasError}
                emptyCart={emptyCart}
                getCombos={getCombos}
              />
            )}
          </Stores>
        </StoresWrapper>

        {displayingLocations &&
          displayingLocations.length === 0 &&
          !deliveryLocation &&
          !selectedLocation && (
            <>
              <StoresWrapper>
                <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                  Restaurants Delivering to You
                </Title1>
                <Body style={{ fontWeight: 'bold', marginBottom: '24px' }}>
                  Add a delivery address to browse stores
                </Body>
              </StoresWrapper>
            </>
          )}
        {displayingLocations?.length === 0 ? (
          <StoresWrapper>
            <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
              Restaurants Delivering to You
            </Title1>
            <NotFoundWrapper>
              <i
                className="fas fa-store"
                style={{
                  fontSize: '40px',
                  color: '#737a87',
                  marginBottom: '23px',
                }}
              />
              <Body
                style={{
                  fontSize: '19px',
                  color: '#737a87',
                  marginBottom: '9px',
                }}
              >
                No Results
              </Body>
              <Caption2
                style={{
                  fontSize: '16px',
                  color: '#737a87',
                  marginBottom: '10px',
                }}
              >
                Sorry, we couldn’t find any restaurants that deliver to you at
                the moment
              </Caption2>
            </NotFoundWrapper>
          </StoresWrapper>
        ) : null}
        {!isMarketplaceDeliveryLocationsRequest &&
          displayingLocations &&
          displayingLocations.length !== 0 && (
            <>
              <StoresWrapper>
                <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                  {deliveryDetails
                    ? 'Restaurants Delivering to You'
                    : `${
                        deliveryToAddress
                          ? 'Restaurants Delivering to you'
                          : 'All Restaurants'
                      }`}
                </Title1>
                {!deliveryToAddress ? (
                  <Body style={{ fontSize: '16px', marginBottom: '20px' }}>
                    Add a delivery address to see restaurants delivering to you
                  </Body>
                ) : null}
                <Stores>
                  {displayingLocations?.map((marketplaceDeliveryLocation) => (
                    <>
                      <LocationDeliveryCard
                        confirmMarketplaceDeliverySelection={
                          confirmMarketplaceDeliverySelection
                        }
                        location={marketplaceDeliveryLocation}
                        locations={locations}
                        deliveryLocation={deliveryLocation}
                        setDeliveryTimeFormIsVisible={
                          setDeliveryTimeFormIsVisible
                        }
                        setDeliveryAddressesFormIsVisible={
                          setDeliveryAddressesFormIsVisible
                        }
                        isUnavailableProductInCartRequest={
                          isUnavailableProductInCartRequest
                        }
                        checkUnavailableProductForMarketplace={
                          checkUnavailableProductForMarketplace
                        }
                        resetInterSelectedDateTime={resetInterSelectedDateTime}
                        interOrderType={interOrderType}
                        confirmDeliveryDetails={confirmDeliveryDetails}
                        getTimeIntervalsDate={getTimeIntervalsDate}
                        interDeliveryDetails={interDeliveryDetails}
                        deliveryDetails={deliveryDetails}
                        updateInterDeliveryDetails={updateInterDeliveryDetails}
                        isRequesting={isRequesting}
                        setMarketplaceDeliverySelection={
                          setMarketplaceDeliverySelection
                        }
                        validateCartForLocation={validateCartForLocation}
                        isParentDeepLink={isParentDeepLink}
                        marketplaceDeliverySelection={setIsMenuDisabled}
                        selectMarketplacePickUpLocation={
                          selectMarketplacePickUpLocation
                        }
                        updateSelectedLocation={updateSelectedLocation}
                        updateInterSelectedDateTime={
                          updateInterSelectedDateTime
                        }
                        updateOrderSettingModalIsVisible={
                          updateOrderSettingModalIsVisible
                        }
                        getMenu={getMenu}
                        deliveryToAddress={deliveryToAddress}
                        setIsMenuDisabled={setIsMenuDisabled}
                        emptyCart={emptyCart}
                        getCombos={getCombos}
                      />
                    </>
                  ))}
                </Stores>
              </StoresWrapper>
            </>
          )}
      </LocationsWrapper>
      <DeliveryTimeFormContainer />
      <OutOfDeliveryAreaModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default MarketplaceDelivery;
