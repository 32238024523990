import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import CreditsModal from './components/CreditsModal';
import {getLoyalty} from '../Loyalty/loyaltyActions';

const CreditsContainer = ({
  setCreditModalIsVisible,
  creditModalIsVisible,
  loyalty,
  getLoyalty,
  authReducer,
}) => {
  const {isAuthenticated, emailIsUnconfirmed} = authReducer;
  useEffect(() => {
    if (isAuthenticated && !emailIsUnconfirmed) {
      getLoyalty();
    }
  }, []);

  return (
    <Fragment>
      <CreditsModal
        id="Credits-CreditsModal"
        setCreditModalIsVisible={setCreditModalIsVisible}
        creditModalIsVisible={creditModalIsVisible}
        loyalty={loyalty}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loyalty: state.loyaltyReducer.loyalty,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps, {getLoyalty})(CreditsContainer);
