import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from './locationsActions';
import { geolocated } from 'react-geolocated';
import { changePickUpLocation } from '../../OrderSetting/orderSettingActions';
import { getMyTransactionsList } from '../../MyTransactions/MyTransactionsActions';

const GetLocationsContainer = ({
  locations,
  getLocations,
  session_id,
  selectedPickUpLocation,
  changePickUpLocation,
  isAuth,
  coords,
  getMyTransactionsList,
  isGeolocationEnabled,
  orderHistory,
  orders,
}) => {
  useEffect(() => {
    if (isAuth) getMyTransactionsList();
    if (session_id && isGeolocationEnabled && coords) {
      getLocations(coords);
    } else if (session_id && !isGeolocationEnabled) {
      getLocations();
    }
  }, [session_id, coords, isGeolocationEnabled]);

  // useEffect(
  //   () => {
  //     if (coords) {
  //       getLocations(coords);
  //     }
  //   },
  //   [coords]
  // );

  // useEffect(() => {
  //   if (locations && selectedPickUpLocation && !coords) {
  //     changePickUpLocation(selectedPickUpLocation);
  //     // if (
  //     //   !locations
  //     //     .map(location => location.id)
  //     //     .includes(selectedPickUpLocation.id)
  //     // ) {
  //     //   changePickUpLocation(selectedPickUpLocation.id);
  //     // }
  //   } else if (locations && locations.length) {
  //     const location = locations.find(
  //       location =>
  //         location.accepting_orders &&
  //         location.is_order_ahead_enabled &&
  //         location.accepted_order_types.includes(PICKUP)
  //     );
  //     changePickUpLocation(location);
  //   }
  // }, [locations]);

  return null;
};

const mapStateToProps = (state) => ({
  locations: state.locationsReducer.locations,
  session_id: state.authReducer.session_id,
  selectedPickUpLocation: state.orderSettingReducer.interPickUpDetails,
  isAuth: state.authReducer.isAuthenticated,
  orderHistory: state.myTransactionsReducer.myTransactions,
  orders: state.orderHistoryReducer.orderHistory,
});

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 1000,
})(
  connect(mapStateToProps, {
    ...actions,
    changePickUpLocation,
    getMyTransactionsList,
  })(GetLocationsContainer)
);
