import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonLoading = ({}) => {
  return (
    <div>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  );
};

export default SkeletonLoading;
