import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 24px;
`;
const Title = styled.div`
  font-family: Nunito;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;
const Message = styled.div`
  margin: 25px 0;
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;
const ConfirmButton = styled.button`
  padding: 15px 0;
  border-radius: 10px;
  color: white;
  border: none;
  background-color: #0e5ca9;
  width: 100%;
  font-size: 19px;
  cursor: pointer;
  &:hover {
    background-color: #083765;
  }
`;

const styles = () => ({
  scrollPaper: {
    // alignItems:
    //   typeof window !== 'undefined' && window.innerWidth < 786
    //     ? 'flex-end'
    //     : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '500px',
  },
});

const OutOfDeliveryAreaModal = ({ isOpen, setIsOpen }) => {
  const classes = withStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{ scrollPaper: classes.scrollPaper }}
      PaperProps={{ classes: { root: classes.paper } }}
      aria-labelledby="delivery-time-form"
      scroll="body"
    >
      <Wrapper>
        <Title>Outside of Delivery Area</Title>
        <Message>
          Sorry, this restaurant cannot deliver to your address. Try ordering
          for pickup instead, or browse our other restaurants.
        </Message>
        <ConfirmButton onClick={() => setIsOpen(false)}>Confirm</ConfirmButton>
      </Wrapper>
    </Dialog>
  );
};

export default withStyles(styles)(OutOfDeliveryAreaModal);
