export const DROPOFF_LOCATION_FORM_IS_VISIBLE =
  'DROPOFF_LOCATION_FORM_IS_VISIBLE';

export const GET_FIRST_DROPOFF_SELECTION_REQUEST =
  'GET_FIRST_DROPOFF_SELECTION_REQUEST';
export const GET_FIRST_DROPOFF_SELECTION_SUCCESS =
  'GET_FIRST_DROPOFF_SELECTION_SUCCESS';

export const SAVE_DROPOFF_SELECTION_SUCCESS = 'SAVE_DROPOFF_SELECTION_SUCCESS';

export const DELETE_DROPOFF_LOCATION = 'DELETE_DROPOFF_LOCATION';
export const SAVE_DROPOFF_LOCATION = 'SAVE_DROPOFF_LOCATION';

export const SET_IS_EDIT_DROPOFF_LOCATION = 'SET_IS_EDIT_DROPOFF_LOCATION';

export const RESTORE_PICKED_LOCATION = 'RESTORE_PICKED_LOCATION';
export const RESTORE_SELECTED_LOCATION = 'RESTORE_SELECTED_LOCATION';
