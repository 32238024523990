import api from '../../api';
import {
  CLOSE_COMBO_POPUP,
  GET_COMBO_LIST_SUCCESS,
  GET_COMBO_SUCCESS,
  SET_COMBO_GROUP,
  SET_COMBO_ITEM,
  FILL_COMBO_GROUP,
  EMPTY_COMBO_GROUP,
  GET_COMBO_LIST_REQUEST,
  GET_COMBO_LIST_FAILURE,
  UPDATE_COMBO_PRICE,
  EDIT_COMBO_CONFIG,
  ADD_ORDER_INSTRUCTION,
} from './comboTypes';
import {
  getProductToConfigure,
  editProductConfiguration,
  getProduct,
} from '../ProductCustomizer/productCustomizerActions';
import { createAlert } from '../Alert/alertActions';
import { addComboToCart } from '../Cart/cartActions';
import flattenProduct from '../../utils/flattenProduct';
import { transformComboItemForProductCustomizer } from './comboTransformers';
import getOrderDateTimeParam from '../../utils/getOrderDateTimeParam';
import { PICKUP } from '../OrderSetting/orderSettingTypes';

export const getCombos = (businessId) => {
  return (dispatch, getState) => {
    const orderType = getState().orderSettingReducer.orderType;
    dispatch({
      type: GET_COMBO_LIST_REQUEST,
    });
    const {
      selectedDateTime,
      interSelectedDateTime,
    } = getState().orderSettingReducer;
    const orderDateTimeParam = getOrderDateTimeParam(interSelectedDateTime);
    return api
      .get(
        `/combos?business_id=${businessId}&order_type=${orderType}${orderDateTimeParam}`
      )
      .then(
        (response) => {
          let combosCart = getState().cartReducer.combos;
          const combos = response.data.data;
          if (combosCart.length) {
            combosCart = combosCart.map((cartCombo) => {
              let updatedCart = null;
              combos.forEach((combo) => {
                if (combo.id === cartCombo.id) {
                  updatedCart = {
                    ...cartCombo,
                    price: combo.price,
                    totalPrice: cartCombo.totalPrice, //bk
                  };
                }
              });
              return updatedCart || cartCombo;
            });
            dispatch({
              type: 'UPDATE_CART_COMBOS',
              combosModified: combosCart,
            });
          }

          dispatch({
            type: GET_COMBO_LIST_SUCCESS,
            combos: response.data.data,
          });
        },
        (error) => {
          dispatch({
            type: GET_COMBO_LIST_FAILURE,
          });
        }
      );
  };
};

const getProducts = (combo, businessId) => {
  const productIds = combo.combo_item_groups.reduce(
    (productIds, comboGroup) => {
      return comboGroup.combo_items.reduce((productIds, comboItem) => {
        if (-1 === productIds.indexOf(comboItem.product_id)) {
          productIds.push(comboItem.product_id);
        }
        return productIds;
      }, productIds);
    },
    []
  );

  const productIdParams = productIds.map((id) => `products[]=${id}`);
  let productIdParamStrings = [];

  /*
   There is a limit of URL length, if the URL length exceeds limit, the request won't be sent. 
   */
  const maxNumberOfIdsPerRequest = 20;
  const buildParams = (i = 0) => {
    const start = i * maxNumberOfIdsPerRequest;
    const end = (i + 1) * maxNumberOfIdsPerRequest;
    productIdParamStrings.push(productIdParams.slice(start, end).join('&'));
    if (end < productIdParams.length) buildParams(i + 1);
  };

  buildParams();

  /*
    cannot specifying variant id of each product here because they could have diffenrent default ones
    this groups getting product data into one endpoint.

    some products are not listed in menu, they are only available for combo.
  */
  return new Promise((resolve, reject) => {
    Promise.all(
      productIdParamStrings.map((params) =>
        api.get(`/products?business_id=${businessId}&${params}`)
      )
    )
      .then((responses) => {
        resolve(
          responses.reduce(
            (acc, response) => [...acc, ...response.data.data],
            []
          )
        );
      })
      .catch((e) => reject(e));
  });
};

const mapProductData = (combo, products) => {
  combo.combo_item_groups.forEach((comboGroup, i) => {
    const combo_item_group_id = comboGroup.combo_item_group_id;
    let productItems = [];
    const groupedItems = comboGroup.combo_items.reduce((acc, comboItem) => {
      let items = acc.get(comboItem.product_id) || [];
      items.push(comboItem);
      acc.set(comboItem.product_id, items);
      return acc;
    }, new Map());

    groupedItems.forEach((comboItems, productId) => {
      const itemVariants = comboItems.map((item) => item.variant_id);
      let product = products.find(
        (product) => product.product_id === productId
      );
      if (product) {
        product = { ...product };
        let filteredPrices = product.prices.filter((price) => {
          return itemVariants.includes(price.variant_id);
        });
        product.prices.map((price) => {
          const price_override = combo.combo_price_override.find(
            (priceOverride) => {
              return (
                priceOverride.product_id === productId &&
                combo_item_group_id === priceOverride.combo_item_group_id &&
                priceOverride.variant_id === price.variant_id
              );
            }
          );

          if (price_override) {
            price.extra_price = price_override.extra_price;
          }
          return price;
        });
        product.prices = filteredPrices;

        /*
        when getting a product's data without specifying variant_id,
        product.variant_id and product.variant_name are of the default one
        default variant of a product may not put in the combo group,
        if no default variant, set the first variant as default
        */
        const defaultVariant =
          filteredPrices.find((p) => p.is_default) || filteredPrices[0];

        if (defaultVariant) {
          product.variant_id = defaultVariant.variant_id;
          product.variant_name = defaultVariant.name;

          productItems.push(product);
        }
      }
    });
    combo.combo_item_groups[i].productItems = productItems;
  });
  return combo;
};

export const getCombo = (comboId, businessId) => {
  return (dispatch, getState) => {
    const selectedBusinessId = getState().orderSettingReducer.selectedLocation
      ?.id;
    return api
      .get(`/combos/${comboId}?business_id=${businessId || selectedBusinessId}`)
      .then((response) => {
        getProducts(response.data.data, businessId).then((products) => {
          let combo = mapProductData(response.data.data, products);
          addNoConfigRequiredProductToCombo(combo, businessId).then(
            (preFilledProducts) => {
              preFilledProducts.forEach((preFilledProduct) => {
                combo.combo_item_groups[
                  preFilledProduct.groupIndex
                ].productCustomizer = preFilledProduct.productCustomizer;
              });
              dispatch({
                type: GET_COMBO_SUCCESS,
                combo: combo,
              });
            }
          );
        });
      })
      .catch((e) => {
        dispatch(
          createAlert({
            type: 'error',
            message: "There's an error while loading combo.",
          })
        );
      });
  };
};

export const addNoConfigRequiredProductToCombo = (combo, businessId) => {
  return new Promise((resolve, reject) => {
    let promises = [];
    /*
      cannot group fetching product info (with options) here
      because each product has different default variant id.
      need to fetch one by one
    */
    combo.combo_item_groups.map((comboGroup, i) => {
      if (comboGroup.is_required && 1 === comboGroup.productItems.length) {
        promises.push({
          groupIndex: i,
          product: comboGroup.productItems[0],
          promise: getProduct(
            comboGroup.productItems[0].product_id,
            businessId,
            comboGroup.productItems[0].variant_id,
            { has_valid_configuration: false }
          ),
        });
      }
    });

    Promise.all(promises.map((p) => p.promise))
      .then((responses) => {
        let preFilledProducts = [];
        responses.map((response, i) => {
          const productsModified = transformComboItemForProductCustomizer(
            null,
            response.data.data,
            promises[i].product
          );
          const flatProduct = flattenProduct(productsModified);
          const requiredOption = Object.values(flatProduct.optionsById).find(
            (option) => option.minimum_pick > 0
          );

          const selectedVariant =
            flatProduct.prices.find((price) => price.is_default) ||
            flatProduct.prices[0];
          preFilledProducts.push({
            groupIndex: promises[i].groupIndex,
            productCustomizer: {
              singlePrice: productsModified.price,
              totalPrice: productsModified.price,
              flatProduct: { ...flatProduct, quantity: 1 },
              selectedVariantId: selectedVariant.variant_id,
              isPrefilled: true,
            },
          });
        });
        resolve(preFilledProducts);
      })
      .catch((e) => reject(e));
  });
};

export const closeComboPopup = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_COMBO_POPUP,
    });
  };
};

export const setComboGroup = (selectedComboGroup) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMBO_GROUP,
      selectedComboGroup,
    });
  };
};

export const getComboItemProduct = (product) => {
  return (dispatch, getState) => {
    const {
      interOrderType,
      interPickUpDetails,
      interDeliveryDetails,
    } = getState().orderSettingReducer;

    const businessId =
      interOrderType === PICKUP
        ? interPickUpDetails.id
        : interDeliveryDetails.deliveryBusiness.id;

    dispatch({
      type: SET_COMBO_ITEM,
      comboItemToCustomize: product,
    });
    dispatch(
      getProductToConfigure(
        product.product_id,
        businessId,
        null,
        product.variant_id
      )
    );
  };
};

export const editSelectedProductInGroup = (
  productCustomizer,
  comboGroupIndex
) => {
  return (dispatch) => {
    dispatch(setComboGroup(comboGroupIndex));
    dispatch({
      type: SET_COMBO_ITEM,
      comboItemToCustomize: { ...productCustomizer.flatProduct },
    });
    dispatch(editProductConfiguration(productCustomizer));
  };
};

export const removeSelectedProductInGroup = (comboGroupIndex) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_COMBO_GROUP,
      comboGroupIndex: comboGroupIndex,
    });
  };
};

export const addToCart = () => {
  return (dispatch, getState) => {
    const { combo, comboIndexInCart, isEditing } = getState().comboReducer;
    const hasOneRequiredGroupNotFilled = combo.combo_item_groups.find(
      (comboGroup) => {
        return comboGroup.is_required && !comboGroup.productCustomizer;
      }
    );
    const hasRequiredComboGroup = combo.combo_item_groups.find(
      (comboGroup) => comboGroup.is_required
    );
    /*
    In some cases, all groups are optional but at least one group has to be filled
    */
    const hasAllGroupNotFilled = combo.combo_item_groups.find((comboGroup) => {
      return !comboGroup.productCustomizer;
    });
    if (
      hasOneRequiredGroupNotFilled ||
      (!hasRequiredComboGroup && hasAllGroupNotFilled)
    ) {
      const message = hasOneRequiredGroupNotFilled
        ? 'Please select all required customizations.'
        : 'Please select at lease one customization.';
      dispatch(
        createAlert({
          type: 'error',
          message: message,
        })
      );
    } else {
      dispatch(addComboToCart({ combo, comboIndexInCart, isEditing }));
      dispatch(closeComboPopup());
      dispatch(
        createAlert({
          type: 'success',
          message: isEditing
            ? 'Your combo has been updated.'
            : 'Your combo has been added to cart.',
        })
      );
    }
  };
};

export const editComboInCart = (combo, comboIndexInCart) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_COMBO_CONFIG,
      comboIndexInCart,
      combo: {
        ...combo,
        combo_item_groups: [
          ...combo.combo_item_groups.map((group) => {
            let copiedGroup = { ...group };
            if (group.productCustomizer) {
              copiedGroup = {
                ...copiedGroup,
                productCustomizer: {
                  ...group.productCustomizer,
                },
              };
            }
            return copiedGroup;
          }),
        ],
      },
    });
  };
};

export const addToCombo = (productCustomizer) => {
  return (dispatch) => {
    dispatch({
      type: FILL_COMBO_GROUP,
      productCustomizer,
    });
  };
};
export function addComboOrderInstruction(instruction) {
  return {
    type: ADD_ORDER_INSTRUCTION,
    instruction,
  };
}
const calculateProductPrice = (productCustomizer) => {
  const { flatProduct, selectedVariantId, singlePrice } = productCustomizer;
  const variant = flatProduct.prices.find(
    (price) => price.variant_id === selectedVariantId
  );
  const extra_price = variant ? variant.extra_price || 0 : 0;
  return singlePrice - flatProduct.price + extra_price;
};

const calculateComboPrice = (combo) => {
  if (!combo.is_dollar_discount) return 0;
  const price = combo.combo_item_groups.reduce((acc, group) => {
    if (group.productCustomizer) {
      acc += calculateProductPrice(group.productCustomizer);
    }
    return acc;
  }, combo.price.amount);

  return price;
};

export const updatePrice = () => {
  return (dispatch, getState) => {
    const combo = getState().comboReducer.combo;
    dispatch({
      type: UPDATE_COMBO_PRICE,
      totalPrice: calculateComboPrice(combo),
    });
  };
};
