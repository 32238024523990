import React from "react";
import styled from "styled-components";
import { Headline, Body } from "../../../../components/Typography";
import ConfirmButton from "../../OrderSettingModal/components/ConfirmButton";
import UnavailableMessage from "../../OrderSettingModal/components/UnavailableMessage";
import {
  renderMessageText,
  orderingIsAvailable,
} from "../../OrderSettingModal/utils/helper";
import { getOpeningHour } from "../../../OrderSetting/utils/helper";
import appConfig from "../../../../../app-config";

const Wrapper = styled.div`
  padding: 20px 18px 20px 13px;
  border-radius: 9.6px;
  box-shadow: 0 5px 16px 0 rgba(20, 19, 33, 0.12);
  background-color: #fff;
  margin-bottom: 18px;
  align-items: center;
  cursor: pointer;
  border: 2px solid
    ${(props) =>
      props.isCurrentStore ? props.theme.primaryButtonBackgroundColor : "none"};
  min-height: 170px;
  max-width: 500px;
  :hover {
    box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  }
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InformationDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: left;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-end;
  align-content: space-between;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;

// const LocationAddress = styled(Body)`
//   margin-top: 9px;
// `;

const ThresholdFeeText = styled(Body)`
  color: ${(props) => props.theme.primaryButtonFontColor};
`;

const ImageDiv = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;

const LocationCardWrapper = styled("div")`
  display: grid;
  grid-template-rows: 1fr 1fr;
  box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  border-radius: 9.6px;
  max-width: 497px;
  height: 180px;
  margin-bottom: 1em;
  padding: ${(props) => (props.message ? `0 1em` : `10px 1em 0 1em`)};
  cursor: pointer;

  :hover {
    box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    width: 385px;
    padding: ${(props) => (!props.noImage ? "0 1em 1em 1em" : "0 1em 1em 1em")};
    height: ${(props) => (props.message ? ` 240px` : `180px`)};
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    width: 100%;
    padding: ${(props) => (!props.noImage ? "0 1em 1em 1em" : "0 1em 1em 1em")};
    height: ${(props) => (props.message ? ` 260px` : `180px`)};
  }
`;

const LocationContentWrapper = styled("div")`
  display: grid;
  grid-template-columns: auto 1fr 0.75fr;
`;

const LocationMessageWrapper = styled("div")`
  display: grid;
  grid-column: 1 / span 3;
  grid-row: 4;
  padding: 2em 0;
`;

const ImageWrapper = styled.div`
  display: block;
  align-self: center;
  justify-content: center;
`;

const LocationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-left: ${(props) => (props.noImage ? "0" : "1.25em")};
  width: 245px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  align-items: end;
  justify-content: end;
  grid-column: 3;

  @media (max-width: 480px) {
    grid-column: ${(props) => (props.noImage ? 1 : 2)};
    grid-row: 2;
    margin-top: 0.5em;
    align-items: ${(props) => (props.noImage ? "start" : "end")};
    justify-content: start;
    padding-left: 1.25em;
  }
`;

const LocationAddress = styled(Body)`
  display: flex;
  flex-direction: column;
  color: #737a87;
  font-size: 15px;
  margin-top: 9px;
`;

const OrderTypesWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  &:first-child {
    margin-right: 1em;
  }
`;

const TypeDivider = styled("div")`
  width: 4px;
  height: 4px;
  margin: 10px 7px 0 5px;
  background-color: #737a87;
`;

const LocationCard = ({
  marketplaceDeliveryLocation,
  isCurrentStore,
  resetInterSelectedDateTime,
  interOrderType,
  validateCartForLocation,
  setDeliveryTimeFormIsVisible,
}) => {
  const openingHour =
    marketplaceDeliveryLocation && marketplaceDeliveryLocation.deliveryBusiness
      ? getOpeningHour(marketplaceDeliveryLocation.deliveryBusiness)
      : null;

  const orderingIsAvailableResult = marketplaceDeliveryLocation
    ? orderingIsAvailable(
        marketplaceDeliveryLocation,
        marketplaceDeliveryLocation.ordering_hours
      )
    : null;

  const thresholdFee =
    marketplaceDeliveryLocation?.delivery_zone?.threshold_fee;
  const deliveryFee = marketplaceDeliveryLocation?.delivery_zone?.fee;
  const distance = (marketplaceDeliveryLocation?.distance / 1000).toFixed(1);
  const name = marketplaceDeliveryLocation?.deliveryBusiness?.name || "";

  return (
    <LocationCardWrapper isCurrentStore={isCurrentStore}>
      <LocationContentWrapper
        data-cy={`marketplace-delivery-location-card-${name}`}
        onClick={() => {
          resetInterSelectedDateTime();
          setDeliveryTimeFormIsVisible(true, marketplaceDeliveryLocation);
          validateCartForLocation(marketplaceDeliveryLocation);
        }}
      >
        {marketplaceDeliveryLocation.image_url !== "" ? (
          <ImageWrapper>
            <img
              width="100"
              height="100"
              src={marketplaceDeliveryLocation?.deliveryBusiness?.image_url}
            />
          </ImageWrapper>
        ) : null}
        <LocationInfoWrapper
          noImage={
            marketplaceDeliveryLocation?.deliveryBusiness?.image_url === ""
          }
        >
          <Headline style={{ paddingTop: "1em" }}>{name}</Headline>
          <LocationAddress>
            {marketplaceDeliveryLocation?.line1},{" "}
            {marketplaceDeliveryLocation?.city}
            <OrderTypesWrapper>
              {marketplaceDeliveryLocation &&
                marketplaceDeliveryLocation?.accepted_order_types?.map(
                  (orderType, i) =>
                    marketplaceDeliveryLocation?.accepted_order_types.length >
                      1 && i === 0 ? (
                      <>
                        <a>{orderType[0].toUpperCase() + orderType.slice(1)}</a>
                        <TypeDivider />
                      </>
                    ) : (
                      <a>{orderType[0].toUpperCase() + orderType.slice(1)}</a>
                    )
                )}
              {!marketplaceDeliveryLocation?.is_order_ahead_enabled ||
                (!marketplaceDeliveryLocation?.accepting_orders && (
                  <a style={{ fontSize: "14px", marginLeft: "2px" }}>
                    {" - "}💤 Closed
                  </a>
                ))}
            </OrderTypesWrapper>
            {marketplaceDeliveryLocation?.is_order_ahead_enabled &&
              marketplaceDeliveryLocation?.accepting_orders && (
                <a>Order until {openingHour} </a>
              )}
          </LocationAddress>
        </LocationInfoWrapper>
        <ButtonWrapper noImage={marketplaceDeliveryLocation?.image_url === ""}>
          <ConfirmButton
            text={
              isCurrentStore
                ? "Edit Time"
                : marketplaceDeliveryLocation && orderingIsAvailableResult
                ? "Order Here"
                : "See Menu"
            }
            style={{
              borderRadius: "18px",
              minWidth: "110px",
              fontSize: "14px",
            }}
          />
        </ButtonWrapper>

        {/* <InformationDiv>
          {deliveryFee && deliveryFee > 0 ? (
            <Body>${deliveryFee} Delivery Fee</Body>
          ) : deliveryFee === 0 ? (
            <Body>Free Delivery </Body>
          ) : null}
          {thresholdFee === 0 ? (
            <ThresholdFeeText>
              Free Delivery Over $
              {marketplaceDeliveryLocation?.delivery_zone?.threshold}
            </ThresholdFeeText>
          ) : thresholdFee && thresholdFee >= 0 ? (
            <ThresholdFeeText>
              ${thresholdFee} Delivery Fee Over $
              {marketplaceDeliveryLocation?.delivery_zone?.threshold}
            </ThresholdFeeText>
          ) : null}
        </InformationDiv>

        <SecondColumn>
          {distance == 0 ? <Body>0 km</Body> : <Body>{distance} km</Body>}
          <ButtonDiv></ButtonDiv>
        </SecondColumn> */}
      </LocationContentWrapper>
      <LocationMessageWrapper>
        {!marketplaceDeliveryLocation.is_order_ahead_enabled ? (
          <UnavailableMessage
            title="Selected location is unable to accept orders at the moment"
            marginTop="-23px"
          />
        ) : !orderingIsAvailableResult ||
          !marketplaceDeliveryLocation.accepting_orders ? (
          <UnavailableMessage
            title={renderMessageText(
              interOrderType,
              marketplaceDeliveryLocation.ordering_hours,
              []
            )}
            marginTop="-23px"
          />
        ) : marketplaceDeliveryLocation &&
          !marketplaceDeliveryLocation.isDeliveryValid ? (
          <UnavailableMessage
            title={
              marketplaceDeliveryLocation.errorMessage ||
              "We are unable to deliver to this address"
            }
            marginTop="-23px"
          />
        ) : null}
      </LocationMessageWrapper>
    </LocationCardWrapper>
  );
};

export default LocationCard;
