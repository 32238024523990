const removeDefaultItems = flatProduct => {
  Object.keys(flatProduct.itemsById).forEach(itemId => {
    // if there is a default item and it's unavailable, unselect it
    if (!flatProduct.itemsById[itemId].is_available) {
      // flatProduct.itemsById[itemId].quantity = 1;
      flatProduct.itemsById[itemId].quantity = 0;
    }
  });

  Object.keys(flatProduct.subItemsById).forEach(subItemId => {
    if (flatProduct.subItemsById[subItemId].is_available) {
      // flatProduct.subItemsById[subItemId].quantity = 1;
      flatProduct.subItemsById[subItemId].quantity = 0;
    }
  });

  return flatProduct;
};

export default removeDefaultItems;
