// args are flatProducts

const perserveOnVariantChange = (oldProduct, newProduct) => {
  Object.keys(newProduct.itemsById).forEach(itemId => {
    if (!newProduct.itemsById[itemId].is_available) {
      newProduct.itemsById[itemId].quantity = 0;
    }
  });
  Object.keys(newProduct.subItemsById).forEach(subItemId => {
    if (!newProduct.subItemsById[subItemId].is_available) {
      newProduct.subItemsById[subItemId].quantity = 0;
    }
  });
  return {
    ...newProduct,
    quantity: oldProduct.quantity,
  };
};

export default perserveOnVariantChange;
