import {
  GET_CUSTOMER_PROFILE_REQUEST,
  GET_CUSTOMER_PROFILE_SUCCESS,
  GET_CUSTOMER_PROFILE_FAILURE,
  EDIT_PROFILE_SETTINGS_REQUEST,
  EDIT_PROFILE_SETTINGS_SUCCESS,
  EDIT_PROFILE_SETTINGS_FAILURE,
  SET_USER_COORDS,
  RESET_EDIT_PROFILE_SETTINGS,
  SAVE_BIRTHDAY_REQUEST,
  SAVE_BIRTHDAY_SUCCESS,
  OPEN_BIRTHDAY_MODAL,
  CLOSE_BIRTHDAY_MODAL,
} from "./profileSettingsTypes";
import api from "../../api";
import { createAlert } from "../Alert/alertActions";
import moment from "moment";
import appConfig from "../../../app-config";

export function getCustomerProfile() {
  return function(dispatch) {
    dispatch({ type: GET_CUSTOMER_PROFILE_REQUEST });
    return api.get(`/customer/profile`).then(
      (response) => {
        dispatch({
          type: GET_CUSTOMER_PROFILE_SUCCESS,
          response: {
            ...response.data.data,
            birthdate: response.data.data.birthdate
              ? moment(response.data.data.birthdate).format("DD/MM/YYYY")
              : null,
          },
        });
      },
      (error) => {
        dispatch({ type: GET_CUSTOMER_PROFILE_FAILURE, error });
      }
    );
  };
}

export function editProfileSettings(values) {
  let formBody = {
    first_name: values.first_name,
    last_name: values.last_name,
    email_marketing: values.email_marketing,
    sms_marketing: values.sms_marketing,
  };

  if (appConfig.appSource === "madradish_catering") {
    formBody = {
      ...formBody,
      company_name: values.company_name,
    };
  }

  return function(dispatch) {
    dispatch({ type: EDIT_PROFILE_SETTINGS_REQUEST });
    return api.patch(`/customer/profile`, values).then(
      (response) => {
        dispatch({
          type: EDIT_PROFILE_SETTINGS_SUCCESS,
          response: formBody,
        });
        dispatch(
          createAlert({
            type: "success",
            message: "Profile Settings Updated Successfully",
          })
        );
        dispatch({ type: RESET_EDIT_PROFILE_SETTINGS });
      },
      (error) => {
        dispatch({ type: EDIT_PROFILE_SETTINGS_FAILURE, error });
      }
    );
  };
}

export function editToggleValue(emailMarketing, smsMarketing) {
  return function(dispatch) {
    return api
      .patch(`/customer/profile`, {
        email_marketing: emailMarketing,
        sms_marketing: smsMarketing,
      })
      .then((response) => {
        dispatch(getCustomerProfile());
        dispatch(
          createAlert({
            type: "success",
            message: "Value is updated successfully",
          })
        );
      })
      .catch((err) => {
        createAlert({
          type: "error",
          message: "An error occurred trying to update the value",
        });
      });
  };
}

export function setUserCoords(coords) {
  return {
    type: SET_USER_COORDS,
    coords,
  };
}

export function openBirthdayModal() {
  return {
    type: OPEN_BIRTHDAY_MODAL,
  };
}

export function closeBirthdayModal() {
  return {
    type: CLOSE_BIRTHDAY_MODAL,
  };
}

export function saveBirthday(birthdate) {
  return function(dispatch) {
    if (!isValidDate(birthdate)) {
      return dispatch(
        createAlert({ type: "error", message: "The date is invalid" })
      );
    } else if (!moment(birthdate).isBefore(moment().subtract(13, "years"))) {
      dispatch(
        createAlert({
          type: "error",
          message: `The minimal required age to register is 13!`,
        })
      );
    } else {
      const birth_date = moment(birthdate).format("YYYY-MM-DD");
      dispatch({ type: SAVE_BIRTHDAY_REQUEST });
      api
        .patch(`/customer/profile`, { birth_date })
        .then((response) => {
          dispatch({
            type: SAVE_BIRTHDAY_SUCCESS,
            birthdate: moment(birthdate).format("DD/MM/YYYY"),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
