import {
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  SHOW_PAYMENT_METHOD_FORM,
  HIDE_PAYMENT_METHOD_FORM,
  CREATE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  SET_DEFAULT_PAYMENT_METHOD_REQUEST,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_FAILURE,
  SHOW_CONFIRM_DELETE_PAYMENT_METHOD,
  HIDE_CONFIRM_DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  CHANGE_GUEST_PAYMENT_METHOD,
  UPDATE_GUEST_CREDIT_CARD_DETAILS,
  RESET_GUEST_CREDIT_CARD_DETAILS,
  UPDATE_GUEST_GIFT_CARD_DETAILS,
  RESET_GUEST_GIFT_CARD_DETAILS,
  SET_TOUCHED_CREDIT_CARD_DETAILS,
  SHOW_SELECT_PAYMENT_METHOD_FORM,
  SET_SELECTED_PAYMENT_METHOD,
  SHOW_PAYMENT_METHOD_SELECTION_FORM,
} from "./paymentMethodsTypes";
import appConfigFile from "../../../app-config";
import api from "../../api";
import moment from "moment";
import productForOrder from "../Cart/utils/productForOrder";
import comboForOrder from "../Cart/utils/comboForOrder";
import { createAlert } from "../Alert/alertActions";
import isValidCreditCard from "../../utils/isValidCreditCard";
import { shouldValidatePostalCode, shouldValidateAddress } from "./utils";
import { placeGuestOrder } from "../Cart/cartActions";

export function resetPayPalNonce() {
  return function(dispatch) {
    dispatch({
      type: "UPDATE_PAYPAL_NONCE",
      payPalNonce: null,
    });
  };
}
export function updateIsPayPalButtonDisplayed(isPayPalButtonDisplayed) {
  return function(dispatch) {
    dispatch({
      type: "UPDATE_PAYPAL_BUTTON_DISPLAY",
      isPayPalButtonDisplayed: isPayPalButtonDisplayed,
    });
  };
}
const getOrderForPayPal = (
  orderSettingReducer,
  guestDeliveryFormInitialAddress,
  validatedCart,
  specialInstructions,
  selectedDropOffLocation
) => {
  const {
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interOrderType,
  } = orderSettingReducer;
  let finalOrderConfig = null;
  const location =
    interOrderType === "pickup" && interPickUpDetails
      ? interPickUpDetails
      : interOrderType === "delivery" &&
        interDeliveryDetails &&
        interDeliveryDetails.deliveryBusiness
      ? interDeliveryDetails.deliveryBusiness
      : interOrderType === "drop-off" && interDropOffDetails
      ? interDropOffDetails
      : null;

  if (location) {
    let orderConfig = {
      business_id: location.id,
      subtotal: validatedCart.subtotal,
      tip: validatedCart.tip,
      tax: validatedCart.tax,
      total: validatedCart.total,
      cart: [
        ...validatedCart.products.map((product) => productForOrder(product)),
        ...comboForOrder(validatedCart.combos || []),
      ],
      special_instruction: specialInstructions,
    };

    if (orderSettingReducer.interOrderType === "drop-off") {
      let dropOffLocation = [...selectedDropOffLocation];
      const dropOffLocationObject = dropOffLocation
        .reverse()
        .reduce((obj, selection) => {
          return {
            id: selection.id,
            option: { ...obj },
          };
        }, {});

      orderConfig = {
        ...orderConfig,
        drop_off_location: dropOffLocationObject,
      };
    } else if (orderSettingReducer.interOrderType === "delivery") {
      orderConfig = {
        ...orderConfig,
        delivery_address: {
          line1: guestDeliveryFormInitialAddress.line1,
          line2: guestDeliveryFormInitialAddress.line2,
          city: guestDeliveryFormInitialAddress.city,
          state: guestDeliveryFormInitialAddress.state,
          zip: guestDeliveryFormInitialAddress.zip,
          country: guestDeliveryFormInitialAddress.country,
          type: guestDeliveryFormInitialAddress.type,
          is_preferred: guestDeliveryFormInitialAddress.is_preferred,
          details: guestDeliveryFormInitialAddress.details,
        },
      };
    }

    if (
      appConfigFile.isFutureOrderDelivery ||
      appConfigFile.isFutureOrderPickup ||
      appConfigFile.isFutureOrderDropOff
    ) {
      if (
        orderSettingReducer.selectedDateTime.radioValue &&
        orderSettingReducer.selectedDateTime.radioValue.value !== "asap" &&
        orderSettingReducer.selectedDateTime.radioValue.value !== null
      ) {
        let orderTimeModified = orderSettingReducer.dateTimeToShow.value;
        orderConfig = {
          ...orderConfig,
          order_date: moment(orderTimeModified, "ddd MMM DD YYYY LT").format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        };
      }
    }
    finalOrderConfig = orderConfig;
  }
  return finalOrderConfig;
};
export function getClientToken() {
  return function(dispatch, getState) {
    const isAuthenticated = getState().authReducer.isAuthenticated;
    let paypal;
    const braintree = window.braintree;
    dispatch({ type: "GET_CLIENT_TOKEN_REQUEST" });
    return api
      .post(`/payment-methods/client-token`)
      .then((response) => {
        braintree.client
          .create({
            authorization: response.data.data.client_token,
          })
          .then(function(clientInstance) {
            // Create a PayPal Checkout component.
            // console.log('clientInstance=>', clientInstance);
            return braintree.paypalCheckout.create({
              client: clientInstance,
            });
          })
          .then(function(paypalCheckoutInstance) {
            return paypalCheckoutInstance.loadPayPalSDK({
              vault: true,
              currency: "CAD",
            });
          })
          .then(function(paypalCheckoutInstance) {
            dispatch({
              type: "UPDATE_PAYPAL_BUTTON_DISPLAY",
              isPayPalButtonDisplayed: true,
            });
            // console.log('Paypal Chckout Instance', paypalCheckoutInstance);
            paypal = window.paypal;
            return paypal
              .Buttons({
                fundingSource: paypal.FUNDING.PAYPAL,
                style: {
                  layout: "vertical",
                  color: "silver",
                  shape: "rect",
                  label: isAuthenticated ? "paypal" : "pay",
                  height: 55,
                },
                createBillingAgreement: function() {
                  return paypalCheckoutInstance.createPayment({
                    flow: "vault", // Required
                    currency: "CAD",
                    // The following are optional params
                    // billingAgreementDescription: 'Your agreement description',
                    // enableShippingAddress: true,
                    // shippingAddressEditable: false,
                    // shippingAddressOverride: {
                    //   recipientName: 'Scruff McGruff',
                    //   line1: '1234 Main St.',
                    //   line2: 'Unit 1',
                    //   city: 'Toronto',
                    //   countryCode: 'CA',
                    //   postalCode: 'X1X1X1',
                    //   state: 'ON',
                    //   phone: '123.456.7890',
                    // },
                  });
                },

                onApprove: function(data, actions) {
                  return paypalCheckoutInstance
                    .tokenizePayment(data)
                    .then(function(payload) {
                      dispatch({ type: CREATE_PAYMENT_METHOD_REQUEST });
                      if (isAuthenticated) {
                        return api
                          .post(
                            `/payment-methods`,
                            {
                              type: "paypal",
                              details: { payment_method_nonce: payload.nonce },
                            },
                            { ignore_interception: true }
                          )
                          .then((response) => {
                            dispatch({
                              type: CREATE_PAYMENT_METHOD_SUCCESS,
                              response: response.data,
                              isPayPalButtonDisplayed: false,
                            });
                            dispatch({
                              type: CREATE_PAYMENT_METHOD_FAILURE,
                              errorMessage: {},
                            });
                            dispatch(getPaymentMethods());
                          })
                          .catch((error) => {
                            dispatch({
                              type: CREATE_PAYMENT_METHOD_FAILURE,
                              errorMessage: error.response.data.errors,
                            });
                          });
                      } else {
                        dispatch({
                          type: "UPDATE_PAYPAL_NONCE",
                          payPalNonce: payload.nonce,
                        });
                        const orderSettingReducer = getState()
                          .orderSettingReducer;
                        const deliveryToAddress = getState()
                          .deliveryAddressesReducer.deliveryToAddress;
                        const {
                          validatedCart,
                          specialInstructions,
                        } = getState().cartReducer;
                        const selectedDropOffLocation = getState()
                          .dropOffLocationReducer.selectedDropOffLocation;
                        const orderConfig = getOrderForPayPal(
                          orderSettingReducer,
                          deliveryToAddress,
                          validatedCart,
                          specialInstructions,
                          selectedDropOffLocation
                        );
                        const paymentDetails = {
                          card_number: "",
                          cardholder_name: "",
                          cvv: "",
                          expiry_month: "",
                          expiry_year: "",
                          postal_code: "",
                        };

                        dispatch(
                          placeGuestOrder(
                            orderConfig,
                            paymentDetails,
                            null,
                            payload.nonce
                          )
                        );
                      }
                    });
                },

                onCancel: function(data) {
                  dispatch({
                    type: "UPDATE_PAYPAL_BUTTON_DISPLAY",
                    isPayPalButtonDisplayed: false,
                  });
                },

                onError: function(err) {
                  console.log("error=>", err);
                  dispatch({
                    type: "UPDATE_PAYPAL_BUTTON_DISPLAY",
                    isPayPalButtonDisplayed: false,
                  });
                },
              })
              .render("#paypal-button-container");
          })
          .then(function() {
            // The PayPal button will be rendered in an html element with the ID
            // `paypal-button`. This function will be called when the PayPal button
            // is set up and ready to be used
          })
          .then(function() {
            dispatch({
              type: "GET_CLIENT_TOKEN_SUCCESS",
              response: response.data,
            });
          })
          .catch(function(err) {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log("error=>", error);
      });
  };
}
export function updateIsPayOnArrivalSelect(isSelected) {
  return function(dispatch) {
    dispatch({
      type: "UPDATE_IS_PAY_ON_ARRIVAL_SELECTED",
      isSelected: isSelected,
    });
  };
}

export function getPaymentMethods() {
  return function(dispatch) {
    dispatch({ type: GET_PAYMENT_METHODS_REQUEST });
    return api.get(`/payment-methods`).then(
      (response) => {
        dispatch({
          type: GET_PAYMENT_METHODS_SUCCESS,
          response: response.data,
        });
      },
      (error) => {
        dispatch({ type: GET_PAYMENT_METHODS_FAILURE, error });
      }
    );
  };
}

export function showPaymentMethodForm(noDialogBackground) {
  return function(dispatch, getState) {
    const paymentMethods = getState().paymentMethodsReducer.paymentMethods;
    dispatch({
      type: SHOW_PAYMENT_METHOD_FORM,
      noDialogBackground: noDialogBackground && paymentMethods.length,
    });
  };
}

export function showSelectPaymentMethodForm(bool) {
  return function(dispatch) {
    dispatch({
      type: SHOW_SELECT_PAYMENT_METHOD_FORM,
      selectPaymentMethodFormIsVisible: bool,
    });
  };
}

export function setSelectedPaymentMethod(selectedPaymentMethod) {
  return function(dispatch) {
    dispatch({
      type: SET_SELECTED_PAYMENT_METHOD,
      selectedPaymentMethod: selectedPaymentMethod,
    });
  };
}

export function showPaymentMethodSelectionForm(bool) {
  return function(dispatch) {
    dispatch({
      type: SHOW_PAYMENT_METHOD_SELECTION_FORM,
      paymentMethodSelectionFormIsVisible: bool,
    });
  };
}

export function clearErrorMessage() {
  return {
    type: CREATE_PAYMENT_METHOD_FAILURE,
    errorMessage: {},
  };
}
export function hidePaymentMethodForm() {
  return {
    type: HIDE_PAYMENT_METHOD_FORM,
  };
}

export function createPaymentMethod({
  card_number,
  cardholder_name,
  cvv,
  expiry_month,
  expiry_year,
  postal_code,
  street_number,
  street_name,
}) {
  let formBody = {
    type: "credit_card",
    details: {
      card_number,
      cardholder_name,
      cvv,
      expiry_date: `${expiry_year}-${expiry_month}-01`,
    },
  };
  if (shouldValidatePostalCode) {
    formBody = { ...formBody, details: { ...formBody.details, postal_code } };
  }
  if (shouldValidateAddress) {
    formBody = {
      ...formBody,
      details: { ...formBody.details, street_number, street_name },
    };
  }
  return function(dispatch) {
    dispatch({
      type: CREATE_PAYMENT_METHOD_FAILURE,
      errorMessage: {},
    });
    if (expiry_month > 12) {
      return dispatch({
        type: CREATE_PAYMENT_METHOD_FAILURE,
        errorMessage: { message: "Invalid Expiry Date" },
      });
    }
    if (expiry_year.length < 4) {
      return dispatch({
        type: CREATE_PAYMENT_METHOD_FAILURE,
        errorMessage: { message: "Invalid Expiry Year" },
      });
    }
    if (!isValidCreditCard(expiry_month, expiry_year)) {
      return dispatch({
        type: CREATE_PAYMENT_METHOD_FAILURE,
        errorMessage: { message: "Expired Credit Card" },
      });
    }

    dispatch({ type: CREATE_PAYMENT_METHOD_REQUEST });
    return api
      .post(`/payment-methods`, formBody, { ignore_interception: true })
      .then((response) => {
        dispatch({
          type: CREATE_PAYMENT_METHOD_SUCCESS,
          response: response.data,
        });
        dispatch({
          type: CREATE_PAYMENT_METHOD_FAILURE,
          errorMessage: {},
        });
        dispatch(getPaymentMethods());
      })
      .catch((error) => {
        dispatch({
          type: CREATE_PAYMENT_METHOD_FAILURE,
          errorMessage: error.response.data.errors,
        });
      });
  };
}

export function setDefaultPaymentMethod(cardId) {
  return function(dispatch) {
    dispatch({ type: SET_DEFAULT_PAYMENT_METHOD_REQUEST });
    return api.post(`/payment-methods/${cardId}/default`).then(
      (response) => {
        dispatch({
          type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
          cardId,
        });
      },
      (error) => {
        dispatch({ type: SET_DEFAULT_PAYMENT_METHOD_FAILURE, error });
      }
    );
  };
}

export function showConfirmDeletePaymentMethod() {
  return {
    type: SHOW_CONFIRM_DELETE_PAYMENT_METHOD,
  };
}

export function hideConfirmDeletePaymentMethod() {
  return {
    type: HIDE_CONFIRM_DELETE_PAYMENT_METHOD,
  };
}

export function deletePaymentMethod(cardId) {
  return function(dispatch) {
    dispatch({ type: DELETE_PAYMENT_METHOD_REQUEST });
    return api.delete(`/payment-methods/${cardId}`).then(
      (response) => {
        dispatch(getPaymentMethods());
        dispatch({
          type: DELETE_PAYMENT_METHOD_SUCCESS,
          response: response.data,
        });
      },
      (error) => {
        dispatch({ type: DELETE_PAYMENT_METHOD_FAILURE, error });
      }
    );
  };
}

export function changeGuestPaymentMethod(method) {
  return function(dispatch) {
    dispatch({ type: CHANGE_GUEST_PAYMENT_METHOD, method });
  };
}

export function updateGuestPaymentDetails(guestCreditCardDetails) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_GUEST_CREDIT_CARD_DETAILS,
      guestCreditCardDetails,
    });
  };
}

export function resetGuestCreditCardDetails() {
  return function(dispatch) {
    dispatch({ type: RESET_GUEST_CREDIT_CARD_DETAILS });
  };
}

export function updateGuestGiftCardDetails(guestGiftCardDetails) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_GUEST_GIFT_CARD_DETAILS,
      guestGiftCardDetails,
    });
  };
}

export function resetGuestGiftCardDetails() {
  return function(dispatch) {
    dispatch({ type: RESET_GUEST_GIFT_CARD_DETAILS });
  };
}

export function setTouchedCreditCardDetails(bool) {
  return function(dispatch) {
    dispatch({ type: SET_TOUCHED_CREDIT_CARD_DETAILS, bool });
  };
}
