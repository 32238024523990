export const UPDATE_INTER_ORDER_TYPE = 'UPDATE_INTER_ORDER_TYPE';
export const UPDATE_INTER_PICKUP_DETAILS = 'UPDATE_INTER_PICKUP_DETAILS';
export const UPDATE_INTER_DELIVERY_DETAILS = 'UPDATE_INTER_DELIVERY_DETAILS';
export const UPDATE_INTER_DROPOFF_DETAILS = 'UPDATE_INTER_DROPOFF_DETAILS';
export const GET_DATE_TIMES = 'GET_DATE_TIMES';
export const UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE =
  'UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE';
export const CONFIRM_PICKUP_DETAILS = 'CONFIRM_PICKUP_DETAILS';
export const UPDATE_INTER_SELECTED_DATE_TIME =
  'UPDATE_INTER_SELECTED_DATE_TIME';
export const RESET_INTER_SELECTED_DATE_TIME = 'RESET_INTER_SELECTED_DATE_TIME';
export const UPDATE_INTER_DELIVERY_DETAILS_REQUEST =
  'UPDATE_INTER_DELIVERY_DETAILS_REQUEST';
export const UPDATE_INTER_PICKUP_DETAILS_REQUEST =
  'UPDATE_INTER_PICKUP_DETAILS_REQUEST';
export const UPDATE_INTER_DROPOFF_DETAILS_REQUEST =
  'UPDATE_INTER_DROPOFF_DETAILS_REQUEST';
export const CONFIRM_DELIVERY_DETAILS = 'CONFIRM_DELIVERY_DETAILS';
export const CONFIRM_DROPOFF_DETAILS = 'CONFIRM_DROPOFF_DETAILS';
export const RESET_DATE_TIMES = 'RESET_DATE_TIMES';
export const RESET_ORDER_SETTING_REDUCER = 'RESET_ORDER_SETTING_REDUCER';
export const CHANGE_PICKUP_LOCATION = 'CHANGE_PICKUP_LOCATION';
export const HIDE_TOOL_TIP = 'HIDE_TOOL_TIP';
export const RESET_ORDER_SETTING_TIME_SETTINGS =
  'RESET_ORDER_SETTING_TIME_SETTINGS';
export const RESET_TIME_NO_LONGER_VALID = 'RESET_TIME_NO_LONGER_VALID';
export const SET_ORDERING_CLOSED_MODAL_IS_VISIBLE =
  'SET_ORDERING_CLOSED_MODAL_IS_VISIBLE';
export const RESET_UNAVAILABLE_PRODUCTS = 'RESET_UNAVAILABLE_PRODUCTS';
export const GET_SPECIAL_HOURS = 'GET_SPECIAL_HOURS';
export const GET_CHILD_LOCATIONS = 'GET_CHILD_LOCATIONS';

export const PICKUP = 'pickup';
export const DELIVERY = 'delivery';
export const DROPOFF = 'drop_off';
export const ASAP = 'asap';
export const NOW = 'Now';

export const INTER_DELIVERY_ERROR_MESSAGE = 'INTER_DELIVERY_ERROR_MESSAGE';
export const PICKUP_TIME_FORM_IS_VISIBLE = 'PICKUP_TIME_FORM_IS_VISIBLE';
export const SET_CHILD_LOCATIONS = 'SET_CHILD_LOCATIONS';

export const CHANGE_PICKUP_LOCATION_FROM_LOCATIONS_PAGE =
  'CHANGE_PICKUP_LOCATION_FROM_LOCATIONS_PAGE';

export const UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE_FOR_LOCATIONS_PAGE =
  'UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE_FOR_LOCATIONS_PAGE';

export const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE';
export const UPDATE_ALL_ORDER_TYPE = 'UPDATE_ALL_ORDER_TYPE';

export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';

export const GET_MARKETPLACE_DELIVERY_LOCATIONS =
  'GET_MARKETPLACE_DELIVERY_LOCATIONS';

export const CONFIRM_MARKETPLACE_DELIVERY_DETAILS =
  'CONFIRM_MARKETPLACE_DELIVERY_DETAILS';

export const GET_MARKETPLACE_DELIVERY_LOCATIONS_REQUEST =
  'GET_MARKETPLACE_DELIVERY_LOCATIONS_REQUEST';

  export const SET_INTER_DELIVERY_DETAILS_ID = 'SET_INTER_DELIVERY_DETAILS_ID';

export const DELIVERY_TIME_FORM_IS_VISIBLE = 'DELIVERY_TIME_FORM_IS_VISIBLE';

export const CONFIRM_MARKETPLACE_DELIVERY_DETAILS_REQUEST =
  'CONFIRM_MARKETPLACE_DELIVERY_DETAILS_REQUEST';

export const CHECK_UNAVAILABLE_PRODUCT_IN_CART =
  'CHECK_UNAVAILABLE_PRODUCT_IN_CART';
export const CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST =
  'CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST';

export const START_NEW_ORDER_FORM_IS_VISIBLE =
  'START_NEW_ORDER_FORM_IS_VISIBLE';

export const SET_MARKETPLACE_DELIVERY_SELECTION =
  'SET_MARKETPLACE_DELIVERY_SELECTION';

export const RESET_MARKETPLACE_DELIVERY_LOCATIONS =
  'RESET_MARKETPLACE_DELIVERY_LOCATIONS';

export const RESET_INTER_DELIVERY_DETAILS = 'RESET_INTER_DELIVERY_DETAILS';
export const SET_PARENT_LOCATION = 'SET_PARENT_LOCATION';
export const UPDATE_AVAILABLE_TIMES = 'UPDATE_AVAILABLE_TIMES';
export const SET_IS_STORE_DEEP_LINK = 'SET_IS_STORE_DEEP_LINK';
export const SET_IS_PARENT_STORE_DEEP_LINK = 'SET_IS_PARENT_STORE_DEEP_LINK';
export const SET_IS_MENU_DISABLED = 'SET_IS_MENU_DISABLED';