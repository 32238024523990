const index = require('../web-ordering-client-config/index.json');
const rapidDeploymentSchema = require('./rapidDeploymentSchema.json');
const baseIndex = require('./baseIndex.json');
const flatten = require('flat');
const unflatten = require('flat').unflatten;

exports.createConfig = () => {
  const colorSchema =
    index.colorSchemaFilename === 'rapidDeploymentSchema.json'
      ? rapidDeploymentSchema
      : null;

  let newIndex;
  const flatIndex = flatten(index);
  const flatBaseIndex = flatten(baseIndex);

  if (colorSchema) {
    newIndex = {
      ...flatBaseIndex,
      ...flatIndex,
      ...Object.entries(colorSchema).reduce((acc, pair) => {
        const [themeColor, colorList] = pair;
        colorList.map(
          (color) =>
            (acc[`styleConfig.${color}`] = flatIndex[`theme.${themeColor}`])
        );
        return acc;
      }, {}),
    };
    newIndex = unflatten(newIndex);
    delete newIndex['theme'];
    return newIndex;
  } else {
    newIndex = { ...flatBaseIndex, ...flatIndex };
    return unflatten(newIndex);
  }
};
