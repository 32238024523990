import styled from "styled-components";

const Subhead = styled.p`
  font-size: 15px;
  color: ${(props) => props.theme.subHeadDefaultFontColor};
  font-weight: ${(props) => props.theme.subHeadDefaultFontWeight};
  text-transform: ${(props) => props.textTransform || "unset"};
  text-align: ${(props) => props.textAlign || "inherit"};
  margin: 0;
  font-family: ${(props) => props.theme.secondaryFontFontFamily};
`;

export default Subhead;
