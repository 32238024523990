export default product => {
  let skinnyProduct = {
    product_id: product.product_id,
    special_instruction: product.special_instruction,
    quantity: product.quantity,
    configuration: {
      variant_id: product.variant_id,
      options: [],
    },
  };

  const mapOption = option => {
    return {
      option_id: option.option_id,
      items: option.items.map(item => {
        if (item.sub_items) {
          const itemToReturn = {
            item_id: item.item_id,
            sub_items: item.sub_items
              .map(subItem => {
                if (subItem.quantity) {
                  return {item_id: subItem.item_id, quantity: subItem.quantity};
                }
              })
              .filter(subItem => subItem),
          };
          if (itemToReturn.sub_items.length) {
            return itemToReturn;
          }
        } else if (item.quantity) {
          return {item_id: item.item_id, quantity: item.quantity};
        }
      }),
    };
  };

  if (product.options) {
    skinnyProduct = {
      ...skinnyProduct,
      configuration: {
        ...skinnyProduct.configuration,
        options: product.options.map(option => {
          if (option.sub_options.length) {
            const optionToReturn = {
              option_id: option.option_id,
              items: [],
              sub_options: option.sub_options.map(subOption =>
                mapOption(subOption)
              ),
            };
            if (optionToReturn.sub_options.length) {
              return optionToReturn;
            }
          } else {
            return mapOption(option);
          }
        }),
      },
    };
  }

  return {config_products: [skinnyProduct]};
};
