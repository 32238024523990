import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,
  SET_ORDER_TYPE,
  RESET_MENU_REDUCER,
  SET_VIEW_ONLY_MENU
} from './menuTypes';
import { UPDATE_ALL_ORDER_TYPE } from '../../OrderSetting/orderSettingTypes';

const initialState = {
  requestingGetMenu: true,
  categories: [],
  products: false,
  orderType: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ALL_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
    };
    case GET_MENU_REQUEST:
      return {
        ...state,
        requestingGetMenu: true,
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        requestingGetMenu: false,
        categories: action.categories,
        products: action.products,
      };
    case GET_MENU_FAILURE:
      return {
        ...state,
        requestingGetMenu: false,
        error: action.error,
      };
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case RESET_MENU_REDUCER:
      return initialState;
    default:
      return state;
  }
}
