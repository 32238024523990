import React, {Fragment} from 'react';
import {Element} from 'react-scroll';
import styled from 'styled-components';
import {Title2} from '../../../../components/Typography';
import getSelectedItems from '../../utils/getSelectedItems';
import SelectedItem from './components/SelectedItem';
import AddItemButton from './components/AddItemButton';
import EditOptionButton from './components/EditOptionButton';

const OptionWrapper = styled.div``;

const OptionTitleContainer = styled.div`
  width: 100%;
  padding-top: ${props => (props.index === 0 ? '25px' : '0')};
`;

const OptionTitle = styled(Title2)`
  width: fit-content;
  font-weight: normal;
  margin-bottom: 15px;
  display: inline-block;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const MaximumPick = styled.div`
  margin-bottom: 12px;
  color: ${props => props.theme.darkGray};
`;

const MinimumPick = styled.span`
  float: right;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 14px;
  color: ${props => props.theme.categoriesBarSelectedBorderColor};
  background-color: #f3f3f3;
`;

const OptionSummary = ({
  flatProduct,
  option,
  removeItem,
  removeSubItem,
  handleOpenDrawerModal,
}) => {
  const openDrawer = () => {
    handleOpenDrawerModal(option.option_id);
  };
  const selectedItems = getSelectedItems(option, flatProduct);
  return (
    <Element
      name={`option_${option.option_id}`}
      id={`option_${option.option_id}`}
      key={option.option_id}
    >
      <OptionWrapper key={option.option_id}>
        <OptionTitleContainer>
          <OptionTitle id="OptionSummary-OptionTitle">
            {option.name}
          </OptionTitle>
          {option.minimum_pick > 0 && (
            <MinimumPick>{option.minimum_pick} required</MinimumPick>
          )}
        </OptionTitleContainer>
        {selectedItems.length ? (
          <Fragment>
            {selectedItems.map((item, i) => (
              <SelectedItem
                item={item}
                key={i}
                removeItem={removeItem}
                removeSubItem={removeSubItem}
              />
            ))}
            <EditOptionButton handleEditOption={openDrawer} />
          </Fragment>
        ) : (
          <AddItemButton option={option} handleAddItem={openDrawer} />
        )}
      </OptionWrapper>
    </Element>
  );
};

export default OptionSummary;
