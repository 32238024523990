import React, {useEffect} from 'react';
import {
  PICKUP,
  DELIVERY,
  DROPOFF,
  UPDATE_INTER_ORDER_TYPE,
} from '../../OrderSetting/orderSettingTypes';
import Select from '../../../components/Select';
import {useDispatch} from 'react-redux';

import {
  updateInterPickUpDetails,
  updateInterDeliveryDetails,
  updateInterDropOffDetails,
} from '../../OrderSetting/orderSettingActions';

function OrderMethodSelection({
  interOrderType,
  orderMethodDetails,
  isAuthenticated,
}) {
  const orderTypes = orderMethodDetails
    ? orderMethodDetails.accepted_order_types
    : [PICKUP, DROPOFF];

  const onOrderTypeChange = interOrderType => {
    dispatch({type: UPDATE_INTER_ORDER_TYPE, interOrderType});
    if (interOrderType === PICKUP) {
      dispatch(
        updateInterPickUpDetails(orderMethodDetails, new Date().toString())
      );
    } else if (interOrderType === DELIVERY) {
      dispatch(updateInterDeliveryDetails(orderMethodDetails));
    } else if (interOrderType === DROPOFF) {
      dispatch(
        updateInterDropOffDetails(orderMethodDetails, new Date().toString())
      );
    }
  };

  useEffect(() => {
    onOrderTypeChange(interOrderType);
  }, []);

  const dispatch = useDispatch();
  return (
    <Select
      options={orderTypes.map(value => ({
        value: value,
        name: value,
      }))}
      placeholder="Select Order Type"
      getOptionLabel={option => option.name.replaceAll('_', '-')}
      getOptionValue={option => option.name}
      onChange={interOrderType => onOrderTypeChange(interOrderType)}
      value={{value: interOrderType, name: interOrderType}}
    />
  );
}

export default OrderMethodSelection;
