import React, {useEffect} from 'react';
import {Dialog} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import {Formik} from 'formik';
import {PrimaryButton, ButtonRow} from '../../../components/Buttons';
import TextField from '@material-ui/core/TextField';
import FormGrid from '../../../components/Form/FormGrid';
import {Title1} from '../../../components/Typography';
import PhoneNumber from "../../../layout/components/PhoneNumber";

const styles = () => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? '300px'
        : '100%',
  },
});
const FormLayout = styled(FormGrid)`
  grid-gap: 20px;
`;
const Cancel = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.54);
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
  }
`;

const CancelWrapper = styled.div``;
const Wrapper = styled.div`
  width: 443px;
  padding: 20px;
  background: white;
  margin: auto;
  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
`;
const PhoneNumberText = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const TitleWrapper = styled(Title1)`
  letter-spacing: 0.04px;
  font-weight: 600;
  width: fit-content;
  margin: 9px 0 24px 0;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
  }
`;

const validateEmail = values => {
  let errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isEmail.test(values.email)) {
    errors.email = 'Please put a valid email format';
  }
  return errors;
};
const validatePhoneNumber = (values) => {
  let errors = {};
  if (!values.phone_number) {
    errors.phone_number = "Required";
  }
  return errors;
};

const isEmail = new RegExp(
  '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
    '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
);

const ResendModal = ({
  classes,
  isResendModalVisible,
  setIsResendModalVisible,
  customStyle,
  emailOrPhoneField,
  postForgotPassword,
  setEmailOrPhoneObject,
  emailOrPhoneObject,
}) => {
  const handleSubmitAction = obj => {
    setEmailOrPhoneObject(obj);
    postForgotPassword(obj);
    setIsResendModalVisible(false);
  };
  const createInitialValues = () => {
    let initialValues = {};
    if (emailOrPhoneField === 'email') {
      initialValues = {email: emailOrPhoneObject.email, phone_number: ''};
    } else {
      initialValues = {
        email: '',
        phone_number: emailOrPhoneObject.phone_number,
      };
    }
    return initialValues;
  };

  return (
    <Dialog
      id="ResendModal-Dialog"
      aria-labelledby="resend-modal"
      aria-describedby="resend-modal"
      open={isResendModalVisible}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{scrollPaper: classes.scrollPaper}}
      PaperProps={{classes: {root: classes.paper}}}
      style={{
        height: customStyle && '230px',
        marginTop: customStyle && '20%',
      }}
    >
      <Wrapper>
        <CancelWrapper>
          <Cancel onClick={() => setIsResendModalVisible(false)}>
            <i className="fal fa-times" />
          </Cancel>
        </CancelWrapper>
        <TitleWrapper>{`Verify ${
          emailOrPhoneField === 'email' ? 'Email' : 'Phone Number'
        }`}</TitleWrapper>
        <Formik
          initialValues={createInitialValues()}
          validate={values => {
            let errors = {};
            if (emailOrPhoneField === 'email') {
              errors = validateEmail(values);
            } else {
              errors = validatePhoneNumber(values);
            }
            return errors;
          }}
          onSubmit={values => {
            emailOrPhoneField === 'email'
              ? handleSubmitAction({email: values.email})
              : handleSubmitAction({phone_number: values.phone_number});
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormLayout>
                {emailOrPhoneField === 'email' ? (
                  <TextField
                    id="Resend-Modal-Username"
                    label="Email"
                    name="email"
                    type="email"
                    autoComplete="username"
                    value={values.email}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    error={touched.email && errors.email ? true : false}
                    helperText={touched.email && errors.email}
                    InputLabelProps={{
                      className: classes.input,
                      required: false,
                    }}
                  />
                ) : (
                  <PhoneNumberText>
                    
                    <PhoneNumber
                      id="Resend-Modal-PhoneNumberField"
                      label="Phone Number"
                      name="phone_number"
                      handlePhone={(value) => setFieldValue("phone_number", value)}
                      handleBlur={handleBlur}
                      required
                      error={
                        touched.phone_number && errors.phone_number ? true : false
                      }
                      helperText={touched.phone_number && errors.phone_number}
                    />
                    
                  </PhoneNumberText>
                )}
                <ButtonRow style={{marginTop: '12px'}} justifyContent="center">
                  <PrimaryButton
                    buttonBorderRadius="10px"
                    style={{
                      minWidth: '240px',
                      minHeight: '56px',
                      fontWeight: '600',
                    }}
                    id="Resend-Modal-Submit"
                    type="submit"
                    disabled={
                      emailOrPhoneField === 'email'
                        ? !values.email
                        : !values.phone_number
                    }
                  >
                    Resend Code
                  </PrimaryButton>
                </ButtonRow>
              </FormLayout>
            </form>
          )}
        </Formik>
      </Wrapper>
    </Dialog>
  );
};
export default withStyles(styles)(ResendModal);
