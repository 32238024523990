export const GET_CUSTOMER_PROFILE_REQUEST = 'GET_CUSTOMER_PROFILE_REQUEST';
export const GET_CUSTOMER_PROFILE_SUCCESS = 'GET_CUSTOMER_PROFILE_SUCCESS';
export const GET_CUSTOMER_PROFILE_FAILURE = 'GET_CUSTOMER_PROFILE_REQUEST';

export const EDIT_PROFILE_SETTINGS_REQUEST = 'EDIT_PROFILE_SETTINGS_REQUEST';
export const EDIT_PROFILE_SETTINGS_SUCCESS = 'EDIT_PROFILE_SETTINGS_SUCCESS';
export const EDIT_PROFILE_SETTINGS_FAILURE = 'EDIT_PROFILE_SETTINGS_REQUEST';

export const SET_USER_COORDS = 'SET_USER_COORDS';

export const RESET_EDIT_PROFILE_SETTINGS = 'RESET_EDIT_PROFILE_SETTINGS';

export const SAVE_BIRTHDAY_REQUEST = 'SAVE_BIRTHDAY_REQUEST';
export const SAVE_BIRTHDAY_SUCCESS = 'SAVE_BIRTHDAY_SUCCESS';

export const OPEN_BIRTHDAY_MODAL = 'OPEN_BIRTHDAY_MODAL';
export const CLOSE_BIRTHDAY_MODAL = 'CLOSE_BIRTHDAY_MODAL';
