import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as queryString from "query-string";
import { insertParameters, resetParameters } from "./DeepLinkActions";

function useLinkParameters(location) {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (Object.keys(params).length > 0) {
      dispatch(insertParameters(params));
    }
  }, [location.search]);
}

export default useLinkParameters;
