import {
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
} from "./locationsTypes";
import api from "../../../api";
import {
  resetOrderSettingReducer,
  updateInterSelectedDateTime,
  updateInterDeliveryDetails,
  updateInterOrderType,
  updateInterDropOffDetails,
} from "../../OrderSetting/orderSettingActions";
import { getFirstDropOffSelection } from "../../DropOff/dropOffLocationActions";
import {
  updateInterPickUpDetails,
  changePickUpLocation,
} from "../../OrderSetting/orderSettingActions";
import {
  PICKUP,
  ASAP,
  DELIVERY,
  DROPOFF,
} from "../../OrderSetting/orderSettingTypes";
import { resetMenuReducer } from "../Menu/menuActions";
import appConfigFile from "../../../../app-config";
import { initialState as orderSettingInitialState } from "../../OrderSetting/orderSettingReducer";

export const calculateInterValue = (
  locations,
  pickUpDetailsUpdated,
  orderHistory,
  isUserAuth,
  coords
) => {
  let preSelectedLocation = {
    location: null,
    isNearestLocation: false,
    isLastOrderLocation: false,
  };

  const closestLocation = locations.find(
    (location) =>
      location.accepting_orders &&
      location.is_order_ahead_enabled &&
      location.accepted_order_types.includes(PICKUP)
  );

  let firstOrderLocation;

  if (orderHistory.length) {
    firstOrderLocation = locations.find(
      (location) => location.id === orderHistory[0].business_detail.business_id
    );
  }

  if (
    (!isUserAuth && !coords) ||
    (isUserAuth && !orderHistory.length && !coords) ||
    (isUserAuth && orderHistory.length && !firstOrderLocation && !coords)
  ) {
    preSelectedLocation = { location: null, isNearestLocation: false };
    return preSelectedLocation;
  } else if (
    (!isUserAuth && coords) ||
    (isUserAuth && !orderHistory.length && coords) ||
    (isUserAuth && orderHistory.length && !firstOrderLocation && coords)
  ) {
    preSelectedLocation = {
      location: closestLocation,
      isNearestLocation: true,
    };
    return preSelectedLocation;
  } else if (isUserAuth && orderHistory.length && firstOrderLocation) {
    preSelectedLocation = {
      location: firstOrderLocation,
      isNearestLocation: false,
      isLastOrderLocation: true,
    };
    return preSelectedLocation;
  } else {
    return preSelectedLocation;
  }
};

export function getLocations(coords) {
  const queryString =
    coords && coords.latitude && coords.longitude
      ? `?latitude=${coords.latitude}&longitude=${coords.longitude}`
      : "";

  return function(dispatch, getState) {
    dispatch({ type: GET_LOCATIONS_REQUEST });
    return api.get(`/businesses${queryString}`).then((response) => {
      const {
        interPickUpDetails,
        interSelectedDateTime,
        interDeliveryDetails,
        interDropOffDetails,
      } = getState().orderSettingReducer;
      const orderHistory = getState().myTransactionsReducer.myTransactions;
      const orders = getState().orderHistoryReducer.orderHistory;
      const isUserAuth = getState().authReducer.isAuthenticated;
      const pickUpDetailsUpdated = interPickUpDetails
        ? response.data.data.find(
            (location) => location.id === interPickUpDetails.id
          )
        : null;

      if (
        interPickUpDetails &&
        !response.data.data.find(
          (location) => location.id === interPickUpDetails.id
        ) &&
        !appConfigFile.isParentChildClient
      ) {
        dispatch(resetOrderSettingReducer());
      }

      response.data.data.map((res) => {
        const index = res.accepted_order_types.indexOf("scan_and_go");
        if (index > -1) {
          res.accepted_order_types.splice(index, 1);
        }
      });

      let emptyOrderTypesIndex = -1;
      response.data.data.forEach((res, index) => {
        if (res.accepted_order_types.length === 0) {
          emptyOrderTypesIndex = index;
        }
      });

      if (emptyOrderTypesIndex > -1) {
        response.data.data.splice(emptyOrderTypesIndex, 1);
      }

      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        response: response.data,
        queryString: coords,
      });
      dispatch({ type: "SHOW_TOOL_TIP" });

      let locations = response.data.data;

      const preselectedValue = calculateInterValue(
        locations,
        pickUpDetailsUpdated,
        orderHistory,
        isUserAuth,
        coords
      );

      if (
        preselectedValue &&
        preselectedValue.isLastOrderLocation &&
        preselectedValue.location.accepting_orders
      ) {
        let selectedDateTimeModified = {
          ...interSelectedDateTime,
          // radioValue: {name: 'As soon as possible', value: 'asap'},
        };
        if (selectedDateTimeModified.radioValue.value === "asap") {
          selectedDateTimeModified = {
            ...selectedDateTimeModified,
            radioValue: { name: "As soon as possible", value: "asap" },
          };
        } else if (selectedDateTimeModified.radioValue.value === "schedule") {
        }
        dispatch(updateInterSelectedDateTime(selectedDateTimeModified));
      }

      if (locations) {
        // dispatch(
        //   updateInterPickUpDetails(
        //     preselectedValue.location,
        //     new Date().toString(),
        //     preselectedValue.isNearestLocation
        //   )
        // );
        // dispatch({type: 'SHOW_TOOL_TIP'});
        let locations = response.data.data;

        const isPickupMethodEnabled =
        appConfigFile.isPickUpEnabled || appConfigFile.isFutureOrderPickup;
        const isDeliveryMethodEnabled =
        appConfigFile.isDeliveryEnabled || appConfigFile.isFutureOrderDelivery;
        const isDropOffMethodEnabled =
        appConfigFile.isDropOffEnabled || appConfigFile.isFutureOrderDropOff;

        const lastOrder = orders.length ? orders[0] : null;

        if (
          lastOrder &&
          !interDeliveryDetails &&
          !interDropOffDetails &&
          !interPickUpDetails
        ) {
          const businessId = lastOrder.business_id;
          const foundBusiness = locations.find(
            (location) => location.id === businessId
          );

          if (foundBusiness) {
            if (
              lastOrder.order_type === DELIVERY &&
              isDeliveryMethodEnabled &&
              foundBusiness.accepted_order_types.includes(DELIVERY)
            ) {
              // if (!interDropOffDetails) {
              //   dispatch(updateInterOrderType(DELIVERY));
              // }
              dispatch(
                updateInterDeliveryDetails(
                  {
                    ...lastOrder.customer_address,
                    deliveryBusiness: foundBusiness,
                  },
                  true
                )
              );
            } else if (
              lastOrder.order_type === DROPOFF &&
              isDropOffMethodEnabled &&
              foundBusiness.accepted_order_types.includes(DROPOFF)
            ) {
              // dispatch(updateInterOrderType(DROPOFF));
              dispatch(
                updateInterDropOffDetails(foundBusiness, new Date().toString())
              );
              dispatch(getFirstDropOffSelection(foundBusiness.id));
            } else if (
              lastOrder.order_type === PICKUP &&
              isPickupMethodEnabled &&
              foundBusiness.accepted_order_types.includes(PICKUP) &&
              !appConfigFile.isMarketplaceDelivery
            ) {
              // if (!interDropOffDetails) {
              //   dispatch(updateInterOrderType(PICKUP));
              // }

              dispatch(
                updateInterPickUpDetails(foundBusiness, new Date().toString())
              );
            }
          } else {
            if (!appConfigFile.isParentChildClient) {
              dispatch(resetMenuReducer());
              dispatch(resetOrderSettingReducer());
            }
          }
          dispatch({
            type: GET_LOCATIONS_SUCCESS,
            response: response.data,
            queryString: coords,
          });
        }
      } else {
        dispatch(resetMenuReducer());
        dispatch(resetOrderSettingReducer());
      }
      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        response: response.data,
        queryString: coords,
      });
    });
  };
}
