import React from 'react';
import {connect} from 'react-redux';
import EmptyComboGroupPreview from './components/EmptyComboGroupPreview';
import FilledComboGroupPreview from './components/FilledComboGroupPreview';
import RequiredComboGroupOneItemPreview from './components/RequiredComboGroupOneItemPreview';

const ComboGroupPreview = ({
  combo,
  onGroupClick,
  onEditProductInGroup,
  onRemoveProductInGroup,
}) => {
  return combo.combo_item_groups.map((comboGroup, i) => {
    if (comboGroup.productCustomizer) {
      return (
        <FilledComboGroupPreview
          comboGroup={comboGroup}
          onGroupClick={onGroupClick}
          onEditProductInGroup={onEditProductInGroup}
          onRemoveProductInGroup={onRemoveProductInGroup}
          comboGroupIndex={i}
          key={`combo-group-${comboGroup.combo_item_group_id}-${i}`}
          id={`combo-group-${comboGroup.combo_item_group_id}`}
        />
      );
    } else if (comboGroup.is_required && 1 === comboGroup.productItems.length) {
      return (
        <RequiredComboGroupOneItemPreview
          comboGroup={comboGroup}
          onGroupClick={onGroupClick}
          comboGroupIndex={i}
          key={`combo-group-${comboGroup.combo_item_group_id}-${i}`}
          id={`combo-group-${comboGroup.combo_item_group_id}`}
        />
      );
    } else {
      return (
        <EmptyComboGroupPreview
          comboGroup={comboGroup}
          onGroupClick={onGroupClick}
          comboGroupIndex={i}
          key={`combo-group-${comboGroup.combo_item_group_id}-${i}`}
          id={`combo-group-${comboGroup.combo_item_group_id}`}
        />
      );
    }
  });
};

export default ComboGroupPreview;
