import {
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
  SET_ORDER_HISTORY_MODAL_VISIBLE,
} from './orderHistoryTypes';
import api from '../../api';
import flattenProduct from '../../utils/flattenProduct';
import calculatePrice from '../../utils/calculatePrice';

export function getOrderHistory() {
  return function(dispatch) {
    dispatch({type: GET_ORDER_HISTORY_REQUEST});
    api.get(`/orders`).then(response => {
      dispatch({
        type: GET_ORDER_HISTORY_SUCCESS,
        orderHistory: response.data.data,
      });
    });
  };
}

export function setOrderHistoryModalIsVisible(isVisible) {
  return function(dispatch) {
    dispatch({type: SET_ORDER_HISTORY_MODAL_VISIBLE, isVisible: isVisible});
  };
}

export function addProductsToCart(products) {
  return function(dispatch, getState) {
    const menuProducts = getState().menuReducer.products;
    products.map(product => {
      const foundProduct = menuProducts.find(
        menuProduct => menuProduct.product_id === product.product_id
      );

      if (foundProduct) {
        let flatProduct = flattenProduct({
          ...product,
          prices: product.menuProduct.prices,
          options: product.options || [],
        });

        dispatch({
          type: 'ADD_TO_CART',
          product: {
            isEditing: false,
            isFromPreviousOrder: true,
            requestGetProductToConfigure: false,
            selectedVariantId: flatProduct.variant_id,
            flatProduct: flatProduct,
            ...calculatePrice(flatProduct),
          },
        });
      }
    });
  };
}
