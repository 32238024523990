import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import * as actions from "../deliveryAddressesActions";
import { setAuthenticationModalIsVisible } from "../../Auth/authActions";
import DeliveryAddressesForm from "./components/DeliveryAddressesForm";
import StickyErrorMessageBox from "../../../components/ErrorMessage/StickyErrorMessageBox";

const Transition = (props) => <Fade direction="down" {...props} />;
const ErrorMesageWrapper = styled.div`
  padding: 0;
  max-width: 600px;
  margin: 0;
`;
const styles = () => ({
  scrollPaper: {
    // alignItems:
    //   typeof window !== 'undefined' && window.innerWidth < 786
    //     ? 'flex-end'
    //     : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: "600px",
    ["@media(max-width: 768px)"]: {
      maxWidth: "100% !important",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
    },
  },
});

const DeliveryAddressesFormContainer = ({
  deliveryAddressesFormIsVisible,
  setDeliveryAddressesFormIsVisible,
  classes,
  saveDeliveryAddress,
  deliveryAddressIsSaving,
  deliveryAddressesFormInitialValues,
  updateDeliveryAddress,
  resetDeliveryAddressesReducer,
  deleteDeliveryAddress,
  deleteDeliveryAddressIsRequesting,
  isAuthenticated,
  setAuthenticationModalIsVisible,
  deliverToThisAddress,
  deliveryAddressErrors,
  clearDeliveryAddressErrorMessage,
}) => {
  useEffect(() => {
    if (!deliveryAddressesFormIsVisible) {
      resetDeliveryAddressesReducer();
      setAuthenticationModalIsVisible(false);
    } else if (deliveryAddressesFormIsVisible && !isAuthenticated) {
      setAuthenticationModalIsVisible(true);
    }
  }, [deliveryAddressesFormIsVisible]);

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listener);
    clearDeliveryAddressErrorMessage();
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  const listener = () => {
    setScrollY(typeof window !== "undefined" && window.scrollY);
  };

  useEffect(() => {
    if (!deliveryAddressesFormIsVisible) window.scrollTo(0, scrollY);
  }, [deliveryAddressesFormIsVisible]);
  return (
    <Dialog
      open={deliveryAddressesFormIsVisible}
      onClose={() => setDeliveryAddressesFormIsVisible(false)}
      fullWidth={typeof window !== "undefined" && window.innerWidth < 786}
      // TransitionComponent={Transition}
      classes={{ scrollPaper: classes.scrollPaper }}
      PaperProps={{ classes: { root: classes.paper } }}
      aria-labelledby="delivery-addresses-form"
      scroll="body"
    >
      {deliveryAddressErrors && Object.keys(deliveryAddressErrors).length > 0 && (
        <ErrorMesageWrapper>
          <StickyErrorMessageBox
            errors={deliveryAddressErrors}
            marginTop="0"
            messageWidth="550px"
          />
        </ErrorMesageWrapper>
      )}
      <DeliveryAddressesForm
        setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
        saveDeliveryAddress={saveDeliveryAddress}
        isRequesting={
          deliveryAddressIsSaving || deleteDeliveryAddressIsRequesting
        }
        deliveryAddressesFormInitialValues={deliveryAddressesFormInitialValues}
        updateDeliveryAddress={updateDeliveryAddress}
        deleteDeliveryAddress={deleteDeliveryAddress}
        deliverToThisAddress={deliverToThisAddress}
        clearDeliveryAddressErrorMessage={clearDeliveryAddressErrorMessage}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  deliveryAddressesFormIsVisible:
    state.deliveryAddressesReducer.deliveryAddressesFormIsVisible,
  deliveryAddressIsSaving:
    state.deliveryAddressesReducer.deliveryAddressIsSaving,
  deliveryAddressesFormInitialValues:
    state.deliveryAddressesReducer.deliveryAddressesFormInitialValues,
  deleteDeliveryAddressIsRequesting:
    state.deliveryAddressesReducer.deleteDeliveryAddressIsRequesting,
  isAuthenticated: state.authReducer.isAuthenticated,
  deliveryAddressErrors: state.deliveryAddressesReducer.deliveryAddressErrors,
});

export default withStyles(styles)(
  connect(mapStateToProps, { ...actions, setAuthenticationModalIsVisible })(
    DeliveryAddressesFormContainer
  )
);
