import React, {Fragment, useEffect} from 'react';
import styled from 'styled-components';
import {Callout, Body, Caption1} from '../../../../components/Typography';
import Select from '../../../../components/Select';
import LocationCustomFormatLabel from './LocationCustomLabel';
import formatAddressName from '../../../../utils/formatAddressName';
import OrderFutureSchedule from './OrderFutureSchedule';
import UnavailableMessage from './UnavailableMessage';
import ConfirmButton from './ConfirmButton';
import UnavailableProducts from './UnavailableProducts';
import {Link} from 'gatsby';
import {
  renderConfirmButtonText,
  isConfirmButtonDisabled,
  isLocationAcceptingOrders,
  isScheduleRadioDisabledCalculated,
} from '../utils/helper';
import appConfig from '../../../../../app-config';
import SkeletonLoading from './SkeletonLoading';
import UnavailableMessageWithButton from './UnavailableMessageWithButton';
import {EditIcon} from '../../../../components/Icons';
import {drop} from 'lodash';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const SelectTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropOffLocationWrapper = styled.div`
  font-family: ${props => props.theme.primaryFontFontFamily};
  display: flex;
  justify-content: space-between;
`;

const EditDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const DropOffTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const AddNewButton = styled(Link)`
  margin: 0;
  letter-spacing: 0px;
  text-decoration: none;
`;

const SelectTableButton = styled(Caption1)`
  cursor: pointer;
  color: ${props => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${props => props.theme.clickableTextColorHover};
  }
`;

const CalloutWrapper = styled(Callout)`
  &:hover {
    color: ${props => props.theme.clickableTextColorHover};
  }
`;

const DropOff = ({
  interDropOffDetails,
  updateInterDropOffDetails,
  availableTimes,
  specialHours,
  setDropOffLocationFormIsVisible,
  locations,
  isRequesting,
  requestingGetLocations,
  currentLocation,
  resetInterSelectedDateTime,
  resetIsTimeNoLongerValid,
  interOrderType,
  interSelectedDateTime,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  confirmDropOffDetails,
  isTimeNoLongerValid,
  selectedDateTime,
  orderPacingData,
  unavailableProducts,
  getFirstDropOffSelection,
  pickedDropOffLocation,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  handleConfirmDropOffButton,
  dropOffLocations,
  selectedDropOffLocation,
  getDropOffLocationsIsRequesting,
  cartErrorMessage,
  cartReducer,
  validateCartForLocation
}) => {
  const maxFutureOrderDate =
    interDropOffDetails &&
    interDropOffDetails.settings &&
    interDropOffDetails.settings.max_future_order_date;

  const orderEndTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);

  const isAsapRadioDisabled = !(
    interDropOffDetails &&
    specialHours &&
    isLocationAcceptingOrders(
      interDropOffDetails.accepting_orders,
      specialHours
    ) &&
    !interDropOffDetails.settings.online
  );

  const isScheduleRadioDisabled = !(
    availableTimes &&
    maxFutureOrderDate &&
    orderEndTime &&
    specialHours &&
    !isScheduleRadioDisabledCalculated(
      availableTimes,
      maxFutureOrderDate,
      orderEndTime,
      specialHours,
      interDropOffDetails.settings.online
    )
  );

  return (
    <Wrapper>
      <SelectTitleWrapper>
        <Body style={{fontWeight: 'bold'}}>Select Location:</Body>
        <AddNewButton to="/locations" onClick={() => {}}>
          <CalloutWrapper>View Map</CalloutWrapper>
        </AddNewButton>
      </SelectTitleWrapper>
      <div>
        <Select
          value={interDropOffDetails}
          onChange={location => {
            updateInterDropOffDetails(location, new Date().toString());
            resetInterSelectedDateTime();
            resetIsTimeNoLongerValid();
            getFirstDropOffSelection(location.id);
            deleteDropOffLocation();
          }}
          options={locations}
          getOptionLabel={location => location.name}
          getOptionValue={location => location}
          formatOptionLabel={location => (
            <LocationCustomFormatLabel
              location={location}
              coords={currentLocation}
              interDropOffDetails={interDropOffDetails}
              specialHours={specialHours}
            />
          )}
          placeholder="Select a location..."
          maxMenuHeight={200}
          isLocation={true}
          isDisabled={isRequesting || requestingGetLocations}
          coords={currentLocation}
          specialHours={specialHours}
          triggerOnChange
        />
      </div>

      {isRequesting ||
      getDropOffLocationsIsRequesting ||
      requestingGetLocations ? (
        <SkeletonLoading />
      ) : (
        interDropOffDetails &&
        dropOffLocations.length !== 0 && (
          <>
            {selectedDropOffLocation && selectedDropOffLocation.length !== 0 ? (
              <DropOffLocationWrapper>
                {selectedDropOffLocation.map((selection, i) => {
                  if (selectedDropOffLocation.length === i + 1)
                    return selection.value;
                  return selection.value + ', ';
                })}
                <EditDiv
                  onClick={() => {
                    setDropOffLocationFormIsVisible(true);
                    setIsEditDropOffLocation(true);
                  }}
                >
                  <EditIcon id="DropOff-EditLocation" />
                  <span id="DropOff-EditLocation" style={{cursor: 'pointer'}}>
                    Edit
                  </span>
                </EditDiv>
              </DropOffLocationWrapper>
            ) : (
              <DropOffTitleWrapper>
                <div style={{marginRight: 'auto'}}>
                  <SelectTableButton
                    onClick={() => {
                      setDropOffLocationFormIsVisible(true);
                      getFirstDropOffSelection(interDropOffDetails.id);
                    }}
                  >
                    + Select Table
                  </SelectTableButton>
                </div>
              </DropOffTitleWrapper>
            )}
          </>
        )
      )}

      {isRequesting || requestingGetLocations ? (
        <SkeletonLoading />
      ) : (
        <Fragment>
          {interDropOffDetails && availableTimes ? (
            !interDropOffDetails.is_order_ahead_enabled ? (
              <div>
                <UnavailableMessage title="Selected location does not accept order ahead" />
              </div>
            ) : interDropOffDetails && dropOffLocations.length == 0 ? (
              <div>
                <UnavailableMessageWithButton
                  onClick={() => confirmDropOffDetails(true)}
                  title="Selected location does not accept this order type"
                />
              </div>
            ) : (
              <>
                {(!interDropOffDetails.settings.online &&
                  (interDropOffDetails.accepting_orders, specialHours) &&
                  (!isScheduleRadioDisabled || !isAsapRadioDisabled)) ||
                  (!getDropOffLocationsIsRequesting &&
                    dropOffLocations.length == 0 && (
                      <div>
                        <UnavailableMessageWithButton
                          onClick={() => confirmDropOffDetails(true)}
                          title="Selected location is unable to accept orders at the moment"
                        />
                      </div>
                    ))}
                {selectedDropOffLocation.length !== 0 && (
                  <OrderFutureSchedule
                  is_future_order_enabled={
                    interDropOffDetails.settings.is_future_order_enabled
                  }
                  is_asap_order_enabled={
                    interDropOffDetails.settings.is_asap_order_enabled
                  }
                    availableTimes={availableTimes}
                    selectedLocation={interDropOffDetails}
                    updateInterDropOffDetails={updateInterDropOffDetails}
                    interOrderType={interOrderType}
                    interSelectedDateTime={interSelectedDateTime}
                    updateInterSelectedDateTime={updateInterSelectedDateTime}
                    getTimeIntervalsDate={getTimeIntervalsDate}
                    confirmDropOffDetails={confirmDropOffDetails}
                    isTimeNoLongerValid={isTimeNoLongerValid}
                    resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
                    selectedDateTime={selectedDateTime}
                    interDropOffDetails={interDropOffDetails}
                    maxFutureOrderDate={
                      interDropOffDetails
                        ? interDropOffDetails.settings.max_future_order_date
                        : null
                    }
                    isLocationOrDelivBusinesssAcceptingOrders={
                      isLocationAcceptingOrders(
                        interDropOffDetails.accepting_orders,
                        specialHours
                      )
                      // interDropOffDetails.accepting_orders
                    }
                    specialHours={specialHours}
                    orderPacingData={orderPacingData}
                    isOnline={interDropOffDetails.settings.online}
                    cartErrorMessage={cartErrorMessage}
                    cartReducer={cartReducer}
                  />
                )}
              </>
            )
          ) : null}

          {unavailableProducts.length ? (
            <UnavailableProducts unavailableProducts={unavailableProducts} />
          ) : null}

          <ConfirmButton
            text={renderConfirmButtonText(
              interDropOffDetails,
              appConfig.isFutureOrderDropOff
            )}
            onClick={() => {
              handleConfirmDropOffButton();
            }}
            disabled={
              !interDropOffDetails ||
              isConfirmButtonDisabled(
                interSelectedDateTime,
                appConfig.isFutureOrderDropOff,
                interDropOffDetails
              ) ||
              (isAsapRadioDisabled && isScheduleRadioDisabled) ||
              !interDropOffDetails?.settings?.online ||
              pickedDropOffLocation.length === 0
            }
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default DropOff;
