export default flatProduct => {
  const {
    options,
    optionsById,
    subOptionsById,
    itemsById,
    subItemsById,
  } = flatProduct;

  const mapOption = option => {
    const optionToReturn = {
      ...option,
      items: option.items
        .map(itemId => {
          const item = itemsById[itemId];
          if (item.sub_items) {
            const itemToReturn = {
              ...item,
              sub_items: item.sub_items
                .map(subItemId => {
                  const subItem = subItemsById[subItemId];
                  if (subItem.quantity) {
                    return subItem;
                  }
                })
                .filter(subItem => subItem),
            };
            if (itemToReturn.sub_items.length) {
              return itemToReturn;
            }
          } else if (item.quantity) {
            return item;
          }
        })
        .filter(item => item),
    };
    if (optionToReturn.items.length) {
      return optionToReturn;
    }
  };

  return (
    options &&
    options
      .map(optionId => {
        const option = optionsById[optionId];
        if (option.sub_options.length) {
          const optionToReturn = {
            ...option,
            sub_options: option.sub_options
              .map(subOptionId => mapOption(subOptionsById[subOptionId]))
              .filter(subOption => subOption),
          };
          if (optionToReturn.sub_options.length) {
            return optionToReturn;
          }
        } else {
          return mapOption(option);
        }
      })
      .filter(option => option)
  );
};
