const formatAddressName = deliveryAddress =>
  `${deliveryAddress.type ? deliveryAddress.type + ' -' : ''} ${
    deliveryAddress.line2 ? 'Apt/Suite ' + deliveryAddress.line2 + ',' : ''
  } ${deliveryAddress.line1}, ${
    deliveryAddress.line3 ? deliveryAddress.line3 + ',' : ''
  } ${deliveryAddress.city}, ${deliveryAddress.state} ${deliveryAddress.zip}, ${
    deliveryAddress.country
  }`;

export default formatAddressName;
