import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Headline, Body } from '../../../components/Typography';
import ConfirmButton from '../../OrderSetting/OrderSettingModal/components/ConfirmButton';
import useOpeningHours from '../customHooks/useOpeningHours';
import {
  confirmPickUpDetails,
  getBusinessSpecialHours,
  setChildLocation,
  confirmDropOffDetails,
  confirmDeliveryDetails,
} from '../../OrderSetting/orderSettingActions';
import {
  DROPOFF,
  PICKUP,
  DELIVERY,
} from '../../OrderSetting/orderSettingTypes';
import {
  updateCartWithUnavailbleProducts,
  emptyCart,
} from '../../Cart/cartActions';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  padding: 20px 18px 20px 13px;
  border-radius: 9.6px;
  box-shadow: 0 5px 16px 0 rgba(20, 19, 33, 0.12);
  background-color: #fff;
  margin-bottom: 18px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'initial')};

  :hover {
    box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageDiv = styled.img`
  width: 100px;
  height: 100px;
`;

const InformationDiv = styled.div``;

const ButtonDiv = styled.div``;

const LocationAddress = styled(Body)`
  margin-top: 9px;
`;

const HoverButton = styled(ConfirmButton)`
  :hover {
    content: 'Confirm';
  }
`;

const ChildCard = ({
  interOrderType,
  childLocation,
  interPickUpDetails,
  interDeliveryDetails,
  interDropOffDetails,
  unavailableProducts,
  isAuthenticated,
  isOrderButtonDisabled,
}) => {
  const dispatch = useDispatch();
  const { openingHour } = useOpeningHours(childLocation);

  const [buttonLabel, setButtonLabel] = useState(
    !childLocation.accepting_orders || !childLocation.is_order_ahead_enabled
      ? 'See Menu'
      : 'Order Here'
  );

  useEffect(() => {
    setButtonLabel(
      !childLocation.accepting_orders || !childLocation.is_order_ahead_enabled
        ? 'See Menu'
        : 'Order Here'
    );
  }, [
    childLocation,
    interPickUpDetails,
    interDropOffDetails,
    interDeliveryDetails,
  ]);

  const onConfirmStore = () => {
    dispatch(setChildLocation(childLocation));

    if (!isAuthenticated) {
      dispatch(emptyCart());
    } else {
      if (unavailableProducts.length) {
        const unavailableProductsModified = unavailableProducts.map(
          (product) => product.product_id
        );
        dispatch(updateCartWithUnavailbleProducts(unavailableProductsModified));
      }
    }

    if (interOrderType === PICKUP) {
      dispatch(confirmPickUpDetails(true));
    } else if (interOrderType === DROPOFF) {
      dispatch(confirmDropOffDetails(true));
    } else if (interOrderType === DELIVERY) {
      dispatch(confirmDeliveryDetails(true));
    }
  };

  return (
    <div>
      <Wrapper>
        <ImageDiv src={childLocation.image_url}></ImageDiv>
        <InformationDiv>
          <Headline>{childLocation.name}</Headline>
          <LocationAddress>
            {childLocation.line1}, {childLocation.city}
          </LocationAddress>
          {(!childLocation.accepting_orders ||
            !childLocation.is_order_ahead_enabled) && <Body>💤 Closed</Body>}
          {openingHour &&
            childLocation.is_order_ahead_enabled &&
            childLocation.accepting_orders && <Body>{openingHour}</Body>}
        </InformationDiv>
        <ButtonDiv
          onMouseOver={() =>
            buttonLabel !== 'See Menu' ? setButtonLabel('Confirm') : null
          }
          onMouseLeave={() =>
            setButtonLabel(
              !childLocation.accepting_orders ||
                !childLocation.is_order_ahead_enabled
                ? 'See Menu'
                : 'Order Here'
            )
          }
        >
          <HoverButton
            text={buttonLabel}
            style={{ borderRadius: '18px', minWidth: '120px' }}
            onClick={() => {
              onConfirmStore();
              window.scrollTo(0, 0);
            }}
            disabled={isOrderButtonDisabled}
          />
        </ButtonDiv>
      </Wrapper>
    </div>
  );
};

export default ChildCard;
