import React, {Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {Body, Caption1} from '../../../components/Typography';
import OrderFutureSchedule from './OrderFutureSchedule';
import Select from '../../../components/Select';
import {updateInterDeliveryDetails} from '../../OrderSetting/orderSettingActions';
import {setDeliveryAddressesFormIsVisible} from '../../DeliveryAddresses/deliveryAddressesActions';
import formatAddressName from '../../../utils/formatAddressName';
import UnavailableMessage from '../../OrderSetting/OrderSettingModal/components/UnavailableMessage';
import UnavailableProducts from '../../OrderSetting/OrderSettingModal/components/UnavailableProducts';
import {isScheduleRadioDisabledCalculated} from '../../OrderSetting/OrderSettingModal/utils/helper';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const SelectTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const AddNewButton = styled(Caption1)`
  cursor: pointer;
  color: ${props => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${props => props.theme.clickableTextColorHover};
  }
`;

function Delivery({
  interDeliveryDetails,
  availableTimes,
  specialHours,
  isRequesting,
  unavailableProducts,
  interSelectedDateTime,
  isAuthenticated,
  deliveryBusiness,
}) {
  const dispatch = useDispatch();
  const {deliveryAddressesList} = useSelector(
    state => state.deliveryAddressesReducer
  );

  const deliverBusiness = interDeliveryDetails
    ? interDeliveryDetails.deliveryBusiness
    : null;

  const maxFutureOrderDate =
    interDeliveryDetails &&
    interDeliveryDetails.settings &&
    interDeliveryDetails.settings.max_future_order_date;

  const orderEndTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);

  const isAsapRadioDisabled = !(
    interDeliveryDetails &&
    specialHours &&
    deliveryBusiness &&
    deliveryBusiness.accepting_orders &&
    !interDeliveryDetails.settings.online
  );

  const isScheduleRadioDisabled = !(
    availableTimes &&
    maxFutureOrderDate &&
    orderEndTime &&
    specialHours &&
    !isScheduleRadioDisabledCalculated(
      availableTimes,
      maxFutureOrderDate,
      orderEndTime,
      specialHours,
      interDeliveryDetails.settings.online
    )
  );

  return (
    <Wrapper>
      <SelectTitleWrapper>
        <div style={{marginRight: 'auto'}}>
          <AddNewButton
            onClick={() => {
              dispatch(setDeliveryAddressesFormIsVisible(true));
            }}
          >
            + Add New Address
          </AddNewButton>
        </div>
      </SelectTitleWrapper>

      {isAuthenticated && (
        <div>
          <Select
            value={interDeliveryDetails}
            options={deliveryAddressesList}
            getOptionLabel={deliveryAddress =>
              formatAddressName(deliveryAddress)
            }
            getOptionValue={deliveryAddress => deliveryAddress}
            onChange={deliveryAddress => {
              dispatch(updateInterDeliveryDetails(deliveryAddress));
            }}
            isDisabled={isRequesting}
          />
        </div>
      )}

      {isRequesting ? (
        <div />
      ) : (
        <Fragment>
          {interDeliveryDetails && !interDeliveryDetails.isDeliveryValid ? (
            <UnavailableMessage
              title={
                interDeliveryDetails.errorMessage ||
                'We are unable to deliver to this address'
              }
            />
          ) : interDeliveryDetails && availableTimes && deliveryBusiness ? (
            !deliveryBusiness.is_order_ahead_enabled ? (
              <UnavailableMessage title="We are unable to deliver to this address at this time" />
            ) : (
              <>
                {!interDeliveryDetails.settings.online &&
                  deliveryBusiness.accepting_orders &&
                  (!isScheduleRadioDisabled || !isAsapRadioDisabled) && (
                    <div>
                      <UnavailableMessage title="Your nearest location is unable to accept orders at the moment" />
                    </div>
                  )}
                <OrderFutureSchedule />
              </>
            )
          ) : null}

          {unavailableProducts.length ? (
            <UnavailableProducts unavailableProducts={unavailableProducts} />
          ) : null}
        </Fragment>
      )}
    </Wrapper>
  );
}

export default Delivery;
