import {CREATE_ALERT, CLEAR_ALERT} from './alertTypes';

export function createAlert(alert) {
  return {
    type: CREATE_ALERT,
    alert,
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT,
  };
}

export function createServerErrorAlert(dispatch, error) {
  return dispatch(
    createAlert({
      type: 'error',
      message: error.response.data.errors.message,
    })
  );
}
