import React, {useEffect} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import 'react-widgets/dist/css/react-widgets.css';
import {withStyles} from '@material-ui/core/styles';
import {PICKUP, ASAP} from '../../OrderSetting/orderSettingTypes';
import Select from '../../../components/Select';
import hexToRgbA from '../../../utils/hexToRgbA';
import UnavailableMessage from '../../OrderSetting/OrderSettingModal/components/UnavailableMessage';

const DateTimeSelectDiv = styled.div`
  margin-top: 10px;
  display: ${props => (props.isGrid ? 'grid' : 'block')};
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainDiv = styled.div``;

const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily.main,
  },
  radio: {
    color: '#d8d8d8',
    '&$checked': {
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: hexToRgbA(theme.palette.primary.main, 0.1),
      },
    },
    '&:hover': {
      backgroundColor: hexToRgbA(theme.palette.primary.main, 0.1),
    },
  },
  checked: {},
});

const DateTimeSelect = ({
  interSelectedDateTime,
  selectedDateTime,
  maxFutureOrderDate,
  onDateChange,
  timesOptions,
  onTimeChange,
  datesOptions,
  noTimesAvailable,
  isAsapDisabled,
  isScheduleDisabled,
  handleSelectChange,
  isTimeNoLongerValid,
  location,
  orderMethodDetails,
}) => {
  let today = moment();

  const isDateShowing =
    moment(maxFutureOrderDate).format('ddd MMM DD') ==
    moment().format('ddd MMM DD')
      ? false
      : true;

  const timeOptions = [
    {
      name: 'As soon as possible',
      value: ASAP,
      disabled: isAsapDisabled,
    },
    {
      name: 'Schedule a time',
      value: 'schedule',
      disabled: isScheduleDisabled,
    },
  ];

  useEffect(() => {
    handleSelectChange(ASAP, timeOptions);
  }, [orderMethodDetails]);

  return (
    <MainDiv>
      <Select
        value={
          !interSelectedDateTime.radioValue.value
            ? ASAP
            : interSelectedDateTime.radioValue
        }
        onChange={e => {
          handleSelectChange(e, timeOptions);
        }}
        options={timeOptions}
        getOptionLabel={radioValue => radioValue.name}
        getOptionValue={radioValue => radioValue.value}
        placeholder="Select Option"
        isOptionDisabled={option => option.disabled}
        isSearchable={false}
        menuPlacement="top"
        maxMenuHeight={150}
      />

      {interSelectedDateTime.radioValue.value === 'schedule' &&
        !isScheduleDisabled && (
          <DateTimeSelectDiv
            isGrid={!moment(maxFutureOrderDate).isSame(today, 'day')}
          >
            {moment(maxFutureOrderDate).format('ddd MMM DD') ==
            moment().format('ddd MMM DD') ? null : (
              <Select
                value={interSelectedDateTime.dateTime.date}
                onChange={onDateChange}
                options={datesOptions}
                getOptionLabel={date => date.name}
                getOptionValue={date => date.value}
                placeholder="Select Date"
                isSearchable={false}
                menuPlacement="top"
                maxMenuHeight={150}
                isDate
              />
            )}
            <Select
              value={
                !!interSelectedDateTime.dateTime.time.name
                  ? interSelectedDateTime.dateTime.time
                  : null
              }
              onChange={onTimeChange}
              options={timesOptions}
              getOptionLabel={time => time.name}
              getOptionValue={time => time.value}
              placeholder="Select Time"
              isSearchable={false}
              noOptionsMessage={() =>
                'There are no order ahead hours for the selected date or the restaurant is closed'
              }
              materialUiNoOptionsMessage={
                noTimesAvailable ? null : 'No order ahead hours'
              }
              menuPlacement="top"
              maxMenuHeight={150}
              isTimeNoLongerValid={isTimeNoLongerValid}
              isFromDateTimeSelect={true}
            />
          </DateTimeSelectDiv>
        )}

      {isTimeNoLongerValid && selectedDateTime.radioValue && (
        <UnavailableMessage
          marginTop="15px"
          title="Selected time is no longer valid. Please select a different time"
        />
      )}
    </MainDiv>
  );
};

export default withStyles(styles)(DateTimeSelect);
