const updateOptionError = (option, flatProduct) => {
  const {subOptionsById, itemsById, subItemsById} = flatProduct;
  let selectedItemQuantity = 0;

  const iterateItems = items => {
    items.forEach(itemId => {
      const item = itemsById[itemId];
      if (item.sub_items && item.sub_items.length) {
        item.sub_items.forEach(subItemId => {
          const subItem = subItemsById[subItemId];
          selectedItemQuantity += subItem.quantity;
        });
      } else {
        selectedItemQuantity += item.quantity;
      }
    });
  };

  if (option.sub_options && option.sub_options.length) {
    option.sub_options.forEach(subOptionId => {
      const subOption = subOptionsById[subOptionId];
      iterateItems(subOption.items);
    });
  } else {
    iterateItems(option.items);
  }
  return {...option, error: selectedItemQuantity < option.minimum_pick};
};

export default updateOptionError;
