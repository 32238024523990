import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from './menuActions';
import { getCombos } from '../../Combo/comboActions';
import appConfigFile from '../../../../app-config';
import {
  PICKUP,
  DROPOFF,
  DELIVERY,
} from '../../OrderSetting/orderSettingTypes';

const GetMenuContainer = ({
  getMenu,
  getCombos,
  requestingGetMenu,
  isRequestingGetComboList,
  orderSettingReducer,
  locations,
  currentLocation,
}) => {
  const {
    interOrderType,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interSelectedDateTime,
    selectedLocation,
  } = orderSettingReducer;

  const nearestLocation =
    currentLocation &&
    locations &&
    locations.find(
      (location) =>
        location.accepting_orders &&
        location.is_order_ahead_enabled &&
        location.accepted_order_types.includes(PICKUP)
    );

  const selectedPickUpLocation = interPickUpDetails;

  useEffect(() => {
    if (
      interOrderType === PICKUP &&
      interPickUpDetails &&
      interPickUpDetails.id
    ) {
      getMenu(interPickUpDetails.id, interOrderType);
    } else if (interOrderType === DROPOFF && interDropOffDetails.id) {
      getMenu(interDropOffDetails.id, interOrderType);
    } else if (
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness &&
      interDeliveryDetails.deliveryBusiness.id
    ) {
      getMenu(interDeliveryDetails.deliveryBusiness.id, interOrderType);
    } else if (selectedLocation && interOrderType !== DELIVERY) {
      getMenu(selectedLocation.id, interOrderType);
    } else if (
      interOrderType === DELIVERY &&
      selectedLocation &&
      selectedLocation.deliveryBusiness
    ) {
      getMenu(selectedLocation.deliveryBusiness.id, interOrderType);
    } else if (
      interOrderType === DELIVERY &&
      selectedLocation &&
      selectedLocation.id
    ) {
      getMenu(selectedLocation.id, interOrderType);
    }
    if (
      appConfigFile.isComboEnabled &&
      !isRequestingGetComboList &&
      selectedPickUpLocation &&
      interOrderType === PICKUP
    ) {
      getCombos(interPickUpDetails.id);
    } else if (
      appConfigFile.isComboEnabled &&
      !isRequestingGetComboList &&
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness &&
      interDeliveryDetails.deliveryBusiness.id &&
      interOrderType === DELIVERY
    ) {
      getCombos(interDeliveryDetails.deliveryBusiness.id, interOrderType);
    } else if (
      appConfigFile.isComboEnabled &&
      !isRequestingGetComboList &&
      interDropOffDetails &&
      interOrderType === DROPOFF
    ) {
      getCombos(interDropOffDetails.id);
    }
  }, [
    interOrderType,
    selectedPickUpLocation,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  requestingGetMenu: state.menuReducer.requestingGetMenu,
  interOrderType: state.menuReducer.interOrderType,
  isRequestingGetComboList: state.comboReducer.isRequestingGetComboList,
  currentLocation: state.locationsReducer.currentLocation,
  locations: state.locationsReducer.locations,
  orderSettingReducer: state.orderSettingReducer,
});

export default connect(mapStateToProps, { ...actions, getCombos })(
  GetMenuContainer
);
