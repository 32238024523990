import React, {memo} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${props => props.theme.headerHoverColor};
    transition: all 0.25s ease;
  }
  @media (max-width: 786px) {
    width: 48px;
    height: 56px;
  }
`;

const Icon = styled.i`
  position: relative;
  font-size: 24px;
  cursor: pointer;
  color: inherit;
`;

const NumberInCart = styled.div`
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 500;
`;

const DynamicCartIcon = ({numberOfProductsInCart, navigateToCart}) => (
  <Wrapper id="DynamicCartIcon-Navigate" onClick={navigateToCart}>
    <Icon className="fal fa-shopping-bag">
      <NumberInCart id="DynamicCartIcon-NumberInCart">
        {numberOfProductsInCart}
      </NumberInCart>
    </Icon>
  </Wrapper>
);

export default memo(DynamicCartIcon);
