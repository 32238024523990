import React, { useEffect, useState } from 'react';
import logos from '../../assets/logos';
import { connect } from 'react-redux';
import { updateOrderSettingModalIsVisible } from '../../modules/OrderSetting/orderSettingActions';
import { Caption2 } from '../../components/Typography';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { AppConfigContext } from '../Layout';
import { isOrderTimeValid } from '../../modules/CartMenuSidebar/utils/helper';
import { PICKUP } from '../../modules/OrderSetting/orderSettingTypes';
import { reviewOrder } from '../../modules/CartMenuSidebar/utils/reviewOrderValidation';

const Wrapper = styled.div`
  min-height: 150px;
  background-color: ${(props) => props.theme.footerBackgroundColor};
  position: relative;
  @media (max-width: 785px) {
    display: none;
  }
  font-family: ${(props) => props.theme.primaryFontFontFamily};
`;

const MainWrapper = styled.div`
  // width: 100%;
  margin: 0 5%;

  min-height: 150px;
  display: grid;
  grid-template-rows: 3fr 2fr;
  padding: 0 16px;
  @media (max-width: 786px) {
    grid-template-rows: auto auto;
    padding: 0 12px;
    margin: 0;
  }
`;

const Logo = styled.img`
  height: 40px;
  @media (max-width: 786px) {
    order: -1;
  }
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  @media (max-width: 786px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 24px;
    padding: 16px 0;
  }
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 68px;
  @media (max-width: 786px) {
    grid-auto-flow: row;
    justify-content: center;
    text-align: center;
    grid-gap: 16px;
  }
`;

const FooterLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  color: ${(props) => props.theme.footerFontColor};

  &:hover {
    color: ${(props) => props.theme.headerHoverColor};
    transition: all 0.25s ease;
  }
`;

const BottomDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 5fr auto;
  grid-gap: 16px;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.footerHRLineColor};
  @media (max-width: 786px) {
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
    padding: 24px 0;
  }
`;

const FooterCaption2 = styled(Caption2)`
  color: ${(props) => props.theme.footerFontColor};
`;

const BottomPriceDiv = styled.div`
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  text-transform: ${(props) => props.textTransform || 'unset'};
  color: ${(props) => props.theme.footerFontColor};
`;

const BottomDivLink = styled.a`
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  color: ${(props) => props.color || props.theme.bodyColor};
  text-transform: ${(props) => props.textTransform || 'unset'};
  margin: 0;
  letter-spacing: 0px;
  text-decoration: none;
  color: ${(props) => props.theme.footerFontColor};
`;

const LineDivider = styled.hr`
  margin-bottom: 0;
  height: 1px;
  background-color: ${(props) => props.theme.footerHRLineColor};
  border: none;
  @media (max-width: 785px) {
    display: none;
  }
`;

const Footer = ({
  isAuthenticated,
  amountEditOrderConfig,
  updateOrderSettingModalIsVisible,
  selectedPickUpLocation,
  orderSettingReducer,
}) => {
  const {
    availableTimes,
    deliveryDetails,
    selectedDateTime,
    orderType,
    deliveryBusiness,
    dateTimeToShow,
    specialHours,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    dropOffDetails,
  } = orderSettingReducer;
  const deepLink = localStorage.getItem('deep-link-url');
  return (
    <AppConfigContext.Consumer>
      {(appConfig) => (
        <div>
          <LineDivider />
          <Wrapper>
            <MainWrapper>
              <TopDiv>
                <LinksWrapper>
                  <FooterLink
                    id="footer_home"
                    onClick={() => {
                      if (deepLink) {
                        navigate('/' + deepLink);
                      } else {
                        navigate('/');
                      }
                    }}
                  >
                    Home
                  </FooterLink>
                  <FooterLink
                    id="footer_cart"
                    onClick={() =>
                      reviewOrder({
                        amountEditOrderConfig,
                        orderType,
                        interPickUpDetails,
                        deliveryDetails,
                        deliveryBusiness,
                        selectedDateTime,
                        availableTimes,
                        dateTimeToShow,
                        updateOrderSettingModalIsVisible,
                        specialHours,
                        interPickUpDetails,
                        interDeliveryDetails,
                        dropOffDetails,
                        interDropOffDetails,
                      })
                    }
                  >
                    Cart
                  </FooterLink>
                  {isAuthenticated && (
                    <FooterLink
                      id="footer_my-account"
                      onClick={() => navigate('/profile-settings')}
                    >
                      My Account
                    </FooterLink>
                  )}
                  {/* <FooterLink onClick={() => navigate("/")}>Contact</FooterLink> */}
                </LinksWrapper>
                <Logo src={appConfig.styleConfig.logoUrl} />
              </TopDiv>
              <BottomDiv>
                <BottomDivLink
                  href="https://www.smoothcommerce.tech/user-terms/terms.html"
                  target="_blank"
                  id="footer_terms-of-service"
                >
                  Terms of Service
                </BottomDivLink>
                <BottomDivLink
                  href="https://www.smoothcommerce.tech/privacy.html"
                  target="_blank"
                  id="footer_privacy-policy"
                >
                  Privacy Policy
                </BottomDivLink>
                <FooterCaption2 id="footer_copyright">
                  © {new Date().getFullYear()} Smooth Commerce
                </FooterCaption2>
                {selectedPickUpLocation && (
                  <BottomPriceDiv id="footer_currency">
                    All prices are in {selectedPickUpLocation.currency}
                  </BottomPriceDiv>
                )}
              </BottomDiv>
            </MainWrapper>
          </Wrapper>
        </div>
      )}
    </AppConfigContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  amountEditOrderConfig: state.orderSettingReducer.amountEditOrderConfig,
  orderingClosedInAllLocations:
    state.locationsReducer.orderingClosedInAllLocations,
  selectedPickUpLocation: state.orderSettingReducer.interPickUpDetails,
  orderSettingReducer: state.orderSettingReducer,
});

export default connect(mapStateToProps, {
  updateOrderSettingModalIsVisible,
})(Footer);
