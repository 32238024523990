export const GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE';

export const NAVIGATED_TO_OFFERS_TAB = 'NAVIGATED_TO_OFFERS_TAB';
export const NAVIGATED_TO_HOW_IT_WORKS_TAB = 'NAVIGATED_TO_HOW_IT_WORKS_TAB';
export const ACTIVATE_OFFER_SUCCESS = 'ACTIVATE_OFFER_SUCCESS';
export const DEACTIVATE_OFFER_SUCCESS = 'DEACTIVATE_OFFER_SUCCESS';

export const GET_OFFERS_FOR_REVIEW_SCREEN_REQUEST =
  'GET_OFFERS_FOR_REVIEW_SCREEN_REQUEST';
export const GET_OFFERS_FOR_REVIEW_SCREEN_SUCCESS =
  'GET_OFFERS_FOR_REVIEW_SCREEN_SUCCESS';
export const GET_OFFERS_FOR_REVIEW_SCREEN_FAILURE =
  'GET_OFFERS_FOR_REVIEW_SCREEN_FAILURE';
