import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import appConfig from "../../../app-config";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const StyledPaper = styled(Paper)`
  padding: 0px 5px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-items: center;
  width: 100%;
  height: 40px;

  &:hover {
    outline: 1px solid #000;
    outline-offset: -1px;
  }

  &:focus-within {
    outline: 2px solid ${appConfig.styleConfig.primaryButtonBackgroundColor};
    outline-offset: -1px;
  }
`;

const AddressSelect = styled(Select)`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  outline-color: transparent;
  cursor: pointer;
`;

function SelectField({
  leftIcon,
  rightIcon,
  deleteIcon,
  value,
  onDelete,
  options,
  onNewAddressClick,
  onChange,
  ...props
}) {
  const formatAddressName = (deliveryAddress) =>
    deliveryAddress
      ? `${deliveryAddress.type ? deliveryAddress.type + " -" : ""} ${
          deliveryAddress.line2
            ? "Apt/Suite " + deliveryAddress.line2 + ","
            : ""
        } ${deliveryAddress.line1}, ${
          deliveryAddress.line3 ? deliveryAddress.line3 + "," : ""
        } ${deliveryAddress.city}, ${deliveryAddress.state} ${
          deliveryAddress.zip
        }, ${deliveryAddress.country}`
      : null;

  return (
    <StyledPaper component="form" elevation={0}>
      {leftIcon ? (
        <>
          <IconButton style={{ padding: "10px" }} aria-label="menu">
            {leftIcon}
          </IconButton>
          {/* <Divider style={{ height: 28, margin: 0.5 }} orientation="vertical" /> */}
        </>
      ) : null}
      <Select
        style={{
          border: "none",
          outline: "none",
          height: "40px",
          width: "100%",
          backgroundColor: "transparent",
        }}
        value={value?.name}
        onChange={(ev) => {
          if (onChange) onChange(ev.target.value);
        }}
      >
        <MenuItem value="NewAddress">
          <ListItemText
            style={{
              fontWeight: 600,
              justifyContent: "center",
              alignItems: "center",
            }}
            primary="+ Add New Address"
            onClick={() => {
              if (onNewAddressClick) onNewAddressClick();
            }}
          />
        </MenuItem>
        {options && options.length > 0
          ? options.map((option, index) => (
              <MenuItem
                key={`${value}-${index}`}
                value={option}
                name={formatAddressName(option)}
              >
                <ListItemIcon>
                  <i className="fas fa-home" style={{ fontSize: "16px" }} />
                </ListItemIcon>
                <ListItemText primary={formatAddressName(option)} />
              </MenuItem>
            ))
          : null}
      </Select>
      {/* <InputBase label="Address" {...props} /> */}
      {value && deleteIcon ? (
        <IconButton
          type="button"
          style={{ p: "10px" }}
          aria-label="remove address"
          onClick={() => {
            if (onDelete) onDelete();
          }}
        >
          <i className="fas fa-times" style={{ fontSize: "16px" }} />
        </IconButton>
      ) : null}
      {rightIcon ? (
        <>
          <Divider style={{ height: 28, margin: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            style={{ padding: "10px" }}
            aria-label="directions"
          >
            {rightIcon}
          </IconButton>
        </>
      ) : null}
    </StyledPaper>
  );
}

export default SelectField;
