import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import * as actions from './authActions';
import styled from 'styled-components';
import ConfirmCloseModal from './ConfirmCloseModal/ConfirmCloseModal';
import CategoriesBar from '../Menu/components/Menu__a/components/CategoriesBar';
import LogInForm from './LogInForm';
import ForgotPassword from './ForgetPassword/ForgotPassword';
import Verification from './Verification/Verification';
import RegistrationContainer from '../Registration/RegistrationContainer';
import CreateNewPassword from './CreateNewPassword';
import { Title1 } from '../../components/Typography';
import {
  setShowGuestPersonalDetailsPage,
  updateCartScreen,
} from '../Cart/cartActions';
import StickyErrorMessageBox from '../../components/ErrorMessage/StickyErrorMessageBox';
import { clearRegisterationErrorMessage } from '../Registration/registrationActions';

const styles = () => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    borderRadius: '10px',
    margin: 0,
    maxWidth: '100%',
    // maxHeight: '95%',
  },
});

const Wrapper = styled.div`
  padding-top: 15px;
  width: 440px;
  background: white;
  margin: auto;
  @media (max-width: 786px) {
    padding: 0px 20px;
    width: 100%;
    margin: 10px 0 0 0;
  }
  h1 {
    margin-bottom: 20px;
  }
`;
const TitleWrapper = styled.div`
  position: relative;
  width: 440px;
  background: yello;
  margin: auto;
  margin: 20px 0;
  padding: 10px 24px;
  @media (max-width: 786px) {
    margin: 10px 0;
    width: 100%;
  }
`;
const Cancel = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.54);
  position: absolute;
  right: 20px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
  }
`;

const CancelWrapper = styled.div``;
const ScreenWrapper = styled.div`
  padding: 24px;
`;

const FormWrapper = styled.div`
  padding: 24px 15px;
`;

const LoginTitleWrapper = styled(Title1)`
  margin: 0;
  letter-spacing: 0.04px;
  font-weight: 600;
  width: fit-content;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
  }
`;
const ErrorMesageWrapper = styled.div`
  padding: 0;
  width: 440px;
  margin: 0;
`;
const AuthContainer = ({
  authReducer,
  authenticationModalIsVisible,
  hideAuthenticationModal,
  resetCheckedEmail,
  classes,
  updatePhoneNumber,
  resetAuthReducer,
  postForgotPassword,
  resetForgotPassword,
  verificationCodeConfirmation,
  login,
  resetPassword,
  setShowGuestPersonalDetailsPage,
  updateCartScreen,
  setAuthenticationModalIsVisible,
  cartScreen,
  clearAuthErrorMessage,
  registrationError,
  clearRegisterationErrorMessage,
}) => {
  const {
    forgotPasswordSuccess,
    isVerificationCodeMatched,
    isPasswordResetSuccessfull,
    loginFailureError,
    appConfig,
    authErrors,
    requestingLogin
  } = authReducer;
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const updateErrors = useCallback(() => {
    window.scrollTo(0, 0);
    if (authErrors && Object.keys(authErrors).length > 0) {
      setErrors(authErrors);
    } else if (registrationError && Object.keys(registrationError).length > 0) {
      setErrors(registrationError);
    } else {
      setErrors({});
    }
  }, [authErrors, registrationError]);
  useEffect(() => {
    updateErrors();
  }, [updateErrors]);
  useEffect(() => {
    clearAuthErrorMessage();
    clearRegisterationErrorMessage();
    return function cleanup() {
      resetAuthReducer();
      resetCheckedEmail();
    };
  }, []);
  useEffect(() => {}, [selectedAuthScreen]);

  const [confirmCloseModalIsVisible, setConfirmCloseModalIsVisible] = useState(
    false
  );
  useEffect(() => {
    if (forgotPasswordSuccess === true) {
      const id = 3; //id = 3 represents VerificationCodeScren
      setSelectedAuthScreen(authScreens[id]);
    }
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    if (isVerificationCodeMatched === true) {
      const id = 4; //id = 4 represents createNewPasswordScreen
      setSelectedAuthScreen(authScreens[id]);
    }
  }, [isVerificationCodeMatched]);
  useEffect(() => {
    if (isPasswordResetSuccessfull === true) {
      const id = 1; //id = 1 represents loginOrSignup
      setSelectedAuthScreen(authScreens[id]);
    }
  }, [isPasswordResetSuccessfull]);

  //folowing array is for handling the tabs in the login screen.
  const authTabCategories = [
    { category_id: 1, name: 'Login' },
    { category_id: 2, name: 'Sign Up' },
  ];
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    authTabCategories[0].category_id
  );

  //folowing array is to maintain the authentication modal screens.
  const authScreens = {
    1: 'loginOrSignup',
    2: 'forgotPassword',
    3: 'VerificationCode',
    4: 'createNewPasswordScreen',
  };
  const [selectedAuthScreen, setSelectedAuthScreen] = useState(authScreens[1]);

  const [emailOrPhoneField, setEmailOrPhoneField] = useState('');
  const handleChangeTab = (id) => {
    setSelectedCategoryId(id);
  };
  const handleChangeScreen = (id) => {
    clearAuthErrorMessage();
    clearRegisterationErrorMessage();
    setErrors({});
    setSelectedAuthScreen(authScreens[id]);
  };
  const [emailOrPhoneObject, setEmailOrPhoneObject] = useState({});
  const handleConfirmModalConfirmClick = () => {
    setConfirmCloseModalIsVisible(false);
    hideAuthenticationModal();
  };

  const handleConfirmModalCancelClick = () => {
    setConfirmCloseModalIsVisible(false);
    setSelectedAuthScreen(authScreens[1]);
  };
  useEffect(() => {
    setErrors({});
    clearAuthErrorMessage();
    clearRegisterationErrorMessage();
  }, [selectedCategoryId]);
  return (
    <Dialog
      id="Auth-AuthContainer-Dialog"
      aria-labelledby="payment-methods"
      aria-describedby="payment-methods"
      open={authenticationModalIsVisible}
      onBackdropClick={() => {
        setConfirmCloseModalIsVisible(true);
        setSelectedAuthScreen(authScreens[1]);
      }}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{ scrollPaper: classes.scrollPaper }}
      PaperProps={{ classes: { root: classes.paper } }}
      scroll="body"
    >
      <div>
        {selectedAuthScreen === 'loginOrSignup' && (
          <>
            <TitleWrapper>
              <LoginTitleWrapper>Get Started</LoginTitleWrapper>
              <CancelWrapper>
                <Cancel
                  onClick={() => {
                    hideAuthenticationModal();
                    setSelectedAuthScreen(authScreens[1]);
                  }}
                >
                  <i className="fal fa-times" />
                </Cancel>
              </CancelWrapper>
            </TitleWrapper>
            <CategoriesBar
              id="Auth-Tab"
              categories={authTabCategories}
              selectedCategoryId={selectedCategoryId}
              changeCategory={handleChangeTab}
              customStyle={{
                gridAutoColumns: 'minmax(0, 1fr)',
                gridGap: 0,
                padding: 0,
                borderBottom: '1px solid #d8d8d8',
                height: 'auto',
              }}
              position="static"
            />
          </>
        )}
        {errors &&
          (errors.details || selectedCategoryId === 1 || activeStep === 2) &&
          Object.keys(errors).length > 0 && (
            <ErrorMesageWrapper>
              <StickyErrorMessageBox
                errors={errors}
                marginTop="0"
                messageWidth="100%"
              />
            </ErrorMesageWrapper>
          )}
        <Wrapper>
          {selectedAuthScreen === 'loginOrSignup' ? (
            <>
              <FormWrapper>
                {selectedCategoryId === 1 ? (
                  <LogInForm
                    handleChangeScreen={handleChangeScreen}
                    classes={classes}
                    loginFailureError={loginFailureError}
                    resetCheckedEmail={resetCheckedEmail}
                    resetAuthReducer={resetAuthReducer}
                    login={login}
                    setEmailOrPhoneField={setEmailOrPhoneField}
                    appConfig={appConfig}
                    setShowGuestPersonalDetailsPage={
                      setShowGuestPersonalDetailsPage
                    }
                    updateCartScreen={updateCartScreen}
                    cartScreen={cartScreen}
                    setAuthenticationModalIsVisible={
                      setAuthenticationModalIsVisible
                    }
                    requestingLogin={requestingLogin}
                  />
                ) : (
                  <RegistrationContainer
                    id="Auth-AuthContainer-RegistrationContainer"
                    hideAuthenticationModal={hideAuthenticationModal}
                    updatePhoneNumber={updatePhoneNumber}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                  />
                )}
              </FormWrapper>
            </>
          ) : (
            <ScreenWrapper>
              {selectedAuthScreen === 'forgotPassword' ? (
                <ForgotPassword
                  setEmailOrPhoneObject={setEmailOrPhoneObject}
                  handleChangeScreen={handleChangeScreen}
                  postForgotPassword={postForgotPassword}
                  emailOrPhoneField={emailOrPhoneField}
                  setEmailOrPhoneField={setEmailOrPhoneField}
                ></ForgotPassword>
              ) : selectedAuthScreen === 'VerificationCode' ? (
                <Verification
                  handleChangeScreen={handleChangeScreen}
                  classes={classes}
                  emailOrPhoneField={emailOrPhoneField}
                  setEmailOrPhoneField={setEmailOrPhoneField}
                  resetForgotPassword={resetForgotPassword}
                  emailOrPhoneObject={emailOrPhoneObject}
                  verificationCodeConfirmation={verificationCodeConfirmation}
                  postForgotPassword={postForgotPassword}
                  setEmailOrPhoneObject={setEmailOrPhoneObject}
                ></Verification>
              ) : selectedAuthScreen === 'createNewPasswordScreen' ? (
                <CreateNewPassword
                  resetPassword={resetPassword}
                  classes={classes}
                  minPasswordLength={appConfig.password_complexity_min_length}
                ></CreateNewPassword>
              ) : null}
            </ScreenWrapper>
          )}
        </Wrapper>
        <ConfirmCloseModal
          id="Auth-AuthContainer-ConfirmCloseModal"
          handleConfirmClick={handleConfirmModalConfirmClick}
          handleCancelClick={handleConfirmModalCancelClick}
          confirmCloseModalIsVisible={confirmCloseModalIsVisible}
        />
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  cartScreen: state.cartReducer.cartScreen,
  registrationError: state.registrationReducer.registrationError,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    ...actions,
    setShowGuestPersonalDetailsPage,
    updateCartScreen,
    clearRegisterationErrorMessage,
  })(AuthContainer)
);
