import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MaterialUISelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { formatLocationName } from '../modules/OrderSetting/OrderSettingModal/utils/helper';

const StyledSelect = styled(Select)`

   cursor:pointer;
  .react-select__value-container--is-multi {
    max-width: 100%;
  }

  .react-select__container {
   
  }

  .react-select__control {
cursor:pointer;
border: ${(props) =>
  props.isTimeNoLongerValid && props.isFromDateTimeSelect
    ? '1px solid #c90e0e'
    : 'default'}

  }
  .react-select__control--menu-is-open {
  }
  .react-select__placeholder {
  }
  .react-select__control--is-focused {
   box-shadow:0 0 0 1px hsl(0,0%,80%);;
   border-color:hsl(0,0%,80%);;
  }

  .react-select__control:hover {
    border-color: hsl(0,0%,80%);
    background-color: ${(props) => props.theme.select.hoverBackgroundColor};
    transition: all 0.25s ease;
  }

  .react-select__option {
    cursor:pointer;
   background-color: #fff;
  }

  .react-select__option:hover {
   background-color: ${(props) => props.theme.select.hoverBackgroundColor};
  //  color:white;
  transition: all 0.25s ease;
  }

  .react-select__option--is-selected {
    // color: white;
    color: ${(props) => props.theme.bodyColor};
    background-color: ${(props) => props.theme.select.isSelected};
    font-family: ${(props) => props.theme.primaryFontFontFamily};

  }

  .react-select__multi-value {
    // background-color: ${(props) => props.theme.lightGray};
  }

  .react-select__indicator-separator {
    margin: 0;
    background-color: #e6e6e6;
  }

  .react-select__dropdown-indicator {
  }
  .react-select__menu {
  }
  .react-select__option--is-disabled {
    // color: #f2f2f2;
  }

  .select input {
    font-family: ${(props) => props.theme.primaryFontFontFamily};
  }

  input {
    font-family: ${(props) => props.theme.primaryFontFontFamily};
  }

  font-family: ${(props) => props.theme.primaryFontFontFamily};

`;

const styles = (theme) => ({
  select: {
    borderBottom: '1px solid #c90e0e',
  },
});

const ReactSelect = ({
  options,
  getOptionLabel,
  getOptionValue,
  value,
  onChange,
  placeholder,
  formatOptionLabel,
  fromLocationPage,
  maxMenuHeight,
  isSearchable,
  noOptionsMessage,
  materialUiNoOptionsMessage,
  menuPlacement,
  isTimeNoLongerValid,
  isFromDateTimeSelect,
  classes,
  isLocation,
  isDate,
  coords,
  nearestLocation,
  interOrderType,
  triggerOnChange,
  ...props
}) => {
  const [innerWidth, setInnerWidth] = useState(null);
  const [lastValue, setLastValue] = useState(value);

  useEffect(() => {
    const windowSizeHandler = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', windowSizeHandler);
    return () => window.removeEventListener('resize', windowSizeHandler);
  }, []);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [typeof window !== 'undefined' && window.innerWidth]);

  // useEffect(() => {
  //   if (triggerOnChange) {
  //     if (
  //       (!lastValue && value) ||
  //       (value && lastValue && lastValue.id !== value.id)
  //     ) {
  //       onChange(getOptionValue(value));
  //       setLastValue(value);
  //     }
  //   }
  // }, [value, interOrderType, lastValue]);

  if (innerWidth) {
    return (
      <StyledSelect
        className="react-select"
        classNamePrefix="react-select"
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        onChange={(e) => {
          onChange(getOptionValue(e));
        }}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight}
        formatOptionLabel={formatOptionLabel}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        menuPlacement={menuPlacement}
        isTimeNoLongerValid={isTimeNoLongerValid}
        isFromDateTimeSelect={isFromDateTimeSelect}
        {...props}
      />
    );
  } else {
    let selectValue = '';
    try {
      if (isDate) {
        selectValue = options
          .map((option, index) =>
            getOptionValue(option).substr(0, 10) ==
            getOptionValue(value).substr(0, 10)
              ? index
              : undefined
          )
          .filter((i) => i != undefined)[0];
      } else if (isLocation) {
        selectValue = options
          .map((option, index) =>
            getOptionValue(option).id == getOptionValue(value).id
              ? index
              : undefined
          )
          .filter((i) => i != undefined)[0];
      } else {
        selectValue = options
          .map((option, index) =>
            getOptionValue(option) == getOptionValue(value) ? index : undefined
          )
          .filter((i) => i != undefined)[0];
      }
    } catch {}
    return (
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel
          htmlFor="native-select"
          shrink={value ? true : false}
          style={{ backgroundColor: 'white' }}
        >
          {placeholder}
        </InputLabel>
        <MaterialUISelect
          native
          value={selectValue || selectValue === 0 ? selectValue : ''}
          onChange={(event) => {
            onChange && onChange(getOptionValue(options[event.target.value]));
          }}
          placeholder={placeholder}
          InputLabelProps={{ shrink: value ? true : false }}
          inputProps={{
            name: 'native-select',
            id: 'native-select',
            shrink: value ? true : false,
          }}
          autoWidth={true}
          key={selectValue}
          //style={{margin: '30px', minWidth: 120}}
          className={
            isFromDateTimeSelect && isTimeNoLongerValid ? classes.select : ''
          }
        >
          {/* {!fromLocationPage && <option value="" />} */}
          <option disabled>
            {materialUiNoOptionsMessage
              ? materialUiNoOptionsMessage
              : placeholder}
          </option>
          {!value && <option value="" disabled />}
          {options &&
            options.map((option, index) => {
              if (isLocation) {
                option = formatLocationName(option, coords, nearestLocation);
              }
              return (
                <option key={index} value={index}>
                  {getOptionLabel(option)}
                </option>
              );
            })}
        </MaterialUISelect>
      </FormControl>
    );
  }
};

export default withStyles(styles)(ReactSelect);
