import styled from 'styled-components';

const ButtonRow = styled.div`
  display: grid;
  grid-auto-flow: ${props => props.row? 'row' : 'column'};
  justify-content: ${props => props.justifyContent || 'space-between'};
  @media (max-width: 768px) {
    grid-auto-flow: row;
    grid-gap: 20px;
    justify-content: stretch;
  }
`;

export default ButtonRow;
