import {
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  NAVIGATED_TO_OFFERS_TAB,
  NAVIGATED_TO_HOW_IT_WORKS_TAB,
  ACTIVATE_OFFER_SUCCESS,
  DEACTIVATE_OFFER_SUCCESS,
  GET_OFFERS_FOR_REVIEW_SCREEN_REQUEST,
  GET_OFFERS_FOR_REVIEW_SCREEN_SUCCESS,
  GET_OFFERS_FOR_REVIEW_SCREEN_FAILURE,
} from './offersTypes';
import api from '../../api';
import { getProductToConfigure } from '../ProductCustomizer/productCustomizerActions';
import { createAlert } from '../Alert/alertActions';
import flattenProduct from '../../utils/flattenProduct';
import productForCartValidation from '../Cart/utils/productForCartValidation';
import { PICKUP, DELIVERY, DROPOFF } from '../OrderSetting/orderSettingTypes';
import getOrderDateTimeParam from '../../utils/getOrderDateTimeParam';
import moment from 'moment';

export function getOffers(business_id = null) {
  return function(dispatch, getState) {
    // const requestedCart = getState().cartReducer;
    const currentBusinessID = parseInt(localStorage.getItem('business_id'));
    const currentOrderType = localStorage.getItem('order_type');
    const orderSetting = getState().orderSettingReducer;
    const orderType = orderSetting.orderType;
    const selectedDateTime = orderSetting.selectedDateTime;
    // add filters to body
    let body = {
      business_id: currentBusinessID,
      order_type: currentOrderType,
    };

    // if (orderType === PICKUP && orderSetting.interPickUpDetails) {
    //   body = {
    //     ...body,
    //     business_id: currentBusinessID,
    //   };
    // } else if (orderType === DELIVERY && orderSetting.deliveryDetails) {
    //   let business_id =
    //     orderSetting &&
    //     orderSetting.deliveryDetails &&
    //     orderSetting.deliveryDetails.deliveryBusiness
    //       ? orderSetting.deliveryDetails.deliveryBusiness.id
    //       : null;
    //   if (business_id) {
    //     body = {
    //       ...body,
    //       business_id: currentBusinessID,
    //     };
    //   } else {
    //     body = {
    //       ...body,
    //       customer_address_id: currentBusinessID,
    //     };
    //   }
    // }

    const orderDate = getOrderDate(selectedDateTime);

    if (orderDate) {
      body = {
        ...body,
        order_date: orderDate,
      };
    }
    if (currentOrderType && currentOrderType) {
      return api.post('/offers', body).then(
        (response) => {
          const filteredOffers = response.data.data.filter((offer) => {
            return (
              offer.offer_type === 'first-time' ||
              offer.offer_type === 'carryover-loyalty' ||
              offer.web_conditions
            );
          });

          dispatch({
            type: GET_OFFERS_SUCCESS,
            payload: filteredOffers,
          });
        },
        (error) => {
          dispatch({
            type: GET_OFFERS_FAILURE,
            error,
          });
        }
      );
    }
  };
}

export function navigatedToOffersTab() {
  return {
    type: NAVIGATED_TO_OFFERS_TAB,
    tabIndex: 0,
  };
}

export function navigatedToHowItWorksTab() {
  return {
    type: NAVIGATED_TO_HOW_IT_WORKS_TAB,
    tabIndex: 1,
  };
}

export function activateOffer(offer, onClose) {
  return function(dispatch) {
    return api.post(`/offers/${offer.offer_id}/activate`).then(
      (response) => {
        dispatch({
          type: ACTIVATE_OFFER_SUCCESS,
          payload: response.data.data,
        });
        dispatch(getOffersForReviewScreen());
        dispatch(
          createAlert({
            type: 'success',
            message: 'You have successfully activated the offer',
          })
        );
        onClose();
      },
      (err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Sorry, you are currently not eligible for this offer',
          })
        );
      }
    );
  };
}

export function deactivateOffer(offer, onClose) {
  return function(dispatch) {
    return api.post(`/offers/${offer.offer_id}/deactivate`).then(
      (response) => {
        dispatch({
          type: DEACTIVATE_OFFER_SUCCESS,
          payload: response.data.data,
        });
        dispatch(getOffersForReviewScreen());
        dispatch(
          createAlert({
            type: 'success',
            message: 'You have successfully deactivated the offer',
          })
        );
        onClose();
      },
      (err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Sorry, an error occurred trying to deactivate the offer',
          })
        );
      }
    );
  };
}

export function getSingleProduct(product, offer) {
  return function(dispatch, getState) {
    const orderType = getState().orderSettingReducer.orderType;
    let business_id;
    if (orderType === PICKUP) {
      business_id = getState().orderSettingReducer.interPickUpDetails?.id;
    } else if (orderType === DROPOFF) {
      business_id = getState().orderSettingReducer.dropOffDetails?.id;
    }
    let variantList =
      offer.web_conditions.eligible_variants ||
      offer.web_conditions.discounted_variants;

    let variantId = null;

    if (variantList) {
      const defaultVariant = product.variant_id;

      if (variantList.includes(defaultVariant)) {
        variantId = defaultVariant;
      } else {
        const productPricesIds = product.prices.map(
          (price) => price.variant_id
        );
        variantId = variantList.find((listId) =>
          productPricesIds.includes(listId)
        );
      }
    }
    dispatch(
      getProductToConfigure(product.product_id, business_id, offer, variantId)
    );

    // variantId &&
  };
}

export const getOfferAvailableBusinesses = (
  { offer },
  setModalState,
  setNotAvailableModalState
) => {
  return function(dispatch, getState) {
    return api.get(`/offers/${offer.offer_id}/businesses`).then((response) => {
      let businessesAvailable = response.data.data.businesses;
      let currentBusiness;
      if (getState().orderSettingReducer.orderType === PICKUP) {
        currentBusiness = getState().orderSettingReducer.interPickUpDetails.id;
      }
      if (getState().orderSettingReducer.orderType === DROPOFF) {
        currentBusiness = getState().orderSettingReducer.dropOffDetails.id;
      }
      if (getState().orderSettingReducer.orderType === DELIVERY) {
        currentBusiness = getState().orderSettingReducer.interPickUpDetails.id;
      }

      // if (businessesAvailable.includes(currentBusiness)) {
      //setModalState(true);
      // } else {
      //setNotAvailableModalState(true);
      // }

      if (offer.auto_redeem) {
        setModalState(true);
      } else {
        setNotAvailableModalState(true);
      }
    });
  };
};

export function getOffersForReviewScreen() {
  return function(dispatch, getState) {
    const requestedCart = getState().cartReducer;
    const orderSetting = getState().orderSettingReducer;
    const orderType = orderSetting.orderType;
    const selectedDateTime = orderSetting.selectedDateTime;

    let body = {
      cart: requestedCart.products.map((product) =>
        productForCartValidation(product.flatProduct)
      ),
      order_type: orderType,
    };

    if (orderType === PICKUP) {
      body = {
        ...body,
        business_id: orderSetting.interPickUpDetails.id,
      };
    } else if (orderType === DELIVERY) {
      let business_id =
        orderSetting &&
        orderSetting.deliveryDetails &&
        orderSetting.deliveryDetails.deliveryBusiness
          ? orderSetting.deliveryDetails.deliveryBusiness.id
          : null;
      if (business_id) {
        body = {
          ...body,
          business_id,
        };
      } else {
        body = {
          ...body,
          customer_address_id: orderSetting?.deliveryDetails?.id,
        };
      }
    }

    const orderDate = getOrderDate(selectedDateTime);

    if (orderDate) {
      body = { ...body, order_date: orderDate };
    }

    dispatch({ type: GET_OFFERS_FOR_REVIEW_SCREEN_REQUEST });
    api
      .post(`/offers`, body)
      .then((response) => {
        dispatch({
          type: GET_OFFERS_FOR_REVIEW_SCREEN_SUCCESS,
          reviewScreenOffers: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_OFFERS_FOR_REVIEW_SCREEN_FAILURE });
      });
  };
}

export function getOrderDate(selectedDateTime) {
  let orderDateTimeParam = null;
  if (
    selectedDateTime &&
    selectedDateTime.radioValue.value === 'schedule' &&
    selectedDateTime.dateTime &&
    selectedDateTime.dateTime.date &&
    selectedDateTime.dateTime.date.name &&
    selectedDateTime.dateTime.time &&
    selectedDateTime.dateTime.time.name
  ) {
    let orderDate = moment(
      selectedDateTime.dateTime.date.name,
      'ddd MMM DD'
    ).set({
      year: moment().format('YYYY'),
    });

    let orderTime = moment(selectedDateTime.dateTime.time.name, 'hh:mm A');
    if (orderDate.isValid() && orderTime.isValid()) {
      orderDate = orderDate.format('YYYY-MM-DD');
      orderTime = orderTime.format('HH:mm:ss');
      orderDateTimeParam = `${orderDate + ' ' + orderTime}`;
    }
  }
  return orderDateTimeParam;
}
