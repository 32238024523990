export const DELIVERY_ADDRESSES_FORM_IS_VISIBLE =
  'DELIVERY_ADDRESSES_FORM_IS_VISIBLE';

export const SAVE_DELIVERY_ADDRESS_REQUEST = 'SAVE_DELIVERY_ADDRESS_REQUEST';
export const SAVE_DELIVERY_ADDRESS_SUCCESS = 'SAVE_DELIVERY_ADDRESS_SUCCESS';

export const UPDATE_DELIVERY_ADDRESS_REQUEST =
  'UPDATE_DELIVERY_ADDRESS_REQUEST';
export const UPDATE_DELIVERY_ADDRESS_SUCCESS =
  'UPDATE_DELIVERY_ADDRESS_SUCCESS';

export const GET_DELIVERY_ADDRESSES_REQUEST = 'GET_DELIVERY_ADDRESSES_REQUEST';
export const GET_DELIVERY_ADDRESSES_SUCCESS = 'GET_DELIVERY_ADDRESSES_SUCCESS';

export const SET_DELIVERY_ADDRESSES_FORM_INITIAL_VALUES =
  'SET_DELIVERY_ADDRESSES_FORM_INITIAL_VALUES';

export const RESET_DELIVERY_ADDRESSES_REDUCER =
  'RESET_DELIVERY_ADDRESSES_REDUCER';

export const DELETE_DELIVERY_ADDRESS_REQUEST =
  'DELETE_DELIVERY_ADDRESS_REQUEST';
export const DELETE_DELIVERY_ADDRESS_SUCCESS =
  'DELETE_DELIVERY_ADDRESS_SUCCESS';
export const SAVE_DELIVERY_ADDRESS_ERROR = 'SAVE_DELIVERY_ADDRESS_ERROR';

export const DELIVERY_TO_ADDRESS_ENABLED = 'DELIVERY_TO_ADDRESS_ENABLED';
export const DELIVERY_TO_ADDRESS_DISABLED = 'DELIVERY_TO_ADDRESS_DISABLED';

export const SET_GUEST_DELIVERY_ADDRESS_FORM_INITIAL_VALUES =
  'SET_GUEST_DELIVERY_ADDRESS_FORM_INITIAL_VALUES';
