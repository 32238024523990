import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../orderSettingActions';
import {
  setDeliveryAddressesFormIsVisible,
  setSelectedDeliveryAddress,
} from '../../DeliveryAddresses/deliveryAddressesActions';
import {
  setDropOffLocationFormIsVisible,
  getFirstDropOffSelection,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  restoreSelectedLocation,
} from '../../DropOff/dropOffLocationActions';
import {
  validateCart,
  updateCartWithUnavailbleProducts,
  emptyCart,
  validateCartForLocation,
  resetCartErrorMessages,
} from '../../Cart/cartActions';
import {
  resetGuestSession,
  setAuthenticationModalIsVisible,
} from '../../Auth/authActions';
import { PICKUP, DELIVERY, DROPOFF } from '../orderSettingTypes';
import Dialog from '../../../components/Dialog/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MarketplaceOrderSettingModal from './MarketplaceOrderSettingModal';
import OrderingClosedContainer from '../OrderingClosed/OrderingClosedContainer';
import appConfigFile from '../../../../app-config';
import { connectSearchBox } from 'react-instantsearch-dom';
import { getMenu } from '../../OnAppLoad/Menu/menuActions';
import { getCombos } from '../../Combo/comboActions';

const styles = () => ({
  scrollPaper: {},
  paper: {
    margin: 0,
    maxHeight: '90%',
    overflowY: 'visible',
    maxWidth: '1080px !important',
    width: '90%',
    margin: '0 2em',
    ['@media(max-width: 480px)']: {
      margin: '0',
      maxWidth: '100% !important',
      width: '100%',
    },
  },
});

const MarketplaceOrderSettingModalContainer = ({
  classes,
  orderSettingReducer,
  updateInterOrderType,
  locations,
  updateInterPickUpDetails,
  deliveryAddressesList,
  updateInterDeliveryDetails,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  updateOrderSettingModalIsVisible,
  confirmPickUpDetails,
  resetInterSelectedDateTime,
  resetDateTimes,
  requestingGetLocations,
  confirmMarketplaceDeliverySelection,
  setDeliveryAddressesFormIsVisible,
  validateCart,
  resetIsTimeNoLongerValid,
  currentLocation,
  isAuthenticated,
  hideToolTip,
  nearestLocation,
  orderHistory,
  cartReducer,
  updateCartWithUnavailbleProducts,
  emptyCart,
  orderingClosedInAllLocations,
  getBusinessSpecialHours,
  setChildLocation,
  setDropOffLocationFormIsVisible,
  updateInterDropOffDetails,
  confirmDropOffDetails,
  getFirstDropOffSelection,
  dropOffLocationReducer,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  restoreSelectedLocation,
  refine,
  updateSelectedLocation,
  deliveryToAddress,
  deepLinkParameters,
  getMarketplaceDeliveryLocations,
  setDeliveryTimeFormIsVisible,
  checkUnavailableProductForMarketplace,
  resetMarketplaceDeliveryLocations,
  confirmDeliveryDetails,
  setMarketplaceDeliverySelection,
  checkUnavailableProductForPickUp,
  setParentLocation,
  getChildLocations,
  appConfig,
  profileSettingsReducer,
  setPickUpTimeFormIsVisible,
  validateCartForLocation,
  resetCartErrorMessages,
  resetGuestSession,
  setIsParentStoreDeepLink,
  getMenu,
  setAuthenticationModalIsVisible,
  setIsMenuDisabled,
  selectMarketplacePickUpLocation,
  setSelectedDeliveryAddress,
  deliveryAddressToBeDisplayed,
  clearSelectedLocation,
  getCombos,
}) => {
  //useDeepLink();

  const cartItems = {
    products: cartReducer.products,
    combos: cartReducer.combos,
  };
  const {
    orderType,
    interOrderType,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    availableTimes,
    interSelectedDateTime,
    orderSettingModalIsVisible,
    unavailableProducts,
    isRequesting,
    toolTipIsHidden,
    selectedDateTime,
    isTimeNoLongerValid,
    specialHours,
    orderPacingData,
    selectedLocation,
    cartErrorMessage,
    marketplaceDeliveryLocations,
    isMarketplaceDeliveryLocationsRequest,
    deliveryTimeFormIsVisible,
    deliveryDetails,
    isUnavailableProductInCartRequest,
    parentLocation,
    childLocations,
    isDeepLink,
    isParentDeepLink,
    isMenuDisabled,
    singleOrderType,
  } = orderSettingReducer;

  const {
    pickedDropOffLocation,
    dropOffLocations,
    selectedDropOffLocation,
    getDropOffLocationsIsRequesting,
  } = dropOffLocationReducer;

  useEffect(() => {
    resetCartErrorMessages();
  }, []);
  useEffect(() => {
    let id = null;
    let type = null;
    if (interOrderType === PICKUP && interPickUpDetails) {
      type = 'pickup';
      id = interPickUpDetails.id;
    } else if (interOrderType === DELIVERY && interDeliveryDetails) {
      type = 'delivery';
      id = interDeliveryDetails.business_id;
    } else if (interOrderType === DROPOFF && interDropOffDetails) {
      type = 'drop-off';
      id = interDropOffDetails.id;
    }
    if (id && type) {
      localStorage.setItem('business_id', id);
      localStorage.setItem('order_type', type);
    }
  }, [
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interOrderType,
  ]);

  const handleConfirmPickupButton = (childLocation) => {
    if (!isAuthenticated) {
      emptyCart();
      // confirmPickUpDetails(true);
    } else {
      if (unavailableProducts.length) {
        const unavailableProductsModified = unavailableProducts.map(
          (product) => product.product_id
        );
        updateCartWithUnavailbleProducts(unavailableProductsModified);
        // confirmPickUpDetails(true);
      }
      // else confirmPickUpDetails(true);
    }

    confirmPickUpDetails(true);

    if (
      appConfigFile.isGlobalSearchEnabled &&
      interPickUpDetails &&
      (!interPickUpDetails.accepting_orders || !interPickUpDetails.open)
    ) {
      refine('');
    }
  };

  const handleConfirmDropOffButton = () => {
    if (!isAuthenticated) {
      emptyCart();
    } else {
      if (unavailableProducts.length) {
        const unavailableProductsModified = unavailableProducts.map(
          (product) => product.product_id
        );
        updateCartWithUnavailbleProducts(unavailableProductsModified);
      }
    }
    confirmDropOffDetails(true);
  };

  return (
    <>
      <Dialog
        open={orderSettingModalIsVisible}
        onClose={() => {
          updateOrderSettingModalIsVisible(false);
          updateInterPickUpDetails(interPickUpDetails);
          updateInterSelectedDateTime(selectedDateTime);
          if (orderType === DROPOFF) {
            restoreSelectedLocation();
          }
        }}
        classes={{ scrollPaper: classes.scrollPaper }}
        PaperProps={{ classes: { root: classes.paper } }}
        aria-labelledby="order-method"
        scroll="body"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <MarketplaceOrderSettingModal
          cartItems={cartItems}
          interOrderType={interOrderType}
          updateInterOrderType={updateInterOrderType}
          locations={locations}
          currentLocation={currentLocation}
          updateInterPickUpDetails={updateInterPickUpDetails}
          interPickUpDetails={interPickUpDetails}
          deliveryAddressesList={deliveryAddressesList}
          updateInterDeliveryDetails={updateInterDeliveryDetails}
          interDeliveryDetails={interDeliveryDetails}
          availableTimes={availableTimes}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          confirmPickUpDetails={confirmPickUpDetails}
          unavailableProducts={unavailableProducts}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          deliveryBusiness={
            interDeliveryDetails && interDeliveryDetails.deliveryBusiness
          }
          resetDateTimes={resetDateTimes}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          isTimeNoLongerValid={isTimeNoLongerValid}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          confirmMarketplaceDeliverySelection={
            confirmMarketplaceDeliverySelection
          }
          handleConfirmPickupButton={handleConfirmPickupButton}
          setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
          isAuthenticated={isAuthenticated}
          hideToolTip={hideToolTip}
          selectedDateTime={selectedDateTime}
          toolTipIsHidden={toolTipIsHidden}
          orderHistory={orderHistory}
          nearestLocation={nearestLocation}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          setDropOffLocationFormIsVisible={setDropOffLocationFormIsVisible}
          interDropOffDetails={interDropOffDetails}
          updateInterDropOffDetails={updateInterDropOffDetails}
          confirmDropOffDetails={confirmDropOffDetails}
          getFirstDropOffSelection={getFirstDropOffSelection}
          pickedDropOffLocation={pickedDropOffLocation}
          setIsEditDropOffLocation={setIsEditDropOffLocation}
          deleteDropOffLocation={deleteDropOffLocation}
          handleConfirmDropOffButton={handleConfirmDropOffButton}
          dropOffLocations={dropOffLocations}
          selectedDropOffLocation={selectedDropOffLocation}
          getDropOffLocationsIsRequesting={getDropOffLocationsIsRequesting}
          refine={refine}
          selectedLocation={selectedLocation}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          updateSelectedLocation={updateSelectedLocation}
          cartErrorMessage={cartErrorMessage}
          deliveryToAddress={deliveryToAddress}
          deepLinkParameters={deepLinkParameters}
          getMarketplaceDeliveryLocations={getMarketplaceDeliveryLocations}
          marketplaceDeliveryLocations={marketplaceDeliveryLocations}
          isMarketplaceDeliveryLocationsRequest={
            isMarketplaceDeliveryLocationsRequest
          }
          setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
          deliveryTimeFormIsVisible={deliveryTimeFormIsVisible}
          deliveryDetails={deliveryDetails}
          isUnavailableProductInCartRequest={isUnavailableProductInCartRequest}
          checkUnavailableProductForMarketplace={
            checkUnavailableProductForMarketplace
          }
          resetMarketplaceDeliveryLocations={resetMarketplaceDeliveryLocations}
          confirmDeliveryDetails={confirmDeliveryDetails}
          setMarketplaceDeliverySelection={setMarketplaceDeliverySelection}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          setParentLocation={setParentLocation}
          parentLocation={parentLocation}
          getChildLocations={getChildLocations}
          childLocations={childLocations}
          appConfig={appConfig}
          profileSettingsReducer={profileSettingsReducer}
          setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
          isDeepLink={isDeepLink}
          isParentDeepLink={isParentDeepLink}
          setIsParentStoreDeepLink={setIsParentStoreDeepLink}
          getMenu={getMenu}
          setAuthenticationModalIsVisible={setAuthenticationModalIsVisible}
          setIsMenuDisabled={setIsMenuDisabled}
          selectMarketplacePickUpLocation={selectMarketplacePickUpLocation}
          isMenuDisabled={isMenuDisabled}
          setSelectedDeliveryAddress={setSelectedDeliveryAddress}
          deliveryAddressToBeDisplayed={deliveryAddressToBeDisplayed}
          emptyCart={emptyCart}
          clearSelectedLocation={clearSelectedLocation}
          getCombos={getCombos}
          singleOrderType={singleOrderType}
        />
      </Dialog>

      <OrderingClosedContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  orderSettingReducer: state.orderSettingReducer,
  locations: state.locationsReducer.locations,
  deliveryAddressesList: state.deliveryAddressesReducer.deliveryAddressesList,
  deliveryAddressToBeDisplayed:
    state.deliveryAddressesReducer.deliveryAddressToBeDisplayed,
  dropOffLocationReducer: state.dropOffLocationReducer,
  requestingGetLocations: state.locationsReducer.requestingGetLocations,
  isAuthenticated: state.authReducer.isAuthenticated,

  currentLocation: state.locationsReducer.currentLocation,
  orderHistory: state.myTransactionsReducer.myTransactions,
  cartReducer: state.cartReducer,
  orderingClosedInAllLocations:
    state.locationsReducer.orderingClosedInAllLocations,
  deepLinkParameters: state.deepLinkReducer.parameters,
  deliveryToAddress: state.deliveryAddressesReducer.deliveryToAddress,
  appConfig: state.authReducer.appConfig,
  profileSettingsReducer: state.profileSettingsReducer,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    ...actions,
    setDeliveryAddressesFormIsVisible,
    validateCart,
    updateCartWithUnavailbleProducts,
    emptyCart,
    setIsEditDropOffLocation,
    deleteDropOffLocation,
    setDropOffLocationFormIsVisible,
    getFirstDropOffSelection,
    restoreSelectedLocation,
    validateCartForLocation,
    resetCartErrorMessages,
    resetGuestSession,
    getMenu,
    getCombos,
    setAuthenticationModalIsVisible,
    setSelectedDeliveryAddress,
  })(
    appConfigFile.isGlobalSearchEnabled
      ? connectSearchBox(MarketplaceOrderSettingModalContainer)
      : MarketplaceOrderSettingModalContainer
  )
);
