import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../orderSettingActions';
import {
  setDeliveryAddressesFormIsVisible,
  setGuestDeliveryAddressFormInitialValues,
} from '../../DeliveryAddresses/deliveryAddressesActions';
import {
  setDropOffLocationFormIsVisible,
  getFirstDropOffSelection,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  restoreSelectedLocation,
} from '../../DropOff/dropOffLocationActions';
import { resetGuestSession } from '../../Auth/authActions';
import {
  validateGlobalCart,
  updateCartWithUnavailbleProducts,
  emptyCart,
  validateCartForLocation,
} from '../../Cart/cartActions';
import { PICKUP, DELIVERY, DROPOFF } from '../orderSettingTypes';
import Dialog from '../../../components/Dialog/Dialog';
import { withStyles } from '@material-ui/core/styles';
//import {DialogContent, Box} from '@material-ui/core';
import OrderSettingModal from './OrderSettingModal';
import OrderingClosedContainer from '../OrderingClosed/OrderingClosedContainer';
import appConfigFile from '../../../../app-config';
import { connectSearchBox } from 'react-instantsearch-dom';
import { navigate } from 'gatsby';
import { getMenu } from "../../OnAppLoad/Menu/menuActions";

const styles = () => ({
  scrollPaper: {},
  paper: {
    margin: 0,
    maxHeight: '90%',
    overflowY: 'visible',
    width: appConfigFile.isParentChildClient ? '1000px' : '380px',
    maxWidth: appConfigFile.isParentChildClient ? '1000px' : '450px',
    minWidth: '350px',
  },
});

const OrderSettingModalContainer = ({
  classes,
  orderSettingReducer,
  updateInterOrderType,
  locations,
  updateInterPickUpDetails,
  deliveryAddressesList,
  updateInterDeliveryDetails,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  updateOrderSettingModalIsVisible,
  confirmPickUpDetails,
  resetInterSelectedDateTime,
  resetDateTimes,
  requestingGetLocations,
  confirmDeliveryDetails,
  setDeliveryAddressesFormIsVisible,
  validateGlobalCart, //GLOBAL CART
  resetIsTimeNoLongerValid,
  currentLocation,
  isAuthenticated,
  isGuestSession,
  hideToolTip,
  nearestLocation,
  orderHistory,
  cartReducer,
  updateCartWithUnavailbleProducts,
  emptyCart,
  orderingClosedInAllLocations,
  getBusinessSpecialHours,
  getChildLocations,
  setChildLocation,
  setDropOffLocationFormIsVisible,
  updateInterDropOffDetails,
  confirmDropOffDetails,
  getFirstDropOffSelection,
  dropOffLocationReducer,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  restoreSelectedLocation,
  refine,
  updateSelectedLocation,
  deliveryToAddress,
  deepLinkParameters,
  checkUnavailableProductForPickUp,
  guestDeliveryFormInitialAddress,
  setGuestDeliveryAddressFormInitialValues,
  appConfig,
  validateCartForLocation,
  resetGuestSession,
  getMenu
}) => {
  const cartItems = {
    products: cartReducer.products,
    combos: cartReducer.combos,
  };
  const {
    orderType,
    interOrderType,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    availableTimes,
    interSelectedDateTime,
    orderSettingModalIsVisible,
    unavailableProducts,
    isRequesting,
    toolTipIsHidden,
    selectedDateTime,
    isTimeNoLongerValid,
    specialHours,
    childLocations,
    orderPacingData,
    selectedLocation,
    cartErrorMessage,
  } = orderSettingReducer;

  const {
    pickedDropOffLocation,
    dropOffLocations,
    selectedDropOffLocation,
    getDropOffLocationsIsRequesting,
  } = dropOffLocationReducer;
  const handleConfirmPickupButton = (childLocation) => {
    if (!isAuthenticated && !isGuestSession) {
      emptyCart();
      // confirmPickUpDetails(true);
    } else {
      if (unavailableProducts.length) {
        const unavailableProductsModified = unavailableProducts.map(
          (product) => product.product_id
        );
        updateCartWithUnavailbleProducts(unavailableProductsModified);
        // confirmPickUpDetails(true);
      }
      // else confirmPickUpDetails(true);
    }

    confirmPickUpDetails(true);
    if (
      appConfigFile.isGlobalSearchEnabled &&
      (!interPickUpDetails.accepting_orders || !interPickUpDetails.open) &&
      !interPickUpDetails.is_future_order_enabled &&
      !interPickUpDetails.is_order_ahead_enabled
    ) {
      refine('');
    }
  };

  const handleConfirmDeliveryButton = () => {
    if (!isAuthenticated && !isGuestSession) {
      emptyCart();
      // confirmPickUpDetails(true);
    } else {
      if (unavailableProducts.length > 0) {
        const unavailableProductsModified = unavailableProducts.map(
          (product) => product.product_id
        );
        updateCartWithUnavailbleProducts(unavailableProductsModified);
        // confirmPickUpDetails(true);
      }
      // else confirmPickUpDetails(true);
    }
    confirmDeliveryDetails(true);
  };

  const handleConfirmDropOffButton = () => {
    if (!isAuthenticated && !isGuestSession) {
      emptyCart();
    } else {
      if (unavailableProducts.length) {
        const unavailableProductsModified = unavailableProducts.map(
          (product) => product.product_id
        );
        updateCartWithUnavailbleProducts(unavailableProductsModified);
      }
    }
    confirmDropOffDetails(true);
  };

  useEffect(() => {
    if (orderSettingModalIsVisible) {
      navigate("/");
    }
  }, [orderSettingModalIsVisible]);

  useEffect(() => {
    if (
      interPickUpDetails &&
      interPickUpDetails.is_order_ahead_enabled &&
      interOrderType === PICKUP
    )
      getBusinessSpecialHours(interPickUpDetails, interOrderType);

    if (
      interDeliveryDetails &&
      interDeliveryDetails.settings &&
      interDeliveryDetails.deliveryBusiness &&
      interDeliveryDetails.deliveryBusiness.is_order_ahead_enabled &&
      interOrderType === DELIVERY
    )
      getBusinessSpecialHours(interDeliveryDetails, interOrderType);

    if (
      interDropOffDetails &&
      interDropOffDetails.is_order_ahead_enabled &&
      interDropOffDetails === DROPOFF
    )
      getBusinessSpecialHours(interDropOffDetails, interOrderType);
  }, [interPickUpDetails, interDeliveryDetails, interDropOffDetails]);

  useEffect(() => {
    const specialHoursModified = specialHours.filter(
      (day) => day.date === moment().format('YYYY-MM-DD')
    );

    if (
      specialHoursModified[0] &&
      !specialHoursModified[0].order_start_time &&
      !specialHoursModified[0].order_end_time
    ) {
      updateInterSelectedDateTime({
        radioValue: interSelectedDateTime.radioValue,
        dateTime: {
          date: {
            name: moment()
              .add(1, 'days')
              .format('ddd MMM DD'),
            value: moment()
              .add(1, 'days')
              .toString(),
          },
          time: {
            name: '',
            value: '',
          },
        },
      });
      if (interPickUpDetails)
        getTimeIntervalsDate(
          interPickUpDetails.id,
          moment()
            .add(1, 'days')
            .toString()
            .toString()
        );
    }
  }, [interPickUpDetails, specialHours]);

  useEffect(() => {
    if (interPickUpDetails && interOrderType === PICKUP)
      getTimeIntervalsDate(interPickUpDetails.id, new Date().toString());
    if (interDropOffDetails && interOrderType === DROPOFF)
      getTimeIntervalsDate(interDropOffDetails.id, new Date().toString());
    if (
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness &&
      interOrderType === DELIVERY
    )
      getTimeIntervalsDate(
        interDeliveryDetails.deliveryBusiness.id,
        new Date().toString()
      );

    ///

    // if (appConfigFile.isParentChildClient) {
    // if (interOrderType === PICKUP && interPickUpDetails) {
    //   type = 'pickup';
    //   id = interPickUpDetails.id;
    // } else if (interOrderType === DELIVERY && interDeliveryDetails) {
    //   type = 'delivery';
    //   id = interDeliveryDetails.business_id;
    // } else if (interOrderType === DROPOFF && interDropOffDetails) {
    //   type = 'drop-off';
    //   id = interDropOffDetails.id;
    // }
    // } else {
    // }
  }, [
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interOrderType,
  ]);
  useEffect(() => {
    let id = null;
    let type = null;
    if (interOrderType === PICKUP && interPickUpDetails) {
      type = 'pickup';
      id = interPickUpDetails.id;
    } else if (
      interOrderType === DELIVERY &&
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness
    ) {
      type = 'delivery';
      id = interDeliveryDetails.deliveryBusiness.id;
    } else if (interOrderType === DROPOFF && interDropOffDetails) {
      type = 'drop-off';
      id = interDropOffDetails.id;
    }
    if (id && type) {
      localStorage.setItem('business_id', id);
      localStorage.setItem('order_type', type);
    }
  }, [
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interOrderType,
  ]);
  useEffect(() => {
    if (interOrderType === PICKUP && interPickUpDetails) {
      if (
        interPickUpDetails.is_asap_order_enabled &&
        !interPickUpDetails.is_future_order_enabled
      ) {
        updateInterSelectedDateTime({
          radioValue: {
            name: 'As soon as possible',
            value: 'asap',
          },
          dateTime: {
            date: {
              name: moment().format('ddd MMM DD'),
              value: moment().toString(),
            },
            time: {
              name: '',
              value: '',
            },
          },
        });
      }
    } else if (
      interOrderType === DELIVERY &&
      interDeliveryDetails &&
      interDeliveryDetails.deliveryBusiness
    ) {
      if (
        interDeliveryDetails.deliveryBusiness.is_asap_order_enabled &&
        !interDeliveryDetails.deliveryBusiness.is_future_order_enabled
      ) {
        updateInterSelectedDateTime({
          radioValue: {
            name: 'As soon as possible',
            value: 'asap',
          },
          dateTime: {
            date: {
              name: moment().format('ddd MMM DD'),
              value: moment().toString(),
            },
            time: {
              name: '',
              value: '',
            },
          },
        });
      }
    } else if (interOrderType === DROPOFF && interDropOffDetails) {
      if (
        interDropOffDetails.is_asap_order_enabled &&
        !interDropOffDetails.is_future_order_enabled
      ) {
        updateInterSelectedDateTime({
          radioValue: {
            name: 'As soon as possible',
            value: 'asap',
          },
          dateTime: {
            date: {
              name: moment().format('ddd MMM DD'),
              value: moment().toString(),
            },
            time: {
              name: '',
              value: '',
            },
          },
        });
      }
    }
  }, [interPickUpDetails, interDeliveryDetails, interDropOffDetails]);

  return (
    <>
      <Dialog
        open={orderSettingModalIsVisible}
        onClose={() => {
          updateOrderSettingModalIsVisible(false);
          updateInterPickUpDetails(interPickUpDetails);
          updateInterSelectedDateTime(selectedDateTime);
          if (orderType === DROPOFF) {
            restoreSelectedLocation();
          }
        }}
        classes={{ scrollPaper: classes.scrollPaper }}
        PaperProps={{ classes: { root: classes.paper } }}
        aria-labelledby="order-method"
        scroll="body"
        disableBackdropClick={
          true
          // (!isAuthenticated && !nearestLocation) ||
          // (interOrderType === DELIVERY && !deliveryAddressesList.length) ||
          // (isAuthenticated && !orderHistory.length && !nearestLocation) ||
          // !interPickUpDetails ||
          // (interOrderType === DROPOFF && !selectedDropOffLocation.length)
        }
        disableEscapeKeyDown={
          true
          // (!isAuthenticated && !nearestLocation) ||
          // (interOrderType === DELIVERY && !deliveryAddressesList.length) ||
          // (isAuthenticated && !orderHistory.length && !nearestLocation) ||
          // !interPickUpDetails ||
          // (interOrderType === DROPOFF && !selectedDropOffLocation.length)
        }
      >
        <OrderSettingModal
          cartItems={cartItems}
          interOrderType={interOrderType}
          updateInterOrderType={updateInterOrderType}
          locations={locations}
          currentLocation={currentLocation}
          updateInterPickUpDetails={updateInterPickUpDetails}
          interPickUpDetails={interPickUpDetails}
          deliveryAddressesList={deliveryAddressesList}
          updateInterDeliveryDetails={updateInterDeliveryDetails}
          interDeliveryDetails={interDeliveryDetails}
          availableTimes={availableTimes}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          confirmPickUpDetails={confirmPickUpDetails}
          unavailableProducts={unavailableProducts}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          deliveryBusiness={
            interDeliveryDetails && interDeliveryDetails.deliveryBusiness
          }
          resetDateTimes={resetDateTimes}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          isTimeNoLongerValid={isTimeNoLongerValid}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          confirmDeliveryDetails={confirmDeliveryDetails}
          handleConfirmPickupButton={handleConfirmPickupButton}
          handleConfirmDeliveryButton={handleConfirmDeliveryButton}
          setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
          isAuthenticated={isAuthenticated}
          hideToolTip={hideToolTip}
          selectedDateTime={selectedDateTime}
          toolTipIsHidden={toolTipIsHidden}
          orderHistory={orderHistory}
          nearestLocation={nearestLocation}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          setDropOffLocationFormIsVisible={setDropOffLocationFormIsVisible}
          interDropOffDetails={interDropOffDetails}
          updateInterDropOffDetails={updateInterDropOffDetails}
          confirmDropOffDetails={confirmDropOffDetails}
          getFirstDropOffSelection={getFirstDropOffSelection}
          pickedDropOffLocation={pickedDropOffLocation}
          setIsEditDropOffLocation={setIsEditDropOffLocation}
          deleteDropOffLocation={deleteDropOffLocation}
          handleConfirmDropOffButton={handleConfirmDropOffButton}
          dropOffLocations={dropOffLocations}
          selectedDropOffLocation={selectedDropOffLocation}
          getDropOffLocationsIsRequesting={getDropOffLocationsIsRequesting}
          refine={refine}
          selectedLocation={selectedLocation}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          updateSelectedLocation={updateSelectedLocation}
          cartErrorMessage={cartErrorMessage}
          deliveryToAddress={deliveryToAddress}
          deepLinkParameters={deepLinkParameters}
          validateGlobalCart={validateGlobalCart}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          guestDeliveryFormInitialAddress={guestDeliveryFormInitialAddress}
          setGuestDeliveryAddressFormInitialValues={
            setGuestDeliveryAddressFormInitialValues
          }
          appConfig={appConfig}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
          getMenu={getMenu}
          emptyCart={emptyCart}
        />
      </Dialog>

      <OrderingClosedContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  orderSettingReducer: state.orderSettingReducer,
  locations: state.locationsReducer.locations,
  deliveryAddressesList: state.deliveryAddressesReducer.deliveryAddressesList,
  dropOffLocationReducer: state.dropOffLocationReducer,
  requestingGetLocations: state.locationsReducer.requestingGetLocations,
  isAuthenticated: state.authReducer.isAuthenticated,
  isGuestSession: state.authReducer.isGuestSession,
  currentLocation: state.locationsReducer.currentLocation,
  orderHistory: state.myTransactionsReducer.myTransactions,
  cartReducer: state.cartReducer,
  orderingClosedInAllLocations:
    state.locationsReducer.orderingClosedInAllLocations,
  deliveryToAddress: state.deliveryAddressesReducer.deliveryToAddress,
  deepLinkParameters: state.deepLinkReducer.parameters,
  guestDeliveryFormInitialAddress:
    state.deliveryAddressesReducer.guestDeliveryFormInitialAddress,
  appConfig: state.authReducer.appConfig,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    ...actions,
    setDeliveryAddressesFormIsVisible,
    validateGlobalCart,
    updateCartWithUnavailbleProducts,
    emptyCart,
    setIsEditDropOffLocation,
    deleteDropOffLocation,
    setDropOffLocationFormIsVisible,
    getFirstDropOffSelection,
    restoreSelectedLocation,
    setGuestDeliveryAddressFormInitialValues,
    validateCartForLocation,
    resetGuestSession,
    getMenu
  })(
    appConfigFile.isGlobalSearchEnabled
      ? connectSearchBox(OrderSettingModalContainer)
      : OrderSettingModalContainer
  )
);
