import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/Buttons';
import { Title1 } from '../../../components/Typography';

const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 28px;
  grid-gap: 5px;
  margin-bottom: 25px;
`;

const MessageTitle = styled(Title1)`
  color: ${(props) => props.theme.productCustomizerTitleFontColor};
  font-weight: bold;
  font-style: italic;
  letter-spacing: normal;
`;

const CancelIconWrapper = styled.div`
  /* background-color: green; */
`;
const CancelIcon = styled.i`
  display: block;
  cursor: pointer;
  font-size: 28px;
  color: rgba(81, 78, 89, 0.63);
  z-index: 99;
  &:hover {
    color: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const InfoIcon = styled.i`
  padding-right: 15px;
`;

const Disclaimer = styled.div`
  /* display: flex; */
  align-items: center;
`;

const UnavailableItemList = styled.ul`
  margin: 10px 0 0 ${(props) => (props.modalView ? '20px' : '46px')};
`;

const UnavailableMessage = styled.div`
  margin: 10px 0 0 30px;
`;

const ProceedButtonWrapper = styled.div`
  background-color: #fff;
  width: 240px;
  margin: 20px 0 20px 160px;

  @media (max-width: 768px) {
    margin: 20px 0 20px 20%;
  }

  @media (max-width: 445px) {
    margin: 20px 0 20px 0;
    width: 75px;
  }
`;

const ProceedButton = styled(PrimaryButton)`
  width: 240px;
  color: ${(props) => props.theme.productCustomizerTitleFontColor};
  margin-bottom: 25px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: normal;
  @media (max-width: 640px) {
    background-color: ${(props) => props.theme.addToCartButtonBackgroundColor};
    height: 60;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
    width: 120px;
  }

  @media (max-width: 445px) {
    width: 75px;
    height: 50;
  }
`;

export default function DefaultUnavailableItems({
  items,
  productName,
  modalView,
  onCancel,
  onProceed,
}) {
  return (
    <div>
      {modalView && (
        <HeadingWrapper>
          <MessageTitle>WE ARE MISSING SOME INGREDIENTS</MessageTitle>
          <CancelIconWrapper>
            <CancelIcon
              className="fas fa-times-circle"
              size="xl"
              onClick={onCancel}
            />
          </CancelIconWrapper>
        </HeadingWrapper>
      )}
      <Disclaimer>
        {!modalView && <InfoIcon className="fal fa-exclamation-circle" />}
        <div>{`The following item${
          items.length > 1 ? 's are' : ' is'
        } currently unavailable in ${productName}: `}</div>
      </Disclaimer>
      <UnavailableItemList modalView={modalView}>
        {items.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </UnavailableItemList>
      {modalView ? (
        <ProceedButtonWrapper>
          <ProceedButton onClick={onProceed}>I'M GOOD WITHOUT IT</ProceedButton>
        </ProceedButtonWrapper>
      ) : (
        <UnavailableMessage>
          Check for other available options.
        </UnavailableMessage>
      )}
    </div>
  );
}
