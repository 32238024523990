import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useSpecialHours from '../../customHooks/useSpecialHours';
import Dialog from '../../components/Dialog/Dialog';
import Select from '../../components/Select';
import { Title1, Callout, Body } from '../../components/Typography';
import { useSelector, useDispatch } from 'react-redux';
import {
  PICKUP,
  DELIVERY,
  DROPOFF,
  GET_SPECIAL_HOURS,
} from '../OrderSetting/orderSettingTypes';
import { Link } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import useChildLocations from './customHooks/useChildLocations';
import LocationCustomFormatLabel from '../OrderSetting/OrderSettingModal/components/LocationCustomLabel';
import OrderMethodSelection from './components/OrderMethodSelection';
import OrderFutureSchedule from './components/OrderFutureSchedule';
import appConfig from '../../../app-config';
import {
  RESET_INTER_SELECTED_DATE_TIME,
  RESET_TIME_NO_LONGER_VALID,
} from '../OrderSetting/orderSettingTypes';
import {
  updateInterPickUpDetails,
  updateInterDeliveryDetails,
  updateInterDropOffDetails,
} from '../OrderSetting/orderSettingActions';
import {
  restoreSelectedLocation,
  deleteDropOffLocation,
} from '../DropOff/dropOffLocationActions';
import ChildCard from './components/ChildCard';
import DropOff from './components/DropOff';
import Delivery from './components/Delivery';

const MainDiv = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.24);
`;

const Title = styled(Title1)`
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 5px;
  grid-column-start: 1;
  grid-row-start: 1;
`;

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-rows: 23% 23%;
  grid-gap: 16px;
  padding: 10px;
  @media (max-width: 786px) {
    grid-template-columns: 1fr;
  }
`;

const ParentSelectSection = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-row-start: 2;
`;

const OrderMethodSection = styled.div`
  display: grid;
  grid-column-start: 2;
  grid-row-start: 2;
`;

const CalloutWrapper = styled(Callout)`
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const AddNewButton = styled(Link)`
  margin: 0;
  letter-spacing: 0px;
  text-decoration: none;
  float: right;
`;

const OrderScheduleSession = styled.div`
  grid-column-start: 3;
  grid-row-start: 2;
`;

const LineDivider = styled.hr`
  margin-bottom: 0;
`;

const ChildStoresSection = styled.div`
  padding: 10px 24px;
  overflow-y: scroll;
`;

const ChildCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px 46px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SelectStoreTitle = styled(Title1)`
  margin-bottom: 20px;
  font-weight: 600;
`;

const styles = () => ({
  scrollPaper: {},
  paper: {
    margin: 0,
    maxHeight: '90%',
    overflowY: 'visible',
    width: '1000px',
    maxWidth: '1000px',
  },
});

function ParentChildOrderMethodModal(props) {
  const [parentBusinessId, setParentBusinessId] = useState(null);
  const { businessSpecialHours } = useSpecialHours(parentBusinessId);
  const { childLocations } = useChildLocations(parentBusinessId);

  const {
    interOrderType,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    orderSettingModalIsVisible,
    interSelectedDateTime,
    selectedLocation,
    availableTimes,
    isRequesting,
    unavailableProducts,
  } = useSelector((state) => state.orderSettingReducer);

  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const { locations } = useSelector((state) => state.locationsReducer);
  const { selectedDropOffLocation } = useSelector(
    (state) => state.dropOffLocationReducer
  );

  const dispatch = useDispatch();

  const orderMethodDetails =
    interOrderType === PICKUP
      ? interPickUpDetails
      : interOrderType === DELIVERY
      ? interDeliveryDetails
        ? interDeliveryDetails.deliveryBusiness
          ? interDeliveryDetails.deliveryBusiness
          : interPickUpDetails
        : interDeliveryDetails
      : interOrderType === DROPOFF
      ? interDropOffDetails
      : null;

  useEffect(() => {
    dispatch({
      type: GET_SPECIAL_HOURS,
      payload: businessSpecialHours,
    });
  }, [businessSpecialHours]);

  useEffect(() => {
    if (orderMethodDetails) {
      setParentBusinessId(orderMethodDetails.id);
    }
  }, [orderMethodDetails]);

  const filteredLocations = locations.length
    ? locations.filter(
        (location) => location.accepted_order_types[0] !== 'scan_and_go'
      )
    : [];

  const isFutureScheduleEnabled =
    appConfig.isPickUpEnabled || appConfig.isFutureOrderPickup;

  const isOrderButtonDisabled =
    !orderMethodDetails ||
    !orderMethodDetails.settings?.online ||
    (interOrderType === DROPOFF && selectedDropOffLocation.length === 0);

  const onLocationChange = (location) => {
    setParentBusinessId(location.id);

    dispatch({
      type: RESET_INTER_SELECTED_DATE_TIME,
    });

    dispatch({
      type: RESET_TIME_NO_LONGER_VALID,
    });

    dispatch(deleteDropOffLocation());

    if (interOrderType === PICKUP) {
      dispatch(updateInterPickUpDetails(location, new Date().toString()));
    } else if (interOrderType === DELIVERY) {
      dispatch(updateInterDeliveryDetails(location));
    } else if (interOrderType === DROPOFF) {
      dispatch(updateInterDropOffDetails(location, new Date().toString()));
    }
  };

  return (
    <Dialog
      open={orderSettingModalIsVisible}
      classes={{ scrollPaper: props.classes.scrollPaper }}
      PaperProps={{ classes: { root: props.classes.paper } }}
      onClose={() => {
        if (interOrderType === DROPOFF) {
          dispatch(restoreSelectedLocation());
        }
      }}
    >
      <MainDiv>
        <SectionWrapper>
          <Title>{'Order Method'}</Title>
          <ParentSelectSection>
            <Select
              value={orderMethodDetails}
              options={filteredLocations}
              getOptionLabel={(location) => location.name}
              getOptionValue={(location) => location}
              placeholder="Select a pickup location..."
              maxMenuHeight={200}
              isLocation={true}
              specialHours={businessSpecialHours}
              onChange={(location) => onLocationChange(location)}
              formatOptionLabel={(location) => (
                <LocationCustomFormatLabel
                  location={location}
                  interPickUpDetails={orderMethodDetails}
                  specialHours={businessSpecialHours}
                />
              )}
            />
            <AddNewButton to="/locations" onClick={() => {}}>
              <CalloutWrapper style={{ marginTop: '5px' }}>
                View Map
              </CalloutWrapper>
            </AddNewButton>
          </ParentSelectSection>
          <OrderMethodSection>
            <OrderMethodSelection
              interOrderType={interOrderType}
              orderMethodDetails={orderMethodDetails}
              isAuthenticated={isAuthenticated}
            />
          </OrderMethodSection>
          <OrderScheduleSession>
            {interOrderType === PICKUP && isFutureScheduleEnabled ? (
              <OrderFutureSchedule orderMethodDetails={orderMethodDetails} />
            ) : interOrderType === DELIVERY ? (
              <Delivery
                interDeliveryDetails={interDeliveryDetails}
                interSelectedDateTime={interSelectedDateTime}
                updateLocationChange={(location) => onLocationChange(location)}
                availableTimes={availableTimes}
                specialHours={businessSpecialHours}
                locations={locations}
                isRequesting={isRequesting}
                unavailableProducts={unavailableProducts}
                isAuthenticated={isAuthenticated}
              />
            ) : interOrderType === DROPOFF ? (
              <DropOff
                interDropOffDetails={interDropOffDetails}
                interSelectedDateTime={interSelectedDateTime}
                updateLocationChange={(location) => onLocationChange(location)}
                availableTimes={availableTimes}
                specialHours={businessSpecialHours}
                locations={locations}
                isRequesting={isRequesting}
                unavailableProducts={unavailableProducts}
                isAuthenticated={isAuthenticated}
              />
            ) : null}
          </OrderScheduleSession>
        </SectionWrapper>
        <LineDivider />
        <ChildStoresSection>
          <SelectStoreTitle> Select Store </SelectStoreTitle>
          {!interPickUpDetails &&
          !interDeliveryDetails &&
          !interDropOffDetails ? (
            <Body style={{ marginTop: '24px', marginBottom: '24px' }}>
              {' '}
              Select desired location and order method to browse stores
            </Body>
          ) : (
            <ChildCardWrapper>
              {childLocations && childLocations.length > 0 ? (
                childLocations.map((childLocation) => {
                  if (
                    childLocation.accepted_order_types.includes(interOrderType)
                  )
                    return (
                      <ChildCard
                        childLocation={childLocation}
                        selectedLocation={selectedLocation}
                        interSelectedDateTime={interSelectedDateTime}
                        interPickUpDetails={interPickUpDetails}
                        interDeliveryDetails={interDeliveryDetails}
                        interDropOffDetails={interDropOffDetails}
                        interOrderType={interOrderType}
                        unavailableProducts={unavailableProducts}
                        isAuthenticated={isAuthenticated}
                        isOrderButtonDisabled={isOrderButtonDisabled}
                      />
                    );
                })
              ) : (
                <div>
                  {`There is no restaurant open for ${interOrderType} at this time`}
                </div>
              )}
            </ChildCardWrapper>
          )}
        </ChildStoresSection>
      </MainDiv>
    </Dialog>
  );
}

export default withStyles(styles)(ParentChildOrderMethodModal);
