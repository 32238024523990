import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field } from "formik";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import FormGrid from "../../../components/Form/FormGrid";
// import Switch from '../../../components/Form/Switch';
import Switch from "@material-ui/core/Switch";
import { PrimaryButton, ButtonRow } from "../../../components/Buttons";
import SingleDatePicker from "../../../components/SingleDatePicker";
import moment from "moment";
import appConfig from "../../../../app-config";
import WarningBox from "../../Auth/WarningBox";
import PasswordField from "../../../layout/components/PasswordField";
import { Anchor, Caption1 } from "../../../components/Typography";
import PhoneNumber from "../../../layout/components/PhoneNumber";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: appConfig.styleConfig.switchActiveColor,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#1976D2",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${appConfig.styleConfig.switchNormalColor}`,
    backgroundColor: appConfig.styleConfig.switchNormalColor,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const SwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 0.5fr;
  width: 100%;
  align-items: center;
`;

const FormLayout = styled(FormGrid)`
  grid-gap: 22px;
`;

const TermsConditions = styled(Caption1)`
  font-size: 16px;
  width: 301px;
`;

const styles = (theme) => ({
  input: {
    fontFamily:
      appConfig.appSource === "Aahar"
        ? theme.typography.fontFamily.secondary
        : theme.typography.fontFamily.primary,
  },
});

const isEmail = new RegExp(
  "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@" +
    "[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
);

const countries = [
  { code: "CA", label: "Canada", phone: "1", suggested: true },
  { code: "US", label: "United States", phone: "1", suggested: true },
];

const RegistrationForm = ({
  createAccount,
  isRequesting,
  classes,
  isBirthdayEnabled,
  showReferralCode,
  signUpFailureError,
  setSignupFailError,
  minPasswordLength,
  registrationError,
  clearRegisterationErrorMessage,
}) => {
  const [passwordMaskState, setPasswordMaskState] = useState(true);
  const [backendInlineErrorEmail, setBackendInlineErrorEmail] = useState("");
  const [backendInlineErrorPhone, setBackendInlineErrorPhone] = useState("");
  const [backendInlineErrorType, setBackendInlineErrorType] = useState("");
  const [minLengthCheck, setMinLengthCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [oneSymbolCheck, setOneSymbolCheck] = useState(false);
  const [oneUpperCheck, setOneUpperCheck] = useState(false);
  const [oneLowerCheck, setOneLowerCheck] = useState(false);
  const marketing =
    appConfig.appSource === "madradish_catering" ||
    appConfig.appSource === "madradish"
      ? true
      : false;
  const updateInlineError = useCallback(() => {
    if (
      registrationError &&
      Object.keys(registrationError).length > 0 &&
      !registrationError.details
    ) {
      if (registrationError.message.includes("Email is already")) {
        setBackendInlineErrorEmail(registrationError.message);
        setBackendInlineErrorType("email");
      } else if (registrationError.message.includes("Your SMS number is")) {
        setBackendInlineErrorPhone(registrationError.message);
        setBackendInlineErrorType("phone");
      }
    } else {
      if (backendInlineErrorEmail) {
        setBackendInlineErrorEmail("");
        setBackendInlineErrorType("");
      }
      if (backendInlineErrorPhone) {
        setBackendInlineErrorPhone("");
        setBackendInlineErrorType("");
      }
    }
  }, [registrationError]);
  useEffect(() => {
    updateInlineError();
  }, [updateInlineError]);
  useEffect(() => {
    clearRegisterationErrorMessage();
  }, []);
  // 'Email is already associated to an existing account';
  // 'Your SMS number is used by another account. Please contact support for assistance.';
  const errorsFromServer = {};
  if (
    signUpFailureError === "Email is already associated to an existing account"
  ) {
    errorsFromServer.email =
      "Email cannot be used. Please try a different one.";
  } else if (
    signUpFailureError ===
    "Your SMS number is used by another account. Please contact support for assistance."
  ) {
    errorsFromServer.phone_number =
      "Phone number cannot be used. Please try a different one.";
  } else if (signUpFailureError) {
    errorsFromServer.others = signUpFailureError;
  }
  // clearRegisterationErrorMessage;
  return (
    <>
      {/* {errorsFromServer.others && (
        <WarningBox message={errorsFromServer.others} />
      )} */}
      <Formik
        initialValues={{ marketing: marketing }}
        enableReinitialize={true}
        validate={(values) => {
          signUpFailureError && setSignupFailError(null);
          let errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (!isEmail.test(values.email)) {
            errors.email = "Please put a valid email format";
            if (
              registrationError &&
              Object.keys(registrationError).length > 0
            ) {
              clearRegisterationErrorMessage();
            }
          }
          if (!values.first_name) {
            errors.first_name = "Required";
          }
          if (!values.last_name) {
            errors.last_name = "Required";
          }
          if (!values.phone_number) {
            errors.phone_number = "Required";
          }
          if (!values.password) {
            errors.password = "Required";
          }
          if (values.password.length < minPasswordLength) {
            errors.password = `Password must be a minimum of ${minPasswordLength} characters`;
            setMinLengthCheck(false);
          } else {
            setMinLengthCheck(true);
          }
          if (!new RegExp("[^A-Za-z0-9]").test(values.password)) {
            errors.password = "Password must contain at least one symbol";
            setOneSymbolCheck(false);
          } else {
            setOneSymbolCheck(true);
          }
          if (!new RegExp("[0-9]").test(values.password)) {
            errors.password = "Password must contain at least one digit";
            setOneNumberCheck(false);
          } else {
            setOneNumberCheck(true);
          }
          if (!new RegExp("(?=.*[a-z])").test(values.password)) {
            errors.password =
              "Password must contain at least one lowercase letter";
            setOneLowerCheck(false);
          } else {
            setOneLowerCheck(true);
          }
          if (!new RegExp("(?=.*[A-Z])").test(values.password)) {
            errors.password =
              "Password must contain at least one uppercase letter";
            setOneUpperCheck(false);
          } else {
            setOneUpperCheck(true);
          }
          if (Object.keys(errors).length > 0 && errors.email) {
            clearRegisterationErrorMessage();
          }
          return errors;
        }}
        onSubmit={(values) => {
          createAccount({
            first_name: values.first_name,
            last_name: values.last_name,
            password: values.password,
            phone_number: values.phone_number,
            marketing: values.marketing,
            birthdate: values.birthdate
              ? moment(values.birthdate).format("YYYY-MM-DD")
              : "",
            email: values.email,
            referral_code: values.referral_code,
            company_name: values.company_name,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                id="RegistrationForm-Email-Field"
                label="Email"
                name="email"
                type="email"
                autoComplete="username"
                value={values.email}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                error={
                  (touched.email && errors.email) ||
                  (backendInlineErrorEmail &&
                    backendInlineErrorType === "email")
                }
                helperText={errors.email || backendInlineErrorEmail}
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
                InputProps={{
                  className: classes.input,
                }}
                autoFocus
              />
              <PasswordField
                id="RegistrationForm-PasswordField"
                label="Choose a password"
                name="password"
                autoComplete="off"
                value={values.password}
                showPassword={passwordMaskState}
                setShowPassword={setPasswordMaskState}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                classes={classes}
                helperText={touched.password && errors.password}
                error={touched.password && errors.password ? true : false}
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
                InputProps={{
                  className: classes.input,
                }}
                complexityProps={{
                  minLengthCheck,
                  oneNumberCheck,
                  oneSymbolCheck,
                  oneUpperCheck,
                  oneLowerCheck,
                  minPasswordLength,
                  marginTop: "-12px",
                }}
              />
              <TextField
                id="RegistrationForm-FirstNameField"
                label="First Name"
                name="first_name"
                type="text"
                autoComplete="given-name"
                value={values.first_name}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                error={touched.first_name && errors.first_name ? true : false}
                helperText={touched.first_name && errors.first_name}
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="RegistrationForm-LastNameField"
                label="Last Name"
                name="last_name"
                type="text"
                autoComplete="family-name"
                value={values.last_name}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                error={touched.last_name && errors.last_name ? true : false}
                helperText={touched.last_name && errors.last_name}
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
              />
              {appConfig.appSource === "madradish_catering" ? (
                <TextField
                  id="RegistrationForm-CompanyNameField"
                  label="Company Name - Optional"
                  name="company_name"
                  type="text"
                  value={values.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.input,
                    required: false,
                  }}
                />
              ) : null}
              {isBirthdayEnabled ? (
                <Field
                  id="RegistrationForm-BirthdateField"
                  name="birthdate"
                  component={SingleDatePicker}
                  label="Birthday - Optional"
                  clearable
                  disableFuture
                  openTo="year"
                  format={"dd/MM/yyyy"}
                  views={["year", "month", "date"]}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={handleChange}
                  value={values.birthdate}
                />
              ) : null}
              {showReferralCode ? (
                <TextField
                  id="RegistrationForm-ReferralCode"
                  label="Referral Code - Optional"
                  name="referral_code"
                  type="text"
                  value={values.referral_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.input,
                    required: false,
                  }}
                />
              ) : null}
              <PhoneNumber
                countries={countries}
                name="phone_number"
                label="Phone Number"
                id="RegistrationForm-PhoneNumberField"
                setFieldValue={setFieldValue}
                InputProps={{
                  className: classes.input,
                }}
                handlePhone={(value) => {
                  setFieldValue("phone_number", value);
                  clearRegisterationErrorMessage();
                }}
                handleBlur={handleBlur}
                required
                // error={
                //   touched.phone_number && errors.phone_number ? true : false
                // }
                // helperText={touched.phone_number && errors.phone_number}
                error={
                  (touched.phone_number && errors.phone_number) ||
                  (backendInlineErrorPhone &&
                    backendInlineErrorType === "phone")
                }
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
                helperText={errors.email || backendInlineErrorPhone}
              />
              <SwitchWrapper>
                <div>
                  {appConfig.appSource === "iQ"
                    ? "Email communication"
                    : "Email me deals and offers"}
                </div>
                <div>
                  <IOSSwitch
                    name="marketing"
                    checked={values.marketing}
                    onChange={handleChange}
                  />
                </div>
              </SwitchWrapper>
              <TermsConditions>
                By signing up you agree to our
                <br />
                <Anchor
                  target="_blank"
                  href="https://smoothcommerce.tech/user-terms/terms.html"
                >
                  Terms & Conditions
                </Anchor>
                &nbsp; and &nbsp;
                <Anchor
                  target="_blank"
                  href="https://smoothcommerce.tech/privacy.html"
                >
                  Privacy Policy
                </Anchor>
                &nbsp;
              </TermsConditions>
              <ButtonRow justifyContent="center" style={{ marginTop: "10px" }}>
                <PrimaryButton
                  id="RegistrationForm-SubmitButton"
                  type="submit"
                  disabled={
                    isRequesting ||
                    !values.email ||
                    !values.password ||
                    !values.first_name ||
                    !values.last_name ||
                    !values.phone_number
                  }
                  buttonWidth="240px"
                  buttonHeight="56px"
                  buttonBorderRadius="10px"
                  fontWeight="600"
                >
                  Continue
                </PrimaryButton>
              </ButtonRow>
            </FormLayout>
          </form>
        )}
      </Formik>
    </>
  );
};

export default withStyles(styles)(RegistrationForm);
