import {
  DELIVERY_ADDRESSES_FORM_IS_VISIBLE,
  SAVE_DELIVERY_ADDRESS_REQUEST,
  SAVE_DELIVERY_ADDRESS_SUCCESS,
  GET_DELIVERY_ADDRESSES_SUCCESS,
  GET_DELIVERY_ADDRESSES_REQUEST,
  SET_DELIVERY_ADDRESSES_FORM_INITIAL_VALUES,
  UPDATE_DELIVERY_ADDRESS_REQUEST,
  UPDATE_DELIVERY_ADDRESS_SUCCESS,
  RESET_DELIVERY_ADDRESSES_REDUCER,
  DELETE_DELIVERY_ADDRESS_REQUEST,
  DELETE_DELIVERY_ADDRESS_SUCCESS,
  DELIVERY_TO_ADDRESS_ENABLED,
  DELIVERY_TO_ADDRESS_DISABLED,
  SET_GUEST_DELIVERY_ADDRESS_FORM_INITIAL_VALUES,
} from './deliveryAddressesTypes';
import api from '../../api';
import { createAlert } from '../Alert/alertActions';
import formatedBEErrorMessage from '../../utils/formatedBEErrorMessage';
import { getMarketplaceDeliveryLocations } from '../OrderSetting/orderSettingActions';
export function clearDeliveryAddressErrorMessage() {
  return function(dispatch) {
    dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS_ERROR_MESSAGE',
      errors: {},
    });
  };
}
export function setDeliveryAddressesFormIsVisible(isVisible) {
  return {
    type: DELIVERY_ADDRESSES_FORM_IS_VISIBLE,
    isVisible,
  };
}

export function getDeliveryAddresses() {
  return function(dispatch) {
    dispatch({ type: GET_DELIVERY_ADDRESSES_REQUEST });
    api.get(`/customer/addresses`).then((response) => {
      dispatch({
        type: GET_DELIVERY_ADDRESSES_SUCCESS,
        deliveryAddressesList: response.data.data,
      });
    });
  };
}

export function saveDeliveryAddress(deliveryAddress) {
  return function(dispatch, getState) {
    const { deliveryToAddress } = getState().deliveryAddressesReducer;
    dispatch({ type: SAVE_DELIVERY_ADDRESS_REQUEST });
    dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS_ERROR_MESSAGE',
      errors: {},
    });
    api
      .post(`/customer/addresses`, deliveryAddress, {
        ignore_interception: true,
      })
      .then((res) => {
        dispatch(
          setSelectedDeliveryAddress({
            ...deliveryToAddress,
            id: res.data.data.customer_address_id,
          })
        );
        dispatch(getDeliveryAddresses());
        dispatch(
          getMarketplaceDeliveryLocations({
            ...deliveryToAddress,
            id: res.data.data.customer_address_id,
          })
        );
        dispatch(
          createAlert({
            type: 'success',
            message: 'Delivery address saved successfully',
          })
        );
        dispatch({ type: SAVE_DELIVERY_ADDRESS_SUCCESS });
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_DELIVERY_ADDRESS_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}

export function setDeliveryAddressesFormInitialValues(deliveryAddress) {
  return {
    type: SET_DELIVERY_ADDRESSES_FORM_INITIAL_VALUES,
    deliveryAddress,
  };
}

export function setGuestDeliveryAddressFormInitialValues(guestDeliveryAddress) {
  return {
    type: SET_GUEST_DELIVERY_ADDRESS_FORM_INITIAL_VALUES,
    guestDeliveryAddress,
  };
}

export function updateDeliveryAddress(deliveryAddress, id) {
  return function(dispatch, getState) {
    const { deliveryToAddress } = getState().deliveryAddressesReducer;
    dispatch({ type: UPDATE_DELIVERY_ADDRESS_REQUEST });
    dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS_ERROR_MESSAGE',
      errors: {},
    });
    api
      .patch(`/customer/addresses/${id}`, deliveryAddress, {
        ignore_interception: true,
      })
      .then((res) => {
        dispatch(
          setSelectedDeliveryAddress({
            ...deliveryToAddress,
            id: res.data.data.customer_address_id,
          })
        );
        dispatch(
          getMarketplaceDeliveryLocations({
            ...deliveryToAddress,
            id: res.data.data.customer_address_id,
          })
        );
        dispatch(getDeliveryAddresses());
        dispatch(
          createAlert({
            type: 'success',
            message: 'Delivery address updated successfully',
          })
        );
        dispatch({ type: UPDATE_DELIVERY_ADDRESS_SUCCESS });
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_DELIVERY_ADDRESS_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}

export function resetDeliveryAddressesReducer() {
  return {
    type: RESET_DELIVERY_ADDRESSES_REDUCER,
  };
}

export function deleteDeliveryAddress(deliveryAddressId) {
  return function(dispatch) {
    dispatch({ type: DELETE_DELIVERY_ADDRESS_REQUEST });
    api.delete(`/customer/addresses/${deliveryAddressId}`).then((response) => {
      dispatch({ type: DELETE_DELIVERY_ADDRESS_SUCCESS });
      dispatch(getDeliveryAddresses());
      dispatch(
        createAlert({
          type: 'success',
          message: 'Address Deleted Successfully',
        })
      );
    });
  };
}

export function deliverToThisAddress(deliveryAddress, isDisabled) {
  return function(dispatch) {
    if (isDisabled) {
      dispatch({ type: DELIVERY_TO_ADDRESS_DISABLED });
    } else {
      dispatch({
        type: DELIVERY_TO_ADDRESS_ENABLED,
        deliveryToAddress: deliveryAddress,
      });
    }
  };
}

export function setSelectedDeliveryAddress(
  deliveryAddress,
  deliveryAddressToBeDisplayed
) {
  return function(dispatch) {
    dispatch({
      type: DELIVERY_TO_ADDRESS_ENABLED,
      deliveryToAddress: deliveryAddress,
      deliveryAddressToBeDisplayed: deliveryAddressToBeDisplayed || null,
    });
  };
}
