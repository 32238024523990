import {
  UPDATE_INTER_ORDER_TYPE,
  PICKUP,
  DELIVERY,
  DROPOFF,
  UPDATE_INTER_PICKUP_DETAILS,
  UPDATE_INTER_DELIVERY_DETAILS,
  UPDATE_INTER_DROPOFF_DETAILS,
  UPDATE_AVAILABLE_TIMES,
  GET_DATE_TIMES,
  UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE,
  CONFIRM_PICKUP_DETAILS,
  UPDATE_INTER_SELECTED_DATE_TIME,
  RESET_INTER_SELECTED_DATE_TIME,
  UPDATE_INTER_DELIVERY_DETAILS_REQUEST,
  UPDATE_INTER_PICKUP_DETAILS_REQUEST,
  UPDATE_INTER_DROPOFF_DETAILS_REQUEST,
  CONFIRM_DELIVERY_DETAILS,
  CONFIRM_DROPOFF_DETAILS,
  RESET_DATE_TIMES,
  RESET_ORDER_SETTING_REDUCER,
  CHANGE_PICKUP_LOCATION,
  HIDE_TOOL_TIP,
  RESET_ORDER_SETTING_TIME_SETTINGS,
  ASAP,
  RESET_TIME_NO_LONGER_VALID,
  SET_ORDERING_CLOSED_MODAL_IS_VISIBLE,
  RESET_UNAVAILABLE_PRODUCTS,
  GET_SPECIAL_HOURS,
  INTER_DELIVERY_ERROR_MESSAGE,
  GET_CHILD_LOCATIONS,
  SET_CHILD_LOCATON,
  CHANGE_PICKUP_LOCATION_FROM_LOCATIONS_PAGE,
  UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE_FOR_LOCATIONS_PAGE,
  UPDATE_ORDER_TYPE,
  SET_SELECTED_LOCATION,
  GET_MARKETPLACE_DELIVERY_LOCATIONS,
  CONFIRM_MARKETPLACE_DELIVERY_DETAILS,
  GET_MARKETPLACE_DELIVERY_LOCATIONS_REQUEST,
  DELIVERY_TIME_FORM_IS_VISIBLE,
  CONFIRM_MARKETPLACE_DELIVERY_DETAILS_REQUEST,
  CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST,
  CHECK_UNAVAILABLE_PRODUCT_IN_CART,
  START_NEW_ORDER_FORM_IS_VISIBLE,
  SET_MARKETPLACE_DELIVERY_SELECTION,
  RESET_MARKETPLACE_DELIVERY_LOCATIONS,
  RESET_INTER_DELIVERY_DETAILS,
  SET_PARENT_LOCATION,
  SET_CHILD_LOCATIONS,
  PICKUP_TIME_FORM_IS_VISIBLE,
  SET_INTER_DELIVERY_DETAILS_ID,
  SET_IS_STORE_DEEP_LINK,
  SET_IS_PARENT_STORE_DEEP_LINK,
  UPDATE_ALL_ORDER_TYPE,
  SET_IS_MENU_DISABLED,
} from './orderSettingTypes';
import moment from 'moment';

export const initialStateLocalStorageOverrides = {
  requestingCartValidation: false,
  validatedUnavailableProducts: null,
};

export const initialState = {
  interOrderType: '',
  interPickUpDetails: null,
  interDeliveryDetails: null,
  interDropOffDetails: null,
  interSelectedDateTime: {
    radioValue: {
      name: null,
      value: null,
    },
    dateTime: {
      date: {
        name: moment().format('ddd MMM DD'),
        value: moment(),
      },
      time: {
        name: '',
        value: '',
      },
    },
  },
  isTimeNoLongerValid: false,
  availableTimes: null,
  orderSettingModalIsVisible: false,
  deliveryDetails: null,
  dropOffDetails: null,
  unavailableProducts: [],
  isRequesting: false,
  selectedDateTime: {
    radioValue: {
      name: null,
      value: null,
    },
    dateTime: {
      date: {
        name: '',
        value: '',
      },
      time: {
        name: '',
        value: '',
      },
    },
  },
  orderType: '',
  dateTimeToShow: { name: 'As Soon As Possible', value: ASAP },
  amountEditOrderConfig: 0,
  toolTipIsHidden: true,
  orderingClosedModalIsVisible: false,
  specialHours: [],
  childLocations: null,
  selectedLocation: null,
  selectedChild: null,
  cartErrorMessage: null,
  orderPacingData: {
    is_asap_slot_available: true,
    future_unavailable_slots: [],
  },
  marketplaceDeliveryLocations: [],
  marketplaceDeliverySelection: null,
  isMarketplaceDeliveryLocationsRequest: false,
  isConfirmMarketPlaceDeliveryDetailsRequest: false,
  deliveryTimeFormIsVisible: false,
  pickUpTimeFormIsVisible: false,
  isUnavailableProductInCartRequest: false,
  startNewOrderFormIsVisible: false,
  parentLocation: null,
  isDeepLink: false,
  isParentDeepLink: false,
  isMenuDisabled: false,
  singleOrderType: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SINGLE_ORDER_TYPE':
      return {
        ...state,
        singleOrderType: action.singleOrderType,
      };
    case UPDATE_INTER_ORDER_TYPE:
      return {
        ...state,
        interOrderType: action.interOrderType,
      };
    case UPDATE_ALL_ORDER_TYPE:
      return {
        ...state,
        interOrderType: action.orderType,
        orderType: action.orderType,
      };
    case UPDATE_INTER_PICKUP_DETAILS:
      return {
        ...state,
        interPickUpDetails: action.interPickUpDetails,
        unavailableProducts: action.unavailableProducts,
        isRequesting: false,
        availableTimes: action.availableTimes,
        orderPacingData: action.orderPacingData,
        cartErrorMessage: action.cartErrorMessage,
      };
    case UPDATE_INTER_DELIVERY_DETAILS:
      return {
        ...state,
        interDeliveryDetails: action.interDeliveryDetails,
        unavailableProducts: action.unavailableProducts,
        interOrderType: action.interOrderType || state.interOrderType,
        isRequesting: false,
      };
    case UPDATE_INTER_DROPOFF_DETAILS:
      return {
        ...state,
        interDropOffDetails: action.interDropOffDetails,
        unavailableProducts: action.unavailableProducts,
        interOrderType: action.interOrderType || state.interOrderType,
        isRequesting: false,
        availableTimes: action.availableTimes,
        orderPacingData: action.orderPacingData,
      };
    case UPDATE_INTER_SELECTED_DATE_TIME:
      return {
        ...state,
        interSelectedDateTime: action.selectedDateTime,
      };
    case SET_ORDERING_CLOSED_MODAL_IS_VISIBLE:
      return {
        ...state,
        orderingClosedModalIsVisible: action.orderingClosedModalIsVisible,
      };
    case RESET_INTER_SELECTED_DATE_TIME:
      return {
        ...state,
        interSelectedDateTime: initialState.interSelectedDateTime,
      };
    case GET_DATE_TIMES:
      return {
        ...state,
        availableTimes: action.payload,
        orderPacingData: action.orderPacingData,
      };
    case RESET_DATE_TIMES:
      return {
        ...state,
        availableTimes: null,
      };
    case UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE:
      return {
        ...state,
        orderSettingModalIsVisible: action.orderSettingModalIsVisible,
        isTimeNoLongerValid: action.isTimeNoLongerValid,
      };
    case CONFIRM_PICKUP_DETAILS:
      return {
        ...state,
        interPickUpDetails: state.interPickUpDetails
          ? state.interPickUpDetails
          : state.selectedLocation,
        selectedDateTime: state.interSelectedDateTime,
        dateTimeToShow: action.dateTimeToShow,
        orderType: state.interOrderType,
        amountEditOrderConfig: action.fromOrderSettings
          ? state.amountEditOrderConfig + 1
          : state.amountEditOrderConfig,
        orderSettingsConfirmed: true,
        cartErrorMessage: null,
      };
    case UPDATE_INTER_DELIVERY_DETAILS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case UPDATE_INTER_PICKUP_DETAILS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case UPDATE_INTER_DROPOFF_DETAILS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case CONFIRM_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: state.interDeliveryDetails
          ? state.interDeliveryDetails
          : state.selectedLocation,
        selectedDateTime: state.interSelectedDateTime,
        dateTimeToShow: action.dateTimeToShow,
        orderType: state.interOrderType,
        amountEditOrderConfig: action.fromOrderSettings
          ? state.amountEditOrderConfig + 1
          : state.amountEditOrderConfig,
        orderSettingsConfirmed: true,
        cartErrorMessage: null,
      };
    case CONFIRM_DROPOFF_DETAILS:
      return {
        ...state,
        dropOffDetails: {
          ...state.interDropOffDetails,
          finalDropOffLocation: action.selectedDropOffLocation,
        },
        selectedDateTime: state.interSelectedDateTime,
        dateTimeToShow: action.dateTimeToShow,
        orderType: state.interOrderType,
        amountEditOrderConfig: action.fromOrderSettings
          ? state.amountEditOrderConfig + 1
          : state.amountEditOrderConfig,
        orderSettingsConfirmed: true,
        cartErrorMessage: null,
      };
    case CHANGE_PICKUP_LOCATION:
      return {
        ...state,
        interPickUpDetails: action.location,
        // interPickUpDetails: action.location,
        // amountEditOrderConfig: action.fromOrderSettings
        //   ? state.amountEditOrderConfig + 1
        //   : state.amountEditOrderConfig,
      };
    case RESET_TIME_NO_LONGER_VALID: {
      return {
        ...state,
        isTimeNoLongerValid: false,
      };
    }
    case HIDE_TOOL_TIP:
      return {
        ...state,
        toolTipIsHidden: true,
      };
    case RESET_ORDER_SETTING_REDUCER:
      return initialState;
    case RESET_ORDER_SETTING_TIME_SETTINGS:
      return {
        ...state,
        dateTimeToShow: action.dateTimeToShow,
        interSelectedDateTime: initialState.interSelectedDateTime,
        selectedDateTime: initialState.selectedDateTime,
      };
    case RESET_UNAVAILABLE_PRODUCTS:
      return {
        ...state,
        unavailableProducts: [],
      };
    case GET_SPECIAL_HOURS:
      return {
        ...state,
        specialHours: action.payload,
      };
    case INTER_DELIVERY_ERROR_MESSAGE:
      return {
        ...state,
        interDeliveryDetails: {
          ...state.interDeliveryDetails,
          errorMessage: action.errorMessage,
        },
      };
    case GET_CHILD_LOCATIONS:
      return {
        ...state,
        childLocations: action.payload,
      };
    case SET_CHILD_LOCATON:
      return {
        ...state,
        selectedChild: action.payload,
      };
    case CHANGE_PICKUP_LOCATION_FROM_LOCATIONS_PAGE:
      return {
        ...state,
        interPickUpDetails:
          action.orderType && action.orderType === PICKUP
            ? action.location
            : null,
        //interDeliveryDetails: action.orderType && action.orderType === DELIVERY ? action.location : null,
        interDropOffDetails:
          action.orderType && action.orderType === DROPOFF
            ? action.location
            : null,
        amountEditOrderConfig: state.amountEditOrderConfig + 1,
        // orderSettingModalIsVisible: false,
        orderType: action.orderType ? action.orderType : PICKUP,
        interOrderType: action.orderType ? action.orderType : PICKUP,
        interSelectedDateTime: {
          radioValue: {
            name: 'As soon as possible',
            value: 'asap',
          },
          dateTime: {
            date: {
              name: '',
              value: '',
            },
            time: {
              name: '',
              value: '',
            },
          },
        },
        selectedDateTime: {
          radioValue: {
            name: 'As soon as possible',
            value: 'asap',
          },
          dateTime: {
            date: {
              name: '',
              value: '',
            },
            time: {
              name: '',
              value: '',
            },
          },
        },
      };
    case UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE_FOR_LOCATIONS_PAGE:
      return {
        ...state,
        amountEditOrderConfig: state.amountEditOrderConfig + 1,
        orderSettingModalIsVisible: action.orderSettingModalIsVisible,
      };
    case UPDATE_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.location,
      };
    case GET_MARKETPLACE_DELIVERY_LOCATIONS:
      return {
        ...state,
        interDeliveryDetails: {
          ...state.interDeliveryDetails,
          ...action.deliveryAddress,
        },
        marketplaceDeliveryLocations: action.marketplaceLocationsOrderSettings,
        isMarketplaceDeliveryLocationsRequest: false,
      };
    case GET_MARKETPLACE_DELIVERY_LOCATIONS_REQUEST:
      return {
        ...state,
        isMarketplaceDeliveryLocationsRequest: true,
      };
    case RESET_MARKETPLACE_DELIVERY_LOCATIONS:
      return {
        ...state,
        deliveryDetails: null,
      };
    case CONFIRM_MARKETPLACE_DELIVERY_DETAILS:
      return {
        ...state,
        interDeliveryDetails: action.interDeliveryDetails,
        isConfirmMarketPlaceDeliveryDetailsRequest: false,
      };
    case CONFIRM_MARKETPLACE_DELIVERY_DETAILS_REQUEST:
      return {
        ...state,
        isConfirmMarketPlaceDeliveryDetailsRequest: true,
      };
    case 'DELIVERY_TIME_FORM_ONLY_VISIBILITY':
      return {
        ...state,
        deliveryTimeFormIsVisible: action.isVisible,
      };
    case DELIVERY_TIME_FORM_IS_VISIBLE:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
        deliveryTimeFormIsVisible: action.isVisible,
        selectedLocation: action.selectedLocation,
        availableTimes: action.availableTimes,
      };
    case 'PICKUP_TIME_FORM_ONLY_VISIBILITY':
      return {
        ...state,
        pickUpTimeFormIsVisible: action.isVisible,
      };
    case PICKUP_TIME_FORM_IS_VISIBLE:
      return {
        ...state,
        pickUpTimeFormIsVisible: action.isVisible,
        selectedLocation: action.location
          ? action.location
          : state.selectedLocation,
      };
    case START_NEW_ORDER_FORM_IS_VISIBLE:
      return {
        ...state,
        startNewOrderFormIsVisible: action.isVisible,
      };
    case CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST:
      return {
        ...state,
        isUnavailableProductInCartRequest: true,
      };
    case CHECK_UNAVAILABLE_PRODUCT_IN_CART:
      return {
        ...state,
        cartErrorMessage: action.cartErrorMessage,
        unavailableProducts: action.unavailableProducts,
        isUnavailableProductInCartRequest: false,
      };
    case SET_MARKETPLACE_DELIVERY_SELECTION:
      return {
        ...state,
        marketplaceDeliverySelection: action.marketplaceDeliverySelection,
      };
    case RESET_INTER_DELIVERY_DETAILS:
      return {
        ...state,
        interDeliveryDetails: null,
        deliveryDetails: null,
        marketplaceDeliveryLocations: [],
      };
    case SET_PARENT_LOCATION:
      return {
        ...state,
        parentLocation: action.parentLocation,
      };
    case SET_CHILD_LOCATIONS:
      return {
        ...state,
        childLocations: action.childLocations,
        // marketplaceDeliveryLocations: null,
      };
    case SET_INTER_DELIVERY_DETAILS_ID:
      return {
        ...state,
        interDeliveryDetails: {
          ...state.interDeliveryDetails,
          id: action.id,
        },
      };
    case UPDATE_AVAILABLE_TIMES:
      return {
        ...state,
        availableTimes: action.availableTimes,
      };
    case SET_IS_STORE_DEEP_LINK:
      return {
        ...state,
        isDeepLink: action.isDeepLink,
      };
    case SET_IS_PARENT_STORE_DEEP_LINK:
      return {
        ...state,
        isParentDeepLink: action.isDeepLink,
      };
    case SET_IS_MENU_DISABLED:
      return {
        ...state,
        isMenuDisabled: action.isMenuDisabled,
      };
    default:
      return state;
  }
}
