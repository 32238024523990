import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import appConfig from '../../../app-config';
const firebase2Clients = [
  'tahinis',
  'Aahar',
  'raahi',
  'thebottledrive',
  'harvestcleaneats',
  'thelingnan',
  'aloette',
];
const environment = process.env.GATSBY_ENV;

const stagingFirebaseConfig = !firebase2Clients.includes(appConfig.appSource)
  ? {
      apiKey: 'AIzaSyALtG59qFL66pYfvE02q8uP4Xb5rjAVDTI',
      authDomain: 'test-firebase-php.firebaseapp.com',
      databaseURL: 'https://test-firebase-php.firebaseio.com',
      projectId: 'test-firebase-php',
      storageBucket: 'test-firebase-php.appspot.com',
      messagingSenderId: '798173583438',
      appId: '1:798173583438:web:a906ecb1fdc18ff8cd8b1f',
      measurementId: 'G-QX0Y754YNK',
    }
  : {
      apiKey: 'AIzaSyCAdMaNffvBJZz6is15Yk9fnsXfblO1G8c',
      authDomain: 'staging-env-2.firebaseapp.com',
      databaseURL: 'https://staging-env-2-default-rtdb.firebaseio.com',
      projectId: 'staging-env-2',
      storageBucket: 'staging-env-2.appspot.com',
      messagingSenderId: '1043032324683',
      appId: '1:1043032324683:web:c82187253ac1211c8a6bce',
    };

const sandboxFirebaseConfig = !firebase2Clients.includes(appConfig.appSource)
  ? {
      apiKey: 'AIzaSyB_7LqOxCkHYUjCjc3En-ywzMjf8KQVk3M',
      authDomain: 'sandbox-orders-cb8e8.firebaseapp.com',
      databaseURL: 'https://sandbox-orders-cb8e8.firebaseio.com',
      projectId: 'sandbox-orders-cb8e8',
      storageBucket: 'sandbox-orders-cb8e8.appspot.com',
      messagingSenderId: '1060394646511',
      appId: '1:1060394646511:web:759f03bafa94311eac941a',
      measurementId: 'G-Y1J5QTFWF0',
    }
  : {
      apiKey: 'AIzaSyC8znDoRlbeSXMFfwLS2HnZSNDzO89VCY8',
      authDomain: 'sandbox-env-2.firebaseapp.com',
      databaseURL: 'https://sandbox-env-2-default-rtdb.firebaseio.com',
      projectId: 'sandbox-env-2',
      storageBucket: 'sandbox-env-2.appspot.com',
      messagingSenderId: '12880788970',
      appId: '1:12880788970:web:f5312b6a1d0676bc008693',
    };

var productionFirebaseConfig = !firebase2Clients.includes(appConfig.appSource)
  ? {
      apiKey: 'AIzaSyBCkJDorGA0Rmj1VdsKgONA-NJBVYId_I0',
      authDomain: 'smoothcommerce-orders.firebaseapp.com',
      databaseURL: 'https://smoothcommerce-orders.firebaseio.com',
      projectId: 'smoothcommerce-orders',
      storageBucket: 'smoothcommerce-orders.appspot.com',
      messagingSenderId: '91076220229',
      appId: '1:91076220229:web:15ee834894d9df9055da2c',
    }
  : appConfig.firebaseEnv2ConfigProduction;

const firebaseConfig =
  environment === 'staging' || environment === 'development'
    ? stagingFirebaseConfig
    : environment === 'sandbox'
    ? sandboxFirebaseConfig
    : productionFirebaseConfig;

const getOrderId = (ref) => {
  return parseInt(ref.path.pieces_[3]);
};
class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.database = app.database;
    this.ref = [];

    this.listen = (uri, func) => {
      let dbPath = uri.replace(firebaseConfig.databaseURL, '');
      if (dbPath !== '') {
        const ref = this.database().ref(dbPath);
        this.ref = [...this.ref, ref];

        ref.on('value', (snapshot) => {
          const value = snapshot.val();

          func && func({ ...value, orderId: getOrderId(ref) });
        });
      }
    };

    this.removeListeners = (func) => {
      this.ref.forEach((ref) => {
        ref.off();
        func && func(getOrderId(ref));
      });
      this.ref = [];
    };
  }
}

export default Firebase;
