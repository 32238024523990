import shouldValidatePostalCode from './shouldValidatePostalCode';
import shouldValidateAddress from './shouldValidateAddress';

const getPaymentMethodInitialValues = () => {
  let initialValues = {
    card_number: '',
    cvv: '',
    expiry_month: '',
    expiry_year: '',
    cardholder_name: '',
  };

  if (shouldValidatePostalCode()) {
    initialValues = {...initialValues, postal_code: ''};
  }
  if (shouldValidateAddress()) {
    initialValues = {...initialValues, street_number: '', street_name: ''};
  }

  return initialValues;
};

export default getPaymentMethodInitialValues;
