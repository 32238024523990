import {
  DELIVERY_ADDRESSES_FORM_IS_VISIBLE,
  SAVE_DELIVERY_ADDRESS_REQUEST,
  SAVE_DELIVERY_ADDRESS_SUCCESS,
  GET_DELIVERY_ADDRESSES_SUCCESS,
  GET_DELIVERY_ADDRESSES_REQUEST,
  SET_DELIVERY_ADDRESSES_FORM_INITIAL_VALUES,
  UPDATE_DELIVERY_ADDRESS_REQUEST,
  UPDATE_DELIVERY_ADDRESS_SUCCESS,
  RESET_DELIVERY_ADDRESSES_REDUCER,
  DELETE_DELIVERY_ADDRESS_REQUEST,
  DELETE_DELIVERY_ADDRESS_SUCCESS,
  SAVE_DELIVERY_ADDRESS_ERROR,
  DELIVERY_TO_ADDRESS_ENABLED,
  DELIVERY_TO_ADDRESS_DISABLED,
  SET_GUEST_DELIVERY_ADDRESS_FORM_INITIAL_VALUES,
} from './deliveryAddressesTypes';

const initialState = {
  deliveryAddressesFormIsVisible: false,
  getDeliveryAddressesIsRequesting: true,
  deliveryAddressesList: [],
  deliveryAddressesFormInitialValues: null,
  deleteDeliveryAddressIsRequesting: false,
  guestDeliveryFormInitialAddress: null,
  deliveryToAddress: null,
  deliveryAddressErrors: {},
  deliveryAddressToBeDisplayed: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DELIVERY_ADDRESS_ERROR_MESSAGE':
      return {
        ...state,
        deliveryAddressErrors: action.errors,
      };
    case DELIVERY_ADDRESSES_FORM_IS_VISIBLE:
      return {
        ...state,
        deliveryAddressesFormIsVisible: action.isVisible,
      };
    case SAVE_DELIVERY_ADDRESS_REQUEST:
      return {
        ...state,
        deliveryAddressIsSaving: true,
      };
    case SAVE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        deliveryAddressIsSaving: false,
        deliveryAddressesFormIsVisible: false,
      };
    case GET_DELIVERY_ADDRESSES_REQUEST:
      return {
        ...state,
        getDeliveryAddressesIsRequesting: true,
      };
    case GET_DELIVERY_ADDRESSES_SUCCESS:
      return {
        ...state,
        getDeliveryAddressesIsRequesting: false,
        deliveryAddressesList: action.deliveryAddressesList,
      };
    case SET_DELIVERY_ADDRESSES_FORM_INITIAL_VALUES:
      return {
        ...state,
        deliveryAddressesFormInitialValues: action.deliveryAddress,
        deliveryAddressesFormIsVisible: true,
      };
    case UPDATE_DELIVERY_ADDRESS_REQUEST:
      return {
        ...state,
        deliveryAddressIsSaving: true,
      };
    case UPDATE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        deliveryAddressIsSaving: false,
        deliveryAddressesFormIsVisible: false,
      };
    case RESET_DELIVERY_ADDRESSES_REDUCER:
      return {
        ...state,
        deliveryAddressesFormInitialValues:
          initialState.deliveryAddressesFormInitialValues,
      };
    case DELETE_DELIVERY_ADDRESS_REQUEST:
      return {
        ...state,
        deleteDeliveryAddressIsRequesting: true,
      };
    case DELETE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        deliveryAddressesFormIsVisible: false,
        deleteDeliveryAddressIsRequesting: false,
        deliveryAddressesFormInitialValues:
          initialState.deliveryAddressesFormInitialValues,
      };
    case SAVE_DELIVERY_ADDRESS_ERROR:
      return {
        ...state,
        deliveryAddressIsSaving: false,
      };
    case DELIVERY_TO_ADDRESS_ENABLED:
      return {
        ...state,
        deliveryToAddress: action.deliveryToAddress,
        deliveryAddressToBeDisplayed: action.deliveryAddressToBeDisplayed,
      };
    case DELIVERY_TO_ADDRESS_DISABLED:
      return {
        ...state,
        deliveryToAddress: null,
      };
    case SET_GUEST_DELIVERY_ADDRESS_FORM_INITIAL_VALUES:
      return {
        ...state,
        guestDeliveryFormInitialAddress: action.guestDeliveryAddress,
      };
    default:
      return state;
  }
}
