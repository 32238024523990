import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from './comboActions';
import {
  validateProduct,
  clearProductToConfigure,
  updatePrice,
} from '../ProductCustomizer/productCustomizerActions';
import ProductCustomizer from '../ProductCustomizer/ProductCustomizer';

const ComboProductCustomizerContainer = ({
  addToCombo,
  validateProduct,
  goBack,
  clearProductToConfigure,
  updatePrice,
  closeComboPopup,
  hidePrice,
  productCustomizerReducer,
  combo,
  ...props
}) => {
  const {flatProduct, isEditing} = productCustomizerReducer;

  useEffect(() => {
    if (flatProduct) {
      updatePrice(flatProduct);
    }
  }, [flatProduct]);

  /* const scrollToFirstOptionWithError = () => {
    const firstOptionIdWithError =
      flatProduct.options &&
      flatProduct.options.find(optionId => {
        const option = flatProduct.optionsById[optionId];
        return option.error;
      });

    document
      .getElementById(`option_${firstOptionIdWithError}`)
      .scrollIntoView({behavior: 'smooth'});
    // scroller.scrollTo(`option_${firstOptionIdWithError}`, {
    //   duration: 800,
    //   delay: 0,
    //   smooth: 'easeInOutQuart',
    //   offset: -90,
    //   containerId: 'productCustomizerContainer',
    // });
  }; */

  const validateProductAndScrollToError = validatedProducts => {
    validateProduct(validatedProducts);
    addProductToCombo();
  };

  const addProductToCombo = () => {
    addToCombo(productCustomizerReducer);
    clearProductToConfigure();
    goBack(true);
  };
  return (
    <ProductCustomizer
      id="Combo-ProductCustomizer"
      productCustomizerReducer={productCustomizerReducer}
      onCancel={closeComboPopup}
      onBackClick={goBack}
      handleAddToCart={validateProductAndScrollToError}
      isCustomizingProductForCombo={true}
      hidePrice={hidePrice}
      addToCartString={!isEditing ? 'Add to Combo' : 'Update Combo'}
      combo={combo}
    />
  );
};

const mapStateToProps = state => ({
  combo: state.comboReducer.combo,
  // comboCustomizerIsVisible: state.comboReducer.comboCustomizerIsVisible,
  // selectedPickUpLocation: state.orderConfigReducer.selectedPickUpLocation,
  // productCustomizerReducer: state.productCustomizerReducer,
});

export default connect(mapStateToProps, {
  ...actions,
  validateProduct,
  clearProductToConfigure,
  updatePrice,
})(ComboProductCustomizerContainer);
