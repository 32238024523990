import React from 'react';
import styled from 'styled-components';
import {Title1} from '../../../../components/Typography';
import ComboItem from './components/ComboItems';
import appConfig from '../../../../../app-config';

const ComboGroupWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
  padding: 32px;
  @media (max-width: 786px) {
    -webkit-overflow-scrolling: touch;
  }

  font-family: ${appConfig.styleConfig.appFontFamily};

  @media (max-width: 786px) {
    padding: 20px;
    padding-bottom: 80px;
  }
`;

const ComboGroupTitle = styled(Title1)`
  margin: 16px 0 24px;
  font-weight: 600;
`;

const BackButton = styled.div`
  font-size: 16px;
  cursor: pointer;

  i {
    margin-right: 8px;
  }

  :hover {
    color: #737a87;
  }
`;

const ComboItemWrapper = styled.div`
  padding: 0 20px;

  @media (max-width: 786px) {
    padding: 0;
  }
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const CancelWrapper = styled.div``;

const ComboGroup = ({
  comboGroup,
  onClickBack,
  onItemClick,
  closeComboPopup,
}) => {
  const productItems = comboGroup.productItems;

  return (
    <ComboGroupWrapper>
      <BackButton id="ComboGroup-BackButton" onClick={() => onClickBack()}>
        <i className="fas fa-chevron-left"></i>
        Back
      </BackButton>
      <CancelWrapper>
        <Cancel id="ComboGroup-CancelButton" onClick={() => closeComboPopup()}>
          <i className="fal fa-times" />
        </Cancel>
      </CancelWrapper>
      <ComboGroupTitle id="ComboGroup-Title">
        {comboGroup.label}
      </ComboGroupTitle>
      <ComboItemWrapper>
        {productItems.map((product, i) => {
          return (
            <ComboItem
              product={product}
              onItemClick={onItemClick}
              key={`combo-item-${comboGroup.combo_item_group_id}-${i}`}
            />
          );
        })}
      </ComboItemWrapper>
    </ComboGroupWrapper>
  );
};

export default ComboGroup;
