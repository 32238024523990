import React, {Fragment} from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import Title1 from '../../../components/Typography/Title1';
import FormGrid from '../../../components/Form/FormGrid';
import TextField from '@material-ui/core/TextField';
import {PrimaryButton, ButtonRow} from '../../../components/Buttons';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? '300px'
        : '100%',
  },
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
  root: {
    maxWidth: 600,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
});

const CouponWrapper = styled.div`
  width: 400px;
  padding: 20px;
  background: white;
  margin: auto;
  display: grid;
  grid-gap: 20px;

  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const FormLayout = styled(FormGrid)`
  grid-gap: 20px;
`;

const CouponModal = ({
  couponsModalIsVisible,
  setCouponsModalIsVisible,
  classes,
  handleCouponSubmit,
  isRequesting,
}) => {
  return (
    <Fragment>
      <Dialog
        id="CouponsModal-Dialog"
        aria-labelledby="coupon"
        aria-describedby="coupon"
        open={couponsModalIsVisible}
        onBackdropClick={() => setCouponsModalIsVisible(false)}
        fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
        fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
        classes={{scrollPaper: classes.scrollPaper}}
        PaperProps={{classes: {root: classes.paper}}}
        scroll="body"
      >
        <CouponWrapper>
          <TitleWrapper>
            <Title1 id="CouponsModal-Title" style={{fontWeight: '600'}}>
            Enter Promo Code
            </Title1>
            <Cancel onClick={() => setCouponsModalIsVisible(false)}>
              <i className="fal fa-times" />
            </Cancel>
          </TitleWrapper>
          <Formik
            initialValues={{coupon: ''}}
            validate={values => {
              let errors = {};
              if (!values.coupon) {
                errors.coupon = 'Required';
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleReset,
              dirty,
              isValid,
              setFieldValue,
            }) => (
              <FormLayout>
                <TextField
                  id="CouponsModal-CouponCode"
                  label="Enter coupon code"
                  name="coupon"
                  type="text"
                  value={values.coupon}
                  onChange={e => {
                    setFieldValue('coupon', e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={touched.coupon && errors.coupon ? true : false}
                  helperText={touched.coupon && errors.coupon}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                />
                <ButtonRow justifyContent="center">
                  <PrimaryButton
                    id="CouponsModal-ApplyCoupon"
                    // type="submit"
                    disabled={isRequesting || !dirty || !isValid}
                    onClick={() => {
                      handleReset();
                      handleCouponSubmit(values.coupon);
                    }}
                  >
                    Apply
                  </PrimaryButton>
                </ButtonRow>
              </FormLayout>
            )}
          </Formik>
        </CouponWrapper>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(CouponModal);
