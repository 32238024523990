import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import 'react-widgets/dist/css/react-widgets.css';
import appConfig from '../../../../../app-config';
import { PICKUP, DELIVERY, DROPOFF } from '../../orderSettingTypes';
import UnavailableMessage from './UnavailableMessage';
import UnavailableMessageWithButton from './UnavailableMessageWithButton';
import { Body, Caption1 } from '../../../../components/Typography';
import {
  renderTextToShow,
  timesAvailable,
  datesAvailable,
  renderMessageText,
  isScheduleRadioDisabledCalculated,
  selectedLocationOrderingHours,
} from '../utils/helper';
import TogglesAndDateTimeSelect from './TogglesAndDateTimeSelect';
import DropOff from './DropOff';
import UnavailableProducts from './UnavailableProducts';

const Wrapper = styled.div`
  display: grid;
`;

const OrderMessage = styled(Caption1)`
  color: #737a87;
`;

const OrderFutureSchedule = ({
  availableTimes,
  selectedLocation,
  interSelectedDateTime,
  selectedDateTime,
  interOrderType,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  confirmPickUpDetails,
  confirmDeliveryDetails,
  interDeliveryDetails,
  interPickUpDetails,
  interDropOffDetails,
  maxFutureOrderDate,
  deliveryBusiness,
  isTimeNoLongerValid,
  resetIsTimeNoLongerValid,
  isLocationOrDelivBusinesssAcceptingOrders,
  specialHours,
  orderPacingData,
  orderMessage,
  isOnline,
  confirmDropOffDetails,
  emptySearchBox,
  deepLinkParameters,
  isMarketplaceDelivery,
  setDeliveryTimeFormIsVisible,
  is_future_order_enabled,
  is_asap_order_enabled,
  onSeeMenu,
  cartReducer,
  setPickUpTimeFormIsVisible,
  selectMarketplacePickUpLocation,
  selectMarketplaceDeliveryLocation,
  emptyCart,
}) => {
  /* const orderStartTime = availableTimes.length
    ? availableTimes.length > 1
      ? availableTimes[1].order_start_time
      : availableTimes[0].order_start_time
    : null; */

  const { errors } = cartReducer;

  const marketplaceSelectedLocation =
    interOrderType !== DELIVERY && selectedLocation
      ? selectedLocation
      : interOrderType === DELIVERY &&
        selectedLocation &&
        selectedLocation.deliveryBusiness
      ? selectedLocation.deliveryBusiness
      : null;

  // const location = marketplaceSelectedLocation
  //   ? marketplaceSelectedLocation
  //   : interOrderType === PICKUP && interPickUpDetails
  //   ? interPickUpDetails
  //   : interOrderType === DELIVERY &&
  //     interDeliveryDetails &&
  //     interDeliveryDetails.deliveryBusiness
  //   ? interDeliveryDetails.deliveryBusiness
  //   : interOrderType === DROPOFF && interDropOffDetails
  //   ? interDropOffDetails
  //   : null;

  const location = selectedLocation;
  const orderEndTime =
    location &&
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);

  const minPrepTime =
    location?.settings?.min_delivery_time ||
    location?.deliveryBusiness?.settings?.min_delivery_time;

  const isAsapRadioDisabled = !is_asap_order_enabled;

  const timesOptions =
    availableTimes && minPrepTime
      ? timesAvailable(availableTimes, minPrepTime, interSelectedDateTime)
      : [];

  const noHoursAvailableForChosenDay =
    location && timesOptions && timesOptions.length === 0;

  const noDatesAvailableForSchedule = location
    ? moment(location.max_future_order_date).isSame(
        moment().format('ddd MMM DD')
      ) ||
      datesAvailable(location?.max_future_order_date, specialHours).length <= 1
    : true;
  const isScheduleRadioDisabled =
    (location && noDatesAvailableForSchedule && noHoursAvailableForChosenDay) ||
    !isOnline;

  const timeOptions = [
    {
      name: 'As soon as possible',
      value: 'asap',
      disabled: isAsapRadioDisabled,
    },
    {
      name: 'Schedule a time',
      value: 'schedule',
      disabled: isScheduleRadioDisabled,
    },
  ];

  const handleRadioChange = (event) => {
    const filteredArray = timeOptions.filter(
      (time) => time.value === event.target.value
    );
    updateInterSelectedDateTime({
      radioValue: {
        name: filteredArray[0].name,
        value: event.target.value,
      },
      dateTime: {
        date: null,
        time: {
          name: '',
          value: '',
        },
      },
    });
    resetIsTimeNoLongerValid();
  };

  const handleDateChange = (date) => {
    updateInterSelectedDateTime({
      radioValue: {
        name: 'Schedule a time',
        value: 'schedule',
      },
      dateTime: {
        date: {
          name: moment(date).format('ddd MMM DD'),
          value: moment(date).toString(),
        },
        time: {
          name: '',
          value: '',
        },
      },
    });

    resetIsTimeNoLongerValid();
    if (location) {
      getTimeIntervalsDate(location.id, date);
    }
  };

  const handleSeeMenuButton = () => {
    if (interOrderType === PICKUP) confirmPickUpDetails(true);
    if (interOrderType === DELIVERY) confirmDeliveryDetails(true);
    if (interOrderType === DROPOFF) confirmDropOffDetails(true);
    if (appConfig.isGlobalSearchEnabled) {
      emptySearchBox();
    }
  };

  const messageToRender =
    location && location.ordering_hours
      ? renderMessageText(
          interOrderType,
          location.ordering_hours,
          location.specialHours
        )
      : null;
  if (
    location &&
    location.specialHours &&
    location.ordering_hours &&
    !location.accepting_orders
  ) {
    return <UnavailableMessage title={messageToRender} />;
  } else {
    return (
      <Wrapper>
        {/* {location && isAsapRadioDisabled && messageToRender && isOnline && (
          <UnavailableMessageWithButton
            title={messageToRender + '***'}
            onClick={() => {
              emptyCart();
              if (isMarketplaceDelivery && interOrderType === DELIVERY) {
                selectMarketplaceDeliveryLocation();
                setDeliveryTimeFormIsVisible(false, selectedLocation);
              } else if (isMarketplaceDelivery && interOrderType !== DELIVERY) {
                selectMarketplacePickUpLocation();
                setPickUpTimeFormIsVisible(false, selectedLocation);
              } else {
                if (interOrderType === DELIVERY) {
                  confirmDeliveryDetails(true);
                } else if (interOrderType === PICKUP) {
                  confirmPickUpDetails(true);
                } else if (interOrderType === DROPOFF) {
                  confirmDropOffDetails(true);
                }
              }
              handleSeeMenuButton();
            }}
            marginTop="5px"
          />
        )} */}
        {!isMarketplaceDelivery &&
          (is_future_order_enabled || is_asap_order_enabled) && (
            <Body style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {renderTextToShow(interOrderType)}
            </Body>
          )}
        {orderMessage ? (
          <OrderMessage>
            Please note: Any future orders must be placed with 24-hour notice in
            advance. Any ASAP orders will be processed and fulfilled for next
            day as we do not offer same-day pickup or delivery.
          </OrderMessage>
        ) : null}

        <TogglesAndDateTimeSelect
          is_future_order_enabled={is_future_order_enabled}
          is_asap_order_enabled={is_asap_order_enabled}
          interSelectedDateTime={interSelectedDateTime}
          selectedDateTime={selectedDateTime}
          maxFutureOrderDate={location.max_future_order_date || null}
          datesOptions={
            location
              ? datesAvailable(location.max_future_order_date, specialHours)
              : []
          }
          onDateChange={(date) => handleDateChange(date)}
          timesOptions={timesOptions}
          onTimeChange={(time) => {
            resetIsTimeNoLongerValid();
            updateInterSelectedDateTime({
              ...interSelectedDateTime,
              dateTime: {
                ...interSelectedDateTime.dateTime,
                time: { name: time, value: time },
              },
            });
          }}
          isTimeNoLongerValid={isTimeNoLongerValid}
          noTimesAvailable={timesAvailable.length}
          isAsapRadioDisabled={isAsapRadioDisabled}
          isScheduleRadioDisabled={isScheduleRadioDisabled}
          handleRadioChange={handleRadioChange}
          orderPacingData={orderPacingData}
          deepLinkParameters={deepLinkParameters}
          onSeeMenu={onSeeMenu}
          noDatesAvailableForSchedule={noDatesAvailableForSchedule}
          noHoursAvailableForChosenDay={noHoursAvailableForChosenDay}
          emptyCart={emptyCart}
        />

        {errors ? <UnavailableProducts cartErrorMessage={errors} /> : null}
      </Wrapper>
    );
  }
};

export default OrderFutureSchedule;
