const getProperOption = (option, subOption) => {
  let ret = {...subOption};

  ret = {
    ...ret,
    maximum_pick: ret.maximum_pick || option.maximum_pick,
    minimum_pick: ret.minimum_pick || option.minimum_pick,
    included_pick: ret.included_pick || option.included_pick,
  };

  return ret;
};

export default getProperOption;
