import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

const Transition = (props) => <Slide direction="down" {...props} />;

const CustomDialog = (props) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  const listener = () => {
    setScrollY(typeof window !== 'undefined' && window.scrollY);
  };

  // useEffect(() => {
  //   if (!props.open) window.scrollTo(0, scrollY);
  // }, [props.open]);
  return (
    <Dialog
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      TransitionComponent={Transition}
      classes={{ scrollPaper: props.classes.scrollPaper }}
      PaperProps={{ classes: { root: props.classes.paper } }}
      {...props}
    >
      {props.children}
    </Dialog>
  );
};

export default CustomDialog;
