import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Headline, Body } from '../../../../components/Typography';
import ConfirmButton from '../../OrderSettingModal/components/ConfirmButton';
import UnavailableMessage from '../../OrderSettingModal/components/UnavailableMessage';
import {
  renderMessageText,
  orderingIsAvailable,
} from '../../OrderSettingModal/utils/helper';
import { getOpeningHour } from '../../utils/helper';
import moment from 'moment';

const LocationCard = styled('div')`
  position: relative;
  box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  border-radius: 9.6px;
  max-width: 497px;
  height: 200px;
  margin-bottom: 1em;
  padding: ${(props) => (props.message ? `0 1em` : `10px 1em 0 1em`)};
  cursor: pointer;
  :hover {
    box-shadow: 0 2px 7px 0 rgba(20, 19, 33, 0.24);
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    width: 385px;
    min-height: 250px;
    padding: ${(props) => (!props.noImage ? '0 1em 1em 1em' : '0 1em 1em 1em')};
    height: ${(props) => (props.message ? ` 240px` : `180px`)};
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    width: 100%;
    min-height: 250px;
    padding: ${(props) => (!props.noImage ? '0 1em 1em 1em' : '0 1em 1em 1em')};
    height: ${(props) => (props.message ? ` 260px` : `180px`)};
  }
`;

const LocationContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr 0.75fr;
`;

const LocationMessageWrapper = styled('div')`
  position: absolute;
  bottom: 5px;
  left: 10px;
  right: 10px;
  display: grid;
  grid-column: 1 / span 3;
  grid-row: 4;
  padding: 5px 0;
`;

const ImageWrapper = styled.div`
  padding-top: 20px;
  height: 100%;
`;

const LocationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-left: ${(props) => (props.noImage ? '0' : '1.25em')};
  width: 255px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  align-items: end;
  justify-content: end;
  grid-column: 3;

  @media (max-width: 480px) {
    grid-column: ${(props) => (props.noImage ? 1 : 2)};
    grid-row: 2;
    margin-top: 0.5em;
    align-items: ${(props) => (props.noImage ? 'start' : 'end')};
    justify-content: start;
    padding-left: 1.25em;
  }
`;

const LocationAddress = styled(Body)`
  display: flex;
  flex-direction: column;
  color: #737a87;
  font-size: 15px;
  margin-top: 9px;
`;

const OrderTypesWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  &:first-child {
    margin-right: 1em;
  }
`;

const TypeDivider = styled('div')`
  width: 4px;
  height: 4px;
  margin: 10px 7px 0 5px;
  background-color: #737a87;
`;

const LocationPickupCard = ({
  location,
  isCurrentStore,
  interOrderType,
  setPickUpTimeFormIsVisible,
  resetInterSelectedDateTime,
  updateSelectedLocation,
  getMenu,
  updateOrderSettingModalIsVisible,
  updateInterSelectedDateTime,
  isMenuDisabled,
  setIsMenuDisabled,
  isSelectedLocationCard,
  selectedLocation,
  emptyCart,
  getCombos,
}) => {
  const [openingHour, setOpeningHour] = useState(null);
  const [isOrderingAvailable, setIsOrderingAvailable] = useState(null);
  const [buttonText, setButtonText] = useState('Order Here');
  const orderingIsAvailableResult =
    location && location.ordering_hours
      ? orderingIsAvailable(location, location.ordering_hours)
      : null;
  const getLocationInfo = useCallback(() => {
    const openingHour =
      location && location.hours ? getOpeningHour(location) : null;

    const orderingAvailable =
      location && location.ordering_hours
        ? orderingIsAvailable(location, location.ordering_hours)
        : null;

    const btnText =
      location.accepting_orders && location.is_order_ahead_enabled
        ? 'Order Here'
        : 'See Menu';

    if (openingHour) setOpeningHour(openingHour);
    if (orderingAvailable) setIsOrderingAvailable(isOrderingAvailable);
    if (btnText) setButtonText(btnText);
  }, [location]);

  useEffect(() => {
    getLocationInfo();
  }, [getLocationInfo]);

  const onLocationSelection = () => {
    if (buttonText === 'See Menu') {
      if (
        !location.is_order_ahead_enabled ||
        (isSelectedLocationCard && !selectedLocation.is_order_ahead_enabled)
      ) {
        emptyCart();
        setIsMenuDisabled(true);
        updateSelectedLocation(location);
        updateInterSelectedDateTime({
          radioValue: {
            name: 'As soon as possible',
            value: 'asap',
          },
          dateTime: {
            date: {
              name: moment().format('ddd MMM DD'),
              value: moment().toString(),
            },
            time: {
              name: '',
              value: '',
            },
          },
        });
        getMenu(location.id, 'pickup');
        getCombos(location.id);
        updateOrderSettingModalIsVisible(false);
      } else {
        const maxFutureOrder = location?.max_future_order_date;
        const isAfter = moment(maxFutureOrder).isAfter(moment());
        if (
          (isAfter && location.is_future_order_enabled) ||
          (location.accepting_orders && location.is_asap_order_enabled)
        ) {
          updateSelectedLocation(location);
          setPickUpTimeFormIsVisible(true, location);
        } else {
          emptyCart();
          updateSelectedLocation(location);
          getMenu(location.id, 'pickup');
          getCombos(location.id);
          setIsMenuDisabled(true);
          updateOrderSettingModalIsVisible(false);
        }
      }
    } else {
      updateSelectedLocation(location);
      resetInterSelectedDateTime();
      setPickUpTimeFormIsVisible(true, location);
      window.scrollTo(0, 0);
    }
  };
  return location ? (
    <LocationCard isCurrentStore={isCurrentStore}>
      <LocationContentWrapper
        data-cy={`marketplace-pickup-location-card-${location?.name}`}
      >
        {location.image_url !== '' ? (
          <ImageWrapper>
            <img
              width="100"
              src={location.image_url}
              style={{ margin: 'auto', display: 'block' }}
            />
          </ImageWrapper>
        ) : null}
        <LocationInfoWrapper noImage={location.image_url === ''}>
          <Headline style={{ paddingTop: '1em' }}>{location.name}</Headline>
          <LocationAddress>
            {location.line1}, {location.city}
            <OrderTypesWrapper>
              {location.accepted_order_types.map((orderType, i) =>
                location.accepted_order_types.length > 1 && i === 0 ? (
                  <>
                    <a>{orderType[0].toUpperCase() + orderType.slice(1)}</a>
                    <TypeDivider />
                  </>
                ) : (
                  <a>{orderType[0].toUpperCase() + orderType.slice(1)}</a>
                )
              )}
            </OrderTypesWrapper>
            {(!location.is_order_ahead_enabled ||
              !location.accepting_orders) && (
              <div>
                <a style={{ fontSize: '14px', marginLeft: '2px' }}>💤 Closed</a>
              </div>
            )}
            {location.is_order_ahead_enabled && location.accepting_orders && (
              <a>Order until {openingHour} </a>
            )}
          </LocationAddress>
        </LocationInfoWrapper>
        <ButtonWrapper noImage={location.image_url === ''}>
          <ConfirmButton
            text={buttonText}
            style={{
              borderRadius: '18px',
              minWidth: '110px',
              fontSize: '14px',
            }}
            inverted={buttonText.toLowerCase().includes('here') ? false : true}
            onClick={onLocationSelection}
            disabled={
              isSelectedLocationCard &&
              !selectedLocation.is_order_ahead_enabled &&
              buttonText !== 'See Menu'
            }
          />
        </ButtonWrapper>
      </LocationContentWrapper>
      <LocationMessageWrapper>
        {!location.is_order_ahead_enabled ||
        (!location.accepting_orders && !location.is_future_order_enabled) ? (
          <UnavailableMessage
            title="Selected location is unable to accept orders at the moment"
            marginTop="-23px"
          />
        ) : !orderingIsAvailableResult || !location.accepting_orders ? (
          <UnavailableMessage
            title={renderMessageText(
              interOrderType,
              location.ordering_hours,
              []
            )}
            marginTop="-20px"
          />
        ) : !location ? (
          <UnavailableMessage title={location.errorMessage} marginTop="-23px" />
        ) : null}
      </LocationMessageWrapper>
    </LocationCard>
  ) : null;
};

export default LocationPickupCard;
