import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  font-size: 14px;
  color: ${props => props.theme.editIconFontColor};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.editIconHoverFontColor};
    transition: all 0.25s ease;
  }
`;

const EditIcon = props => <Icon className="fa fa-pen" {...props} />;

export default EditIcon;
