import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  isConfirmButtonDisabled,
  isLocationAcceptingOrders,
  isScheduleRadioDisabledCalculated,
} from "../../OrderSetting/OrderSettingModal/utils/helper";
import { Caption1 } from "../../../components/Typography";
import { EditIcon } from "../../../components/Icons";
import {
  setDropOffLocationFormIsVisible,
  getFirstDropOffSelection,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
} from "../../DropOff/dropOffLocationActions";
import SkeletonLoading from "../../OrderSetting/OrderSettingModal/components/SkeletonLoading";
import UnavailableMessage from "../../OrderSetting/OrderSettingModal/components/UnavailableMessage";
import OrderFutureSchedule from "./OrderFutureSchedule";
import UnavailableProducts from "../../OrderSetting/OrderSettingModal/components/UnavailableProducts";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const DropOffLocationWrapper = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  display: flex;
`;

const EditDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}}
`;

const SelectTableButton = styled(Caption1)`
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const DropOffTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

function DropOff({
  interDropOffDetails,
  availableTimes,
  specialHours,
  isRequesting,
  unavailableProducts,
}) {
  const dispatch = useDispatch();

  const maxFutureOrderDate =
    interDropOffDetails &&
    interDropOffDetails.settings &&
    interDropOffDetails.settings.max_future_order_date;

  const orderEndTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);

  const isAsapDisabled = !(
    interDropOffDetails &&
    specialHours &&
    isLocationAcceptingOrders(
      interDropOffDetails.accepting_orders,
      specialHours
    ) &&
    !interDropOffDetails.settings.online
  );

  const isScheduleDisabled = !(
    availableTimes &&
    maxFutureOrderDate &&
    orderEndTime &&
    specialHours &&
    !isScheduleRadioDisabledCalculated(
      availableTimes,
      maxFutureOrderDate,
      orderEndTime,
      specialHours,
      interDropOffDetails.settings.online
    )
  );

  const { selectedDropOffLocation } = useSelector(
    (state) => state.dropOffLocationReducer
  );

  const isDisabled =
    !interDropOffDetails ||
    (isAsapDisabled && isScheduleDisabled) ||
    !interDropOffDetails?.settings?.online;

  const locationName =
    selectedDropOffLocation && selectedDropOffLocation.length !== 0
      ? selectedDropOffLocation.map((selection, i) => {
          if (selectedDropOffLocation.length === i + 1) return selection.value;
          return selection.value + ", ";
        })
      : null;

  return (
    <Wrapper>
      {selectedDropOffLocation && selectedDropOffLocation.length !== 0 ? (
        <DropOffLocationWrapper>
          <Input
            value={locationName}
            variant="outlined"
            size="small"
            disabled
            endAdornment={
              <InputAdornment position="end">
                <EditIcon
                  id="DropOff-EditLocation"
                  onClick={() => {
                    dispatch(setDropOffLocationFormIsVisible(true));
                    dispatch(setIsEditDropOffLocation(true));
                  }}
                />
              </InputAdornment>
            }
            style={{ width: "100%" }}
          />
        </DropOffLocationWrapper>
      ) : (
        <DropOffTitleWrapper>
          {!isDisabled ? (
            <div style={{ marginRight: "auto" }}>
              <SelectTableButton
                onClick={() => {
                  dispatch(setDropOffLocationFormIsVisible(true));
                  dispatch(getFirstDropOffSelection(interDropOffDetails.id));
                }}
              >
                + Select Table
              </SelectTableButton>
            </div>
          ) : null}
        </DropOffTitleWrapper>
      )}

      {isRequesting ? (
        <SkeletonLoading />
      ) : (
        <Fragment>
          {interDropOffDetails && availableTimes ? (
            !interDropOffDetails.is_order_ahead_enabled ? (
              <div>
                {/* <UnavailableMessageWithButton
                  onClick={() => {
                    handleConfirmPickupButton();
                    emptySearchBox();
                  }}
                  title="Selected location does not accept order ahead"
                /> */}
              </div>
            ) : (
              <>
                {isDisabled ? (
                  <div>
                    <UnavailableMessage
                      title="Selected location is unable to accept drop-off orders at the moment"
                      marginTop="-25px"
                    />
                  </div>
                ) : null}
                {selectedDropOffLocation.length !== 0 && (
                  <OrderFutureSchedule
                    orderMethodDetails={interDropOffDetails}
                  />
                )}
              </>
            )
          ) : null}

          {unavailableProducts.length ? (
            <UnavailableProducts
              unavailableProducts={unavailableProducts}
              marginTop="-15px"
            />
          ) : null}
        </Fragment>
      )}
    </Wrapper>
  );
}

export default DropOff;
