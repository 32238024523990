import {
  GET_HOME_FEED_REQUEST,
  GET_HOME_FEED_SUCCESS,
  GET_HOME_FEED_FAILURE,
} from './homeFeedTypes';
import api from '../../api';

export function getHomeFeed() {
  return function(dispatch) {
    dispatch({ type: GET_HOME_FEED_REQUEST });
    return api.get(`/feed`).then(
      (response) => {
        dispatch({ type: GET_HOME_FEED_SUCCESS, response: response.data });
      },
      (error) => {
        dispatch({ type: GET_HOME_FEED_FAILURE, error });
      }
    );
  };
}
