import _ from "lodash";

const identifyCartChanges = (newCart, products, combos) => {
  let unavailableProducts = [];
  if (newCart && newCart.length > 0) {
    const keep_products = newCart.map((product) =>
      product.config_products.map((prod) => parseInt(prod.product_id))
    )[0];

    const unavailableItems = products
      .filter(
        (product) =>
          !keep_products.includes(parseInt(product.flatProduct.product_id))
      )
      .map((prod) => ({
        product_id: prod.flatProduct.product_id,
      }));

    const unavailableCombos = combos
      .filter((combo) => !keep_products.includes(combo.id))
      .map((prod) => ({
        product_id: prod.id,
      }));

    unavailableProducts = [...unavailableItems, ...unavailableCombos];
  } else {
    const unavailableItems = products.map(
      (product) => product.flatProduct.product_id
    );

    const unavailableCombos = combos.map((combo) => combo.id);

    unavailableProducts = [...unavailableItems, ...unavailableCombos];
  }

  return unavailableProducts;
};

export default identifyCartChanges;
