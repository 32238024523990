export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_COMBO_TO_CART = 'ADD_COMBO_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const REMOVE_COMBO_FROM_CART = 'REMOVE_COMBO_FROM_CART';
export const INCREMENT_PRODUCT = 'INCREMENT_PRODUCT';
export const DECREMENT_PRODUCT = 'DECREMENT_PRODUCT';

export const VALIDATE_CART_REQUEST = 'VALIDATE_CART_REQUEST';
export const VALIDATE_CART_SUCCESS = 'VALIDATE_CART_SUCCESS';
export const VALIDATE_CART_FAILURE = 'VALIDATE_CART_FAILURE';

export const CHANGE_TIP_PERCENTAGE = 'CHANGE_TIP_PERCENTAGE';
export const RESET_TIP_PERCENTAGE = 'RESET_TIP_PERCENTAGE';

export const CHANGE_SPECIAL_INSTRUCTIONS = 'CHANGE_SPECIAL_INSTRUCTIONS';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const INVALIDATE_CART = 'INVALIDATE_CART';

export const TOGGLE_PRODUCT_IS_FAVOURITED = 'TOGGLE_PRODUCT_IS_FAVOURITED';
export const UNFAVOURITE_BY_FAVOURITE_ID = 'UNFAVOURITE_BY_FAVOURITE_ID';

export const SET_IS_FOR_PREVIEW = 'SET_IS_FOR_PREVIEW';
export const EMPTY_CART = 'EMPTY_CART';

export const INVALID_TIP_AMOUNT = 'INVALID_TIP_AMOUNT';
export const RESET_INVALID_TIP_FLAG = 'RESET_INVALID_TIP_FLAG';
export const UPDATE_CART_PRODUCTS = 'UPDATE_CART_PRODUCTS';
export const INITIALIZE_VALIDATE_CART_WITH_NULL =
  'INITIALIZE_VALIDATE_CART_WITH_NULL';
export const UPDATE_CART_SCREEN = 'UPDATE_CART_SCREEN';
export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';

export const SET_CHECKOUT_AS_GUEST_BUTTON_IS_VISIBLE =
  'SET_CHECKOUT_AS_GUEST_BUTTON_IS_VISIBLE';

export const SET_SHOW_GUEST_PERSONAL_DETAILS_PAGE =
  'SET_SHOW_GUEST_PERSONAL_DETAILS_PAGE';

export const VALIDATE_PERSONAL_DETAILS = 'VALIDATE_PERSONAL_DETAILS';

export const SET_ACCOUNT_FOUND_MODAL_IS_VISIBLE =
  'SET_ACCOUNT_FOUND_MODAL_IS_VISIBLE';

export const SET_GUEST_PERSONAL_DETAILS = 'SET_GUEST_PERSONAL_DETAILS';

export const VALIDATE_GUEST_CART_REQUEST = 'VALIDATE_GUEST_CART_REQUEST';
export const VALIDATE_GUEST_CART_SUCCESS = 'VALIDATE_GUEST_CART_SUCCESS';
export const VALIDATE_GUEST_CART_FAILURE = 'VALIDATE_GUEST_CART_FAILURE';

export const RESET_GUEST_PERSONAL_DETAILS = 'RESET_GUEST_PERSONAL_DETAILS';

export const PLACE_GUEST_ORDER_REQUEST = 'PLACE_GUEST_ORDER_REQUEST';
export const PLACE_GUEST_ORDER_SUCCESS = 'PLACE_GUEST_ORDER_SUCCESS';
export const PLACE_GUEST_ORDER_FAILURE = 'PLACE_GUEST_ORDER_FAILURE';
export const UPDATE_UNAVAILABLE_PRODUCTS = 'UPDATE_UNAVAILABLE_PRODUCTS';