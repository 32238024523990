import React from 'react';
import styled from 'styled-components';

const PlusOrMinusIcon = styled.i`
  font-size: 18px;
  color: ${props => props.theme.plusOrMinusIconFontColor};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.plusMinusHoverColor};
    transition: all 0.25s ease;
  }
`;

const PlusOrMinus = props => (
  <PlusOrMinusIcon className={`fal fa-${props.plusOrMinus}`} {...props} />
);

export const PlusIcon = props => <PlusOrMinus plusOrMinus="plus" {...props} />;

export const MinusIcon = props => (
  <PlusOrMinus plusOrMinus="minus" {...props} />
);
