import styled from 'styled-components';
import appConfig from '../../../app-config';

const Title3 = styled.h3`
  font-size: ${props => props.theme.title3DefaultFontSize};
  color: ${props => props.theme.title3DefaultFontColor};
  font-weight: ${props => props.theme.title3DefaultFontWeight};
  text-transform: ${props => appConfig.appSource === "raahi" ? 'uppercase' : props.textTransform || 'unset'};
  text-align: ${props => props.textAlign || 'inherit'};
  margin: 0;
  font-family: ${props => props.theme.secondaryFontFontFamily};
`;

export default Title3;
