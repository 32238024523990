import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Title3 } from '../../../components/Typography';
import OrderMethodSelection from './components/OrderMethodSelection';
import PickUp from './components/PickUp';
import Delivery from './components/Delivery';
import { PICKUP, DROPOFF, DELIVERY } from '../orderSettingTypes';
import DropOff from './components/DropOff';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 24px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.24);
`;

const CancelWrapper = styled.div``;

const Cancel = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 18px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
  }
`;

const formatLocationsPickup = (locations) => {
  return locations.filter((location) =>
    location.accepted_order_types.includes(PICKUP)
  );
};

const formatLocationsDropOff = (locations) => {
  return locations.filter((location) =>
    location.accepted_order_types.includes(DROPOFF)
  );
};

const OrderSettingModal = ({
  cartItems,
  interOrderType,
  updateInterOrderType,
  locations,
  updateInterPickUpDetails,
  interPickUpDetails,
  deliveryAddressesList,
  updateInterDeliveryDetails,
  interDeliveryDetails,
  availableTimes,
  interSelectedDateTime,
  updateInterSelectedDateTime,
  getTimeIntervalsDate,
  updateOrderSettingModalIsVisible,
  confirmPickUpDetails,
  unavailableProducts,
  resetInterSelectedDateTime,
  resetDateTimes,
  deliveryBusiness,
  isRequesting,
  requestingGetLocations,
  confirmDeliveryDetails,
  setDeliveryAddressesFormIsVisible,
  resetIsTimeNoLongerValid,
  isTimeNoLongerValid,
  currentLocation,
  orderHistory,
  isAuthenticated,
  selectedDateTime,
  hideToolTip,
  toolTipIsHidden,
  nearestLocation,
  handleConfirmPickupButton,
  handleConfirmDeliveryButton,
  specialHours,
  orderPacingData,
  setDropOffLocationFormIsVisible,
  interDropOffDetails,
  updateInterDropOffDetails,
  confirmDropOffDetails,
  getFirstDropOffSelection,
  pickedDropOffLocation,
  setIsEditDropOffLocation,
  deleteDropOffLocation,
  handleConfirmDropOffButton,
  dropOffLocations,
  selectedDropOffLocation,
  getDropOffLocationsIsRequesting,
  refine,
  selectedLocation,
  orderSettingModalIsVisible,
  updateSelectedLocation,
  cartErrorMessage,
  deliveryToAddress,
  deepLinkParameters,
  checkUnavailableProductForPickUp,
  guestDeliveryFormInitialAddress,
  setGuestDeliveryAddressFormInitialValues,
  appConfig,
  cartReducer,
  validateCartForLocation,
  getMenu,
  emptyCart,
}) => {
  // only works for 3 truth assignments
  const onlyOne = (a, b, c) => {
    let truthyCount = !!a + !!b + !!c;
    // Workaround for the prohibited test truthyCount == 1
    return 0 < truthyCount && truthyCount < 2;
  };
  let PickUpTruth = appConfig && appConfig.accepted_order_types.pickup;
  let deliveryTruth = appConfig && appConfig.accepted_order_types.delivery;
  let dropOffTruth = appConfig && appConfig.accepted_order_types.drop_off;

  const isOneMethodOnly = onlyOne(PickUpTruth, deliveryTruth, dropOffTruth);

  useEffect(() => {
    if (
      deepLinkParameters?.orderType === DELIVERY ||
      deepLinkParameters?.orderType === PICKUP ||
      deepLinkParameters?.orderType === DROPOFF
    ) {
      updateInterOrderType(deepLinkParameters.orderType);
    }
  }, [deepLinkParameters]);

  return (
    <Wrapper>
      <Title3 style={{ fontWeight: '600', textAlign: 'center' }}>
        {'HOW WOULD YOU LIKE TO ORDER TODAY?'}
      </Title3>
      {((isAuthenticated && !orderHistory.length) ||
        (!isAuthenticated && nearestLocation) ||
        (isAuthenticated && !orderHistory.length && nearestLocation)) &&
        (interPickUpDetails || interDeliveryDetails || interDropOffDetails) && (
          <CancelWrapper>
            <Cancel
              onClick={() => {
                updateOrderSettingModalIsVisible(false);
              }}
            >
              <i className="fal fa-times" />
            </Cancel>
          </CancelWrapper>
        )}

      {isOneMethodOnly ? null : (
        <OrderMethodSelection
          interOrderType={interOrderType}
          updateInterOrderType={updateInterOrderType}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          resetDateTimes={resetDateTimes}
          deepLinkParameters={deepLinkParameters}
          appConfig={appConfig}
        />
      )}

      {interOrderType === PICKUP ? (
        <PickUp
          locations={formatLocationsPickup(locations)}
          currentLocation={currentLocation}
          updateInterPickUpDetails={updateInterPickUpDetails}
          interPickUpDetails={interPickUpDetails}
          availableTimes={availableTimes}
          interSelectedDateTime={interSelectedDateTime}
          interOrderType={interOrderType}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          confirmPickUpDetails={confirmPickUpDetails}
          unavailableProducts={unavailableProducts}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          isTimeNoLongerValid={isTimeNoLongerValid}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          handleConfirmPickupButton={handleConfirmPickupButton}
          selectedDateTime={selectedDateTime}
          nearestLocation={nearestLocation}
          hideToolTip={hideToolTip}
          toolTipIsHidden={toolTipIsHidden}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          refine={refine}
          selectedLocation={selectedLocation}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          updateSelectedLocation={updateSelectedLocation}
          cartErrorMessage={cartErrorMessage}
          deepLinkParameters={deepLinkParameters}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          appConfig={appConfig}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
          getMenu={getMenu}
          emptyCart={emptyCart}
        />
      ) : interOrderType === DROPOFF ? (
        <DropOff
          locations={formatLocationsDropOff(locations)}
          availableTimes={availableTimes}
          specialHours={specialHours}
          interOrderType={interOrderType}
          setDropOffLocationFormIsVisible={setDropOffLocationFormIsVisible}
          isRequesting={isRequesting}
          requestingGetLocations={requestingGetLocations}
          currentLocation={currentLocation}
          interDropOffDetails={interDropOffDetails}
          updateInterDropOffDetails={updateInterDropOffDetails}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          confirmDropOffDetails={confirmDropOffDetails}
          isTimeNoLongerValid={isTimeNoLongerValid}
          selectedDateTime={selectedDateTime}
          orderPacingData={orderPacingData}
          unavailableProducts={unavailableProducts}
          getFirstDropOffSelection={getFirstDropOffSelection}
          pickedDropOffLocation={pickedDropOffLocation}
          setIsEditDropOffLocation={setIsEditDropOffLocation}
          deleteDropOffLocation={deleteDropOffLocation}
          handleConfirmDropOffButton={handleConfirmDropOffButton}
          dropOffLocations={dropOffLocations}
          selectedDropOffLocation={selectedDropOffLocation}
          getDropOffLocationsIsRequesting={getDropOffLocationsIsRequesting}
          cartErrorMessage={cartErrorMessage}
          deepLinkParameters={deepLinkParameters}
          appConfig={appConfig}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          emptyCart={emptyCart}
        />
      ) : interOrderType === DELIVERY ? (
        <Delivery
          locations={formatLocationsPickup(locations)}
          updateInterPickUpDetails={updateInterPickUpDetails}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          cartItems={cartItems}
          deliveryAddressesList={deliveryAddressesList}
          updateInterDeliveryDetails={updateInterDeliveryDetails}
          interDeliveryDetails={interDeliveryDetails}
          interOrderType={interOrderType}
          interSelectedDateTime={interSelectedDateTime}
          updateInterSelectedDateTime={updateInterSelectedDateTime}
          getTimeIntervalsDate={getTimeIntervalsDate}
          unavailableProducts={unavailableProducts}
          availableTimes={availableTimes}
          deliveryBusiness={deliveryBusiness}
          resetInterSelectedDateTime={resetInterSelectedDateTime}
          resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
          isTimeNoLongerValid={isTimeNoLongerValid}
          isRequesting={isRequesting}
          confirmDeliveryDetails={confirmDeliveryDetails}
          selectedDateTime={selectedDateTime}
          setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
          isAuthenticated={isAuthenticated}
          handleConfirmDeliveryButton={handleConfirmDeliveryButton}
          specialHours={specialHours}
          orderPacingData={orderPacingData}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
          refine={refine}
          cartErrorMessage={cartErrorMessage}
          deliveryToAddress={deliveryToAddress}
          deepLinkParameters={deepLinkParameters}
          guestDeliveryFormInitialAddress={guestDeliveryFormInitialAddress}
          setGuestDeliveryAddressFormInitialValues={
            setGuestDeliveryAddressFormInitialValues
          }
          appConfig={appConfig}
          cartReducer={cartReducer}
          validateCartForLocation={validateCartForLocation}
          checkUnavailableProductForPickUp={checkUnavailableProductForPickUp}
          getMenu={getMenu}
          emptyCart={emptyCart}
        />
      ) : null}
    </Wrapper>
  );
};

export default OrderSettingModal;
