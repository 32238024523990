import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Body,
  Caption1,
  Caption2,
  Title1,
  Callout,
} from '../../../../components/Typography';
import LocationPickupCard from './LocationPickupCard';
import { PICKUP, DELIVERY, DROPOFF } from '../../orderSettingTypes';
import appConfigFile from '../../../../../app-config';
import ConfirmationDialog from '../../../Menu/components/GlobalSearchMenu/components/ConfirmationDialog';
import OrderMethodSelection from '../../OrderSettingModal/components/OrderMethodSelection';
import ViewOnlyCard from './ViewOnlyCard';
import PickUpTimeFormContainer from '../PickUpTimeFormContainer';

const MainDiv = styled.div`
  background: white;
  padding: 19px 36px 32px 36px;
  margin: 0;
  border-bottom: solid 3px #ebebeb;
  margin-top: 5px;
  border-start-start-radius: 5px;

  @media (max-width: 786px) {
    padding: 16px;
    align-items: center;
  }
`;
const LocationsWrapper = styled.div`
  display: grid;
  overflow-y: auto;
  max-height: 450px;
  padding: 19px 36px 0 36px;
  margin-bottom: 5px;
  border-end-end-radius: 5px;

  @media (max-width: 786px) {
    padding: 16px 16px 32px 16px;
    align-items: center;
    width: 100%;
  }
`;
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  width: 100%;
  @media (max-width: 786px) {
    display: block;
  }
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.7em;

  @media (max-width: 786px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 500px) {
    justify-content: center;
    text-align: center;
  }
`;

const Title = styled(Title1)`
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const SelectSectionWrapper = styled.div`
  margin: 0 16px 0 0;
  @media (max-width: 786px) {
    display: block;
    margin: 9px 0;
  }
`;
const StoresWrapper = styled.div`
  display: block;
`;
const AddNewAddressButton = styled(Caption1)`
  margin: 10px 0 0 0;
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const SelectTitleWrapper = styled.div`
  display: block;
  align-items: center;
  align-content: center;

  @media (min-width: 786px) {
    padding-top: 10px;
  }
`;

const Stores = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 46px;
  padding: 0 5px;
  animation: fadeIn 0.5s ease-in-out;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 3em;
    width: 100%;
  }

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    width: 100%;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CalloutWrapper = styled(Callout)`
  display: flex;
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const SignInWrapper = styled(Callout)`
  display: block;
  padding-top: 10px;
  padding-left: 10px;
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const GuestDeliveryForm = styled.div`
  margin-top: 20px;
`;

const NotFoundWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PickupErrorMessage = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: #fbebe5;
  margin-right: 0.5em;
  height: 40px;
  border-radius: 8px;
  margin: 0.5em 0 1em 0;
  padding: 0.5em 1em 1em 1em;

  @media (max-width: 1025px) {
    height: 55px;
  }

  @media (max-width: 555px) {
    height: 75px;
  }

  @media (max-width: 410px) {
    height: 90px;
  }
`;

const MarketplacePickup = ({
  confirmPickUpDetails,
  interPickUpDetails,
  interOrderType,
  updateInterOrderType,
  locations,
  updateInterPickUpDetails,
  parentLocation,
  setParentLocation,
  getChildLocations,
  childLocations,
  isAuthenticated,
  profileSettingsReducer,
  setPickUpTimeFormIsVisible,
  cartReducer,
  updateOrderSettingModalIsVisible,
  resetInterSelectedDateTime,
  isDeepLink,
  isParentDeepLink,
  deepLinkParameters,
  setIsParentStoreDeepLink,
  appConfig,
  resetDateTimes,
  resetIsTimeNoLongerValid,
  isMenuDisabled,
  updateSelectedLocation,
  setIsMenuDisabled,
  getMenu,
  updateInterSelectedDateTime,
  selectedLocation,
  emptyCart,
  clearSelectedLocation,
  getCombos,
  singleOrderType,
}) => {
  const { appSource } = appConfigFile;
  const { email } = profileSettingsReducer;

  const [isCartMessageVisible, setIsCartMessageVisible] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [filteredChildLocations, setFilteredChildLocations] = useState(null);

  useEffect(() => {
    if (deepLinkParameters?.parentId && locations && locations.length > 0) {
      const parentStore = locations.filter(
        (addr) => addr.id === parseInt(deepLinkParameters.parentId)
      );
      if (parentStore.length > 0 && !isParentDeepLink) {
        setParentLocation(parentStore[0]);
        setIsParentStoreDeepLink(true);
      }
    }
  }, [locations]);

  const setChildLocations = useCallback(() => {
    if (parentLocation) {
      getChildLocations(parentLocation.id);
      const strParentLocation = JSON.stringify(parentLocation);

      if (isAuthenticated && !appConfigFile.isDeepLinkClient) {
        localStorage.setItem(`${appSource}-${email}`, strParentLocation);
      } else {
        localStorage.setItem(`${appSource}-guest`, strParentLocation);
      }
    }
  }, [parentLocation]);

  useEffect(() => {
    setChildLocations();
  }, [setChildLocations]);

  useEffect(() => {
    const cachedParentLocation = isAuthenticated
      ? localStorage.getItem(`${appSource}-${email}`)
      : localStorage.getItem(`${appSource}-guest`);

    if (cachedParentLocation && !appConfigFile.isDeepLinkClient) {
      const parsedLocation = JSON.parse(cachedParentLocation);
      setParentLocation(parsedLocation);
    }
    // return () => {
    //   if (
    //     selectedLocation?.accepted_order_types?.length === 1 &&
    //     selectedLocation?.accepted_order_types[0] === 'pickup'
    //   ) {
    //     clearSelectedLocation();
    //   }
    // };
  }, []);

  const getFilteredLocations = useCallback(() => {
    let locations = null;
    // console.log('childLocations=>', childLocations);
    if (childLocations && childLocations.length > 0) {
      locations = childLocations.filter(
        (childLocation) =>
          childLocation.accepted_order_types.includes(PICKUP) &&
          childLocation.id !== selectedLocation?.id
      );
    }
    // console.log('filtered=>', locations);
    if (locations && locations.length > 0) {
      // console.log('111');
      setFilteredChildLocations(locations);
    } else {
      // console.log('222');
      setFilteredChildLocations(null);
    }
  }, [childLocations, selectedLocation]);

  useEffect(() => {
    getFilteredLocations();
  }, [getFilteredLocations]);

  const onConfirmationResponse = () => {
    if (selectedParent) {
      setIsCartMessageVisible(false);
      setParentLocation(selectedParent);
    }
  };

  const isCurrentStore = (store) => {
    if (interPickUpDetails && store) {
      if (interPickUpDetails.id === store.id) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <MainDiv>
        <TitleWrapper>
          <Title>
            {parentLocation
              ? `Ordering from ${parentLocation.city[0] +
                  parentLocation.city.slice(1).toLowerCase()}, ${
                  parentLocation.state
                }`
              : 'Ordering from'}
          </Title>
          {!singleOrderType && (
            <OrderMethodSelection
              interOrderType={interOrderType}
              updateInterOrderType={updateInterOrderType}
              resetInterSelectedDateTime={resetInterSelectedDateTime}
              resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
              resetDateTimes={resetDateTimes}
              deepLinkParameters={deepLinkParameters}
              appConfig={appConfig}
            />
          )}
        </TitleWrapper>
      </MainDiv>
      <LocationsWrapper>
        {selectedLocation?.accepted_order_types.includes('pickup') && (
          <StoresWrapper>
            <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
              {'Current Restaurant'}
            </Title1>
            {/* {!selectedLocation.accepted_order_types.includes('pickup')  ? (
              <PickupErrorMessage>
                <i
                  className="fas fa-exclamation-triangle"
                  style={{
                    fontSize: '16px',
                    color: '#c90e0e',
                    marginRight: '1em',
                  }}
                />
                <a style={{ fontSize: '15px', color: '#000' }}>
                  Sorry, pickup is not available at this moment. Try ordering
                  for delivery instead, or browse our other restaurants.
                </a>
              </PickupErrorMessage>
            ) : null} */}
            <Stores>
              <LocationPickupCard
                isCurrentStore
                location={selectedLocation}
                updateInterPickUpDetails={updateInterPickUpDetails}
                confirmPickUpDetails={confirmPickUpDetails}
                setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
                resetInterSelectedDateTime={resetInterSelectedDateTime}
                updateSelectedLocation={updateSelectedLocation}
                setIsMenuDisabled={setIsMenuDisabled}
                getMenu={getMenu}
                updateInterSelectedDateTime={updateInterSelectedDateTime}
                updateOrderSettingModalIsVisible={
                  updateOrderSettingModalIsVisible
                }
                isMenuDisabled={isMenuDisabled}
                isSelectedLocationCard={true}
                selectedLocation={selectedLocation}
                emptyCart={emptyCart}
                getCombos={getCombos}
              />
            </Stores>
          </StoresWrapper>
        )}
        {!childLocations && (
          <>
            <StoresWrapper>
              <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                Other Restaurants
              </Title1>
              <NotFoundWrapper>
                <i
                  className="fas fa-store"
                  style={{
                    fontSize: '40px',
                    color: '#737a87',
                    marginBottom: '23px',
                  }}
                />
                <Body
                  style={{
                    fontSize: '19px',
                    color: '#737a87',
                    marginBottom: '9px',
                  }}
                >
                  No Results
                </Body>
                <Caption2
                  style={{
                    fontSize: '16px',
                    color: '#737a87',
                    marginBottom: '10px',
                  }}
                >
                  Sorry, we couldn’t find any restaurants that deliver to you at
                  the moment
                </Caption2>
              </NotFoundWrapper>
            </StoresWrapper>
          </>
        )}
        {filteredChildLocations && filteredChildLocations.length !== 0 ? (
          <>
            <StoresWrapper>
              <Title1 style={{ fontWeight: '600', marginBottom: '24px' }}>
                {interPickUpDetails
                  ? 'Other Restaurants Available for Pickup'
                  : 'Restaurants Available for Pickup'}
              </Title1>
              <Stores>
                {filteredChildLocations.map((location) => (
                  <LocationPickupCard
                    key={location?.id}
                    location={location}
                    updateInterPickUpDetails={updateInterPickUpDetails}
                    confirmPickUpDetails={confirmPickUpDetails}
                    setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
                    resetInterSelectedDateTime={resetInterSelectedDateTime}
                    updateSelectedLocation={updateSelectedLocation}
                    setIsMenuDisabled={setIsMenuDisabled}
                    getMenu={getMenu}
                    updateInterSelectedDateTime={updateInterSelectedDateTime}
                    updateOrderSettingModalIsVisible={
                      updateOrderSettingModalIsVisible
                    }
                    isMenuDisabled={isMenuDisabled}
                    emptyCart={emptyCart}
                    getCombos={getCombos}
                  />
                ))}
              </Stores>
            </StoresWrapper>
          </>
        ) : (
          <>
            <Body style={{ color: '#737a87', marginTop: '25px' }}>
              Couldn’t find what you’re looking for? Try Delivery instead.
            </Body>
          </>
        )}
      </LocationsWrapper>
      <PickUpTimeFormContainer />
      <ConfirmationDialog
        open={isCartMessageVisible && isAuthenticated}
        close={() => setIsCartMessageVisible(false)}
        location={interPickUpDetails}
        cart={cartReducer}
        onGoToMenuClick={() => onConfirmationResponse()}
      />
    </>
  );
};

export default MarketplacePickup;
