import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './alertActions';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import appConfig from '../../../app-config';

const styles = theme => ({
  success: {
    backgroundColor: appConfig.styleConfig.alertSuccessBackgroundColour,
    color: appConfig.styleConfig.alertSuccessTextColour,
    fontFamily: appConfig.styleConfig.primaryFontFontFamily,
  },
  error: {
    backgroundColor: '#CD2929',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;

  if (!classes.message) return null;

  return (
    <SnackbarContent
      id="Alert-SnackBarContent"
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={[
        <i
          key="alert"
          aria-label="Close"
          className="fal fa-times"
          onClick={onClose}
        />,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class Alert extends Component {
  state = {
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.alert !== this.props.alert && this.props.alert) {
      this.handleClick();
    }
  }

  handleClick = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <Snackbar
        id="Alert-Snackbar"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={5000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          id="Alert-SnackbarContentWrapper"
          onClose={this.handleClose}
          variant={this.props.alert.type}
          message={this.props.alert.message}
        />
      </Snackbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alert,
  };
}

export default connect(mapStateToProps, actions)(Alert);
