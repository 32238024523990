import productForCartValidation from './productForCartValidation';

export default combos => {
  return combos.map(combo => {
    const configProducts = combo.combo_item_groups.reduce((acc, group) => {
      if (group.productCustomizer) {
        acc = [
          ...acc,
          ...productForCartValidation(group.productCustomizer.flatProduct)
            .config_products,
        ];
      }
      return acc;
    }, []);

    return {
      combo_id: combo.id,
      // special_instruction: combo.instruction,
      config_products: configProducts,
    };
  });
};
