import React from 'react';
import styled from 'styled-components';

export const QuantityCircle = styled.div`
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.hasQuantity ? props.theme.circleCheckIconFontColor : 'inherit'};
  background: ${props =>
    props.hasQuantity
      ? props.theme.circleCheckIconBackgroundColor
      : 'transparent'};
  border: ${props => (!props.hasQuantity ? 'solid 1px #d8d8d8' : 'none')};
  border-radius: 4px;
`;

const QuantityCheck = ({quantity, maximumPick}) => (
  <QuantityCircle hasQuantity={quantity > 0}>
    {quantity > 0 &&
      (maximumPick === 1 ? <i className="fas fa-check"></i> : quantity)}
  </QuantityCircle>
);

export default QuantityCheck;
