import React from 'react';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import {Dialog} from '@material-ui/core';
import {Title2, Body} from '../../../components/Typography';
import {
  ButtonRow,
  PrimaryButton,
  FlatButton,
} from '../../../components/Buttons';

const styles = () => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? '300px'
        : '100%',
  },
});

const Wrapper = styled.div`
  width: 400px;
  padding: 20px;
  background: white;
  margin: auto;
  display grid;
  grid-gap: 15px;
  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
`;

const Cancel = styled(FlatButton)`
  min-width: 0px;
  width: 175px;
  font-weight: 500;
  color: ${props => props.theme.clickableTextColor};

  @media (max-width: 786px) {
    width: ${props => (props.customStyle ? '100%' : '175px')};
  }
`;

const Yes = styled(PrimaryButton)`
  min-width: 0px;
  width: 175px;

  @media (max-width: 786px) {
    width: ${props => (props.customStyle ? '100%' : '175px')};
  }
`;

// @media (max-width: 786px) {
//   width: ${props => (props.customStyle ? '50px' : '175px')};
// }

const ConfirmCloseModal = ({
  confirmCloseModalIsVisible,
  classes,
  handleConfirmClick,
  handleCancelClick,
  body,
  customStyle,
  setDeliveryAddressesFormIsVisible,
  isAuthContainer,
}) => {
  return (
    <Dialog
      id="ConfirmCloseModal-Dialog"
      aria-labelledby="confirm-close-modal"
      aria-describedby="confirm-close-modal"
      open={confirmCloseModalIsVisible}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{scrollPaper: classes.scrollPaper}}
      PaperProps={{classes: {root: classes.paper}}}
      style={{
        height: customStyle && '230px',
        marginTop: customStyle && '20%',
      }}
    >
      <Wrapper customStyle={customStyle}>
        <Title2 id="ConfirmCloseModal-Title">Are You Sure</Title2>
        <Body id="ConfirmCloseModal-Message">
          {body
            ? body
            : 'Are you sure you want to exit? All changes will be unsaved'}
        </Body>
        <ButtonRow
          justifyContent="space-between"
          style={{gridTemplateColumns: customStyle && '1fr 1fr'}}
        >
          <Cancel
            id="ConfirmCloseModal-CancelButton"
            onClick={handleCancelClick}
            customStyle={customStyle}
          >
            Cancel
          </Cancel>
          <Yes
            id="ConfirmCloseModal-YesButton"
            onClick={() => {
              handleConfirmClick();
              if (isAuthContainer) {
                setDeliveryAddressesFormIsVisible(false);
              }
            }}
            customStyle={customStyle}
          >
            Yes
          </Yes>
        </ButtonRow>
      </Wrapper>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmCloseModal);
