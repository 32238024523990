import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {maxOrderDate} from '../modules/OrderSetting/OrderSettingModal/utils/helper';
import {
  PICKUP,
  DELIVERY,
  DROPOFF,
} from '../modules/OrderSetting/orderSettingTypes';
import api from '../api';

async function getBusinessMaxOrderDate(businessId) {
  const {data} = await api.get(`/order/settings?business_id=${businessId}`);
  const maxDate = maxOrderDate(data.data.max_future_order_date).split(' ')[0];
  return maxDate;
}

async function getSpecialHours(businessId, date) {
  const {data} = await api.get(
    `/businesses/${businessId}/special-hours?date=${date}`
  );

  return data;
}

async function getDeliveryMaxOrderDate(addressId) {
  const {data} = await api.get(
    `/order/settings?customer_address_id=${addressId}`
  );

  const maxDate = maxOrderDate(data.data.max_future_order_date).split(' ')[0];
  return maxDate;
}

function useSpecialHours(businessId) {
  const {
    interOrderType,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
  } = useSelector(state => state.orderSettingReducer);

  const [businessSpecialHours, setBusinessSpecialHours] = useState([]);

  const isOrderAheadEnabled =
    interOrderType === PICKUP && interPickUpDetails
      ? interPickUpDetails.is_order_ahead_enabled
      : interOrderType === DELIVERY && interDeliveryDetails
      ? interDeliveryDetails.deliveryBusiness
        ? interDeliveryDetails.deliveryBusiness.is_order_ahead_enabled
        : false
      : interOrderType === DROPOFF && interDropOffDetails
      ? interDropOffDetails.is_order_ahead_enabled
      : false;

  useEffect(() => {
    if (businessId && isOrderAheadEnabled) {
      if (interOrderType === PICKUP || interOrderType === DROPOFF) {
        getBusinessMaxOrderDate(businessId).then(businessMaxDate => {
          getSpecialHours(businessId, businessMaxDate).then(specialHours =>
            setBusinessSpecialHours(specialHours.data)
          );
        });
      } else {
        getDeliveryMaxOrderDate(businessId).then(businessMaxDate =>
          getSpecialHours(businessId, businessMaxDate).then(specialHours =>
            setBusinessSpecialHours(specialHours.data)
          )
        );
      }
    }
  }, [interOrderType, businessId]);

  return {businessSpecialHours};
}

export default useSpecialHours;
