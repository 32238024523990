import React, { useState } from "react";
import styled from "styled-components";
const ErrorBox = styled.div`
  width: ${(props) => props.width || "100%"};
  padding: 12px;
  background-color: #fbebe5;
  position: -webkit-sticky;
  position: sticky;
  top: ${(props) => props.marginTop || "80px"};
  z-index: 2;
  max-height: 245px;
  overflow-y: auto;
  @media screen and (max-width: 790px) {
    top: 70px;
    margin: 0;
  }
`;
// error message
const ErrorMessageWrapper = styled.div`
  margin: 0 auto;
  width: ${(props) => props.messageWidth || "750px"};
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ErrorTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 5px 1fr;
  grid-gap: 5px;
`;
const ErrorIconWrapper = styled.div`
  color: red;
  margin-right: 15px;
`;
const ErrorTitle = styled.div`
  margin-left: 15px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* overflow-wrap: break-word; */
`;
// error detail
const ErrorDetailWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 20px 0 20px;
  width: ${(props) => props.messageWidth || "750px"};
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ErrorList = styled.ul`
  text-align: left;
  margin: 0;
  padding: 0 20px;
`;
const ErrorListItem = styled.li`
  text-align: left;
`;
const DisplayMoreWrapper = styled.div`
  margin: 0 auto;
  width: ${(props) => props.messageWidth || "750px"};
  padding: 5px 0;
  @media screen and (max-width: 600px) {
    text-align: center;
    width: 100%;
  }
`;
const DisplayMore = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  cursor: pointer;
`;

const StickyErrorMessageBox = ({ errors, width, marginTop, messageWidth }) => {
  const [displayMore, setDisplayMore] = useState(false);
  const errorMessage =
    errors && errors.message && errors.message.length > 0
      ? errors.message[0].toUpperCase() + errors.message.substring(1)
      : null;
  return (
    <ErrorBox width={width} marginTop={marginTop}>
      <ErrorMessageWrapper messageWidth={messageWidth}>
        <ErrorTitleWrapper>
          <ErrorIconWrapper>
            <i className="fas fa-exclamation-triangle"></i>
          </ErrorIconWrapper>
          <ErrorTitle>{errorMessage}</ErrorTitle>
        </ErrorTitleWrapper>
      </ErrorMessageWrapper>
      {errors?.details?.cart_changes && (
        <ErrorDetailWrapper messageWidth={messageWidth}>
          <ErrorList>
            {errors?.details?.cart_changes.map(
              (errorItem, index) =>
                (index < 3 || (index > 2 && displayMore)) && (
                  <ErrorListItem>{errorItem}</ErrorListItem>
                )
            )}
          </ErrorList>
        </ErrorDetailWrapper>
      )}
      {errors?.details?.cart_changes?.length > 3 && (
        <DisplayMoreWrapper messageWidth={messageWidth}>
          <DisplayMore
            onClick={() => setDisplayMore((prevState) => !prevState)}
          >
            {displayMore ? "Less" : "More"}
          </DisplayMore>
        </DisplayMoreWrapper>
      )}
    </ErrorBox>
  );
};

export default StickyErrorMessageBox;
