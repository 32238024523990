import React, { useState, useEffect } from 'react';
import logos from '../../assets/logos';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import DynamicCartIconContainer from '../../modules/DynamicCartIcon/DynamicCartIconContainer';
import DesktopMenu from './DesktopMenu';
import MobileNavDrawer from './MobileNavDrawer';
import { Headline, Body } from '../../components/Typography';
import { Link } from 'gatsby';
import { AppConfigContext } from '../Layout';
import appConfig from '../../../app-config';
import { connect } from 'react-redux';
import * as actions from '../../modules/Coupons/couponsActions';
import Coupons from '../../modules/Coupons/CouponsContainer';
import CustomSearchBox from './CustomSearchBox';
import { connectSearchBox } from 'react-instantsearch-dom';

const OuterWrapper = styled.div`
  z-index: 999;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.headerBackgroundColor};
  border-bottom: 1px solid ${(props) => props.theme.headerBorderColor};

  @media (max-width: 786px) {
    height: 70px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin: 0 5%;
  height: 100%;
  padding: ${(props) => props.theme.headerWrapperPadding};
  color: ${(props) => props.theme.headerFontColor};
  @media (max-width: 1200px) {
    margin: 0 16px;
    align-items: center;
    padding: 0;
  }
`;

const DesktopNavWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  //grid-gap: 24px;
  grid-gap: 30px;
  position: relative;
  height: 100%;
  align-items: flex-end;
  // padding: 16px 0 16px 16px;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  //padding: 32px 0 25px 16px;
  //padding: 16px 0 16px 16px;
  padding: ${(props) => props.theme.desktopNavWrapperPadding};

  @media (max-width: 1200px) {
    display: none;
  }
`;

const HeadlineLink = styled(Body)`
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  color: ${(props) => props.theme.headerFontColor};
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  text-transform: ${(props) =>
    appConfig.appSource === 'raahi' ? 'uppercase' : 'unset'};

  &:hover {
    color: ${(props) => props.theme.headerHoverColor};
    transition: all 0.25s ease;
  }
`;

const DesktopNavLink = styled(Link)`
  text-decoration: none;
  font-size: 17px;
  color: ${(props) => props.theme.headerFontColor};
  line-height: 1;
  font-weight: bold;
  text-transform: ${(props) =>
    appConfig.appSource === 'raahi' ? 'uppercase' : 'unset'};

  &:hover {
    color: ${(props) => props.theme.headerHoverColor};
    transition: all 0.25s ease;
  }
`;

const IconClickWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    height: 56px;
    width: 56px;
    padding: 0 12px;
  }
`;

const Icon = styled.i`
  cursor: pointer;
  color: inherit;
  font-size: 24px;
`;

const MobileIconWrapper = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: ${(props) => props.theme.logoWidth};
  margin-bottom: ${(props) => props.theme.logoMargin};
  cursor: pointer;

  @media (max-width: 1200px) {
    margin-top: ${(props) => props.theme.logoMobileMargin};
  }
`;

// var environment = process.env.GATSBY_ENV;

// const pathWrapper = path => {
//   if (environment === 'development') {
//     return path;
//   } else {
//     return `/${appConfig.pathPrefix}${path}`;
//   }
// };

const Header = ({
  logout,
  location,
  isAuthenticated,
  showAuthenticationModal,
  loyalty,
  setCouponsModalIsVisible,
  couponsModalIsVisible,
  showReferralCode,
  refine,
  currentRefinement,
}) => {
  const deepLink = localStorage.getItem('deep-link-url');
  const [searchContent, setSearchContent] = useState(currentRefinement);
  const [isSearching, setIsSearching] = useState(false);
  const [desktopMenuIsVisible, setDesktopMenuIsVisible] = useState(false);
  const [mobileNavDrawerIsVisible, setMobileNavDrawerIsVisible] = useState(
    false
  );

  useEffect(() => {
    if (currentRefinement === '' && isSearching) {
      setSearchContent('');
      setIsSearching(false);
    }
  }, [currentRefinement]);

  const toggleDesktopMenu = () =>
    setDesktopMenuIsVisible(!desktopMenuIsVisible);

  const toggleMobileNavDrawer = () =>
    setMobileNavDrawerIsVisible(!mobileNavDrawerIsVisible);

  return (
    <React.Fragment>
      <AppConfigContext.Consumer>
        {(appConfig) => (
          <OuterWrapper>
            <Wrapper>
              <MobileIconWrapper>
                <IconClickWrapper
                  id="Header-ToggleMobileNav"
                  onClick={toggleMobileNavDrawer}
                >
                  <Icon className="fal fa-bars" />
                </IconClickWrapper>
              </MobileIconWrapper>
              <Logo
                src={appConfig.styleConfig.logoUrl}
                onClick={() => {
                  if (deepLink) {
                    navigate('/' + deepLink);
                  } else {
                    navigate('/');
                  }
                  setSearchContent('');

                  if (appConfig.isGlobalSearchEnabled) {
                    refine('');
                  }
                }}
                //onClick={() => navigate(pathWrapper('/'))}
              />
              {appConfig.isGlobalSearchEnabled ? (
                <CustomSearchBox
                  searchContent={searchContent}
                  setSearchContent={setSearchContent}
                  isSearching={isSearching}
                  setIsSearching={setIsSearching}
                />
              ) : null}

              <DesktopNavWrapper>
                <DesktopMenu
                  open={desktopMenuIsVisible}
                  toggleDesktopMenu={toggleDesktopMenu}
                  logout={logout}
                  closeMenu={() => setDesktopMenuIsVisible(false)}
                  loyalty={loyalty}
                  setCouponsModalIsVisible={setCouponsModalIsVisible}
                  showReferralCode={showReferralCode}
                  appConfig={appConfig}
                />
                {!appConfig.isRedirectedToMenuPage && (
                  // <DesktopNavLink to={pathWrapper('/')}>Home</DesktopNavLink>
                  <DesktopNavLink
                    id="Header-HomeLink"
                    to={deepLink ? '/' + deepLink : '/'}
                  >
                    Home
                  </DesktopNavLink>
                )}
                <DesktopNavLink
                  id="Header-MenuLink"
                  to={deepLink ? '/' + deepLink : '/'}
                >
                  Menu
                </DesktopNavLink>
                {/* <DesktopNavLink to={pathWrapper('/menu')}>Menu</DesktopNavLink> */}
                {isAuthenticated && appConfig.isOffersEnabled && (
                  <DesktopNavLink id="Header-RewardsLink" to="/offers">
                    Rewards
                  </DesktopNavLink>
                  // <DesktopNavLink to={pathWrapper('/offers')}>
                  //   Offers
                  // </DesktopNavLink>
                )}
                {appConfig.appSource !== 'feastify' ? (
                  <DesktopNavLink id="Header-HomeLocations" to="/locations">
                    Locations
                  </DesktopNavLink>
                ) : null}

                {/* <DesktopNavLink to={pathWrapper('/locations')}>
                Locations
              </DesktopNavLink> */}
                {isAuthenticated ? (
                  <HeadlineLink
                    id="Header-AccountLink"
                    onClick={toggleDesktopMenu}
                  >
                    Account
                  </HeadlineLink>
                ) : (
                  <HeadlineLink
                    id="Header-SignInLink"
                    onClick={showAuthenticationModal}
                    style={{ width: '80px' }}
                  >
                    Sign In
                  </HeadlineLink>
                )}
                <DynamicCartIconContainer />
              </DesktopNavWrapper>
              <MobileIconWrapper>
                <DynamicCartIconContainer />
              </MobileIconWrapper>
            </Wrapper>
            <MobileNavDrawer
              mobileNavDrawerIsVisible={mobileNavDrawerIsVisible}
              toggleMobileNavDrawer={() => toggleMobileNavDrawer()}
              logout={logout}
              location={location}
              isAuthenticated={isAuthenticated}
              showAuthenticationModal={showAuthenticationModal}
              loyalty={loyalty}
              setCouponsModalIsVisible={setCouponsModalIsVisible}
              showReferralCode={showReferralCode}
            />
          </OuterWrapper>
        )}
      </AppConfigContext.Consumer>
      <Coupons couponsModalIsVisible={couponsModalIsVisible} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loyalty: state.loyaltyReducer.loyalty,
    couponsModalIsVisible: state.couponsReducer.couponsModalIsVisible,
    showReferralCode: state.authReducer.appConfig?.show_referral_code,
    appConfig: state.authReducer.appConfig,
  };
};

export default connect(
  mapStateToProps,
  actions
)(appConfig.isGlobalSearchEnabled ? connectSearchBox(Header) : Header);
