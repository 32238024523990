import React from "react";
import styled from "styled-components";
import { Subhead } from "../../../../components/Typography";

const LocationUnavailable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 5px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "-16px")};
  align-items: center;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const UnavailableMessage = ({ title, marginTop }) => {
  return (
    <LocationUnavailable marginTop={marginTop}>
      <i className="fal fa-exclamation-circle" />
      <Subhead style={{ fontWeight: "400", marginLeft: '1em' }}>{title}</Subhead>
    </LocationUnavailable>
  );
};

export default UnavailableMessage;
