import React from "react";
import { connect } from "react-redux";
import * as actions from "../productCustomizerActions";
import styled from "styled-components";
import {
  PICKUP,
  DROPOFF,
  DELIVERY,
} from "../../OrderSetting/orderSettingTypes";

const Wrapper = styled.div`
  overflow-x: scroll;
  margin: ${(props) => (props.hasVariant ? "15px 0 24px" : "0")};
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const VariantWrapper = styled.div`
  border-radius: 24px;
  background-color: ${(props) => props.theme.lightGray};
  white-space: nowrap;
  background: ${(props) => props.theme.lightGray};
  width: fit-content;
  margin: 0 auto;
`;

const Variant = styled.div`
  border-radius: 24px;
  align-items: center;
  padding: 10px 20px;
  background: ${(props) =>
    props.selected ? props.theme.primaryButtonBackgroundColor : "#f3f3f3"};
  cursor: pointer;
  color: ${(props) =>
    props.selected ? props.theme.primaryButtonFontColor : "inherit"};
  text-align: center;
  font-weight: 600;
  display: inline-block;

  &:hover {
    color: ${(props) =>
      props.selected ? props.theme.primaryButtonFontColor : "#69768c"};
    transition: all 0.25s ease;
  }
`;

const SizeDrawer = ({
  prices,
  selectedVariantId,
  changeVariant,
  flatProduct,
  interPickUpDetails,
  comboCustomizerIsVisible,
  interDeliveryDetails,
  interOrderType,
  interDropOffDetails,
}) => {
  const selectedVariant = prices.find(
    ({ variant_id }) => variant_id === selectedVariantId
  );

  const selectVariant = (variant) => {
    if (flatProduct.prices.length > 1) {
      changeVariant({
        selectedProduct: flatProduct,
        variantId: variant.variant_id,
        businessId:
          interOrderType === PICKUP && interPickUpDetails
            ? interPickUpDetails.id
            : interOrderType === DROPOFF && interDropOffDetails
            ? interDropOffDetails.id
            : interOrderType === DELIVERY &&
              interDeliveryDetails &&
              interDeliveryDetails.deliveryBusiness
            ? interDeliveryDetails.deliveryBusiness.id
            : null,
      });
    }
  };

  return (
    <Wrapper hasVariant={flatProduct.prices.length > 1}>
      {flatProduct.prices.length > 1 && (
        <VariantWrapper>
          {flatProduct.prices
            .sort((a, b) => a.price - b.price) //sort array in crescent mode using price as the comparing value
            .map((variant) => {
              return (
                <Variant
                  onClick={() => selectVariant(variant)}
                  key={variant.variant_id}
                  selected={selectedVariant.variant_id === variant.variant_id}
                >
                  {variant.name}
                </Variant>
              );
            })}
        </VariantWrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  flatProduct: state.productCustomizerReducer.flatProduct,
  prices: state.productCustomizerReducer.flatProduct.prices,
  selectedVariantId: state.productCustomizerReducer.selectedVariantId,
  interPickUpDetails: state.orderSettingReducer.interPickUpDetails,
  interDropOffDetails: state.orderSettingReducer.dropOffDetails,
  interDeliveryDetails: state.orderSettingReducer.interDeliveryDetails,
  interOrderType: state.orderSettingReducer.interOrderType,
  comboCustomizerIsVisible: state.comboReducer.comboCustomizerIsVisible,
});

export default connect(mapStateToProps, actions)(SizeDrawer);
