import React from "react";
import styled from "styled-components";
import { TextField, InputAdornment } from "@material-ui/core";

const CheckCircle = styled.i`
  cursor: pointer;
  color: #8a93a0;
`;

const CheckText = styled.p`
  width: 100%;
  height: 15px;
  margin: 0 0 0 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a93a0;
`;

const CheckWrapper = styled.div`
  display: flex;
  margin-top: 4px;
`;

const PasswordComplexityWrapper = styled.div`
  margin-top: ${(props) => props.marginTop || "0px"};
`;

const MaskIcon = styled.i`
  cursor: pointer;
  color: #0d0e12;
`;

export default function PasswordField({
  showPassword,
  setShowPassword,
  complexityProps,
  classes,
  ...rest
}) {
  return (
    <>
      <TextField
        {...rest}
        type={showPassword ? "password" : "text"}
        InputProps={{
          className: classes && classes.input,
          endAdornment: (
            <InputAdornment position="end">
              <MaskIcon
                className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          className: classes && classes.input,
          required: false,
        }}
      />
      {complexityProps && (
        <PasswordComplexityWrapper marginTop={complexityProps.marginTop}>
          <CheckWrapper>
            {complexityProps.minLengthCheck ? (
              <CheckCircle className="fas fa-check-circle" />
            ) : (
              <CheckCircle className="far fa-circle" />
            )}
            <CheckText>{`At least ${complexityProps.minPasswordLength} characters`}</CheckText>
          </CheckWrapper>
          <CheckWrapper>
            {complexityProps.oneNumberCheck ? (
              <CheckCircle className="fas fa-check-circle" />
            ) : (
              <CheckCircle className="far fa-circle" />
            )}
            <CheckText>One number</CheckText>
          </CheckWrapper>
          <CheckWrapper>
            {complexityProps.oneSymbolCheck ? (
              <CheckCircle className="fas fa-check-circle" />
            ) : (
              <CheckCircle className="far fa-circle" />
            )}
            <CheckText>One symbol</CheckText>
          </CheckWrapper>
          <CheckWrapper>
            {complexityProps.oneUpperCheck && complexityProps.oneLowerCheck ? (
              <CheckCircle className="fas fa-check-circle" />
            ) : (
              <CheckCircle className="far fa-circle" />
            )}
            <CheckText>One uppercase and one lowercase letter</CheckText>
          </CheckWrapper>
        </PasswordComplexityWrapper>
      )}
    </>
  );
}
