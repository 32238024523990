export default flatProduct => {
  const {
    options,
    optionsById,
    subOptionsById,
    itemsById,
    subItemsById,
  } = flatProduct;
  let singlePrice = flatProduct.price;

  const iterateOptionItems = option => {
    let itemsWithQuantities = [];
    option.items.forEach(itemId => {
      const item = itemsById[itemId];
      if (item.sub_items) {
        item.sub_items.forEach(subItemId => {
          const subItem = subItemsById[subItemId];
          if (subItem.quantity) {
            itemsWithQuantities = [...itemsWithQuantities, subItem];
          }
        });
      } else {
        if (item.quantity) {
          itemsWithQuantities = [...itemsWithQuantities, item];
        }
      }
    });

    // sort items by highest Quantity first, then by lower count_as
    itemsWithQuantities.sort((firstEl, secondEl) => {
      return firstEl.count_as === secondEl.count_as ? 0
              : (firstEl.count_as > secondEl.count_as ? 1 : -1)
    })
    itemsWithQuantities.sort((firstEl, secondEl) => {
      return firstEl.quantity === secondEl.quantity ? 0
              : (firstEl.quantity > secondEl.quantity ? -1 : 1)
    })

    let includedPick = option.included_pick
    itemsWithQuantities.forEach(item => {
      const quantity = item.quantity;
      const countAs = item.count_as
      let selectedItemPrice =
        option.included_pick === 0 ? item.price : item.additional_price;

      if (includedPick < quantity * countAs) {
        const freeQuantity = Math.floor(includedPick/countAs);
        const pricedQuantity = quantity - freeQuantity
        includedPick -= freeQuantity
        singlePrice += pricedQuantity * selectedItemPrice;
      } else {
        includedPick -= quantity * countAs
      }
    });
  };

  options &&
    options.forEach(optionId => {
      const option = optionsById[optionId];
      if (option.sub_options.length) {
        option.sub_options.forEach(subOptionId =>
          iterateOptionItems(subOptionsById[subOptionId])
        );
      } else {
        iterateOptionItems(option);
      }
    });
  const totalPrice = singlePrice * flatProduct.quantity;
  return {
    singlePrice,
    totalPrice,
  };
};
