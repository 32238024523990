export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE';

export const SHOW_PAYMENT_METHOD_FORM = 'SHOW_PAYMENT_METHOD_FORM';
export const HIDE_PAYMENT_METHOD_FORM = 'HIDE_PAYMENT_METHOD_FORM';

export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAILURE = 'CREATE_PAYMENT_METHOD_FAILURE';

export const SET_DEFAULT_PAYMENT_METHOD_REQUEST =
  'SET_DEFAULT_PAYMENT_METHOD_REQUEST';
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS =
  'SET_DEFAULT_PAYMENT_METHOD_SUCCESS';
export const SET_DEFAULT_PAYMENT_METHOD_FAILURE =
  'SET_DEFAULT_PAYMENT_METHOD_FAILURE';

export const SHOW_CONFIRM_DELETE_PAYMENT_METHOD =
  'SHOW_CONFIRM_DELETE_PAYMENT_METHOD';
export const HIDE_CONFIRM_DELETE_PAYMENT_METHOD =
  'HIDE_CONFIRM_DELETE_PAYMENT_METHOD';

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

export const CHANGE_GUEST_PAYMENT_METHOD = 'CHANGE_GUEST_PAYMENT_METHOD';

export const UPDATE_GUEST_CREDIT_CARD_DETAILS =
  'UPDATE_GUEST_CREDIT_CARD_DETAILS';
export const RESET_GUEST_CREDIT_CARD_DETAILS =
  'RESET_GUEST_CREDIT_CARD_DETAILS';

export const UPDATE_GUEST_GIFT_CARD_DETAILS = 'UPDATE_GUEST_GIFT_CARD_DETAILS';
export const RESET_GUEST_GIFT_CARD_DETAILS = 'RESET_GUEST_GIFT_CARD_DETAILS';

export const SET_TOUCHED_CREDIT_CARD_DETAILS =
  'SET_TOUCHED_CREDIT_CARD_DETAILS';

export const SHOW_SELECT_PAYMENT_METHOD_FORM =
  'SHOW_SELECT_PAYMENT_METHOD_FORM';

export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';

export const SHOW_PAYMENT_METHOD_SELECTION_FORM =
  'SHOW_PAYMENT_METHOD_SELECTION_FORM';
