import React from "react";
import styled from "styled-components";

const ProductUnavailable = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "15px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  display: grid;
  grid-template-columns: 24px auto;
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 12px;
  font-size: 15px;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  font-weight: 400;
  > div:first-child {
    padding-top: 12px;
  }
`;

const UnavaliableProductList = styled.ul`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const UnavailableProducts = ({
  unavailableProducts,
  marginTop,
  cartErrorMessage,
  marginBottom,
}) => {
  return cartErrorMessage && Array.isArray(cartErrorMessage) && cartErrorMessage.length > 0 ? (
    <ProductUnavailable marginTop={marginTop} marginBottom={marginBottom}>
      <div>
        <i className="fal fa-exclamation-circle" />
      </div>
      <UnavaliableProductList>
        {cartErrorMessage && cartErrorMessage.length > 0 ? (
          cartErrorMessage.map((error, index) => (
            <li key={`unavailable_${index}`}>{error}</li>
          ))
        ) : (
          <li key="ErrorMessageDefault">
            {
              "The product(s) on the cart are not available on the selected location."
            }
          </li>
        )}
        <p>By selecting this location it will be removed from your cart.</p>
      </UnavaliableProductList>
    </ProductUnavailable>
  ) : null;
};

export default UnavailableProducts;
