import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DeliveryTimeForm from './components/DeliveryTimeForm';
import * as actions from '../orderSettingActions';
import { setAuthenticationModalIsVisible } from '../../Auth/authActions';
import { getMenu } from '../../OnAppLoad/Menu/menuActions';
import { getCombos } from '../../Combo/comboActions';
import appConfig from '../../../../app-config';
import {
  emptyCart,
  updateCartWithUnavailbleProducts,
  resetCartErrorMessages,
  validateCartForLocation,
  updateUnavailableProducts,
} from '../../Cart/cartActions';
import { setIsMenuDisabled } from '../../OrderSetting/orderSettingActions';

const styles = () => ({
  scrollPaper: {
    // alignItems:
    //   typeof window !== 'undefined' && window.innerWidth < 786
    //     ? 'flex-end'
    //     : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '500px',
  },
});

const DeliveryTimeFormContainer = ({
  classes,
  setDeliveryTimeFormIsVisible,
  orderSettingReducer,
  isAuthenticated,
  updateInterSelectedDateTime,
  confirmDeliveryDetails,
  resetIsTimeNoLongerValid,
  getTimeIntervalsDate,
  deepLinkParameters,
  refine,
  emptyCart,
  checkUnavailableProductForMarketplace,
  resetUnavailableProducts,
  cartReducer,
  resetCartErrorMessages,
  validateCartForLocation,
  updateInterDeliveryDetails,
  confirmMarketplaceDeliverySelection,
  updateOrderSettingModalIsVisible,
  selectMarketplaceDeliveryLocation,
  updateCartWithUnavailbleProducts,
  updateUnavailableProducts,
  setIsMenuDisabled,
  getMenu,
  getCombos,
  setOpenDeliveryTimeModal,
}) => {
  const {
    interDeliveryDetails,
    deliveryTimeFormIsVisible,
    interOrderType,
    interSelectedDateTime,
    availableTimes,
    selectedDateTime,
    isTimeNoLongerValid,
    specialHours,
    orderPacingData,
    isConfirmMarketPlaceDeliveryDetailsRequest,
    isUnavailableProductInCartRequest,
    cartErrorMessage,
    selectedLocation,
  } = orderSettingReducer;

  const { unavailableProducts } = cartReducer;

  const handleConfirmDeliveryButton = () => {
    if (!isAuthenticated) {
      emptyCart();
    } else if (unavailableProducts && unavailableProducts.length > 0) {
      updateCartWithUnavailbleProducts(unavailableProducts);
    }

    selectMarketplaceDeliveryLocation();
    confirmDeliveryDetails(true);
    setDeliveryTimeFormIsVisible(false);
    updateOrderSettingModalIsVisible(false);
  };

  const onSeeMenu = () => {
    emptyCart();
    selectMarketplaceDeliveryLocation();
    confirmDeliveryDetails(true);
    setDeliveryTimeFormIsVisible(false);
    updateOrderSettingModalIsVisible(false);
  };

  const emptySearchBox = () => {
    if (appConfig.isGlobalSearchEnabled) {
      refine('');
    }
  };

  return (
    <Dialog
      open={deliveryTimeFormIsVisible}
      onClose={() => {
        setDeliveryTimeFormIsVisible(false, null, true);
        resetCartErrorMessages();
      }}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      classes={{ scrollPaper: classes.scrollPaper }}
      PaperProps={{ classes: { root: classes.paper } }}
      aria-labelledby="delivery-time-form"
      scroll="body"
    >
      <DeliveryTimeForm
        setIsMenuDisabled={setIsMenuDisabled}
        setDeliveryTimeFormIsVisible={setDeliveryTimeFormIsVisible}
        interDeliveryDetails={selectedLocation}
        selectedLocation={selectedLocation}
        interOrderType={interOrderType}
        interSelectedDateTime={interSelectedDateTime}
        updateInterSelectedDateTime={updateInterSelectedDateTime}
        confirmDeliveryDetails={confirmDeliveryDetails}
        availableTimes={availableTimes}
        selectedDateTime={selectedDateTime}
        isTimeNoLongerValid={isTimeNoLongerValid}
        resetIsTimeNoLongerValid={resetIsTimeNoLongerValid}
        getTimeIntervalsDate={getTimeIntervalsDate}
        specialHours={specialHours}
        orderPacingData={orderPacingData}
        orderMessage={appConfig.orderMessageDelivery}
        isOnline={
          interDeliveryDetails && interDeliveryDetails?.settings?.online
        }
        emptySearchBox={() => emptySearchBox()}
        deepLinkParameters={deepLinkParameters}
        isConfirmMarketPlaceDeliveryDetailsRequest={
          isConfirmMarketPlaceDeliveryDetailsRequest
        }
        handleConfirmDeliveryButton={handleConfirmDeliveryButton}
        onSeeMenu={onSeeMenu}
        isUnavailableProductInCartRequest={isUnavailableProductInCartRequest}
        unavailableProducts={unavailableProducts}
        updateCartWithUnavailbleProducts={updateCartWithUnavailbleProducts}
        cartErrorMessage={cartErrorMessage}
        checkUnavailableProductForMarketplace={
          checkUnavailableProductForMarketplace
        }
        resetUnavailableProducts={resetUnavailableProducts}
        cartReducer={cartReducer}
        resetCartErrorMessages={resetCartErrorMessages}
        updateInterDeliveryDetails={updateInterDeliveryDetails}
        confirmMarketplaceDeliverySelection={
          confirmMarketplaceDeliverySelection
        }
        updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
        selectMarketplaceDeliveryLocation={selectMarketplaceDeliveryLocation}
        isAuthenticated={isAuthenticated}
        emptyCart={emptyCart}
        updateUnavailableProducts={updateUnavailableProducts}
        getMenu={getMenu}
        getCombos={getCombos}
        setOpenDeliveryTimeModal={setOpenDeliveryTimeModal}
        validateCartForLocation={validateCartForLocation}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  orderSettingReducer: state.orderSettingReducer,
  isAuthenticated: state.authReducer.isAuthenticated,
  deepLinkParameters: state.deepLinkReducer.parameters,
  cartReducer: state.cartReducer,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    ...actions,
    getMenu,
    getCombos,
    emptyCart,
    updateUnavailableProducts,
    updateCartWithUnavailbleProducts,
    resetCartErrorMessages,
    validateCartForLocation,
    setIsMenuDisabled,
  })(DeliveryTimeFormContainer)
);
