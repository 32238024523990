import React from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  display: inline-block;
`

const ContentWrapper = styled.div`
  background-color: #f3f3f3;
  width: fit-content;
  border-radius: 18px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  height: 36px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
`

const Quantity = styled.span`
  display: inline-block;
  white-space: nowrap;
  border-radius: 50%;
  background-color: ${props => props.theme.circleCheckIconBackgroundColor};
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: ${props => props.theme.circleCheckIconFontColor};
  margin-left: 5px;
`

const Title = styled.span`
  display: inline-block;
  white-space: nowrap;
  margin: 0 12px;
`

const Remove = styled.span`
  margin-right: 12px;
  cursor: pointer;
  font-size: 20px;
`

const SelectedItem = ({
  item,
  removeItem,
  removeSubItem,
}) => {
  const handleRemoveItem = () => {
    item.originalItem ? removeItem(item.originalItem) : removeSubItem(item.originalSubItem);
  }
  return (
    <ItemWrapper>
      <ContentWrapper>
        {item.quantity > 1 && <Quantity id="OptionSummary-ItemQuantity">{item.quantity}</Quantity>}
        <Title id="OptionSummary-SelectedItemTitle">{item.name}</Title>
        <Remove id="OptionSummary-RemoveSelectedButton" onClick={handleRemoveItem} title="Remove">
          <i className="fal fa-times" />
        </Remove>
      </ContentWrapper>
    </ItemWrapper>
  );
};

export default SelectedItem;

