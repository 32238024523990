import teriyaki from './teriyakiLogo.png';
import chopped from './choppedLogo.png';

//key name must be same as appSource.
//Separated header and footer if client needs different header and footer logos

export default {
  teriyaki: {
    header: teriyaki,
    footer: teriyaki,
    mobileNavDrawer: teriyaki,
  },
  chopped: {
    header: chopped,
    footer: chopped,
    mobileNavDrawer: chopped,
  },
};
