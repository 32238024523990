import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import FormGrid from "../../../components/Form/FormGrid";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import { PrimaryButton, ButtonRow } from "../../../components/Buttons";
import PhoneNumber from "../../../layout/components/PhoneNumber";

const FormLayout = styled(FormGrid)`
  grid-gap: 20px;
`;
const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const PhoneNumberText = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
const DescriptionWrapper = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
`;
const EmailOrPhoneForm = ({
  emailOrPhoneField,
  classes,
  postForgotPassword,
  setEmailOrPhoneObject,
}) => {
  const isEmail = new RegExp(
    "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@" +
      "[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
  );
  const handleSubmitAction = (obj) => {
    setEmailOrPhoneObject(obj);
    postForgotPassword(obj);
  };
  const validateEmail = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!isEmail.test(values.email)) {
      errors.email = "Please put a valid email format";
    }
    return errors;
  };
  const validatePhoneNumber = (values) => {
    let errors = {};
    if (!values.phone_number) {
      errors.phone_number = "Required";
    }
    return errors;
  };
  return (
    <div>
      <DescriptionWrapper>
        {`Enter your ${
          emailOrPhoneField === "email" ? "email" : "phone number"
        } below to update your password. If there is an account
          linked to this email, you will receive a verification code.`}
      </DescriptionWrapper>
      <Formik
        initialValues={{ email: "", phone_number: "" }}
        validate={(values) => {
          let errors = {};
          if (emailOrPhoneField === "email") {
            errors = validateEmail(values);
          } else {
            errors = validatePhoneNumber(values);
          }
          return errors;
        }}
        onSubmit={(values) => {
          emailOrPhoneField === "email"
            ? handleSubmitAction({ email: values.email })
            : handleSubmitAction({ phone_number: values.phone_number });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              {emailOrPhoneField === "email" ? (
                <TextField
                  id="EmailForm-Username"
                  label="Email"
                  name="email"
                  type="email"
                  autoComplete="username"
                  value={values.email}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.email && errors.email ? true : false}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{
                    className: classes.input,
                    required: false,
                  }}
                />
              ) : (
                <PhoneNumber
                  name="phone_number"
                  label="Phone Number"
                  id="RegistrationForm-PhoneNumberField"
                  handlePhone={(value) => setFieldValue("phone_number", value)}
                  handleBlur={handleBlur}
                  required
                  error={
                    touched.phone_number && errors.phone_number ? true : false
                  }
                  helperText={touched.phone_number && errors.phone_number}
                />
              )}
              <ButtonRow style={{ marginTop: "12px" }} justifyContent="center">
                <PrimaryButton
                  id="Email-Submit"
                  type="submit"
                  disabled={
                    emailOrPhoneField === "email"
                      ? !values.email
                      : !values.phone_number
                  }
                  buttonWidth="240px"
                  buttonHeight="56px"
                  buttonBorderRadius="10px"
                  fontWeight="600"
                >
                  Continue
                </PrimaryButton>
              </ButtonRow>
            </FormLayout>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(EmailOrPhoneForm);
