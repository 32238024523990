import React from 'react';
import styled from 'styled-components';
import appConfig from '../../../../app-config';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.hasLoyalty ? 'auto 1fr auto 1fr auto' : '1fr 1fr 1fr'};
  grid-gap: 10px;
  align-items: center;
  width: 100%;
  max-width: ${props => (props.hasLoyalty ? '260px' : '150px')};
  margin: auto;
  @media (max-width: 786px) {
    max-width: 180px;
  }
`;

const Circle = styled.div`
  width: 32px;
  height: 32px;
  // border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.selected
      ? props.theme.stepIndicatorSelectedColour
      : props.theme.registrationInactiveIconColor};
  // background-color: ${props =>
    props.selected ? props.theme.primaryColor : 'white'};
  // border: 2px solid ${props => props.theme.primaryColor};
  margin: ${props => (props.hasLoyalty ? 'none' : '0 auto')};
`;

const Hr = styled.hr`
  height: 3px;
  width: 34px;
  border-radius: 20px;
  border: 1px;
  background-color: ${props =>
    props.selected
      ? props.theme.stepIndicatorSelectedColour
      : props.theme.registrationInactiveIconColor};
  margin: 0;
`;

const Icon = styled.i`
  font-size: 24px;
`;

const hasLoyalty =
  appConfig.isLoyaltyEnabled && appConfig.loyaltyType === 'givex';

const StepIndicator = ({activeStep}) => (
  <Wrapper hasLoyalty={hasLoyalty}>
    <Circle selected={activeStep > 0} hasLoyalty={hasLoyalty}>
      <Icon className="fa fa-user" />
    </Circle>
    <Hr selected={activeStep > 1} />
    <Circle selected={activeStep > 1} hasLoyalty={hasLoyalty}>
      <Icon className="fas fa-comment-alt-dots" />
    </Circle>
    {hasLoyalty && (
      <>
        <Hr selected={activeStep > 2} />
        <Circle selected={activeStep > 2}>
          <Icon className="fas fa-barcode-alt" />
        </Circle>
      </>
    )}
  </Wrapper>
);

export default StepIndicator;
