import {
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
  SET_ORDER_HISTORY_MODAL_VISIBLE,
} from './orderHistoryTypes';

export default function(
  state = {
    requestingGetOrderHistory: true,
    orderHistory: [],
    orderHistoryModalIsVisible: false,
    singleOrderHistory: []
  },
  action
) {
  switch (action.type) {
    case GET_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        requestingGetOrderHistory: true,
      };
    case GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        requestingGetOrderHistory: false,
        orderHistory: action.orderHistory,
      };
    case SET_ORDER_HISTORY_MODAL_VISIBLE:
      return {
        ...state,
        orderHistoryModalIsVisible : action.isVisible
      };
    default:
      return state;
  }
}
