import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {Title2, Body} from '../../../../../components/Typography';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 0.3fr 1fr 0.3fr;
  justify-content: center;
  margin: auto;
`;

const TitleDiv = styled.div`
  margin: auto;
  margin-bottom: 16px;
  margin-top: 23px;
`;

const BodyDiv = styled.div`
  margin: auto;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

const ButtonDiv = styled.div`
  margin: auto;
  margin-bottom: 24px;
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CloseButton = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  background-color: ${props => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;
  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

function ConfirmationDialog({open, close, location, cart, onGoToMenuClick}) {
  const {products, combos} = cart;

  return (
    <Dialog open={open} onClose={() => close()}>
      <Wrapper>
        <TitleWrapper>
          <Title2 style={{marginLeft: '16px'}}>START NEW ORDER?</Title2>
          <CloseButton onClick={() => close()}>
            <i
              className="fas fa-times"
              style={{fontSize: '15px', cursor: 'pointer'}}
            />
          </CloseButton>
        </TitleWrapper>
        <BodyDiv>
          {products.length > 0 || combos.length > 0 ? (
            <div>
              <Body>
                {location
                  ? `Your cart already contains an item from ${location.name}.`
                  : `Your cart already contains an item.`}
              </Body>
              <Body>
                Would you like to discard your existing cart and start a new
                order?
              </Body>
            </div>
          ) : (
            <div>
              <Body>Would you like to start a new order at this location?</Body>
            </div>
          )}
        </BodyDiv>
        <ButtonDiv>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onGoToMenuClick()}
            style={{width: '90%', marginLeft: '16px', marginRight: '16px'}}
          >
            Start New Order
          </Button>
        </ButtonDiv>
      </Wrapper>
    </Dialog>
  );
}

export default ConfirmationDialog;
