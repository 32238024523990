import styled from 'styled-components';

const PrimaryButtonHeadline = styled.button`
  height: ${props => (props.small ? '38px' : '48px')};
  min-width: ${props => (props.small ? '114px' : '280px')};
  color: ${props =>
    props.disabled
      ? props.theme.primaryButtonDisabledFontColor
      : props.theme.primaryButtonFontColor};
  background: ${props =>
    props.disabled
      ? props.theme.primaryButtonDisabledColor
      : props.theme.primaryButtonBackgroundColor};
  text-transform: ${props => props.theme.primaryButtonTextTransform};
  border-radius: ${props => props.theme.primaryButtonBorderRadius};
  // box-shadow: ${props => props.theme.primaryButtonBoxShadow};
  font-size: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-family: ${props => props.theme.primaryFontFontFamily};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:hover {
    background: ${props =>
      !props.disabled && props.theme.primaryButtonHoverColor};
    color: ${props =>
      props.disabled
        ? props.theme.primaryButtonDisabledFontColor
        : props.theme.primaryButtonTextHoverColor};
    transition: all 0.25s ease;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default PrimaryButtonHeadline;
