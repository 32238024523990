export const CREATE_ANONYMOUS_SESSION_REQUEST =
  'CREATE_ANONYMOUS_SESSION_REQUEST';
export const CREATE_ANONYMOUS_SESSION_SUCCESS =
  'CREATE_ANONYMOUS_SESSION_SUCCESS';
export const CREATE_ANONYMOUS_SESSION_FAILURE =
  'CREATE_ANONYMOUS_SESSION_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAILURE = 'CHECK_EMAIL_FAILURE';

export const RESET_CHECKED_EMAIL = 'RESET_CHECKED_EMAIL';

export const SET_AUTHENTICATION_MODAL_IS_VISIBLE =
  'SET_AUTHENTICATION_MODAL_IS_VISIBLE';

export const PATCH_PHONE_NUMBER_REQUEST = 'PATCH_PHONE_NUMBER_REQUEST';
export const PATCH_PHONE_NUMBER_SUCCESS = 'PATCH_PHONE_NUMBER_SUCCESS';
export const PATCH_PHONE_NUMBER_FAILURE = 'PATCH_PHONE_NUMBER_FAILURE';

export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD';
export const POST_FORGOT_EMAIL_SUCCESS = 'POST_FORGOT_EMAIL_SUCCESS';

export const EMAIL_IS_UNCONFIRMED = 'EMAIL_IS_UNCONFIRMED';

export const RESET_AUTH_REDUCER = 'RESET_AUTH_REDUCER';

export const GET_APP_CONFIG = 'GET_APP_CONFIG';
export const UPDATE_LOGIN_OR_SIGNUP_SCREEN = 'UPDATE_LOGIN_OR_SIGNUP_SCREEN';
export const UPDATE_IS_VERIFICATION_CODE_MATCHED =
  'UPDATE_IS_VERIFICATION_CODE_MATCHED';
export const IS_PASSWORD_RESET_SUCCESSFULL = 'IS_PASSWORD_RESET_SUCCESSFULL';

export const INITIATE_CHECKOUT_AS_GUEST_REQUEST =
  'INITIATE_CHECKOUT_AS_GUEST_REQUEST';
export const INITIATE_CHECKOUT_AS_GUEST_SUCCESS =
  'INITIATE_CHECKOUT_AS_GUEST_SUCCESS';
export const INITIATE_CHECKOUT_AS_GUEST_FAILURE =
  'INITIATE_CHECKOUT_AS_GUEST_FAILURE';

export const RESET_GUEST_SESSION = 'RESET_GUEST_SESSION';
export const SET_IS_GUEST_SESSION_INITIALIZED = 'SET_IS_GUEST_SESSION_INITIALIZED';