import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ComboGroup from './ComboGroup'
import * as actions from '../../comboActions'
import { SCREEN_PRODUCT } from '../../comboTypes'

const ComboGroupContainer = ({
  combo,
  selectedComboGroup,
  changePage,
  getComboItemProduct,
  goBack,
  closeComboPopup,
  editSelectedProductInGroup
}) => {
  const comboGroup = combo.combo_item_groups[selectedComboGroup]

  const onItemClick = (product) => {
    getComboItemProduct(product)
    changePage(SCREEN_PRODUCT)
  }

  return (
    <ComboGroup
      comboGroup={comboGroup}
      onClickBack={goBack}
      onItemClick={onItemClick}
      closeComboPopup={closeComboPopup}
    />
  )
}

const mapStateToProps = state => ({
  selectedComboGroup: state.comboReducer.selectedComboGroup
})

export default connect(
  mapStateToProps,
  actions
)(ComboGroupContainer)