import React, { useState, useEffect, memo } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';
import { Title3, Headline } from '../../components/Typography';
import appConfig from '../../../app-config';
import logos from '../../assets/logos';
import ConfirmCloseModal from '../../modules/Auth/ConfirmCloseModal/ConfirmCloseModal';
import Credits from '../../modules/Credits/CreditsContainer';

const Wrapper = styled(Drawer)`
  width: 264px !important;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const MainWrapper = styled.div`
  height: 100vh;
  display: grid;
  align-content: space-between;
  color: ${(props) => props.theme.mobileNavDrawerFontColor || 'black'};
  background-color: ${(props) =>
    props.theme.mobileNavDrawerBackgroundColor || '#fff'};
`;

const MenuItemsWrapper = styled.div`
  padding: 24px 12px 24px 36px;
  background-color: ${(props) =>
    props.theme.mobileNavDrawerBackgroundColor || '#fff'};
`;

const MenuItem = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr auto;
  grid-gap: 20px;
  align-items: center;
  padding: 12px 0;
`;

const Icon = styled.i`
  justify-self: center;
  font-size: 16px;
  color: ${(props) => props.theme.clickableTextColor};
`;

const Image = styled.img`
  width: 17px;
  justify-self: center;
`;

const SubMenuWrapper = styled.div`
  margin-left: 60px;
`;

const SubMenuItem = styled.div`
  padding: 9px 0;
  font-size: 15px;
  font-weight: 600;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
`;

const CreditsWrapper = styled.p`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MenuLink = styled(Headline)`
  color: ${(props) => props.theme.mobileNavDrawerFontColor || 'black'};
  &:hover {
    color: ${(props) => props.theme.mobileMenuItemHoverColor};
    transition: all 0.25 ease;
  }
`;

const Logo = styled.img`
  margin: 0 auto 42px auto;
  height: 50px;
`;

const LogoWrapper = styled.div`
  text-align: center;
  padding: 0 20px;
  background-color: ${(props) =>
    props.theme.mobileNavDrawerBackgroundColor || '#fff'};
`;

const styles = () => ({
  paper: {
    width: 264,
  },
});

const MobileNavDrawer = ({
  mobileNavDrawerIsVisible,
  toggleMobileNavDrawer,
  logout,
  classes,
  location,
  isAuthenticated,
  showAuthenticationModal,
  loyalty,
  setCouponsModalIsVisible,
  showReferralCode,
}) => {
  const deepLink = localStorage.getItem('deep-link-url');
  const [accountMenuIsExpanded, setAccountMenuIsExpanded] = useState(false);
  useEffect(() => {
    setAccountMenuIsExpanded(false);
  }, [location]);

  const toggleAccountMenu = (event) => {
    event.stopPropagation();
    setAccountMenuIsExpanded(!accountMenuIsExpanded);
  };

  const [confirmCloseModalIsVisible, setConfirmCloseModalIsVisible] = useState(
    false
  );

  const [creditModalIsVisible, setCreditModalIsVisible] = useState(false);

  const handleConfirmModalConfirmClick = () => {
    setConfirmCloseModalIsVisible(false);
    toggleMobileNavDrawer();
    logout();
  };

  const handleConfirmModalCancelClick = () => {
    setConfirmCloseModalIsVisible(false);
    toggleMobileNavDrawer();
  };

  const handleLogout = () => {
    setConfirmCloseModalIsVisible(true);
  };

  return (
    <div>
      <Wrapper
        open={mobileNavDrawerIsVisible}
        onClose={toggleMobileNavDrawer}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={toggleMobileNavDrawer}
          onKeyDown={toggleMobileNavDrawer}
          style={{ textDecoration: 'none', outline: 'none' }}
        >
          <MainWrapper>
            <MenuItemsWrapper>
              <Icon
                className="fas fa-times"
                onClick={toggleMobileNavDrawer}
                style={{
                  marginBottom: '36px',
                  marginLeft: '4px',
                  fontSize: '24px',
                  fontWeight: '300',
                  color: appConfig.styleConfig.black,
                }}
              />
              {!appConfig.isRedirectedToMenuPage && (
                // <MenuItem onClick={() => navigateWrapper('/')}>
                <MenuItem
                  id="Mobile-HomeLink"
                  onClick={() => {
                    if (deepLink) {
                      navigate('/' + deepLink);
                    } else {
                      navigate('/');
                    }
                  }}
                >
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <Image src={appConfig.accountIcons.icons.my_address} />
                  ) : (
                    <Icon className="fas fa-home" />
                  )}
                  <MenuLink>Home</MenuLink>
                </MenuItem>
              )}
              <MenuItem
                id="Mobile-MenuLink"
                onClick={() => {
                  if (deepLink) {
                    navigate('/' + deepLink);
                  } else {
                    navigate('/');
                  }
                }}
              >
                {/* <MenuItem onClick={() => navigateWrapper('/menu')}> */}
                {appConfig.accountIcons?.iconType === 'image' ? (
                  <Image src={appConfig.accountIcons.icons.menu} />
                ) : (
                  <Icon className="fas fa-utensils" />
                )}
                <MenuLink>Menu</MenuLink>
              </MenuItem>
              {appConfig.isGlobalSearchEnabled ? (
                <MenuItem
                  id="Mobile-SearchLink"
                  onClick={() => navigate('/search')}
                >
                  {/* <MenuItem onClick={() => navigateWrapper('/menu')}> */}
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <Image src={appConfig.accountIcons.icons.menu} />
                  ) : (
                    <Icon className="fas fa-search" />
                  )}
                  <MenuLink>Search</MenuLink>
                </MenuItem>
              ) : null}
              {isAuthenticated && appConfig.isOffersEnabled && (
                // <MenuItem onClick={() => navigateWrapper('/offers')}>
                <MenuItem
                  id="Mobile-OffersLink"
                  onClick={() => navigate('/offers')}
                >
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <Image src={appConfig.accountIcons.icons.my_credits} />
                  ) : (
                    <Icon className="fas fa-star" />
                  )}
                  <MenuLink>Rewards</MenuLink>
                </MenuItem>
              )}
              {/* <MenuItem onClick={() => navigateWrapper('/locations')}> */}
              {appConfig.appSource !== 'feastify' ? (
                <MenuItem
                  id="Mobile-LocationsLink"
                  onClick={() => navigate('/locations')}
                >
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <Image src={appConfig.accountIcons.icons.locations} />
                  ) : (
                    <Icon className="fas fa-map-marker-alt" />
                  )}
                  <MenuLink>Locations</MenuLink>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem id="Mobile-AccountToggle" onClick={toggleAccountMenu}>
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <Image
                      src={appConfig.accountIcons.icons.profile_settings}
                    />
                  ) : (
                    <Icon className="fas fa-user" />
                  )}
                  <MenuLink>Account</MenuLink>
                  <i
                    className={`fas fa-chevron-${
                      accountMenuIsExpanded ? 'up' : 'down'
                    }`}
                  />
                </MenuItem>
              ) : (
                <MenuItem id="Mobile-SignIn" onClick={showAuthenticationModal}>
                  <Icon className="fas fa-user" />
                  <MenuLink>Sign In</MenuLink>
                </MenuItem>
              )}
              <Collapse in={accountMenuIsExpanded}>
                <SubMenuWrapper>
                  {loyalty && loyalty.credits_available > 0 && (
                    <SubMenuItem
                      id="Mobile-MyCredits"
                      onClick={() => setCreditModalIsVisible(true)}
                    >
                      {loyalty.credits_available > 0 ? (
                        <CreditsWrapper>
                          {' '}
                          Credits
                          {`: $${loyalty.credits_available.toFixed(2)}`}
                        </CreditsWrapper>
                      ) : (
                        <CreditsWrapper> Credits</CreditsWrapper>
                      )}
                    </SubMenuItem>
                  )}
                  <SubMenuItem
                    id="Mobile-ProfileSettingsLink"
                    onClick={() => navigate('/profile-settings')}
                  >
                    Account Settings
                  </SubMenuItem>
                  <SubMenuItem
                    id="Mobile-PaymentMethodsLink"
                    onClick={() => navigate('/payment-methods')}
                  >
                    Payment Methods
                  </SubMenuItem>
                  <SubMenuItem
                    id="Mobile-OrderHistoryLink"
                    onClick={() => navigate('/my-transactions')}
                  >
                    Order History
                  </SubMenuItem>
                  {(appConfig.isDeliveryEnabled ||
                    appConfig.isFutureOrderDelivery) && (
                    <SubMenuItem
                      id="Mobile-MyAdressesLink"
                      onClick={() => navigate('/addresses')}
                    >
                      Delivery Addresses
                    </SubMenuItem>
                  )}
                  {appConfig.isCouponsEnabled && (
                    <SubMenuItem
                      id="Mobile-CouponsLink"
                      onClick={() => setCouponsModalIsVisible(true)}
                    >
                      Coupons
                    </SubMenuItem>
                  )}
                  {showReferralCode && (
                    <SubMenuItem
                      id="Mobile-ReferAFrindLink"
                      onClick={() => navigate('/refer-friend')}
                    >
                      Refer a Friend
                    </SubMenuItem>
                  )}
                  <SubMenuItem
                    id="Mobile-LogoutLink"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </SubMenuItem>
                </SubMenuWrapper>
              </Collapse>
            </MenuItemsWrapper>
            <LogoWrapper>
              <Logo src={appConfig.styleConfig.loglUrl} />
            </LogoWrapper>
          </MainWrapper>
        </div>
      </Wrapper>

      {confirmCloseModalIsVisible && (
        <ConfirmCloseModal
          handleConfirmClick={handleConfirmModalConfirmClick}
          handleCancelClick={handleConfirmModalCancelClick}
          confirmCloseModalIsVisible={confirmCloseModalIsVisible}
          body="Are you sure you want to logout?"
          customStyle
        />
      )}
      <Credits
        setCreditModalIsVisible={setCreditModalIsVisible}
        creditModalIsVisible={creditModalIsVisible}
      />
    </div>
  );
};

export default memo(withStyles(styles)(MobileNavDrawer));
