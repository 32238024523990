const useDefaultItems = flatProduct => {
  Object.keys(flatProduct.itemsById).forEach(itemId => {
    // if there is a default item and it's unavailable, unselect it
    if (
      flatProduct.itemsById[itemId].is_default &&
      !flatProduct.itemsById[itemId].is_available
    ) {
      // flatProduct.itemsById[itemId].quantity = 1;
      flatProduct.itemsById[itemId].quantity = 0;
      flatProduct.itemsById[
        itemId
      ].name = `${flatProduct.itemsById[itemId].name} - Currently unavailable. Please select a different item.`;
    }
  });

  Object.keys(flatProduct.subItemsById).forEach(subItemId => {
    if (
      flatProduct.subItemsById[subItemId].is_default &&
      flatProduct.subItemsById[subItemId].is_available
    ) {
      // flatProduct.subItemsById[subItemId].quantity = 1;
      flatProduct.subItemsById[subItemId].quantity = 0;
      flatProduct.subItemsById[
        subItemId
      ].name = `${flatProduct.subItemsById[subItemId].name} - Currently unavailable. Please select a different item.`;
    }
  });

  return flatProduct;
};

export default useDefaultItems;
