const getSelectedItems = (option, flatProduct) => {
  let selectedItems = []

  if (option.sub_options) {
    option.sub_options.forEach(subOptionId => {
      const subOption = flatProduct.subOptionsById[subOptionId];
      selectedItems = [
        ...selectedItems,
        ...getSelectedItems(subOption, flatProduct),
      ]
    })
  }

  if (option.items) {
    option.items.forEach((itemId) => {
      const item = flatProduct.itemsById[itemId];
      if (item.sub_items && item.sub_items.length) {
        const items = item.sub_items.forEach((subItemId) => {
          const subItem = flatProduct.subItemsById[subItemId];
          if (subItem && subItem.quantity > 0) {
            selectedItems.push({
              name: item.name + ' - ' + subItem.name,
              quantity: subItem.quantity,
              originalSubItem: subItem,
            });
          }
        })
      } else if (item.quantity > 0) {
        selectedItems.push({
          name: item.name,
          quantity: item.quantity,
          originalItem: item,
        });
      }
    });
  }

  return selectedItems;
}

export default getSelectedItems;