import {
  UPDATE_INTER_ORDER_TYPE,
  UPDATE_INTER_PICKUP_DETAILS,
  UPDATE_INTER_DELIVERY_DETAILS,
  UPDATE_INTER_DROPOFF_DETAILS,
  GET_DATE_TIMES,
  UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE,
  UPDATE_INTER_PICKUP_DETAILS_REQUEST,
  CONFIRM_PICKUP_DETAILS,
  CONFIRM_DELIVERY_DETAILS,
  CONFIRM_DROPOFF_DETAILS,
  PICKUP,
  UPDATE_INTER_SELECTED_DATE_TIME,
  RESET_INTER_SELECTED_DATE_TIME,
  UPDATE_INTER_DELIVERY_DETAILS_REQUEST,
  UPDATE_INTER_DROPOFF_DETAILS_REQUEST,
  RESET_DATE_TIMES,
  DELIVERY,
  ASAP,
  RESET_ORDER_SETTING_REDUCER,
  CHANGE_PICKUP_LOCATION,
  HIDE_TOOL_TIP,
  RESET_TIME_NO_LONGER_VALID,
  SET_ORDERING_CLOSED_MODAL_IS_VISIBLE,
  RESET_UNAVAILABLE_PRODUCTS,
  GET_SPECIAL_HOURS,
  GET_CHILD_LOCATIONS,
  INTER_DELIVERY_ERROR_MESSAGE,
  CHANGE_PICKUP_LOCATION_FROM_LOCATIONS_PAGE,
  UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE_FOR_LOCATIONS_PAGE,
  DROPOFF,
  UPDATE_ORDER_TYPE,
  SET_SELECTED_LOCATION,
  GET_MARKETPLACE_DELIVERY_LOCATIONS,
  CONFIRM_MARKETPLACE_DELIVERY_DETAILS,
  GET_MARKETPLACE_DELIVERY_LOCATIONS_REQUEST,
  DELIVERY_TIME_FORM_IS_VISIBLE,
  CONFIRM_MARKETPLACE_DELIVERY_DETAILS_REQUEST,
  CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST,
  CHECK_UNAVAILABLE_PRODUCT_IN_CART,
  START_NEW_ORDER_FORM_IS_VISIBLE,
  SET_MARKETPLACE_DELIVERY_SELECTION,
  RESET_MARKETPLACE_DELIVERY_LOCATIONS,
  RESET_INTER_DELIVERY_DETAILS,
  SET_PARENT_LOCATION,
  SET_CHILD_LOCATIONS,
  PICKUP_TIME_FORM_IS_VISIBLE,
  SET_INTER_DELIVERY_DETAILS_ID,
  UPDATE_AVAILABLE_TIMES,
  SET_IS_STORE_DEEP_LINK,
  SET_IS_PARENT_STORE_DEEP_LINK,
  SET_IS_MENU_DISABLED,
} from './orderSettingTypes';
import moment from 'moment';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { createAlert } from '../Alert/alertActions';
import api from '../../api';
import cartForValidation from '../Cart/utils/cartForValidation';
import identifyCartChanges from './utils/identifyCartChanges';
import { maxOrderDate } from './OrderSettingModal/utils/helper';
import { getOrderDate } from '../Offers/offersActions';
import appConfig from '../../../app-config';
import {
  validateGlobalCart,
  updateCartWithUnavailbleProducts,
} from '../Cart/cartActions';

export function updateInterOrderType(interOrderType) {
  return function(dispatch, getState) {
    const { deliveryAddressesList } = getState().deliveryAddressesReducer;
    const { interDeliveryDetails } = getState().orderSettingReducer;
    // let useDeliveryDetails = interDeliveryDetails
    //   ? null
    //   : deliveryAddressesList.find(
    //       deliveryAddress => deliveryAddress.is_preferred
    //     );

    // if (useDeliveryDetails) {
    //   return dispatch(
    //     updateInterDeliveryDetails(useDeliveryDetails, {
    //       interOrderType: DELIVERY,
    //     })
    //   );
    // }
    dispatch({ type: RESET_UNAVAILABLE_PRODUCTS });
    dispatch({
      type: UPDATE_INTER_ORDER_TYPE,
      interOrderType,
      // interDeliveryDetails: useDeliveryDetails,
    });
  };
}

export function updateInterPickUpDetails(
  interPickUpDetails,
  date,
  isNearestLocation
) {
  return function(dispatch, getState) {
    if (!interPickUpDetails) {
      dispatch({
        type: UPDATE_INTER_PICKUP_DETAILS,
        interPickUpDetails: null,
        unavailableProducts: [],
        availableTimes: [],
        orderPacingData: {
          is_asap_slot_available: true,
          future_unavailable_slots: [],
        },
      });
      return;
    }
    dispatch({ type: UPDATE_INTER_PICKUP_DETAILS_REQUEST });
    const { cartReducer } = getState();
    const { orderSettingReducer } = getState();
    const { selectedChild } = orderSettingReducer;
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const config = getState().authReducer.appConfig;
    const isCartEmpty = getState().cartReducer.products.length === 0;

    const { is_order_pacing_enabled } = getState().authReducer.appConfig;
    let cart = null;
    if (appConfig.isParentChildClient && selectedChild) {
      cart = cartForValidation(
        cartReducer,
        selectedChild,
        PICKUP,
        orderSettingReducer
      );
    } else if (!appConfig.isParentChildClient) {
      cart = cartForValidation(
        cartReducer,
        interPickUpDetails,
        PICKUP,
        orderSettingReducer
      );
    }

    api
      .get(`/order/settings?business_id=${interPickUpDetails.id}`)
      .then((settingResponse) => {
        const dateModified = moment(date.substr(0, 15)).format('YYYY-MM-DD');
        api
          .get(
            `/businesses/${interPickUpDetails.id}/ordering-hours?date=${dateModified}`
          )
          .then(async (dateIntervalResponse) => {
            let orderPacingResponse = null;
            if (
              is_order_pacing_enabled &&
              interPickUpDetails.accepted_order_types.includes('pickup')
            ) {
              orderPacingResponse = await api
                .get(
                  `/businesses/${interPickUpDetails.id}/order-time-slots?order_type=pickup&date=${dateModified}`
                )
                .catch((err) => {
                  orderPacingResponse = {
                    is_asap_slot_available: true,
                    future_unavailable_slots: [],
                  };
                });
            }
            const orderPacingData = orderPacingResponse
              ? orderPacingResponse.data.data
              : { is_asap_slot_available: true, future_unavailable_slots: [] };
            if (
              (isAuthenticated || (config && config.guest_checkout_enabled)) &&
              !isCartEmpty &&
              cart &&
              !appConfig.isParentChildClient
            ) {
              dispatch({
                type: UPDATE_INTER_PICKUP_DETAILS,
                interPickUpDetails: {
                  ...interPickUpDetails,
                  settings: settingResponse.data.data,
                  isPickupValid: true,
                  isNearestLocation,
                  availableTimes: dateIntervalResponse.data.data,
                },
                unavailableProducts: [],
                availableTimes: dateIntervalResponse.data.data,
                orderPacingData,
              });
            } else {
              dispatch({
                type: UPDATE_INTER_PICKUP_DETAILS,
                interPickUpDetails: {
                  ...interPickUpDetails,
                  settings: settingResponse.data.data,
                  isPickupValid: true,
                  isNearestLocation,
                  availableTimes: dateIntervalResponse.data.data,
                },
                unavailableProducts: [],
                cartErrorMessage: null,
                availableTimes: dateIntervalResponse.data.data,
                orderPacingData,
              });
            }
          });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_INTER_PICKUP_DETAILS,
          interPickUpDetails: {
            ...interPickUpDetails,
            isPickupValid: false,
            isNearestLocation,
            availableTimes: null,
          },
          cartErrorMessage: null,
          unavailableProducts: [],
          availableTimes: null,
          orderPacingData: {
            is_asap_slot_available: true,
            future_unavailable_slots: [],
          },
        });
      });
  };
}

export function setInterPickUpDetails(
  interPickUpDetails,
  date,
  isNearestLocation
) {
  return function(dispatch, getState) {
    if (!interPickUpDetails) {
      dispatch({
        type: UPDATE_INTER_PICKUP_DETAILS,
        interPickUpDetails: null,
        unavailableProducts: [],
        availableTimes: [],
        orderPacingData: {
          is_asap_slot_available: true,
          future_unavailable_slots: [],
        },
      });
      return;
    }
    dispatch({ type: UPDATE_INTER_PICKUP_DETAILS_REQUEST });
    const { cartReducer } = getState();
    const { orderSettingReducer } = getState();
    const { selectedChild } = orderSettingReducer;
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const config = getState().authReducer.appConfig;
    const isCartEmpty = getState().cartReducer.products.length === 0;

    const { is_order_pacing_enabled } = getState().authReducer.appConfig;
    let cart = null;
    if (appConfig.isParentChildClient && selectedChild) {
      cart = cartForValidation(
        cartReducer,
        selectedChild,
        PICKUP,
        orderSettingReducer
      );
    } else if (!appConfig.isParentChildClient) {
      cart = cartForValidation(
        cartReducer,
        interPickUpDetails,
        PICKUP,
        orderSettingReducer
      );
    }

    const dateModified = moment(date.substr(0, 15)).format('YYYY-MM-DD');
    api
      .get(
        `/businesses/${interPickUpDetails.id}/ordering-hours?date=${dateModified}`
      )
      .then(async (dateIntervalResponse) => {
        let orderPacingResponse = null;
        if (
          is_order_pacing_enabled &&
          interPickUpDetails.accepted_order_types.includes('pickup')
        ) {
          orderPacingResponse = await api
            .get(
              `/businesses/${interPickUpDetails.id}/order-time-slots?order_type=pickup&date=${dateModified}`
            )
            .catch((err) => {
              orderPacingResponse = {
                is_asap_slot_available: true,
                future_unavailable_slots: [],
              };
            });
        }
        const orderPacingData = orderPacingResponse
          ? orderPacingResponse.data.data
          : { is_asap_slot_available: true, future_unavailable_slots: [] };
        if (
          (isAuthenticated || (config && config.guest_checkout_enabled)) &&
          !isCartEmpty &&
          cart &&
          !appConfig.isParentChildClient
        ) {
          dispatch({
            type: UPDATE_INTER_PICKUP_DETAILS,
            interPickUpDetails: {
              ...interPickUpDetails,
              isPickupValid: true,
              isNearestLocation,
              availableTimes: dateIntervalResponse.data.data,
            },
            unavailableProducts: [],
            availableTimes: dateIntervalResponse.data.data,
            orderPacingData,
          });
        } else {
          dispatch({
            type: UPDATE_INTER_PICKUP_DETAILS,
            interPickUpDetails: {
              ...interPickUpDetails,
              isPickupValid: true,
              isNearestLocation,
              availableTimes: dateIntervalResponse.data.data,
            },
            unavailableProducts: [],
            cartErrorMessage: null,
            availableTimes: dateIntervalResponse.data.data,
            orderPacingData,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_INTER_PICKUP_DETAILS,
          interPickUpDetails: {
            ...interPickUpDetails,
            isPickupValid: false,
            isNearestLocation,
            availableTimes: null,
          },
          cartErrorMessage: null,
          unavailableProducts: [],
          availableTimes: null,
          orderPacingData: {
            is_asap_slot_available: true,
            future_unavailable_slots: [],
          },
        });
      });
  };
}

export function updateAvailableTimes() {
  return function(dispatch, getState) {
    const {
      interOrderType,
      interPickUpDetails,
      interDeliveryDetails,
      interDropOffDetails,
      interSelectedDateTime,
      selectedLocation,
    } = getState().orderSettingReducer;

    const location = selectedLocation
      ? selectedLocation
      : interOrderType === PICKUP && interPickUpDetails
      ? interPickUpDetails
      : interOrderType === DELIVERY &&
        interDeliveryDetails &&
        interDeliveryDetails.deliveryBusiness
      ? interDeliveryDetails.deliveryBusiness
      : interOrderType === DROPOFF && interDropOffDetails
      ? interDropOffDetails
      : null;

    const dateModified = moment(interSelectedDateTime.dateTime.date).format(
      'YYYY-MM-DD'
    );
    api
      .get(`/businesses/${location.id}/ordering-hours?date=${dateModified}`)
      .then((dateIntervalResponse) => {
        dispatch({
          type: UPDATE_AVAILABLE_TIMES,
          availableTimes: dateIntervalResponse.data.data,
        });
      });
  };
}

export function updateInterDeliveryDetails(interDeliveryDetails, options) {
  let interOrderType = null;
  if (options) {
    interOrderType = options.interOrderType;
  }
  return function(dispatch, getState) {
    const { cartReducer } = getState();
    const { orderSettingReducer } = getState();
    const locations = getState().locationsReducer.locations;
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const config = getState().authReducer.appConfig;
    const deliveryToAddress = getState().deliveryAddressesReducer
      .deliveryToAddress;
    const isGuestSession = config ? config.is_guest_checkout_enabled : false;

    const { interSelectedDateTime } = orderSettingReducer;

    let cart = cartForValidation(
      cartReducer,
      interDeliveryDetails,
      DELIVERY,
      orderSettingReducer,
      isAuthenticated
    );

    let order_date;
    if (interSelectedDateTime.radioValue.value === 'schedule') {
      order_date = interSelectedDateTime.dateTime.date
        ? moment(
            interSelectedDateTime.dateTime.date.name +
              ' ' +
              interSelectedDateTime.dateTime.time.name,
            'ddd MMM DD h:mm A'
          ).format('YYYY-MM-DD HH:mm:ss')
        : null;
    }

    if (order_date) {
      cart = {
        ...cart,
        order_date,
      };
    }

    const { is_order_pacing_enabled } = getState().authReducer.appConfig;

    let body = null;
    if (isAuthenticated && deliveryToAddress && deliveryToAddress.id) {
      body = { customer_address_id: deliveryToAddress.id };
    } else {
      const {
        line1,
        city,
        state,
        zip,
        country,
        is_preferred,
        type,
        details,
      } = interDeliveryDetails;

      body = {
        delivery_address: {
          line1,
          city,
          state,
          zip,
          country,
          is_preferred,
          type,
          details,
        },
      };
    }
    dispatch({ type: UPDATE_INTER_DELIVERY_DETAILS_REQUEST });
    api
      .post(`/order/settings`, body)
      .then(async (settingResponse) => {
        const dateModified = moment(order_date).format('YYYY-MM-DD');
        api
          .get(
            `/businesses/${settingResponse.data.data.business_id}/ordering-hours?date=${dateModified}`
          )
          .then(async (dateIntervalResponse) => {
            let deliveryBusiness = locations.find(
              (location) =>
                location.id === settingResponse.data.data.business_id
            );
            let orderPacingResponse = null;
            if (
              is_order_pacing_enabled &&
              deliveryBusiness.accepted_order_types.includes('delivery')
            ) {
              orderPacingResponse = await api
                .get(
                  `/businesses/${deliveryBusiness.id}/order-time-slots?order_type=delivery&date=${dateModified}`
                )
                .catch((err) => {
                  orderPacingResponse = {
                    is_asap_slot_available: true,
                    future_unavailable_slots: [],
                  };
                });
            }
            const orderPacingData = orderPacingResponse
              ? orderPacingResponse.data.data
              : {
                  is_asap_slot_available: true,
                  future_unavailable_slots: [],
                };

            deliveryBusiness = {
              ...deliveryBusiness,
              settings: settingResponse.data.data,
            };

            dispatch({
              type: UPDATE_INTER_DELIVERY_DETAILS,
              interDeliveryDetails: {
                ...interDeliveryDetails,
                settings: settingResponse.data.data,
                availableTimes: dateIntervalResponse.data.data,
                isDeliveryValid: true,
                deliveryBusiness,
              },
              unavailableProducts: [],
              interOrderType,
              orderPacingData,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_INTER_DELIVERY_DETAILS,
          interDeliveryDetails: {
            ...interDeliveryDetails,
            isDeliveryValid: false,
            deliveryBusiness: null,
          },
          cartErrorMessage: null,
          unavailableProducts: [],
          interOrderType,
          orderPacingData: {
            is_asap_slot_available: true,
            future_unavailable_slots: [],
          },
        });
      });
  };
}

export const formatSelectedDateTime = (dateTime) => {
  let formattedDateTime;
  if (
    !dateTime ||
    !dateTime.radioValue ||
    dateTime.radioValue === ASAP ||
    !dateTime.dateTime.time.name
  )
    formattedDateTime = { name: 'As Soon As Possible', value: ASAP };
  else if (dateTime.dateTime && dateTime.dateTime.date) {
    formattedDateTime = {
      name: dateTime.dateTime.date.name + ', ' + dateTime.dateTime.time.name,
      value:
        dateTime.dateTime.date.value.substr(0, 16) +
        dateTime.dateTime.time.value,
    };
  }
  return formattedDateTime;
};

export function updateInterDropOffDetails(interDropOffDetails, date) {
  return function(dispatch, getState) {
    if (!interDropOffDetails) {
      dispatch({
        type: UPDATE_INTER_DROPOFF_DETAILS,
        interDropOffDetails: null,
        unavailableProducts: [],
        availableTimes: [],
        orderPacingData: {
          is_asap_slot_available: true,
          future_unavailable_slots: [],
        },
      });
      return;
    }
    dispatch({ type: UPDATE_INTER_DROPOFF_DETAILS_REQUEST });
    const { cartReducer } = getState();
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const isGuestSession = getState().authReducer.isGuestSession;
    const { orderSettingReducer } = getState();
    const isCartEmpty =
      getState().cartReducer.products.length === 0 &&
      getState().cartReducer.combos.length === 0;

    const { is_order_pacing_enabled } = getState().authReducer.appConfig;

    const cart = cartForValidation(
      cartReducer,
      interDropOffDetails,
      PICKUP,
      orderSettingReducer
    );

    const getOrderTimeSlots = async (dateModified) => {
      try {
        return await api.get(
          `/businesses/${interDropOffDetails.id}/order-time-slots?order_type=drop_off&date=${dateModified}`
        );
      } catch (e) {
        return null;
      }
    };

    api
      .get(`/order/settings?business_id=${interDropOffDetails.id}`)
      .then((settingResponse) => {
        const dateModified = moment(date.substr(0, 15)).format('YYYY-MM-DD');
        api
          .get(
            `/businesses/${interDropOffDetails.id}/ordering-hours?date=${dateModified}`
          )
          .then(async (dateIntervalResponse) => {
            let orderPacingResponse = null;
            if (
              is_order_pacing_enabled &&
              !appConfig.isParentChildClient &&
              interDropOffDetails.accepted_order_types.includes('drop_off')
            ) {
              orderPacingResponse = await getOrderTimeSlots(dateModified);
            }
            const orderPacingData = orderPacingResponse
              ? orderPacingResponse.data.data
              : {
                  is_asap_slot_available: true,
                  future_unavailable_slots: [],
                };
            if (
              (isAuthenticated || isGuestSession) &&
              !isCartEmpty &&
              !appConfig.isParentChildClient
            ) {
              const path = isGuestSession
                ? `/guest/cart/validate`
                : `/cart/validate`;
              api
                .post(path, cart, {
                  ignore_interception: true,
                })
                .then(() => {
                  dispatch({
                    type: UPDATE_INTER_DROPOFF_DETAILS,
                    interDropOffDetails: {
                      ...interDropOffDetails,
                      settings: settingResponse.data.data,
                      isDropOffValid: true,
                    },
                    unavailableProducts: [],
                    availableTimes: dateIntervalResponse.data.data,
                    orderPacingData,
                  });
                })
                .catch((error) => {
                  if (
                    error.response.status === 400 &&
                    error.response.data.errors.message === 'Invalid cart'
                  ) {
                    const newCart =
                      error.response.data?.errors?.details?.new_cart?.cart ||
                      [];

                    const products = getState().cartReducer.products;
                    const combos = getState().cartReducer.combos;
                    const unavailableProducts = identifyCartChanges(
                      newCart,
                      products,
                      combos
                    );

                    dispatch({
                      type: UPDATE_INTER_DROPOFF_DETAILS,
                      interDropOffDetails: {
                        ...interDropOffDetails,
                        settings: settingResponse.data.data,
                        isDropOffValid: true,
                      },
                      unavailableProducts,
                      availableTimes: dateIntervalResponse.data.data,
                      orderPacingData,
                    });
                  } else {
                    dispatch({
                      type: UPDATE_INTER_DROPOFF_DETAILS,
                      interDropOffDetails: {
                        ...interDropOffDetails,
                        settings: settingResponse.data.data,
                        isDropOffValid: false,
                      },
                      unavailableProducts: [],
                      availableTimes: dateIntervalResponse.data.data,
                      orderPacingData,
                    });

                    // const unavailableProducts = identifyCartChanges(
                    //   null,
                    //   products
                    // );
                    // dispatch({
                    //   type: "UPDATE_UNAVAILABLE_PRODUCTS",
                    //   unavailableProducts,
                    // });
                  }
                });
            } else {
              dispatch({
                type: UPDATE_INTER_DROPOFF_DETAILS,
                interDropOffDetails: {
                  ...interDropOffDetails,
                  settings: settingResponse.data.data,
                  isDropOffValid: true,
                },
                cartErrorMessage: null,
                unavailableProducts: [],
                availableTimes: dateIntervalResponse.data.data,
                orderPacingData,
              });
            }
          });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_INTER_DROPOFF_DETAILS,
          interDropOffDetails: {
            ...interDropOffDetails,
            isDropOffValid: false,
          },
          cartErrorMessage: null,
          unavailableProducts: [],
          // availableTimes: null,
        });
      });
  };
}

export function updateInterSelectedDateTime(selectedDateTime) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_INTER_SELECTED_DATE_TIME,
      selectedDateTime,
    });
  };
}

export const setOrderingClosedModalIsVisible = (
  orderingClosedModalIsVisible
) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDERING_CLOSED_MODAL_IS_VISIBLE,
      orderingClosedModalIsVisible,
    });
  };
};

export function resetInterSelectedDateTime() {
  return function(dispatch) {
    dispatch({
      type: RESET_INTER_SELECTED_DATE_TIME,
    });
  };
}

export function updateOrderSettingModalIsVisible(
  orderSettingModalIsVisible,
  isTimeNoLongerValid
) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE,
      orderSettingModalIsVisible,
      isTimeNoLongerValid,
    });
  };
}

export function getTimeIntervalsDate(businessId, date) {
  return async function(dispatch, getState) {
    if (businessId) {
      const dateModified = moment(date.substr(0, 15)).format('YYYY-MM-DD');
      const { is_order_pacing_enabled } = getState().authReducer.appConfig;
      const { interOrderType } = getState().orderSettingReducer;

      let orderPacingResponse = null;
      if (is_order_pacing_enabled) {
        orderPacingResponse = await api
          .get(
            `/businesses/${businessId}/order-time-slots?order_type=${interOrderType}&date=${dateModified}`
          )
          .catch((err) => {
            orderPacingResponse = {
              is_asap_slot_available: true,
              future_unavailable_slots: [],
            };
          });
      }
      const orderPacingData = orderPacingResponse
        ? orderPacingResponse.data.data
        : { is_asap_slot_available: true, future_unavailable_slots: [] };

      api
        .get(`/businesses/${businessId}/ordering-hours?date=${dateModified}`)
        .then((response) => {
          dispatch({
            type: GET_DATE_TIMES,
            payload: response.data.data,
            orderPacingData,
          });
        });
    }
  };
}

export function resetDateTimes() {
  return function(dispatch) {
    dispatch({
      type: RESET_DATE_TIMES,
    });
  };
}

export function confirmPickUpDetails(fromOrderSettings) {
  return function(dispatch, getState) {
    const { interSelectedDateTime } = getState().orderSettingReducer;
    dispatch({
      type: CONFIRM_PICKUP_DETAILS,
      dateTimeToShow: formatSelectedDateTime(interSelectedDateTime),
      fromOrderSettings,
    });
    dispatch(updateOrderSettingModalIsVisible(false));
    dispatch(resetUnavailableProducts());
    dispatch(resetInterDeliveryDetails());
  };
}

export function confirmDeliveryDetails(fromOrderSettings) {
  return function(dispatch, getState) {
    const { interSelectedDateTime } = getState().orderSettingReducer;
    dispatch({
      type: CONFIRM_DELIVERY_DETAILS,
      dateTimeToShow: formatSelectedDateTime(interSelectedDateTime),
      fromOrderSettings,
    });
    dispatch(updateOrderSettingModalIsVisible(false));
    dispatch(resetUnavailableProducts());
  };
}

export function confirmDropOffDetails(fromOrderSettings) {
  return function(dispatch, getState) {
    const { interSelectedDateTime } = getState().orderSettingReducer;

    dispatch({
      type: CONFIRM_DROPOFF_DETAILS,
      dateTimeToShow: formatSelectedDateTime(interSelectedDateTime),
      fromOrderSettings,
      selectedDropOffLocation: getState().dropOffLocationReducer
        .selectedDropOffLocation,
    });
    dispatch(updateOrderSettingModalIsVisible(false));
    dispatch(resetUnavailableProducts());
    dispatch(resetInterDeliveryDetails());
  };
}

export function resetOrderSettingReducer() {
  return function(dispatch) {
    dispatch({
      type: RESET_ORDER_SETTING_REDUCER,
    });
  };
}

export function changePickUpLocation(location) {
  return function(dispatch, getState) {
    // const location = getState().locationsReducer.locations.find(
    //   ({id}) => id == locationId
    // );
    if (location) {
      const businessId = location.id;
      api.get(`/order/settings?business_id=${businessId}`).then((response) => {
        dispatch({
          type: CHANGE_PICKUP_LOCATION,
          location: { ...location, settings: response.data.data },
        });
      });
    }
  };
}

export function changePickUpLocationFromLocationsPage(location) {
  return function(dispatch, getState) {
    const fromLocationsScreen = true;
    const orderType = getState().orderSettingReducer.interOrderType;
    const interDeliveryDetails = getState().orderSettingReducer
      .interDeliveryDetails;

    if (location) {
      if (
        orderType === PICKUP &&
        location.accepted_order_types.includes(PICKUP)
      ) {
        dispatch(updateInterPickUpDetails(location, new Date().toString()));
      } else if (
        orderType === DELIVERY &&
        location.accepted_order_types.includes(DELIVERY)
      ) {
        dispatch(updateInterDeliveryDetails(interDeliveryDetails));
      } else if (
        orderType === DROPOFF &&
        location.accepted_order_types.includes(DROPOFF)
      ) {
        dispatch(updateInterDropOffDetails(location, new Date().toString()));
      }

      dispatch(validateGlobalCart(fromLocationsScreen));
    }
  };
}

export function resetIsTimeNoLongerValid() {
  return function(dispatch) {
    dispatch({
      type: RESET_TIME_NO_LONGER_VALID,
    });
  };
}

export function hideToolTip() {
  return function(dispatch) {
    dispatch({ type: HIDE_TOOL_TIP });
  };
}

export function resetUnavailableProducts() {
  return function(dispatch) {
    dispatch({ type: RESET_UNAVAILABLE_PRODUCTS });
  };
}

export function getBusinessSpecialHours(details, interOrderType) {
  return function(dispatch, getState) {
    let id = null;

    id =
      interOrderType === PICKUP
        ? details.id
        : interOrderType === DROPOFF
        ? details.id
        : details.deliveryBusiness.id;

    if (interOrderType === PICKUP || interOrderType === DROPOFF) {
      api
        .get(`/order/settings?business_id=${details.id}`)
        .then((settingResponse) => {
          const maxOrderDateModified = maxOrderDate(
            settingResponse.data.data.max_future_order_date
          );

          const dateModified = maxOrderDateModified.split(' ')[0];

          api
            .get(`/businesses/${id}/special-hours?date=${dateModified}`)
            .then((response) => {
              dispatch({
                type: GET_SPECIAL_HOURS,
                payload: response.data.data,
              });
            });
        });
    } else {
      const isAuthenticated = getState().authReducer.isAuthenticated;
      const interDeliveryDetails = getState().orderSettingReducer
        .interDeliveryDetails;
      let body = null;
      if (isAuthenticated) {
        body = { customer_address_id: details.id };
      } else {
        body = {
          delivery_address: {
            line1: interDeliveryDetails.line1,
            line2: interDeliveryDetails.line2,
            city: interDeliveryDetails.city,
            state: interDeliveryDetails.state,
            zip: interDeliveryDetails.zip,
            country: interDeliveryDetails.country,
            type: 'home',
            is_preferred: false,
          },
        };
      }
      api
        .post('/order/settings', body, { ignore_interception: true })
        .then((settingResponse) => {
          const maxOrderDateModified = maxOrderDate(
            settingResponse.data.data.max_future_order_date
          );

          const dateModified = maxOrderDateModified.split(' ')[0];

          api
            .get(`/businesses/${id}/special-hours?date=${dateModified}`)
            .then((response) => {
              dispatch({
                type: GET_SPECIAL_HOURS,
                payload: response.data.data,
              });
            });
        });
    }
  };
  // const maxOrderDateModified = maxOrderDate(
  //   details.settings.max_future_order_date
  // );
  // const dateModified = moment(maxOrderDateModified.substr(0, 15)).format(
  //   'YYYY-MM-DD'
  // );
  // api
  //   .get(`/businesses/${id}/special-hours?date=${dateModified}`)
  //   .then(response => {
  //     dispatch({
  //       type: GET_SPECIAL_HOURS,
  //       payload: response.data.data,
  //     });
  //   });
}
export function getParentInformation(id) {
  return api.get(`/businesses/${id}`).then((response) => response.data.data);
}

// export function setChildLocation(childLocation) {
//   return function(dispatch) {
//     dispatch({ type: SET_CHILD_LOCATIONS, payload: childLocation });
//   };
// }
export function updateOrderSettingModalIsVisibleForLocationsPage(
  orderSettingModalIsVisible,
  isTimeNoLongerValid
) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_ORDER_SETTING_MODAL_IS_VISIBLE_FOR_LOCATIONS_PAGE,
      orderSettingModalIsVisible,
      isTimeNoLongerValid,
    });
  };
}

export function clearSelectedLocation() {
  return function(dispatch) {
    dispatch({
      type: SET_SELECTED_LOCATION,
      location: null,
    });
  };
}
export function updateSelectedLocation(location) {
  return function(dispatch) {
    if (location) {
      const dateModified = moment().format('YYYY-MM-DD');
      Promise.all([
        api.get(
          `/businesses/${location.id}/ordering-hours?date=${dateModified}`
        ),
        api.get(`/order/settings?business_id=${location.id}`),
        // api.get(
        //   `/businesses/${location.id}/order-time-slots?order_type=pickup&date=${dateModified}`
        // ),
      ]).then((values) => {
        dispatch({
          type: SET_SELECTED_LOCATION,
          location: {
            ...location,
            orderingHours: values[0].data.data,
            settings: values[1].data.data,
            orderTimeSlots: null,
          },
        });
      });
    }
  };
}

export function updateOrderType(orderType) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_ORDER_TYPE,
      orderType,
    });
  };
}

export function resetMarketplaceDeliveryLocations() {
  return function(dispatch) {
    dispatch({
      type: RESET_MARKETPLACE_DELIVERY_LOCATIONS,
    });
  };
}

export function getMarketplaceDeliveryLocations(deliveryAddress) {
  return function(dispatch, getState) {
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const locations = getState().locationsReducer.locations;
    const childLocations = getState().orderSettingReducer.childLocations;
    const interSelectedDateTime = getState().orderSettingReducer
      .interSelectedDateTime;
    const parentId = getState().orderSettingReducer.parentLocation.id;

    let body = null;
    if (isAuthenticated) {
      if (deliveryAddress?.id) {
        body = { customer_address_id: deliveryAddress.id };
      } else {
        body = { delivery_address: deliveryAddress };
      }
    } else {
      body = { delivery_address: deliveryAddress };
    }

    let marketplaceLocationsOrderSettings = [];
    body = { ...body, parent_business_id: parentId };
    let order_date;
    if (interSelectedDateTime.radioValue.value === 'schedule') {
      order_date = interSelectedDateTime.dateTime.date
        ? moment(
            interSelectedDateTime.dateTime.date.name +
              ' ' +
              interSelectedDateTime.dateTime.time.name,
            'ddd MMM DD h:mm A'
          ).format('YYYY-MM-DD HH:mm:ss')
        : null;
    }
    dispatch({ type: GET_MARKETPLACE_DELIVERY_LOCATIONS_REQUEST });
    api
      .post(`/order/delivery-addresses`, body, { ignore_interception: true })
      .then((response) => {
        const validBusinesess = response.data.data.map(
          (locationSettings, index) => locationSettings.business_id
        );
        if (childLocations?.length > 0) {
          childLocations.forEach((store) => {
            if (validBusinesess?.includes(store.id)) {
              const filterdBusness = response.data.data.find(
                (item) => item.business_id === store.id
              );
              const fee = filterdBusness?.delivery_zone.fee;
              marketplaceLocationsOrderSettings.push({
                ...store,
                delivery_fee: fee,
              });
            }
          });
        }
        // dispatch({
        //   type: GET_CHILD_LOCATIONS,
        //   payload: marketplaceLocationsOrderSettings,
        // });
        dispatch({
          type: GET_MARKETPLACE_DELIVERY_LOCATIONS,
          marketplaceLocationsOrderSettings,
          deliveryAddress,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_MARKETPLACE_DELIVERY_LOCATIONS,
          marketplaceLocationsOrderSettings,
          deliveryAddress,
        });
      });
  };
}

export function confirmMarketplaceDeliverySelection(
  marketplaceDeliverySelection,
  seeMenu
) {
  return function(dispatch, getState) {
    const locations = getState().locationsReducer.locations;
    const childLocations = getState().orderSettingReducer.childLocations;
    const config = getState().authReducer.appConfig;
    const isAuthenticated = getState().authReducer.isAuthenticated;

    let deliveryBusiness;
    if (appConfig.isParentChildClient) {
      deliveryBusiness = childLocations.find(
        (location) => location.id === marketplaceDeliverySelection.business_id
      );
    } else {
      deliveryBusiness = locations.find(
        (location) => location.id === marketplaceDeliverySelection.business_id
      );
    }

    dispatch({ type: CONFIRM_MARKETPLACE_DELIVERY_DETAILS_REQUEST });

    if (!seeMenu) {
      dispatch({
        type: DELIVERY_TIME_FORM_IS_VISIBLE,
        isVisible: true,
      });
    } else {
      dispatch(confirmDeliveryDetails(true));
    }

    dispatch({
      type: CONFIRM_MARKETPLACE_DELIVERY_DETAILS,
      interDeliveryDetails: {
        ...marketplaceDeliverySelection,
      },
    });
  };
}

export function setDeliveryTimeFormIsVisible(
  isVisible,
  location,
  onlyCloseModal = false
) {
  return function(dispatch, getState) {
    if (onlyCloseModal) {
      dispatch({
        type: 'DELIVERY_TIME_FORM_ONLY_VISIBILITY',
        isVisible,
      });
    } else {
      const {
        interDeliveryDetails,
        interSelectedDateTime,
        interOrderType,
        orderPacingData,
      } = getState().orderSettingReducer;
      const { isAuthenticated } = getState().authReducer;

      const dateModified = moment().format('YYYY-MM-DD');

      let order_date;
      if (interSelectedDateTime.radioValue.value === 'schedule') {
        order_date = interSelectedDateTime.dateTime.date
          ? moment(
              interSelectedDateTime.dateTime.date.name +
                ' ' +
                interSelectedDateTime.dateTime.time.name,
              'ddd MMM DD h:mm A'
            ).format('YYYY-MM-DD HH:mm:ss')
          : null;
      }

      if (!location) {
        dispatch({
          type: DELIVERY_TIME_FORM_IS_VISIBLE,
          isVisible,
        });
      } else if (location && !isAuthenticated) {
        api
          .get(`/order/settings?business_id=${location.id}`)
          .then(async (settingResponse) => {
            await api
              .get(
                `/businesses/${settingResponse.data.data.business_id}/ordering-hours?date=${dateModified}`
              )
              .then((dateIntervalResponse) => {
                const settings = settingResponse.data.data;
                const updatedLocation = {
                  ...location,
                  deliveryBusiness: {
                    ...location.deliveryBusiness,
                    settings,
                  },
                };

                dispatch({
                  type: DELIVERY_TIME_FORM_IS_VISIBLE,
                  selectedLocation: updatedLocation,
                  isVisible,
                  availableTimes: dateIntervalResponse.data.data,
                });
              });
          });
      } else if (location && isAuthenticated) {
        const {
          line1,
          line2,
          city,
          state,
          country,
          type,
          is_preferred,
          details,
          zip,
        } = location;

        const payload = {
          line1,
          line2,
          city,
          state,
          country,
          type,
          is_preferred,
          details,
          zip,
        };

        //api.post("/customer/addresses", payload).then((address) => {
        api
          .get(`/order/settings?business_id=${location?.id}`)
          .then(async (settingResponse) => {
            await api
              .get(
                `/businesses/${settingResponse.data.data.business_id}/ordering-hours?date=${dateModified}`
              )
              .then((dateIntervalResponse) => {
                const settings = settingResponse.data.data;
                const updatedLocation = {
                  ...location,
                  deliveryBusiness: {
                    ...location.deliveryBusiness,
                    settings,
                  },
                };

                dispatch({
                  type: DELIVERY_TIME_FORM_IS_VISIBLE,
                  selectedLocation: updatedLocation,
                  isVisible,
                  availableTimes: dateIntervalResponse.data.data,
                });
              });
          });
        //});
      }
    }
  };
}

export function selectMarketplaceDeliveryLocation() {
  return function(dispatch, getState) {
    const {
      interDeliveryDetails,
      selectedLocation,
      interOrderType,
      orderPacingData,
    } = getState().orderSettingReducer;
    dispatch({
      type: UPDATE_INTER_DELIVERY_DETAILS,
      interDeliveryDetails: {
        id: interDeliveryDetails.id ? interDeliveryDetails.id : null,
        ...selectedLocation,
      },
      unavailableProducts: [],
      interOrderType,
      orderPacingData,
    });
  };
}

export function selectMarketplacePickUpLocation() {
  return function(dispatch, getState) {
    const { selectedLocation } = getState().orderSettingReducer;
    dispatch(setInterPickUpDetails(selectedLocation, new Date().toString()));
  };
}

export function setPickUpTimeFormIsVisible(
  isVisible,
  location,
  onlyCloseModal = false
) {
  return function(dispatch) {
    if (onlyCloseModal) {
      dispatch({
        type: 'PICKUP_TIME_FORM_ONLY_VISIBILITY',
        isVisible,
      });
    } else {
      updateSelectedLocation(null);
      if (location) {
        api
          .get(`/order/settings?business_id=${location.id}`)
          .then((settingResponse) => {
            const settings = settingResponse.data.data;
            const updatedLocation = {
              ...location,
              settings,
            };

            resetInterSelectedDateTime();
            resetIsTimeNoLongerValid();

            dispatch({
              type: PICKUP_TIME_FORM_IS_VISIBLE,
              isVisible,
              location: updatedLocation,
            });
          });
      } else {
        dispatch({
          type: PICKUP_TIME_FORM_IS_VISIBLE,
          isVisible,
        });
      }
    }
  };
}
// export function setPickUpTimeFormIsVisible(isVisible, location) {
//   return function(dispatch) {
//     updateSelectedLocation(null);
//     if (location) {
//       api
//         .get(`/order/settings?business_id=${location.id}`)
//         .then((settingResponse) => {
//           const settings = settingResponse.data.data;
//           const updatedLocation = {
//             ...location,
//             settings,
//           };

//           resetInterSelectedDateTime();
//           resetIsTimeNoLongerValid();

//           dispatch({
//             type: PICKUP_TIME_FORM_IS_VISIBLE,
//             isVisible,
//             location: updatedLocation,
//           });
//         });
//     } else {
//       dispatch({
//         type: PICKUP_TIME_FORM_IS_VISIBLE,
//         isVisible,
//       });
//     }
//   };
// }

export function setStartNewOrderFormIsVisible(isVisible) {
  return function(dispatch) {
    if (!isVisible) {
      dispatch({
        type: CHECK_UNAVAILABLE_PRODUCT_IN_CART,
        cartErrorMessage: null,
        unavailableProducts: [],
      });
    }
    dispatch({ type: START_NEW_ORDER_FORM_IS_VISIBLE, isVisible });
  };
}

export function checkUnavailableProductForMarketplace(
  marketplaceDeliveryLocation,
  seeMenu
) {
  return function(dispatch, getState) {
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const interSelectedDateTime = getState().orderSettingReducer
      .interSelectedDateTime;
    const { cartReducer } = getState();
    const { orderSettingReducer } = getState();
    const { selectedLocation, interOrderType } = getState().orderSettingReducer;
    const { deliveryToAddress } = getState().deliveryAddressesReducer;
    const products = getState().cartReducer.products;
    const isCartEmpty =
      getState().cartReducer.products.length === 0 &&
      getState().cartReducer.combos.length === 0;

    if (!isCartEmpty && marketplaceDeliveryLocation) {
      let cart = cartForValidation(
        cartReducer,
        marketplaceDeliveryLocation,
        DELIVERY,
        orderSettingReducer
      );

      cart = {
        ...cart,
        business_id: marketplaceDeliveryLocation.business_id,
      };

      const orderDate = getOrderDate(interSelectedDateTime);

      if (orderDate) {
        cart = { ...cart, order_date: orderDate };
      }

      dispatch({
        type: CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST,
      });
      cart = {
        ...cart,
        business_id: selectedLocation.id,
        order_type: interOrderType,
      };
      if (interOrderType === 'pickup') {
        delete cart?.customer_address_id;
      } else {
        cart = {
          ...cart,
          customer_address_id: deliveryToAddress.id,
        };
      }
      if (isAuthenticated && !isCartEmpty) {
        api
          .post('/cart/validate', cart, { ignore_interception: true })
          .then(() => {
            dispatch({
              type: CHECK_UNAVAILABLE_PRODUCT_IN_CART,
              cartErrorMessage: null,
              unavailableProducts: [],
            });

            if (seeMenu) {
              dispatch(
                confirmMarketplaceDeliverySelection(
                  marketplaceDeliveryLocation,
                  true
                )
              );
            }
          })
          .catch((error) => {
            dispatch({
              type: 'UPDATE_CART_ERROR_MESSAGE',
              errors: error.response.data.errors,
            });
            if (
              error.response.status === 400 &&
              error.response.data.errors.message === 'Invalid cart'
            ) {
              const newCart =
                error.response.data?.errors?.details?.new_cart?.cart || [];

              const products = getState().cartReducer.products;
              const combos = getState().cartReducer.combos;
              const unavailableProducts = identifyCartChanges(
                newCart,
                products,
                combos
              );

              dispatch({
                type: 'UPDATE_UNAVAILABLE_PRODUCTS',
                unavailableProducts,
              });

              // dispatch(updateCartWithUnavailbleProducts(unavailableProducts));

              if (seeMenu) {
                dispatch(
                  confirmMarketplaceDeliverySelection(
                    marketplaceDeliveryLocation,
                    true
                  )
                );
              }
            } else {
              const products = getState().cartReducer.products;
              const combos = getState().cartReducer.combos;
              const unavailableProducts = identifyCartChanges(
                null,
                products,
                combos
              );
              dispatch({
                type: 'UPDATE_UNAVAILABLE_PRODUCTS',
                unavailableProducts,
              });
            }
          });
      }
    } else {
      if (seeMenu) {
        dispatch(
          confirmMarketplaceDeliverySelection(marketplaceDeliveryLocation, true)
        );
      }
    }
  };
}

export function checkUnavailableProductForPickUp(interPickUpDetails) {
  return function(dispatch, getState) {
    const isAuthenticated = getState().authReducer.isAuthenticated;
    const interSelectedDateTime = getState().orderSettingReducer
      .interSelectedDateTime;
    const { cartReducer } = getState();
    const { orderSettingReducer } = getState();
    const isCartEmpty =
      getState().cartReducer.products.length === 0 &&
      getState().cartReducer.combos.length === 0;
    const { selectedLocation } = getState().orderSettingReducer;
    if (!isCartEmpty) {
      let cart = cartForValidation(
        cartReducer,
        interPickUpDetails,
        PICKUP,
        orderSettingReducer
      );

      cart = {
        ...cart,
        business_id: interPickUpDetails.id,
      };

      const orderDate = getOrderDate(interSelectedDateTime);

      if (orderDate) {
        cart = { ...cart, order_date: orderDate };
      }

      dispatch({
        type: CHECK_UNAVAILABLE_PRODUCT_IN_CART_REQUEST,
      });

      cart = { ...cart, business_id: selectedLocation.id };
      if (isAuthenticated && !isCartEmpty) {
        api
          .post('/cart/validate', cart, { ignore_interception: true })
          .then(() => {
            dispatch({
              type: CHECK_UNAVAILABLE_PRODUCT_IN_CART,
              cartErrorMessage: null,
              unavailableProducts: [],
            });
          })
          .catch((error) => {
            dispatch({
              type: 'UPDATE_CART_ERROR_MESSAGE',
              errors: error.response.data.errors,
            });
            if (
              error.response.status === 400 &&
              error.response.data.errors.message === 'Invalid cart'
            ) {
              const newCart =
                error.response.data?.errors?.details?.new_cart?.cart || [];

              const products = getState().cartReducer.products;
              const combos = getState().cartReducer.combos;
              const unavailableProducts = identifyCartChanges(
                newCart,
                products,
                combos
              );

              dispatch({
                type: 'UPDATE_UNAVAILABLE_PRODUCTS',
                unavailableProducts,
              });

              dispatch(updateCartWithUnavailbleProducts(unavailableProducts));
            } else {
              const products = getState().cartReducer.products;
              const combos = getState().cartReducer.combos;
              const unavailableProducts = identifyCartChanges(
                null,
                products,
                combos
              );
              dispatch({
                type: 'UPDATE_UNAVAILABLE_PRODUCTS',
                unavailableProducts,
              });
            }
          });
      }
    }
  };
}

export function resetInterDeliveryDetails() {
  return function(dispatch) {
    dispatch({
      type: RESET_INTER_DELIVERY_DETAILS,
    });
  };
}

export function setMarketplaceDeliverySelection(marketplaceDeliverySelection) {
  return function(dispatch) {
    dispatch({
      type: SET_MARKETPLACE_DELIVERY_SELECTION,
      marketplaceDeliverySelection,
    });
  };
}

export function setParentLocation(parentLocation) {
  return function(dispatch) {
    api
      .get(`/order/settings?business_id=${parentLocation.id}`)
      .then((settingResponse) => {
        const acceptedOrderTypes =
          settingResponse.data.data.accepted_order_types;
        let singleOrderType = null;
        if (acceptedOrderTypes?.length === 1) {
          singleOrderType = acceptedOrderTypes[0];
          dispatch({
            type: 'UPDATE_SINGLE_ORDER_TYPE',
            singleOrderType,
          });
          dispatch(updateInterOrderType(singleOrderType));
          dispatch(updateOrderType(singleOrderType));
        } else {
          dispatch({
            type: 'UPDATE_SINGLE_ORDER_TYPE',
            singleOrderType,
          });
        }
        dispatch({
          type: SET_PARENT_LOCATION,
          parentLocation: {
            ...parentLocation,
            settings: settingResponse.data.data,
          },
        });
      });
  };
}

export function getChildLocations(parentLocationId) {
  return function(dispatch) {
    api.get(`/businesses/${parentLocationId}`).then((payload) => {
      dispatch({
        type: SET_CHILD_LOCATIONS,
        childLocations: payload.data.data.sub_businesses,
      });
    });
  };
}

export function setInterDeliveryDetailsId(id) {
  return function(dispatch) {
    dispatch({
      type: SET_INTER_DELIVERY_DETAILS_ID,
      id,
    });
  };
}

export function setIsStoreDeepLink(isDeepLink) {
  return function(dispatch) {
    dispatch({
      type: SET_IS_STORE_DEEP_LINK,
      isDeepLink,
    });
  };
}

export function setIsParentStoreDeepLink(isDeepLink) {
  return function(dispatch) {
    dispatch({
      type: SET_IS_PARENT_STORE_DEEP_LINK,
      isDeepLink,
    });
  };
}

export function setIsMenuDisabled(isMenuDisabled) {
  return function(dispatch) {
    dispatch({ type: SET_IS_MENU_DISABLED, isMenuDisabled });
  };
}
