import React from 'react';
import styled from 'styled-components';
import {PrimaryButton, ButtonRow} from '../../../components/Buttons';
import {Title1, Body} from '../../../components/Typography';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
  padding: 32px;
`;

const InfoWrapper = styled.div``;

const InfoTitle1 = styled(Title1)`
  color: ${props => props.theme.productCustomizerTitleFontColor};
  margin-bottom: 24px;
`;

const Description = styled(Body)`
  margin: 8px 0;
`;

const CloseIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const CloseButton = styled(PrimaryButton)`
  min-width: 200px;
`;

const CloseIconWrapper = styled.div``;

const OrderingClosed = ({handleClose}) => {
  return (
    <Wrapper id="comboModalContainer" name="comboModalContainer">
      <CloseIconWrapper>
        {/* <CloseIcon onClick={handleClose}>
          <i className="fal fa-times" />
        </CloseIcon> */}
      </CloseIconWrapper>
      <InfoWrapper>
        <InfoTitle1 id="OrderingClosed-Title">
          Ordering is currently unavailable
        </InfoTitle1>
        <Description>
          We are currently unable to accept any orders. Please check back later.
          We apologize for the inconvenience.
        </Description>
        {/* <ButtonRow id="OrderingClosed-CloseButton" style={{marginTop: '30px'}} justifyContent="center">
          <CloseButton onClick={handleClose}>Close</CloseButton>
        </ButtonRow> */}
      </InfoWrapper>
    </Wrapper>
  );
};

export default OrderingClosed;
