import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {
  timesAvailable,
  datesAvailable,
  isScheduleRadioDisabledCalculated,
  isLocationAcceptingOrders,
} from '../../OrderSetting/OrderSettingModal/utils/helper';
import DateTimeSelect from './DateTimeSelect';
import useTimeIntervalsDate from '../../../customHooks/useTimeIntervalsDate';
import {
  UPDATE_INTER_SELECTED_DATE_TIME,
  RESET_TIME_NO_LONGER_VALID,
  PICKUP,
  DROPOFF,
} from '../../OrderSetting/orderSettingTypes';

function OrderFutureSchedule({orderMethodDetails}) {
  const dispatch = useDispatch();

  const {
    interOrderType,
    availableTimes,
    specialHours,
    interSelectedDateTime,
    isTimeNoLongerValid,
    selectedDateTime,
    selectedLocation,
  } = useSelector(state => state.orderSettingReducer);

  const orderStartTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_start_time
      : availableTimes[0].order_start_time);

  const orderEndTime =
    availableTimes &&
    availableTimes.length &&
    (availableTimes.length > 1
      ? availableTimes[1].order_end_time
      : availableTimes[0].order_end_time);
  
  const maxFutureOrderDate = orderMethodDetails
    ? orderMethodDetails.settings.max_future_order_date
    : null;

  const isOnline = orderMethodDetails
    ? orderMethodDetails.settings.online
    : true;

  const minPrepTime = orderMethodDetails
    ? interOrderType === PICKUP
      ? orderMethodDetails.settings.min_pickup_time
      : interOrderType === DROPOFF
      ? orderMethodDetails.settings.min_delivery_time
      : null
    : null;

  const isLocationOrDelivBusinesssAcceptingOrders = isLocationAcceptingOrders(
    orderMethodDetails ? orderMethodDetails.accepting_orders : null,
    specialHours
  );

  const isAsapDisabled = !isLocationOrDelivBusinesssAcceptingOrders;

  const isScheduleDisabled = isScheduleRadioDisabledCalculated(
    availableTimes,
    maxFutureOrderDate,
    orderEndTime,
    specialHours,
    isOnline
  );

  const handleDateChange = date => {
    const selectedDateTime = {
      radioValue: 'schedule',
      dateTime: {
        date: {
          name: moment(date).format('ddd MMM DD'),
          value: moment(date).toString(),
        },
        time: {
          name: '',
          value: '',
        },
      },
    };

    dispatch({
      type: UPDATE_INTER_SELECTED_DATE_TIME,
      selectedDateTime,
    });

    dispatch({
      type: RESET_TIME_NO_LONGER_VALID,
    });
  };

  const handleTimeChange = time => {
    const selectedDateTime = {
      ...interSelectedDateTime,
      dateTime: {
        ...interSelectedDateTime.dateTime,
        time: {name: time, value: time},
      },
    };
    dispatch({
      type: RESET_TIME_NO_LONGER_VALID,
    });
    dispatch({
      type: UPDATE_INTER_SELECTED_DATE_TIME,
      selectedDateTime,
    });
  };

  const handleSelectChange = (event, timeOptions) => {
    const filteredArray = timeOptions.filter(time => time.value === event);
    const selectedDateTime = {
      radioValue: {
        name: filteredArray[0].name,
        value: event,
      },
      dateTime: {
        date: {
          name: interSelectedDateTime.dateTime.date.name.length
            ? interSelectedDateTime.dateTime.date.name
            : moment().format('ddd MMM DD'),
          value: interSelectedDateTime.dateTime.date.value.length
            ? interSelectedDateTime.dateTime.date.value
            : moment().toString(),
        },
        time: {
          name: '',
          value: '',
        },
      },
    };
    dispatch({
      type: RESET_TIME_NO_LONGER_VALID,
    });
    dispatch({
      type: UPDATE_INTER_SELECTED_DATE_TIME,
      selectedDateTime,
    });
  };

  return (
    <div>
      <DateTimeSelect
        orderMethodDetails={orderMethodDetails}
        interSelectedDateTime={interSelectedDateTime}
        selectedDateTime={selectedDateTime}
        maxFutureOrderDate={maxFutureOrderDate}
        datesOptions={datesAvailable(maxFutureOrderDate, specialHours)}
        onDateChange={date => handleDateChange(date)}
        timesOptions={timesAvailable(
          availableTimes,
          minPrepTime,
          interSelectedDateTime
        )}
        onTimeChange={time => handleTimeChange(time)}
        isTimeNoLongerValid={isTimeNoLongerValid}
        noTimesAvailable={timesAvailable.length}
        isAsapDisabled={isAsapDisabled}
        isScheduleDisabled={isScheduleDisabled}
        handleSelectChange={(ev, opt) => handleSelectChange(ev, opt)}
        location={selectedLocation}
        value={interSelectedDateTime.radioValue.value}
      />
    </div>
  );
}

export default OrderFutureSchedule;
