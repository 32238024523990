export const GET_PRODUCT_TO_CONFIGURE_REQUEST =
  'GET_PRODUCT_TO_CONFIGURE_REQUEST';
export const GET_PRODUCT_TO_CONFIGURE_SUCCESS =
  'GET_PRODUCT_TO_CONFIGURE_SUCCESS';
export const GET_PRODUCT_TO_CONFIGURE_FAILURE =
  'GET_PRODUCT_TO_CONFIGURE_FAILURE';

export const CHANGE_VARIANT_REQUEST = 'CHANGE_VARIANT_REQUEST';
export const CHANGE_VARIANT_SUCCESS = 'CHANGE_VARIANT_SUCCESS';
export const CHANGE_VARIANT_FAILURE = 'CHANGE_VARIANT_FAILURE';

export const UPDATE_PRICE = 'UPDATE_PRICE';

export const INCREMENT_ITEM = 'INCREMENT_ITEM';
export const DECREMENT_ITEM = 'DECREMENT_ITEM';
export const INCREMENT_SUB_ITEM = 'INCREMENT_SUB_ITEM';
export const DECREMENT_SUB_ITEM = 'DECREMENT_SUB_ITEM';

export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_SUB_ITEM = 'REMOVE_SUB_ITEM';

export const SET_DRAWER_MODAL_OPEN = 'SET_DRAWER_MODAL_OPEN';

export const EDIT_PRODUCT_CONFIGURATION = 'EDIT_PRODUCT_CONFIGURATION';
export const CANCEL_EDIT_PRODUCT_CONFIGURATION =
  'CANCEL_EDIT_PRODUCT_CONFIGURATION';

export const CLEAR_PRODUCT_TO_CONFIGURE = 'CLEAR_PRODUCT_TO_CONFIGURE';

export const VALIDATE_PRODUCT = 'VALIDATE_PRODUCT';
export const VALIDATE_OPTION = 'VALIDATE_OPTION';

export const EDIT_PREVIOUS_PRODUCT_CONFIGURATION_REQUEST =
  'EDIT_PREVIOUS_PRODUCT_CONFIGURATION_REQUEST';
export const EDIT_PREVIOUS_PRODUCT_CONFIGURATION_SUCCESS =
  'EDIT_PREVIOUS_PRODUCT_CONFIGURATION_SUCCESS';
export const ADD_ORDER_INSTRUCTION = 'ADD_ORDER_INSTRUCTION';
