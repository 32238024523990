import { navigate } from 'gatsby';
import {
  PICKUP,
  DELIVERY,
  DROPOFF,
} from '../../OrderSetting/orderSettingTypes';
import { isOrderTimeValid } from './helper';
import { isLocationAcceptingOrders } from '../../OrderSetting/OrderSettingModal/utils/helper';
import appConfig from '../../../../app-config';

export const reviewOrder = ({
  amountEditOrderConfig,
  orderType,
  interPickUpDetails,
  deliveryDetails,
  deliveryBusiness,
  selectedDateTime,
  availableTimes,
  dateTimeToShow,
  updateOrderSettingModalIsVisible,
  specialHours,
  interDeliveryDetails,
  interDropOffDetails,
  dropOffDetails,
  isFoodRequired,
}) => {
  // if (
  //   !isOrderTimeValid(
  //     selectedDateTime,
  //     interPickUpDetails,
  //     interDeliveryDetails,
  //     orderType,
  //     availableTimes,
  //     dateTimeToShow,
  //     dropOffDetails
  //   )
  // ) {
  //   console.log('1');
  //   updateOrderSettingModalIsVisible(true, true);
  // } else if (
  //   amountEditOrderConfig === 0 ||
  //   (orderType === PICKUP &&
  //     ((interPickUpDetails && !interPickUpDetails.is_order_ahead_enabled) ||
  //       !interPickUpDetails.settings.online)) ||
  //   (orderType === DELIVERY &&
  //     ((interDeliveryDetails &&
  //       interDeliveryDetails.deliveryBusiness &&
  //       !interDeliveryDetails.deliveryBusiness.is_order_ahead_enabled) ||
  //       !interDeliveryDetails.deliveryBusiness.settings.online)) ||
  //   (orderType === DROPOFF &&
  //     (dropOffDetails?.finalDropOffLocation.length === 0 ||
  //       (dropOffDetails && !dropOffDetails.is_order_ahead_enabled) ||
  //       !interDropOffDetails.settings.online))
  // ) {
  //   console.log('2');
  //   updateOrderSettingModalIsVisible(true);
  // } else if (
  //   orderType === PICKUP &&
  //   appConfig.isFutureOrderDelivery &&
  //   !isLocationAcceptingOrders(
  //     interPickUpDetails.accepting_orders,
  //     specialHours
  //   ) &&
  //   !selectedDateTime.dateTime.time.value
  // ) {
  //   console.log('3');
  //   updateOrderSettingModalIsVisible(true);
  // } else {
  // }
  navigate('/cart', { state: { isFoodRequired: isFoodRequired } });
};
