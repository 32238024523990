import {
  GET_HOME_FEED_REQUEST,
  GET_HOME_FEED_SUCCESS,
  GET_HOME_FEED_FAILURE,
} from './homeFeedTypes';

export default function(
  state = {
    requestingGetHomeFeed: false,
    homeFeed: null,
  },
  action
) {
  switch (action.type) {
    case GET_HOME_FEED_REQUEST:
      return {
        ...state,
        requestingGetHomeFeed: true,
      };
    case GET_HOME_FEED_SUCCESS:
      return {
        ...state,
        homeFeed: action.response.data,
        requestingGetHomeFeed: false,
      };
    case GET_HOME_FEED_FAILURE:
      return {
        ...state,
        requestingGetHomeFeed: false,
      };
    default:
      return state;
  }
}
