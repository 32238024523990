export const SHOW_GIFT_CARD_MODAL = 'SHOW_GIFT_CARD_MODAL';
export const INITIATE_ADD_GIFT_CARD = 'INITIATE_ADD_GIFT_CARD';
export const RESET_GIFT_CARD_REDUCER = 'RESET_GIFT_CARD_REDUCER';
export const INITIATE_CONNECTING_EXISTING_GIFT_CARD =
  'INITIATE_CONNECTING_EXISTING_GIFT_CARD';
export const CONNECT_EXISTING_GIFT_CARD_SUCCESS =
  'CONNECT_EXISTING_GIFT_CARD_SUCCESS';
export const INITIATE_RELOAD_GIFT_CARD = 'INITIATE_RELOAD_GIFT_CARD';
export const RELOAD_GIFT_CARD = 'RELOAD_GIFT_CARD';
export const SHOW_CONFIRM_DELETE_MODAL_GIFT_CARD =
  'SHOW_CONFIRM_DELETE_MODAL_GIFT_CARD';
export const HIDE_CONFIRM_DELETE_MODAL_GIFT_CARD =
  'HIDE_CONFIRM_DELETE_MODAL_GIFT_CARD';
