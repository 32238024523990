import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  createAnonymousSession,
  getAppConfig,
  loadReactGA,
  resetGuestSession,
  logout,
} from '../Auth/authActions';
import { getCustomerProfile } from '../ProfileSettings/profileSettingsActions';
import { getPaymentMethods } from '../PaymentMethods/paymentMethodsActions';
import { getHomeFeed } from '../HomeFeed/homeFeedActions';
import { getOffers } from '../Offers/offersActions';
import { getOrderHistory } from '../OrderHistory/orderHistoryActions';
import { getMyTransactionsList } from '../MyTransactions/MyTransactionsActions';
import {
  initializeValidateCartWithNull,
  resetGuestPersonalDetails,
  resetPlaceOrderRequest,
} from '../Cart/cartActions';
import GetLocationsContainer from './Locations/GetLocationsContainer';
import GetMenuContainer from './Menu/GetMenuContainer';
import {
  getFavourites,
  updateCartFavouritedProducts,
} from '../Favourites/favouritesActions';
import { getLoyalty } from '../Loyalty/loyaltyActions';
import { getDeliveryAddresses } from '../DeliveryAddresses/deliveryAddressesActions';
import appConfig from '../../../app-config';
import { PICKUP } from '../OrderSetting/orderSettingTypes';

const OnAppLoadContainer = ({
  authReducer,
  createAnonymousSession,
  getHomeFeed,
  getCustomerProfile,
  customerProfile,
  paymentMethods,
  getPaymentMethods,
  offers,
  getOffers,
  getLoyalty,
  orderHistory,
  getOrderHistory,
  getFavourites,
  favouritesList,
  loyalty,
  orderPlaced,
  deliveryAddressesList,
  getDeliveryAddresses,
  getAppConfig,
  getMyTransactionsList,
  updateCartFavouritedProducts,
  initializeValidateCartWithNull,
  loadReactGA,
  orderSettingReducer,
  resetGuestPersonalDetails,
  alert,
  logout,
}) => {
  const {
    session_id,
    isAuthenticated,
    emailIsUnconfirmed,
    isGuestSession,
  } = authReducer;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const {
    selectedChild,
    interPickUpDetails,
    deliveryDetails,
    orderType,
    selectedDateTime,
    orderSettingModalIsVisible,
  } = orderSettingReducer;
  const config = authReducer.appConfig;

  useEffect(() => {
    initializeValidateCartWithNull();
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (config && config.guest_checkout_enabled) {
      resetGuestSession();
      resetGuestPersonalDetails();
    }
  }, []);

  useEffect(() => {
    if (alert && alert.type === 'error') {
      if (alert.message === 'Access Denied' && isAuthenticated) {
        logout();
      }
    }
  }, [alert]);

  useEffect(() => {
    if (!session_id) {
      createAnonymousSession();
    } else if (session_id && !emailIsUnconfirmed) {
      getHomeFeed();
      getAppConfig();
      loadReactGA();
    }
  }, [session_id]);

  useEffect(() => {
    if (!isFirstRender) {
      updateCartFavouritedProducts();
    }
  }, [favouritesList]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!customerProfile) {
        getCustomerProfile();
      }
      if (!emailIsUnconfirmed) {
        getLoyalty();
      }
      if (!paymentMethods) {
        getPaymentMethods();
      }
      if (!orderHistory.length) {
        getOrderHistory();
      }
      if (!favouritesList.length) {
        getFavourites();
      }
      if (!deliveryAddressesList.length) {
        getDeliveryAddresses();
      }
      if (appConfig.isOffersEnabled && !offers) {
        getOffers();
      }
      getAppConfig();
      getMyTransactionsList();
      // getLoyalty();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (orderPlaced && !isGuestSession) {
      getOrderHistory();
      // if (appConfig.isLoyaltyEnabled) {
      //   getLoyalty();
      // }
      getLoyalty();
      if (appConfig.isOffersEnabled) {
        getOffers();
      }
    }
  }, [orderPlaced]);

  useEffect(() => {
    let business;
    if (isAuthenticated) {
      let business_id =
        orderSettingReducer.orderType === PICKUP
          ? interPickUpDetails?.id
          : deliveryDetails?.id;
      if (appConfig.isParentChildClient) {
        business_id = selectedChild ? selectedChild.id : null;
      }
      if (appConfig.isOffersEnabled) {
        getOffers();
      }
    }
  }, [
    selectedChild,
    interPickUpDetails,
    deliveryDetails,
    orderType,
    selectedDateTime,
  ]);

  return (
    <Fragment>
      {!isFirstRender && <GetLocationsContainer />}
      <GetMenuContainer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  customerProfile: state.profileSettingsReducer.customerProfile,
  requestingPlaceOrder: state.cartReducer.requestingPlaceOrder,
  paymentMethods: state.paymentMethodsReducer.paymentMethods,
  offers: state.offersReducer.offers,
  authReducer: state.authReducer,
  orderHistory: state.orderHistoryReducer.orderHistory,
  favouritesList: state.favouritesReducer.favouritesList,
  loyalty: state.loyaltyReducer.loyalty,
  orderPlaced: state.cartReducer.orderPlaced,
  deliveryAddressesList: state.deliveryAddressesReducer.deliveryAddressesList,
  offers: state.offersReducer.offers,
  orderSettingReducer: state.orderSettingReducer,
});

const actions = {
  getMyTransactionsList,
  createAnonymousSession,
  getHomeFeed,
  getCustomerProfile,
  getPaymentMethods,
  getOffers,
  getOrderHistory,
  getFavourites,
  getLoyalty,
  getDeliveryAddresses,
  getAppConfig,
  updateCartFavouritedProducts,
  initializeValidateCartWithNull,
  loadReactGA,
  resetGuestSession,
  resetGuestPersonalDetails,
  logout,
  resetPlaceOrderRequest,
};

export default connect(mapStateToProps, actions)(OnAppLoadContainer);
