import {useState, useEffect} from 'react';
import api from '../../../api';

async function getChildLocations(businessId) {
  const {data} = await api.get(`/businesses/${businessId}`);
  return data;
}

function useChildLocations(businessId) {
  const [childLocations, setChildLocations] = useState(null);

  useEffect(() => {
    if (businessId) {
      getChildLocations(businessId).then(locations =>
        setChildLocations(locations.data.sub_businesses)
      );
    }
  }, [businessId]);

  return {childLocations};
}

export default useChildLocations;
