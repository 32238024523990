import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll'

const Wrapper = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.white};

  .activeSubOption {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.categoriesBarSelectedBorderColor};
    border-radius: 18px;
  }  
`

const ScrollWrapper = styled.div`
  overflow-x: scroll;
  display: block;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`

const SubOptionLink = styled(Link)`
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  cursor: pointer;
  padding: 8px 10px;
`

const SubOptionBar = ({
  subOptions
}) => {
  const scrollToSubOption = (subOptionId) => {
    const scrollWrapper = document
      .getElementById('product_customizer_suboption_scroll_wrapper')
    const scrollWrapperRect = scrollWrapper
      .getBoundingClientRect();

    const activeSubOptionRect = document
      .getElementById(`product_customizer_suboption_${subOptionId}`)
      .getBoundingClientRect();

    scrollWrapper.scrollLeft +=
      activeSubOptionRect.left - scrollWrapperRect.left - 35; // use -20 to show just the selected category
  };
  return (
    <Wrapper>
      <ScrollWrapper id="product_customizer_suboption_scroll_wrapper">
        {subOptions.map((subOption) => 
          <SubOptionLink
            id={`product_customizer_suboption_${subOption.option_id}`}
            key={`product_customizer_suboption_${subOption.option_id}`}
            to={`suboption_${subOption.option_id}`}
            activeClass="activeSubOption"
            containerId="containerElement"
            spy={true}
            smooth={true}
            offset={-90}
            duration={300}
            containerId="productOptionDrawers"
            onSetActive={() => scrollToSubOption(subOption.option_id)}
          >
              {subOption.name}
          </SubOptionLink>
        )}
      </ScrollWrapper>
    </Wrapper>
  );
};

export default SubOptionBar;
