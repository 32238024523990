export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const SET_SIGNUP_FAIL_ERROR = 'SET_SIGNUP_FAIL_ERROR';

export const CONFIRM_SMS_REQUEST = 'CONFIRM_SMS_REQUEST';
export const CONFIRM_SMS_SUCCESS = 'CONFIRM_SMS_SUCCESS';
export const CONFIRM_SMS_FAILURE = 'CONFIRM_SMS_FAILURE';

export const TO_CONFIRM_SMS = 'TO_CONFIRM_SMS';

export const RESET_REGISTRATION_STATES = 'RESET_REGISTRATION_STATES';
