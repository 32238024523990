import React from 'react';
import hexToRgbA from '../../utils/hexToRgbA';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily.main,
    fontSize: '15px',
  },
  radio: {
    color: '#d8d8d8',
    '&$checked': {
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: hexToRgbA(theme.palette.primary.main, 0.1),
      },
    },
    '&:hover': {
      backgroundColor: hexToRgbA(theme.palette.primary.main, 0.1),
    },
    '&$disabled': {
      color: '#d8d8d8',
    },
  },
  checked: {},
  disabled: {
    color: '#d8d8d8',
  },
});

let ControlledRadio = ({value, label, classes, disabled}) => (
  <FormControlLabel
    value={value}
    control={
      <Radio
        disabled={disabled}
        classes={{
          root: classes.radio,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
      />
    }
    label={label}
    classes={{label: classes.label}}
    disabled={disabled}
  />
);

export default withStyles(styles)(ControlledRadio);
