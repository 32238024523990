import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Caption2 } from '../../components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import FormGrid from '../../components/Form/FormGrid';
import { updateOrderSettingModalIsVisible } from '../OrderSetting/orderSettingActions';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonRow,
} from '../../components/Buttons';
import WarningBox from './WarningBox';
import PasswordField from '../../layout/components/PasswordField';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 14px;
  margin-top: -25px;
`;

const FormLayout = styled(FormGrid)`
  grid-gap: 24px;
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const ForgotLoginParams = styled(Caption2)`
  color: ${(props) => props.theme.clickableTextColor};
  cursor: pointer;
  margin-top: -13px;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

const isEmail = new RegExp(
  '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
    '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
);

const LoginForm = ({
  classes,
  login,
  handleChangeScreen,
  resetAuthReducer,
  resetCheckedEmail,
  setEmailOrPhoneField,
  loginFailureError,
  appConfig,
  setShowGuestPersonalDetailsPage,
  updateCartScreen,
  setAuthenticationModalIsVisible,
  cartScreen,
  requestingLogin,
  updateOrderSettingModalIsVisible,
}) => {
  useEffect(() => {
    return function cleanup() {
      setEmailOrPhoneField('');
      resetAuthReducer();
      resetCheckedEmail();
    };
  }, []);
  const [showPassword, setShowPassword] = useState(true);

  return (
    <Wrapper>
      {loginFailureError && (
        <WarningBox
          message={
            loginFailureError === 'Wrong username/password' ||
            loginFailureError === 'Account does not exist'
              ? 'Wrong email or password'
              : loginFailureError
          }
        />
      )}
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!isEmail.test(values.email)) {
            errors.email = 'Please put a valid email format';
          }
          if (!values.password) {
            errors.password = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          login(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                id="EmailForm-Username"
                label="Email"
                name="email"
                type="email"
                autoComplete="username"
                value={values.email}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                error={touched.email && errors.email ? true : false}
                helperText={touched.email && errors.email}
                InputLabelProps={{
                  className: classes.input,
                  required: false,
                }}
              />
              {/* <ForgotLoginParams
                id="LoginForm-ForgotEmail"
                onClick={() => setForgotEmailModalIsVisible(true)}
              >
                Forgot Email?
              </ForgotLoginParams> */}
              <PasswordField
                id="RegistrationForm-PasswordField"
                label="Password"
                name="password"
                autoComplete="off"
                value={values.password}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                classes={classes}
                helperText={touched.password && errors.password}
                error={touched.password && errors.password ? true : false}
              />
              <ForgotLoginParams
                id="LoginForm-ForgotPassword"
                onClick={() => handleChangeScreen(2)}
              >
                Forgot Password?
              </ForgotLoginParams>
              <ButtonRow justifyContent="center" row>
                <PrimaryButton
                  id="EmailForm-Submit"
                  type="submit"
                  disabled={
                    !values.email || !values.password || requestingLogin
                  }
                  buttonWidth="240px"
                  buttonHeight="56px"
                  buttonBorderRadius="10px"
                  fontWeight="600"
                >
                  Login
                </PrimaryButton>
              </ButtonRow>
            </FormLayout>
          </form>
        )}
        {/*  */}
      </Formik>
      {appConfig && appConfig.guest_checkout_enabled ? (
        <ButtonRow justifyContent="center">
          <SecondaryButton
            id="Continue-As-Guest"
            buttonWidth="240px"
            buttonHeight="56px"
            buttonBorderRadius="10px"
            fontWeight="600"
            onClick={() => {
              if (cartScreen === 'review') {
                setShowGuestPersonalDetailsPage(true);
                updateCartScreen('guest-order');
              } else {
                setAuthenticationModalIsVisible(false);
              }
            }}
          >
            Continue as Guest
          </SecondaryButton>
        </ButtonRow>
      ) : null}
    </Wrapper>
  );
};
export default withStyles(styles)(
  connect(null, { updateOrderSettingModalIsVisible })(LoginForm)
);
// export default withStyles(styles)(LoginForm);
