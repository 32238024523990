import moment, { weekdays } from "moment";
import { useEffect } from "react";
import appConfig from "../../../../../app-config";

export const PICKUP = "pickup";
export const DELIVERY = "delivery";
export const DROPOFF = "drop_off";

let today = moment();

export const datesAvailable = (maxFutureOrderDate, specialHours) => {
  var dateArray = [];
  var currentDate = moment();
  var maxDateModified = maxOrderDate(maxFutureOrderDate);
  var maxDate = moment(maxDateModified);
  while (currentDate.isSameOrBefore(maxDate, "day")) {
    const specialHoursModified = specialHours
      ? specialHours.filter(
          (day) => day.date === currentDate.format("YYYY-MM-DD")
        )
      : [];
    if (
      specialHoursModified.length === 1 &&
      !specialHoursModified[0].order_start_time &&
      !specialHoursModified[0].order_end_time
    ) {
      currentDate = currentDate.add(1, "days");
    } else {
      dateArray.push({
        name: `${currentDate.format("ddd MMM DD").toString()}`,
        value: `${currentDate.toString()}`,
      });
      currentDate = currentDate.add(1, "days");
    }
  }
  return dateArray;
};

export const availableTimesTransform = (availableTimes) => {
  let arrayTest = [];
  if (availableTimes)
    availableTimes.map((availableTime) => {
      let startTime = moment(availableTime.order_start_time, ["HH.mm"]).format(
        "hh:mm A"
      );
      let endTime = moment(availableTime.order_end_time, ["HH:mm"]).format(
        "hh:mm A"
      );
      arrayTest.push(`${startTime} - ${endTime}`);
    });
  return arrayTest;
};

export const timesAvailable = (
  availableTimes,
  minPrepTime,
  selectedDateTime
) => {
  let timeArray = [];
  const hours = availableTimesTransform(availableTimes);

  if (hours && selectedDateTime.dateTime.date) {
    hours.forEach((hour) => {
      const now = moment();
      const startTime = moment(
        moment(selectedDateTime.dateTime.date.value).isSame(now, "day") &&
          moment(hour.substr(0, 8), "h:mm A").isBefore(now, "minutes")
          ? moment()
              .minute(Math.ceil(moment().minute() / 15) * 15) // roundup to nearest 15mins
              .second(0)
              .format("h:mm A")
          : hour.substr(0, 8),
        "h:mm A"
      );

      moment(selectedDateTime.dateTime.date.value).isSame(now, "day") &&
        startTime.add(minPrepTime, "minutes");

      const endTime = moment(hour.substr(11), "h:mm A");
      while (startTime.isBefore(endTime)) {
        timeArray.push({
          name: startTime.format("h:mm A").toString(),
          value: startTime.format("h:mm A").toString(),
        });
        startTime.add(15, "minutes");
      }
    });
  } else if (hours) {
    hours.forEach((hour) => {
      const now = moment();
      const startTime = moment(
        moment(hour.substr(0, 8), "h:mm A").isBefore(now, "minutes")
          ? moment()
              .minute(Math.ceil(moment().minute() / 15) * 15) // roundup to nearest 15mins
              .second(0)
              .format("h:mm A")
          : hour.substr(0, 8),
        "h:mm A"
      );

      startTime.add(minPrepTime, "minutes");

      const endTime = moment(hour.substr(11), "h:mm A");
      while (startTime.isBefore(endTime)) {
        timeArray.push({
          name: startTime.format("h:mm A").toString(),
          value: startTime.format("h:mm A").toString(),
        });
        startTime.add(15, "minutes");
      }
    });
  }
  return timeArray;
};

//if max date is more than 30 days from now show only the first 30 days
export const maxOrderDate = (maxOrderDate) => {
  let final;
  const todayDate = moment();
  const maxDate = moment(maxOrderDate);

  if (maxDate.diff(todayDate, "days") > 30) {
    final = todayDate.add(30, "days").format("YYYY-MM-DD");
  } else final = maxOrderDate;

  return final;
};

export const renderTextToShow = (orderMethodType) => {
  // show text only if future order is enabled for pickup or delivery
  if (appConfig.isFutureOrderPickup && orderMethodType === PICKUP)
    return "Pick up time:";
  else if (appConfig.isFutureOrderDelivery && orderMethodType === DELIVERY)
    return "Delivery time:";
  else if (appConfig.isFutureOrderDropOff && orderMethodType === DROPOFF)
    return "Drop-Off time:";
  else return null;
};

const getNextAvailableOrderingDay = (orderingHours) => {
  let weekday = moment()
    .format("dddd")
    .toLowerCase();

  let numberOfDays = 1;

  while (
    orderingHours[weekday][0] === "Closed" ||
    orderingHours[weekday].length === 0
  ) {
    weekday = moment()
      .add(numberOfDays, "days")
      .format("dddd")
      .toLowerCase();
    numberOfDays += 1;
  }
  return weekday;
};

const verifySpecialDay = (date, specialHours, orderingHours) => {
  const nextWeekday = moment(today)
    .add(1, "days")
    .format("dddd")
    .toLowerCase();
  const nextDayStartOrderingTime = orderingHours
    ? orderingHours[nextWeekday][0].split(`-`)[0]
    : null;

  const specialDate = specialHours.filter(
    (special) =>
      moment(special.date).format("DD/MM/YYYY") ===
      moment(date).format("DD/MM/YYYY")
  );

  const nextSpecialDate = specialHours.filter(
    (special) =>
      moment(special.date).format("DD/MM/YYYY") ===
      moment(date)
        .add(1, "days")
        .format("DD/MM/YYYY")
  );

  if (specialDate.length > 0 && specialDate[0].order_start_time) {
    const startOrderingTime = moment(
      specialDate[0].order_start_time,
      "hh:mm a"
    ).format("hh:mm a");
    const nextDayOrderingTime =
      nextSpecialDate.length > 0 && nextSpecialDate[0].order_start_time
        ? moment(nextSpecialDate[0].order_start_time, "hh:mm a").format(
            "hh:mm a"
          )
        : moment(nextDayStartOrderingTime, "hh:mm a").format("hh:mm a");

    return moment(startOrderingTime, "hh:mm a").isAfter(today, "hour")
      ? `Ordering opens at ${startOrderingTime}`
      : !moment(startOrderingTime, "hh:mm a").isAfter(today, "hour")
      ? `Ordering opens Tomorrow at ${startOrderingTime}`
      : `Ordering opens Tomorrow at ${nextDayOrderingTime}`;
  }

  return null;
};

const getMessageToRender = (
  orderingHours,
  availableWeekday,
  interOrderType,
  specialHours
) => {
  const startOrderingTime = orderingHours
    ? orderingHours[availableWeekday][0].split(`-`)[0]
    : null;
  const formattedStartTime = orderingHours
    ? moment(startOrderingTime, "hh:mm a").format("hh:mm a")
    : null;
  const todayWeekday = moment(today)
    .format("dddd")
    .toLowerCase();
  const nextWeekday = moment(today)
    .add(1, "days")
    .format("dddd")
    .toLowerCase();
  const nextDayStartOrderingTime = orderingHours
    ? orderingHours[nextWeekday][0].split(`-`)[0]
    : null;
  const formattedNextDayStartOrderingTime = orderingHours
    ? moment(nextDayStartOrderingTime, "hh:mm a").format("hh:mm a")
    : null;

  if (
    startOrderingTime &&
    moment(startOrderingTime, "hh:mm a").isAfter(today, "hour") &&
    todayWeekday === availableWeekday
  ) {
    return `Ordering opens at ${formattedStartTime}`;
  } else if (
    startOrderingTime &&
    !moment(startOrderingTime, "hh:mm a").isAfter(today, "hour") &&
    todayWeekday === availableWeekday
  ) {
    const nextDayDate = moment(today).add(1, "days");
    const isSpecialDay = verifySpecialDay(
      nextDayDate,
      specialHours,
      orderingHours
    );

    return isSpecialDay
      ? isSpecialDay
      : `Ordering opens Tomorrow at ${formattedNextDayStartOrderingTime}`;
  } else if (
    startOrderingTime &&
    todayWeekday !== availableWeekday &&
    nextWeekday === availableWeekday
  ) {
    const nextDayDate = moment(today).add(1, "days");
    const isSpecialDay = verifySpecialDay(
      nextDayDate,
      specialHours,
      orderingHours
    );

    return isSpecialDay
      ? isSpecialDay
      : `Ordering opens Tomorrow at ${formattedNextDayStartOrderingTime}`;
  } else if (
    startOrderingTime &&
    todayWeekday !== availableWeekday &&
    nextWeekday !== availableWeekday
  ) {
    return `Ordering opens ${availableWeekday} at ${formattedStartTime}`;
  } else {
    return interOrderType === PICKUP
      ? "Selected location is unable to accept orders at the moment"
      : "We are unable to deliver to this address at this time";
  }
};

export const renderMessageText = (
  interOrderType,
  orderingHours,
  specialHours
) => {
  const today = moment().startOf("day");
  let modifiedOrderingHours = {};
  let modifiedSpecialHours = specialHours;

  let calculatedHours = [];

  if (orderingHours && Object.keys(orderingHours).length > 0) {
    Object.keys(orderingHours).forEach((weekday) => {
      if (
        !orderingHours[weekday].length ||
        orderingHours[weekday][0] === "Closed"
      ) {
        modifiedOrderingHours[weekday] = {
          order_start_time: null,
          order_end_time: null,
        };
      } else {
        let hours = orderingHours[weekday][0].split(" - ");
        modifiedOrderingHours[weekday] = {
          order_start_time: moment(hours[0], "hh:mm A").format("HH:mm:ss"),
          order_end_time: moment(hours[1], "hh:mm A").format("HH:mm:ss"),
        };
      }
    });

    modifiedSpecialHours = modifiedSpecialHours
      ? modifiedSpecialHours.map((specialHour) => {
          return {
            ...specialHour,
          };
        })
      : [];

    const lastSpecialHourDay = moment
      .max([
        ...modifiedSpecialHours.map((specialHour) =>
          moment(specialHour.date, "YYYY-MM-DD")
        ),
        moment().startOf("day"),
      ])
      .add(7, "days");

    let curDay = moment().startOf("day");

    while (curDay.isSameOrBefore(lastSpecialHourDay)) {
      let specialHourDay = modifiedSpecialHours.find((specialHour) =>
        moment(specialHour.date, "YYYY-MM-DD").isSame(curDay)
      );

      if (specialHourDay) {
        calculatedHours = [
          ...calculatedHours,
          {
            date: curDay.format("YYYY-MM-DD"),
            order_start_time: specialHourDay.order_start_time,
            order_end_time: specialHourDay.order_end_time,
          },
        ];
      } else {
        const weekday = curDay.format("dddd").toLowerCase();
        calculatedHours = [
          ...calculatedHours,
          {
            date: curDay.format("YYYY-MM-DD"),
            order_start_time: modifiedOrderingHours[weekday].order_start_time,
            order_end_time: modifiedOrderingHours[weekday].order_end_time,
          },
        ];
      }
      curDay = curDay.add(1, "days");
    }

    const now = moment();

    const todaysHours = calculatedHours[0];

    let firstAvailableDay =
      now.isAfter(moment(todaysHours.order_start_time, "HH:mm:ss")) &&
      now.isBefore(moment(todaysHours.order_end_time, "HH:mm:ss"))
        ? todaysHours
        : null;

    if (!firstAvailableDay) {
      firstAvailableDay = now.isBefore(
        moment(todaysHours.order_start_time, "HH:mm:ss")
      )
        ? todaysHours
        : null;
    }

    if (!firstAvailableDay) {
      firstAvailableDay = calculatedHours.find((dayHours, i) => {
        return dayHours.order_start_time && dayHours.order_end_time && i !== 0;
      });
    }

    const index = calculatedHours.findIndex((dayHours) => {
      return (
        dayHours &&
        firstAvailableDay &&
        dayHours.date === firstAvailableDay.date
      );
    });

    if (index === 0 || firstAvailableDay?.date === todaysHours.date) {
      return `Schedule an order for any time after ${moment(
        firstAvailableDay.order_start_time,
        "HH:mm:ss"
      ).format("hh:mm A")} today`;
    } else if (index === 1) {
      return `Ordering starts tomorrow at ${moment(
        firstAvailableDay.order_start_time,
        "HH:mm:ss"
      ).format("hh:mm a")}`;
    } else if (index > 1) {
      return `Ordering starts on ${moment(
        firstAvailableDay.date + " " + firstAvailableDay.order_start_time,
        "YYYY-MM-DD HH:mm:ss"
      ).format("dddd MMM Do hh:mm a")}`;
    } else {
      return `Store is closed`;
    }
  }
};

export const orderingIsAvailable = (
  marketplaceDeliveryLocation,
  orderingHours
) => {
  if (marketplaceDeliveryLocation && orderingHours) {
    let modifiedOrderingHours = {};
    let calculatedHours = [];

    Object.keys(orderingHours).forEach((weekday) => {
      if (
        !orderingHours[weekday].length ||
        orderingHours[weekday][0] === "Closed"
      ) {
        modifiedOrderingHours[weekday] = {
          order_start_time: null,
          order_end_time: null,
        };
      } else {
        let hours = orderingHours[weekday][0].split(" - ");
        modifiedOrderingHours[weekday] = {
          order_start_time: moment(hours[0], "hh:mm A").format("HH:mm:ss"),
          order_end_time: moment(hours[1], "hh:mm A").format("HH:mm:ss"),
        };
      }
    });

    const lastSpecialHourDay = moment
      .max([moment().startOf("day")])
      .add(7, "days");

    let curDay = moment().startOf("day");

    while (curDay.isSameOrBefore(lastSpecialHourDay)) {
      const weekday = curDay.format("dddd").toLowerCase();
      calculatedHours = [
        ...calculatedHours,
        {
          date: curDay.format("YYYY-MM-DD"),
          order_start_time: modifiedOrderingHours[weekday].order_start_time,
          order_end_time: modifiedOrderingHours[weekday].order_end_time,
        },
      ];
      curDay = curDay.add(1, "days");
    }

    const now = moment();
    const todaysHours = calculatedHours[0];

    let firstAvailableDay =
      now.isAfter(moment(todaysHours.order_start_time, "HH:mm:ss")) &&
      now.isBefore(moment(todaysHours.order_end_time, "HH:mm:ss"))
        ? todaysHours
        : null;

    if (!firstAvailableDay) {
      firstAvailableDay = now.isBefore(
        moment(todaysHours.order_start_time, "HH:mm:ss")
      )
        ? todaysHours
        : null;
    }

    if (!firstAvailableDay) {
      firstAvailableDay = calculatedHours.find((dayHours, i) => {
        return dayHours.order_start_time && dayHours.order_end_time && i !== 0;
      });
    }

    const firstAvailableDate =
      `${firstAvailableDay?.date}` +
      ` ` +
      `${firstAvailableDay?.order_start_time}`;

    const orderingAvailable = moment(firstAvailableDate).isSameOrBefore(
      marketplaceDeliveryLocation.max_future_order_date,
      "HH:mm:ss"
    );

    if (
      (marketplaceDeliveryLocation.is_asap_order_enabled ||
        marketplaceDeliveryLocation.is_future_order_enabled) &&
      orderingAvailable
    ) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

export const isScheduleRadioDisabledCalculated = (
  availableTimes,
  maxFutureOrderDate,
  orderEndTime,
  specialHours,
  online
) => {
  // disabled schedule radio button if there are no available times or the location has been
  // opened, but is closed in the current time
  return (
    (availableTimes &&
      !availableTimes.length &&
      moment(maxFutureOrderDate).isSame(today, "day")) ||
    (moment(maxFutureOrderDate).isSame(today, "day") &&
      orderEndTime &&
      moment(orderEndTime.substr(0, 5).substr(0, 8), "h:mm A").isBefore(
        moment(today, "minutes")
      )) ||
    !datesAvailable(maxFutureOrderDate, specialHours).length ||
    !online
  );
};

export const selectedLocationOrderingHours = (
  interOrderType,
  selectedLocation,
  deliveryBusiness
) => {
  let orderingHours;
  if (interOrderType === PICKUP) {
    orderingHours = Object.keys(selectedLocation.ordering_hours).reduce(
      (acc, key) => {
        acc.push(selectedLocation.ordering_hours[key]);
        return acc;
      },
      []
    );
  }
  if (interOrderType === DELIVERY) {
    orderingHours = Object.keys(deliveryBusiness.ordering_hours).reduce(
      (acc, key) => {
        acc.push(deliveryBusiness.ordering_hours[key]);
        return acc;
      },
      []
    );
  }
  if (interOrderType === DROPOFF) {
    orderingHours = Object.keys(selectedLocation.ordering_hours).reduce(
      (acc, key) => {
        acc.push(selectedLocation.ordering_hours[key]);
        return acc;
      },
      []
    );
  }
  return orderingHours;
};

export const renderConfirmButtonText = (details, futureOrderEnabled) => {
  // show See Menu text only if future order is disabled and the location does not accept order
  // ahead or is closed and for all other cases show Confirm
  if (
    (details && !details.is_order_ahead_enabled) ||
    (details && !futureOrderEnabled && !details.accepting_orders)
  ) {
    return "See Menu";
  } else return "Confirm";
};

export const isConfirmButtonDisabled = (
  interSelectedDateTime,
  futureOrderEnabled,
  details
) => {
  // disabled confirm button only if future order is enabled, is order ahead is true and there is no toggle or time selected
  let isButtonDisabled = false;
  if (details && details.is_order_ahead_enabled) {
    if (interSelectedDateTime.radioValue.value) {
      if (interSelectedDateTime.radioValue.value === "asap") {
        isButtonDisabled = false;
      } else {
        if (interSelectedDateTime.dateTime.time.name.length) {
          isButtonDisabled = false;
        } else {
          isButtonDisabled = true;
        }
      }
    } else {
      if (details?.is_asap_order_enabled) {
        if (details?.is_future_order_enabled) {
          isButtonDisabled = true;
        } else {
          isButtonDisabled = false;
        }
      }
    }
  }
  return isButtonDisabled;
};

export const formatLocationName = (location, coords, nearestLocation) => {
  let name = location.name;
  if (coords) {
    name =
      nearestLocation && location.id === nearestLocation.id
        ? location.name +
          " - " +
          location.distance +
          " - " +
          "(Nearest Location)"
        : location.name + " - " + location.distance;
  }

  if (!location.accepting_orders || !location.is_order_ahead_enabled) {
    name = "💤 Closed - " + name;
    location = { ...location, name: name };
  }
  location = { ...location, name: name };

  return location;
};

export const isLocationAcceptingOrders = (accepting_orders, specialHours) => {
  const specialHoursModified = specialHours?.filter(
    (day) => day.date === moment().format("YYYY-MM-DD")
  );

  if (
    specialHoursModified?.length === 1 &&
    !specialHoursModified[0].order_start_time
  ) {
    return false;
  } else return accepting_orders;
};
