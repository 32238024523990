import React from 'react';
import styled from 'styled-components';
import {Body, Title2} from '../../../../components/Typography';
import barCode from '../../../../assets/barCode.jpg';
import {PrimaryButton, FlatButton} from '../../../../components/Buttons';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 49px;
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);

  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const Description = styled(Body)`
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const LoyalMemberButton = styled(PrimaryButton)``;

const CreateCodeButton = styled(PrimaryButton)``;

const GivexLoyalty = ({
  setCustomerHasLoyalty,
  setCustomerWantsLoyalty,
  hideAuthenticationModal,
  setLoginToken,
  shouldDisplayTitle,
  isRequesting,
}) => {
  return (
    <Wrapper>
      {shouldDisplayTitle && (
        <TitleWrapper>
          <Title2 id="GiveLoyalty-Title">Set up Loyalty Card</Title2>
          <Cancel id="GiveLoyalty-CancelButton" onClick={hideAuthenticationModal}>
            <i className="fal fa-times" />
          </Cancel>
        </TitleWrapper>
      )}
      <Description>
        If you are already a member of our loyalty program all you have to do is
        input the number under the code so we can track your progress in the
        app. If you are not, then we will create a code for you so you can start
        earning rewards!
      </Description>
      <ImageWrapper>
        <img src={barCode} width="150" />
      </ImageWrapper>
      <ButtonWrapper>
        <LoyalMemberButton
          id="GiveLoyalty-LoyalMemberButton"
          onClick={() => setCustomerHasLoyalty(true)}
          disabled={isRequesting}
        >
          I'M A LOYALTY MEMBER
        </LoyalMemberButton>
        <CreateCodeButton
          id="GiveLoyalty-CreateCodeButton"
          onClick={() => setCustomerWantsLoyalty(true)}
          disabled={isRequesting}
        >
          CREATE A CODE FOR ME
        </CreateCodeButton>
        <FlatButton
          id="GiveLoyalty-FlatButton"
          onClick={() => {
            hideAuthenticationModal();
            setLoginToken();
          }}
        >
          Skip For Now
        </FlatButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default GivexLoyalty;
