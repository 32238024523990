import {
  SHOW_GIFT_CARD_MODAL,
  INITIATE_ADD_GIFT_CARD,
  RESET_GIFT_CARD_REDUCER,
  INITIATE_CONNECTING_EXISTING_GIFT_CARD,
  ADD_FUNDS_SUCCESS,
  CONNECT_EXISTING_GIFT_CARD_SUCCESS,
  RELOAD_GIFT_CARD,
  INITIATE_RELOAD_GIFT_CARD,
  RELOAD_FUNDS_SUCCESS,
  SHOW_CONFIRM_DELETE_MODAL_GIFT_CARD,
  HIDE_CONFIRM_DELETE_MODAL_GIFT_CARD,
} from './giftCardTypes';

export default function(
  state = {
    isGiftCardModalVisible: false,
    isAddingGiftCard: false,
    isConnectingExistingGiftCard: false,
    isReloadingGiftCard: false,
    giftCardDetails: {},
    confirmDeleteGiftCardIsVisible: false,
    giftCardErrors: {},
  },
  action
) {
  switch (action.type) {
    case 'UPDATE_GIFTCARD_ERROR_MESSAGE':
      return {
        ...state,
        giftCardErrors: action.errors,
      };
    case SHOW_GIFT_CARD_MODAL:
      return {
        ...state,
        isGiftCardModalVisible: action.boolean,
      };
    case INITIATE_ADD_GIFT_CARD:
      return {
        ...state,
        isAddingGiftCard: true,
      };
    case RESET_GIFT_CARD_REDUCER:
      return {
        ...state,
        isGiftCardModalVisible: false,
        isAddingGiftCard: false,
        isConnectingExistingGiftCard: false,
        isReloadingGiftCard: false,
        giftCardDetails: {},
      };
    case INITIATE_CONNECTING_EXISTING_GIFT_CARD:
      return {
        ...state,
        isConnectingExistingGiftCard: true,
      };
    case CONNECT_EXISTING_GIFT_CARD_SUCCESS:
      return {
        ...state,
        isConnectingExistingGiftCard: false,
      };
    case INITIATE_RELOAD_GIFT_CARD:
      return {
        ...state,
        isReloadingGiftCard: true,
      };
    case RELOAD_GIFT_CARD:
      return {
        ...state,
        giftCardDetails: action.giftCardDetails,
      };
    case SHOW_CONFIRM_DELETE_MODAL_GIFT_CARD:
      return {
        ...state,
        confirmDeleteGiftCardIsVisible: true,
      };
    case HIDE_CONFIRM_DELETE_MODAL_GIFT_CARD:
      return {
        ...state,
        confirmDeleteGiftCardIsVisible: false,
      };
    default:
      return state;
  }
}
