import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles, Slide} from '@material-ui/core';
import OrderingClosed from './OrderingClosed';
import {setOrderingClosedModalIsVisible} from '../orderSettingActions';
import {connect} from 'react-redux';

const Transition = props => <Slide direction="down" {...props} />;

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
});

const OrderingClosedContainer = ({
  classes,
  orderingClosedInAllLocations,
  orderingClosedModalIsVisible,
  setOrderingClosedModalIsVisible,
}) => {
  useEffect(() => {
    if (orderingClosedInAllLocations) {
      setOrderingClosedModalIsVisible(true);
    } else setOrderingClosedModalIsVisible(false);
  }, [orderingClosedInAllLocations]);

  const handleClose = () => {
    setOrderingClosedModalIsVisible(false);
  };

  return (
    <Dialog
      aria-labelledby="order-closed-modal"
      aria-describedby="Ordering is closed"
      fullWidth={true}
      open={orderingClosedModalIsVisible}
      onClose={null}
      className={classes.root}
      scroll="body"
      maxWidth={'sm'}
      TransitionComponent={Transition}
      PaperProps={{id: 'orderClosedDialog'}}
      disableBackdropClick={true}
    >
      <OrderingClosed handleClose={handleClose} />
    </Dialog>
  );
};

const mapStateToProps = state => ({
  orderingClosedModalIsVisible:
    state.orderSettingReducer.orderingClosedModalIsVisible,
  orderingClosedInAllLocations:
    state.locationsReducer.orderingClosedInAllLocations,
});

export default withStyles(styles)(
  connect(mapStateToProps, {setOrderingClosedModalIsVisible})(
    OrderingClosedContainer
  )
);
