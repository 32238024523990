import React, { useState } from 'react';
import { Formik } from 'formik';
import FormGrid from '../../components/Form/FormGrid';
import styled from 'styled-components';
import { PrimaryButton, ButtonRow } from '../../components/Buttons';
import { Title1 } from '../../components/Typography';
import PasswordField from '../../layout/components/PasswordField';

const FormLayout = styled(FormGrid)`
  grid-gap: 20px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const TitleWrapper = styled(Title1)`
  letter-spacing: 0.04px;
  font-weight: 600;
  width: fit-content;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
  }
`;

const CreateNewPassword = ({ classes, resetPassword, minPasswordLength }) => {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [minLengthCheck, setMinLengthCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [oneSymbolCheck, setOneSymbolCheck] = useState(false);
  const [oneUpperCheck, setOneUpperCheck] = useState(false);
  const [oneLowerCheck, setOneLowerCheck] = useState(false);
  return (
    <Wrapper>
      <TitleWrapper>Create New Password</TitleWrapper>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.password) {
            errors.password = 'Required';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
          }
          if (values.password.length < minPasswordLength) {
            errors.password = `Password must be a minimum of ${minPasswordLength} characters`;
            setMinLengthCheck(false);
          } else {
            setMinLengthCheck(true);
          }
          if (!new RegExp('[^A-Za-z0-9]').test(values.password)) {
            errors.password = 'Password must contain at least one symbol';
            setOneSymbolCheck(false);
          } else {
            setOneSymbolCheck(true);
          }
          if (!new RegExp('[0-9]').test(values.password)) {
            errors.password = 'Password must contain at least one digit';
            setOneNumberCheck(false);
          } else {
            setOneNumberCheck(true);
          }
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Password mismatch';
          }
          if (!new RegExp('(?=.*[a-z])').test(values.password)) {
            errors.password =
              'Password must contain at least one lowercase letter';
            setOneLowerCheck(false);
          } else {
            setOneLowerCheck(true);
          }
          if (!new RegExp('(?=.*[A-Z])').test(values.password)) {
            errors.password =
              'Password must contain at least one uppercase letter';
            setOneUpperCheck(false);
          } else {
            setOneUpperCheck(true);
          }
          return errors;
        }}
        onSubmit={(values) => {
          resetPassword(values.password);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              <PasswordField
                id="CreateNewPasswordForm-Field"
                label="Password"
                name="password"
                autoComplete="off"
                showPassword={showPassword}
                value={values.current_password}
                onChange={handleChange}
                onBlur={handleBlur}
                setShowPassword={setShowPassword}
                required
                classes={classes}
                helperText={touched.password && errors.password}
                error={touched.password && errors.password ? true : false}
                complexityProps={{
                  minLengthCheck,
                  oneNumberCheck,
                  oneSymbolCheck,
                  oneUpperCheck,
                  oneLowerCheck,
                  minPasswordLength,
                  marginTop: '-12px',
                }}
              />
              <PasswordField
                id="RegistrationForm-ConfirmPasswordField"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="off"
                showPassword={showConfirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                setShowPassword={setShowConfirmPassword}
                required
                classes={classes}
                helperText={touched.confirmPassword && errors.confirmPassword}
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? true
                    : false
                }
              />
              <ButtonRow style={{ marginTop: '26px' }} justifyContent="center">
                <PrimaryButton
                  buttonBorderRadius="10px"
                  style={{
                    minWidth: '240px',
                    minHeight: '56px',
                    fontWeight: '600',
                  }}
                  id="EmailForm-Submit"
                  type="submit"
                  disabled={!values.password || !values.confirmPassword}
                >
                  Save
                </PrimaryButton>
              </ButtonRow>
            </FormLayout>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default CreateNewPassword;
