import productForCartValidation from './productForCartValidation';
import comboForCartValidation from './comboForCartValidation';
import {DELIVERY} from '../../OrderSetting/orderSettingTypes';
import moment from 'moment';

export default (
  {products, combos},
  location,
  orderMethodType,
  orderSettingReducer,
  isAuthenticated
) => {
  let cart = {
    tip: 0,
    cart: [
      ...products.map(product => productForCartValidation(product.flatProduct)),
      ...comboForCartValidation(combos || []),
    ],
  };

  if (orderMethodType === DELIVERY) {
    cart = {
      ...cart,
      customer_address_id: location.id || location,
    };
    if (
      orderSettingReducer &&
      orderSettingReducer.deliveryDetails &&
      orderSettingReducer.deliveryDetails.deliveryBusiness
    ) {
      cart = {
        ...cart,
        business_id: orderSettingReducer.deliveryDetails.deliveryBusiness.id,
      };
    }
  } else {
    cart = {...cart, business_id: location.id};
  }
  // add order date to api body if its future order
  if (orderSettingReducer) {
    const {selectedDateTime} = orderSettingReducer;

    if (selectedDateTime.radioValue === 'schedule') {
      let order_date = moment(
        selectedDateTime.dateTime.date.name +
          ' ' +
          selectedDateTime.dateTime.time.name,
        'ddd MMM DD h:mm A'
      ).format('YYYY-MM-DD HH:mm:ss');

      cart = {...cart, order_date};
    }
  }

  return cart;
};
