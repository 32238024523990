import {
  SET_COUPON_MODAL_IS_VISIBLE,
  COUPON_POST_SUCCESS,
  SET_IS_REQUESTING,
} from './couponsTypes';
import api from '../../api';
import {createAlert} from '../../modules/Alert/alertActions';

export function setCouponsModalIsVisible(isCouponModalVisible) {
  return {
    type: SET_COUPON_MODAL_IS_VISIBLE,
    isCouponModalVisible: isCouponModalVisible,
  };
}

export function applyCouponCode(couponCode) {
  return function(dispatch) {
    dispatch({type: SET_IS_REQUESTING, boolean: true});
    api
      .post('/coupons/apply', {coupon_code: couponCode})
      .then(response => {
        dispatch({
          type: COUPON_POST_SUCCESS,
          couponType: response.data.data.type,
          couponValue: response.data.data.value,
        });
        dispatch(
          createAlert({
            type: 'sucess',
            message:
              response.data.data.type == 'money'
                ? `Successfully added $${response.data.data.value} to your account`
                : `Successfully added ${response.data.data.value} points to your account`,
          })
        );
      })
      .catch(error => {
        dispatch({type: SET_IS_REQUESTING, boolean: false});
        console.error(error);
      });
  };
}
