import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Body,
  Caption1,
  Title1,
  Callout,
} from "../../../../components/Typography";
import Select from "../../../../components/Select";
import LocationPickupCard from "./LocationPickupCard";
import LocationCustomFormatLabel from "../../OrderSettingModal/components/LocationCustomLabel";
import { PICKUP, DELIVERY, DROPOFF } from "../../orderSettingTypes";
import appConfigFile from "../../../../../app-config";
import { navigate } from "gatsby";

const MainDiv = styled.div`
  background: white;
  padding: 19px 36px 32px 36px;
  margin: 0;
  border-bottom: ${(props) => (props.hasStores ? "solid 3px #ebebeb" : "none")};
  margin-top: 5px;
  border-start-start-radius: 5px;
  width: 100%;
  @media (max-width: 786px) {
    padding: 16px;
    align-items: center;
    width: 100%;
  }
  @media (min-width: 1050px) {
    min-width: 960px;
  }
`;
const LocationsWrapper = styled.div`
  display: grid;
  overflow-y: auto;
  max-height: 450px;
  padding: 19px 36px 0 36px;
  margin-bottom: 5px;
  border-end-end-radius: 5px;
  @media (max-width: 786px) {
    padding: 16px 16px 32px 16px;
    align-items: center;
    width: 100%;
  }
`;
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  width: 100%;
  @media (max-width: 786px) {
    display: block;
  }
`;
const Title = styled(Title1)`
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 5px;
`;
const StoresWrapper = styled.div`
  display: grid;
  margin-top: 5px;
  margin-left: 15px;
`;

const AddNewButton = styled(Caption1)`
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const Stores = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 46px;
  padding: 0 5px;
  animation: fadeIn 0.5s ease-in-out;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const SelectSectionWrapper = styled.div`
  margin: 0 16px 0 0;
  @media (max-width: 786px) {
    display: block;
    margin: 9px 0;
  }
`;

const CalloutWrapper = styled(Callout)`
  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const SelectTableButton = styled(Caption1)`
  cursor: pointer;
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
  }
`;

const DropOffTitleWrapper = styled.div`
  align-items: top;
  align-content: center;
  height: 100%;

  @media (min-width: 786px) {
    padding-top: 10px;
  }
`;

const DropOffLocationWrapper = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  display: block;
  width: 100%;
`;
const MarketplaceDropOff = ({
  interDropOffDetails,
  updateInterOrderType,
  updateInterDropOffDetails,
  setDropOffLocationFormIsVisible,
  locations,
  interOrderType,
  confirmDropOffDetails,
  getFirstDropOffSelection,
  setIsEditDropOffLocation,
  selectedDropOffLocation,
  parentLocation,
  setParentLocation,
  getChildLocations,
  childLocations,
  isAuthenticated,
  profileSettingsReducer,
  setPickUpTimeFormIsVisible,
  updateOrderSettingModalIsVisible,
  resetInterSelectedDateTime,
  isDeepLink,
  isParentDeepLink,
}) => {
  const [filteredChildLocations, setFilteredChildLocations] = useState(false);

  const { appSource } = appConfigFile;
  const { email } = profileSettingsReducer;

  const onOrderTypeChange = (interOrderType) => {
    if (interOrderType === PICKUP) {
      updateInterOrderType(interOrderType);
    } else if (interOrderType === DELIVERY) {
      updateInterOrderType(interOrderType);
    } else if (interOrderType === DROPOFF) {
      updateInterOrderType(interOrderType);
    }
  };

  useEffect(() => {
    if (parentLocation) {
      getChildLocations(parentLocation.id);

      const strParentLocation = JSON.stringify(parentLocation);

      if (isAuthenticated) {
        localStorage.setItem(`${appSource}-${email}`, strParentLocation);
      } else {
        localStorage.setItem(`${appSource}-guest`, strParentLocation);
      }
    }
  }, [parentLocation]);

  useEffect(() => {
    const cachedParentLocation = isAuthenticated
      ? localStorage.getItem(`${appSource}-${email}`)
      : localStorage.getItem(`${appSource}-guest`);

    if (cachedParentLocation) {
      const parsedLocation = JSON.parse(cachedParentLocation);
      setParentLocation(parsedLocation);
    }
  }, []);

  useEffect(() => {
    if (childLocations.length > 0) {
      const locations = childLocations.filter((childLocation) =>
        childLocation.accepted_order_types.includes(DROPOFF)
      );

      if (locations.length > 0) {
        setFilteredChildLocations(locations);
      }
    }
  }, [childLocations]);

  const filteredLocations = locations.length
    ? locations.filter(
        (location) => location.accepted_order_types[0] !== "scan_and_go"
      )
    : [];

  const availableOrderMethods = parentLocation
    ? parentLocation.accepted_order_types
    : [];

  const dropOffLoc = selectedDropOffLocation.map((selection, i) => {
    if (selectedDropOffLocation.length === i + 1) return selection.value;
    return selection.value + ", ";
  });

  return (
    <>
      <MainDiv
        hasStores={
          childLocations &&
          childLocations.length !== 0 &&
          selectedDropOffLocation.length !== 0
        }
      >
        <Title>{"Order Method"}</Title>
        <SectionWrapper>
          <SelectSectionWrapper>
            <Select
              value={parentLocation}
              options={filteredLocations}
              getOptionLabel={(location) => location.name}
              getOptionValue={(location) => location}
              placeholder="Select a Location ..."
              maxMenuHeight={200}
              isLocation={true}
              onChange={(location) => setParentLocation(location)}
              isDisabled={isParentDeepLink}
              formatOptionLabel={(location) => (
                <LocationCustomFormatLabel
                  location={location}
                  interPickUpDetails={interDropOffDetails}
                />
              )}
            />
            <AddNewButton
              to="/locations"
              onClick={() => {
                navigate("/locations/");
                updateOrderSettingModalIsVisible(false);
              }}
            >
              <CalloutWrapper style={{ marginTop: "5px" }}>
                View Map
              </CalloutWrapper>
            </AddNewButton>
          </SelectSectionWrapper>
          <SelectSectionWrapper>
            <Select
              options={availableOrderMethods.map((value) => ({
                value: value,
                name: value.charAt(0).toUpperCase() + value.slice(1),
              }))}
              placeholder="Select Order Type"
              getOptionLabel={(option) => option.name.replaceAll("_", "-")}
              getOptionValue={(option) => option.value}
              onChange={(interOrderType) => onOrderTypeChange(interOrderType)}
              value={{
                value: interOrderType,
                name:
                  interOrderType.charAt(0).toUpperCase() +
                  interOrderType.slice(1),
              }}
            />
          </SelectSectionWrapper>
          <SelectSectionWrapper>
            {selectedDropOffLocation && selectedDropOffLocation.length !== 0 ? (
              <>
                <DropOffLocationWrapper>
                  <Select
                    value={dropOffLoc}
                    options={dropOffLoc}
                    getOptionLabel={(location) => location}
                    getOptionValue={(location) => location}
                    maxMenuHeight={200}
                    style={{ width: "200px" }}
                  />
                </DropOffLocationWrapper>
                <AddNewButton
                  to="/locations"
                  onClick={() => setDropOffLocationFormIsVisible(true)}
                >
                  <CalloutWrapper style={{ marginTop: "5px" }}>
                    + Add New address
                  </CalloutWrapper>
                </AddNewButton>
              </>
            ) : (
              <DropOffTitleWrapper>
                <div style={{ marginRight: "auto" }}>
                  <SelectTableButton
                    onClick={() => {
                      if (parentLocation) {
                        setDropOffLocationFormIsVisible(true);
                        getFirstDropOffSelection(parentLocation.id);
                      }
                    }}
                  >
                    + Add Drop-Off Location
                  </SelectTableButton>
                </div>
              </DropOffTitleWrapper>
            )}
          </SelectSectionWrapper>
        </SectionWrapper>
      </MainDiv>
      <LocationsWrapper>
        {interDropOffDetails && (
          <>
            <StoresWrapper>
              <Title1 style={{ fontWeight: "600", marginBottom: "24px" }}>
                {"Current Store"}
              </Title1>
              <Stores>
                <LocationPickupCard
                  isCurrentStore
                  location={interDropOffDetails}
                  updateInterPickUpDetails={updateInterDropOffDetails}
                  setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
                  confirmDropOffDetails={confirmDropOffDetails}
                  resetInterSelectedDateTime={resetInterSelectedDateTime}
                />
              </Stores>
            </StoresWrapper>
          </>
        )}
        {!childLocations && (
          <>
            <StoresWrapper>
              <Title1 style={{ fontWeight: "600", marginBottom: "24px" }}>
                {interDropOffDetails ? "Other Stores" : "Select Store"}
              </Title1>
              <Body style={{ marginBottom: "24px" }}>
                Select a location to browse stores{" "}
              </Body>
            </StoresWrapper>
          </>
        )}
        {childLocations &&
        childLocations.length !== 0 &&
        filteredChildLocations &&
        filteredChildLocations.length !== 0 &&
        selectedDropOffLocation.length !== 0 ? (
          <>
            <StoresWrapper>
              <Title1 style={{ fontWeight: "600", marginBottom: "24px" }}>
                {interDropOffDetails ? "Other Stores" : "Select Store"}
              </Title1>
              <Stores>
                {filteredChildLocations.map((location) => (
                  <>
                    {location && (
                      <LocationPickupCard
                        location={location}
                        updateInterPickUpDetails={updateInterDropOffDetails}
                        setPickUpTimeFormIsVisible={setPickUpTimeFormIsVisible}
                        confirmDropOffDetails={confirmDropOffDetails}
                        resetInterSelectedDateTime={resetInterSelectedDateTime}
                      />
                    )}
                  </>
                ))}
              </Stores>
            </StoresWrapper>
          </>
        ) : (
          <>
            <Title1 style={{ fontWeight: "600", marginBottom: "24px" }}>
              {"Select Store"}
            </Title1>
            <Body style={{ color: "#737a87", marginTop: "55px" }}>
              Couldn’t find what you’re looking for? Try PickUp instead.
            </Body>
          </>
        )}
      </LocationsWrapper>
    </>
  );
};

export default MarketplaceDropOff;
