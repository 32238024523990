import {
  CREATE_ANONYMOUS_SESSION_REQUEST,
  CREATE_ANONYMOUS_SESSION_SUCCESS,
  CREATE_ANONYMOUS_SESSION_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  RESET_CHECKED_EMAIL,
  SET_AUTHENTICATION_MODAL_IS_VISIBLE,
  PATCH_PHONE_NUMBER_REQUEST,
  PATCH_PHONE_NUMBER_SUCCESS,
  PATCH_PHONE_NUMBER_FAILURE,
  POST_FORGOT_EMAIL_SUCCESS,
  RESET_FORGOT_PASSWORD,
  EMAIL_IS_UNCONFIRMED,
  RESET_AUTH_REDUCER,
  GET_APP_CONFIG,
  UPDATE_LOGIN_OR_SIGNUP_SCREEN,
  UPDATE_IS_VERIFICATION_CODE_MATCHED,
  IS_PASSWORD_RESET_SUCCESSFULL,
  INITIATE_CHECKOUT_AS_GUEST_REQUEST,
  INITIATE_CHECKOUT_AS_GUEST_SUCCESS,
  INITIATE_CHECKOUT_AS_GUEST_FAILURE,
  RESET_GUEST_SESSION,
  SET_IS_GUEST_SESSION_INITIALIZED,
} from './authTypes';

export const initialState = {
  requestingCheckEmail: false,
  emailHasBeenChecked: false,
  requestingLogin: false,
  isAuthenticated: false,
  authenticationModalIsVisible: false,
  requestingPatchPhoneNumber: false,
  patchPhoneNumberError: null,
  forgotPasswordSuccess: false,
  emailIsUnconfirmed: false,
  appConfig: null,
  logInOrSingUpTab: 'login',
  isVerificationCodeMatched: false,
  isPasswordResetSuccessfull: false,
  token: '',
  loginFailureError: null,
  isGuestSession: false,
  isGuestSessionInitiated: false,
  authErrors: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_AUTH_ERROR_MESSAGE':
      return {
        ...state,
        authErrors: action.errors,
      };
    case CREATE_ANONYMOUS_SESSION_REQUEST:
      return {
        ...state,
        requestingCreateAnonymousSession: true,
      };
    case CREATE_ANONYMOUS_SESSION_SUCCESS:
      return {
        ...state,
        requestingCreateAnonymousSession: false,
        session_id: action.response.data.session_id,
        isGuestSession: false,
      };
    case CREATE_ANONYMOUS_SESSION_FAILURE:
      return {
        ...state,
        requestingCreateAnonymousSession: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        requestingLogin: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        session_id: action.token,
        isAuthenticated: true,
        requestingLogin: false,
        isGuestSession: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginFailureError: action.error,
        requestingLogin: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        requestingLogout: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        requestingLogout: false,
        isAuthenticated: false,
        session_id: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        requestingLogout: false,
      };
    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        requestingCheckEmail: true,
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        requestingCheckEmail: false,
        emailIsAvailable: action.emailIsAvailable,
        emailHasBeenChecked: true,
        checkedEmail: action.checkedEmail,
      };
    case CHECK_EMAIL_FAILURE:
      return {
        ...state,
        requestingCheckEmail: false,
      };
    case RESET_CHECKED_EMAIL:
      return {
        ...state,
        emailHasBeenChecked: false,
      };
    case RESET_GUEST_SESSION:
      return {
        ...state,
        session_id: null,
        isGuestSession: false,
        isGuestSessionInitiated: false,
      };
    case SET_AUTHENTICATION_MODAL_IS_VISIBLE:
      return {
        ...state,
        authenticationModalIsVisible: action.bool,
      };
    case PATCH_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        requestingPatchPhoneNumber: true,
        patchPhoneNumberError: null,
      };
    case PATCH_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        requestingPatchPhoneNumber: false,
      };
    case PATCH_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        requestingPatchPhoneNumber: false,
        patchPhoneNumberError: action.error,
      };
    case RESET_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordSuccess: false,
      };
    case POST_FORGOT_EMAIL_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
      };
    case EMAIL_IS_UNCONFIRMED:
      return {
        ...state,
        emailIsUnconfirmed: true,
        // session_id: action.token,
      };
    case RESET_AUTH_REDUCER:
      return {
        ...state,
        emailIsUnconfirmed: initialState.emailHasBeenChecked,
        requestingCheckEmail: initialState.requestingCheckEmail,
        emailHasBeenChecked: initialState.emailHasBeenChecked,
        requestingLogin: initialState.requestingLogin,
        isPasswordResetSuccessfull: initialState.isPasswordResetSuccessfull,
        forgotPasswordSuccess: initialState.forgotPasswordSuccess,
        isVerificationCodeMatched: initialState.isVerificationCodeMatched,
        loginFailureError: null,
      };

    case GET_APP_CONFIG:
      return {
        ...state,
        appConfig: action.appConfig,
      };
    case UPDATE_LOGIN_OR_SIGNUP_SCREEN:
      return {
        ...state,
        logInOrSingUpTab: action.screen,
      };
    case UPDATE_IS_VERIFICATION_CODE_MATCHED:
      return {
        ...state,
        isVerificationCodeMatched: action.isVerified,
        token: action.token,
      };
    case IS_PASSWORD_RESET_SUCCESSFULL:
      return {
        ...state,
        isPasswordResetSuccessfull: action.payload,
      };
    case INITIATE_CHECKOUT_AS_GUEST_REQUEST:
      return {
        ...state,
        requestingGuestSession: true,
      };
    case INITIATE_CHECKOUT_AS_GUEST_SUCCESS:
      return {
        ...state,
        session_id: action.token,
        requestingGuestSession: false,
        isGuestSession: true,
        isGuestSessionInitiated: true,
      };
    case INITIATE_CHECKOUT_AS_GUEST_FAILURE:
      return {
        ...state,
        requestingGuestSession: false,
      };
    case SET_IS_GUEST_SESSION_INITIALIZED:
      return {
        ...state,
        isGuestSessionInitiated: action.isGuestCheckoutInitialized,
      };
    default:
      return state;
  }
}
