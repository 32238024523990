import moment from 'moment';

const dummydata = [
  {
    start_time: '11:00:00',
    end_time: '11:14:59',
  },
  {
    start_time: '12:15:00',
    end_time: '12:29:59',
  },
  {
    start_time: '14:15:00',
    end_time: '15:29:59',
  },
];

const formatTimesOptions = (timesOptions, futureUnavailableSlots) => {
  let formattedTimesOptions = timesOptions.map(option => {
    let isTimeSlotFull = futureUnavailableSlots.some(unavailableTimeSlot => {
      const orderTime = moment(option.value, 'h:mm A');
      const startTime = moment(unavailableTimeSlot.start_time, 'HH:mm:ss');
      const endTime = moment(unavailableTimeSlot.end_time, 'HH:mm:ss');
      return (
        orderTime.isSameOrAfter(startTime) && orderTime.isSameOrBefore(endTime)
      );
    });
    if (isTimeSlotFull) {
      return {
        ...option,
        name: option.name + ' - Time slot full',
        isDisabled: true,
      };
    } else {
      return option;
    }
  });
  return formattedTimesOptions;
};

export default formatTimesOptions;
