import {
  DROPOFF_LOCATION_FORM_IS_VISIBLE,
  GET_FIRST_DROPOFF_SELECTION_SUCCESS,
  GET_FIRST_DROPOFF_SELECTION_REQUEST,
  SAVE_DROPOFF_SELECTION_SUCCESS,
  SAVE_DROPOFF_LOCATION,
  DELETE_DROPOFF_LOCATION,
  SET_IS_EDIT_DROPOFF_LOCATION,
  RESTORE_PICKED_LOCATION,
  RESTORE_SELECTED_LOCATION,
} from './dropOffLocationTypes';

const initialState = {
  dropOffLocations: [],
  savedDropOffLocations: [],
  pickedDropOffLocation: [],
  selectedDropOffLocation: [],
  dropOffLocationFormIsVisible: false,
  getDropOffLocationsIsRequesting: false,
  saveDropOffLocationButtonIsEnabled: false,
  isEditDropOffLocation: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DROPOFF_LOCATION_FORM_IS_VISIBLE:
      return {
        ...state,
        dropOffLocationFormIsVisible: action.isVisible,
      };
    case GET_FIRST_DROPOFF_SELECTION_SUCCESS:
      return {
        ...state,
        getDropOffLocationsIsRequesting: false,
        dropOffLocations: action.dropOffLocations,
        saveDropOffLocationButtonIsEnabled: false,
      };
    case GET_FIRST_DROPOFF_SELECTION_REQUEST:
      return {
        ...state,
        getDropOffLocationsIsRequesting: true,
      };
    case SAVE_DROPOFF_SELECTION_SUCCESS:
      return {
        ...state,
        pickedDropOffLocation: action.pickedDropOffLocation,
        dropOffLocations: action.dropOffLocations,
        saveDropOffLocationButtonIsEnabled:
          action.saveDropOffLocationButtonIsEnabled,
      };
    case DELETE_DROPOFF_LOCATION:
      return {
        ...state,
        pickedDropOffLocation: action.pickedDropOffLocation,
        selectedDropOffLocation: [],
      };
    case SET_IS_EDIT_DROPOFF_LOCATION:
      return {
        ...state,
        isEditDropOffLocation: action.isEdit,
        saveDropOffLocationButtonIsEnabled: false,
      };
    case SAVE_DROPOFF_LOCATION:
      return {
        ...state,
        selectedDropOffLocation: action.selectedDropOffLocation,
        savedDropOffLocations: state.dropOffLocations,
      };
    case RESTORE_PICKED_LOCATION:
      return {
        ...state,
        pickedDropOffLocation: [...state.selectedDropOffLocation],
        dropOffLocations: state.savedDropOffLocations.length
          ? state.savedDropOffLocations
          : state.dropOffLocations,
      };
    case RESTORE_SELECTED_LOCATION:
      return {
        ...state,
        selectedDropOffLocation: action.finalDropOffLocation,
        pickedDropOffLocation: action.finalDropOffLocation,
      };
    default:
      return state;
  }
}
