import {
  GET_COMBO_LIST_REQUEST,
  GET_COMBO_LIST_SUCCESS,
  GET_COMBO_SUCCESS,
  CLOSE_COMBO_POPUP,
  SET_COMBO_GROUP,
  SET_COMBO_ITEM,
  FILL_COMBO_GROUP,
  EMPTY_COMBO_GROUP,
  GET_COMBO_LIST_FAILURE,
  UPDATE_COMBO_PRICE,
  EDIT_COMBO_CONFIG,
  ADD_ORDER_INSTRUCTION,
} from './comboTypes';

export default (
  state = {
    isRequestingGetComboList: false,
    combo: null,
    selectedComboGroup: null,
    comboCustomizerIsVisible: false,
    combos: [],
    comboItemToCustomize: null,
    isEditing: false,
  },
  action
) => {
  switch (action.type) {
    case GET_COMBO_LIST_REQUEST:
      return {
        ...state,
        isRequestingGetComboList: true,
      };
    case GET_COMBO_LIST_SUCCESS:
      return {
        ...state,
        isRequestingGetComboList: false,
        combos: action.combos,
      };
    case GET_COMBO_LIST_FAILURE:
      return {
        ...state,
        isRequestingGetComboList: false,
      };
    case GET_COMBO_SUCCESS:
      return {
        ...state,
        combo: action.combo,
        comboCustomizerIsVisible: true,
        isEditing: false,
      };
    case CLOSE_COMBO_POPUP:
      return {
        ...state,
        conbo: null,
        selectedComboGroup: null,
        comboCustomizerIsVisible: false,
        comboItemToCustomize: null,
      };
    case SET_COMBO_GROUP:
      return {
        ...state,
        selectedComboGroup: action.selectedComboGroup,
      };
    case SET_COMBO_ITEM:
      return {
        ...state,
        comboItemToCustomize: action.comboItemToCustomize,
      };
    case FILL_COMBO_GROUP:
      let comboGroups = [...state.combo.combo_item_groups];
      comboGroups[state.selectedComboGroup].productCustomizer = {
        ...action.productCustomizer,
      };
      return {
        ...state,
        combo: {
          ...state.combo,
          combo_item_groups: comboGroups,
        },
      };
    case EMPTY_COMBO_GROUP:
      let currentComboGroups = [...state.combo.combo_item_groups];
      delete currentComboGroups[action.comboGroupIndex].productCustomizer;
      return {
        ...state,
        combo: {
          ...state.combo,
          combo_item_groups: currentComboGroups,
        },
      };
    case ADD_ORDER_INSTRUCTION:
      return {
        ...state,
        combo: {
          ...state.combo,
          instruction: action.instruction,
        },
      };
    case UPDATE_COMBO_PRICE:
      return {
        ...state,
        combo: {
          ...state.combo,
          totalPrice: action.totalPrice,
        },
      };
    case EDIT_COMBO_CONFIG:
      return {
        ...state,
        isEditing: true,
        combo: action.combo,
        comboIndexInCart: action.comboIndexInCart,
        comboCustomizerIsVisible: true,
      };
    default:
      return state;
  }
};
