import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import spinner from '../../assets/spinner.svg';
import ComboDetailsContainer from './ComboCustomizer/ComboDetails/ComboDetailsContainer';
import ComboGroupContainer from './ComboCustomizer/ComboGroup/ComboGroupContainer';
import ComboProductCustomizerContainer from './ComboProductCustomizerContainer';
import {SCREEN_PRODUCT, SCREEN_COMBO_GROUP} from './comboTypes';

const ComboModal = ({
  combo,
  closeComboPopup,
  classes,
  comboCustomizerIsVisible,
  page,
  changePage,
  goBack,
  onModalClose,
  productCustomizerReducer,
  setscrollToDiv,
}) => {
  const {flatProduct} = productCustomizerReducer;

  const loading = !combo || (!flatProduct && SCREEN_PRODUCT === page);

  return (
    <Dialog
      aria-labelledby="customize-combo-modal"
      aria-describedby="Customize Combo"
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      fullWidth={true}
      open={comboCustomizerIsVisible}
      onClose={() => onModalClose()}
      className={classes.root}
      scroll="body"
      maxWidth={'md'}
      PaperProps={{
        classes: {root: page == SCREEN_PRODUCT ? classes.paper : null},
        id: 'comboDialog',
      }}
      // PaperProps={{ id: "comboDialog" }}
    >
      {loading ? (
        <div style={{textAlign: 'center', margin: '160px'}}>
          <img src={spinner} style={{width: '100px'}} alt="loading" />
        </div>
      ) : SCREEN_PRODUCT === page ? (
        <ComboProductCustomizerContainer
          productCustomizerReducer={productCustomizerReducer}
          closeComboPopup={closeComboPopup}
          changePage={changePage}
          goBack={goBack}
          hidePrice={!combo.is_dollar_discount}
        />
      ) : SCREEN_COMBO_GROUP === page ? (
        <ComboGroupContainer
          combo={combo}
          changePage={changePage}
          goBack={goBack}
          closeComboPopup={closeComboPopup}
        />
      ) : (
        <ComboDetailsContainer
          combo={combo}
          changePage={changePage}
          closeComboPopup={closeComboPopup}
          setscrollToDiv={setscrollToDiv}
        />
      )}
    </Dialog>
  );
};

export default ComboModal;
