const updateProductErrors = flatProduct => {
  const {
    options,
    optionsById,
    subOptionsById,
    itemsById,
    subItemsById,
  } = flatProduct;

  let updatedProduct = {...flatProduct, hasErrors: false};

  options &&
    options.forEach(optionId => {
      let option = optionsById[optionId];
      let selectedItemQuantity = 0;

      const iterateItems = items => {
        items.forEach(itemId => {
          const item = itemsById[itemId];
          if (item.sub_items && item.sub_items.length) {
            item.sub_items.forEach(subItemId => {
              const subItem = subItemsById[subItemId];
              selectedItemQuantity += subItem.quantity;
            });
          } else {
            selectedItemQuantity += item.quantity;
          }
        });
      };

      if (option.sub_options && option.sub_options.length) {
        option.sub_options.forEach(subOptionId => {
          const subOption = subOptionsById[subOptionId];
          iterateItems(subOption.items);
        });
      } else {
        iterateItems(option.items);
      }

      const error = selectedItemQuantity < option.minimum_pick;

      updatedProduct = {
        ...updatedProduct,
        hasErrors: error ? error : updatedProduct.hasErrors,
        optionsById: {
          ...updatedProduct.optionsById,
          [optionId]: {
            ...option,
            error,
          },
        },
      };
    });

  return updatedProduct;
};

export default updateProductErrors;
