import styled from 'styled-components';
import appConfig from '../../../app-config';

const Title1 = styled.h1`
  font-size: ${props => props.theme.title1DefaultFontSize};
  color: ${props => props.theme.title1DefaultFontColor};
  font-weight: ${props => props.theme.title1DefaultFontWeight};
  text-transform: ${props => appConfig.appSource === "raahi" ? 'uppercase' : props.textTransform || 'unset'};
  text-align: ${props => props.textAlign || 'inherit'};
  margin: 0;
  font-family: ${props => props.theme.primaryFontFontFamily};
`;

export default Title1;
