import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { resetRegistrationStates, setLoginToken } from './registrationActions';
import Registration from './components/Registration';
import appConfig from '../../../app-config';

const RegistrationContainer = ({
  createAccountIsSuccessful,
  confirmSMSIsSuccessful,
  hideAuthenticationModal,
  resetCheckedEmail,
  updatePhoneNumber,
  step,
  resetRegistrationStates,
  setLoginToken,
  setDeliveryAddressesFormIsVisible,
  setActiveStep,
  activeStep,
}) => {
  useEffect(() => {
    return () => resetRegistrationStates();
  }, []);

  // useEffect(() => {
  //   if (hideAuthenticationModal) {
  //     resetRegistrationStates();
  //   }
  // }, [hideAuthenticationModal]);

  useEffect(() => {
    if (createAccountIsSuccessful) {
      setActiveStep(2);
    }
  }, [createAccountIsSuccessful]);

  useEffect(() => {
    if (confirmSMSIsSuccessful && appConfig.loyaltyType === 'givex') {
      setActiveStep(3);
    } else if (confirmSMSIsSuccessful) {
      hideAuthenticationModal();
      setLoginToken();
    }
  }, [confirmSMSIsSuccessful]);

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  const handleBackButtonClick = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <Registration
        updatePhoneNumber={updatePhoneNumber}
        activeStep={activeStep}
        handleBackButtonClick={handleBackButtonClick}
        resetCheckedEmail={resetCheckedEmail}
        hideAuthenticationModal={hideAuthenticationModal}
        setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  createAccountIsSuccessful:
    state.registrationReducer.createAccountIsSuccessful,
  confirmSMSIsSuccessful: state.registrationReducer.confirmSMSIsSuccessful,
  step: state.registrationReducer.step,
});

export default connect(mapStateToProps, {
  resetRegistrationStates,
  setLoginToken,
})(RegistrationContainer);
