export const GET_COMBO_LIST_REQUEST = 'GET_COMBO_LIST_REQUEST';
export const GET_COMBO_LIST_SUCCESS = 'GET_COMBO_LIST_SUCCESS';
export const GET_COMBO_SUCCESS = 'GET_COMBO_SUCCESS';
export const CLOSE_COMBO_POPUP = 'CLOSE_COMBO_POPUP';
export const SET_COMBO_GROUP = 'SET_COMBO_GROUP';
export const SET_COMBO_ITEM = 'SET_COMBO_ITEM';
export const FILL_COMBO_GROUP = 'FILL_COMBO_GROUP';
export const EMPTY_COMBO_GROUP = 'EMPTY_COMBO_GROUP';
export const GET_COMBO_LIST_FAILURE = 'GET_COMBO_LIST_FAILURE';
export const UPDATE_COMBO_PRICE = 'UPDATE_COMBO_PRICE';
export const EDIT_COMBO_CONFIG = 'EDIT_COMBO_CONFIG';
export const ADD_ORDER_INSTRUCTION = 'ADD_ORDER_INSTRUCTION';

export const SCREEN_COMBO = 'combo_customizer_screen_combo';
export const SCREEN_COMBO_GROUP = 'combo_customizer_screen_combo_group';
export const SCREEN_PRODUCT = 'combo_customizer_screen_product';
