import React from 'react';
import styled from 'styled-components';
import {Caption1, Title1} from '../../../components/Typography';
import {withStyles} from '@material-ui/core/styles';
import email from '../../../assets/email.png';
import sms from '../../../assets/sms.png';
import EmailOrPhoneForm from './EmailOrPhoneForm';

const styles = theme => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const BackButton = styled(Caption1)`
  color: ${props => props.theme.clickableTextColor};
  cursor: pointer;
  font-size: 16px;
  margin-right: auto;
  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

const EmailWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 56px;
  margin: 21px 0 14px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  border: solid 2px #d8d8d8;
`;
const SMSWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 56px;
  margin: 14px 0 0;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: solid 2px #d8d8d8;
`;
const ViaText = styled.div`
  width: 72px;
  height: 19px;

  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: #0d0e12;
`;

const Icon = styled.i`
  font-size: 30px;
  color: ${props => props.theme.primaryColor};
`;
const TitleWrapper = styled(Title1)`
  letter-spacing: 0.04px;
  font-weight: 600;
  width: fit-content;
  margin: 9px 0 24px 0;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
  }
`;
const BackIcon = styled.i`
  margin-right: 8px;
`;

const DescriptionWrapper = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
`;

const ForgotPassword = ({
  handleChangeScreen,
  postForgotPassword,
  emailOrPhoneField,
  setEmailOrPhoneField,
  setEmailOrPhoneObject,
}) => {
  return (
    <div>
      <BackButton
        id="Back1111-Button"
        onClick={() => {
          if (emailOrPhoneField === '') handleChangeScreen(1);
          else {
            setEmailOrPhoneField('');
          }
        }}
      >
        <BackIcon className="fas fa-chevron-left" />
        Back
      </BackButton>
      <TitleWrapper>Forgot Password</TitleWrapper>
      {emailOrPhoneField === '' ? (
        <div>
          <DescriptionWrapper>
            We will send you a verification code to reset your password. How do
            you want it sent?
          </DescriptionWrapper>
          <EmailWrapper onClick={() => setEmailOrPhoneField('email')}>
            <ViaText>Via Email</ViaText>
            <Icon className="far fa-envelope" />
          </EmailWrapper>
          <SMSWrapper onClick={() => setEmailOrPhoneField('phone')}>
            <ViaText>Via SMS</ViaText>
            <Icon className="far fa-comment-alt-lines" />
          </SMSWrapper>
        </div>
      ) : (
        <EmailOrPhoneForm
          setEmailOrPhoneObject={setEmailOrPhoneObject}
          postForgotPassword={postForgotPassword}
          emailOrPhoneField={emailOrPhoneField}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ForgotPassword);
