import React, {useState} from 'react';
import styled from 'styled-components';
import FlatButton from './FlatButton';

// const StyledFlatButton = styled(FlatButton)`
//   border-radius: ${props => props.theme.addFavOrderButtonBorderRadius};
//   height: 32px;
//   width: 72px;
//   border: solid 2px
//     ${props =>
//       props.keepHoverStyle
//         ? props.theme.addButtonHoverColor
//         : props.theme.addFavOrderButtonBorderColor};
//   color: ${props =>
//     props.keepHoverStyle
//       ? props.theme.white
//       : props.theme.addFavOrderButtonTextColor};
//   background: ${props =>
//     props.keepHoverStyle ? props.theme.addButtonHoverColor : null};
//   font-family: ${props => props.theme.primaryFontFontFamily};

//   &:hover {
//     background: ${props => props.theme.addButtonHoverColor};
//     color: ${props => props.theme.white};
//     border: solid 2px ${props => props.theme.addButtonHoverColor};
//   }
// `;

const StyledFlatButton = styled(FlatButton)`
  border-radius: ${props => props.theme.addFavOrderButtonBorderRadius};
  height: 32px;
  width: 72px;
  border: solid 2px ${props => props.theme.addFavOrderButtonBorderColor};
  color: ${props => props.theme.addFavOrderButtonTextColor};
  background: ${props => props.theme.addFavOrderButtonBackgroundColor};
  font-family: ${props => props.theme.primaryFontFontFamily};

  &:hover {
    background: ${props => props.theme.addButtonHoverColor};
    color: ${props => props.theme.addFavOrderButtonHoverTextColor};
    border: solid 2px ${props => props.theme.addFavOrderButtonBorderColorHover};
    transition: all 0.25s ease;
  }
`;

const ADD = 'ADD';

const AddButton = props => {
  const [text, setText] = useState(ADD);
  return (
    <StyledFlatButton
      {...props}
      onClick={e => {
        if (props.onClick) props.onClick(e);
        setText(<i className="fas fa-check" />);
        setTimeout(() => setText(ADD), 3000);
      }}
      keepHoverStyle={text !== ADD}
    >
      {text}
    </StyledFlatButton>
  );
};

export default AddButton;
