import styled from 'styled-components';
import appConfig from '../../../app-config';

const Headline = styled.div`
  font-size: 18px;
  font-weight: ${(props) => props.fontWeight || 600};
  text-align: ${(props) => props.textAlign || 'inherit'};
  color: ${(props) => props.color || props.theme.bodyColor};
  text-transform: ${(props) =>
    appConfig.appSource === 'raahi'
      ? 'uppercase'
      : props.textTransform || 'unset'};
  margin: 0;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
`;

export default Headline;
