export default function(error) {
  let details = [];
  if (
    error?.response?.data?.errors?.details &&
    Object.keys(error?.response?.data?.errors?.details).length > 0
  ) {
    for (let value of Object.values(error?.response?.data?.errors?.details)) {
      details.push(value);
    }
  }
  const errors = error.response.data.errors.details
    ? {
        message: error?.response?.data?.errors?.message,
        details: {
          cart_changes: details,
        },
      }
    : error.response.data.errors;

  return errors;
}
