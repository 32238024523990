// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-addresses-js": () => import("./../src/pages/addresses.js" /* webpackChunkName: "component---src-pages-addresses-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-how-it-works-js": () => import("./../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-menu-index-js": () => import("./../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-my-transactions-js": () => import("./../src/pages/my-transactions.js" /* webpackChunkName: "component---src-pages-my-transactions-js" */),
  "component---src-pages-offers-js": () => import("./../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-payment-methods-js": () => import("./../src/pages/payment-methods.js" /* webpackChunkName: "component---src-pages-payment-methods-js" */),
  "component---src-pages-profile-settings-js": () => import("./../src/pages/profile-settings.js" /* webpackChunkName: "component---src-pages-profile-settings-js" */),
  "component---src-pages-refer-friend-js": () => import("./../src/pages/refer-friend.js" /* webpackChunkName: "component---src-pages-refer-friend-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

