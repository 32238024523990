import {
  CREATE_ANONYMOUS_SESSION_REQUEST,
  CREATE_ANONYMOUS_SESSION_SUCCESS,
  CREATE_ANONYMOUS_SESSION_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  RESET_CHECKED_EMAIL,
  SET_AUTHENTICATION_MODAL_IS_VISIBLE,
  PATCH_PHONE_NUMBER_REQUEST,
  PATCH_PHONE_NUMBER_SUCCESS,
  PATCH_PHONE_NUMBER_FAILURE,
  RESET_FORGOT_PASSWORD,
  POST_FORGOT_EMAIL_SUCCESS,
  EMAIL_IS_UNCONFIRMED,
  RESET_AUTH_REDUCER,
  GET_APP_CONFIG,
  UPDATE_LOGIN_OR_SIGNUP_SCREEN,
  UPDATE_IS_VERIFICATION_CODE_MATCHED,
  IS_PASSWORD_RESET_SUCCESSFULL,
  INITIATE_CHECKOUT_AS_GUEST_REQUEST,
  INITIATE_CHECKOUT_AS_GUEST_SUCCESS,
  INITIATE_CHECKOUT_AS_GUEST_FAILURE,
  RESET_GUEST_SESSION,
  SET_IS_GUEST_SESSION_INITIALIZED,
} from './authTypes';
import { DROPOFF_LOCATION_FORM_IS_VISIBLE } from '../DropOff/dropOffLocationTypes';
import api from '../../api';
import { navigate } from 'gatsby';
import appConfig from '../../../app-config';
import { createAlert } from '../Alert/alertActions';
import {
  hideToolTip,
  updateOrderSettingModalIsVisible,
  setInterDeliveryDetailsId,
} from '../OrderSetting/orderSettingActions';
import { updateCartScreen, validateGlobalCart } from '../Cart/cartActions';
import app from 'firebase/app';
import formatedBEErrorMessage from '../../utils/formatedBEErrorMessage';
import { initialDynamicOrderMethodType } from '../OrderSetting/utils/helper';
import { UPDATE_ALL_ORDER_TYPE } from '../OrderSetting/orderSettingTypes';
import {
  setSelectedDeliveryAddress,
  getDeliveryAddresses,
} from '../DeliveryAddresses/deliveryAddressesActions';
import {
  updateInterOrderType,
  updateOrderType,
} from '../OrderSetting/orderSettingActions';
const source = appConfig.appSource;
const UNCONFIRMED = 'UNCONFIRMED';

export function clearAuthErrorMessage() {
  return function(dispatch) {
    dispatch({
      type: 'UPDATE_AUTH_ERROR_MESSAGE',
      errors: {},
    });
  };
}

export function createAnonymousSession() {
  return function(dispatch) {
    dispatch({ type: CREATE_ANONYMOUS_SESSION_REQUEST });
    return api.post(`/sessions`, { source }).then(
      (response) => {
        localStorage.setItem(
          `${appConfig.appSource}_token`,
          response.data.data.session_id
        );
        dispatch({
          type: CREATE_ANONYMOUS_SESSION_SUCCESS,
          response: response.data,
        });
      },
      (error) => {
        dispatch({ type: CREATE_ANONYMOUS_SESSION_FAILURE, error });
      }
    );
  };
}

export function login({ password, email }) {
  return function(dispatch, getState) {
    dispatch({ type: LOGIN_REQUEST });
    let body = {
      email,
      password,
      source,
    };

    const coords = getState().locationsReducer.currentLocation;
    const isMenuDisabled = getState().orderSettingReducer.isMenuDisabled;

    if (coords) {
      body = {
        ...body,
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    } else {
      body = {
        ...body,
        latitude: 0,
        longitude: 0,
      };
    }
    return api
      .post(`/login`, body, { ignore_interception: true })
      .then((response) => {
        dispatch({
          type: 'UPDATE_AUTH_ERROR_MESSAGE',
          errors: {},
        });
        const { status } = response.data.data;
        if (status === UNCONFIRMED) {
          dispatch({ type: 'TO_CONFIRM_SMS' });
          dispatch({
            type: EMAIL_IS_UNCONFIRMED,
            token: response.data.data.token,
          });
          localStorage.setItem(
            `${appConfig.appSource}_token`,
            response.data.data.token
          );
        } else {
          // analytics.initialize(response.data.data.token);

          dispatch({
            type: LOGIN_SUCCESS,
            token: response.data.data.token,
          });

          localStorage.setItem(
            `${appConfig.appSource}_token`,
            response.data.data.token
          );

          if (!isMenuDisabled) dispatch(updateOrderSettingModalIsVisible(true));

          api.get(`/firebase-token`).then((response) => {
            localStorage.setItem('firebase_token', response.data.data.token);
            app
              .auth()
              .signInWithCustomToken(response.data.data.token)
              .then((userCredential) => {})
              .catch((error) => {});
          });

          const { interDeliveryDetails } = getState().orderSettingReducer;
          const config = getState().authReducer.appConfig;
          const { deliveryToAddress } = getState().deliveryAddressesReducer;

          if (
            interDeliveryDetails &&
            !deliveryToAddress.id &&
            config &&
            config.guest_checkout_enabled
          ) {
            api.post('/customer/addresses', deliveryToAddress).then((res) => {
              if (res.data.data.customer_address_id) {
                dispatch(
                  setSelectedDeliveryAddress({
                    ...deliveryToAddress,
                    id: res.data.data.customer_address_id,
                  })
                );
                dispatch(getDeliveryAddresses());
                dispatch(
                  setInterDeliveryDetailsId(res.data.data.customer_address_id)
                );
              }
            });
          }
          if (getState().cartReducer.cartScreen === 'checkout') {
            updateCartScreen('review');
            navigate('/cart');
          }
        }
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAILURE });
        dispatch({
          type: 'UPDATE_AUTH_ERROR_MESSAGE',
          errors: error.response.data.errors,
        });
      });
  };
}

export function logout() {
  return function(dispatch) {
    const deepLink = localStorage.getItem('deep-link-url');
    dispatch({ type: LOGOUT_REQUEST });
    if (deepLink) {
      navigate('/' + deepLink);
    } else {
      navigate('/');
    }
    dispatch(hideToolTip());
    return api.post(`/logout`).then(
      (response) => {
        dispatch({ type: LOGOUT_SUCCESS });
        localStorage.removeItem(`${appConfig.appSource}_token`);
        // localStorage.removeItem(`firebase_token`);
      },
      (error) => {
        // dispatch({type: LOGOUT_FAILURE});
        dispatch({ type: LOGOUT_SUCCESS });
        localStorage.removeItem(`${appConfig.appSource}_token`);
      }
    );
  };
}

export function postForgotPassword(verificationVia) {
  return function(dispatch) {
    return api
      .post(`password/forgot-password`, { ...verificationVia, source })
      .then((response) => {
        dispatch({ type: POST_FORGOT_EMAIL_SUCCESS });
      });
  };
}

export function resetForgotPassword() {
  return {
    type: RESET_FORGOT_PASSWORD,
  };
}

export function checkEmail(values) {
  return function(dispatch) {
    dispatch({ type: CHECK_EMAIL_REQUEST });
    return api
      .post(`/registration/email/availability`, {
        email: values.email,
        source: source,
      })
      .then(
        (response) => {
          dispatch({
            type: CHECK_EMAIL_SUCCESS,
            emailIsAvailable: response.data.data.is_available,
            checkedEmail: values.email,
          });
        },
        (error) => {
          dispatch({ type: CHECK_EMAIL_FAILURE });
        }
      );
  };
}

export function resetCheckedEmail() {
  return {
    type: RESET_CHECKED_EMAIL,
  };
}

export function resetGuestSession() {
  return function(dispatch) {
    dispatch({ type: RESET_GUEST_SESSION });
  };
}

export function setAuthenticationModalIsVisible(bool) {
  return function(dispatch, getState) {
    const { isAuthenticated } = getState().authReducer;
    dispatch({ type: SET_AUTHENTICATION_MODAL_IS_VISIBLE, bool });
    if (!isAuthenticated && !bool) {
      // dispatch({type: DELIVERY_ADDRESSES_FORM_IS_VISIBLE, isVisible: false});
      dispatch({ type: DROPOFF_LOCATION_FORM_IS_VISIBLE, isVisible: false });
    }
  };
}

export function updatePhoneNumber(phone_number) {
  return function(dispatch) {
    dispatch({ type: PATCH_PHONE_NUMBER_REQUEST });
    dispatch({
      type: 'UPDATE_AUTH_ERROR_MESSAGE',
      errors: {},
    });
    api
      .patch(
        `/registration/phone`,
        { phone_number },
        { ignore_interception: true }
      )
      .then((response) => {
        dispatch({ type: PATCH_PHONE_NUMBER_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Phone Number Updated Successfully',
          })
        );
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_AUTH_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}

export function resetAuthReducer() {
  return {
    type: RESET_AUTH_REDUCER,
  };
}

export function getAppConfig() {
  return function(dispatch, getState) {
    const orderType = getState().orderSettingReducer.orderType;
    api.get(`/config?source=${source}&platform_id=${3}`).then((response) => {
      const appConfig = response.data.data;
      const initialOrderType = initialDynamicOrderMethodType(appConfig);
      dispatch({ type: GET_APP_CONFIG, appConfig });
      dispatch({
        type: UPDATE_ALL_ORDER_TYPE,
        orderType: orderType || initialOrderType,
      });
      // const acceptedOrderTypes =
      //   appConfig && Object.keys(appConfig?.accepted_order_types);
      // let singleOrderType = null;
      // if (acceptedOrderTypes.length === 1) {
      //   // singleOrderType = acceptedOrderTypes[0];
      //   // console.log('Acc=>', acceptedOrderTypes);
      //   // dispatch(updateInterOrderType(singleOrderType));
      //   // dispatch(updateOrderType(singleOrderType));
      // } else {
      // }
    });
  };
}

export function loadReactGA() {
  return function(dispatch, getState) {
    // ReactGA.pageview('/');
    // ReactGA.event({
    //   category: 'user visits web ordering',
    //   action: 'user visits web ordering',
    // });
  };
}
export function updateLoginOrSignUpScreen(value) {
  return { type: UPDATE_LOGIN_OR_SIGNUP_SCREEN, screen: value };
}

export function verificationCodeConfirmation(
  verificationVia,
  verification_code
) {
  return function(dispatch, getState) {
    dispatch({
      type: 'UPDATE_AUTH_ERROR_MESSAGE',
      errors: {},
    });
    return api
      .post(
        `password/confirm-verification-code`,
        {
          source,
          ...verificationVia,
          verification_code,
        },
        { ignore_interception: true }
      )
      .then((response) => {
        dispatch({
          type: UPDATE_IS_VERIFICATION_CODE_MATCHED,
          payload: response,
          isVerified: true,
          token: response.data.data.token,
        });

        if (getState().cartReducer.cartScreen === 'review') {
          dispatch(validateGlobalCart());
        }
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_AUTH_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}
export function resetPassword(password) {
  return function(dispatch, getState) {
    const { token } = getState().authReducer;
    dispatch({
      type: 'UPDATE_AUTH_ERROR_MESSAGE',
      errors: {},
    });
    return api
      .post(
        `/password/reset`,
        {
          token,
          password,
        },
        { ignore_interception: true }
      )
      .then((response) => {
        dispatch({ type: IS_PASSWORD_RESET_SUCCESSFULL, payload: true });
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_AUTH_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}

export function initiateCheckoutAsGuest(personalDetails) {
  return function(dispatch, getState) {
    //const { isGuestSessionInitiated } = getState().authReducer;
    //if (!isGuestSessionInitiated) {
    dispatch({ type: INITIATE_CHECKOUT_AS_GUEST_REQUEST });
    return api
      .post(`/guest/init`, personalDetails)
      .then((response) => {
        // localStorage.setItem(`${appConfig.appSource}_token`);
        dispatch({
          type: INITIATE_CHECKOUT_AS_GUEST_SUCCESS,
          token: response.data.data.token,
        });
        // api
        //   .get(`/firebase-token?session_id=${response.data.data.token}`)
        //   .then((response) => {
        //     localStorage.setItem("firebase_token", response.data.data.token);
        //     app
        //       .auth()
        //       .signInWithCustomToken(response.data.data.token)
        //       .then((userCredential) => {})
        //       .catch((error) => {});
        //   })
        //   .catch((error) => {
        //   });
      })
      .catch((error) => {
        dispatch({ type: INITIATE_CHECKOUT_AS_GUEST_FAILURE, error });
      });
    //}
  };
}

export function setIsGuestCheckoutInitialized(isGuestCheckoutInitialized) {
  return function(dispatch) {
    dispatch({
      type: SET_IS_GUEST_SESSION_INITIALIZED,
      isGuestCheckoutInitialized: isGuestCheckoutInitialized,
    });
  };
}
