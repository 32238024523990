import moment from 'moment';

const EXPIRY_THRESHOLD_DAYS = 1;

const isValidCreditCard = (expiry_month, expiry_year) => {
  const creditCardDate = moment(`${expiry_month}/${expiry_year}`, 'MM/YYYY');
  creditCardDate.endOf('month');

  return creditCardDate.isAfter(moment().add(EXPIRY_THRESHOLD_DAYS, 'days'));
};

export default isValidCreditCard;
