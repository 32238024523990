import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../registrationActions';
import ConfirmSMSForm from './ConfirmSMSForm';
import StickyErrorMessageBox from '../../../components/ErrorMessage/StickyErrorMessageBox';

const ConfirmSMSFormContainer = ({
  registrationReducer,
  confirmSMS,
  handleBackButtonClick,
  updatePhoneNumber,
  requestingPatchPhoneNumber,
  patchPhoneNumberError,
  authErrors,
}) => {
  const [patchPhoneNumberIsVisible, setPatchPhoneNumberIsVisible] = useState(
    false
  );
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!requestingPatchPhoneNumber && !patchPhoneNumberError) {
      setPhoneNumber('');
      setPatchPhoneNumberIsVisible(false);
    }
  }, [requestingPatchPhoneNumber]);
  return (
    <>
      <ConfirmSMSForm
        updatePhoneNumberButtonIsDisabled={
          !phoneNumber || phoneNumber.length < 10
        }
        updatePhoneNumber={updatePhoneNumber}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        patchPhoneNumberIsVisible={patchPhoneNumberIsVisible}
        confirmSMS={confirmSMS}
        setPatchPhoneNumberIsVisible={setPatchPhoneNumberIsVisible}
        handleBackButtonClick={handleBackButtonClick}
        isRequesting={registrationReducer.requestingConfirmSMS}
        authErrors={authErrors}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  registrationReducer: state.registrationReducer,
  requestingPatchPhoneNumber: state.authReducer.requestingPatchPhoneNumber,
  patchPhoneNumberError: state.authReducer.patchPhoneNumberError,
  authErrors: state.authReducer.authErrors,
});

export default connect(mapStateToProps, actions)(ConfirmSMSFormContainer);
