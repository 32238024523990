import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import {PrimaryButton, ButtonRow} from '../../../../components/Buttons';
import {Title1, Body} from '../../../../components/Typography';
import ComboGroupPreview from './ComboGroupPreview/ComboGroupPreview';
import calculateMinimumSelectionRequired from '../../util/calculateMinimumSelectionRequired';
import optionsForProductSummary from '../../../OrderSummary/ProductSummary/utils/optionsForProductSummary';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
  padding: 32px;
  @media (max-width: 786px) {
    padding: 20px;
  }
`;

const InfoWrapper = styled.div`
  @media (max-width: 786px) {
    -webkit-overflow-scrolling: touch;
    padding-bottom: 80px;
  }
`;

const ComboTitle1 = styled(Title1)`
  color: ${props => props.theme.productCustomizerTitleFontColor};
  margin-bottom: 24px;
  font-weight: 600;
`;

const Description = styled(Body)`
  margin: 8px 0;
`;

const Price = styled(Body)`
  margin-bottom: 16px;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const CartButton = styled(PrimaryButton)`
  position: relative;
  grid-column: 1 / span 2;
  font-size: 19px;
  font-weight: 600px;
  @media (max-width: 786px) {
    background-color: ${props => props.theme.addToCartButtonBackgroundColor};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
`;

const Dot = () => (
  <i style={{fontSize: '3px', textAlign: 'center'}} className="fas fa-circle" />
);

const CancelWrapper = styled.div``;

const AddtionalPrice = styled.span`
  position: absolute;
  right: 10px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  font-family: ${props => props.theme.secondaryFontFontFamily};
`;
const InstructionWrapper = styled.div`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`;
const InstructionInput = styled.input`
  width: 100%;
  overflow: hidden;
  height: auto;
  resize: none;
  margin: 0 8px 5px 8px;
  padding: 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
    color: var(--colors-basic-dark);
  }
  &::placeholder {
    color: #737a87;
  }
`;
const Line = styled.hr`
  width: 100%;
  color: #c1c1c1;
`;
const ComboDetails = ({
  combo,
  closeComboPopup,
  handleAddToCart,
  addToCartString,
  onGroupClick,
  onEditProductInGroup,
  onRemoveProductInGroup,
  setscrollToDiv,
  addComboOrderInstruction,
}) => {
  const hasOneRequiredGroupNotFilled = combo.combo_item_groups.some(
    comboGroup => {
      if (comboGroup.productCustomizer && comboGroup.is_required) {
        const optionsSummary = optionsForProductSummary(
          comboGroup.productCustomizer.flatProduct
        );

        if (optionsSummary && optionsSummary.length > 0) {
          return false;
        } else {
          return calculateMinimumSelectionRequired(
            comboGroup.productCustomizer.flatProduct
          );
        }
      }
    }
  );
  const hasOneUnavailableProduct = combo.combo_item_groups.some(comboGroup => {
    return (
      comboGroup.is_required &&
      comboGroup.productCustomizer &&
      !comboGroup.productCustomizer.flatProduct.is_product_available
    );
  });
  const [instruction, setInstruction] = useState('');

  useEffect(() => {
    if (combo.instruction && instruction === '') {
      setInstruction(combo.instruction);
    }
  }, [instruction]);
  const [rows, setRows] = useState(1);
  const [prevRows, setPrevRows] = useState(1);
  const handleChange = e => {
    if (e.target.value.length < 180) {
      setInstruction(e.target.value);
      addComboOrderInstruction(e.target.value);
    }
    const textareaLineHeight = 20;
    const previousRows = e.target.rows;
    e.target.rows = 1;
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows > prevRows) {
      setPrevRows(prevRows => prevRows + 1);
      e.target.rows = rows;
      setRows(rows => rows + 1);
    } else if (currentRows < prevRows) {
      setPrevRows(prevRows => prevRows - 1);
      setRows(rows => rows - 1);
      e.target.rows = rows;
    }
  };
  return (
    <Wrapper id="comboModalContainer" name="comboModalContainer">
      <CancelWrapper>
        <Cancel id="ComboDetails-CloseCombo" onClick={() => closeComboPopup()}>
          <i className="fal fa-times" />
        </Cancel>
      </CancelWrapper>
      <InfoWrapper>
        <ComboTitle1 id="ComboDetails-ComboTitle">{combo.name}</ComboTitle1>
        <Description id="ComboDetails-ComboDescription">
          {combo.user_description}
        </Description>
        {combo.is_dollar_discount && (
          <Price>{numeral(combo.price.amount).format('$0.00')}</Price>
        )}
        <ComboGroupPreview
          combo={combo}
          onGroupClick={onGroupClick}
          onEditProductInGroup={onEditProductInGroup}
          onRemoveProductInGroup={onRemoveProductInGroup}
          setscrollToDiv={setscrollToDiv}
        />

        {/* <InstructionWrapper
          marginTop={window.innerWidth <= 480 ? '25px' : '0'}
          marginBottom={window.innerWidth <= 480 ? '160px' : '60px'}
        >
          <InstructionInput
            placeholder="Additional Notes (Optional)"
            as="textarea"
            onChange={handleChange}
            value={instruction}
            rows={rows}
          />
          <Line size="2" />
        </InstructionWrapper> */}
        <ButtonRow style={{marginTop: '20px'}}>
          <CartButton
            id="ComboDetails-CartButton"
            onClick={handleAddToCart}
            disabled={hasOneRequiredGroupNotFilled || hasOneUnavailableProduct}
          >
            {addToCartString}
            {combo.is_dollar_discount && (
              <AddtionalPrice>
                {numeral(combo.totalPrice).format('$0.00')}
              </AddtionalPrice>
            )}
          </CartButton>
        </ButtonRow>
      </InfoWrapper>
    </Wrapper>
  );
};

export default ComboDetails;
