import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Title1, Headline } from '../../../../components/Typography';
import { PrimaryButton } from '../../../../components/Buttons';
import Select from '../../../../components/Select';

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  padding: 32px;
  max-width: 500px;
  // height: 1000px;
  @media (min-width: 786px) {
    width: 600px;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const FormWrapper = styled.div`
  position: relative;
  margin-top: 14px;
  display: grid;
  gap: 21px;
`;

const SelectLabel = styled.p`
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  // grid-template-rows: 1fr;
  grid-gap: 14px;
  margin-top: 40px;
`;

const Delete = styled(Headline)`
  color: ${(props) =>
    props.disabled
      ? props.theme.primaryButtonDisabledColor
      : props.theme.black};
  align-self: center;
  cursor: pointer;
`;

const SaveButton = styled(PrimaryButton)`
  width: 70%;
  margin: auto;
  height: 56px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const DeleteButton = styled(PrimaryButton)`
  width: 70%;
  margin: auto;
  height: 56px;
  font-size: 19px;
  font-weight: 600;

  background-color: ${(props) =>
    props.theme.deleteLocationButtonBackgroundColor};
  color: ${(props) => props.theme.deleteLocationButtonTextColor};

  &:hover {
    background-color: ${(props) =>
      props.theme.deleteLocationButtonBackgroundColor};
    color: ${(props) => props.theme.deleteLocationButtonTextColor};
    transition: all 0.25s ease;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: auto;
    min-width: auto;
  }
`;

const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const DropOffLocationForm = ({
  setDropOffLocationFormIsVisible,
  saveDropOffSelection,
  dropOffLocations,
  pickedDropOffLocation,
  saveDropOffLocationButtonIsEnabled,
  deleteDropOffLocation,
  isEditDropOffLocation,
  setIsEditDropOffLocation,
  saveDropOffLocation,
  selectedDropOffLocation,
  restorePickedLocation,
}) => {
  return (
    <Wrapper>
      <TitleWrapper>
        {!isEditDropOffLocation ? (
          <Title1 style={{ fontWeight: '600' }}>Select Table</Title1>
        ) : (
          <Title1 style={{ fontWeight: '600' }}>Edit Table Selection</Title1>
        )}
        <CloseButton
          onClick={() => {
            setDropOffLocationFormIsVisible(false);
            setIsEditDropOffLocation(false);
            if (!isEditDropOffLocation) {
              deleteDropOffLocation();
            }
            restorePickedLocation();
          }}
        >
          <i
            className="fas fa-times"
            style={{ fontSize: '24px', cursor: 'pointer' }}
          ></i>
        </CloseButton>
      </TitleWrapper>
      <FormWrapper>
        {dropOffLocations.map((locations, i) => {
          return (
            <div key={i}>
              <SelectLabel>{locations[0].label}</SelectLabel>
              <Select
                value={pickedDropOffLocation[i] || null}
                onChange={(location) => {
                  saveDropOffSelection(location, i);
                }}
                options={locations}
                getOptionLabel={(location) => location.value}
                getOptionValue={(location) => location}
                placeholder={`Select ${locations[0].label}`}
                maxMenuHeight={200}
                isLocation={true}
              />
            </div>
          );
        })}
        {!isEditDropOffLocation ? (
          <ButtonWrapper>
            <SaveButton
              id="DropOffAddresses-SaveButton"
              disabled={!saveDropOffLocationButtonIsEnabled}
              onClick={() => {
                setDropOffLocationFormIsVisible(false);
                saveDropOffLocation(pickedDropOffLocation);
              }}
            >
              Save
            </SaveButton>
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <SaveButton
              id="DropOffLocation-SaveButton"
              disabled={!saveDropOffLocationButtonIsEnabled}
              onClick={() => {
                setDropOffLocationFormIsVisible(false);
                setIsEditDropOffLocation(false);
                saveDropOffLocation(pickedDropOffLocation);
              }}
            >
              Save Changes
            </SaveButton>
            <DeleteButton
              id="DropOffLocation-DeleteButton"
              onClick={() => {
                deleteDropOffLocation();
                setDropOffLocationFormIsVisible(false);
                setIsEditDropOffLocation(false);
              }}
            >
              Delete Table Selection
            </DeleteButton>
          </ButtonWrapper>
        )}
      </FormWrapper>
    </Wrapper>
  );
};

export default withStyles(styles)(DropOffLocationForm);
