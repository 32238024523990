import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormGrid from '../../../components/Form/FormGrid';
import { Title2 } from '../../../components/Typography';
import PhoneNumber from '../../../layout/components/PhoneNumber';
import StickyErrorMessageBox from '../../../components/ErrorMessage/StickyErrorMessageBox';

import { PrimaryButton, ButtonRow } from '../../../components/Buttons';

const Button = styled(PrimaryButton)`
  min-width: 0px;
  width: 175px;
  margin-top: 10px;
`;

const VerificationAndChangeNumberWrapper = styled.div`
  display: grid;
  grid-gap: 12px;
`;

const NumberWrapper = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const ErrorMessageWrapper = styled.div`
  padding: 0;
  margin: 0;
  width: 400px;
  @media (max-width: 786px) {
    width: 100%;
  }
`;
const ModalWrapper = styled.div`
  width: 400px;
  padding: 20px;
  background: white;
  margin: auto;
  display: grid;
  grid-gap: 10px;
  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);

  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const VerifyButton = styled.div`
  cursor: pointer;
  margin-right: auto;
  color: ${(props) => props.theme.clickableTextColor};
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

const styles = (theme) => ({
  scrollPaper: {
    alignItems: 'flex-start',
  },
  paper: {
    margin: 0,
    maxWidth: '100%',
  },
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const ConfirmSMSForm = ({
  isRequesting,
  confirmSMS,
  handleBackButtonClick,
  setPatchPhoneNumberIsVisible,
  patchPhoneNumberIsVisible,
  classes,
  setPhoneNumber,
  phoneNumber,
  updatePhoneNumber,
  updatePhoneNumberButtonIsDisabled,
  authErrors,
}) => {
  return (
    <Formik
      initialValues={{ marketing: false }}
      validate={(values) => {
        let errors = {};
        if (!values.sms_code) {
          errors.sms_code = 'Required';
        } else if (!/^\d+$/.test(values.sms_code)) {
          errors.sms_code = 'Must contain Numbers Only';
        }
        return errors;
      }}
      onSubmit={(values) => confirmSMS(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGrid>
            <VerificationAndChangeNumberWrapper>
              <NumberWrapper>
                <TextField
                  id="Registration-VerificationCode"
                  label="Verification Code"
                  name="sms_code"
                  type="text"
                  autoComplete="one-time-code"
                  value={values.sms_code}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.sms_code && errors.sms_code ? true : false}
                  helperText={touched.sms_code && errors.sms_code}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                />
              </NumberWrapper>
              <VerifyButton
                id="Registration-VerifyPhoneButton"
                onClick={() => setPatchPhoneNumberIsVisible(true)}
              >
                Verify using another phone number?
              </VerifyButton>
            </VerificationAndChangeNumberWrapper>
            <ButtonRow justifyContent="space-between">
              {/* <Button
                disabled={isRequesting}
                type="button"
                onClick={handleBackButtonClick}
              >
                Back
              </Button> */}
              <Button
                id="Registration-VerifyButton"
                disabled={!values.sms_code}
              >
                Verify
              </Button>
            </ButtonRow>
          </FormGrid>
          {patchPhoneNumberIsVisible && (
            <Dialog
              open={patchPhoneNumberIsVisible}
              onBackdropClick={() => setPatchPhoneNumberIsVisible(false)}
              fullWidth={
                typeof window !== 'undefined' && window.innerWidth < 786
              }
              fullScreen={
                typeof window !== 'undefined' && window.innerWidth < 786
              }
              classes={{ scrollPaper: classes.scrollPaper }}
              PaperProps={{ classes: { root: classes.paper } }}
              scroll="body"
            >
              {authErrors && Object.keys(authErrors).length > 0 && (
                <ErrorMessageWrapper>
                  <StickyErrorMessageBox
                    errors={authErrors}
                    marginTop="0"
                    messageWidth="100%"
                  />
                </ErrorMessageWrapper>
              )}
              <ModalWrapper>
                <TitleWrapper>
                  <Title2 id="Registration-Title">Update Phone Number</Title2>
                  <Cancel
                    id="Registration-CancelButton"
                    onClick={() => setPatchPhoneNumberIsVisible(false)}
                  >
                    <i className="fal fa-times" />
                  </Cancel>
                </TitleWrapper>

                <PhoneNumber
                  id="Registration-PhoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  handlePhone={(value) => setPhoneNumber(value)}
                />
                <div style={{ margin: '0 auto' }}>
                  <Button
                    id="Registration-UpdateButton"
                    disabled={updatePhoneNumberButtonIsDisabled}
                    type="button"
                    onClick={() => {
                      updatePhoneNumber(phoneNumber);
                    }}
                  >
                    Update
                  </Button>
                </div>
              </ModalWrapper>
            </Dialog>
          )}
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(ConfirmSMSForm);
