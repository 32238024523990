import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Headline} from '../../../../../../components/Typography';

const ComboGroupWrapper = styled.div`
  border-radius: 8px;
  border-style: ${props => (props.isRequired ? 'solid' : 'dashed')};
  border-width: 1.2px;
  padding: 20px 24px;
  border-color: ${props => props.theme.comboGroupOutlineBorderColor};
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: auto 20px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.comboGroupStateBackgroundColor};
    transition: all 0.25s ease;
  }
`;

const GroupLabel = styled(Headline)`
  font-size: 19px;
  font-weight: 600;
`;

const Arrow = styled.span`
  justify-self: end;
  font-size: 16px;
`;

const OptionalSpan = styled.span`
  font-size: 16px;
  font-weight: normal;
  margin-left: 8px;
`;

const EmptyComboGroupPreview = ({
  comboGroup,
  comboGroupIndex,
  onGroupClick,
  id,
}) => {
  return (
    <ComboGroupWrapper
      id={id}
      onClick={() => onGroupClick(comboGroupIndex, id)}
    >
      <GroupLabel>
        {comboGroup.label}
        {!comboGroup.is_required && <OptionalSpan>(Optional)</OptionalSpan>}
      </GroupLabel>
      <Arrow>
        <i className="fas fa-chevron-right"></i>
      </Arrow>
    </ComboGroupWrapper>
  );
};

export default EmptyComboGroupPreview;
