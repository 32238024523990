import React from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  border: solid 1px
    ${props =>
      props.theme.addEditBorderColor
        ? props.theme.addEditBorderColor
        : props.theme.clickableTextColor};
  width: fit-content;
  border-radius: 18px;
  padding: 8px 12px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  color: ${props =>
    props.theme.addEditTextColor
      ? props.theme.addEditTextColor
      : props.theme.clickableTextColor};
  cursor: pointer;
`;

const Label = styled.span`
  margin-left: 12px;
`;

const AddItemButton = ({option, handleAddItem}) => {
  return (
    <ItemWrapper onClick={handleAddItem}>
      <i className="fas fa-plus" />
      <Label id="OptionSummary-AddItemButton">{option.name}</Label>
    </ItemWrapper>
  );
};

export default AddItemButton;
