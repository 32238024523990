import {
  SET_CHANGE_PASSWORD_IS_VISIBLE,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_CHANGE_PASSWORD_FAILURE,
} from './changePasswordTypes';

const initialState = {
  changePasswordIsVisible: false,
  changePasswordIsRequesting: false,
  changePasswordErrors: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_CHANGE_PASSWORD_ERROR_MESSAGE':
      return {
        ...state,
        changePasswordErrors: action.errors,
      };
    case SET_CHANGE_PASSWORD_IS_VISIBLE:
      return {
        ...state,
        changePasswordIsVisible: action.changePasswordIsVisible,
      };
    case POST_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordIsRequesting: true,
      };
    case POST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordIsRequesting: false,
        changePasswordIsVisible: false,
      };
    case POST_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordIsRequesting: false,
      };
    default:
      return state;
  }
}
