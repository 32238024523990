import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll'

const Wrapper = styled.div`
  padding: 20px 20px 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;

  .activeOption {
    color: inherit;
    ::after {
      content: '';
      display: block;
      width: 100%;
      margin-top: 15px;
      border-bottom: 4px solid ${props => props.theme.categoriesBarSelectedBorderColor};
    }
  }  
`

const ScrollWrapper = styled.div`
  overflow-x: scroll;
  display: block;
  white-space: nowrap;
  height: 43px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const OptionLink = styled(Link)`
  font-size: 17px;
  font-weight: 600;
  :not(:last-child) {
    margin-right: 20px;
  }
  display: inline-block;
  cursor: pointer;
  color: ${props => props.theme.gray};
`

const OptionBar = ({
  options,
  targetOptionId,
}) => {
  const scrollToOption = (optionId) => {
    const scrollWrapper = document
      .getElementById('product_customizer_option_scroll_wrapper')
    const scrollWrapperRect = scrollWrapper
      .getBoundingClientRect();

    const activeOptionRect = document
      .getElementById(`product_customizer_option_${optionId}`)
      .getBoundingClientRect();

    scrollWrapper.scrollLeft +=
      activeOptionRect.left - scrollWrapperRect.left - 35; // use -20 to show just the selected category
  };
  useEffect(() => {
    targetOptionId && document.getElementById(`product_customizer_option_${targetOptionId}`).click()
  }, [targetOptionId])
  return (
    <Wrapper>
      <ScrollWrapper id="product_customizer_option_scroll_wrapper">
        {options.map((option) => 
          <OptionLink
            id={`product_customizer_option_${option.option_id}`}
            key={`product_customizer_option_${option.option_id}`}
            to={`option_${option.option_id}`}
            activeClass="activeOption"
            containerId="containerElement"
            spy={true}
            smooth={true}
            offset={0}
            duration={300}
            containerId="productOptionDrawers"
            onSetActive={() => scrollToOption(option.option_id)}
          >
              {option.name}
          </OptionLink>
        )}
      </ScrollWrapper>
    </Wrapper>
  );
};

export default OptionBar;
