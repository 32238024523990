const countSubItemSelection = (item, flatProduct) => {
  let quantity = 0

  if (item.sub_items && item.sub_items.length) {
    quantity += item.sub_items.reduce((count, subItemId) => {
      const subItem = flatProduct.subItemsById[subItemId];
      return count + subItem.quantity * subItem.count_as;
    }, 0)
  }

  return quantity;
};

export default countSubItemSelection;
