import {
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
} from './locationsTypes';

export default function(
  state = {
    requestingGetLocations: false,
    locations: [],
    currentLocation: null,
    orderingClosedInAllLocations: false,
  },
  action
) {
  switch (action.type) {
    case GET_LOCATIONS_REQUEST:
      return {
        ...state,
        requestingGetLocations: true,
      };
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        requestingGetLocations: false,
        locations: action.response.data,
        currentLocation: action.queryString,
        orderingClosedInAllLocations: !action.response.data.length,
      };
    case GET_LOCATIONS_FAILURE:
      return {
        ...state,
        requestingGetLocations: false,
        error: action.error,
      };
    default:
      return state;
  }
}
