import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import appConfig from '../../../app-config';

const StyledPaper = styled(Paper)`
  padding: 0px 5px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-items: center;
  width: 100%;
  height: 40px;

  &:hover {
    outline: 1px solid #000;
    outline-offset: -1px;
  }

  &:focus-within {
    outline: 2px solid ${appConfig.styleConfig.primaryButtonBackgroundColor};
    outline-offset: -1px;
  }
`;

function InputField({
  leftIcon,
  rightIcon,
  deleteIcon,
  value,
  onDelete,
  focused,
  autoFocus,
  highlight,
  ...props
}) {
  return (
    <StyledPaper
      component="form"
      elevation={0}
      style={{
        border:
          highlight &&
          `3px solid ${appConfig.styleConfig.deliveryAddressHighlightColor}`,
        borderRadius: '10px',
      }}
    >
      {leftIcon ? (
        <>
          <IconButton style={{ padding: '10px' }} aria-label="menu">
            {leftIcon}
          </IconButton>
        </>
      ) : null}
      <InputBase
        label="Address"
        {...props}
        focused={focused}
        autoFocus={autoFocus}
        value={value}
      />
      {value && deleteIcon ? (
        <IconButton
          type="button"
          style={{ p: '10px' }}
          aria-label="remove address"
          onClick={() => {
            if (onDelete) onDelete();
          }}
        >
          <i className="fas fa-times" style={{ fontSize: '16px' }} />
        </IconButton>
      ) : null}
      {rightIcon ? (
        <>
          <Divider style={{ height: 28, margin: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            style={{ padding: '10px' }}
            aria-label="directions"
          >
            {rightIcon}
          </IconButton>
        </>
      ) : null}
    </StyledPaper>
  );
}

export default InputField;
