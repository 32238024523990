import {
    GET_UPSELL_REQUEST,
    GET_UPSELL_SUCCESS
  } from './UpsellTypes';

const initialState = {
    requestingUpsell : true,
    upsellProducts: []
}

export default function (state = initialState, action)  {

    switch (action.type) {
        case GET_UPSELL_REQUEST:
          return {
            ...state,
            requestingUpsell: true,
          };
        case GET_UPSELL_SUCCESS:
          return {
            ...state,
            requestingUpsell: false,
            upsellProducts: action.upsellProducts,
          };
        default:
          return state;
      }
}
