import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import appConfig from '../../../app-config';
import { connectSearchBox } from 'react-instantsearch-dom';
import TextField from '@material-ui/core/TextField';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 10px 15px 0;
  width: 85%;
`;

export default connectSearchBox(
  ({
    refine,
    onFocus,
    searchContent,
    setSearchContent,
    isSearching,
    setIsSearching,
  }) => {
    const deepLink = localStorage.getItem('deep-link-url');
    const [showSearchBar, setShowSearchBar] = useState(true);
    const location = useLocation();

    useEffect(() => {
      if (searchContent === '') {
        refine('');
      }
    }, [searchContent]);

    useEffect(() => {
      setShowSearchBar(
        location.pathname.includes('/menu') ||
          location.pathname.includes('/search')
      );
    }, [location.pathname]);

    useEffect(() => {
      if (!showSearchBar) {
        refine('');
        setSearchContent('');
      }
    }, [showSearchBar]);

    return (
      <Wrapper>
        {showSearchBar ? (
          <TextField
            placeholder={
              appConfig.searchBarPlaceholder
                ? appConfig.searchBarPlaceholder
                : 'Dishes'
            }
            aria-label="Search"
            variant="outlined"
            size="small"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (!location.pathname.includes('/menu')) {
                  if (deepLink) {
                    navigate('/' + deepLink);
                  } else {
                    navigate('/');
                  }
                }
                refine(searchContent);
              }
            }}
            onChange={(e) => {
              setSearchContent(e.target.value);
              if (!isSearching) {
                setIsSearching(true);
              }
            }}
            value={searchContent}
            onFocus={onFocus}
            style={{ width: '85%' }}
            InputProps={{
              startAdornment: (
                <i className="far fa-search" style={{ marginRight: '5px' }} />
              ),
              endAdornment:
                searchContent !== '' ? (
                  <i
                    className="far fa-times-circle"
                    onClick={() => {
                      setSearchContent('');
                      refine('');
                      setIsSearching(false);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : null,
            }}
          />
        ) : null}
      </Wrapper>
    );
  }
);
