import styled from 'styled-components';

const Footnote = styled.p`
  font-size: 14px;
  font-weight: ${props => props.fontWeight || 500};
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || props.theme.bodyColor};
  text-transform: ${props => props.textTransform || 'unset'};
  margin: 0;
  font-family: ${props => props.theme.secondaryFontFontFamily};
`;

export default Footnote;
