import React, {Fragment} from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Body} from '../../components/Typography';

const Wrapper = styled.div`
  margin: auto;
  margin-bottom: 32px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  justify-content: flex-start;
  max-width: 1120px;
  @media (max-width: 786px) {
    margin-bottom: 16px;
  }
`;

const PageLink = styled(Body)`
  cursor: pointer;
`;

const BreadCrumb = ({pages}) => {
  return (
    <Wrapper>
      {pages.map((page, index) => (
        <Fragment key={page.url}>
          <PageLink onClick={() => navigate(page.url)}>{page.name}</PageLink>
          {index + 1 < pages.length && <Body>/</Body>}
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default BreadCrumb;
