import React from 'react';
import styled from 'styled-components';
import ImageFadeIn from 'react-image-fade-in';
import optionsForProductSummary from '../../../../../OrderSummary/ProductSummary/utils/optionsForProductSummary';
import renderSelectedItemsString from '../../../../../../utils/renderSelectedItemsString';
import {Headline, Footnote} from '../../../../../../components/Typography';

const Wrapper = styled.div`
  display: grid;
  margin-bottom: 32px;
  grid-gap: 16px;
  pointer-events: ${props => (props.isAvailable ? 'default' : 'none')};
`;

const SelectionWrapper = styled.div`
  border-radius: 8px;
  border-width: 1.2px;
  border-style: ${props => (props.isPrefilled ? 'solid' : 'dashed')};
  padding: 16px;
  border-color: ${props => props.theme.comboGroupOutlineBorderColor};

  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto 20px;
  grid-gap: 16px;

  @media (max-width: 786px) {
    grid-template-rows: auto 40px;
  }

  &:hover {
    background: ${props => props.theme.comboGroupStateBackgroundColor};
    transition: all 0.25s ease;
  }
`;

const ProductTitle = styled(Headline)`
  margin-bottom: 8px;
  color: ${props =>
    props.isAvailable ? 'inherit' : props.theme.unavailableProductColor};
`;

const ProductImage = styled(ImageFadeIn)`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: ${props =>
    props.theme.productPreviewImageShape === 'circle' ? '50%' : '8px'};
  border: ${props => props.theme.productPreviewBorder};
  order: ${props => (props.theme.productPreviewAlignImage === 'left' ? 0 : 2)};
  grid-row: 1 / span 2;
  cursor: ${props => (props.iscustomizable ? 'pointer' : 'normal')};
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
`;

const OptionWrapper = styled.span`
  padding: 5px 2px;
  @media (max-width: 786px) {
    grid-template-columns: 80px 1fr;
  }
`;

const Label = styled.span`
  color: black;
  font-weight: 600;
`;

const Text = styled.span`
  text-transform: capitalize;
  span {
    font-size: 13px;
    font-weight: 500;
  }
`;

const ActionRow = styled.div`
  color: ${props => props.theme.darkGray};
  display: flex;
  flex-direction: row;

  @media (max-width: 320px) {
    > span {
      display: block;
    }
  }
`;

const CustomizeButton = styled(Footnote)`
  margin-right: 12px;
  cursor: pointer;

  i {
    margin-right: 5px;
  }

  :hover {
    color: #69768c;
  }
`;

const RemoveButton = styled(CustomizeButton)``;

const ProductInfo = styled.div`
  cursor: ${props => (props.isCustomizable ? 'pointer' : 'normal')};
  color: ${props =>
    props.isAvailable ? 'inherit' : props.theme.unavailableProductColor};
`;

const ChangeSelection = styled(Footnote)`
  justify-self: end;
  font-weight: 600;
  cursor: pointer;
  i {
    margin-left: 5px;
  }

  :hover {
    color: #69768c;
  }
`;

const ProductTitleWrapper = styled(Headline)`
  margin-bottom: 8px;
  position: relative;
`;

const Required = styled.div`
  background-color: ${props => props.theme.primaryColor};
  border-radius: 4px;
  color: ${props => props.theme.white};
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  font-weight: 300;
  padding: 4px 8px;
  @media (max-width: 480px) {
    position: relative;
    display: inline-block;
  }
`;

const calculateMinimumSelectionRequired = flatProduct => {
  const totalMinimumPick = flatProduct.options.reduce((count, optionId) => {
    const option = flatProduct.optionsById[optionId];
    if (option.sub_options && option.sub_options.length) {
      return (
        count +
        option.sub_options.reduce((count, subOptionId) => {
          const subOption = flatProduct.subOptionsById[subOptionId];

          return count + subOption.minimum_pick;
        }, 0)
      );
    } else {
      return count + option.minimum_pick;
    }
  }, 0);

  return totalMinimumPick;
};

const FilledComboGroupPreview = ({
  comboGroup,
  comboGroupIndex,
  onGroupClick,
  onEditProductInGroup,
  onRemoveProductInGroup,
  id,
}) => {
  const {
    flatProduct,
    totalPrice,
    singlePrice,
    isPrefilled,
  } = comboGroup.productCustomizer;

  const hasOnlyOneItem = comboGroup.productItems.length === 1;

  const isRemoveable = !comboGroup.is_required || !hasOnlyOneItem;

  const optionsSummary = optionsForProductSummary(flatProduct);

  const isCustomizable = flatProduct.prices.length >= 1 && flatProduct.options;

  const minimumPickRequired =
    flatProduct && flatProduct.options && flatProduct.options.length
      ? calculateMinimumSelectionRequired(flatProduct)
      : null;

  return (
    <Wrapper isAvailable={flatProduct.is_product_available}>
      <SelectionWrapper isPrefilled={isPrefilled} id={id}>
        <ProductImage
          iscustomizable={isCustomizable}
          src={flatProduct.image_url}
          onClick={() => {
            isCustomizable &&
              onEditProductInGroup(
                comboGroup.productCustomizer,
                comboGroupIndex,
                id
              );
          }}
          isAvailable={flatProduct.is_product_available}
        />
        <ProductInfo
          id="FilledComboGroupPreview-Productinfo"
          isCustomizable={isCustomizable}
          onClick={() =>
            isCustomizable &&
            onEditProductInGroup(
              comboGroup.productCustomizer,
              comboGroupIndex,
              id
            )
          }
          isAvailable={flatProduct.is_product_available}
        >
          <ProductTitleWrapper>
            <ProductTitle
              id="FilledComboGroupPreview-ProductTitle"
              isAvailable={flatProduct.is_product_available}
            >
              {flatProduct.name}
            </ProductTitle>
            {(!optionsSummary || optionsSummary.length === 0) &&
              minimumPickRequired > 0 && (
                <Required>{minimumPickRequired} Selection(s) Required</Required>
              )}
          </ProductTitleWrapper>
          <div>
            {flatProduct.prices.length > 1 && (
              <OptionWrapper>
                <Label id="FilledComboGroupPreview-Size">Size</Label> -
                <Text>{flatProduct.variant_name.toLowerCase()}</Text>
              </OptionWrapper>
            )}
            {optionsSummary &&
              optionsSummary.map((option, index) => {
                const isLastOption = optionsSummary.length === index + 1;
                if (option.sub_options.length) {
                  return option.sub_options.map(subOption => {
                    return (
                      <OptionWrapper
                        isLastOption={isLastOption}
                        key={subOption.option_id}
                      >
                        <Label id="FilledComboGroupPreview-SubOptionName">
                          {subOption.name}
                        </Label>
                        <span style={{margin: '0 2px'}}>-</span>
                        <Text>
                          {renderSelectedItemsString(
                            flatProduct,
                            subOption
                          ).toLowerCase()}
                        </Text>
                      </OptionWrapper>
                    );
                  });
                } else {
                  return (
                    <div style={{position: 'relative'}}>
                      <OptionWrapper
                        isLastOption={isLastOption}
                        key={option.option_id}
                      >
                        <Label id="FilledComboGroupPreview-OptionName">
                          {option.name}
                        </Label>
                        <span style={{margin: '0 2px'}}>-</span>
                        <Text>
                          {renderSelectedItemsString(
                            flatProduct,
                            option
                          ).toLowerCase()}
                        </Text>
                      </OptionWrapper>
                      {/* {option.minimum_pick !== 1 && (
                        <Required id="FilledComboGroupPreview-SelectionRequired">
                          {option.minimum_pick} Selection Required
                        </Required>
                      )} */}
                    </div>
                  );
                }
              })}
          </div>
        </ProductInfo>
        <ActionRow>
          {isCustomizable && (
            <CustomizeButton
              id="FilledComboGroupPreview-Customize"
              onClick={() =>
                onEditProductInGroup(
                  comboGroup.productCustomizer,
                  comboGroupIndex,
                  id
                )
              }
            >
              <i className="fas fa-pen"></i>
              <span>Customize</span>
            </CustomizeButton>
          )}
          {isRemoveable && (
            <RemoveButton
              id="FilledComboGroupPreview-Remove"
              onClick={() => onRemoveProductInGroup(comboGroupIndex, id)}
            >
              <i className="fas fa-trash-alt"></i>
              <span>Remove</span>
            </RemoveButton>
          )}
        </ActionRow>
      </SelectionWrapper>
      {!hasOnlyOneItem && (
        <ChangeSelection
          id="FilledComboGroupPreview-ChangeSelection"
          onClick={() => onGroupClick(comboGroupIndex, id)}
        >
          Change selection
          <i className="fas fa-chevron-right"></i>
        </ChangeSelection>
      )}
    </Wrapper>
  );
};

export default FilledComboGroupPreview;
