import {
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  SHOW_PAYMENT_METHOD_FORM,
  HIDE_PAYMENT_METHOD_FORM,
  CREATE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  SET_DEFAULT_PAYMENT_METHOD_REQUEST,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_FAILURE,
  SHOW_CONFIRM_DELETE_PAYMENT_METHOD,
  HIDE_CONFIRM_DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  CHANGE_GUEST_PAYMENT_METHOD,
  UPDATE_GUEST_CREDIT_CARD_DETAILS,
  RESET_GUEST_CREDIT_CARD_DETAILS,
  UPDATE_GUEST_GIFT_CARD_DETAILS,
  RESET_GUEST_GIFT_CARD_DETAILS,
  SET_TOUCHED_CREDIT_CARD_DETAILS,
  SHOW_SELECT_PAYMENT_METHOD_FORM,
  SET_SELECTED_PAYMENT_METHOD,
  SHOW_PAYMENT_METHOD_SELECTION_FORM,
} from './paymentMethodsTypes';

export default function(
  state = {
    requestingGetPaymentMethods: false,
    requestingCreatePaymentMethod: false,
    requestingSetDefaultPaymentMethod: false,
    paymentMethods: null,
    paymentMethodFormIsVisible: false,
    confirmDeletePaymentMethodIsVisible: false,
    selectPaymentMethodFormIsVisible: false,
    selectedPaymentMethod: null,
    paymentMethodSelectionFormIsVisible: false,
    guestPaymentMethod: '',
    guestCreditCardDetails: {
      card_number: '',
      cvv: '',
      expiry_month: '',
      expiry_year: '',
      cardholder_name: '',
    },
    errorMessage: {},
    guestGiftCardDetails: null,
    isTouchedCreditCardDetails: false,
    isPayOnArrivalSelected: false,
    isPayPalButtonDisplayed: false,
    payPalNonce: null,
  },
  action
) {
  switch (action.type) {
    case 'UPDATE_PAYPAL_NONCE':
      return {
        ...state,
        payPalNonce: action.payPalNonce,
      };
    case 'UPDATE_PAYPAL_BUTTON_DISPLAY':
      return {
        ...state,
        isPayPalButtonDisplayed: action.isPayPalButtonDisplayed,
      };
    case 'UPDATE_IS_PAY_ON_ARRIVAL_SELECTED':
      return {
        ...state,
        isPayOnArrivalSelected: action.isSelected,
      };
    case GET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        requestingGetPaymentMethods: true,
      };
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.response.data,
        requestingGetPaymentMethods: false,
      };
    case GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        requestingGetPaymentMethods: false,
      };
    case SHOW_PAYMENT_METHOD_FORM:
      return {
        ...state,
        paymentMethodFormIsVisible: true,
        noDialogBackground: action.noDialogBackground,
      };
    case HIDE_PAYMENT_METHOD_FORM:
      return {
        ...state,
        paymentMethodFormIsVisible: false,
      };
    case CREATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        requestingCreatePaymentMethod: true,
      };
    case CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        requestingCreatePaymentMethod: false,
        paymentMethodFormIsVisible: false,
        paymentMethods: action.response.data,
        isPayPalButtonDisplayed: action.isPayPalButtonDisplayed,
      };
    case CREATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        requestingCreatePaymentMethod: false,
      };
    case SET_DEFAULT_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        requestingSetDefaultPaymentMethod: true,
      };
    case SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        requestingSetDefaultPaymentMethod: false,
        paymentMethods: state.paymentMethods.map((paymentMethod) => ({
          ...paymentMethod,
          is_preferred: paymentMethod.id === action.cardId,
        })),
      };
    case SET_DEFAULT_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        requestingSetDefaultPaymentMethod: false,
      };
    case SHOW_CONFIRM_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        confirmDeletePaymentMethodIsVisible: true,
      };
    case HIDE_CONFIRM_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        confirmDeletePaymentMethodIsVisible: false,
      };
    case DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        requestingDeletePaymentMethod: true,
      };
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        requestingDeletePaymentMethod: false,
        confirmDeletePaymentMethodIsVisible: false,
      };
    case DELETE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        requestingDeletePaymentMethod: false,
      };
    case CHANGE_GUEST_PAYMENT_METHOD:
      return {
        ...state,
        guestPaymentMethod: action.method,
      };
    case UPDATE_GUEST_CREDIT_CARD_DETAILS:
      return {
        ...state,
        guestCreditCardDetails: action.guestCreditCardDetails,
      };
    case RESET_GUEST_CREDIT_CARD_DETAILS:
      return {
        ...state,
        guestCreditCardDetails: {
          card_number: '',
          cvv: '',
          expiry_month: '',
          expiry_year: '',
          cardholder_name: '',
        },
      };
    case UPDATE_GUEST_GIFT_CARD_DETAILS:
      return {
        ...state,
        guestGiftCardDetails: action.guestGiftCardDetails,
      };
    case RESET_GUEST_GIFT_CARD_DETAILS:
      return {
        ...state,
        guestGiftCardDetails: null,
      };
    case SET_TOUCHED_CREDIT_CARD_DETAILS:
      return {
        ...state,
        isTouchedCreditCardDetails: action.bool,
      };
    case SHOW_SELECT_PAYMENT_METHOD_FORM:
      return {
        ...state,
        selectPaymentMethodFormIsVisible:
          action.selectPaymentMethodFormIsVisible,
      };
    case SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.selectedPaymentMethod,
      };
    case SHOW_PAYMENT_METHOD_SELECTION_FORM:
      return {
        ...state,
        paymentMethodSelectionFormIsVisible:
          action.paymentMethodSelectionFormIsVisible,
      };
    default:
      return state;
  }
}
