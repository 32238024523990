const getItemPrice = (option, item, flatProduct) => {
  if (item.sub_items) {
    // start with 0 so we can keep adding
    let price = 0;
    // loop through each sub item and check if it's selected (using quantity)
    item.sub_items.forEach(subItemId => {
      let subItem = flatProduct.subItemsById[subItemId];
      if (subItem.quantity) {
        // if selected, add price to total price, use option included pick
        // to determine if addtional_price or regular price is to be used
        price +=
          price +
          ((option.included_pick > 0
            ? subItem.additional_price
            : subItem.price) || 0);
      }
    });
    return price;
  }
  // else we are only working with items
  return (option.included_pick > 0 ? item.additional_price : item.price) || 0;
};

export default getItemPrice;
