import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {showSelectPaymentMethodForm} from '../../modules/PaymentMethods/paymentMethodsActions';

const Wrapper = styled.div`
  padding-left: ${props => (props.isForCheckoutPage ? '80px' : '0')};
  font-family: ${props => props.theme.primaryFontFontFamily};

  @media (max-width: 786px) {
    padding-left: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  align-items: center;
  justify-self: center;
  cursor: pointer;
  border: solid 2px #dbdbdb;
  padding: 19px 16px 19px 24px;
  border-radius: 6px;
  width: 100%;

  :hover {
    border-color: ${props => props.theme.addCreditCardBorderColorHover};
    color: ${props => props.theme.addCreditCardBorderColorHover};
    transition: all 0.25s ease;
  }
`;

const SelectPaymentMethodButton = ({showSelectPaymentMethodForm}) => (
  <Wrapper>
    <ButtonWrapper
      id="SelectPaymentMethod"
      onClick={() => showSelectPaymentMethodForm(true)}
    >
      <div>+ Select Payment Method</div>
      <div style={{textAlign: 'right'}}>
        <i className="fas fa-credit-card-blank" style={{fontSize: '29px'}} />
      </div>
    </ButtonWrapper>
  </Wrapper>
);

export default connect(null, {showSelectPaymentMethodForm})(
  SelectPaymentMethodButton
);
